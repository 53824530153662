import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import {
  PHONE_NUMBER,
  EMAIL,
  DATE_OF_BIRTH,
  DATE_HIRED,
  NOT_AVAILABLE,
  FULLNAME,
  HOME_ADDRESS,
  EMPLOYEE_INFORMATION,
  DEPARTMENT,
  ASSIGNED_ROLES,
  PROFILE_PICTURE,
  WORKING_STATUS,
  CODE_NAME,
} from '../../../constants';
import { snakeCaseToString } from '../../../utils/methods';
import userPlaceholder from '../../../assets/images/user-placeholder.png';

const EmployeeInformation = () => {
  const {
    employeesPageState: { viewedEmployee },
  } = useMst();

  const userPhoto = viewedEmployee.employeePhoto
    ? viewedEmployee.employeePhoto.document_url
    : userPlaceholder;

  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-page-subsection">
        <div className="subsection-header mt0">
          <p>{EMPLOYEE_INFORMATION}</p>
        </div>
        <div className="flex-alignitems-flex-start">
          <div className="w25">
            <div className="section-content">
              <div className="view-page-item-wrapper">
                <p className="label mb10">{PROFILE_PICTURE}</p>
                <div
                  className="employee-photo"
                  style={{ backgroundImage: `url(${userPhoto})` }}
                />
              </div>
            </div>
          </div>
          <div className="w75">
            <div className="section-content flex-alignitems-flex-start pb20 justify-between">
              <div className="view-page-item-wrapper">
                <p className="label">{WORKING_STATUS}</p>
                <div className="align-center">
                  <div
                    className={`status-dot mr10 ${
                      viewedEmployee.isEmployeeOff ? 'unavailable' : ''
                    }`}
                  />
                  <span className="text-capitalize">
                    {viewedEmployee.employeeWorkingStatus}
                  </span>
                </div>
              </div>
              <div className="view-page-item-wrapper">
                <p className="label">{HOME_ADDRESS}</p>
                <p className="content fs75">
                  {viewedEmployee.street_address
                    ? viewedEmployee.street_address
                    : NOT_AVAILABLE}
                </p>
                <p className="content">
                  {viewedEmployee.city ? viewedEmployee.city : NOT_AVAILABLE},{' '}
                  {viewedEmployee.state ? viewedEmployee.state : NOT_AVAILABLE},{' '}
                  {viewedEmployee.postal_code
                    ? viewedEmployee.postal_code
                    : NOT_AVAILABLE}
                </p>
              </div>
              <div className="view-page-item-wrapper">
                <p className="label">{DEPARTMENT}</p>
                <p className="content">
                  {viewedEmployee.userCurrentDepartment
                    ? snakeCaseToString(viewedEmployee.userCurrentDepartment)
                    : NOT_AVAILABLE}
                </p>
              </div>
              <div className="view-page-item-wrapper">
                <p className="label">{DATE_HIRED}</p>
                <p className="content">
                  {viewedEmployee.date_hired
                    ? viewedEmployee.date_hired
                    : NOT_AVAILABLE}
                </p>
              </div>
              {/* <div className="view-page-item-wrapper">
                <p className="label">{ACCOUNT_ACTIVE_ROLE}</p>
                <p className="content">
                  {viewedEmployee.driver_type
                    ? snakeCaseToString(viewedEmployee.driver_type)
                    : NOT_AVAILABLE}
                </p>
              </div> */}
              {/* <div className="view-page-item-wrapper" /> */}
            </div>
            <div className="section-content flex-alignitems-flex-start pb20 justify-between">
              <div className="view-page-item-wrapper">
                <p className="label">{FULLNAME}</p>
                <p className="content">
                  {viewedEmployee.fullName
                    ? viewedEmployee.fullName
                    : NOT_AVAILABLE}
                </p>
              </div>
              <div className="view-page-item-wrapper">
                <p className="label">{CODE_NAME}</p>
                <p className="content">
                  {viewedEmployee.code_name
                    ? viewedEmployee.code_name
                    : NOT_AVAILABLE}
                </p>
              </div>
              <div className="view-page-item-wrapper">
                <p className="label">{PHONE_NUMBER}</p>
                <p className="content">
                  {viewedEmployee.phone_number
                    ? viewedEmployee.phone_number
                    : NOT_AVAILABLE}
                </p>
              </div>
              <div className="view-page-item-wrapper">
                <p className="label">{ASSIGNED_ROLES}</p>
                <p className="content">
                  {viewedEmployee.allUserRolesString
                    ? snakeCaseToString(viewedEmployee.allUserRolesString)
                    : NOT_AVAILABLE}
                </p>
              </div>
              {/*<div className="view-page-item-wrapper" />*/}
            </div>
            <div className="section-content flex-alignitems-flex-start pb20 justify-between">
              <div className="view-page-item-wrapper">
                <p className="label">{DATE_OF_BIRTH}</p>
                <p className="content">
                  {viewedEmployee.date_of_birth
                    ? viewedEmployee.date_of_birth
                    : NOT_AVAILABLE}
                </p>
              </div>
              <div className="view-page-item-wrapper">
                <p className="label">{EMAIL}</p>
                <p className="content">
                  {viewedEmployee.email ? viewedEmployee.email : NOT_AVAILABLE}
                </p>
              </div>
              <div className="view-page-item-wrapper" />
              <div className="view-page-item-wrapper" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(EmployeeInformation);
