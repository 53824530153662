import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { GENERAL_INFORMATION, TRAILER } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import TrailerInformation from './TrailerInformation';
import ViewLocationSection from '../SharedComponents/ViewComponents/ViewLocationSection';

const GeneralInformation = () => {
  const {
    fleetPageState: { viewedTrailer },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedTrailer.setIsGeneralInfoSectionOpen(
              !viewedTrailer.isGeneralInfoSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedTrailer.isGeneralInfoSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {GENERAL_INFORMATION}
          </span>
        </div>
      </div>
      {viewedTrailer?.isGeneralInfoSectionOpen && (
        <div className="flex-alignitems-flex-start">
          <TrailerInformation />
          <ViewLocationSection model={viewedTrailer} type={TRAILER} />
        </div>
      )}
    </div>
  );
};

export default observer(GeneralInformation);
