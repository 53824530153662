import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Select } from 'antd';
import {
  GENERAL_INFORMATION,
  FIRST_NAME,
  LAST_NAME,
  DRIVER_TYPE,
  CODE_NAME,
  CATEGORY,
  NOTES,
  DRIVER_COMPANY_ID,
} from '../../../constants';
import { snakeCaseToString } from '../../../utils/methods';

const { Option } = Select;

const GeneralInformation = () => {
  const {
    fleetPageState: { newDriver, driverTypes, driverCategories },
  } = useMst();

  const onSelectType = (value) => {
    newDriver.setType(value);
  };

  const onSelectCategory = (value) => {
    newDriver.setCategory(value);
  };

  return (
    <div className="add-driver-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{GENERAL_INFORMATION}</span>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{DRIVER_TYPE}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectType}
            value={newDriver.driver_type}
          >
            {driverTypes.map((type) => (
              <Option key={type} className="text-capitalize">
                {snakeCaseToString(type)}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CATEGORY}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectCategory}
            value={newDriver.driver_category}
          >
            {driverCategories.map((type) => (
              <Option key={type} className="text-capitalize">
                {snakeCaseToString(type)}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{DRIVER_COMPANY_ID}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              newDriver.setInternalCompanyId(e.target.value);
            }}
            className="add-edit-input"
            value={newDriver.internal_company_id}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CODE_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              newDriver.setCodeName(e.target.value);
            }}
            className="add-edit-input"
            value={newDriver.code_name}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{FIRST_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              newDriver.setFirstName(e.target.value);
            }}
            className="add-edit-input"
            value={newDriver.first_name}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{LAST_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              newDriver.setLastName(e.target.value);
            }}
            className="add-edit-input"
            value={newDriver.last_name}
          />
        </div>
      </div>
      <div className="section-content align-center">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{NOTES}</span>
          </div>
          <Input
            onChange={(e) => {
              newDriver.setNotes(e.target.value);
            }}
            className="add-edit-input"
            value={newDriver.notes}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformation);
