import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import {
  EMPTY_CARRIERS_TABLE_TEXT,
  EQUIPMENT_DETAILS,
} from '../../../../../constants';
import { Empty, Table } from 'antd';

const EquipmentDetails = () => {
  const {
    carriersPageState: { equipmentItemsList },
  } = useMst();

  const columns = [
    {
      title: 'Equipment Category',
      dataIndex: 'equipment_category',
      key: 'equipment_category',
      render: (text, record) => {
        return <p>{record.equipment.equipment_category}</p>;
      },
    },
    {
      title: 'Equipment Type',
      dataIndex: 'equipment_type',
      key: 'equipment_type',
      render: (text, record) => {
        return <p>{record.equipment.equipment_type}</p>;
      },
    },
  ];

  return (
    <div className="w100">
      <div className="subsection-header align-center">
        <p>{EQUIPMENT_DETAILS}</p>
      </div>
      {equipmentItemsList && (
        <Table
          className="drivers-table fleet-table"
          rowKey={'carrier_equipment_id'}
          columns={columns}
          dataSource={equipmentItemsList}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_CARRIERS_TABLE_TEXT}
              />
            ),
          }}
        />
      )}
    </div>
  );
};

export default observer(EquipmentDetails);
