import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import {
  ATTACHED_FILE,
  DATE_TIME,
  DELETE_INSPECTION,
  EDIT_INSPECTION,
  INSPECTION_TYPE,
  NOTES,
  ODOMETER,
  SIGNATURE,
  VIEW_FILE,
} from '../../constants';
import trash from '../../assets/images/trash.svg';
import edit from '../../assets/images/edit-equipment.svg';
import { rootInstance } from '../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.fleetPageState.editedVehicle.setInspectionToBeDeleted(
    record.inspection_id
  );
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.fleetPageState.editedVehicle.setEditedInspection(
    record.inspection_id
  );
};

export const truckInspectionsColumnsFunc = (rootInstance) => ({
  '1': {
    title: INSPECTION_TYPE,
    dataIndex: 'inspection_type',
    key: 'inspection_type',
  },
  '2': {
    title: DATE_TIME,
    key: 'inspection_date_time',
    render: (text, record) => {
      return (
        <span>
          {moment(record.inspection_date_time).format('YYYY-MM-DD / HH:mm')}
        </span>
      );
    },
  },
  '3': {
    title: ODOMETER,
    dataIndex: 'odometer',
    key: 'odometer',
    render: (text, record) => {
      return (
        rootInstance.fleetPageState.isTruckPage && (
          <span>
            {record.odometer} {record.odometer_unit}
          </span>
        )
      );
    },
  },
  '4': {
    title: NOTES,
    dataIndex: 'notes',
    key: 'notes',
  },
  '6': {
    title: ATTACHED_FILE,
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <Tooltip placement="top" title={VIEW_FILE}>
          <a href={record.document?.document_url} target="blank">
            {record.document?.name}
          </a>
        </Tooltip>
      );
    },
  },
  '7': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        rootInstance.fleetPageState.isEditVehiclePage && (
          <div>
            <Tooltip title={DELETE_INSPECTION}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
            <Tooltip title={EDIT_INSPECTION}>
              <img
                src={edit}
                alt="edit"
                className="pointer ph10"
                onClick={(e) => onClickEdit(e, record)}
              />
            </Tooltip>
          </div>
        )
      );
    },
  },
});

export const trailerInspectionsColumnsFunc = (rootInstance) => ({
  '1': {
    title: INSPECTION_TYPE,
    dataIndex: 'inspection_type',
    key: 'inspection_type',
  },
  '2': {
    title: DATE_TIME,
    key: 'inspection_date_time',
    render: (text, record) => {
      return (
        <span>
          {moment(record.inspection_date_time).format('YYYY-MM-DD / HH:mm')}
        </span>
      );
    },
  },
  '3': {
    title: NOTES,
    dataIndex: 'notes',
    key: 'notes',
  },
  '4': {
    title: SIGNATURE,
    dataIndex: 'signature',
    key: 'signature',
  },
  '5': {
    title: ATTACHED_FILE,
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <Tooltip placement="top" title={VIEW_FILE}>
          <a href={record.document?.document_url} target="blank">
            {record.document?.name}
          </a>
        </Tooltip>
      );
    },
  },
  '6': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        rootInstance.fleetPageState.isEditVehiclePage && (
          <div>
            <Tooltip title={DELETE_INSPECTION}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
            <Tooltip title={EDIT_INSPECTION}>
              <img
                src={edit}
                alt="edit"
                className="pointer ph10"
                onClick={(e) => onClickEdit(e, record)}
              />
            </Tooltip>
          </div>
        )
      );
    },
  },
});
