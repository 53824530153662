import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { YES, NO, SAVE_FIRST_MODAL_TEXT, SAVE_CHANGES } from '../constants';
import save from '../assets/images/save.svg';

const SaveFirstModal = ({ onCancel, onConfirm, open }) => {
  return (
    <div>
      <Modal
        visible={open}
        footer={null}
        centered={true}
        closable={false}
        width={500}
        className="save-first-modal"
      >
        <div className="modal-content p32">
          <div className="align-center">
            <img src={save} alt="save" className="mr10" />
            <h3>{SAVE_CHANGES}</h3>
          </div>
          <p className="mt20">{SAVE_FIRST_MODAL_TEXT}</p>
          <div className="mt50 align-center">
            <button className="btn white no-wrap" onClick={onCancel}>
              {NO}
            </button>
            <button className="btn primary no-wrap ml20" onClick={onConfirm}>
              {YES}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(SaveFirstModal);
