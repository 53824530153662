import React from 'react';
import { observer } from 'mobx-react';
import CreateShipmentHeader from './CreateShipmentHeader';
import ShipmentDetails from './ShipmentDetails';
import {
  CREATE_SHIPMENT,
  SAVE_SHIPMENT,
  MODAL_SUCCESS_TITLE,
  MODAL_SUCCESS_CREATE_SHIPMENT_TEXT,
  MODAL_SUCCESS_CREATE_SHIPMENT_BUTTON_TEXT,
} from '../../../constants';
import { useMst } from '../../../state/RootModel';
import ErrorBox from '../../ErrorBox';
import ProbillsSection from '../Probills/ProbillsSection';
import AddProbillModal from '../Probills/AddProbillModal';
import EditProbillModal from '../Probills/EditProbillModal';
import ConfirmDeleteProbillModal from '../Probills/ConfirmDeleteProbillModal';
import BillingDetails from '../ShipmentCommons/BillingDetails';
import ShipmentTotalCharges from '../ShipmentCommons/ShipmentTotalCharges';
import ShipmentSpecificDetails from '../ShipmentCommons/ShipmentSpecificDetails';
import DocumentsSection from './DocumentsSection';
import AddDocumentModal from './AddDocumentModal';
import SuccessModal from '../../SuccessModal';
import PageRoutes from '../../../utils/PageRoutes';
import ConfirmDeleteDocumentModal from '../ShipmentCommons/ConfirmDeleteDocument';
import ConfirmDeleteShipmentRelatedCharge from '../ShipmentCommons/ConfirmDeleteShipmentRelatedCharge';
import ShipmentProfit from '../ShipmentCommons/ShipmentProfit';
import ShipmentCarriersListModal from '../ShipmentCommons/ShipmentCarriersListModal';

const CreateShipmentComponent = () => {
  const {
    shipmentsPageState: {
      shipmentBeingCreated,
      newShipment,
      shipmentInCreation,
      createShipmentSuccessModalOpen,
      setCreateShipmentSuccessModalOpen,
    },
  } = useMst();

  const handleConfirm = () => {
    shipmentBeingCreated.saveShipment();
  };

  const backLink = PageRoutes.ViewShipment.name;

  const params = { shipmentId: shipmentBeingCreated?.shipment_id?.toString() };

  return (
    <div className="page-content">
      <CreateShipmentHeader />
      <ShipmentDetails />
      {shipmentInCreation && <ProbillsSection />}
      {shipmentInCreation?.newProbill && <AddProbillModal />}
      {shipmentInCreation?.editedProbill && <EditProbillModal />}
      {shipmentInCreation && (
        <div className="w100 mt20 flex-alignitems-flex-start">
          <BillingDetails />
          {shipmentInCreation.isBrokerageShipment && <ShipmentProfit />}
          {!shipmentInCreation.isBrokerageShipment && <ShipmentTotalCharges />}
          <ShipmentSpecificDetails />
          <ShipmentCarriersListModal />
        </div>
      )}
      {shipmentInCreation && <DocumentsSection />}
      {shipmentInCreation?.addDocumentModalOpen && <AddDocumentModal />}

      <ErrorBox obj={shipmentBeingCreated} />

      <button className="btn primary mt20" onClick={handleConfirm}>
        {newShipment ? CREATE_SHIPMENT : SAVE_SHIPMENT}
      </button>

      {shipmentBeingCreated?.probillToBeDeleted && (
        <ConfirmDeleteProbillModal />
      )}
      {shipmentBeingCreated?.documentToBeDeleted && (
        <ConfirmDeleteDocumentModal />
      )}
      {shipmentBeingCreated?.shipmentChargeToBeDeleted && (
        <ConfirmDeleteShipmentRelatedCharge />
      )}

      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_CREATE_SHIPMENT_TEXT}
        buttonText={MODAL_SUCCESS_CREATE_SHIPMENT_BUTTON_TEXT}
        backLink={backLink}
        params={params}
        open={createShipmentSuccessModalOpen}
        closeModal={() => setCreateShipmentSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(CreateShipmentComponent);
