import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Select } from 'antd';
import collapse from '../../../assets/images/dispatch/collapse.svg';
import {
  ASSETS,
  DRIVERS,
  SEARCH_BY_ID,
  TRAILERS,
  TRUCKS,
} from '../../../constants';
import {
  EMPTY_AVAILABLE_DRIVERS_TABLE_TEXT,
  EMPTY_AVAILABLE_TRAILERS_TABLE_TEXT,
  EMPTY_AVAILABLE_TRUCKS_TABLE_TEXT,
  SHOW,
} from '../../../constants/dispatch';
import { DebounceInput } from 'react-debounce-input';
import AssetsTable from './AssetsTable';

const { Option } = Select;

const DispatchAssetsList = () => {
  const {
    dispatchPageState: {
      setIsAssetsListOpened,
      getAvailableAssetOptions,
      assetsSelectedValue,
      setAssetsSelectedValue,
      getFilteredAssetsListData,
      getAssetsListTruckColumns,
      getAssetsListTrailerColumns,
      getAssetsListDriverColumns,
      assetsDataFilter,
      setAssetsSearchFilter,
      setAssetCurrentPage,
      loadAvailableAssets,
      clearAssetsData,
      resetAssetsTotal,
      assetCurrentPage,
    },
  } = useMst();
  const [page, setPageNumber] = useState(2);

  const onAssetsListHide = () => {
    setIsAssetsListOpened(false);
  };
  const onSetAssetsSelectedValue = (asset) => {
    setPageNumber(2);
    setAssetCurrentPage(1);
    setAssetsSelectedValue(asset);
    resetAssetsTotal();
    clearAssetsData();
  };

  const onFilterAssetsData = (e) => {
    setAssetsSearchFilter(e.target.value);
  };

  const fetchMoreData = () => {
    setPageNumber((prev) => {
      return prev + 1;
    });
    setAssetCurrentPage(page);
    loadAvailableAssets();
  };

  return (
    <div className="driver-trailer-list assets-list ph10">
      <div className="align-center mt20 mb20">
        <p className="color-title text-bold text-uppercase">{ASSETS}</p>
        <div className="input-wrapper ml20 mr20 align-center w100">
          <span className="mr20">{SHOW}</span>
          <Select
            style={{
              width: 200,
            }}
            value={assetsSelectedValue}
            onChange={onSetAssetsSelectedValue}
          >
            {getAvailableAssetOptions?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
          <div className="ml-a align-center">
            <p className="mr10 color-title text-bold text-uppercase">SEARCH</p>
            <div className="input-wrapper">
              <DebounceInput
                placeholder={SEARCH_BY_ID}
                debounceTimeout={300}
                value={assetsDataFilter}
                onChange={(e) => onFilterAssetsData(e)}
                className="trailer-search-input"
              />
            </div>
          </div>
        </div>
        <img
          src={collapse}
          alt="collapse shipments list"
          className="ml-a"
          style={{ cursor: 'pointer' }}
          onClick={onAssetsListHide}
        />
      </div>
      {getFilteredAssetsListData && assetsSelectedValue === TRUCKS && (
        <AssetsTable
          tableText={EMPTY_AVAILABLE_TRUCKS_TABLE_TEXT}
          data={getFilteredAssetsListData}
          column={getAssetsListTruckColumns()}
          expandable={true}
          type={TRUCKS}
          update={fetchMoreData}
        />
      )}
      {getFilteredAssetsListData && assetsSelectedValue === TRAILERS && (
        <AssetsTable
          tableText={EMPTY_AVAILABLE_TRAILERS_TABLE_TEXT}
          data={getFilteredAssetsListData}
          column={getAssetsListTrailerColumns()}
          expandable={false}
          type={TRAILERS}
          update={fetchMoreData}
        />
      )}
      {getFilteredAssetsListData && assetsSelectedValue === DRIVERS && (
        <AssetsTable
          tableText={EMPTY_AVAILABLE_DRIVERS_TABLE_TEXT}
          data={getFilteredAssetsListData}
          column={getAssetsListDriverColumns()}
          expandable={true}
          type={DRIVERS}
          update={fetchMoreData}
        />
      )}
    </div>
  );
};
export default observer(DispatchAssetsList);
