import React from 'react';
import { observer } from 'mobx-react';
import CarriersTable from './CarriersTable';
import AddCarrierModal from './addCarrier/AddCarrierModal';
import EditCarrierModal from './editCarrier/EditCarrierModal';
import {
  ADD_CARRIER,
  MODAL_SUCCESS_ADD_CARRIER_BUTTON_TEXT,
  MODAL_SUCCESS_ADD_CARRIER_TEXT,
  MODAL_SUCCESS_EDIT_CARRIER_BUTTON_TEXT,
  MODAL_SUCCESS_EDIT_CARRIER_TEXT,
  MODAL_SUCCESS_TITLE,
  START_TYPING_PLACEHOLDER_TEXT,
} from '../../../../constants';
import { useMst } from '../../../../state/RootModel';
import SuccessModal from '../../../SuccessModal';
import PageRoutes from '../../../../utils/PageRoutes';
import search from '../../../../assets/images/search.svg';
import { DebounceInput } from 'react-debounce-input';

const CarriersComponent = () => {
  const {
    carriersPageState: {
      setSearchTerm,
      searchTerm,
      setCurrentPage,
      getCarriers,
      newCarrier,
      setNewCarrier,
      editedCarrier,
      addCarrierSuccessModalOpen,
      setAddCarrierSuccessModalOpen,
      editCarrierSuccessModalOpen,
      setEditCarrierSuccessModalOpen,
    },
    userState: { canAddCarriers },
  } = useMst();

  const onAddCarrier = () => {
    setNewCarrier({});
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchTerm(searchText);
    setCurrentPage(1);
    getCarriers({});
  };

  const backLink = PageRoutes.Brokerage.name;

  return (
    <div className="pv20 locations-component">
      {canAddCarriers && (
        <button
          className={'btn primary no-wrap header-button'}
          onClick={onAddCarrier}
        >
          {ADD_CARRIER}
        </button>
      )}
      <div className="search-wrapper border-bottom-search align-center pb20">
        <img src={search} alt="search" className="search-icon" />
        <DebounceInput
          debounceTimeout={300}
          onChange={(e) => handleSearch(e)}
          style={{ width: 300 }}
          placeholder={START_TYPING_PLACEHOLDER_TEXT}
          value={searchTerm}
        />
      </div>
      <CarriersTable />
      {newCarrier && <AddCarrierModal />}
      {editedCarrier && <EditCarrierModal />}
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_ADD_CARRIER_TEXT}
        buttonText={MODAL_SUCCESS_ADD_CARRIER_BUTTON_TEXT}
        backLink={backLink}
        open={addCarrierSuccessModalOpen}
        closeModal={() => setAddCarrierSuccessModalOpen(false)}
      />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_CARRIER_TEXT}
        buttonText={MODAL_SUCCESS_EDIT_CARRIER_BUTTON_TEXT}
        backLink={backLink}
        open={editCarrierSuccessModalOpen}
        closeModal={() => setEditCarrierSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(CarriersComponent);
