import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Select } from 'antd';
import ErrorBox from '../../../../ErrorBox';
import { useMst } from '../../../../../state/RootModel';
import addCarrierIcon from '../../../../../assets/images/add-carrier.svg';
import FormPageTitle from '../../../../FormPageTitle';
import { ADD_CARRIER, STATUS } from '../../../../../constants';
import CarrierDetails from './CarrierDetails';
import InsuranceDetails from './InsuranceDetails';
import LocationDetails from './LocationDetails';
import CarrierEquipment from './CarrierEquipment';

const { Option } = Select;

const AddProbillModal = () => {
  const {
    carriersPageState: { newCarrier, setNewCarrier, availableCarrierStatuses },
  } = useMst();

  const onSaveChanges = () => {
    newCarrier.saveCarrier();
  };

  const onCancel = () => {
    setNewCarrier(null);
  };

  const onSelectStatus = (value) => {
    newCarrier.setStatus(value);
  };

  return (
    <div>
      <Modal
        visible={!!newCarrier}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={1000}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addCarrierIcon} title={ADD_CARRIER} />
            <div className="input-horizontal align-center ml10">
              <span className="no-wrap mr10">{STATUS}</span>
              <Select onChange={onSelectStatus} className="text-capitalize">
                {availableCarrierStatuses.map((status, index) => (
                  <Option
                    key={index}
                    value={status}
                    className="text-capitalize"
                  >
                    {status}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <CarrierDetails />
          <InsuranceDetails />
          <LocationDetails />
          <CarrierEquipment />

          <ErrorBox obj={newCarrier} />

          <button className="btn primary" onClick={onSaveChanges}>
            {ADD_CARRIER}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddProbillModal);
