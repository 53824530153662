import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Modal, Select, Input, Upload, DatePicker, TimePicker } from 'antd';
import {
  SAVE_CHANGES,
  UPDATE_REASON,
  REASON,
  MAINTENANCE_TYPE,
  INSPECTION_TYPE,
  MAINTENANCE_NOTE,
  INSPECTION_NOTE,
  NOTE_PLACEHOLDER,
  MAINTENANCE_DOCUMENT,
  INSPECTION_DOCUMENT,
  DATE_TIME,
  TIME,
  ODOMETER,
} from '../../constants';
import trailer from '../../assets/images/trailer.svg';
import truck from '../../assets/images/truck.svg';
import FormPageTitle from '../FormPageTitle';
import fileUpload from '../../assets/images/file-upload.svg';

const { Option } = Select;
const { TextArea } = Input;

const OutOfServiceModal = () => {
  const {
    fleetPageState: { editedTruck, editedVehicle },
  } = useMst();

  const [document, setDocument] = useState([]);

  const icon = editedTruck ? truck : trailer;

  const reasonTypes = editedVehicle.isInMtce
    ? editedVehicle.availableMtceTypes
    : editedVehicle.availableInspectionTypes;

  const onSelectReason = (value) => {
    setDocument([]);

    editedVehicle.isInMtce
      ? editedVehicle.setInitMaintenance({
          vehicle_id: editedVehicle.vehicle_id,
        })
      : editedVehicle.setInitInspection({
          vehicle_id: editedVehicle.vehicle_id,
        });

    editedVehicle.setOutOfServiceReason(value);
  };

  const onSelectReasonType = (value) => {
    editedVehicle.isInMtce
      ? editedVehicle.initMaintenance.setType(value)
      : editedVehicle.initInspection.setType(value);

    editedVehicle.isInMtce
      ? editedVehicle.setOutOfServiceMtceType(value)
      : editedVehicle.setOutOfServiceInspectionType(value);
  };

  const onChangeNote = (e) => {
    editedVehicle.isInMtce
      ? editedVehicle.initMaintenance.setNotes(e.target.value)
      : editedVehicle.initInspection.setNotes(e.target.value);

    editedVehicle.isInMtce
      ? editedVehicle.setOutOfServiceMtceNote(e.target.value)
      : editedVehicle.setOutOfServiceInspectionNote(e.target.value);
  };

  const handleDocumentUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = editedVehicle.isInMtce
        ? MAINTENANCE_DOCUMENT
        : INSPECTION_DOCUMENT;
      if (file.status !== 'uploading') {
        setDocument(listToUpload);
      }
    } else {
      setDocument(fileList);
    }
  };
  const onSaveChanges = () => {
    editedVehicle.isInMtce
      ? editedVehicle.initMaintenance.saveMaintenance(...document)
      : editedVehicle.initInspection.saveInspection(...document);

    editedVehicle.setOutOfServiceModalOpen(false);
  };

  const onCancel = () => {
    setDocument([]);
    editedVehicle.setOutOfServiceReason(null);
    editedVehicle.setInitMaintenance(null);
    editedVehicle.setInitInspection(null);
    editedVehicle.setOutOfServiceModalOpen(false);
  };

  const onDateChange = (value, string) => {
    editedVehicle.isInMtce
      ? editedVehicle.initMaintenance.setDate(string)
      : editedVehicle.initInspection.setDate(string);
  };
  const onTimeChange = (value, string) => {
    editedVehicle.isInMtce
      ? editedVehicle.initMaintenance.setTime(string)
      : editedVehicle.initInspection.setTime(string);
  };
  const onChangeOdometer = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      editedVehicle.isInMtce
        ? editedVehicle.initMaintenance.setOdometer(null)
        : editedVehicle.initInspection.setOdometer(null);
    } else {
      editedVehicle.isInMtce
        ? editedVehicle.initMaintenance.setOdometer(Number(e.target.value))
        : editedVehicle.initInspection.setOdometer(Number(e.target.value));
    }
  };

  return (
    editedVehicle && (
      <div>
        <Modal
          visible={editedVehicle.outOfServiceModalOpen}
          footer={null}
          centered={true}
          onCancel={onCancel}
          maskClosable={false}
          width={400}
          className="modal"
        >
          <div className="p32">
            <div className="align-center">
              <FormPageTitle image={icon} title={UPDATE_REASON} />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{REASON}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className="w100 text-capitalize"
                onChange={onSelectReason}
                value={editedVehicle.outOfServiceReason}
              >
                {editedVehicle.availableOutOfServiceReasons.map((reason) => (
                  <Option
                    key={reason}
                    value={reason}
                    className="text-capitalize"
                  >
                    {reason}
                  </Option>
                ))}
              </Select>
            </div>
            {editedVehicle.outOfServiceReason && (
              <div className="input-wrapper mt20">
                <div className="input-label justify-between">
                  <span>
                    {editedVehicle.isInMtce
                      ? MAINTENANCE_TYPE
                      : INSPECTION_TYPE}
                  </span>
                  <span className="color-secondary">*</span>
                </div>
                <Select
                  className="w100 text-capitalize"
                  onChange={onSelectReasonType}
                  value={
                    editedVehicle.isInMtce
                      ? editedVehicle.outOfServiceMtceType
                      : editedVehicle.outOfServiceInspectionType
                  }
                >
                  {reasonTypes.map((type) => (
                    <Option key={type} value={type} className="text-capitalize">
                      {type}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {editedVehicle.outOfServiceReason && (
              <div className="input-wrapper mt20">
                <div className="input-label justify-between">
                  <span>{DATE_TIME}</span>
                  <span className="color-secondary">*</span>
                </div>
                <DatePicker allowClear={false} onChange={onDateChange} />
                <div className="input-wrapper mt20">
                  <div className="input-label justify-between">
                    <span>{TIME}</span>
                    <span className="color-secondary">*</span>
                  </div>
                  <TimePicker
                    allowClear={false}
                    onChange={onTimeChange}
                    format={'HH:mm'}
                  />
                </div>
              </div>
            )}
            {editedVehicle.outOfServiceReason && (
              <div className="input-wrapper mt20">
                <div className="input-label justify-between">
                  <span>{ODOMETER}</span>
                </div>
                <Input
                  className="add-edit-input"
                  onChange={onChangeOdometer}
                  value={
                    editedVehicle.isInMtce
                      ? editedVehicle.initMaintenance?.odometer
                      : editedVehicle.initInspection?.odometer
                  }
                />
              </div>
            )}
            {editedVehicle.outOfServiceReason && (
              <div className="input-wrapper mt20">
                <div className="input-label justify-between">
                  <span>
                    {editedVehicle.isInMtce
                      ? MAINTENANCE_NOTE
                      : INSPECTION_NOTE}
                  </span>
                  <span className="color-secondary">*</span>
                </div>
                <TextArea
                  rows={4}
                  placeholder={NOTE_PLACEHOLDER}
                  onChange={onChangeNote}
                  value={
                    editedVehicle.isInMtce
                      ? editedVehicle.outOfServiceMtceNote
                      : editedVehicle.outOfServiceInspectionNote
                  }
                />
              </div>
            )}
            {editedVehicle.outOfServiceReason && (
              <div className="input-wrapper mt20">
                <div className="input-label">
                  <Upload
                    fileList={document}
                    onChange={handleDocumentUpload}
                    beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                  >
                    <div className="align-center justify-between pointer">
                      <span className="color-primary">
                        {editedVehicle.isInMtce
                          ? MAINTENANCE_DOCUMENT
                          : INSPECTION_DOCUMENT}
                      </span>
                      <img
                        src={fileUpload}
                        alt="file upload"
                        className="ml10"
                      />
                    </div>
                  </Upload>
                </div>
              </div>
            )}
            <div className="mt50">
              <button className="btn primary" onClick={onSaveChanges}>
                {SAVE_CHANGES}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(OutOfServiceModal);
