import { getRoot, types } from 'mobx-state-tree';
import { organizationDepartmentsColumnsFunc } from './Departments/OrganizationDepartmentsTableColumns';
import { organizationUserRolesColumnsFunc } from './UserRoles/OrganizationUserRolesTableColumns';
import { OrganizationDepartmentLightModel } from './Departments/OrganizationDepartmentLightModel';
import { OrganizationDepartmentModel } from './Departments/OrganizationDepartmentModel';
import { UserRoleOrganizationModel } from './UserRoles/UserRoleOrganizationModel';
import {
  getDepartments,
  getPermissions,
  getUserRoles,
} from '../../actions/organization';
import { PermissionModel } from './Permissions/PermissionModel';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { notification } from 'antd';
import {
  getDepartmentsPromise,
  getPermissionsPromise,
  getRoleByIdPromise,
  getUserRolesPromise,
} from '../../promises/organization';
import { UserRoleOrganizationLightModel } from './UserRoles/UserRoleOrganizationLightModel';

const allSettled = require('promise.allsettled');

export const OrganizationSettingsPageModel = types
  .model('OrganizationSettingsModel', {
    /** Departments **/
    newDepartment: types.maybe(OrganizationDepartmentLightModel),
    editedDepartment: types.maybeNull(
      types.reference(OrganizationDepartmentModel)
    ),
    deletingDepartment: types.maybeNull(
      types.reference(OrganizationDepartmentModel)
    ),
    departmentsList: types.map(OrganizationDepartmentModel),
    isDepartmentsListOpened: true,
    isAddNewDepartmentModalOpened: false,
    isEditingDepartmentModalOpened: false,
    isDeleteDepartmentModalOpened: false,
    isDepartmentSuccessModalOpen: false,

    /** UserRoles **/
    isUserRolesListOpened: false,
    newUserRole: types.maybe(UserRoleOrganizationLightModel),
    editedUserRole: types.maybeNull(UserRoleOrganizationModel),
    deletingUserRole: types.maybeNull(
      types.reference(UserRoleOrganizationModel)
    ),
    userRolesList: types.map(UserRoleOrganizationModel),
    isUserRoleSuccessModalOpen: false,
    isDeleteUserRoleModalOpen: false,

    /** Permissions **/
    permissionsList: types.map(PermissionModel),
  })
  .volatile((self) => {
    return {
      organization_name: 'Pentagon Logistics',
    };
  })
  .views((self) => {
    return {
      get organizationDepartmentsList() {
        return Array.from(self.departmentsList.values());
      },
      get organizationUserRoles() {
        return Array.from(self.userRolesList.values());
      },
      get permissionsArray() {
        return Array.from(self.permissionsList.values());
      },
      get getGroupedPermissions() {
        const list = this.permissionsArray.map((i) => i.tag);
        const uniqueList = Array.from(new Set(list));
        const groups = uniqueList.map((c) => {
          return { tag: c, data: [] };
        });
        this.permissionsArray.forEach((d) => {
          groups
            .find((g) => g.tag === d.tag)
            .data.push({
              name: d.permission_name,
              id: d.permission_id,
            });
        });
        groups.sort((a, b) => (a.tag > b.tag ? 1 : -1));
        return groups;
      },
    };
  })
  .actions((self) => {
    return {
      /** Departments **/
      setIsDepartmentSuccessModalOpen(value) {
        self.isDepartmentSuccessModalOpen = value;
      },
      setIsDeleteDepartmentModalOpened(value) {
        self.isDeleteDepartmentModalOpened = value;
      },
      getDepartmentToBeDeleted(department_id) {
        let selectedDepartment = self.organizationDepartmentsList.filter(
          (org) => org.department_id === department_id
        )[0];
        this.setDepartmentToBeDeleted(selectedDepartment);
      },
      setDepartmentToBeDeleted(department) {
        self.deletingDepartment = department;
      },
      setIsEditingDepartmentModalOpened(value) {
        self.isEditingDepartmentModalOpened = value;
      },
      getEditingDepartment(department_id) {
        let selectedDepartment = self.organizationDepartmentsList.filter(
          (org) => org.department_id === department_id
        )[0];
        this.setEditedDepartment(selectedDepartment);
      },
      setEditedDepartment(department) {
        self.editedDepartment = department;
      },
      setIsAddNewDepartmentModalOpened(value) {
        self.isAddNewDepartmentModalOpened = value;
      },
      setIsDepartmentsListOpened(value) {
        self.isDepartmentsListOpened = value;
      },
      getDepartmentsColumns() {
        return Object.values(organizationDepartmentsColumnsFunc(getRoot(self)));
      },
      getDepartmentsList() {
        setFetchingInProgress(true);
        getDepartments()
          .then((response) => {
            this.setDepartmentsList(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      setDepartmentsList(departments) {
        self.departmentsList.clear();
        departments.forEach((item) => {
          self.departmentsList.put({
            ...item,
          });
        });
      },
      /** UserRoles **/
      setIsDeleteUserRoleModalOpen(value) {
        self.isDeleteUserRoleModalOpen = value;
      },
      getUserRoleToBeDeleted(role_id) {
        const selectedUserRole = self.userRolesList.get(role_id);
        this.setUserRoleToBeDeleted(selectedUserRole);
      },
      setUserRoleToBeDeleted(role) {
        self.deletingUserRole = role;
      },
      setIsUserRoleSuccessModalOpen(value) {
        self.isUserRoleSuccessModalOpen = value;
      },
      setIsUserRolesListOpened(value) {
        self.isUserRolesListOpened = value;
      },
      getUserRolesColumns() {
        return Object.values(organizationUserRolesColumnsFunc(getRoot(self)));
      },
      getUserRolesList() {
        setFetchingInProgress(true);
        getUserRoles()
          .then((response) => {
            this.setUserRolesList(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      setUserRolesList(user_roles) {
        self.userRolesList.clear();
        user_roles.forEach((item) => {
          self.userRolesList.put({
            ...item,
          });
        });
      },
      getEditedRole(id) {
        setFetchingInProgress(true);
        let role_id = parseInt(id);
        let data = {
          role_id: role_id,
        };
        this.setEditedRole(data);

        let promises = [
          getPermissionsPromise(self),
          getRoleByIdPromise(self, role_id),
        ];
        allSettled(promises)
          .then((response) => {
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(true);
          });
      },
      setEditedRole(data) {
        self.editedUserRole = data;
      },
      /** Permissions **/
      setPermissionsList(permissions) {
        permissions.forEach((item) => {
          if (item.tag !== null) {
            self.permissionsList.put({
              ...item,
            });
          }
        });
      },
      getPermissionsList() {
        setFetchingInProgress(true);
        getPermissions()
          .then((response) => {
            this.setPermissionsList(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      beforeEnterAddRolePage() {
        setFetchingInProgress(true);
        let promises = [getPermissionsPromise(self)];
        allSettled(promises)
          .then(() => {
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      beforeEnterOrganizationPage() {
        setFetchingInProgress(true);
        let promises = [getDepartmentsPromise(self), getUserRolesPromise(self)];
        allSettled(promises)
          .then(() => {
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
    };
  });
