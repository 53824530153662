import React from 'react';
import { Tooltip } from 'antd';
import {
  MAKE_MODEL_YEAR,
  PRICE,
  LEASE_RATE,
  REMAINING_AMOUNT,
  PARTICIPATION,
  PAYMENT_PERIOD,
  REMAINING_PERIOD,
  MVR,
  DPR,
  MARKET_VALUE_RATE,
  DEPRECIATION_RATE,
  TRUCKS,
} from '../../constants';
import questionMark from '../../assets/images/question-mark.svg';

export const leasingCalculationColumnsFunc = (rootInstance) => ({
  '1': {
    title: MAKE_MODEL_YEAR,
    key: 'truck_id',
    render: (text, record) => {
      return (
        <span>
          {rootInstance.fleetPageState.leasingCalculationTableData === TRUCKS
            ? record.truck_make
            : record.trailer_make}{' '}
          {rootInstance.fleetPageState.leasingCalculationTableData === TRUCKS
            ? record.truck_model
            : record.trailer_type}{' '}
          {record.year}
        </span>
      );
    },
  },
  '2': {
    title: PRICE,
    key: 'price',
    dataIndex: 'price',
  },
  '3': {
    title: LEASE_RATE,
    key: 'lease_rate',
    dataIndex: 'lease_rate',
  },
  '4': {
    title: REMAINING_AMOUNT,
    key: 'remaining_amount',
    dataIndex: 'remaining_amount',
    render: (text, record) => {
      return <span className="color-error">{record.remaining_amount}</span>;
    },
  },
  '5': {
    title: PARTICIPATION,
    key: 'participation',
    dataIndex: 'participation',
  },
  '6': {
    title: PAYMENT_PERIOD,
    key: 'payment_period',
    dataIndex: 'payment_period',
  },
  '7': {
    title: REMAINING_PERIOD,
    key: 'remaining_period',
    dataIndex: 'remaining_period',
  },
  '8': {
    title: (
      <div className="align-center">
        <span>{MVR}</span>
        <Tooltip placement="top" title={MARKET_VALUE_RATE}>
          <img className="ml5 pointer" src={questionMark} alt="question" />
        </Tooltip>
      </div>
    ),
    key: 'mvr',
    dataIndex: 'mvr',
    width: '7.5%',
  },
  '9': {
    title: (
      <div className="align-center">
        <span>{DPR}</span>
        <Tooltip placement="top" title={DEPRECIATION_RATE}>
          <img className="ml5 pointer" src={questionMark} alt="question" />
        </Tooltip>
      </div>
    ),
    key: 'dpr',
    dataIndex: 'dpr',
    width: '7.5%',
  },
});
