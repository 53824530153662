import React from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';
import edit from '../../../assets/images/edit.svg';
import { Input, Modal, Select } from 'antd';
import { ALL, CANCEL, CONFIRM } from '../../../constants';
import {
  COMPENSATION_VALUE_BASED_ON,
  COMPENSATION_VALUE_CURRENCY,
  COMPENSATION_VALUE_DEFAULT_VALUE,
  COMPENSATION_VALUE_EDIT,
  COMPENSATION_VALUE_NAME,
  COMPENSATION_VALUE_RATE,
  DEFAULT_VALUE,
  DEFAULT_VALUE_REGEX,
} from '../../../constants/payroll';
import ErrorBox from '../../ErrorBox';

const { Option } = Select;

const EditCompensationValueModal = () => {
  const {
    payrollPageState: {
      isEditingCompensationValueModalOpen,
      setIsEditingCompensationValueModalOpen,
      editedValue,
      getAllCompensationPaymentRates,
      getAllCompensationCurrencies,
      getAllCompensationBasedOnTypes,
      saveEditedCompensationValue,
      errorText,
    },
  } = useMst();

  const onCancel = () => {
    setIsEditingCompensationValueModalOpen(false);
  };
  const onConfirm = () => {
    saveEditedCompensationValue();
  };

  return (
    <div>
      <Modal
        visible={!!isEditingCompensationValueModalOpen}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="modal-content p20">
          <div className="align-center mb20">
            <img src={edit} alt="save" className="mr10" />
            <h3>{COMPENSATION_VALUE_EDIT}</h3>
          </div>
          <div className="flex-column">
            <div className="input-wrapper w100">
              <div className="input-label justify-between">
                <span>{COMPENSATION_VALUE_NAME}</span>
              </div>
              <Input
                disabled
                type="text"
                value={editedValue?.compensation_value}
                className="trailer-search-input"
              />
            </div>
            <div className="section-content justify-between align-center pb20">
              <div className="input-wrapper w45 mt20">
                <div className="input-label align-center">
                  <span>{COMPENSATION_VALUE_BASED_ON}</span>
                </div>
                <Select
                  className={'w100'}
                  onChange={(ct) => editedValue.setBasedOn(ct)}
                  value={editedValue?.based_on}
                >
                  <Option key={ALL} value={null} className="text-capitalize">
                    -
                  </Option>
                  {getAllCompensationBasedOnTypes.map((ct, index) => (
                    <Option key={index} value={ct}>
                      {ct}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="input-wrapper w45 mt20">
                <div className="input-label align-center">
                  <span>
                    {COMPENSATION_VALUE_RATE}
                    <span className="color-secondary d-iblock ml10">*</span>
                  </span>
                </div>
                <Select
                  className={'w100'}
                  onChange={(ct) => editedValue.setRate(ct)}
                  value={editedValue?.rate}
                >
                  {getAllCompensationPaymentRates.map((ct, index) => (
                    <Option key={index} value={ct}>
                      {ct}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="section-content justify-between align-center pb20">
              <div className="input-wrapper w45">
                <div className="input-label justify-between">
                  <span>{COMPENSATION_VALUE_DEFAULT_VALUE}</span>
                </div>
                <Input
                  type="text"
                  value={editedValue?.default_value_temp}
                  placeholder={COMPENSATION_VALUE_DEFAULT_VALUE}
                  onFocus={() => {
                    if (editedValue.default_value_temp === DEFAULT_VALUE)
                      editedValue.setDefaultValueTemp(null);
                  }}
                  onBlur={() => {
                    if (!editedValue.default_value_temp)
                      editedValue.setDefaultValueTemp(DEFAULT_VALUE);
                  }}
                  onChange={(e) => {
                    var rgx = DEFAULT_VALUE_REGEX;

                    if (rgx.test(e.target.value) && e.target.value)
                      editedValue.setDefaultValueTemp(e.target.value);
                    else {
                      editedValue.setDefaultValueTemp(null);
                    }
                  }}
                  className="trailer-search-input"
                />
              </div>
              <div className="input-wrapper w45">
                <div className="input-label align-center">
                  <span>{COMPENSATION_VALUE_CURRENCY}</span>
                  <span className="color-secondary ml10">*</span>
                </div>
                <Select
                  className={'w100'}
                  onChange={(ct) => editedValue.setCurrency(ct)}
                  value={editedValue?.currency}
                >
                  {getAllCompensationCurrencies.map((ct, index) => (
                    <Option key={index} value={ct}>
                      {ct}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>

          <ErrorBox obj={{ errorText }} />
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditCompensationValueModal);
