import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { useMst } from '../../../state/RootModel';
import { EMPTY_LICENCES_TABLE_TEXT } from '../../../constants';

const LicencesTable = () => {
  const {
    fleetPageState: { viewedDriver },
  } = useMst();

  return (
    <Table
      className="mb20"
      rowKey={'licence_id'}
      columns={viewedDriver.getLicencesColumns()}
      dataSource={viewedDriver.licencesArray}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={EMPTY_LICENCES_TABLE_TEXT}
          />
        ),
      }}
    />
  );
};

export default observer(LicencesTable);
