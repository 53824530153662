import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import FormPageTitle from '../../FormPageTitle';
import GeneralInformation from './GeneralInformation';
import LicenceInformation from './LicenceInformation';
import AssociationsSection from './AssociationsSection';
import CompliancesSection from './CompliancesSection';
import ViewDocumentationSection from '../SharedComponents/ViewComponents/ViewDocumentationSection';
import WorkingStatusComponent from './WorkingStatus';
import viewDriverIcon from '../../../assets/images/view-driver.svg';
import { EDIT_DRIVER, VIEW_DRIVER } from '../../../constants';
import PageRoutes from '../../../utils/PageRoutes';
import Link from '../../Link';
import DriverPayroll from './DriverPayroll';

const ViewDriverComponent = () => {
  const {
    fleetPageState: { viewedDriver },
  } = useMst();

  return (
    <div className="add-edit-form-component">
      {viewedDriver?.driverFullyFetched && (
        <div>
          {/* TITLE */}
          <div className="align-center">
            <FormPageTitle image={viewDriverIcon} title={VIEW_DRIVER} />
            <span>
              <h2 className="text-medium color-primary">
                {viewedDriver.fullName}
              </h2>
            </span>
            <Link
              className={'btn primary ml10 ph20 pv0'}
              view={PageRoutes.EditDriver.name}
              params={{ driverId: viewedDriver.driver_id }}
            >
              {EDIT_DRIVER}
            </Link>
          </div>
          <GeneralInformation />
          <WorkingStatusComponent />
          <LicenceInformation />
          <AssociationsSection />
          <CompliancesSection />
          <DriverPayroll />
          <ViewDocumentationSection model={viewedDriver} />
        </div>
      )}
    </div>
  );
};

export default observer(ViewDriverComponent);
