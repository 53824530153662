import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import addOrganization from '../../../assets/images/add-organization.svg';
import { Input, Modal } from 'antd';
import ErrorBox from '../../ErrorBox';
import { CANCEL, CONFIRM } from '../../../constants';
import {
  DEPARTMENT_NAME,
  EDIT_DEPARTMENT,
} from '../../../constants/organization/departments';

const EditOrganizationDepartmentModal = () => {
  const {
    currentOrganization: {
      isEditingDepartmentModalOpened,
      setIsEditingDepartmentModalOpened,
      editedDepartment,
    },
  } = useMst();

  const onSetDepartmentName = (e) => {
    editedDepartment.setDepartmentName(e.target.value);
  };

  const onCancel = () => {
    setIsEditingDepartmentModalOpened(false);
  };
  const onConfirm = () => {
    console.log('validating backend..');
    editedDepartment.saveEditedDepartment();
  };
  return (
    <div>
      <Modal
        visible={!!isEditingDepartmentModalOpened}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="modal-content p20">
          <div className="align-center mb20">
            <img src={addOrganization} alt="save" className="mr10" />
            <h3>{EDIT_DEPARTMENT}</h3>
          </div>
          <div className="flex-column">
            <div className="input-wrapper w100">
              <div className="input-label justify-between">
                <span>
                  {DEPARTMENT_NAME}
                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Input
                type="text"
                value={editedDepartment?.department_name}
                placeholder={DEPARTMENT_NAME}
                onChange={(e) => onSetDepartmentName(e)}
                className="trailer-search-input"
              />
            </div>
          </div>

          <ErrorBox obj={editedDepartment} />
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditOrganizationDepartmentModal);
