import { types } from 'mobx-state-tree';
import { addClient } from '../../actions/clients';
import {
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  CUSTOMERS,
  CUSTOMER_NAME_REQUIRED,
  EMAIL_ERROR_TEXT,
  EMAIL_REQUIRED,
  STATE_REQUIRED,
  BILLING_EMAIL_REQUIRED,
  MC_REQUIRED,
} from '../../constants';
import {
  getErrorText,
  setFetchingInProgress,
  validateEmail,
} from '../../utils/methods';
import { rootInstance } from '../RootModel';

export const ClientLightModel = types
  .model('ClientLightModel', {
    company: types.maybe(types.maybeNull(types.string)),
    address: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    billing_email: types.maybe(types.maybeNull(types.string)),
    mc: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),

    customerSearchTerm: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setNote(text) {
        self.notes = text;
      },
      setMC(text) {
        self.mc = text;
      },
      setBillingEmail(text) {
        self.billing_email = text;
      },
      setCustomerSearchTerm(text) {
        self.customerSearchTerm = text;
      },
      setCompany(text) {
        self.company = text;
      },
      setAddress(text) {
        self.address = text;
      },
      setCountry(text) {
        self.country = text;
      },
      setCity(text) {
        self.city = text;
      },
      setState(text) {
        self.state = text;
      },
      setPostalCode(text) {
        self.postal_code = text;
      },
      setPhoneNumber(text) {
        self.phone_number = text;
      },
      setEmail(text) {
        self.email = text;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveCustomerError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        const emailValid = validateEmail(self.email);
        const billingEmailValid = validateEmail(self.billing_email);
        if (!self.company) {
          this.setErrorText(CUSTOMER_NAME_REQUIRED);
        } else if (!self.address) {
          this.setErrorText(ADDRESS_REQUIRED);
        } else if (!self.country) {
          this.setErrorText(COUNTRY_REQUIRED);
        } else if (!self.state) {
          this.setErrorText(STATE_REQUIRED);
        } else if (!self.city) {
          this.setErrorText(CITY_REQUIRED);
        } else if (!self.mc) {
          this.setErrorText(MC_REQUIRED);
        } else if (!self.email) {
          this.setErrorText(EMAIL_REQUIRED);
        } else if (!self.billing_email) {
          this.setErrorText(BILLING_EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else if (!billingEmailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveCustomer() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addClient(self)
            .then(() => {
              rootInstance.clientsPageState.setNewCustomer(null);
              rootInstance.brokeragePageState.setActiveTab(CUSTOMERS);
              setFetchingInProgress(false);
              rootInstance.clientsPageState.setAddClientSuccessModalOpen(true);
            })
            .catch((error) => {
              this.throwSaveCustomerError(error);
            });
        }
      },
    };
  });
