import {
  COMPENSATION_TYPE,
  COMPENSATION_VALUES,
} from '../../../constants/payroll';

export const compensationsValuesTableColumns = (rootInstance) => ({
  '1': {
    title: COMPENSATION_VALUES,
    dataIndex: 'compensation_value',
    key: 'compensation_value',
  },
  '2': {
    title: COMPENSATION_TYPE,
    dataIndex: 'compensation_type',
    key: 'compensation_type',
  },
});
