import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Input } from 'antd';
import { useMst } from '../../../../state/RootModel';
import { TITLE, STATUS_FOR_ACTIVE_SHIPMENT } from '../../../../constants';

const NewShipmentStatusComponent = () => {
  const {
    shipmentSpecificsPageState: { newShipmentStatus },
  } = useMst();

  const onSelectActiveForShipment = () => {
    newShipmentStatus.setActive(!newShipmentStatus.status_for_active_shipment);
  };
  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) => newShipmentStatus.setName(e.target.value)}
          value={newShipmentStatus?.name}
        />
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="align-center">
          <Checkbox
            checked={newShipmentStatus?.status_for_active_shipment}
            onChange={onSelectActiveForShipment}
            className="align-center"
          >
            <p>{STATUS_FOR_ACTIVE_SHIPMENT}</p>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

export default observer(NewShipmentStatusComponent);
