import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Input } from 'antd';
import { useMst } from '../../../../state/RootModel';
import { TITLE, DESCRIPTION } from '../../../../constants';

const { TextArea } = Input;

const NewRouteActionComponent = () => {
  const {
    shipmentSpecificsPageState: { newRouteAction },
  } = useMst();

  const onChangeDescription = (e) => {
    newRouteAction.setDescription(e.target.value);
  };
  const onChangeActionName = (e) => {
    newRouteAction.setRouteActionName(e.target.value);
  };

  const onSelectRequiresTruck = () => {
    newRouteAction.setRequiresTruck(!newRouteAction.requires_truck);
  };
  const onSelectRequiresTrailer = () => {
    newRouteAction.setRequiresTrailer(!newRouteAction.requires_trailer);
  };
  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={onChangeActionName}
          value={newRouteAction?.trip_event_name}
        />
      </div>
      <div className="input-wrapper align-center w100 mt20">
        <div className="input-wrapper w50">
          <div className="align-center">
            <Checkbox
              checked={newRouteAction?.requires_truck}
              onChange={onSelectRequiresTruck}
              className="align-center"
            >
              <p>Requires Truck</p>
            </Checkbox>
          </div>
        </div>
        <div className="input-wrapper w50">
          <div className="align-center">
            <Checkbox
              checked={newRouteAction?.requires_trailer}
              onChange={onSelectRequiresTrailer}
              className="align-center"
            >
              <p>Requires Trailer</p>
            </Checkbox>
          </div>
        </div>
      </div>
      <div className="input-wrapper mt20 w100">
        <div className="input-label justify-between">
          <span>{DESCRIPTION}</span>
        </div>
        <TextArea
          rows={4}
          onChange={onChangeDescription}
          value={newRouteAction?.description}
        />
      </div>
    </div>
  );
};

export default observer(NewRouteActionComponent);
