import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import { CONFIRM_LOCATION_COORDINATES_TEXT } from '../../../../constants';
import { useMst } from '../../../../state/RootModel';

const EditLocationCoordinatesMap = () => {
  const {
    locationsPageState: { draggableEditedLocation },
  } = useMst();

  const eventHandlers = useMemo(
    () => ({
      dragend(e) {
        const coordinates = e.target._latlng;
        draggableEditedLocation.setLocationLatitude(coordinates.lat.toString());
        draggableEditedLocation.setLocationLongitude(
          coordinates.lng.toString()
        );
      },
    }),
    [draggableEditedLocation]
  );

  return (
    draggableEditedLocation && (
      <div className="map-wrapper">
        <p className="mb10">{CONFIRM_LOCATION_COORDINATES_TEXT}</p>
        <MapContainer
          center={[
            draggableEditedLocation.location_latitude,
            draggableEditedLocation.location_longitude,
          ]}
          zoom={13}
          scrollWheelZoom={false}
          className="view-fleet-map"
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker
            // @ts-ignore
            draggable
            eventHandlers={eventHandlers}
            position={[
              draggableEditedLocation.location_latitude,
              draggableEditedLocation.location_longitude,
            ]}
          />
        </MapContainer>
      </div>
    )
  );
};

export default observer(EditLocationCoordinatesMap);
