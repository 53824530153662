import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../state/RootModel';
import AsyncSelect from 'react-select/async';
import {
  ADDRESS,
  CITY,
  COUNTRY,
  DELIVERY_LOCATION,
  DELIVERY_NUMBER,
  DELIVERY_TIME,
  DELIVERY_TYPE,
  PHONE_NUMBER,
  RECEIVER,
  SCHEDULED,
  START_TYPING_PLACEHOLDER_TEXT,
  STATE,
  ZIP_CODE,
  ZONE,
} from '../../../../constants';
import { Input, Select, TimePicker, DatePicker } from 'antd';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';

const debounce = require('lodash.debounce');
const { Option } = Select;
const { RangePicker } = DatePicker;

const ReceiverInfo = () => {
  const {
    shipmentsPageState: { currentShipment },
    clientsPageState: { getFilteredClients },
  } = useMst();

  const [searchDeliveryLocationValue, setSearchDeliveryLocationValue] =
    useState('');

  const loadSuggestions = debounce(getFilteredClients, 300);

  const onSelectReceiver = (client) => {
    currentShipment.newProbill.setReceiverId(client.client_id);
  };

  const onChangeDeliveryLocation = (locationId) => {
    const location = currentShipment.newProbill.delivery_locations.find(
      (item) => {
        return item.location_id === locationId;
      }
    );
    location &&
      currentShipment.newProbill.setSelectedDeliveryLocation(location);
  };

  const onSearchDeliveryLocation = (value) => {
    setSearchDeliveryLocationValue(value);
  };

  const onDeliveryTimeFromChange = (value, string) => {
    currentShipment.newProbill.setDeliveryTimeStartSelected(string);
  };

  const onDeliveryTimeToChange = (value, string) => {
    currentShipment.newProbill.setDeliveryTimeEndSelected(string);
  };

  const onDeliveryDatesChange = (dates) => {
    const starting_date = moment(dates[0]).format('YYYY-MM-DD');
    const end_date = moment(dates[1]).format('YYYY-MM-DD');
    currentShipment.newProbill.setDeliveryDateStartSelected(starting_date);
    currentShipment.newProbill.setDeliveryDateEndSelected(end_date);
  };

  const getDisabledFromHours = () => {
    if (currentShipment?.newProbill?.isDeliveryRangeSetInSameDate) {
      const toHours = currentShipment?.newProbill?.deliveryTimeEndSelected
        ? moment(
            currentShipment?.newProbill.deliveryTimeEndSelected,
            'HH:mm'
          ).hours()
        : null;

      let hours = [];
      if (toHours) {
        for (let i = toHours + 1; i < 24; i++) {
          hours.push(i);
        }
      }
      return hours;
    }
  };
  const getDisabledToHours = () => {
    if (currentShipment?.newProbill?.isDeliveryRangeSetInSameDate) {
      const fromHours = currentShipment?.newProbill?.deliveryTimeStartSelected
        ? moment(
            currentShipment?.newProbill.deliveryTimeStartSelected,
            'HH:mm'
          ).hours()
        : null;

      let hours = [];
      if (fromHours) {
        for (let i = fromHours - 1; i >= 0; i--) {
          hours.push(i);
        }
      }
      return hours;
    }
  };
  const getDisabledFromMinutes = (hour) => {
    if (currentShipment?.newProbill?.isDeliveryRangeSetInSameDate) {
      const toHours = currentShipment?.newProbill?.deliveryTimeEndSelected
        ? moment(
            currentShipment?.newProbill.deliveryTimeEndSelected,
            'HH:mm'
          ).hours()
        : null;
      const toMinutes = currentShipment?.newProbill?.deliveryTimeEndSelected
        ? moment(
            currentShipment?.newProbill.deliveryTimeEndSelected,
            'HH:mm'
          ).minutes()
        : null;

      let minutes = [];

      if (hour === toHours) {
        for (let i = toMinutes + 1; i < 60; i++) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  };
  const getDisabledToMinutes = (hour) => {
    if (currentShipment?.newProbill?.isDeliveryRangeSetInSameDate) {
      const fromHours = currentShipment?.newProbill?.deliveryTimeStartSelected
        ? moment(
            currentShipment?.newProbill.deliveryTimeStartSelected,
            'HH:mm'
          ).hours()
        : null;
      const fromMinutes = currentShipment?.newProbill?.deliveryTimeStartSelected
        ? moment(
            currentShipment?.newProbill.deliveryTimeStartSelected,
            'HH:mm'
          ).minutes()
        : null;

      let minutes = [];

      if (hour === fromHours) {
        for (let i = fromMinutes - 1; i >= 0; i--) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  };

  const disabledDate = (value) => {
    // Can not select days before pickup start date
    if (currentShipment?.newProbill?.pickupDateStartSelected) {
      return (
        value <
        moment(currentShipment?.newProbill.pickupDateStartSelected).startOf(
          'day'
        )
      );
    }
  };

  const onDeliveryNumberChange = (e) => {
    currentShipment?.newProbill.setDeliveryNumber(e.target.value);
  };
  const onSelectDeliveryType = (value) => {
    currentShipment?.newProbill?.setDeliveryTimeType(value);
  };

  const onDeliveryDateChange = (value, string) => {
    currentShipment?.newProbill.setDeliveryDateStartSelected(string);
    currentShipment?.newProbill.setDeliveryDateEndSelected(string);
  };

  return (
    <div className="delivery-info w45">
      <div className="input-wrapper w100">
        <div className="input-label justify-between">
          <span>{RECEIVER}</span>
        </div>
        <AsyncSelect
          loadOptions={loadSuggestions}
          onChange={onSelectReceiver}
          placeholder={START_TYPING_PLACEHOLDER_TEXT}
        />
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{DELIVERY_LOCATION}</span>
        </div>
        <Select
          disabled={!currentShipment?.newProbill?.receiver_id}
          showSearch
          style={{ width: '100%' }}
          placeholder={
            !searchDeliveryLocationValue.length &&
            !currentShipment?.newProbill.delivery_location_id
              ? START_TYPING_PLACEHOLDER_TEXT
              : ''
          }
          optionFilterProp="children"
          onChange={onChangeDeliveryLocation}
          onSearch={onSearchDeliveryLocation}
          filterOption={(input, option) =>
            option.props.children
              // @ts-ignore
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {currentShipment?.newProbill?.delivery_locations.map((item) => {
            return (
              <Option key={item.location_id} value={item.location_id}>
                {item.location_name}
              </Option>
            );
          })}
        </Select>
      </div>
      <div className="justify-between mt20">
        <div className="input-wrapper w70">
          <div className="input-label justify-between">
            <span>{ADDRESS}</span>
          </div>
          <Input
            className="add-edit-input"
            value={
              currentShipment?.newProbill?.selectedDeliveryLocation?.address
            }
            disabled
          />
        </div>
        <div className="input-wrapper w25">
          <div className="input-label justify-between">
            <span>{ZONE}</span>
          </div>
          <Input
            className="add-edit-input"
            value={currentShipment?.newProbill?.selectedDeliveryLocation?.zone}
            disabled
          />
        </div>
      </div>
      <div className="justify-between mt20">
        <div className="input-wrapper w35">
          <div className="input-label justify-between">
            <span>{ZIP_CODE}</span>
          </div>
          <Input
            className="add-edit-input"
            value={
              currentShipment?.newProbill?.selectedDeliveryLocation?.postal_code
            }
            disabled
          />
        </div>
        <div className="input-wrapper w60">
          <div className="input-label justify-between">
            <span>{COUNTRY}</span>
          </div>
          <Input
            className="add-edit-input"
            value={
              currentShipment?.newProbill?.selectedDeliveryLocation?.country
            }
            disabled
          />
        </div>
      </div>
      <div className="mt20">
        <div className="input-wrapper w100">
          <div className="input-label justify-between">
            <span>{STATE}</span>
          </div>
          <Input
            className="add-edit-input"
            value={currentShipment?.newProbill?.selectedDeliveryLocation?.state}
            disabled
          />
        </div>
        <div className="input-wrapper mt20 w100">
          <div className="input-label justify-between">
            <span>{CITY}</span>
          </div>
          <Input
            className="add-edit-input"
            value={currentShipment?.newProbill?.selectedDeliveryLocation?.city}
            disabled
          />
        </div>
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{PHONE_NUMBER}</span>
        </div>
        <PhoneInput
          value={
            currentShipment?.newProbill?.selectedDeliveryLocation?.phone_number
          }
          displayInitialValueAsLocalNumber
          disabled
        />
      </div>
      <div className="input-label mt20">
        <span>{DELIVERY_TYPE}</span>
        <div className="flex-alignitems-flex-start w100">
          <div className="input-wrapper w100">
            <Select
              className="w100 text-capitalize"
              onChange={onSelectDeliveryType}
              value={currentShipment?.newProbill?.delivery_time_type}
            >
              {currentShipment?.newProbill?.timeTypes.map((type, index) => (
                <Option key={index} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{SCHEDULED}</span>
        </div>
        {currentShipment?.newProbill?.delivery_time_type === 'Appointment' && (
          <DatePicker
            value={
              currentShipment?.newProbill.deliveryDateStartSelected
                ? moment(currentShipment?.newProbill.deliveryDateStartSelected)
                : null
            }
            disabledDate={disabledDate}
            onChange={onDeliveryDateChange}
          />
        )}
        {currentShipment?.newProbill?.delivery_time_type === '24/7' && (
          <DatePicker
            value={moment(
              currentShipment?.newProbill.deliveryDateStartSelected
            )}
            disabledDate={disabledDate}
            onChange={onDeliveryDateChange}
          />
        )}
        {currentShipment?.newProbill?.delivery_time_type === 'Time Frame' && (
          <RangePicker
            value={[
              currentShipment?.newProbill?.deliveryDateStartSelected
                ? moment(currentShipment?.newProbill.deliveryDateStartSelected)
                : null,
              currentShipment?.newProbill?.deliveryDateEndSelected
                ? moment(currentShipment?.newProbill.deliveryDateEndSelected)
                : null,
            ]}
            onChange={onDeliveryDatesChange}
            disabledDate={disabledDate}
          />
        )}
        {currentShipment?.newProbill?.delivery_time_type === 'Not Assigned' && (
          <p>Not assigned</p>
        )}
      </div>
      <div className="w100">
        <div className="input-label justify-between mt20">
          <span>{DELIVERY_TIME}</span>
        </div>
        <div className="flex-alignitems-flex-start w100">
          <div className="input-wrapper w50">
            <TimePicker
              onChange={onDeliveryTimeFromChange}
              disabledHours={getDisabledFromHours}
              disabledMinutes={getDisabledFromMinutes}
              allowClear={false}
              format={'HH:mm'}
              value={
                currentShipment?.newProbill?.deliveryTimeStartSelected
                  ? moment(
                      currentShipment?.newProbill?.deliveryTimeStartSelected,
                      'HH:mm'
                    )
                  : null
              }
              disabled={!currentShipment?.newProbill.isDeliveryDateRangeSet}
            />
          </div>

          <div className="input-wrapper w50">
            {currentShipment?.newProbill?.delivery_time_type ===
              'Time Frame' && (
              <TimePicker
                onChange={onDeliveryTimeToChange}
                disabledHours={getDisabledToHours}
                disabledMinutes={getDisabledToMinutes}
                format={'HH:mm'}
                value={
                  currentShipment?.newProbill?.deliveryTimeEndSelected
                    ? moment(
                        currentShipment?.newProbill?.deliveryTimeEndSelected,
                        'HH:mm'
                      )
                    : null
                }
                disabled={!currentShipment?.newProbill.isDeliveryDateRangeSet}
              />
            )}
          </div>
        </div>
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{DELIVERY_NUMBER}</span>
        </div>
        <Input
          className="add-edit-input"
          value={currentShipment?.newProbill?.delivery_number}
          onChange={onDeliveryNumberChange}
        />
      </div>
    </div>
  );
};

export default observer(ReceiverInfo);
