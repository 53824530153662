import React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import {
  ADDRESS,
  CITY,
  COUNTRY,
  LOCATION_DETAILS,
  OTHER,
  STATE,
  ZIP_CODE,
} from '../../../../../constants';
import 'react-phone-number-input/style.css';
import { useMst } from '../../../../../state/RootModel';
import { Select } from 'antd';

const { Option } = Select;
const LocationDetails = () => {
  const {
    carriersPageState: { newCarrier, countriesArray, filteredStates },
  } = useMst();

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header align-center">
        <p>{LOCATION_DETAILS}</p>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w10">
          <div className="input-label justify-between">
            <span>{ZIP_CODE}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => newCarrier.setPostalCode(e.target.value)}
            value={newCarrier.postal_code}
          />
        </div>
        <div className="input-wrapper w25 ml20">
          <div className="input-label justify-between">
            <span>{COUNTRY}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className={'w100'}
            onChange={(country) => newCarrier.setCountry(country)}
          >
            {countriesArray.map((country, index) => (
              <Option key={index} value={country.name}>
                {country.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper w25 ml20">
          <div className="input-label justify-between">
            <span>{STATE}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className={'w100'}
            onChange={(state) => newCarrier.setState(state)}
          >
            {filteredStates &&
              filteredStates.states.map((state, index) => (
                <Option key={index} value={state}>
                  {state}
                </Option>
              ))}
            <Option key={'other'} value={'Other'}>
              {OTHER}
            </Option>
          </Select>
        </div>
        <div className="input-wrapper w25 ml20">
          <div className="input-label justify-between">
            <span>{CITY}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => newCarrier.setCity(e.target.value)}
            value={newCarrier.city}
          />
        </div>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <div className="input-label justify-between">
            <span>{ADDRESS}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => newCarrier.setAddress(e.target.value)}
            value={newCarrier.address}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(LocationDetails);
