import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { useMst } from '../../../state/RootModel';
import { EMPTY_COMPENSATIONS_TABLE_TEXT } from '../../../constants';
import {
  DEDUCTIONS,
  EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT,
  VALUES,
} from '../../../constants/payroll';

const CompensationsTable = () => {
  const {
    fleetPageState: {
      editedDriver,
      expandedRowKeys,
      setExpandedRowKeys,
      setSelectedCompensationStructureId,
    },
  } = useMst();

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (
      expanded &&
      (record.compensation_values.length !== 0 ||
        record.compensation_deductions.length !== 0)
    ) {
      keys.push(record.compensation_id);
    }
    setExpandedRowKeys(keys);
  };

  return (
    <Table
      onRow={(record) => {
        return {
          onClick: () => {
            setSelectedCompensationStructureId(record.compensation_id);
          },
        };
      }}
      expandedRowKeys={expandedRowKeys}
      onExpand={onTableRowExpand}
      className="mb20"
      rowKey={'compensation_id'}
      columns={editedDriver.editDriverCompensationTableColumns}
      dataSource={editedDriver.compensationsArray.sort(function (x, y) {
        return x.is_active ? -1 : y.is_active ? 1 : 0;
      })}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={EMPTY_COMPENSATIONS_TABLE_TEXT}
          />
        ),
      }}
      expandRowByClick
      expandIconAsCell={false}
      expandIcon={() => <></>}
      rowClassName={(record) =>
        record.is_active
          ? 'compensation-table-row'
          : 'compensation-table-row-inactive'
      }
      expandedRowRender={(record: any) => {
        return (
          <div>
            {!!record.compensation_values.length && (
              <Table
                className="compensations-table mt10"
                columns={editedDriver.getCompensationValuesOrDeductionsTableColumns(
                  VALUES
                )}
                dataSource={record.compensation_values.map((el) =>
                  el.based_on === null ? { ...el, based_on: 'N/A' } : el
                )}
                pagination={false}
                rowKey={record.compensation_value_id}
                rowClassName={() => 'compensation-table-expanded-row'}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT}
                    />
                  ),
                }}
              />
            )}
            {!!record.compensation_deductions.length && (
              <Table
                className="compensations-table mt10"
                columns={editedDriver.getCompensationValuesOrDeductionsTableColumns(
                  DEDUCTIONS
                )}
                dataSource={record.compensation_deductions.map((el) =>
                  el.based_on === null ? { ...el, based_on: 'N/A' } : el
                )}
                pagination={false}
                rowKey={record.compensation_deduction_id}
                rowClassName={() => 'compensation-table-expanded-row'}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT}
                    />
                  ),
                }}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default observer(CompensationsTable);
