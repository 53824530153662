import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Empty, Modal } from 'antd';
import {
  CUSTOMERS_LIST,
  EMPTY_AVAILABLE_CUSTOMERS_TABLE_TEXT,
} from '../../../constants/dispatch';
import { Table } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import { SEARCH_BY_ID } from '../../../constants';

const OrganizeTourCustomersListModal = () => {
  const {
    dispatchPageState: {
      setIsOrganizeTourCustomersModalOpen,
      isOrganizeTourCustomersModalOpen,
      setCustomerListSearch,
      customersListSearch,
      getFilteredAvailableCustomers,
      getCustomersTableColumns,
    },
  } = useMst();

  const onClose = () => {
    setIsOrganizeTourCustomersModalOpen(false);
  };

  const expandIcon = () => {
    return null;
  };
  const onCustomerSearch = (e) => {
    setCustomerListSearch(e.target.value);
  };

  return (
    <div>
      <Modal
        visible={!!isOrganizeTourCustomersModalOpen}
        style={{ top: 88 }}
        footer={null}
        centered={false}
        closable={true}
        onCancel={onClose}
        width={1500}
      >
        <div className="p32">
          <div className="align-center mb40">
            <p className="mr50 color-title text-bold text-uppercase">
              {CUSTOMERS_LIST}
            </p>
            <div className="input-wrapper">
              <DebounceInput
                debounceTimeout={300}
                placeholder={SEARCH_BY_ID}
                value={customersListSearch}
                onChange={(e) => onCustomerSearch(e)}
                className="trailer-search-input"
              />
            </div>
          </div>
          <Table
            rowKey={'client_id'}
            dataSource={getFilteredAvailableCustomers}
            columns={getCustomersTableColumns()}
            pagination={false}
            expandRowByClick={true}
            expandIcon={expandIcon}
            scroll={{ y: 500 }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={EMPTY_AVAILABLE_CUSTOMERS_TABLE_TEXT}
                />
              ),
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default observer(OrganizeTourCustomersListModal);
