import { Empty, Input, Select, Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import {
  ADD,
  ADD_COMPENSATION_DEDUCTION,
  ADD_COMPENSATION_VALUE,
  ALL,
  DISCARD,
} from '../../../constants';
import {
  COMPENSATION_DEDUCTIONS,
  COMPENSATION_DEDUCTION_TYPE,
  COMPENSATION_VALUES,
  COMPENSATION_VALUE_BASED_ON,
  COMPENSATION_VALUE_CURRENCY,
  COMPENSATION_VALUE_DEFAULT_VALUE,
  COMPENSATION_VALUE_NAME,
  COMPENSATION_VALUE_RATE,
  DEDUCTIONS_DELETE,
  DEFAULT_VALUE,
  DEFAULT_VALUE_REGEX,
  EMPTY_COMPENSATION_DEDUCTIONS_TABLE_TEXT,
  EMPTY_COMPENSATION_VALUES_TABLE_TEXT,
  VALUES_DELETE,
} from '../../../constants/payroll';
import { useMst } from '../../../state/RootModel';
import ErrorBox from '../../ErrorBox';

const { Option } = Select;
const CompensationDeductionsSection = () => {
  const {
    payrollPageState: {
      getCompensationValuesOrDeductionsTableColumns,
      compensationCurrencies,
      compensationPaymentRates,
      compensationBasedOnTypes,

      compensationDeductionsTypes,
      getAllEditedCompensationDeductions,
      edited_compensation_deduction,
      edited_compensation_deduction_based_on,
      edited_compensation_deduction_currency,
      edited_compensation_deduction_rate,
      deduction_default_value_temp,
      edited_deduction_error_text,
      setEditedCompensationDeduction,
      setEditedCompensationDeductionBasedOn,
      setEditedCompensationDeductionCurrency,
      setEditedCompensationDeductionRate,
      setDeductionDefaultValueTemp,
      addDeductionToEditedCompensationValuesList,
      setIsDeductionAddingFormOpen,
      isDeductionAddingFormOpen,
      clearAddingDeductionToEditingCompensationForm,
    },
  } = useMst();

  const onCancel = () => {
    setIsDeductionAddingFormOpen(false);
    clearAddingDeductionToEditingCompensationForm();
  };

  return (
    <div className="form-page-section">
      <div className="form-section-title-row pb10 mb10 mt20">
        <span className="form-section-title">{COMPENSATION_DEDUCTIONS}</span>
        {!isDeductionAddingFormOpen && (
          <button
            className="btn primary ml20 ph20 pv0"
            onClick={() => setIsDeductionAddingFormOpen(true)}
          >
            {ADD_COMPENSATION_DEDUCTION}
          </button>
        )}
      </div>
      <Table
        className="compensations-table"
        columns={getCompensationValuesOrDeductionsTableColumns(
          DEDUCTIONS_DELETE
        )}
        dataSource={getAllEditedCompensationDeductions.map((el) =>
          el.based_on === null ? { ...el, based_on: 'N/A' } : el
        )}
        pagination={false}
        rowKey={(record, index) => index.toString()}
        rowClassName={() => 'compensation-table-expanded-row'}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_COMPENSATION_DEDUCTIONS_TABLE_TEXT}
            />
          ),
        }}
      />
      {isDeductionAddingFormOpen && (
        <>
          <div className="section-content flex-alignitems-flex-start pb20 mt10">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>
                  {COMPENSATION_DEDUCTION_TYPE}
                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationDeduction(ct)}
                value={edited_compensation_deduction}
              >
                {compensationDeductionsTypes.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{COMPENSATION_VALUE_BASED_ON}</span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationDeductionBasedOn(ct)}
                value={edited_compensation_deduction_based_on}
              >
                <Option key={ALL} value={null} className="text-capitalize">
                  -
                </Option>
                {compensationBasedOnTypes.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>
                  {COMPENSATION_VALUE_RATE}
                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationDeductionRate(ct)}
                value={edited_compensation_deduction_rate}
              >
                {compensationPaymentRates.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="section-content flex-alignitems-flex-start pb20">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{COMPENSATION_VALUE_DEFAULT_VALUE}</span>
              </div>
              <Input
                type="text"
                onFocus={() => {
                  if (deduction_default_value_temp === DEFAULT_VALUE)
                    setDeductionDefaultValueTemp(null);
                }}
                onBlur={() => {
                  if (!deduction_default_value_temp)
                    setDeductionDefaultValueTemp(DEFAULT_VALUE);
                }}
                onChange={(e) => {
                  var rgx = DEFAULT_VALUE_REGEX;

                  if (rgx.test(e.target.value) && e.target.value)
                    setDeductionDefaultValueTemp(e.target.value);
                  else {
                    setDeductionDefaultValueTemp(null);
                  }
                }}
                className="add-edit-input"
                value={deduction_default_value_temp}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>
                  {COMPENSATION_VALUE_CURRENCY}

                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationDeductionCurrency(ct)}
                value={edited_compensation_deduction_currency}
              >
                {compensationCurrencies.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="ml10">
            <ErrorBox obj={{ errorText: edited_deduction_error_text }} />
          </div>
          <div className="align-center justify-end">
            <button
              className="btn primary add no-wrap header-button mt10 mb20"
              onClick={() => addDeductionToEditedCompensationValuesList()}
            >
              {ADD}
            </button>
            <button
              type="button"
              className="btn transparent discard text-capitalize ml10 mt10 mb20"
              onClick={onCancel}
            >
              {DISCARD}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(CompensationDeductionsSection);
