import React from 'react';
import { observer } from 'mobx-react';
import BarComponent from './BarComponent';

const BarChartComponent = (props) => {
  const data = props.bars;

  return (
    <div className="bar-chart-wrapper flex-alignitems-flex-start justify-between">
      {data.map((bar, index) => (
        <BarComponent
          value={bar.value}
          text={bar.text}
          title={bar.title}
          key={index}
        />
      ))}
    </div>
  );
};

export default observer(BarChartComponent);
