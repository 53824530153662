import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Checkbox } from 'antd';
import { SELECT } from '../../constants';

const SelectEmployeesRow = () => {
  const {
    employeesPageState: {
      departmentsArray,
      selectDepartmentEmployees,
      selectedDepartments,
    },
  } = useMst();

  const onCheckedDepartment = (departments) => {
    selectDepartmentEmployees(departments);
  };

  return (
    <div className="select-employees-dropdown">
      <Checkbox.Group
        onChange={onCheckedDepartment}
        className="text-capitalize flex-column"
        value={selectedDepartments}
      >
        {departmentsArray.map((department) => (
          <Checkbox
            key={department.department_id}
            value={department.department_id}
          >
            {SELECT} {department.department_name}
          </Checkbox>
        ))}
      </Checkbox.Group>
    </div>
  );
};

export default observer(SelectEmployeesRow);
