import { types } from 'mobx-state-tree';
import { DocumentModel } from '../../DocumentModel';

export const LicenceModel = types
  .model('LicenceModel', {
    licence_id: types.identifierNumber,
    licence_number: types.maybe(types.maybeNull(types.string)),
    issued_in: types.maybe(types.maybeNull(types.string)),
    issued_on: types.maybe(types.maybeNull(types.string)),
    expiry_date: types.maybe(types.maybeNull(types.string)),
    is_active: types.maybe(types.maybeNull(types.boolean)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    attachments: types.map(DocumentModel),
    isChangedDuringEdit: false,
  })
  .views((self) => {
    return {
      get attachmentsArray() {
        return Array.from(self.attachments.values());
      },
      get licenceAttachment() {
        return [...this.attachmentsArray]
          .reverse()
          .find((document) => !document.is_archived);
      },
    };
  })
  .actions((self) => {
    return {
      setIsChangedDuringEdit(status) {
        self.isChangedDuringEdit = status;
      },
      setLicenceNumber(licence_number) {
        this.setIsChangedDuringEdit(true);
        self.licence_number = licence_number;
      },
      setLicenceLocation(issued_in) {
        this.setIsChangedDuringEdit(true);
        self.issued_in = issued_in;
      },
      setLicenceExpiryDate(expiry_date) {
        this.setIsChangedDuringEdit(true);
        self.expiry_date = expiry_date;
      },
      setLicenceIssuedOn(issued_on) {
        this.setIsChangedDuringEdit(true);
        self.issued_on = issued_on;
      },
      setAttachments(items) {
        self.attachments.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.attachments.put({
            ...item,
          });
        });
      },
    };
  });
