import { rootInstance } from '../../state/RootModel';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getProbillTimeTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/time_types`, config);
};

export const getProbill = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/probills/${id}`, config);
};

export const addProbill = (probill, shipment_id = null) => {
  const config = getConfig();
  let scheduled_p_time_start = probill.pickupDateTimeStart;
  let scheduled_p_time_end = probill.pickupDateTimeEnd;
  if (probill.pickup_time_type === 'Appointment') {
    scheduled_p_time_end = null;
  } else if (probill.pickup_time_type === '24/7') {
    scheduled_p_time_end = null;
  } else if (probill.pickup_time_type === 'Not Assigned') {
    scheduled_p_time_end = null;
    scheduled_p_time_start = null;
  }
  let scheduled_d_time_start = probill.deliveryDateTimeStart;
  let scheduled_d_time_end = probill.deliveryDateTimeEnd;
  if (probill.delivery_time_type === 'Appointment') {
    scheduled_d_time_end = null;
  } else if (probill.delivery_time_type === '24/7') {
    scheduled_d_time_end = null;
  } else if (probill.delivery_time_type === 'Not Assigned') {
    scheduled_d_time_end = null;
    scheduled_d_time_start = null;
  }
  return axios.post(
    `${rootInstance.baseUrl}/shipments/${probill.probillShipmentId}/probills`,
    {
      pickup_location_id: probill.pickup_location_id,
      delivery_location_id: probill.delivery_location_id,
      scheduled_pickup_time_start: scheduled_p_time_start,
      scheduled_pickup_time_end: scheduled_p_time_end,
      pickup_time_type: probill.pickup_time_type,
      scheduled_delivery_time_start: scheduled_d_time_start,
      scheduled_delivery_time_end: scheduled_d_time_end,
      delivery_time_type: probill.delivery_time_type,
      description: probill.description,
      weight: probill.weight,
      weight_unit: probill.weight_unit,
      quantity: probill.quantity,
      quantity_unit: probill.quantity_unit,
      pickup_number: probill.pickup_number,
      delivery_number: probill.delivery_number,
      shipment_id: probill.probillShipmentId,
      pickup_type_time: probill.pickup_type_time,
    },
    config
  );
};

export const editProbill = (probill) => {
  const config = getConfig();
  let scheduled_p_time_start = probill.pickupDateTimeStart;
  let scheduled_p_time_end = probill.pickupDateTimeEnd;
  if (probill.pickup_time_type === 'Appointment') {
    scheduled_p_time_end = null;
  } else if (probill.pickup_time_type === '24/7') {
    scheduled_p_time_end = null;
  } else if (probill.pickup_time_type === 'Not Assigned') {
    scheduled_p_time_end = null;
    scheduled_p_time_start = null;
  }
  let scheduled_d_time_start = probill.deliveryDateTimeStart;
  let scheduled_d_time_end = probill.deliveryDateTimeEnd;
  if (probill.delivery_time_type === 'Appointment') {
    scheduled_d_time_end = null;
  } else if (probill.delivery_time_type === '24/7') {
    scheduled_d_time_end = null;
  } else if (probill.delivery_time_type === 'Not Assigned') {
    scheduled_d_time_end = null;
    scheduled_d_time_start = null;
  }
  return axios.put(
    `${rootInstance.baseUrl}/probills/${probill.probill_id}`,
    {
      pickup_location_id: probill.pickup_location_id,
      delivery_location_id: probill.delivery_location_id,
      scheduled_pickup_time_start: scheduled_p_time_start,
      scheduled_pickup_time_end: scheduled_p_time_end,
      pickup_time_type: probill.pickup_time_type,
      scheduled_delivery_time_start: scheduled_d_time_start,
      scheduled_delivery_time_end: scheduled_d_time_end,
      delivery_time_type: probill.delivery_time_type,
      description: probill.description,
      weight: probill.weight,
      weight_unit: probill.weight_unit,
      quantity: probill.quantity,
      quantity_unit: probill.quantity_unit,
      pickup_number: probill.pickup_number,
      delivery_number: probill.delivery_number,
      pickup_type_time: probill.pickup_type_time,
    },
    config
  );
};

export const deleteProbill = (probill_id) => {
  const config = getConfig();
  return axios.delete(`${rootInstance.baseUrl}/probills/${probill_id}`, config);
};

export const getCurrencyTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/currency_types`, config);
};
