import L from 'leaflet';

const pickupIcon = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/pickup_marker.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/pickup_marker.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(33, 41),
  // className: 'leaflet-div-icon'
});

const deliveryIcon = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/delivery_marker.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/delivery_marker.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(33, 41),
});

const stopIcon = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/stop_marker.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/stop_marker.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(33, 41),
});

const pointIcon = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/point_marker.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/point_marker.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(33, 41),
});

const trailerIcon = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/trailer_available.svg')
    .default,
  iconRetinaUrl: require('../../assets/images/dispatch/trailer_available.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(70, 70),
});
const trailerIconBlue = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/trailer-blue.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/trailer-blue.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(70, 70),
});
const truckIcon = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/truck_icon.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/truck_icon.svg').default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(70, 70),
});
const truckIconBlue = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/truck_available.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/truck_available.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(32, 32),
});
const yardIcon = new L.Icon({
  iconUrl: require('../../assets/images/dispatch/filters/yard.svg').default,
  iconRetinaUrl: require('../../assets/images/dispatch/filters/yard.svg')
    .default,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: new L.Point(28, 28),
});

export {
  pickupIcon,
  deliveryIcon,
  trailerIcon,
  truckIcon,
  yardIcon,
  trailerIconBlue,
  truckIconBlue,
  stopIcon,
  pointIcon,
};
