import {
  DELIVERY_DATE_END,
  DELIVERY_DATE_START,
  DELIVERY_LOCATION,
  NOT_AVAILABLE,
  PICKUP_DATE_END,
  PICKUP_DATE_START,
  PICKUP_LOCATION,
  STOP_NO,
} from '../../../constants';
import moment from 'moment';
import React from 'react';

export const PlannerProbillsTableColumns = (rootInstance) => ({
  '1': {
    title: STOP_NO,
    dataIndex: 'probill_number',
    key: 'probill_number',
    // defaultSortOrder: 'ascend',
    // sorter: (a, b) => a.probill_number.localeCompare(b.probill_number),
  },
  '2': {
    title: PICKUP_DATE_START,
    dataIndex: 'pickup_start_time',
    key: 'pickup_start_time',
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('MMM D, hh:mm A') : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '3': {
    title: PICKUP_DATE_END,
    dataIndex: 'pickup_end_time',
    key: 'pickup_end_time',
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('MMM D, hh:mm A') : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '4': {
    title: PICKUP_LOCATION,
    dataIndex: 'pickup_location',
    key: 'pickup_location',
    render: (text, record) => {
      return (
        <div>
          {record?.pickup_location?.location_name && (
            <p>{record?.pickup_location?.location_name}</p>
          )}
          {record?.pickup_location?.address && (
            <p>{record?.pickup_location?.address}</p>
          )}
          {record?.pickup_location?.city && (
            <p>{record?.pickup_location?.city}</p>
          )}
          {record?.pickup_location?.state && (
            <p>{record?.pickup_location?.state}</p>
          )}
        </div>
      );
    },
  },
  '5': {
    title: DELIVERY_DATE_START,
    dataIndex: 'delivery_start_time',
    key: 'delivery_start_time',
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('MMM D, hh:mm A') : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '6': {
    title: DELIVERY_DATE_END,
    dataIndex: 'delivery_end_time',
    key: 'delivery_end_time',
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('MMM D, hh:mm A') : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '7': {
    title: DELIVERY_LOCATION,
    dataIndex: 'delivery_location',
    key: 'delivery_location',
    render: (text, record) => {
      return (
        <div>
          {record?.delivery_location?.location_name && (
            <p>{record?.delivery_location?.location_name}</p>
          )}
          {record?.delivery_location?.address && (
            <p>{record?.delivery_location?.address}</p>
          )}
          {record?.delivery_location?.city && (
            <p>{record?.delivery_location?.city}</p>
          )}
          {record?.delivery_location?.state && (
            <p>{record?.delivery_location?.state}</p>
          )}
        </div>
      );
    },
  },
});
