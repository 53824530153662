import { types, getRoot } from 'mobx-state-tree';
import { concatenateArrayMembersNames } from '../../utils/methods';
import { DocumentModel } from '../DocumentModel';
import { rootInstance } from '../RootModel';
import { AbsenceModel } from './AbsenceModel';
import { documentsColumnsFunc } from '../DocumentationTableColumns';
import { DOCUMENT_TYPE_EMPLOYEE_IMAGE } from '../../constants/documents';
import { STATUS_AVAILABLE } from '../../constants/workingStatuses';
import moment from 'moment';

export const EmployeeViewModel = types
  .model('EmployeeViewModel', {
    user_id: types.identifierNumber,
    account_status: types.maybe(types.maybeNull(types.string)),
    code_name: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    current_absence_id: types.maybe(types.maybeNull(types.number)),
    email: types.maybe(types.maybeNull(types.string)),
    first_name: types.maybe(types.maybeNull(types.string)),
    is_regular_working_status: types.maybe(types.maybeNull(types.boolean)),
    last_name: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    date_of_birth: types.maybe(types.maybeNull(types.string)),
    date_hired: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    social_security_number: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    street_address: types.maybe(types.maybeNull(types.string)),
    user_assigned_roles: types.frozen([]),
    user_department: types.maybe(types.maybeNull(types.number)),
    user_organization_id: types.maybe(types.maybeNull(types.number)),
    documents: types.map(DocumentModel),
    currentAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),
    viewedAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),
    employeeFullyFetched: false,
    absences: types.map(AbsenceModel),

    isGeneralInfoSectionOpen: true,
    isDocumentationSectionOpen: true,
    isWorkingStatusSectionOpen: true,
  })
  .views((self) => {
    return {
      get fullName() {
        return `${self.first_name} ${self.last_name}`;
      },
      get employeeWorkingStatus() {
        const unavailableStatus =
          rootInstance.employeesPageState.availableAbsencesTypes.find(
            (status) => status === self.currentAbsence?.status
          );
        if (unavailableStatus) {
          return unavailableStatus;
        } else {
          return STATUS_AVAILABLE;
        }
      },
      get isEmployeeOff() {
        return !!this.current_absence_id;
      },
      get documentsArray() {
        return Array.from(self.documents.values());
      },
      get employeePhoto() {
        return this.documentsArray.find(
          (document) =>
            document.document_type.user_document_type_name ===
            DOCUMENT_TYPE_EMPLOYEE_IMAGE
        );
      },
      get allUserRolesString() {
        const availableRoles =
          rootInstance.employeesPageState.employeeRolesArray;
        const roles = [];
        self.user_assigned_roles.forEach((id) => {
          availableRoles.forEach((role) => {
            if (role.role_id === id) {
              role.name = role.role_name;
              roles.push(role);
            }
          });
        });
        const concatenatedRoles = concatenateArrayMembersNames(roles);
        return concatenatedRoles;
      },
      get userCurrentDepartment() {
        const departmentsArray =
          rootInstance.employeesPageState.departmentsArray;
        const userDepartment = departmentsArray.find(
          (department) => department.department_id === self.user_department
        );
        return userDepartment?.department_name;
      },
      get absencesArray() {
        return Array.from(self.absences.values());
      },
    };
  })
  .actions((self) => {
    return {
      setIsWorkingStatusSectionOpen(status) {
        self.isWorkingStatusSectionOpen = status;
      },
      setIsGeneralInfoSectionOpen(status) {
        self.isGeneralInfoSectionOpen = status;
      },
      setIsDocumentationSectionOpen(status) {
        self.isDocumentationSectionOpen = status;
      },
      setEmployeeFullyFetched(status) {
        self.employeeFullyFetched = status;
      },
      setDocuments(items) {
        self.documents.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.documents.put({
            ...item,
          });
        });
      },
      getDocumentsColumns() {
        return Object.values(documentsColumnsFunc(getRoot(self)));
      },
      setCurrentAbsence(absence_id) {
        self.currentAbsence = absence_id;
      },
      setAbsences(items) {
        const endOfDay = '23:59:59';
        items.forEach((item) => {
          item.start = moment(item.starting_date).toDate();
          item.end = moment(item.end_date + ' ' + endOfDay).toDate();
          item.title = item.status.toUpperCase();
          self.absences.put({
            ...item,
          });
        });
      },
      setViewedAbsence(absence_id) {
        self.viewedAbsence = absence_id;
      },
    };
  });
