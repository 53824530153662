import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Input } from 'antd';
import ErrorBox from '../../../ErrorBox';
import { useMst } from '../../../../state/RootModel';
import addFileIcon from '../../../../assets/images/add-file.svg';
import FormPageTitle from '../../../FormPageTitle';
import {
  ADDRESS,
  ADD_CUSTOMER,
  CITY,
  COUNTRY,
  CUSTOMER_INFORMATION,
  CUSTOMER_NAME,
  EMAIL,
  LOCATION_INFORMATION,
  PHONE_NUMBER,
  POSTAL_CODE,
  STATE,
  OTHER,
  BILLING_EMAIL,
  NOTES,
} from '../../../../constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { Select } from 'antd';
import { MC } from '../../../../constants/organizations/organizations';

const { Option } = Select;
const AddCustomerModal = () => {
  const {
    clientsPageState: {
      newCustomer,
      setNewCustomer,
      countriesArray,
      filteredStates,
    },
  } = useMst();

  const onSaveChanges = () => {
    newCustomer.saveCustomer();
  };

  const onCancel = () => {
    setNewCustomer(null);
  };

  const onCountryChange = (country) => {
    newCustomer.setState('');
    newCustomer.setCountry(country);
  };

  const onStateChange = (state) => {
    newCustomer.setState(state);
  };

  return (
    <div>
      <Modal
        visible={!!newCustomer}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={700}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addFileIcon} title={ADD_CUSTOMER} />
          </div>

          <div className="form-page-subsection">
            <div className="subsection-header mt0">
              <p>{CUSTOMER_INFORMATION}</p>
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{CUSTOMER_NAME}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setCompany(e.target.value)}
                value={newCustomer.company}
              />
            </div>

            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{EMAIL}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setEmail(e.target.value)}
                value={newCustomer.email}
              />
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{PHONE_NUMBER}</span>
                <span className="color-secondary">*</span>
              </div>
              <PhoneInput
                placeholder="e.g. 712338645"
                value={newCustomer.phone_number}
                onChange={(value) => newCustomer.setPhoneNumber(value)}
                maxLength={15}
                displayInitialValueAsLocalNumber
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{BILLING_EMAIL}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setBillingEmail(e.target.value)}
                value={newCustomer.billing_email}
              />
            </div>
          </div>
          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{MC}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setMc(e.target.value)}
                value={newCustomer.pib}
              />
            </div>
          </div>
          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper w100">
              <div className="input-label justify-between">
                <span>{NOTES}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setNote(e.target.value)}
                value={newCustomer.notes}
              />
            </div>
          </div>

          <div className="form-page-subsection mt20">
            <div className="subsection-header mt0">
              <p>{LOCATION_INFORMATION}</p>
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper w20">
              <div className="input-label justify-between">
                <span>{POSTAL_CODE}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setPostalCode(e.target.value)}
                value={newCustomer.postal_code}
              />
            </div>

            <div className="input-wrapper w35">
              <div className="input-label justify-between">
                <span>{COUNTRY}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className={'w100'}
                onChange={(country) => onCountryChange(country)}
              >
                {countriesArray.map((country, index) => (
                  <Option key={index} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="input-wrapper w35 has-select-disabled">
              <div className="input-label justify-between">
                <span>{STATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className={'w100'}
                onChange={(state) => onStateChange(state)}
                value={newCustomer.state}
              >
                {filteredStates &&
                  filteredStates.states.map((state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  ))}
                <Option key={'other'} value={'Other'}>
                  {OTHER}
                </Option>
              </Select>
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{CITY}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setCity(e.target.value)}
                value={newCustomer.city}
              />
            </div>

            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ADDRESS}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => newCustomer.setAddress(e.target.value)}
                value={newCustomer.address}
              />
            </div>
          </div>

          <ErrorBox obj={newCustomer} />

          <button className="btn primary" onClick={onSaveChanges}>
            {ADD_CUSTOMER}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddCustomerModal);
