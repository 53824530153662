import { rootInstance } from '../../state/RootModel';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getNotifications = (notificationType = 'driver_notifications') => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/${notificationType}`, config);
};

export const markNotificationAsResolved = (
  notificationType = 'driver_notifications',
  notification
) => {
  const config = getConfig();

  const notification_id =
    notificationType === 'driver_notifications'
      ? notification?.driver_notification_id
      : notification?.vehicle_notification_id;

  return axios.put(
    `${rootInstance.baseUrl}/${notificationType}/${notification_id}`,
    { ...notification, is_resolved: true },
    config
  );
};
