import React from 'react';
import { observer } from 'mobx-react';
import DispatchMap from '../../components/dispatch/DispatchMap';
import { useMst } from '../../state/RootModel';
import DispatchHeader from '../../components/dispatch/DispatchHeader';
import DispatchMapOrganizeTour from '../../components/dispatch/common/DispatchMapOrganizeTour';
import OrganizeTourShipmentsModal from '../../components/dispatch/shipments/OrganizeTourShipmentsModal';
import ConfirmRemoveRouteAction from '../../components/dispatch/common/ConfirmRemoveRouteAction';
import OrganizeTourCustomersListModal from '../../components/dispatch/customers/OrganizeTourCustomersListModal';
import OrganizeTourDriverModal from '../../components/dispatch/drivers/OrganizeTourDriverModal';
import OrganizeTourTeamDriverModal from '../../components/dispatch/drivers/OrganizeTourTeamDriverModal';
import OrganizeTourTrailerModal from '../../components/dispatch/trailers/OrganizeTourTrailerModal';
import DispatchAssetsList from '../../components/dispatch/assets/DispatchAssetsList';
import OrganizeTourTrucksModal from '../../components/dispatch/trucks/OrganizeTourTrucksModal';
import ShipmentList from '../../components/dispatch/shipment_list/ShipmentList';
import RouteActionsList from '../../components/dispatch/routeActionList/RouteActionsList';
import DispatchRouteActionItemsWrapper from '../../components/dispatch/routeAction/DispatchRouteActionItems';

const DispatchPage = () => {
  const {
    dispatchPageState: {
      isShipmentsSidebarShown,
      isAssetsListOpened,
      isPlannerOpened,
      isRouteActionListOpened,
    },
  } = useMst();

  return (
    <div className="page">
      <div className="page-content" style={{ padding: 0 }}>
        <DispatchHeader />
        <DispatchRouteActionItemsWrapper />
        {isPlannerOpened && <ShipmentList />}
        {isAssetsListOpened && <DispatchAssetsList />}
        {isRouteActionListOpened && <RouteActionsList />}
        <DispatchMap />
        {/*<DispatchFooter />*/}
        <DispatchMapOrganizeTour />
        <OrganizeTourShipmentsModal />
        <OrganizeTourCustomersListModal />
        <OrganizeTourDriverModal />
        <OrganizeTourTeamDriverModal />
        <OrganizeTourTrailerModal />
        <OrganizeTourTrucksModal />
        {isShipmentsSidebarShown && <ShipmentList />}

        <ConfirmRemoveRouteAction />
      </div>
    </div>
  );
};

export default observer(DispatchPage);
