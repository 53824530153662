import React, { useState } from 'react';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async';
import { Checkbox, Input } from 'antd';
import {
  SHIPMENT_DETAILS,
  START_TYPING_PLACEHOLDER_TEXT,
  CUSTOMER,
  BILL_TO,
  PO_NUMBER,
  BROKER_NAME,
  PHONE_NUMBER,
  BROKERAGE_SHIPMENT,
} from '../../../constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useMst } from '../../../state/RootModel';
import ClientDetails from '../ClientDetails';

const debounce = require('lodash.debounce');

const ShipmentDetails = () => {
  const {
    shipmentsPageState: { shipmentBeingCreated },
    clientsPageState: { getFilteredClients, filteredClients },
  } = useMst();

  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedBillTo, setSelectedBillTo] = useState();

  const loadSuggestions = debounce(getFilteredClients, 300);

  const onSelectCustomer = (client) => {
    shipmentBeingCreated.setShipmentBy(client.client_id);
    const customer = filteredClients.find(
      (el) => el.client_id === client.client_id
    );
    setSelectedCustomer(customer);

    shipmentBeingCreated.setBillTo(client.client_id);
    setSelectedBillTo(customer);
  };

  const onSelectBillTo = (client) => {
    shipmentBeingCreated.setBillTo(client.client_id);
    const customer = filteredClients.find(
      (el) => el.client_id === client.client_id
    );
    setSelectedBillTo(customer);
  };

  const onBrokerageShipmentChange = (e) => {
    shipmentBeingCreated.setIsBrokerageShipment(e.target.checked);
  };

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header align-center">
        <p>{SHIPMENT_DETAILS}</p>
        <Checkbox onChange={onBrokerageShipmentChange} className="ml20">
          {BROKERAGE_SHIPMENT}
        </Checkbox>
      </div>
      <div className="subsection-content flex-alignitems-flex-start">
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CUSTOMER}</span>
            <span className="color-secondary">*</span>
          </div>
          <AsyncSelect
            loadOptions={loadSuggestions}
            onChange={onSelectCustomer}
            placeholder={START_TYPING_PLACEHOLDER_TEXT}
          />
          {!!selectedCustomer && <ClientDetails client={selectedCustomer} />}
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{BILL_TO}</span>
            <span className="color-secondary">*</span>
          </div>
          <AsyncSelect
            value={selectedBillTo ? selectedBillTo : undefined}
            loadOptions={loadSuggestions}
            onChange={onSelectBillTo}
            placeholder={START_TYPING_PLACEHOLDER_TEXT}
          />
          {!!selectedBillTo && <ClientDetails client={selectedBillTo} />}
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{PO_NUMBER}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => shipmentBeingCreated.setPioNumber(e.target.value)}
            value={shipmentBeingCreated.pio_number}
          />
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{BROKER_NAME}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => shipmentBeingCreated.setBrokerName(e.target.value)}
            value={shipmentBeingCreated.broker_name}
          />
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{PHONE_NUMBER}</span>
          </div>
          <PhoneInput
            placeholder="e.g. 712338645"
            value={shipmentBeingCreated.broker_phone}
            onChange={(value) => shipmentBeingCreated.setBrokerPhone(value)}
            maxLength={15}
            displayInitialValueAsLocalNumber
            className="phone-input"
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ShipmentDetails);
