import React from 'react';
import {
  DELETE_RECORD_TEXT,
  EDIT_RECORD_TEXT,
  PHONE_NUMBER,
  CARGO,
  CARRIER_COMPANY,
  CARRIER_ADDRESS_CITY_STATE,
  OVERALL_DRIVERS_SCORE,
  INSURANCE_EXPIRES,
} from '../../constants';
import trash from '../../assets/images/trash.svg';
import edit from '../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.carriersPageState.setCarrierToBeDeleted(record.carrier_id);
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.carriersPageState.getEditedCarrier(record.carrier_id);
};

export const carriersColumnsFunc = (rootInstance) => ({
  '1': {
    title: CARRIER_COMPANY,
    dataIndex: 'company_name',
    key: 'company_name',
  },
  '2': {
    title: CARRIER_ADDRESS_CITY_STATE,
    key: 'address',
    dataIndex: 'address',
    render: (text, record) => {
      return (
        <span>
          {record.address}, {record.city}, {record.state}
        </span>
      );
    },
  },
  '3': {
    title: PHONE_NUMBER,
    key: 'phone_number',
    dataIndex: 'phone_number',
  },
  '4': {
    title: OVERALL_DRIVERS_SCORE,
    key: 'score',
    dataIndex: 'score',
  },
  '5': {
    title: INSURANCE_EXPIRES,
    key: 'insurance_expiry_date',
    dataIndex: 'insurance_expiry_date',
  },
  '6': {
    title: CARGO,
    key: 'insurance_cargo_amount',
    dataIndex: 'insurance_cargo_amount',
  },
  '7': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          {rootInstance.userState.canRemoveCarriers && (
            <Tooltip title={DELETE_RECORD_TEXT}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          )}
          {rootInstance.userState.canEditCarriers && (
            <div onClick={(e) => onClickEdit(e, record)}>
              <Tooltip title={EDIT_RECORD_TEXT}>
                <img src={edit} alt="edit" className="pointer ph10" />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
  },
});
