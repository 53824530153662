import { types } from 'mobx-state-tree';
import {
  createRole,
  createRolePermissions,
} from '../../../actions/organization';
import {
  USER_ROLE_NAME_ERROR,
  USER_ROLE_NAME_REQUIRED,
} from '../../../constants/organization/userRole';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { notification } from 'antd';
import { rootInstance } from '../../RootModel';

export const UserRoleOrganizationLightModel = types
  .model('UserRoleOrganizationLightModel', {
    role_name: types.maybe(types.maybeNull(types.string)),
    permissions: types.frozen([]),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setRoleName(value) {
        self.role_name = value;
      },
      setPermissionsList(permissions) {
        self.permissions = permissions;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      validateForm() {
        if (!self.role_name) {
          this.setErrorText(USER_ROLE_NAME_REQUIRED);
        } else if (
          self.role_name.toLocaleLowerCase().includes('admin') ||
          self.role_name.toLocaleLowerCase().includes('super')
        ) {
          this.setErrorText(USER_ROLE_NAME_ERROR);
        } else if (self.permissions.length === 0) {
          this.setErrorText('Please select at least one permission');
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      createUserRole() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          createRole(self.role_name)
            .then((response) => {
              let role_id = response.data.role_id;
              createRolePermissions(role_id, self.permissions)
                .then((response) => {
                  rootInstance.currentOrganization.setIsUserRoleSuccessModalOpen(
                    true
                  );
                  setFetchingInProgress(false);
                })
                .catch((error) => {
                  setFetchingInProgress(false);
                  console.log('Error: ', error);
                  const errorText = getErrorText(error);
                  notification.error({
                    message: 'Error',
                    description: errorText,
                  });
                });
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
    };
  });
