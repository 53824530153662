import React from 'react';
import { observer } from 'mobx-react';
import AddUserRoleComponent from '../../components/organization/AddUserRole/AddUserRoleComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import { ADD_USER_ROLE } from '../../constants/organization/userRole';
import organization from '../../assets/images/organization-black.svg';
import { ORGANIZATION } from '../../constants/menu';
import { rootInstance, useMst } from '../../state/RootModel';
import { EDIT_ORGANIZATION } from '../../constants/organizations/organizations';

const AddUserRolePage = () => {
  const {
    currentOrganization,
    userState: { canEditOrganization },
  } = useMst();

  const onOrganizationEdit = () => {
    const organization_id = JSON.parse(
      localStorage.getItem('user')
    ).user_organization_id;
    const params = { organizationId: organization_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.EditOrganization.name),
      params
    );
  };

  return (
    <div>
      <div className="page add-employee-page">
        <BreadcrumsComponent
          page={`${ADD_USER_ROLE}`}
          breadcrumbsBackLink={PageRoutes.Organization.name}
        />
        <div className="page-content ">
          <div className="page-header-section">
            <div className="table-header mb20 mt20 align-center">
              <div>
                <img src={organization} alt="Organization" />
                <span className="ml10 color-title">{ORGANIZATION}</span>
              </div>
              <h1 className="text-capitalize text-bold color-primary ml10">
                {currentOrganization.organization_name}
              </h1>
              {canEditOrganization && (
                <button
                  type="button"
                  className="btn light ml-a"
                  onClick={onOrganizationEdit}
                >
                  {EDIT_ORGANIZATION}
                </button>
              )}
            </div>
          </div>
          <AddUserRoleComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(AddUserRolePage);
