import { types } from 'mobx-state-tree';
import {
  editRouteAction,
  getRouteActionTrailers,
  getRouteActionTrucks,
  getShipmentRouteActions,
  getTrackingShipmentData,
  updateRouteActionStatus,
} from '../../../actions/dispatch';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';
import {
  ACTION_REQUIRED_MESSAGE,
  DRIVER_REQUIRED_MESSAGE,
  PLANNED_START_DATE_REQUIRED_MESSAGE,
  PLANNED_START_TIME_REQUIRED_MESSAGE,
} from '../../../constants/dispatch';
import moment from 'moment';
import { notification } from 'antd';
import {
  ERROR_GETTING_ROUTE_ACTIONS,
  ERROR_GETTING_SHIPMENT_DATA,
  ERROR_SAVING_ROUTE_ACTION,
} from '../../../constants/dispatch/errors';
import { getShipment } from '../../../actions/shipments';

export const DispatchTripModel = types
  .model('DispatchTripModel', {
    route_action_id: types.identifierNumber,
    action: types.frozen([]),
    customer: types.frozen([]),
    driver: types.frozen([]),
    eta: types.maybe(types.maybeNull(types.string)),
    location: types.frozen([]),
    miles: types.maybe(types.maybeNull(types.number)),
    notes: types.maybe(types.maybeNull(types.string)),
    shipment: types.frozen([]),
    planned_start: types.maybe(types.maybeNull(types.string)),
    route_action_type: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    trailer: types.frozen([]),
    truck: types.frozen([]),
    tripDetailsExpanded: false,
    actionRouteStatus: '',
    color: types.maybe(types.maybeNull(types.string)),

    /** Data **/
    action_id: types.maybe(types.maybeNull(types.number)),
    shipment_id: types.maybe(types.maybeNull(types.number)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    team_driver_id: types.maybe(types.maybeNull(types.number)),
    start_location: types.maybe(types.maybeNull(types.string)),
    selected_location_id: types.maybe(types.maybeNull(types.number)),
    trailer_id: types.maybe(types.maybeNull(types.number)),
    truck_id: types.maybe(types.maybeNull(types.number)),
    start_date: types.maybe(types.maybeNull(types.string)),
    start_time: types.maybe(types.maybeNull(types.string)),
    start_date_and_time: types.maybe(types.maybeNull(types.string)),
    end_date_and_time: types.maybe(types.maybeNull(types.string)),
    isLocalDispatchSelected: false,
    isHighwayDispatchSelected: false,
    bill_to: types.maybe(types.maybeNull(types.number)),
    errorText: types.maybe(types.maybeNull(types.string)),
    selectedActionType: '',
    locationsList: types.frozen([]),
    updatedLocationsList: types.frozen([]),
    isLocationListChanged: false,
    enableAddingLocation: false,
    locationsLoaded: false,
    locations: types.frozen([]),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get getSelectedLocationsList() {
        return Array.from(self.locationsList.values());
      },
    };
  })
  .actions((self) => {
    return {
      setLocationsLoaded(value) {
        self.locationsLoaded = value;
      },
      setEnableAddingLocation(value) {
        self.enableAddingLocation = value;
      },
      setIsLocationListChanged(value) {
        self.isLocationListChanged = value;
      },
      setUpdatedLocationsList(locations) {
        self.updatedLocationsList = locations;
      },
      setSelectedLocations(locations) {
        self.locationsList = locations;
        this.setLocationsLoaded(true);
      },
      setLocationByActionType(action_type) {
        self.selectedActionType = action_type;
        switch (action_type) {
          case 'Hook':
            // Get trailer and set proper location
            if (self.trailer_id) {
              setFetchingInProgress(true);
              getRouteActionTrailers(1, '', '', self.shipment_id, 'All', 1000)
                .then((response) => {
                  let trailers = response.data.trailers;
                  if (trailers.length > 0) {
                    let selected_trailer = trailers.filter(
                      (trailer) => trailer.vehicle_id === self.trailer_id
                    );
                    if (selected_trailer[0].location_name) {
                      let selected_location =
                        rootInstance.dispatchPageState.getAllAvailableLocations.filter(
                          (location) =>
                            location.location_name ===
                            selected_trailer[0]?.location_name
                        );
                      this.setSelectedLocationID(
                        selected_location[0].location_id
                      );
                    }
                  }
                  setFetchingInProgress(false);
                })
                .catch((error) => {
                  console.log('Error: ', error);
                  setFetchingInProgress(false);
                });
            }

            break;
          case 'Drop':
            // Location will be pentagon yard
            this.clearSelectedLocation();
            break;
          case 'Delivery':
            // Location will be set to shipment delivery_location
            this.clearSelectedLocation();
            if (
              self.shipment_id &&
              rootInstance.dispatchPageState.shipmentDeliveryLocations.length >
                0
            ) {
              this.setSelectedLocationID(
                rootInstance.dispatchPageState.shipmentDeliveryLocations[0]
                  .location_id
              );
            }
            break;
          case 'Pickup':
            // Location will be set to shipment pickup_location
            this.clearSelectedLocation();
            if (
              self.shipment_id &&
              rootInstance.dispatchPageState.shipmentPickupLocations.length > 0
            ) {
              this.setSelectedLocationID(
                rootInstance.dispatchPageState.shipmentPickupLocations[0]
                  .location_id
              );
            }
            break;
          case 'Acquire':
            // Get truck and set proper location
            if (self.truck) {
              let selected_truck =
                rootInstance.dispatchPageState.getOrganizeTourTrucks.filter(
                  (truck) => truck.vehicle_id === self.truck
                );
              if (selected_truck[0].location_name) {
                let selected_location =
                  rootInstance.dispatchPageState.getAllAvailableLocations.filter(
                    (location) =>
                      location.location_name ===
                      selected_truck[0]?.location_name
                  );
                this.setSelectedLocationID(selected_location[0].location_id);
              }
            }

            break;
        }
      },
      clearSelectedLocation() {
        self.selected_location_id = null;
      },
      setStatus(value) {
        self.status = value;
      },
      setActionID(id) {
        self.action_id = id;
      },
      setTeamDriver(team_driver_id) {
        self.team_driver_id = team_driver_id;
      },
      setCustomerToBill(customer_id) {
        self.bill_to = customer_id;
      },
      setNote(value) {
        self.notes = value;
      },
      setTripDetailsExpanded(value) {
        self.tripDetailsExpanded = value;
      },
      setSelectedDriver(id) {
        self.driver_id = parseInt(id);
        const drivers = rootInstance.dispatchPageState.getOrganizeTourDrivers;
        let found = false;
        drivers.map((driver) => {
          if (found) {
            return;
          }
          if (driver?.driver_linked_truck_data) {
            if (driver.driver_id === self.driver_id) {
              setFetchingInProgress(true);
              getRouteActionTrucks(
                driver?.driver_linked_truck_data?.internal_company_id,
                1,
                1000
              )
                .then((response) => {
                  rootInstance.dispatchPageState.setOrganizeTourTrucks(
                    response.data.trucks
                  );
                  setFetchingInProgress(false);
                })
                .catch((error) => {
                  console.log('error: ', error);
                  setFetchingInProgress(false);
                });
              this.changeSelectedTruck(
                driver?.driver_linked_truck_data.vehicle_id
              );
              found = true;
            }
          } else {
            rootInstance.dispatchPageState.setTruckToShowOnMap(null);
          }
        });
      },
      changeSelectedTruck(truck_id) {
        self.truck_id = truck_id;
      },
      changeSelectedTrailer(trailer_id) {
        self.trailer_id = parseInt(trailer_id);
        rootInstance.dispatchPageState.setEditingTrailerToShowOnMap(trailer_id);
      },
      setIsLocalDispatchSelected(value) {
        self.isLocalDispatchSelected = value;
      },
      setIsHighwayDispatchSelected(value) {
        self.isHighwayDispatchSelected = value;
      },
      getShipmentDetails(shipment_id) {
        this.setLocationsLoaded(false);
        setFetchingInProgress(true);
        getShipment(shipment_id)
          .then((response) => {
            let routeActionsLocations = [];
            if (response.data?.locations) {
              if (response.data.locations?.pickup_locations) {
                response.data.locations?.pickup_locations.forEach(
                  (location) => {
                    routeActionsLocations.push(location);
                  }
                );
              }
              if (response.data.locations?.delivery_locations) {
                response.data.locations?.delivery_locations.forEach(
                  (location) => {
                    routeActionsLocations.push(location);
                  }
                );
              }
            }
            this.setSelectedLocations(routeActionsLocations);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('error: ', error);
            setFetchingInProgress(false);
          });
      },
      setSelectedShipmentID(value) {
        self.shipment_id = value;
        // this.getShipmentDetails(value);
      },
      setStartLocation(value) {
        self.start_location = value;
      },
      setSelectedLocationID(value) {
        self.selected_location_id = value;
        let selectedLocationName =
          rootInstance.dispatchPageState.getAllAvailableLocations.filter(
            (location) => location.location_id === value
          );
        this.setStartLocation(selectedLocationName[0].location_name);
      },
      setStartDate(date) {
        self.start_date = date;
      },
      setStartTime(time) {
        self.start_time = time;
      },
      setActionRuteStatus(value) {
        setFetchingInProgress(true);
        self.actionRouteStatus = value;
        rootInstance.dispatchPageState.clearTrackingShipment();
        updateRouteActionStatus(self.route_action_id, self.actionRouteStatus)
          .then((response) => {
            getTrackingShipmentData(
              rootInstance.dispatchPageState.selectedShipmentDetails.shipment_id
            )
              .then((response) => {
                rootInstance.dispatchPageState.setTrackingShipment(
                  response.data
                );
                rootInstance.dispatchPageState.setPlannerOpened(false);
                this.setStatus(value);
                setFetchingInProgress(false);
              })
              .catch((error) => {
                console.log('Error: ', error);
                const errorText = getErrorText(error);
                notification.error({
                  message: ERROR_GETTING_SHIPMENT_DATA,
                  description: errorText,
                });
                setFetchingInProgress(false);
              });
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(false);
          });
      },
      setErrorText(text) {
        self.errorText = text;
      },
      validateForm() {
        if (!self.driver_id) {
          this.setErrorText(DRIVER_REQUIRED_MESSAGE);
        } else if (!self.action_id) {
          this.setErrorText(ACTION_REQUIRED_MESSAGE);
        } else if (!self.start_date) {
          this.setErrorText(PLANNED_START_DATE_REQUIRED_MESSAGE);
        } else if (!self.start_time) {
          this.setErrorText(PLANNED_START_TIME_REQUIRED_MESSAGE);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      editRouteAction() {
        if (this.validateForm()) {
          rootInstance.dispatchPageState.clearTrackingShipment();
          let selectedLocationsList = [];
          /** Solve ordering issue **/
          if (self.isLocationListChanged) {
            self.updatedLocationsList.forEach((location) =>
              selectedLocationsList.push(location.location_id)
            );
          } else {
            self.locationsList.forEach((location) => {
              selectedLocationsList.push(location.location_id);
            });
          }
          let time = moment
            .utc(`${self.start_date} ${self.start_time}`)
            .format();
          let data = {
            action_id: self.action_id, //Required
            driver_id: self.driver_id, //Required
            planned_start: time, //Required
            shipment_id: self.shipment_id,
            team_driver_id: self.team_driver_id,
            trailer_id: self.trailer_id,
            truck_id: self.truck_id,
            notes: self.notes,
            bill_to: self.bill_to,
            locations: selectedLocationsList,
          };
          setFetchingInProgress(true);
          editRouteAction(self.route_action_id, data)
            .then((response) => {
              rootInstance.dispatchPageState.setIsOrganizeTourSuccessModalOpen(
                true
              );
              rootInstance.dispatchPageState.removeEditingRouteItem();
              rootInstance.dispatchPageState.onViewShipmentActions(
                self.shipment_id
              );
              getShipmentRouteActions(self.shipment_id)
                .then((response) => {
                  rootInstance.dispatchPageState.settingSavedRouteActionItems(
                    response.data
                  );
                  rootInstance.dispatchPageState.removeEditingRouteItem();
                  getTrackingShipmentData(
                    rootInstance.dispatchPageState.selectedShipmentDetails
                      .shipment_id
                  )
                    .then((response) => {
                      rootInstance.dispatchPageState.setTrackingShipment(
                        response.data
                      );
                      rootInstance.dispatchPageState.setPlannerOpened(false);

                      setFetchingInProgress(false);
                    })
                    .catch((error) => {
                      console.log('Error: ', error);
                      const errorText = getErrorText(error);
                      notification.error({
                        message: ERROR_GETTING_SHIPMENT_DATA,
                        description: errorText,
                      });
                      setFetchingInProgress(false);
                    });
                })
                .catch((error) => {
                  console.log('Error: ', error);
                  const errorText = getErrorText(error);
                  notification.error({
                    message: ERROR_GETTING_ROUTE_ACTIONS,
                    description: errorText,
                  });
                  setFetchingInProgress(false);
                });
            })
            .catch((error) => {
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: ERROR_SAVING_ROUTE_ACTION,
                description: errorText,
              });
              setFetchingInProgress(false);
            });
        }
      },
    };
  });
