import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Modal } from 'antd';
import {
  CONFIRM,
  CANCEL,
  CONFIRM_DELETE_MODAL_TEXT,
  TRAILER,
} from '../../constants';

const ConfirmDeleteTrailerModal = () => {
  const {
    fleetPageState: {
      confirmDeleteTrailerModalOpen,
      setConfirmDeleteTrailerModalOpen,
      trailerToBeDeleted,
      deleteTrailer,
    },
  } = useMst();

  const onCancel = () => {
    setConfirmDeleteTrailerModalOpen(false);
  };

  const onConfirm = () => {
    deleteTrailer();
  };

  return (
    trailerToBeDeleted && (
      <div>
        <Modal
          visible={confirmDeleteTrailerModalOpen}
          footer={null}
          centered={true}
          closable={false}
          onCancel={() => {}}
          width={400}
          className="confirm-delete-modal"
        >
          <div className="p32">
            <div>
              <p>
                {CONFIRM_DELETE_MODAL_TEXT} {TRAILER.toLowerCase()}{' '}
                {trailerToBeDeleted.trailer_type} {trailerToBeDeleted.vin}?
              </p>
            </div>
            <div className="justify-end mt50">
              <button className="btn white mr10" onClick={onCancel}>
                {CANCEL}
              </button>
              <button className="btn primary" onClick={onConfirm}>
                {CONFIRM}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(ConfirmDeleteTrailerModal);
