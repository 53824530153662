import React from 'react';
import { observer } from 'mobx-react';
import {
  DELAYED_SHIPMENTS,
  ON_TIME_SHIPMENTS,
  SHIPMENT_STATUS,
  PENDING_ASSIGNEMENT,
  SCHEDULED_SHIPMENTS,
} from '../../../../constants';
import ChartComponent from '../../../ChartComponent';

const ShipmentStatusComponent = () => {
  return (
    <div className="w60 pr10">
      <div className="diagrams-section-chart shipment-status-box pr20">
        <h3>{SHIPMENT_STATUS}</h3>
        <div className="charts-wrapper flex-alignitems-flex-start justify-between">
          <ChartComponent
            value={25}
            innerCircleText={23}
            pathColor={'#ED4C5C'}
            title={PENDING_ASSIGNEMENT}
          />
          <ChartComponent
            value={32}
            s
            innerCircleText={223}
            pathColor={'#33C141'}
            title={ON_TIME_SHIPMENTS}
          />
          <ChartComponent
            value={44}
            innerCircleText={150}
            pathColor={'#F59233'}
            title={DELAYED_SHIPMENTS}
          />
          <ChartComponent
            value={78}
            innerCircleText={32}
            pathColor={'#008BEA'}
            title={SCHEDULED_SHIPMENTS}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ShipmentStatusComponent);
