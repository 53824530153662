import { types } from 'mobx-state-tree';

export const CurrentLocationModel = types
  .model('CurrentLocationModel', {
    vehicle_id: types.maybe(types.maybeNull(types.number)),
    internal_vehicle_id: types.maybe(types.maybeNull(types.string)),
    location_description: types.maybe(types.maybeNull(types.string)),
    long: types.maybe(types.maybeNull(types.number)),
    lat: types.maybe(types.maybeNull(types.number)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
