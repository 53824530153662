import { Tooltip } from 'antd';
import { NONAME } from 'dns';
import React from 'react';
import {
  COMPENSATION_DEDUCTION_DELETE,
  COMPENSATION_DEDUCTION_EDIT,
  COMPENSATION_DEDUCTION_NAME,
  COMPENSATION_VALUE_BASED_ON,
  COMPENSATION_VALUE_CURRENCY,
  COMPENSATION_VALUE_DEFAULT_VALUE,
  COMPENSATION_VALUE_DELETE,
  COMPENSATION_VALUE_EDIT,
  COMPENSATION_VALUE_NAME,
  COMPENSATION_VALUE_RATE,
  DEDUCTIONS,
  DEDUCTIONS_DELETE,
  DEDUCTIONS_EDIT,
  VALUES_DELETE,
  VALUES_EDIT,
} from '../../../constants/payroll';
import trash from '../../../assets/images/trash.svg';
import edit from '../../../assets/images/edit-equipment.svg';
import { rootInstance } from '../../RootModel';

const onClickDeleteValue = (e, index) => {
  e.stopPropagation();
  // rootInstance.payrollPageState.setIsDeleteValueModalOpen(true);
  rootInstance.payrollPageState.deleteValueFromEditedCompensationValuesList(
    index
  );
};

const onClickDeleteDeduction = (e, index) => {
  e.stopPropagation();
  // rootInstance.payrollPageState.setIsDeleteValueModalOpen(true);
  rootInstance.payrollPageState.deleteDeductionFromEditedCompensationDeductionsList(
    index
  );
};

const onClickEditValue = (e, record) => {
  rootInstance.payrollPageState.getEditingValue(record);
  rootInstance.payrollPageState.setIsEditingCompensationValueModalOpen(true);
};

const onClickEditDeduction = (e, record) => {
  rootInstance.payrollPageState.getEditingDeduction(record);
  rootInstance.payrollPageState.setIsEditingCompensationDeductionModalOpen(
    true
  );
};

// const hideDeleteTooltip = (type) =>
//   (type === VALUES &&
//     rootInstance.payrollPageState.selectedCompensationStructure
//       .compensation_values.length < 2 &&
//     rootInstance.payrollPageState.selectedCompensationStructure
//       .compensation_deductions.length == 0) ||
//   (type === DEDUCTIONS &&
//     rootInstance.payrollPageState.selectedCompensationStructure
//       .compensation_values.length == 0 &&
//     rootInstance.payrollPageState.selectedCompensationStructure
//       .compensation_deductions.length < 2);

export const compensationValuesOrDeductionsTableColumns = (
  rootInstance,
  type
) => ({
  '1': {
    title:
      type === VALUES_EDIT || type === VALUES_DELETE
        ? COMPENSATION_VALUE_NAME
        : type === DEDUCTIONS_EDIT || type === DEDUCTIONS_DELETE
        ? COMPENSATION_DEDUCTION_NAME
        : null,
    dataIndex:
      type === VALUES_EDIT || type === VALUES_DELETE
        ? 'compensation_value'
        : type === DEDUCTIONS_EDIT || type === DEDUCTIONS_DELETE
        ? 'deduction_type'
        : null,
    key:
      type === VALUES_EDIT || type === VALUES_DELETE
        ? 'compensation_value_name'
        : type === DEDUCTIONS_EDIT || type === DEDUCTIONS_DELETE
        ? 'deduction_type_name'
        : null,
    width: '25%',
  },
  '2': {
    title: COMPENSATION_VALUE_BASED_ON,
    dataIndex: 'based_on',
    key: 'compensation_based_on',
    width: '15%',
  },
  '3': {
    title: COMPENSATION_VALUE_RATE,
    dataIndex: 'rate',
    key: 'compensation_rate',
    width: '15%',
  },
  '4': {
    title: COMPENSATION_VALUE_DEFAULT_VALUE,
    dataIndex: 'default_value',
    key: 'compensation_default_value',
    width: '15%',
  },
  '5': {
    title: COMPENSATION_VALUE_CURRENCY,
    dataIndex: 'currency',
    key: 'compensation_currency',
    width: '15%',
  },
  '6': {
    title: NONAME,
    render: (text, record, index) => {
      return (
        <div className="align-center">
          <Tooltip
            title={
              type === VALUES_EDIT
                ? COMPENSATION_VALUE_EDIT
                : type === DEDUCTIONS_EDIT
                ? COMPENSATION_DEDUCTION_EDIT
                : type === DEDUCTIONS_DELETE
                ? COMPENSATION_DEDUCTION_DELETE
                : type === VALUES_DELETE
                ? COMPENSATION_VALUE_DELETE
                : null
            }
          >
            <img
              src={
                type === VALUES_EDIT || type === DEDUCTIONS_EDIT
                  ? edit
                  : type === VALUES_DELETE || type === DEDUCTIONS_DELETE
                  ? trash
                  : null
              }
              alt={
                type === VALUES_EDIT
                  ? COMPENSATION_VALUE_EDIT
                  : type === DEDUCTIONS_EDIT
                  ? COMPENSATION_DEDUCTION_EDIT
                  : type === DEDUCTIONS_DELETE
                  ? COMPENSATION_DEDUCTION_DELETE
                  : type === VALUES_DELETE
                  ? COMPENSATION_VALUE_DELETE
                  : null
              }
              className="pointer ph10"
              onClick={(e) => {
                if (type === VALUES_EDIT) onClickEditValue(e, record);
                else if (type === VALUES_DELETE) onClickDeleteValue(e, index);
                else if (type === DEDUCTIONS_DELETE)
                  onClickDeleteDeduction(e, index);
                else if (type === DEDUCTIONS_EDIT)
                  onClickEditDeduction(e, record);
                else return;
              }}
            />
          </Tooltip>
        </div>
      );
    },
    width: '15%',
  },
});
