import React from 'react';
import { observer } from 'mobx-react';
import ShipmentChargesTable from './ShipmentCharge/ShipmentChargesTable';
import RouteActionsTable from './RouteAction/RouteActionsTable';
import ShipmentDocumentTypesTable from './ShipmentDocumentType/ShipmentDocumentTypesTable';
import AddShipmentSpecificModal from './AddShipmentSpecificModal';
import { ADD_NEW, FILTER } from '../../../../constants';
import { useMst } from '../../../../state/RootModel';
import { Select } from 'antd';
import ShipmentStatusTable from './ShipmentStatus/ShipmentStatusTable';
import EditShipmentSpecificModal from './EditShipmentSpecificModal';

const { Option } = Select;

const OtherComponent = () => {
  const {
    shipmentSpecificsPageState: {
      availableShipmentSpecificTypes,
      selectedShipmentSpecificType,
      setSelectedShipmentSpecificType,
      isShipmentChargeSelected,
      isRouteActionSelected,
      isShipmentDocumentTypeSelected,
      setNewShipmentSpecificModalOpen,
      isShipmentStatusSelected,
      editingShipmentSpecific,
    },
    userState: {
      canViewBillingCharges,
      canViewRouteAction,
      canViewShipmentDocumentTypes,
      canEditBillingCharges,
    },
  } = useMst();

  const onAddNew = () => {
    setNewShipmentSpecificModalOpen(true);
  };

  const onFilterSelect = (value) => {
    setSelectedShipmentSpecificType(value);
  };

  return (
    <div className="pv20 locations-component">
      <button
        className={'btn primary no-wrap header-button'}
        onClick={onAddNew}
      >
        {ADD_NEW}
      </button>
      <div className="filters-wrapper align-center justify-end ml20">
        <p>{FILTER}</p>
        <Select
          className="text-capitalize ml20"
          style={{ width: 200 }}
          onChange={onFilterSelect}
          value={selectedShipmentSpecificType}
        >
          {availableShipmentSpecificTypes.map((type) => (
            <Option key={type} value={type} className="text-capitalize">
              {type}
            </Option>
          ))}
        </Select>
      </div>
      {canEditBillingCharges && editingShipmentSpecific && (
        <EditShipmentSpecificModal />
      )}
      {isShipmentChargeSelected && canViewBillingCharges && (
        <ShipmentChargesTable />
      )}
      {isRouteActionSelected && canViewRouteAction && <RouteActionsTable />}
      {isShipmentDocumentTypeSelected && canViewShipmentDocumentTypes && (
        <ShipmentDocumentTypesTable />
      )}
      {isShipmentStatusSelected && canViewShipmentDocumentTypes && (
        <ShipmentStatusTable />
      )}
      <AddShipmentSpecificModal />

      {/* <CarriersTable /> */}
      {/* {newCarrier && <AddCarrierModal />} */}
      {/* {editedCarrier && <EditCarrierModal />} */}
      {/* <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_ADD_CARRIER_TEXT}
        buttonText={MODAL_SUCCESS_ADD_CARRIER_BUTTON_TEXT}
        backLink={backLink}
        open={addCarrierSuccessModalOpen}
        closeModal={() => setAddCarrierSuccessModalOpen(false)}
      /> */}
      {/* <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_CARRIER_TEXT}
        buttonText={MODAL_SUCCESS_EDIT_CARRIER_BUTTON_TEXT}
        backLink={backLink}
        open={editCarrierSuccessModalOpen}
        closeModal={() => setEditCarrierSuccessModalOpen(false)}
      /> */}
    </div>
  );
};

export default observer(OtherComponent);
