import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Input, DatePicker, Upload, Select } from 'antd';
import {
  REGISTRATION_INFORMATION,
  LICENCE_PLATE,
  EXPIRATION_DATE,
  ADD_REGISTRATION_TEXT,
  REMOVE,
  CURRENT_REGISTRATION,
  NEW_REGISTRATION,
  ISSUED_IN,
  REGISTRATION_ATTACHMENT,
  ISSUED_ON,
  COUNTRY,
  OTHER,
} from '../../constants';
import moment from 'moment';
import fileUpload from '../../assets/images/file-upload.svg';

const { Option } = Select;
const RegistrationInformation = ({
  newRegistration,
  uploadNewRegistration,
  registration,
  uploadRegistration,
}) => {
  const {
    fleetPageState: { editedVehicle },
  } = useMst();

  const onCurrentLicenceExpiryDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedVehicle.licence.setRegistrationExpirationDate(date);
  };

  const onNewLicenceExpiryDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedVehicle.newLicence.setRegistrationExpirationDate(date);
  };

  const onLicenceIssuedOnChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedVehicle.licence.setRegistrationIssuedOn(date);
  };

  const onNewLicenceIssuedOnChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedVehicle.newLicence.setRegistrationIssuedOn(date);
  };

  const onAddRegistration = () => {
    editedVehicle.createNewLicence();
  };

  const onRemoveRegistration = () => {
    uploadNewRegistration({});
    editedVehicle.setNewLicence(null);
  };

  return (
    <div className="form-page-section">
      <div className="form-section-title-row align-center">
        <span className="form-section-title">{REGISTRATION_INFORMATION}</span>
        <button
          className="btn primary ml20 ph20 pv0"
          onClick={onAddRegistration}
          disabled={!!editedVehicle.newLicence}
        >
          {ADD_REGISTRATION_TEXT}
        </button>
      </div>

      {editedVehicle.newLicence && (
        <div
          className={`section-content ${editedVehicle.licence ? 'pb20' : ''}`}
        >
          {editedVehicle.licence && (
            <p className="mb10 text-underline">{NEW_REGISTRATION}</p>
          )}
          <div className="align-center">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{LICENCE_PLATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) =>
                  editedVehicle.newLicence.setLicencePlate(e.target.value)
                }
                value={editedVehicle.newLicence.licence_plate}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{COUNTRY}</span>
              </div>
              <Select
                className={'w100'}
                onChange={(country) =>
                  editedVehicle.newLicence.setRegistrationCountry(country)
                }
                value={editedVehicle.newLicence.country}
              >
                {editedVehicle?.countriesArray.map((country, index) => (
                  <Option key={index} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_IN}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) =>
                  editedVehicle.newLicence.setRegistrationLocation(
                    e.target.value
                  )
                }
                value={editedVehicle.newLicence.issued_in}
                // disabled={!!editedVehicle.newLicence}
              />
              {/*<Select*/}
              {/*    className={'w100'}*/}
              {/*    onChange={(state) =>*/}
              {/*        editedVehicle.newLicence.setRegistrationLocation(state)*/}
              {/*    }*/}
              {/*    value={editedVehicle.newLicence.issued_in}>*/}
              {/*    {editedVehicle?.newLicence?.filteredStates?.states.map((state, index) => (*/}
              {/*        <Option key={index} value={state}>{state}</Option>*/}
              {/*    ))}*/}
              {/*    <Option key={'other'} value={'Other'}>{OTHER}</Option>*/}
              {/*</Select>*/}
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_ON}</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onNewLicenceIssuedOnChange}
                value={
                  editedVehicle.newLicence.issued_on
                    ? moment(editedVehicle.newLicence.issued_on)
                    : null
                }
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{EXPIRATION_DATE}</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onNewLicenceExpiryDateChange}
                value={
                  editedVehicle.newLicence.expiry_date
                    ? moment(editedVehicle.newLicence.expiry_date)
                    : null
                }
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label">
                <Upload
                  fileList={newRegistration}
                  onChange={uploadNewRegistration}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <div className="align-center justify-between pointer">
                    <span className="color-primary">
                      {REGISTRATION_ATTACHMENT}
                    </span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>
            <button
              className="btn primary ml30 ph20 pv0"
              onClick={onRemoveRegistration}
            >
              {REMOVE}
            </button>
          </div>
        </div>
      )}

      {editedVehicle.licence && (
        <div className="section-content">
          {editedVehicle.newLicence && (
            <p className="mb10 text-underline">{CURRENT_REGISTRATION}</p>
          )}
          <div className="align-center">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{LICENCE_PLATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) =>
                  editedVehicle.licence.setLicencePlate(e.target.value)
                }
                value={editedVehicle.licence.licence_plate}
                disabled={!!editedVehicle.newLicence}
              />
            </div>

            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{COUNTRY}</span>
              </div>
              <Select
                className={'w100'}
                onChange={(country) =>
                  editedVehicle.licence.setRegistrationCountry(country)
                }
                value={editedVehicle.licence.country}
              >
                {editedVehicle.countriesArray.map((country, index) => (
                  <Option key={index} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_IN}</span>
              </div>
              <Select
                className={'w100'}
                onChange={(state) =>
                  editedVehicle.licence.setRegistrationLocation(state)
                }
                value={editedVehicle.licence.issued_in}
              >
                {editedVehicle?.licence?.filteredStates?.states.map(
                  (state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  )
                )}
                <Option key={'other'} value={'Other'}>
                  {OTHER}
                </Option>
              </Select>
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_ON}</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onLicenceIssuedOnChange}
                value={
                  editedVehicle.licence.issued_on
                    ? moment(editedVehicle.licence.issued_on)
                    : null
                }
                disabled={!!editedVehicle.newLicence}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{EXPIRATION_DATE}</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onCurrentLicenceExpiryDateChange}
                value={
                  editedVehicle.licence.expiry_date
                    ? moment(editedVehicle.licence.expiry_date)
                    : null
                }
                disabled={!!editedVehicle.newLicence}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label">
                <Upload
                  fileList={registration}
                  onChange={uploadRegistration}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                  disabled={!!editedVehicle.newLicence}
                >
                  <div
                    className={`align-center justify-between ${
                      editedVehicle.newLicence ? 'not-allowed' : 'pointer'
                    }`}
                  >
                    <span className="color-primary">
                      {REGISTRATION_ATTACHMENT}
                    </span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(RegistrationInformation);
