import { rootInstance } from '../../state/RootModel';
const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getDepartments = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/departments`, config);
};

export const getEmployees = (account_status = null) => {
  const config = getConfig();
  const status = account_status ? '?account_status=' + account_status : '';
  return axios.get(`${rootInstance.baseUrl}/users${status}`, config);
};

export const getDepartmentEmployees = (name, active = null) => {
  const config = getConfig();
  if (active === false) {
    return axios.get(
      `${rootInstance.baseUrl}/departments/${name}?active=false`,
      config
    );
  } else {
    return axios.get(`${rootInstance.baseUrl}/departments/${name}`, config);
  }
};

export const getEmployee = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/users/${id}`, config);
};

export const addEmployee = (employee) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/users${
      employee.user_department && employee.user_department.department_id
        ? '?department_id=' + employee.user_department.department_id
        : ''
    }`,
    {
      user: {
        first_name: employee.first_name,
        last_name: employee.last_name,
        code_name: employee.code_name,
        email: employee.email,
        phone_number: employee.phone_number,
      },
      role_ids: employee.selectedRolesIds,
    },
    config
  );
};

export const editEmployee = (employee) => {
  const config = getConfig();
  let department =
    employee.user_department === 0
      ? []
      : employee.user_department && [employee.user_department];
  return axios.put(
    `${rootInstance.baseUrl}/users/${employee.user_id}`,
    {
      first_name: employee.first_name,
      last_name: employee.last_name,
      code_name: employee.code_name,
      date_of_birth: employee.date_of_birth,
      user_role: employee.role_ids,
      phone_number: employee.phone_number,
      user_organization_id: employee.user_organization_id,
      street_address: employee.street_address,
      city: employee.city,
      state: employee.state,
      country: employee.country,
      postal_code: employee.postal_code,
      social_security_number: employee.social_security_number,
      active: true,
      new_roles: employee.user_assigned_roles,
      new_departments: department,
      setting_user_department_to_none: employee.user_department === 0,
    },
    config
  );
};

export const deleteEmployee = (user_id) => {
  const config = {
    headers: {
      Authorization: getToken(),
    },
    data: {
      user_ids: [user_id],
    },
  };
  return axios.delete(`${rootInstance.baseUrl}/users/deactivate`, config);
};

export const deleteEmployees = (user_ids) => {
  const config = {
    headers: {
      Authorization: getToken(),
    },
    data: {
      user_ids,
    },
  };
  return axios.delete(`${rootInstance.baseUrl}/users/deactivate`, config);
};

export const getEmployeeRoles = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/roles`, config);
};

export const getEmployeeActiveRole = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/account/active_role`, config);
};

export const getWorkingStatuses = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/working_statuses`, config);
};

export const getAccountStatuses = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/account_statuses`, config);
};

export const addRoleToUser = (userId, roleId) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/users/${userId}/roles`,
    {
      role_id: roleId,
    },
    config
  );
};

export const getUserDocumentation = (user_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/users/${user_id}/documents`,
    config
  );
};

export const getUserDocument = (user_id, document_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/users/${user_id}/documents/${document_id}`,
    config
  );
};

export const deleteUserDocument = (user_id, document_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/users/${user_id}/documents/${document_id}`,
    config
  );
};

export const uploadUserDocument = (userId, file, fileType) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/users/${userId}/documents?document_type=${fileType}`,
    file,
    config
  );
};

export const getAllAbsenceTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/absence_statuses`, config);
};

export const getAllUserAbsences = (user_id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/users/${user_id}/absences`, config);
};

export const getUserAbsenceDocument = (absence_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/absences/${absence_id}/document?absence_type=user`,
    config
  );
};

export const uploadUserAbsenceDocument = (absenceId, file) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/absences/${absenceId}/document?absence_type=user`,
    file,
    config
  );
};

export const deleteUserAbsenceDocument = (document_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/absence_document/${document_id}`,
    config
  );
};

export const getUserAbsence = (user_id, absence_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/users/${user_id}/absence/${absence_id}`,
    config
  );
};

export const addUserAbsence = (absence) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/users/${absence.user_id}/absence`,
    {
      starting_date: absence.starting_date,
      end_date: absence.end_date,
      status: absence.status,
    },
    config
  );
};

export const updateUserAbsence = (absence) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/users/${absence.user_id}/absence/${absence.absence_id}`,
    {
      starting_date: absence.starting_date,
      end_date: absence.end_date,
      status: absence.status,
    },
    config
  );
};

export const deleteUserAbsence = (user_id, absence_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/users/${user_id}/absence/${absence_id}`,
    config
  );
};

export const reactivateEmployee = (user_id) => {
  const config = {
    headers: {
      Authorization: getToken(),
    },
  };
  return axios.put(
    `${rootInstance.baseUrl}/users/activate`,
    { user_ids: [user_id] },
    config
  );
};

export const reactivateEmployees = (user_ids) => {
  const config = {
    headers: {
      Authorization: getToken(),
    },
  };
  return axios.put(
    `${rootInstance.baseUrl}/users/activate`,
    { user_ids },
    config
  );
};
