import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty, Pagination } from 'antd';
import { useMst } from '../../../../state/RootModel';
import { EMPTY_CUSTOMERS_TABLE_TEXT } from '../../../../constants';

const CustomersTable = () => {
  const {
    clientsPageState: {
      getCustomersColumns,
      clientsArray,
      getClients,
      totalItems,
      currentPage,
      pageSize,
    },
  } = useMst();

  const paginationChange = (val) => {
    getClients({ page: val });
  };

  return (
    <div>
      <Table
        className="drivers-table fleet-table"
        rowKey={'client_id'}
        columns={getCustomersColumns()}
        dataSource={clientsArray}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_CUSTOMERS_TABLE_TEXT}
            />
          ),
        }}
      />
      <div className="table-footer">
        <Pagination
          onChange={paginationChange}
          total={totalItems}
          current={currentPage}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default observer(CustomersTable);
