import React from 'react';
import { observer } from 'mobx-react';
import DispatchRouteActionItem from './DispatchRouteActionItem';
import DispatchEditingRouteActionItem from './DispatchEditingRouteActionItem';
import { useMst } from '../../../state/RootModel';

const DispatchRouteActionItemsWrapper = () => {
  const {
    dispatchPageState: {
      isAllShipmentActionsOpened,
      getEditingRouteAction,
      getSavedRouteTripItems,
      shouldShipmentActionsHaveScroll,
    },
  } = useMst();
  return (
    isAllShipmentActionsOpened && (
      <div
        className={`route-action-items-wrapper ${
          getSavedRouteTripItems?.length > 2 || shouldShipmentActionsHaveScroll
            ? 'has-over'
            : ''
        }`}
      >
        <div className="inside-details">
          <div className="inside-details__single-trip-wrapper">
            {!getEditingRouteAction &&
              getSavedRouteTripItems.map((routeItem, index) => (
                <DispatchRouteActionItem
                  data={routeItem}
                  index={index}
                  key={index}
                />
              ))}
            {getEditingRouteAction && (
              <DispatchEditingRouteActionItem data={getEditingRouteAction} />
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default observer(DispatchRouteActionItemsWrapper);
