import React from 'react';
import { observer } from 'mobx-react';
import EditOrganizationComponent from '../../components/organizations/EditOrganization/EditOrganizationComponent';
import PageRoutes from '../../utils/PageRoutes';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import { EDIT_ORGANIZATION } from '../../constants/organizations/organizations';

const EditOrganization = () => {
  return (
    <div className="page">
      <div className="page-content">
        <BreadcrumsComponent
          breadcrumbsBackLink={PageRoutes.Organization.name}
          page={EDIT_ORGANIZATION}
        />
        <EditOrganizationComponent />
      </div>
    </div>
  );
};

export default observer(EditOrganization);
