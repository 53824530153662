import { types } from 'mobx-state-tree';
import { DEPARTMENT_NAME_REQUIRED } from '../../../constants/organization/departments';
import { rootInstance } from '../../RootModel';
import { createNewDepartment } from '../../../actions/organization';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { notification } from 'antd';

export const OrganizationDepartmentLightModel = types
  .model('OrganizationDepartmentLightModel', {
    department_name: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setIsDepartmentSuccessModalOpen(value) {
        rootInstance.currentOrganization.setIsDepartmentSuccessModalOpen(value);
      },
      setDepartmentName(value) {
        self.department_name = value;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      clearForm() {
        this.setDepartmentName(null);
        this.setErrorText(null);
      },
      validateForm() {
        if (!self.department_name) {
          this.setErrorText(DEPARTMENT_NAME_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveNewDepartment() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          createNewDepartment(self.department_name)
            .then((response) => {
              this.setIsDepartmentSuccessModalOpen(true);
              setFetchingInProgress(false);
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
    };
  });
