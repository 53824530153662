import React from 'react';
import { useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import EditTruckComponent from '../../components/fleet/EditTruck/EditTruckComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import SuccessModal from '../../components/SuccessModal';
import OutOfServiceModal from '../../components/fleet/OutOfServiceModal';
import AddInspectionModal from '../../components/fleet/AddInspectionModal';
import EditInspectionModal from '../../components/fleet/EditInspectionModal';
import ConfirmDeleteInspectionModal from '../../components/fleet/ConfirmDeleteInspectionModal';
import ConfirmDeleteMaintenanceModal from '../../components/fleet/ConfirmDeleteMaintenanceModal';
import AddMaintenanceModal from '../../components/fleet/AddMaintenanceModal';
import EditMaintenanceModal from '../../components/fleet/EditMaintenanceModal';
import PageRoutes from '../../utils/PageRoutes';
import {
  EDIT_TRUCK,
  MODAL_SUCCESS_EDIT_TRUCK_TEXT,
  MODAL_SUCCESS_EDIT_TRUCK_BUTTON_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../constants';

const EditTruckPage = () => {
  const {
    fleetPageState: { editedTruck },
  } = useMst();

  const backLink = PageRoutes.EditTruck.name;

  const params = { truckId: editedTruck?.vehicle_id.toString() };

  return (
    <div>
      <div className="page add-truck-page">
        <BreadcrumsComponent
          page={EDIT_TRUCK}
          breadcrumbsBackLink={PageRoutes.Fleet.name}
        />
        <SuccessModal
          title={MODAL_SUCCESS_TITLE}
          text={MODAL_SUCCESS_EDIT_TRUCK_TEXT}
          buttonText={MODAL_SUCCESS_EDIT_TRUCK_BUTTON_TEXT}
          backLink={backLink}
          params={params}
          open={editedTruck?.successModalOpen}
          closeModal={() => editedTruck.setSuccessModalOpen(false)}
        />
        <OutOfServiceModal />
        <AddInspectionModal />
        <EditInspectionModal />
        <ConfirmDeleteInspectionModal />
        <AddMaintenanceModal />
        <EditMaintenanceModal />
        <ConfirmDeleteMaintenanceModal />
        <div className="page-content">
          <EditTruckComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(EditTruckPage);
