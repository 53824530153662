import React from 'react';
import { rootInstance } from '../../RootModel';
import { Tooltip } from 'antd';
import {
  ID,
  VIEW_AND_TRACK_ON_MAP,
  LINKED_DRIVER,
  VIEW_VEHICLE_ROUTE_ACTIONS,
} from '../../../constants/dispatch';
import {
  CURRENT_LOCATION,
  LINKED_TRUCK,
  NOT_AVAILABLE_SHORT,
  STATUS,
  TRAILER,
  TRAILER_TYPE,
} from '../../../constants';
import { replaceUnderscore } from '../../../utils/methods';
import selectIcon from '../../../assets/images/select-icon.svg';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';
import organizeTour from '../../../assets/images/dispatch/bx_bx-trip.svg';

// const onTrailerSelect = (e, record) => {
//   e.stopPropagation();
//   rootInstance.dispatchPageState.setIsAssetsListOpened(false);
//   rootInstance.dispatchPageState.loadRouteActionDetails();
//   rootInstance.dispatchPageState.addNewRouteActionItem({});
//   rootInstance.dispatchPageState?.routeActionItem?.changeSelectedTrailer(
//     record.vehicle_id
//   );
//   rootInstance.dispatchPageState.setIsOrganizeTourOpened(true);
// };

const onShowTrailerOnMap = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsAssetsListOpened(false);
  rootInstance.dispatchPageState.setTrailerForTracking(record);
};

const onShowVehicleRouteActions = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsAssetsListOpened(false);
  rootInstance.dispatchPageState.getSelectedVehicleRouteActions(
    record,
    TRAILER
  );
};

export const AssetsListTrailersTableColumns = (rootInstance) => ({
  '1': {
    title: ID,
    dataIndex: 'internal_company_id',
    key: 'internal_company_id',
    width: 100,
    render: (text, record) => {
      return <span>{text}</span>;
    },
  },
  '2': {
    title: TRAILER_TYPE,
    dataIndex: 'trailer_type',
    key: 'trailer_type',
    width: 130,
    render: (text) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '3': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (text) => {
      return (
        <span
          className={`text-capitalize text-bold ${
            text === 'Available' ? 'text-green' : 'text-orange'
          }`}
        >
          {text ? replaceUnderscore(text) : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '4': {
    title: LINKED_TRUCK,
    dataIndex: 'linked_truck',
    key: 'linked_truck',
    render: (text, record) => {
      return (
        <span>
          {record?.linked_truck
            ? record?.linked_truck?.internal_company_id +
              ' / ' +
              record?.linked_truck?.vehicle_make
            : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '5': {
    title: LINKED_DRIVER,
    dataIndex: 'linked_driver',
    key: 'linked_driver',
    render: (text, record) => {
      let driver = record?.linked_driver
        ? record?.linked_driver?.first_name +
          ' ' +
          record?.linked_driver?.last_name
        : '';
      let type =
        record?.linked_driver?.driver_type === COMPANY_DRIVER
          ? 'CD'
          : record?.linked_driver?.driver_type === OWNER_OPERATOR
          ? 'OO'
          : NOT_AVAILABLE_SHORT;

      return (
        <p>
          <span>{driver && driver !== '' ? driver : NOT_AVAILABLE_SHORT}</span>
          <span className="ml10">
            <strong
              className={`text-bold ${
                record?.linked_driver?.driver_type ? 'text-blue' : ''
              }`}
            >
              {record?.linked_driver?.driver_type ? type : ''}
            </strong>
          </span>
        </p>
      );
    },
  },
  '8': {
    title: CURRENT_LOCATION,
    dataIndex: 'location_description',
    key: 'location_description',
    render: (text) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '9': {
    title: '',
    dataIndex: 'action',
    width: 70,
    render: (text, record) => {
      let showTrackingOption = false;
      if (record?.vehicle_latitude && record?.vehicle_longitude) {
        showTrackingOption = true;
      }
      return (
        <div className="align-center">
          {showTrackingOption && (
            <Tooltip title={VIEW_AND_TRACK_ON_MAP}>
              <img
                src={selectIcon}
                className="cursor-pointer mr10"
                alt={VIEW_AND_TRACK_ON_MAP}
                onClick={(e) => onShowTrailerOnMap(e, record)}
              />
            </Tooltip>
          )}
          {record?.has_route_actions && (
            <Tooltip title={VIEW_VEHICLE_ROUTE_ACTIONS}>
              <img
                src={organizeTour}
                className="cursor-pointer mr10"
                alt={VIEW_VEHICLE_ROUTE_ACTIONS}
                onClick={(e) => onShowVehicleRouteActions(e, record)}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
});
