import { rootInstance } from '../../state/RootModel';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getCarriers = (text, pageNumber, pageSize) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/carriers?page=${pageNumber}&articles_per_page=${pageSize}${textQuery}`,
    config
  );
};

export const getCarrier = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/carriers/${id}`, config);
};

export const getCarrierEquipment = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/carriers/${id}/equipment`, config);
};

export const addCarrier = (carrier) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/carriers`,
    {
      company_name: carrier.company_name,
      country: carrier.country,
      address: carrier.address,
      city: carrier.city,
      state: carrier.state,
      postal_code: carrier.postal_code,
      phone_number: carrier.phone_number,
      fax_number: carrier.fax_number,
      email: carrier.email,
      dot_number: carrier.dot_number,
      mc_number: carrier.mc_number,
      carrier_code: carrier.carrier_code,
      status: carrier.status,
      contact_name: carrier.contact_name,
      contact_position: carrier.contact_position,
      insurance_company: carrier.insurance_company,
      insurance_broker: carrier.insurance_broker,
      insurance_policy_number: carrier.insurance_policy_number,
      insurance_expiry_date: carrier.insurance_expiry_date,
      insurance_cargo_amount: carrier.insurance_cargo_amount,
      insurance_liability_amount: carrier.insurance_liability_amount,
      notes: carrier.notes,
    },
    config
  );
};

export const addCarrierEquipments = (carrier_id, equipments) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/carriers/${carrier_id}/equipment`,
    equipments,
    config
  );
};

export const removeCarrierEquipments = (carrier_id, equipmentList) => {
  return axios.delete(
    `${rootInstance.baseUrl}/carriers/${carrier_id}/equipment`,
    {
      data: equipmentList,
      headers: {
        Authorization: getToken(),
      },
    }
  );
};

export const editCarrier = (carrier) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/carriers/${carrier.carrier_id}`,
    {
      company_name: carrier.company_name,
      country: carrier.country,
      address: carrier.address,
      city: carrier.city,
      state: carrier.state,
      postal_code: carrier.postal_code,
      phone_number: carrier.phone_number,
      fax_number: carrier.fax_number,
      email: carrier.email,
      dot_number: carrier.dot_number,
      mc_number: carrier.mc_number,
      carrier_code: carrier.carrier_code,
      status: carrier.status,
      contact_name: carrier.contact_name,
      contact_position: carrier.contact_position,
      insurance_company: carrier.insurance_company,
      insurance_broker: carrier.insurance_broker,
      insurance_policy_number: carrier.insurance_policy_number,
      insurance_expiry_date: carrier.insurance_expiry_date,
      insurance_cargo_amount: carrier.insurance_cargo_amount,
      insurance_liability_amount: carrier.insurance_liability_amount,
      notes: carrier.notes,
    },
    config
  );
};

export const deleteCarrier = (carrier_id) => {
  const config = getConfig();
  return axios.delete(`${rootInstance.baseUrl}/carriers/${carrier_id}`, config);
};
