import { types, getRoot, getSnapshot } from 'mobx-state-tree';
import { observable } from 'mobx';
import { rootInstance } from '../RootModel';
import { notification } from 'antd';
import { DepartmentModel } from '../departments/DepartmentModel';
import { EmployeeModel } from './EmployeeModel';
import { EmployeeLightModel } from './EmployeeLightModel';
import { EmployeeViewModel } from './EmployeeViewModel';
import { EmployeeRoleModel } from './EmployeeRoleModel';
import { employeesColumnsFunc } from './EmployeesTableColumns';
import {
  getDepartments,
  getEmployees,
  getEmployee,
  deleteEmployee,
  addEmployee,
  getEmployeeRoles,
  getWorkingStatuses,
  getAccountStatuses,
  deleteEmployees,
  reactivateEmployee,
  reactivateEmployees,
  getAllAbsenceTypes,
} from '../../actions/employees';
import {
  FETCH_DEPARTMENTS_ERROR,
  FETCH_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE_ERROR,
  FETCH_EMPLOYEES_ERROR,
  FETCH_ROLES_ERROR,
  FETCH_WORKING_STATUSES_ERROR,
  FETCH_ACCOUNT_STATUSES_ERROR,
  DELETE_EMPLOYEES_ERROR,
  REACTIVATE_EMPLOYEE_ERROR,
  REACTIVATE_EMPLOYEES_ERROR,
  FETCH_ABSENCE_TYPES_ERROR,
} from '../../constants';
import {
  allCountries,
  userAbsences,
  userDocumentation,
} from '../../promises/employees';
import { setFetchingInProgress, getErrorText } from '../../utils/methods';
import { EMPLOYEES, VIEW_EMPLOYEE } from '../../constants/menu';

const allSettled = require('promise.allsettled');

export const EmployeesPageModel = types
  .model('EmployeesPageModel', {
    employees: types.map(EmployeeModel),
    departments: types.map(DepartmentModel),
    departmentToAddEmployee: types.maybeNull(types.reference(DepartmentModel)),
    editedEmployee: types.maybeNull(EmployeeModel),

    // deleting employees
    userToBeDeleted: types.maybeNull(types.reference(EmployeeModel)),
    confirmDeleteEmployeeModalOpen: false,
    successDeleteEmployeeModalOpen: false,
    confirmDeleteEmployeesModalOpen: false,
    successDeleteEmployeesModalOpen: false,

    viewedEmployee: types.maybeNull(EmployeeViewModel),

    // reactivating employees
    userToBeReactivated: types.maybeNull(types.reference(EmployeeModel)),
    confirmReactivateEmployeeModalOpen: false,
    successReactivateEmployeeModalOpen: false,
    confirmReactivateEmployeesModalOpen: false,
    successReactivateEmployeesModalOpen: false,

    addEmployeeSuccessModalOpen: false,
    employee_roles: types.map(EmployeeRoleModel),
    newEmployee: types.maybe(EmployeeLightModel),
    accountStatuses: types.frozen([]),
    workingStatuses: types.frozen([]),
    selectEmployeesDropdownOpen: false,
    selectedDepartments: types.frozen([]),
    employeesDisplayedStatus: types.maybe(types.maybeNull(types.string)),

    availableAbsencesTypes: types.frozen([]),
  })
  .volatile((self) => {
    return {
      selectedEmployees: observable.map({}),
      // used to compare changes between initial loaded and current state of models
      initialLoadedEmployee: types.maybe(types.maybeNull(EmployeeModel)),
    };
  })
  .views((self) => {
    return {
      get departmentsArray() {
        return Array.from(self.departments.values());
      },
      get employeesArray() {
        return Array.from(self.employees.values());
      },
      get filteredEmployeesArray() {
        const searchText = rootInstance.headerState.searchText.toLowerCase();
        const filteredArray = this.employeesArray.filter((employee) => {
          return (
            employee.first_name.toLowerCase().includes(searchText) ||
            employee.last_name.toLowerCase().includes(searchText) ||
            employee.email.toLowerCase().includes(searchText)
          );
        });
        return filteredArray;
      },
      get employeesWithNoDepartment() {
        return this.employeesArray.filter((employee) => {
          return !employee.user_department;
        });
      },
      get filteredEmployeesWithNoDepartmentArray() {
        const searchText = rootInstance.headerState.searchText.toLowerCase();
        const filteredArray = this.employeesWithNoDepartment.filter(
          (employee) => {
            return (
              employee.first_name.toLowerCase().includes(searchText) ||
              employee.last_name.toLowerCase().includes(searchText) ||
              employee.email.toLowerCase().includes(searchText)
            );
          }
        );
        return filteredArray;
      },
      get selectedEmployeesArray() {
        return Array.from(self.selectedEmployees.values());
      },
      get selectedEmployeesArrayIds() {
        return this.selectedEmployeesArray.map((employee) => {
          return employee.user_id;
        });
      },
      get departmentsNamesArray() {
        return this.departmentsArray.map((department) => {
          return department.department_name;
        });
      },
      get departmentsIdsArray() {
        return this.departmentsArray.map((department) => {
          return department.department_id;
        });
      },
      get departmentsWithOpenEmployeeListsIds() {
        const filteredDepartments = this.departmentsArray.filter(
          (department) => {
            return department.isEmployeeTableOpen;
          }
        );
        return filteredDepartments.map(
          (department) => department.department_id
        );
      },
      get employeeRolesArray() {
        return Array.from(self.employee_roles.values());
      },
    };
  })
  .actions((self) => {
    return {
      setInitialLoadedEmployee(employee) {
        self.initialLoadedEmployee = employee;
      },
      setConfirmReactivateEmployeeModalOpen(status) {
        if (!status) {
          this.setUserToBeReactivated(null);
          this.getAllEmployees();
        }
        self.confirmReactivateEmployeeModalOpen = status;
      },
      setSuccessReactivateEmployeeModalOpen(status) {
        self.successReactivateEmployeeModalOpen = status;
      },
      setConfirmReactivateEmployeesModalOpen(status) {
        self.confirmReactivateEmployeesModalOpen = status;
      },
      setSuccessReactivateEmployeesModalOpen(status) {
        self.successReactivateEmployeesModalOpen = status;
      },
      setEmployeesDisplayedStatus(status) {
        self.employeesDisplayedStatus = status;
      },
      setConfirmDeleteEmployeesModalOpen(status) {
        self.confirmDeleteEmployeesModalOpen = status;
      },
      setSelectedDepartments(departments) {
        self.selectedDepartments = departments;
      },
      setAvailableAbsencesTypes(items) {
        self.availableAbsencesTypes = items;
      },
      selectAllEmployees(status) {
        if (status) {
          this.selectDepartmentEmployees(self.departmentsIdsArray);
          this.setSelectedEmployees([
            ...self.selectedEmployeesArray,
            ...self.employeesWithNoDepartment,
          ]);
        } else {
          this.selectDepartmentEmployees([]);
        }
      },
      selectDepartmentEmployees(departments) {
        this.setSelectedDepartments(departments);
        const employees = [];
        departments.forEach((departmentId) => {
          const department = self.departments.get(departmentId);
          self.employeesArray.forEach((employee) => {
            if (employee.user_department === department.department_id) {
              employees.push(employee);
            }
          });
        });
        this.setSelectedEmployees(employees);
      },
      setSelectEmployeesDropdownOpen(status) {
        self.selectEmployeesDropdownOpen = status;
      },
      getAvailableEmployeeRoles() {
        setFetchingInProgress(true);
        getEmployeeRoles()
          .then((response) => {
            this.setEmployeeRoles(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_ROLES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setEmployeeRoles(items) {
        self.employee_roles.clear();
        items.forEach((item) => {
          self.employee_roles.put({
            ...item,
          });
        });
      },
      setSelectedEmployees(employees) {
        self.selectedEmployees.clear();
        employees.forEach((element) => {
          self.selectedEmployees.set(element.user_id, element);
        });
      },
      removeFromSelectedEmployees(id) {
        self.selectedEmployees.delete(id);
      },
      setAddEmployeeSuccessModalOpen(status) {
        self.addEmployeeSuccessModalOpen = status;
      },
      throwAddEmployeeError(error) {
        const errorText = getErrorText(error);
        self.newEmployee.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      addNewEmployee() {
        if (self.newEmployee.validateForm()) {
          setFetchingInProgress(true);

          addEmployee(self.newEmployee)
            .then((employee) => {
              setFetchingInProgress(false);
              this.setAddEmployeeSuccessModalOpen(true);
            })
            .catch((error) => {
              this.throwAddEmployeeError(error);
            });
        }
      },
      throwFetchEmployeesError(error) {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_EMPLOYEES_ERROR,
          description: errorText,
        });
        setFetchingInProgress(false);
      },
      throwFetchEmployeeError(error) {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_EMPLOYEE_ERROR,
          description: errorText,
        });
        setFetchingInProgress(false);
      },
      getAllEmployees() {
        setFetchingInProgress(true);
        getEmployees(self.employeesDisplayedStatus)
          .then((response) => {
            this.setEmployees(response.data.users);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_EMPLOYEES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setEmployees(items) {
        self.employees.clear();
        items.forEach((item) => {
          self.employees.put({
            ...item,
          });
        });
      },
      getAllDepartments() {
        setFetchingInProgress(true);
        getDepartments()
          .then((response) => {
            this.setDepartments(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_DEPARTMENTS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      getAvailableAbsenceTypes() {
        setFetchingInProgress(true);
        getAllAbsenceTypes()
          .then((response) => {
            this.setAvailableAbsencesTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_ABSENCE_TYPES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDepartments(items) {
        // const departmentsWithOpenEmployeeListsIds =
        //   self.departmentsWithOpenEmployeeListsIds;
        self.departments.clear();
        items.forEach((item) => {
          //   // checks if list was open prior to leaving the page to reopen when user navigates back
          //   departmentsWithOpenEmployeeListsIds.forEach((id) => {
          //     if (item.department_id === id) {
          //       item.isEmployeeTableOpen = true;
          //     }
          //   });
          self.departments.put({
            ...item,
          });
        });
      },
      beforeEnterEmployeesRoute() {
        this.getAvailableEmployeeRoles();
        this.getAllDepartments();
        this.getAllEmployees();
      },
      beforeExitEmployeesRoute() {
        const nextRoute = rootInstance.router.nextView.name;
        nextRoute !== EMPLOYEES && this.setEmployeesDisplayedStatus(null);
        this.selectDepartmentEmployees([]);
        this.setSelectEmployeesDropdownOpen(false);
      },
      beforeEditEmployeeEnterRoute(id) {
        this.getEditedEmployee(id);
        !self.departmentsArray.length && this.getAllDepartments();
        !self.employeeRolesArray.length && this.getAvailableEmployeeRoles();
        !self.accountStatuses.length && this.getAccountStatuses();
        !self.workingStatuses.length && this.getWorkingStatuses();
        !self.availableAbsencesTypes.length && this.getAvailableAbsenceTypes();
      },
      beforeAddEmployeeEnterRoute() {
        !self.departmentsArray.length && this.getAllDepartments();
        !self.employeeRolesArray.length && this.getAvailableEmployeeRoles();
      },
      beforeViewEmployeeEnterRoute(id) {
        this.getViewedEmployee(id);
        !self.departmentsArray.length && this.getAllDepartments();
        !self.employeeRolesArray.length && this.getAvailableEmployeeRoles();
        !self.workingStatuses.length && this.getWorkingStatuses();
        !self.availableAbsencesTypes.length && this.getAvailableAbsenceTypes();
      },
      beforeViewEmployeeExitRoute(id) {
        rootInstance.router.nextView?.name !== VIEW_EMPLOYEE &&
          this.setViewedEmployee(null);
      },
      getEditedEmployee(id) {
        setFetchingInProgress(true);
        this.setEditedEmployee(null);
        getEmployee(id)
          .then((fetched_user) => {
            this.setEditedEmployee(fetched_user.data);
            let promises = [
              userDocumentation(self.editedEmployee),
              userAbsences(self.editedEmployee),
              allCountries(self.editedEmployee),
            ];
            allSettled(promises).then(() => {
              self.editedEmployee.setEmployeeFullyFetched(true);
              const snapshot = getSnapshot(self.editedEmployee);
              this.setInitialLoadedEmployee(snapshot);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchEmployeeError(error);
          });
      },
      setEditedEmployee(user) {
        self.editedEmployee = user;
      },

      // view employee
      getViewedEmployee(id) {
        setFetchingInProgress(true);
        this.setViewedEmployee(null);
        getEmployee(id)
          .then((fetched_user) => {
            this.setViewedEmployee(fetched_user.data);
            let promises = [
              userDocumentation(self.viewedEmployee),
              userAbsences(self.viewedEmployee),
            ];

            allSettled(promises).then(() => {
              self.viewedEmployee.setEmployeeFullyFetched(true);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchEmployeeError(error);
          });
      },
      setViewedEmployee(user) {
        self.viewedEmployee = user;
      },
      // end view employee

      setUserToBeDeleted(user_id) {
        if (user_id) {
          this.setConfirmDeleteEmployeeModalOpen(true);
        }
        self.userToBeDeleted = user_id;
      },
      setUserToBeReactivated(user_id) {
        if (user_id) {
          this.setConfirmReactivateEmployeeModalOpen(true);
        }
        self.userToBeReactivated = user_id;
      },
      getEmployeesColumns() {
        return Object.values(employeesColumnsFunc(getRoot(self)));
      },
      setDepartmentToAddEmployee(id) {
        self.departmentToAddEmployee = id;
      },
      setConfirmDeleteEmployeeModalOpen(status) {
        if (!status) {
          this.setUserToBeDeleted(null);
          this.getAllEmployees();
        }
        self.confirmDeleteEmployeeModalOpen = status;
      },
      setSuccessDeleteEmployeeModalOpen(status) {
        self.successDeleteEmployeeModalOpen = status;
      },
      setSuccessDeleteEmployeesModalOpen(status) {
        self.successDeleteEmployeesModalOpen = status;
      },
      deleteUser() {
        setFetchingInProgress(true);
        deleteEmployee(self.userToBeDeleted.user_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteEmployeeModalOpen(false);
            this.setSuccessDeleteEmployeeModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_EMPLOYEE_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
            this.setConfirmDeleteEmployeeModalOpen(false);
          });
      },
      deleteSelectedUsers() {
        setFetchingInProgress(true);
        deleteEmployees(self.selectedEmployeesArrayIds)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteEmployeesModalOpen(false);
            this.setSuccessDeleteEmployeesModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_EMPLOYEES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
            this.setConfirmDeleteEmployeesModalOpen(false);
          });
      },
      reactivateUser() {
        setFetchingInProgress(true);
        reactivateEmployee(self.userToBeReactivated.user_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmReactivateEmployeeModalOpen(false);
            this.setSuccessReactivateEmployeeModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: REACTIVATE_EMPLOYEE_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
            this.setConfirmReactivateEmployeeModalOpen(false);
          });
      },
      reactivateSelectedUsers() {
        setFetchingInProgress(true);
        reactivateEmployees(self.selectedEmployeesArrayIds)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmReactivateEmployeesModalOpen(false);
            this.setSuccessReactivateEmployeesModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: REACTIVATE_EMPLOYEES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
            this.setConfirmReactivateEmployeesModalOpen(false);
          });
      },
      getWorkingStatuses() {
        setFetchingInProgress(true);
        getWorkingStatuses()
          .then((response) => {
            this.setWorkingStatuses(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_WORKING_STATUSES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setWorkingStatuses(statuses) {
        self.workingStatuses = statuses;
      },
      getAccountStatuses() {
        setFetchingInProgress(true);
        getAccountStatuses()
          .then((response) => {
            this.setAccountStatuses(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_ACCOUNT_STATUSES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAccountStatuses(statuses) {
        self.accountStatuses = statuses;
      },
    };
  });
