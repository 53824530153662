import React from 'react';
import { observer } from 'mobx-react';
import { ADD_INSPECTION, INSPECTION_HISTORY } from '../../../../constants';
import EditInspectionsTable from './EditInspectionsTable';

const onAddInspection = (vehicle) => {
  vehicle.setNewInspection({ vehicle_id: vehicle.vehicle_id });
};

const EditInspectionsSection = ({ model }) => {
  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-section-title-row align-center">
        <span className="form-section-title">{INSPECTION_HISTORY}</span>
        <button
          className="btn primary ml20 ph20 pv0"
          onClick={() => onAddInspection(model)}
          disabled={!!model.newInspection}
        >
          {ADD_INSPECTION}
        </button>
      </div>
      <EditInspectionsTable model={model} />
    </div>
  );
};

export default observer(EditInspectionsSection);
