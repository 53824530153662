import { notification } from 'antd';
import { types, getRoot } from 'mobx-state-tree';
import {
  deleteCarrier,
  getCarrier,
  getCarrierEquipment,
  getCarriers,
} from '../../actions/carriers';
import {
  DELETE_CARRIER_ERROR,
  CARRIERS,
  FETCH_CARRIER_ERROR,
  FETCH_CARRIERS_ERROR,
  FETCH_LOCATIONS_ERROR,
} from '../../constants';
import { VIEW_CARRIER, BROKERAGE } from '../../constants/menu';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { rootInstance } from '../RootModel';
import { CarrierLightModel } from './CarrierLightModel';
import { CarrierModel } from './CarrierModel';
import { carriersColumnsFunc } from './CarriersTableColumns';
import { getAllCountries, getAllEquipments } from '../../actions/common';

export const CarriersPageModel = types
  .model('CarriersPageModel', {
    carriers: types.map(CarrierModel),
    searchTerm: types.maybe(types.maybeNull(types.string)),
    currentPage: 1,
    pageSize: 10,
    totalItems: types.maybe(types.maybeNull(types.number)),

    // new carrier
    newCarrier: types.maybe(types.maybeNull(CarrierLightModel)),
    addCarrierSuccessModalOpen: false,
    // end new carrier

    // delete carrier
    carrierToBeDeleted: types.maybeNull(types.reference(CarrierModel)),
    confirmDeleteCarrierModalOpen: false,
    successDeleteCarrierModalOpen: false,
    // end delete carrier

    // edit carrier
    editedCarrier: types.maybe(types.maybeNull(CarrierModel)),
    editCarrierSuccessModalOpen: false,
    // end edit carrier

    viewedCarrier: types.maybe(types.maybeNull(CarrierModel)),

    equipmentItems: types.frozen([]),
    equipments: types.frozen([]),
    countries: types.frozen([]),
  })
  .volatile((self) => {
    return {
      availableCarrierStatuses: ['active', 'inactive', 'disabled'],
    };
  })
  .views((self) => {
    return {
      get countriesArray() {
        return Array.from(self.countries.values());
      },
      get filteredStates() {
        const carrierType = self.newCarrier
          ? self.newCarrier
          : self.editedCarrier;
        const selectedCountry = carrierType.country;
        return this.countriesArray.find((country) => {
          return country.name === selectedCountry;
        });
      },
      get carriersArray() {
        return Array.from(self.carriers.values());
      },
      get isViewCarrierPage() {
        return rootInstance.router.currentView.name === VIEW_CARRIER;
      },
      get isBrokeragePage() {
        return rootInstance.router.currentView.name === BROKERAGE;
      },
      get equipmentItemsList() {
        return Array.from(self.equipmentItems.values());
      },
    };
  })
  .actions((self) => {
    return {
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      getViewedCarrier(id) {
        setFetchingInProgress(true);
        getCarrier(id)
          .then((response) => {
            getCarrierEquipment(id).then((res) => {
              this.setViewedCarrier(response.data);
              this.setEquipmentItems(res.data);

              self.viewedCarrier.setFullyFetched(true);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_CARRIER_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setEquipmentItems(equipment) {
        self.equipmentItems = equipment;
      },
      setViewedCarrier(carrier) {
        self.viewedCarrier = carrier;
      },
      getEditedCarrier(id) {
        setFetchingInProgress(true);
        getCarrier(id)
          .then((response) => {
            this.setEditedCarrier(response.data);
            this.getCountriesList();
            getCarrierEquipment(id).then((res) => {
              this.setEquipmentItems(res.data);
              self.editedCarrier.setEquipments(res.data);
              this.getEquipments();
            });
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_CARRIER_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setEditedCarrier(carrier) {
        self.editedCarrier = carrier;
      },
      setEditCarrierSuccessModalOpen(status) {
        self.editCarrierSuccessModalOpen = status;
      },
      setAvailableEquipments(equipments) {
        self.equipments = equipments;
      },
      getEquipments() {
        getAllEquipments().then((response) => {
          this.setAvailableEquipments(response.data);
        });
      },
      setNewCarrier(carrier) {
        self.newCarrier = carrier;
        this.getCountriesList();
        this.getEquipments();
      },
      setAddCarrierSuccessModalOpen(status) {
        self.addCarrierSuccessModalOpen = status;
      },
      setSearchTerm(text) {
        self.searchTerm = text;
      },
      setCurrentPage(number) {
        self.currentPage = number;
      },
      setTotalItems(number) {
        self.totalItems = number;
      },
      getCarriers({ page = null }) {
        setFetchingInProgress(true);
        this.clearCarriers();
        // if page is specified in request, set current page to page requested
        page && this.setCurrentPage(page);
        getCarriers(self.searchTerm, self.currentPage, self.pageSize)
          .then((response) => {
            this.setTotalItems(response.data.total);
            this.setCarriers(response.data.carriers);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_CARRIERS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setCarriers(items) {
        self.carriers.clear();
        items.forEach((item) => {
          self.carriers.put({
            ...item,
          });
        });
      },
      clearCarriers() {
        self.carriers.clear();
      },
      getCarriersColumns() {
        return Object.values(carriersColumnsFunc(getRoot(self)));
      },
      // delete carrier
      setCarrierToBeDeleted(carrier_id) {
        self.carrierToBeDeleted = null;
        if (carrier_id) {
          this.setConfirmDeleteCarrierModalOpen(true);
          getCarrierEquipment(carrier_id).then((res) => {
            this.setEquipmentItems(res.data);
          });
        }
        self.carrierToBeDeleted = carrier_id;
      },
      setConfirmDeleteCarrierModalOpen(status) {
        if (!status) {
          this.setCarrierToBeDeleted(null);
        }
        self.confirmDeleteCarrierModalOpen = status;
      },
      deleteCarrier() {
        // setFetchingInProgress(true);
        // const equipmentList = self.equipmentItemsList.map( eq => eq.carrier_equipment_id);

        // removeCarrierEquipments(self.carrierToBeDeleted.carrier_id, equipmentList)
        //     .then((res) => {
        //         console.log(res)
        //
        //     })
        deleteCarrier(self.carrierToBeDeleted.carrier_id)
          .then(() => {
            this.setConfirmDeleteCarrierModalOpen(false);
            this.setDeleteCarrierSuccessModalOpen(true);
            rootInstance.brokeragePageState.setActiveTab(CARRIERS);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_CARRIER_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDeleteCarrierSuccessModalOpen(status) {
        self.successDeleteCarrierModalOpen = status;
      },
      // end delete carrier

      beforeEnterViewCarrierPage(id) {
        this.getViewedCarrier(id);
      },
      beforeExitViewCarrierPage() {
        this.setViewedCarrier(null);
        this.setEditedCarrier(null);
      },
    };
  });
