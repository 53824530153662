import React from 'react';
import { Checkbox } from 'antd';

import { Link } from '../Link';
import { PageRoutes } from '../../utils/PageRoutes';

import { MARK_AS_RESOLVED } from '../../constants';

const Notifications = ({
  notifications = [],
  markNotificationAsDone = (type: string, notification: any) => {},
}) => {
  return (
    <div className="notification-list">
      {notifications.map((notification) => {
        const isDriver = !!notification?.driver_notification_id;

        const id =
          notification?.driver_notification_id ??
          notification?.vehicle_notification_id;

        const linkProps = {
          view: PageRoutes[isDriver ? 'EditDriver' : 'EditTruck'].name,
          params: {
            [isDriver ? 'driverId' : 'truckId']: isDriver
              ? notification.driver_id
              : notification?.vehicle_id,
          },
        };

        return (
          <div
            className="notification-list-item"
            key={`${notification.type}-${id}`}
          >
            <div className="notification-list-item__header">
              <h4>{notification.notification_type}</h4>

              {!notification?.is_resolved && (
                <Checkbox
                  onChange={() =>
                    markNotificationAsDone(
                      isDriver
                        ? 'driver_notifications'
                        : 'vehicle_notifications',
                      notification
                    )
                  }
                >
                  {MARK_AS_RESOLVED}
                </Checkbox>
              )}
            </div>
            <Link className="text-regular color-primary" {...linkProps}>
              {`${notification.notification_text} (${
                isDriver
                  ? `Driver ID: ${notification.internal_company_id}`
                  : `Vehicle ID: ${notification?.internal_company_id}`
              })
              ${isDriver ? `Code name: ${notification.code_name}` : ''}
              `}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Notifications;
