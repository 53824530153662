import React from 'react';
import { observer } from 'mobx-react';
import FormPageTitle from '../../FormPageTitle';
import addUserRole from '../../../assets/images/add-user-role.svg';
import {
  ADD_USER_ROLE,
  MODAL_SUCCESS_CREATE_ROLE_TEXT,
  USER_ROLE_NAME,
} from '../../../constants/organization/userRole';
import { Input, Checkbox } from 'antd';
import { useMst } from '../../../state/RootModel';
import { CONFIRM, MODAL_SUCCESS_TITLE, SELECT_ALL } from '../../../constants';
import ErrorBox from '../../ErrorBox';
import { snakeCaseToString } from '../../../utils/methods';
import { MODAL_SUCCESS_DEPARTMENT_BUTTON_TEXT } from '../../../constants/organization/departments';
import SuccessModal from '../../SuccessModal';
import PageRoutes from '../../../utils/PageRoutes';

const AddUserRoleComponent = () => {
  const {
    currentOrganization: {
      newUserRole,
      getGroupedPermissions,
      setIsUserRoleSuccessModalOpen,
      isUserRoleSuccessModalOpen,
    },
  } = useMst();
  const onSetRoleName = (e) => {
    newUserRole.setRoleName(e.target.value);
  };

  const onCheckedPermission = (permission, value) => {
    const existingPermission = newUserRole.permissions.find(
      (permissionId) => permissionId === permission.id
    );
    if (value) {
      !existingPermission &&
        newUserRole.setPermissionsList([
          ...newUserRole.permissions,
          permission.id,
        ]);
    } else {
      const filteredArray = newUserRole.permissions.filter(
        (element) => element !== existingPermission
      );
      newUserRole.setPermissionsList(filteredArray);
    }
  };

  const onSelectAll = (group, value) => {
    const groupPermissionIds = group.data.map((element) => element.id);
    if (value) {
      newUserRole.setPermissionsList([
        ...newUserRole.permissions,
        ...groupPermissionIds,
      ]);
    } else {
      const filteredArray = newUserRole.permissions.filter(
        (permissionId) => !groupPermissionIds.includes(permissionId)
      );
      newUserRole.setPermissionsList(filteredArray);
    }
  };

  const onSaveUserRole = () => {
    newUserRole.createUserRole();
  };

  const onCloseUserRoleSuccessModal = () => {
    setIsUserRoleSuccessModalOpen(false);
  };

  const backLink = PageRoutes.Organization.name;

  return (
    <div className="add-edit-form-component">
      <FormPageTitle image={addUserRole} title={ADD_USER_ROLE} />
      <div className="flex-column mb40">
        <div className="input-wrapper w20">
          <div className="input-label justify-between">
            <span>
              {USER_ROLE_NAME}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Input
            type="text"
            value={newUserRole.role_name}
            placeholder={USER_ROLE_NAME}
            onChange={(e) => onSetRoleName(e)}
            className="trailer-search-input"
          />
        </div>
      </div>
      {getGroupedPermissions && (
        <div>
          {getGroupedPermissions?.map((group, index) => (
            <div className="mb20" key={index}>
              <div className="table-title-row align-center">
                <div className="pointer">
                  <span className="text-capitalize color-primary text-medium ml10">
                    {group.tag ? group.tag : 'Super Admin permissions'}
                  </span>
                  <Checkbox
                    className="flex-20 ml10"
                    key={group.tag}
                    onChange={(e) => onSelectAll(group, e.target.checked)}
                  >
                    {SELECT_ALL}
                  </Checkbox>
                </div>
              </div>
              <div className="permissions-list">
                <Checkbox.Group
                  className="text-capitalize flex-wrap"
                  value={newUserRole.permissions}
                >
                  {group?.data.map((permission) => (
                    <Checkbox
                      className="flex-20 mb10"
                      key={permission.id}
                      value={permission.id}
                      onChange={(e) =>
                        onCheckedPermission(permission, e.target.checked)
                      }
                    >
                      {snakeCaseToString(permission.name)}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </div>
            </div>
          ))}
        </div>
      )}
      <ErrorBox obj={newUserRole} />
      <button className="btn primary" onClick={onSaveUserRole}>
        {CONFIRM}
      </button>
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_CREATE_ROLE_TEXT}
        buttonText={MODAL_SUCCESS_DEPARTMENT_BUTTON_TEXT}
        backLink={backLink}
        open={isUserRoleSuccessModalOpen}
        closeModal={() => onCloseUserRoleSuccessModal()}
      />
    </div>
  );
};

export default observer(AddUserRoleComponent);
