import { notification } from 'antd';
import { types, getRoot } from 'mobx-state-tree';
import {
  getClients,
  searchClients,
  deleteClient,
  getClient,
} from '../../actions/clients';
import {
  DELETE_CUSTOMER_ERROR,
  FETCH_CLIENTS_ERROR,
  FETCH_LOCATIONS_ERROR,
} from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { ClientModel } from './ClientModel';
import { ClientLightModel } from './ClientLightModel';
import { customersColumnsFunc } from './CustomersTableColumns';
import { getAllCountries } from '../../actions/common';

export const ClientsPageModel = types
  .model('ClientsPageModel', {
    clients: types.map(ClientModel),
    searchTerm: types.maybe(types.maybeNull(types.string)),
    currentPage: 1,
    pageSize: 10,
    totalItems: types.maybe(types.maybeNull(types.number)),

    newCustomer: types.maybe(types.maybeNull(ClientLightModel)),
    addCustomerSuccessModalOpen: false,

    // delete customer
    customerToBeDeleted: types.maybeNull(types.reference(ClientModel)),
    confirmDeleteCustomerModalOpen: false,
    successDeleteCustomerModalOpen: false,
    // end delete customer

    editedCustomer: types.maybe(types.maybeNull(ClientModel)),
    editCustomerSuccessModalOpen: false,
    countries: types.frozen([]),
  })
  .volatile((self) => {
    return {
      filteredClients: types.frozen([]),
    };
  })
  .views((self) => {
    return {
      get countriesArray() {
        return Array.from(self.countries.values());
      },
      get filteredStates() {
        const customerType = self.newCustomer
          ? self.newCustomer
          : self.editedCustomer;
        const selectedCountry = customerType.country;
        return this.countriesArray.find((country) => {
          return country.name === selectedCountry;
        });
      },
      get clientsArray() {
        return Array.from(self.clients.values());
      },
    };
  })
  .actions((self) => {
    return {
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      getEditedCustomer(id) {
        setFetchingInProgress(true);

        getClient(id)
          .then((response) => {
            this.setEditedCustomer(response.data);
            this.getCountriesList();
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_CLIENTS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setEditedCustomer(customer) {
        self.editedCustomer = customer;
      },
      setEditClientSuccessModalOpen(status) {
        self.editCustomerSuccessModalOpen = status;
      },
      setAddClientSuccessModalOpen(status) {
        self.addCustomerSuccessModalOpen = status;
      },
      setNewCustomer(customer) {
        self.newCustomer = customer;
        this.getCountriesList();
      },
      setSearchTerm(text) {
        self.searchTerm = text;
      },
      setCurrentPage(number) {
        self.currentPage = number;
      },
      setTotalItems(number) {
        self.totalItems = number;
      },
      getClients({ page = null }) {
        setFetchingInProgress(true);
        this.clearClients();
        // if page is specified in request, set current page to page requested
        page && this.setCurrentPage(page);
        getClients(self.searchTerm, self.currentPage, self.pageSize)
          .then((response) => {
            response.data.clients.forEach((item) => {
              item.value = item.client_id.toString();
              item.label = item.company;
            });
            this.setTotalItems(response.data.total);
            this.setClients(response.data.clients);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_CLIENTS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setClients(items) {
        self.clients.clear();
        items.forEach((item) => {
          self.clients.put({
            ...item,
          });
        });
      },
      clearClients() {
        self.clients.clear();
      },
      getCustomersColumns() {
        return Object.values(customersColumnsFunc(getRoot(self)));
      },
      getFilteredClients(query, callback) {
        this.setFilteredClients([]);
        searchClients(query).then((response) => {
          response.data.forEach((item) => {
            item.value = item.client_id.toString();
            item.label = item.company;
          });
          this.setFilteredClients(response.data);
          callback(response.data);
        });
      },
      setFilteredClients(clients) {
        self.filteredClients = clients;
      },
      // delete customer
      setCustomerToBeDeleted(customer_id) {
        self.customerToBeDeleted = null;
        if (customer_id) {
          this.setConfirmDeleteCustomerModalOpen(true);
        }
        self.customerToBeDeleted = customer_id;
      },
      setConfirmDeleteCustomerModalOpen(status) {
        if (!status) {
          this.setCustomerToBeDeleted(null);
        }
        self.confirmDeleteCustomerModalOpen = status;
      },
      deleteCustomer() {
        setFetchingInProgress(true);
        deleteClient(self.customerToBeDeleted.client_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteCustomerModalOpen(false);
            this.setDeleteCustomerSuccessModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_CUSTOMER_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDeleteCustomerSuccessModalOpen(status) {
        self.successDeleteCustomerModalOpen = status;
      },
      // end delete customer
    };
  });
