import { notification } from 'antd';
import { types } from 'mobx-state-tree';
import {
  ACTION_REQUIRED_MESSAGE,
  DRIVER_REQUIRED_MESSAGE,
  PLANNED_START_DATE_REQUIRED_MESSAGE,
  PLANNED_START_TIME_REQUIRED_MESSAGE,
} from '../../../constants/dispatch';
import { rootInstance } from '../../RootModel';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import {
  createRouteAction,
  getRouteActionTrucks,
  createRouteActionLocations,
  getTrackingShipmentData,
  getShipmentRouteActions,
} from '../../../actions/dispatch';
import moment from 'moment';
import {
  ERROR_GETTING_ROUTE_ACTIONS,
  ERROR_GETTING_SHIPMENT_DATA,
  ERROR_SAVING_ROUTE_ACTION,
} from '../../../constants/dispatch/errors';
import { LocationModel } from '../location/LocationModel';
import { getShipment } from '../../../actions/shipments';

export const DispatchTripLightModel = types
  .model('DispatchTripLightModel', {
    action_id: types.maybe(types.maybeNull(types.number)),
    shipment_id: types.maybe(types.maybeNull(types.number)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    driver_internal_company_id: types.maybe(types.maybeNull(types.string)),
    team_driver_id: types.maybe(types.maybeNull(types.number)),
    start_location: types.maybe(types.maybeNull(types.string)),
    selected_location_id: types.maybe(types.maybeNull(types.number)),
    isLocalDispatchSelected: false,
    isHighwayDispatchSelected: false,
    tripDetailsExpanded: false,
    trailer_id: types.maybe(types.maybeNull(types.number)),
    truck: types.maybe(types.maybeNull(types.number)),
    start_date: types.maybe(types.maybeNull(types.string)),
    start_time: types.maybe(types.maybeNull(types.string)),
    note: types.maybe(types.maybeNull(types.string)),
    /** Error **/
    errorText: types.maybe(types.maybeNull(types.string)),
    bill_to: types.maybe(types.maybeNull(types.number)),
    isCustomerDisabled: false,
    selectedActionType: '',
    locationsList: types.map(LocationModel),
    updatedLocationsList: types.frozen([]),
    isLocationListChanged: false,
    enableAddingLocation: false,
    locationsLoaded: false,

    empty_miles: types.maybeNull(types.boolean),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get getSelectedLocationsList() {
        return Array.from(self.locationsList.values());
      },
    };
  })
  .actions((self) => {
    return {
      setLocationsLoaded(value) {
        self.locationsLoaded = value;
      },
      setEnableAddingLocation(value) {
        self.enableAddingLocation = value;
      },
      setIsLocationListChanged(value) {
        self.isLocationListChanged = value;
      },
      setUpdatedLocationsList(locations) {
        self.updatedLocationsList = locations;
      },
      setSelectedLocations(locations) {
        locations.forEach((item) => {
          self.locationsList.put({
            ...item,
          });
        });
        this.setLocationsLoaded(true);
      },
      setLocationByActionType(action_type) {
        self.selectedActionType = action_type;
      },
      getShipmentDetails(shipment_id) {
        this.setLocationsLoaded(false);
        setFetchingInProgress(true);
        getShipment(shipment_id)
          .then((response) => {
            let routeActionsLocations = [];
            if (response.data?.locations) {
              if (response.data.locations?.pickup_locations) {
                response.data.locations?.pickup_locations.forEach(
                  (location) => {
                    routeActionsLocations.push(location);
                  }
                );
              }
              if (response.data.locations?.delivery_locations) {
                response.data.locations?.delivery_locations.forEach(
                  (location) => {
                    routeActionsLocations.push(location);
                  }
                );
              }
            }
            this.setSelectedLocations(routeActionsLocations);
          })
          .catch((error) => {
            console.log('error: ', error);
            setFetchingInProgress(false);
          });
      },
      setSelectedShipmentID(shipment_id) {
        self.shipment_id = shipment_id;
        this.getShipmentDetails(shipment_id);
      },
      setIsCustomerDisabled(value) {
        self.isCustomerDisabled = value;
      },
      setActionID(id) {
        self.action_id = id;
      },
      setTeamDriver(team_driver_id) {
        self.team_driver_id = team_driver_id;
      },
      setCustomerToBill(customer_id) {
        self.bill_to = customer_id;
      },
      setNote(value) {
        self.note = value;
      },
      setTripDetailsExpanded(value) {
        self.tripDetailsExpanded = value;
      },
      setSelectedDriver(id) {
        self.driver_id = parseInt(id);
        const drivers = rootInstance.dispatchPageState.getOrganizeTourDrivers;
        let found = false;
        drivers.map((driver) => {
          if (found) {
            return;
          }
          if (driver?.driver_linked_truck_data) {
            if (driver.driver_id === self.driver_id) {
              setFetchingInProgress(true);
              getRouteActionTrucks(
                driver?.driver_linked_truck_data?.internal_company_id,
                1,
                1000
              )
                .then((response) => {
                  rootInstance.dispatchPageState.setOrganizeTourTrucks(
                    response.data.trucks
                  );
                  setFetchingInProgress(false);
                })
                .catch((error) => {
                  console.log('error: ', error);
                  setFetchingInProgress(false);
                });
              this.changeSelectedTruck(driver?.driver_linked_truck_data);
              found = true;
            }
          } else {
            rootInstance.dispatchPageState.setTruckToShowOnMap(null);
          }
        });
      },
      changeSelectedTruck(truck) {
        if (truck && typeof truck === 'object') {
          self.truck = truck.vehicle_id;
          rootInstance.dispatchPageState.setTruckToShowOnMap(truck);
          if (self.selectedActionType === 'Acquire') {
            let selected_truck =
              rootInstance.dispatchPageState.getOrganizeTourTrucks.filter(
                (truck) => truck.vehicle_id === truck.vehicle_id
              );
            if (selected_truck[0].location_name) {
              let selected_location =
                rootInstance.dispatchPageState.getAllAvailableLocations.filter(
                  (location) =>
                    location.location_name === selected_truck[0]?.location_name
                );
              this.setSelectedLocationID(selected_location[0].location_id);
            }
          }
        } else if (Number.isInteger(truck)) {
          self.truck = truck;
          setFetchingInProgress(true);
          getRouteActionTrucks('', 1, 1000)
            .then((response) => {
              let truck_data = response.data.trucks.filter(
                (truck) => truck.vehicle_id === self.truck
              );
              rootInstance.dispatchPageState.setTruckToShowOnMap(truck_data[0]);
              setFetchingInProgress(false);
            })
            .catch((error) => {
              console.log('Error: ', error);
              setFetchingInProgress(false);
            });
        } else {
          self.truck = null;
          rootInstance.dispatchPageState.setTruckToShowOnMap([]);
        }
      },
      changeSelectedTrailer(trailer, type = null) {
        if (type === 'modal') {
          self.trailer_id = parseInt(trailer.vehicle_id);
          rootInstance.dispatchPageState.setTrailerToShowOnMap(
            trailer.internal_company_id
          );
        } else {
          self.trailer_id = parseInt(trailer);
          rootInstance.dispatchPageState.setTrailerToShowOnMap(trailer);
        }
      },
      setIsLocalDispatchSelected(value) {
        self.isLocalDispatchSelected = value;
      },
      setIsHighwayDispatchSelected(value) {
        self.isHighwayDispatchSelected = value;
      },
      setStartLocation(value) {
        self.start_location = value;
      },
      clearSelectedLocation() {
        self.selected_location_id = null;
      },
      setSelectedLocationID(value) {
        if (value) {
          self.selected_location_id = value;
          let selectedLocationName =
            rootInstance.dispatchPageState.getAllAvailableLocations.filter(
              (location) => location.location_id === value
            );
          this.setStartLocation(selectedLocationName[0].location_name);
        }
      },
      setStartDate(date) {
        self.start_date = date;
      },
      setStartTime(time) {
        self.start_time = time;
      },
      setEmptyMiles(emptyMiles) {
        self.empty_miles = emptyMiles;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      validateForm() {
        if (!self.driver_id) {
          this.setErrorText(DRIVER_REQUIRED_MESSAGE);
        } else if (!self.action_id) {
          this.setErrorText(ACTION_REQUIRED_MESSAGE);
        } else if (!self.start_date) {
          this.setErrorText(PLANNED_START_DATE_REQUIRED_MESSAGE);
        } else if (!self.start_time) {
          this.setErrorText(PLANNED_START_TIME_REQUIRED_MESSAGE);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveNewTrip() {
        let selectedLocationsList = [];
        /** Solve ordering issue **/
        if (self.isLocationListChanged) {
          self.updatedLocationsList.forEach((location) =>
            selectedLocationsList.push(location.location_id)
          );
        } else {
          self.getSelectedLocationsList.forEach((location) => {
            selectedLocationsList.push(location.location_id);
          });
        }

        if (this.validateForm()) {
          let time = moment
            .utc(`${self.start_date} ${self.start_time}`)
            .format();
          let data = {
            action_id: self.action_id, //Required
            driver_id: self.driver_id, //Required
            location: self.selected_location_id, //Required
            planned_start: time, //Required
            shipment_id: self.shipment_id,
            team_driver_id: self.team_driver_id,
            trailer_id: self.trailer_id,
            truck_id: self.truck,
            notes: self.note,
            bill_to: self.bill_to,

            empty_miles: self.empty_miles || false, // required
          };

          setFetchingInProgress(true);

          // createRouteActionLocations
          createRouteAction(data)
            .then((response) => {
              createRouteActionLocations(
                response.data.route_action_id,
                selectedLocationsList
              )
                .then(() => {
                  rootInstance.dispatchPageState.setIsOrganizeTourSuccessModalOpen(
                    true
                  );

                  if (
                    rootInstance.dispatchPageState.trackingShipment.length > 0
                  ) {
                    rootInstance.dispatchPageState.clearTrackingShipment();
                    getShipmentRouteActions(
                      rootInstance.dispatchPageState.selectedShipmentDetails
                        .shipment_id
                    )
                      .then((response) => {
                        rootInstance.dispatchPageState.settingSavedRouteActionItems(
                          response.data
                        );
                        rootInstance.dispatchPageState.removeEditingRouteItem();
                        getTrackingShipmentData(
                          rootInstance.dispatchPageState.selectedShipmentDetails
                            .shipment_id
                        )
                          .then((response) => {
                            rootInstance.dispatchPageState.setTrackingShipment(
                              response.data
                            );
                            rootInstance.dispatchPageState.setPlannerOpened(
                              false
                            );

                            setFetchingInProgress(false);
                          })
                          .catch((error) => {
                            console.log('Error: ', error);
                            const errorText = getErrorText(error);
                            notification.error({
                              message: ERROR_GETTING_SHIPMENT_DATA,
                              description: errorText,
                            });
                            setFetchingInProgress(false);
                          });
                      })
                      .catch((error) => {
                        console.log('Error: ', error);
                        const errorText = getErrorText(error);
                        notification.error({
                          message: ERROR_GETTING_ROUTE_ACTIONS,
                          description: errorText,
                        });
                        setFetchingInProgress(false);
                      });
                    // rootInstance.dispatchPageState.clearTrackingShipment();
                    // getTrackingShipmentData(
                    //   rootInstance.dispatchPageState.selectedShipmentDetails
                    //     .shipment_id
                    // )
                    //   .then((response) => {
                    //     rootInstance.dispatchPageState.setTrackingShipment(
                    //       response.data
                    //     );
                    //
                    //     setFetchingInProgress(false);
                    //   })
                    //   .catch((error) => {
                    //     console.log('Error: ', error);
                    //     const errorText = getErrorText(error);
                    //     notification.error({
                    //       message: ERROR_SAVING_ROUTE_ACTION,
                    //       description: errorText,
                    //     });
                    //     setFetchingInProgress(false);
                    //   });
                  } else {
                    setFetchingInProgress(false);
                    rootInstance.dispatchPageState.beforeExitClearSelectedShipmentId();
                  }
                  rootInstance.dispatchPageState.clearNewRouteActionItem();
                })
                .catch((error) => {
                  console.log('Error: ', error);
                  const errorText = getErrorText(error);
                  notification.error({
                    message: ERROR_SAVING_ROUTE_ACTION,
                    description: errorText,
                  });
                  setFetchingInProgress(false);
                });
            })
            .catch((error) => {
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: ERROR_SAVING_ROUTE_ACTION,
                description: errorText,
              });
              setFetchingInProgress(false);
            });
        }
      },
    };
  });
