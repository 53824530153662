import React from 'react';
import { useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import EditEmployeeComponent from '../../components/employees/EditEmployee/EditEmployeeComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import {
  MODAL_SUCCESS_TITLE,
  MODAL_SUCCESS_EDIT_PROFILE_TEXT,
  MODAL_SUCCESS_EDIT_PROFILE_BUTTON_TEXT,
  WELCOME_MODAL_TITLE,
  WELCOME_MODAL_TEXT,
  WELCOME_MODAL_BUTTON_TEXT,
} from '../../constants';
import SuccessModal from '../../components/SuccessModal';

const AccountPage = () => {
  const {
    loginForm: { currentUser },
  } = useMst();

  if (currentUser && currentUser.firstTimeInApp) {
    currentUser.setSuccessModalOpen(true);
  }

  const dashboardLink = PageRoutes.Dashboard.name;
  const accountLink = PageRoutes.Account.name;

  const closeFirstTimeInAppWelcomeModal = () => {
    localStorage.removeItem('first_time_in_app');
    currentUser.setSuccessModalOpen(false);
  };

  return (
    currentUser && (
      <div>
        <div className="page edit-account-page">
          {currentUser.firstTimeInApp ? (
            <SuccessModal
              title={WELCOME_MODAL_TITLE}
              text={WELCOME_MODAL_TEXT}
              buttonText={WELCOME_MODAL_BUTTON_TEXT}
              backLink={accountLink}
              open={currentUser.successModalOpen}
              closeModal={closeFirstTimeInAppWelcomeModal}
            />
          ) : (
            <SuccessModal
              title={MODAL_SUCCESS_TITLE}
              text={MODAL_SUCCESS_EDIT_PROFILE_TEXT}
              buttonText={MODAL_SUCCESS_EDIT_PROFILE_BUTTON_TEXT}
              backLink={accountLink}
              open={currentUser.successModalOpen}
              closeModal={() => currentUser.setSuccessModalOpen(false)}
            />
          )}
          <BreadcrumsComponent
            breadcrumbsBackLink={dashboardLink}
            page={PageRoutes.Account.name}
          />
          <div className="page-content">
            <EditEmployeeComponent />
          </div>
        </div>
      </div>
    )
  );
};

export default observer(AccountPage);
