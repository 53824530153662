import React from 'react';
import { observer } from 'mobx-react';
import { ASSOCIATIONS_HISTORY } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import ViewAssociationsTable from './ViewAssociationsTable';
import { useMst } from '../../../state/RootModel';

const ViewInspectionsSection = () => {
  const {
    fleetPageState: { viewedTruck },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedTruck.setIsAssociationsSectionOpen(
              !viewedTruck.isAssociationsSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedTruck.isAssociationsSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {ASSOCIATIONS_HISTORY}
          </span>
        </div>
      </div>
      {viewedTruck?.isAssociationsSectionOpen && <ViewAssociationsTable />}
    </div>
  );
};

export default observer(ViewInspectionsSection);
