import PageRoutes from '../../utils/PageRoutes';
import organizations from '../../assets/images/menu/organizations.svg';
import organizationsActive from '../../assets/images/menu/organizations-active.svg';
import organizationSettings from '../../assets/images/menu/organization-settings.svg';
import organizationSettingsActive from '../../assets/images/menu/organization-settings-active.svg';
import dashboard from '../../assets/images/menu/dashboard.svg';
import dashboardActive from '../../assets/images/menu/dashboard-active.svg';
import brokerage from '../../assets/images/menu/brokerage.svg';
import brokerageActive from '../../assets/images/menu/brokerage-active.svg';
import employees from '../../assets/images/menu/employees.svg';
import employeesActive from '../../assets/images/menu/employees-active.svg';
import fleet from '../../assets/images/menu/fleet.svg';
import fleetActive from '../../assets/images/menu/fleet-active.svg';
import payroll from '../../assets/images/menu/payroll.svg';
import payrollActive from '../../assets/images/menu/payroll-active.svg';
import reports from '../../assets/images/menu/reports.svg';
import reportsActive from '../../assets/images/menu/reports-active.svg';
import dispatch from '../../assets/images/menu/dispatch-new.svg';
import dispatchActive from '../../assets/images/menu/dispatch-active.svg';
import support from '../../assets/images/menu/support.svg';
import supportActive from '../../assets/images/menu/support-active.svg';
import logout from '../../assets/images/menu/logout.svg';
import {
  DASHBOARD,
  EMPLOYEES,
  FLEET,
  PAYROLL,
  REPORTS,
  SUPPORT,
  LOGOUT,
  DISPATCH,
  ORGANIZATIONS,
  ORGANIZATION,
  SHIPMENT_BOARD,
} from '../../constants/menu';
import {
  EMPLOYEE_SEARCH_PLACEHOLDER_TEXT,
  FLEET_SEARCH_PLACEHOLDER_TEXT,
  BROKERAGE_SEARCH_PLACEHOLDER_TEXT,
  COMPENSATION_SEARCH_PLACEHOLDER_TEXT,
} from '../../constants';

export const sideMenuItems = {
  '1': {
    id: '1',
    title: ORGANIZATIONS,
    icon: organizations,
    activeIcon: organizationsActive,
    limitedTo: PageRoutes.Organizations.limitedTo,
    route: PageRoutes.Organizations.name,
  },
  '2': {
    id: '2',
    title: ORGANIZATION,
    icon: organizationSettings,
    activeIcon: organizationSettingsActive,
    limitedTo: PageRoutes.Organization.limitedTo,
    route: PageRoutes.Organization.name,
  },
  '3': {
    id: '3',
    title: DASHBOARD,
    icon: dashboard,
    activeIcon: dashboardActive,
    limitedTo: PageRoutes.Dashboard.limitedTo,
    route: PageRoutes.Dashboard.name,
  },
  '4': {
    id: '4',
    title: EMPLOYEES,
    icon: employees,
    activeIcon: employeesActive,
    limitedTo: PageRoutes.Employees.limitedTo,
    route: PageRoutes.Employees.name,
    searchPlaceholder: EMPLOYEE_SEARCH_PLACEHOLDER_TEXT,
  },
  '5': {
    id: '5',
    title: FLEET,
    icon: fleet,
    activeIcon: fleetActive,
    limitedTo: PageRoutes.Fleet.limitedTo,
    route: PageRoutes.Fleet.name,
    searchPlaceholder: FLEET_SEARCH_PLACEHOLDER_TEXT,
  },
  '6': {
    id: '6',
    title: SHIPMENT_BOARD,
    icon: brokerage,
    activeIcon: brokerageActive,
    limitedTo: PageRoutes.Brokerage.limitedTo,
    route: PageRoutes.Brokerage.name,
    searchPlaceholder: BROKERAGE_SEARCH_PLACEHOLDER_TEXT,
  },
  '7': {
    id: '7',
    title: DISPATCH,
    icon: dispatch,
    activeIcon: dispatchActive,
    limitedTo: PageRoutes.Dispatch.limitedTo,
    route: PageRoutes.Dispatch.name,
  },
  '8': {
    id: '8',
    title: PAYROLL,
    icon: payroll,
    activeIcon: payrollActive,
    limitedTo: PageRoutes.Payroll.limitedTo,
    route: PageRoutes.Payroll.name,
    searchPlaceholder: COMPENSATION_SEARCH_PLACEHOLDER_TEXT,
  },
  '9': {
    id: '9',
    title: REPORTS,
    icon: reports,
    activeIcon: reportsActive,
    limitedTo: PageRoutes.Reports.limitedTo,
    route: PageRoutes.Reports.name,
  },
  '10': {
    id: '10',
    title: SUPPORT,
    icon: support,
    activeIcon: supportActive,
    limitedTo: PageRoutes.Support.limitedTo,
    route: PageRoutes.Support.name,
  },
  '11': {
    id: '11',
    title: LOGOUT,
    icon: logout,
  },
};
