import {
  getALlRouteActions,
  getRouteActionByID,
  getRouteActionDrivers,
  getRouteActionShipments,
  getRouteActionTrailers,
  getRouteActionTrucks,
  getShipmentRouteActions,
  getTrackingShipmentData,
} from '../../actions/dispatch';
import { getClients } from '../../actions/clients';
import { notification } from 'antd';
import { getErrorText } from '../../utils/methods';
import {
  FETCH_CUSTOMERS_ERROR,
  FETCH_DRIVERS_ERROR,
  FETCH_TRAILERS_ERROR,
  FETCH_TRUCKS_ERROR,
} from '../../constants';
import { getShipment } from '../../actions/shipments';
import { getOrganizationDefaultLocation } from '../../actions/organizations';

export const getShipmentDetails = (shipment_id) => {
  return new Promise((resolve, reject) => {
    getShipment(shipment_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShipmentOrganizationDefaultLocation = (organization_id) => {
  return new Promise((resolve, reject) => {
    getOrganizationDefaultLocation(organization_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRouteActionAllShipmentsPromise = (model) => {
  return new Promise((resolve, reject) => {
    getRouteActionShipments()
      .then((response) => {
        model.setOrganizeTourShipments(response.data.shipments);
        resolve(response.data.shipments);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getRouteActionCustomersPromise = (model) => {
  return new Promise((resolve, reject) => {
    getClients('', 1, 100)
      .then((response) => {
        model.setOrganizeTourCustomers(response.data.clients);
        resolve(response.data);
      })
      .catch((error) => {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_CUSTOMERS_ERROR,
          description: errorText,
        });
        reject(error);
      });
  });
};
export const getRouteActionDriversPromise = (model, filter = null) => {
  return new Promise((resolve, reject) => {
    getRouteActionDrivers('All', 1, '', filter)
      .then((response) => {
        model.setOrganizeTourDrivers(response.data.drivers);
        resolve(response.data.drivers);
      })
      .catch((error) => {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_DRIVERS_ERROR,
          description: errorText,
        });
        reject(error);
      });
  });
};
export const getRouteActionTrucksPromise = (
  model,
  filter = null,
  page = 1,
  items = 10
) => {
  return new Promise((resolve, reject) => {
    getRouteActionTrucks(filter, page, items)
      .then((response) => {
        model.setOrganizeTourTrucks(response.data.trucks);
        resolve(response.data.drivers);
      })
      .catch((error) => {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_TRUCKS_ERROR,
          description: errorText,
        });
        reject(error);
      });
  });
};
export const getRouteActionTrailersPromise = (model, trailer_id) => {
  return new Promise((resolve, reject) => {
    getRouteActionTrailers(1, '', trailer_id)
      .then((response) => {
        model.setOrganizeTourTrailers(response.data.trailers);
        resolve(response.data.trailers);
      })
      .catch((error) => {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_TRAILERS_ERROR,
          description: errorText,
        });
        reject(error);
      });
  });
};
export const getRouteActions = (model) => {
  return new Promise((resolve, reject) => {
    getALlRouteActions()
      .then((response) => {
        model.setOrganizeTourAllActions(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getALlRouteActionsPromise = (model) => {
  return new Promise((resolve, reject) => {
    getALlRouteActions()
      .then((response) => {
        model.setOrganizeTourAllActions(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShipmentFullDetailsPromise = (model) => {
  return new Promise((resolve, reject) => {
    getShipment(model.selectedShipment)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getRouteActionDetailsPromise = (route_action_id) => {
  return new Promise((resolve, reject) => {
    getRouteActionByID(route_action_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShipmentRouteActionsPromise = (shipment_id) => {
  return new Promise((resolve, reject) => {
    getShipmentRouteActions(shipment_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTrackingShipmentDetailsPromise = (model, shipment_id) => {
  return new Promise((resolve, reject) => {
    getTrackingShipmentData(shipment_id)
      .then((response) => {
        model.setSelectedShipment(shipment_id);
        model.setTrackingShipment(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getShipmentRouteActionsAdditionalPromise = (
  model,
  shipment_id
) => {
  return new Promise((resolve, reject) => {
    getShipmentRouteActions(shipment_id)
      .then((response) => {
        model.settingSavedRouteActionItems(response.data);
        if (response.data.length > 0) {
          response.data.map((action) => {
            model.setSelectedShipmentToDraw(action);
            model.getShipmentDetails(shipment_id);
          });
        }
        model.setIsTrackingShipmentDetailsOpened(true);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
