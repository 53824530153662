import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Modal } from 'antd';
import { CONFIRM, CANCEL, CONFIRM_DELETE_USERS_TEXT } from '../../constants';

const ConfirmDeleteEmployeesModal = () => {
  const {
    employeesPageState: {
      confirmDeleteEmployeesModalOpen,
      setConfirmDeleteEmployeesModalOpen,
      deleteSelectedUsers,
    },
  } = useMst();

  const onCancel = () => {
    setConfirmDeleteEmployeesModalOpen(false);
  };

  const onConfirm = () => {
    deleteSelectedUsers();
  };

  return (
    <div>
      <Modal
        visible={confirmDeleteEmployeesModalOpen}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={400}
        className="confirm-delete-modal"
      >
        <div className="p32">
          <div>
            <p>{CONFIRM_DELETE_USERS_TEXT}</p>
          </div>
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ConfirmDeleteEmployeesModal);
