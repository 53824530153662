import { getShipment } from '../../actions/shipments';
import { getCurrencyTypes } from '../../actions/probills';

export const probillShipment = (model) => {
  return new Promise((resolve, reject) => {
    getShipment(model.shipment_id)
      .then((response) => {
        model.setShipment(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const currencyTypes = (model) => {
  return new Promise((resolve, reject) => {
    getCurrencyTypes()
      .then((response) => {
        model.setCurrencyTypes(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
