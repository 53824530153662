import { Empty, Table } from 'antd';
import React from 'react';
import { EMPTY_COMPENSATION_DEDUCTIONS_TABLE_TEXT } from '../../../constants/payroll';
import { useMst } from '../../../state/RootModel';
import { observer } from 'mobx-react';

export const CompensationsDeductionsComponent = () => {
  const {
    payrollPageState: {
      compensationDeductionsTypes,
      getCompensationDeductionsTableColumns,
    },
  } = useMst();

  return (
    <div className="pv20 compensation-structures-component">
      <Table
        className="compensations-table"
        rowKey={(record, index) => index.toString()}
        columns={getCompensationDeductionsTableColumns()}
        dataSource={compensationDeductionsTypes.map((type) => {
          return {
            compensation_deduction: type,
          };
        })}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_COMPENSATION_DEDUCTIONS_TABLE_TEXT}
            />
          ),
        }}
      />
    </div>
  );
};

export default observer(CompensationsDeductionsComponent);
