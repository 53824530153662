import React from 'react';
import { useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import EditTrailerComponent from '../../components/fleet/EditTrailer/EditTrailerComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import SuccessModal from '../../components/SuccessModal';
import OutOfServiceModal from '../../components/fleet/OutOfServiceModal';
import AddInspectionModal from '../../components/fleet/AddInspectionModal';
import EditInspectionModal from '../../components/fleet/EditInspectionModal';
import AddMaintenanceModal from '../../components/fleet/AddMaintenanceModal';
import EditMaintenanceModal from '../../components/fleet/EditMaintenanceModal';
import ConfirmDeleteInspectionModal from '../../components/fleet/ConfirmDeleteInspectionModal';
import ConfirmDeleteMaintenanceModal from '../../components/fleet/ConfirmDeleteMaintenanceModal';
import PageRoutes from '../../utils/PageRoutes';
import {
  EDIT_TRAILER,
  MODAL_SUCCESS_EDIT_TRAILER_TEXT,
  MODAL_SUCCESS_EDIT_TRAILER_BUTTON_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../constants';

const EditTrailerPage = () => {
  const {
    fleetPageState: { editedTrailer },
  } = useMst();

  const backLink = PageRoutes.EditTrailer.name;

  const params = { trailerId: editedTrailer?.vehicle_id.toString() };

  return (
    <div>
      <div className="page add-truck-page">
        <BreadcrumsComponent
          page={EDIT_TRAILER}
          breadcrumbsBackLink={PageRoutes.Fleet.name}
        />
        <SuccessModal
          title={MODAL_SUCCESS_TITLE}
          text={MODAL_SUCCESS_EDIT_TRAILER_TEXT}
          buttonText={MODAL_SUCCESS_EDIT_TRAILER_BUTTON_TEXT}
          backLink={backLink}
          params={params}
          open={editedTrailer?.successModalOpen}
          closeModal={() => editedTrailer.setSuccessModalOpen(false)}
        />
        <AddInspectionModal />
        <EditInspectionModal />
        <OutOfServiceModal />
        <ConfirmDeleteInspectionModal />
        <AddMaintenanceModal />
        <EditMaintenanceModal />
        <ConfirmDeleteMaintenanceModal />
        <div className="page-content">
          <EditTrailerComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(EditTrailerPage);
