import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Select } from 'antd';
import ErrorBox from '../../../ErrorBox';
import { useMst } from '../../../../state/RootModel';
import addOtherIcon from '../../../../assets/images/add-other.svg';
import FormPageTitle from '../../../FormPageTitle';
import { CANCEL, CREATE_NEW, ADD, ITEM } from '../../../../constants';
import NewShipmentCharge from './NewShipmentCharge';
import NewRouteAction from './NewRouteAction';
import NewShipmentDocumentTypeComponent from './NewShipmentDocumentType';
import NewShipmentStatusComponent from './NewShipmentStatus';

const { Option } = Select;

const AddShipmentSpecificsModal = () => {
  const {
    shipmentSpecificsPageState: {
      setNewShipmentSpecificModalOpen,
      newShipmentSpecificModalOpen,
      availableShipmentSpecificTypes,
      selectedNewShipmentSpecificType,
      setSelectedNewShipmentSpecificType,
      newShipmentSpecific,
      newShipmentCharge,
      newRouteAction,
      newShipmentDocumentType,
      newShipmentStatus,
    },
  } = useMst();

  const onCancel = () => {
    setNewShipmentSpecificModalOpen(false);
  };

  const onAdd = () => {
    newShipmentSpecific.saveItem();
    // newLocation.setOwnerId(customer.client_id);
  };

  const onItemSelect = (value) => {
    setSelectedNewShipmentSpecificType(value);
  };

  return (
    <div>
      <Modal
        visible={newShipmentSpecificModalOpen}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={400}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 location-modal-content">
          <div className="align-center">
            <FormPageTitle image={addOtherIcon} title={CREATE_NEW} />
          </div>

          <div className="input-wrapper w100 mt10">
            <div className="input-label justify-between">
              <span>{ITEM}</span>
              <span className="color-secondary">*</span>
            </div>
            <Select
              className="text-capitalize"
              style={{ width: '100%' }}
              onChange={onItemSelect}
              value={selectedNewShipmentSpecificType}
            >
              {availableShipmentSpecificTypes.map((type) => (
                <Option key={type} value={type} className="text-capitalize">
                  {type}
                </Option>
              ))}
            </Select>
          </div>

          {newShipmentCharge && <NewShipmentCharge />}
          {newRouteAction && <NewRouteAction />}
          {newShipmentDocumentType && <NewShipmentDocumentTypeComponent />}
          {newShipmentStatus && <NewShipmentStatusComponent />}

          <ErrorBox obj={newShipmentSpecific} />

          <div className="justify-end mt20">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onAdd}>
              {ADD}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddShipmentSpecificsModal);
