import React from 'react';
import './styles/styles.scss';
import './App.css';
import PropTypes from 'prop-types';
import { useMst } from './state/RootModel';
import { observer } from 'mobx-react';
import { StateRouter } from './components/StateRouter';
import Loader from './components/Loader';
import SideMenu from './components/sideMenu/SideMenu';
import Header from './components/Header';

const App = () => {
  const {
    loginForm: { isAuth },
    loaderState: { fetchingInProgress },
    router,
  } = useMst();

  return (
    <>
      {/* <Header />
      <SideMenu /> */}
      {fetchingInProgress && <Loader />}
      {isAuth && <Header />}
      {isAuth && <SideMenu />}
      <div>
        <StateRouter router={router} />
      </div>
    </>
  );
};

App.propTypes = {
  state: PropTypes.object,
};

export default observer(App);
