import { getRoot, types } from 'mobx-state-tree';
import { RootType } from './ModelTypes';
import { getCurrentUserPermissions } from '../actions/account';
import {
  getErrorText,
  getIsUserPermittedForRoute,
  setFetchingInProgress,
} from '../utils/methods';
import { notification } from 'antd';
import { FETCH_PERMISSIONS_ERROR } from '../constants';
import { permissions } from '../constants/permissions';

export const UserModel = types
  .model('UserModel', {
    permissions: types.array(types.string),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      // ***PERMISSION GETTERS*** //
      get canRemoveCarrierEquipments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_CARRIER_EQUIPMENTS,
        ]);
      },
      get canAddCarrierEquipments() {
        return getIsUserPermittedForRoute([permissions.ADD_CARRIER_EQUIPMENTS]);
      },
      get canViewCarrierEquipments() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_CARRIER_EQUIPMENTS,
        ]);
      },
      get canViewPermissionsForRoles() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_PERMISSIONS_FOR_ROLES,
        ]);
      },
      get canRemovePermissionsForRoles() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_PERMISSIONS_FOR_ROLES,
        ]);
      },
      get canRemoveRoles() {
        return getIsUserPermittedForRoute([permissions.REMOVE_ROLES]);
      },
      get canAddUserRoles() {
        return getIsUserPermittedForRoute([permissions.ADD_USER_ROLES]);
      },
      get canViewUserRoles() {
        return getIsUserPermittedForRoute([permissions.VIEW_USER_ROLES]);
      },
      get canRemoveUserRoles() {
        return getIsUserPermittedForRoute([permissions.REMOVE_USER_ROLES]);
      },
      get canAddUserActiveRoles() {
        return getIsUserPermittedForRoute([permissions.ADD_USER_ACTIVE_ROLES]);
      },
      get canAddDriverDrivesVehicleAssociations() {
        return getIsUserPermittedForRoute([
          permissions.ADD_DRIVER_DRIVES_VEHICLE_ASSOCIATIONS,
        ]);
      },
      get canAddNewUsers() {
        return getIsUserPermittedForRoute([permissions.ADD_NEW_USERS]);
      },
      get canViewUsers() {
        return getIsUserPermittedForRoute([permissions.VIEW_USERS]);
      },
      get canEditUsers() {
        return getIsUserPermittedForRoute([permissions.EDIT_USERS]);
      },
      get canEditUserAbsences() {
        return getIsUserPermittedForRoute([permissions.EDIT_USER_ABSENCES]);
      },
      get canViewUserDepartments() {
        return getIsUserPermittedForRoute([permissions.VIEW_USERS_DEPARTMENTS]);
      },
      get canDeactivateUserAccount() {
        return getIsUserPermittedForRoute([
          permissions.DEACTIVATE_USER_ACCOUNT,
        ]);
      },
      get canActivateUserAccount() {
        return getIsUserPermittedForRoute([permissions.ACTIVATE_USER_ACCOUNT]);
      },
      get canViewUserDocumentTypes() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_USER_DOCUMENT_TYPES,
        ]);
      },
      get canViewUserDocuments() {
        return getIsUserPermittedForRoute([permissions.VIEW_USER_DOCUMENTS]);
      },
      get canRemoveUserDocuments() {
        return getIsUserPermittedForRoute([permissions.REMOVE_USER_DOCUMENTS]);
      },
      get canViewDepartments() {
        return getIsUserPermittedForRoute([permissions.VIEW_DEPARTMENTS]);
      },
      get canEditDepartments() {
        return getIsUserPermittedForRoute([permissions.EDIT_DEPARTMENTS]);
      },
      get canAddUserToDepartment() {
        return getIsUserPermittedForRoute([permissions.ADD_USER_TO_DEPARTMENT]);
      },
      get canRemoveDepartment() {
        return getIsUserPermittedForRoute([permissions.REMOVE_DEPARTMENT]);
      },
      get canAddNewDepartment() {
        return getIsUserPermittedForRoute([permissions.ADD_NEW_DEPARTMENTS]);
      },
      get canRemoveUsersFromDepartment() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_USERS_FROM_DEPARTMENT,
        ]);
      },
      get canViewDrivers() {
        return getIsUserPermittedForRoute([permissions.VIEW_DRIVERS]);
      },
      get canAddDrivers() {
        return getIsUserPermittedForRoute([permissions.ADD_DRIVERS]);
      },
      get canEditDrivers() {
        return getIsUserPermittedForRoute([permissions.EDIT_DRIVERS]);
      },
      get canRemoveDrivers() {
        return getIsUserPermittedForRoute([permissions.REMOVE_DRIVERS]);
      },
      get canActivateDrivers() {
        return getIsUserPermittedForRoute([permissions.ACTIVATE_DRIVERS]);
      },
      get canViewDriverLicences() {
        return getIsUserPermittedForRoute([permissions.VIEW_DRIVER_LICENCES]);
      },
      get canRemoveDriverAbsences() {
        return getIsUserPermittedForRoute([permissions.REMOVE_DRIVER_ABSENCES]);
      },
      get canViewDriverAbsences() {
        return getIsUserPermittedForRoute([permissions.VIEW_DRIVER_ABSENCES]);
      },
      get canEditDriverAbsences() {
        return getIsUserPermittedForRoute([permissions.EDIT_DRIVER_ABSENCES]);
      },
      get canAddDriverAbsences() {
        return getIsUserPermittedForRoute([permissions.ADD_DRIVER_ABSENCES]);
      },
      get canViewDriverLicenceDocuments() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_DRIVER_LICENCE_DOCUMENTS,
        ]);
      },
      get canRemoveDriverLicenceDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_DRIVER_LICENCE_DOCUMENTS,
        ]);
      },
      get canAddDriverLicenceDocuments() {
        return getIsUserPermittedForRoute([
          permissions.ADD_DRIVER_LICENCE_DOCUMENTS,
        ]);
      },
      get canAddDriverVehicleAssociations() {
        return getIsUserPermittedForRoute([
          permissions.ADD_DRIVER_VEHICLE_ASSOCIATIONS,
        ]);
      },
      get canViewDriverVehicleAssociations() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_DRIVER_VEHICLE_ASSOCIATIONS,
        ]);
      },
      get canEditDriverVehicleAssociations() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_DRIVER_VEHICLE_ASSOCIATIONS,
        ]);
      },
      get canViewVehicles() {
        return getIsUserPermittedForRoute([permissions.VIEW_VEHICLES]);
      },
      get canEditVehicleRegistration() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_VEHICLE_REGISTRATIONS,
        ]);
      },
      get canAddVehicleRegistration() {
        return getIsUserPermittedForRoute([
          permissions.ADD_VEHICLE_REGISTRATIONS,
        ]);
      },
      get canAddVehicleDocuments() {
        return getIsUserPermittedForRoute([permissions.ADD_VEHICLE_DOCUMENTS]);
      },
      get canAddVehicles() {
        return getIsUserPermittedForRoute([permissions.ADD_VEHICLES]);
      },
      get canEditVehicles() {
        return getIsUserPermittedForRoute([permissions.EDIT_VEHICLES]);
      },
      get canRemoveVehicles() {
        return getIsUserPermittedForRoute([permissions.REMOVE_VEHICLES]);
      },
      get canViewVehicleRegistration() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_VEHICLE_REGISTRATIONS,
        ]);
      },
      get canViewVehicleDocuments() {
        return getIsUserPermittedForRoute([permissions.VIEW_VEHICLE_DOCUMENTS]);
      },
      get canRemoveVehicleDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_VEHICLE_DOCUMENTS,
        ]);
      },
      get canViewVehicleTollProviders() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_VEHICLE_TOLL_PROVIDERS,
        ]);
      },
      get canViewVehicleInspections() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_VEHICLE_INSPECTIONS,
        ]);
      },
      get canEditShipments() {
        return getIsUserPermittedForRoute([permissions.EDIT_SHIPMENTS]);
      },
      get canAddShipments() {
        return getIsUserPermittedForRoute([permissions.ADD_SHIPMENTS]);
      },
      get canViewShipments() {
        return getIsUserPermittedForRoute([permissions.VIEW_SHIPMENTS]);
      },
      get canRemoveShipments() {
        return getIsUserPermittedForRoute([permissions.REMOVE_SHIPMENTS]);
      },
      get canRemoveProbills() {
        return getIsUserPermittedForRoute([permissions.REMOVE_PROBILLS]);
      },
      get canAddProbills() {
        return getIsUserPermittedForRoute([permissions.ADD_PROBILLS]);
      },
      get canViewProbills() {
        return getIsUserPermittedForRoute([permissions.VIEW_PROBILLS]);
      },
      get canEditProbills() {
        return getIsUserPermittedForRoute([permissions.EDIT_PROBILLS]);
      },
      get canRemoveRouteAction() {
        return getIsUserPermittedForRoute([permissions.REMOVE_ROUTE_ACTION]);
      },
      get canAddRouteAction() {
        return getIsUserPermittedForRoute([permissions.ADD_ROUTE_ACTION]);
      },
      get canEditRouteAction() {
        return getIsUserPermittedForRoute([permissions.EDIT_ROUTE_ACTION]);
      },
      get canViewRouteAction() {
        return getIsUserPermittedForRoute([permissions.VIEW_ROUTE_ACTION]);
      },
      get canViewShipmentDocuments() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_SHIPMENT_DOCUMENTS,
        ]);
      },
      get canAddShipmentDocuments() {
        return getIsUserPermittedForRoute([permissions.ADD_SHIPMENT_DOCUMENTS]);
      },
      get canRemoveShipmentDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_SHIPMENT_DOCUMENTS,
        ]);
      },
      get canViewShipmentDocumentTypes() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_SHIPMENT_DOCUMENT_TYPES,
        ]);
      },
      get canAddShipmentDocumentTypes() {
        return getIsUserPermittedForRoute([
          permissions.ADD_SHIPMENT_DOCUMENT_TYPES,
        ]);
      },
      get canEditShipmentDocumentTypes() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_SHIPMENT_DOCUMENT_TYPES,
        ]);
      },
      get canViewEquipments() {
        return getIsUserPermittedForRoute([permissions.VIEW_EQUIPMENTS]);
      },
      get canViewCarriers() {
        return getIsUserPermittedForRoute([permissions.VIEW_CARRIERS]);
      },
      get canAddCarriers() {
        return getIsUserPermittedForRoute([permissions.ADD_CARRIERS]);
      },
      get canEditCarriers() {
        return getIsUserPermittedForRoute([permissions.EDIT_CARRIERS]);
      },
      get canRemoveCarriers() {
        return getIsUserPermittedForRoute([permissions.REMOVE_CARRIERS]);
      },
      get canViewUserAbsences() {
        return getIsUserPermittedForRoute([permissions.VIEW_USER_ABSENCES]);
      },
      get canRemoveUserAbsences() {
        return getIsUserPermittedForRoute([permissions.REMOVE_USER_ABSENCES]);
      },
      get canAddUserAbsences() {
        return getIsUserPermittedForRoute([permissions.ADD_USER_ABSENCES]);
      },
      get canRemoveAbsenceDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_ABSENCE_DOCUMENTS,
        ]);
      },
      get canViewAbsenceDocuments() {
        return getIsUserPermittedForRoute([permissions.VIEW_ABSENCE_DOCUMENTS]);
      },
      get canAddAbsenceDocuments() {
        return getIsUserPermittedForRoute([permissions.ADD_ABSENCE_DOCUMENTS]);
      },
      get canEditDriverLicences() {
        return getIsUserPermittedForRoute([permissions.EDIT_DRIVER_LICENCES]);
      },
      get canAddDriverLicences() {
        return getIsUserPermittedForRoute([permissions.ADD_DRIVER_LICENCES]);
      },
      get canAddDriverCompliances() {
        return getIsUserPermittedForRoute([permissions.ADD_DRIVER_COMPLIANCES]);
      },
      get canViewDriverCompliances() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_DRIVER_COMPLIANCES,
        ]);
      },
      get canEditDriverCompliances() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_DRIVER_COMPLIANCES,
        ]);
      },
      get canAddCompliancyDocuments() {
        return getIsUserPermittedForRoute([
          permissions.ADD_COMPLIANCY_DOCUMENTS,
        ]);
      },
      get canViewCompliancyDocuments() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_COMPLIANCY_DOCUMENTS,
        ]);
      },
      get canRemoveCompliancyDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_COMPLIANCY_DOCUMENTS,
        ]);
      },
      get canRemoveDriverDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_DRIVER_DOCUMENTS,
        ]);
      },
      get canViewDriverDocuments() {
        return getIsUserPermittedForRoute([permissions.VIEW_DRIVER_DOCUMENTS]);
      },
      get canAddDriverDocuments() {
        return getIsUserPermittedForRoute([permissions.ADD_DRIVER_DOCUMENTS]);
      },
      get canViewDriverDrivesVehicleAssociations() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_DRIVER_DRIVES_VEHICLE_ASSOCIATIONS,
        ]);
      },
      get canEditVehicleInspections() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_VEHICLE_INSPECTIONS,
        ]);
      },
      get canAddVehicleInspections() {
        return getIsUserPermittedForRoute([
          permissions.ADD_VEHICLE_INSPECTIONS,
        ]);
      },
      get canRemoveVehicleInspections() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_VEHICLE_INSPECTIONS,
        ]);
      },
      get canAddVehicleInspectionDocuments() {
        return getIsUserPermittedForRoute([
          permissions.ADD_VEHICLE_INSPECTION_DOCUMENTS,
        ]);
      },
      get canViewVehicleInspectionDocuments() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_VEHICLE_INSPECTION_DOCUMENTS,
        ]);
      },
      get canRemoveVehicleInspectionDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_VEHICLE_INSPECTION_DOCUMENTS,
        ]);
      },
      get canRemoveVehicleRegistrationDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_VEHICLE_REGISTRATION_DOCUMENTS,
        ]);
      },
      get canAddVehicleRegistrationDocuments() {
        return getIsUserPermittedForRoute([
          permissions.ADD_VEHICLE_REGISTRATION_DOCUMENTS,
        ]);
      },
      get canViewVehicleRegistrationDocuments() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_VEHICLE_REGISTRATION_DOCUMENTS,
        ]);
      },
      get canViewVehicleMaintenances() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_VEHICLE_MAINTENANCES,
        ]);
      },
      get canEditVehicleMaintenances() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_VEHICLE_MAINTENANCES,
        ]);
      },
      get canAddVehicleMaintenances() {
        return getIsUserPermittedForRoute([
          permissions.ADD_VEHICLE_MAINTENANCES,
        ]);
      },
      get canRemoveVehicleMaintenances() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_VEHICLE_MAINTENANCES,
        ]);
      },
      get canRemoveVehicleMaintenanceDocuments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_VEHICLE_MAINTENANCE_DOCUMENTS,
        ]);
      },
      get canAddVehicleMaintenanceDocuments() {
        return getIsUserPermittedForRoute([
          permissions.ADD_VEHICLE_MAINTENANCE_DOCUMENTS,
        ]);
      },
      get canViewVehicleMaintenanceDocuments() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_VEHICLE_MAINTENANCE_DOCUMENTS,
        ]);
      },
      get canViewTrailerTruckAssociations() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_TRUCK_TRAILER_ASSOCIATIONS,
        ]);
      },
      get canAddTrailerTruckAssociations() {
        return getIsUserPermittedForRoute([
          permissions.ADD_TRUCK_TRAILER_ASSOCIATIONS,
        ]);
      },
      get canRemoveTrailerTruckAssociations() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_TRUCK_TRAILER_ASSOCIATIONS,
        ]);
      },
      get canRemoveShipmentBillingCharges() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_SHIPMENT_BILLING_CHARGES,
        ]);
      },
      get canAddShipmentBillingCharges() {
        return getIsUserPermittedForRoute([
          permissions.ADD_SHIPMENT_BILLING_CHARGES,
        ]);
      },
      get canEditShipmentBillingCharges() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_SHIPMENT_BILLING_CHARGES,
        ]);
      },
      get canViewShipmentBillingCharges() {
        return getIsUserPermittedForRoute([
          permissions.VIEW_SHIPMENT_BILLING_CHARGES,
        ]);
      },
      get canAddBrokers() {
        return getIsUserPermittedForRoute([permissions.ADD_BROKERS]);
      },
      get canViewBrokers() {
        return getIsUserPermittedForRoute([permissions.VIEW_BROKERS]);
      },
      get canViewShipmentActions() {
        return getIsUserPermittedForRoute([permissions.VIEW_SHIPMENT_ACTIONS]);
      },
      get canAddShipmentActions() {
        return getIsUserPermittedForRoute([permissions.ADD_SHIPMENT_ACTIONS]);
      },
      get canEditShipmentActions() {
        return getIsUserPermittedForRoute([permissions.EDIT_SHIPMENT_ACTIONS]);
      },
      get canRemoveShipmentActions() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_SHIPMENT_ACTIONS,
        ]);
      },
      get canAddClients() {
        return getIsUserPermittedForRoute([permissions.ADD_CLIENTS]);
      },
      get canViewClients() {
        return getIsUserPermittedForRoute([permissions.VIEW_CLIENTS]);
      },
      get canEditClients() {
        return getIsUserPermittedForRoute([permissions.EDIT_CLIENTS]);
      },
      get canRemoveClients() {
        return getIsUserPermittedForRoute([permissions.REMOVE_CLIENTS]);
      },
      get canEditLocations() {
        return getIsUserPermittedForRoute([permissions.EDIT_LOCATIONS]);
      },
      get canViewLocations() {
        return getIsUserPermittedForRoute([permissions.VIEW_LOCATIONS]);
      },
      get canRemoveLocations() {
        return getIsUserPermittedForRoute([permissions.REMOVE_LOCATIONS]);
      },
      get canAddLocations() {
        return getIsUserPermittedForRoute([permissions.ADD_LOCATIONS]);
      },
      get canAddProvinces() {
        return getIsUserPermittedForRoute([permissions.ADD_PROVINCES]);
      },
      get canViewPermissions() {
        return getIsUserPermittedForRoute([permissions.VIEW_PERMISSIONS]);
      },
      get canEditRoles() {
        return getIsUserPermittedForRoute([permissions.EDIT_ROLES]);
      },
      get canAddRoles() {
        return getIsUserPermittedForRoute([permissions.ADD_ROLES]);
      },
      get canAddPermissionsToRoles() {
        return getIsUserPermittedForRoute([
          permissions.ADD_PERMISSIONS_TO_ROLES,
        ]);
      },
      get canViewRoles() {
        return getIsUserPermittedForRoute([permissions.VIEW_ROLES]);
      },
      get canAddTelemetrics() {
        return getIsUserPermittedForRoute([permissions.ADD_TELEMETRICS]);
      },
      get canViewVehicleLocation() {
        return getIsUserPermittedForRoute([permissions.VIEW_VEHICLE_LOCATION]);
      },
      get canEditOrganization() {
        return getIsUserPermittedForRoute([permissions.EDIT_ORGANIZATION]);
      },
      get canViewOrganization() {
        return getIsUserPermittedForRoute([permissions.VIEW_ORGANIZATION]);
      },
      get isSuperAdmin() {
        return getIsUserPermittedForRoute([
          permissions.SUPER_ADMIN_PERMISSIONS,
        ]);
      },
      get canViewOrganizations() {
        return getIsUserPermittedForRoute([permissions.VIEW_ORGANIZATIONS]);
      },
      get canAddOrganizations() {
        return getIsUserPermittedForRoute([[permissions.ADD_ORGANIZATIONS]]);
      },
      get canEditOrganizations() {
        return getIsUserPermittedForRoute([permissions.EDIT_ORGANIZATIONS]);
      },
      get canRemovePhotosFromOrganization() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_PHOTOS_FROM_ORGANIZATION,
        ]);
      },
      get canAddPhotosFromOrganization() {
        return getIsUserPermittedForRoute([
          permissions.ADD_PHOTOS_TO_ORGANIZATION,
        ]);
      },
      get canAddDefaultOrganizationLocation() {
        return getIsUserPermittedForRoute([
          permissions.ADD_DEFAULT_ORGANIZATION_LOCATION,
        ]);
      },
      get canEditTruckTrailerAssociations() {
        return getIsUserPermittedForRoute([
          permissions.EDIT_TRUCK_TRAILER_ASSOCIATIONS,
        ]);
      },
      get canEditBillingCharges() {
        return getIsUserPermittedForRoute([permissions.EDIT_BILLING_CHARGES]);
      },
      get canRemoveBillingCharges() {
        return getIsUserPermittedForRoute([permissions.REMOVE_BILLING_CHARGES]);
      },
      get canViewBillingCharges() {
        return getIsUserPermittedForRoute([permissions.VIEW_BILLING_CHARGES]);
      },
      get canAddBillingCharges() {
        return getIsUserPermittedForRoute([permissions.ADD_BILLING_CHARGES]);
      },
      get canViewPlannedShipments() {
        return getIsUserPermittedForRoute([permissions.VIEW_PLANNED_SHIPMENTS]);
      },
      get canAddPlannedShipments() {
        return getIsUserPermittedForRoute([permissions.ADD_PLANNED_SHIPMENTS]);
      },
      get canEditPlannedShipments() {
        return getIsUserPermittedForRoute([permissions.EDIT_PLANNED_SHIPMENTS]);
      },
      get canRemovePlannedShipments() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_PLANNED_SHIPMENTS,
        ]);
      },
      get canRemoveShipmentDocumentTypes() {
        return getIsUserPermittedForRoute([
          permissions.REMOVE_SHIPMENT_DOCUMENT_TYPES,
        ]);
      },
    };
  })
  .actions((self) => {
    return {
      setPermissions(permissions) {
        self.permissions = permissions;
      },
      async getPermissions() {
        setFetchingInProgress(true);
        try {
          const permissions = await getCurrentUserPermissions();
          this.setPermissions(permissions.data);
        } catch (error) {
          const errorText = getErrorText(error);
          notification.error({
            message: FETCH_PERMISSIONS_ERROR,
            description: errorText,
          });
        } finally {
          setFetchingInProgress(false);
        }
      },
    };
  });
