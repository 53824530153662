import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { notification } from 'antd';
import editTruckIcon from '../../../assets/images/edit-truck.svg';
import FormPageTitle from '../../FormPageTitle';
import GeneralInformation from './GeneralInformation';
import TruckInformation from './TruckInformation';
import RegistrationInformation from '../RegistrationInformation';
import EditInspectionsSection from '../SharedComponents/EditComponents/EditInspectionsSection';
import EditMaintenanceSection from '../SharedComponents/EditComponents/EditMaintenanceSection';
import DocumentationSection from './DocumentationSection';
import ErrorBox from '../../ErrorBox';
import FormButtonsSection from '../../FormButtonsSection';
import SaveFirstModal from '../../SaveFirstModal';
import {
  EDIT_TRUCK,
  SAVE_CHANGES,
  DISCARD_CHANGES,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
} from '../../../constants';
import { getRandomInt } from '../../../utils/methods';
import {
  DOCUMENT_TYPE_ADDITIONAL,
  DOCUMENT_TYPE_VEHICLE_IMAGE,
  DOCUMENT_TYPE_REGISTRATION,
} from '../../../constants/documents';

const EditTruckComponent = () => {
  const {
    fleetPageState: { editedTruck },
  } = useMst();

  // added to reset state on discard changes
  const [key, setKey] = useState(getRandomInt());

  const [newRegistrationAttachment, setNewRegistrationAttachment] = useState(
    []
  );
  const handleNewRegistrationUpload = ({ file, fileList }) => {
    if (fileList) {
      const listToUpload = fileList.length && [fileList[fileList.length - 1]];
      if (listToUpload && listToUpload.length) {
        listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_REGISTRATION;
        if (file.status !== 'uploading') {
          setNewRegistrationAttachment(listToUpload);
        }
      } else {
        setNewRegistrationAttachment(fileList);
      }
    } else {
      setNewRegistrationAttachment([]);
    }
  };

  const [registrationAttachment, setRegistrationAttachment] = useState([]);
  useEffect(() => {
    setRegistrationAttachment(
      editedTruck?.licence?.document ? [editedTruck.licence.document] : []
    );
  }, [editedTruck?.licence?.document, key]);
  const handleRegistrationUpload = ({ file, fileList }) => {
    editedTruck?.licence?.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_REGISTRATION;
      if (file.status !== 'uploading') {
        setRegistrationAttachment(listToUpload);
      }
    } else {
      setRegistrationAttachment(fileList);
    }
  };

  const [additionalFiles, setAdditionalFiles] = useState([]);
  useEffect(() => {
    setAdditionalFiles(
      editedTruck?.additionalDocuments
        ? [...editedTruck.additionalDocuments]
        : []
    );
  }, [editedTruck?.additionalDocuments, key]);
  const handleAdditionalFilesUpload = ({ file, fileList }) => {
    const isUploadAllowed = () => {
      return fileList.length < 6 || fileList.length < additionalFiles.length;
    };
    if (file.status !== 'uploading') {
      if (isUploadAllowed()) {
        fileList.forEach((file) => {
          !('originFileObj' in file) && (file.originFileObj = {});
          file.originFileObj.documentType = DOCUMENT_TYPE_ADDITIONAL;
        });
        setAdditionalFiles(fileList);
      } else {
        notification.error({
          message: UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
          description: UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
        });
      }
    }
  };

  const [truckPictures, setTruckPictures] = useState([]);
  useEffect(() => {
    setTruckPictures(
      editedTruck?.vehicleImages ? [...editedTruck.vehicleImages] : []
    );
  }, [editedTruck?.vehicleImages, key]);
  const handleTruckPicturesUpload = ({ file, fileList }) => {
    const isUploadAllowed = () => {
      return fileList.length < 6 || fileList.length < truckPictures.length;
    };
    if (file.status !== 'uploading') {
      if (isUploadAllowed()) {
        fileList.forEach((file) => {
          !('originFileObj' in file) && (file.originFileObj = {});
          file.originFileObj.documentType = DOCUMENT_TYPE_VEHICLE_IMAGE;
        });
        setTruckPictures(fileList);
      } else {
        notification.error({
          message: UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
          description: UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
        });
      }
    }
  };

  const getDocumentsForUpload = () => {
    const currentDocuments = [
      ...registrationAttachment,
      ...additionalFiles,
      ...truckPictures,
      ...newRegistrationAttachment,
    ];

    const uploadedDocuments = [...editedTruck.documentsArray];
    editedTruck.licence?.document &&
      uploadedDocuments.push(editedTruck.licence.document);

    const documentsToUpload = currentDocuments.filter((document) => {
      return !uploadedDocuments.includes(document);
    });

    return documentsToUpload;
  };

  const getDocumentsForDeletion = () => {
    const currentDocuments = [
      ...registrationAttachment,
      ...additionalFiles,
      ...truckPictures,
    ];
    const uploadedDocuments = [...editedTruck.documentsArray];
    editedTruck.licence?.document &&
      uploadedDocuments.push(editedTruck.licence.document);

    const documentsToDelete = uploadedDocuments.filter((document) => {
      return !currentDocuments.includes(document);
    });

    return documentsToDelete;
  };

  const discardChanges = () => {
    setKey(getRandomInt());
    editedTruck.cancelChanges();
  };

  const saveChanges = () => {
    const documentsToUpload = getDocumentsForUpload();
    const documentsToDelete = getDocumentsForDeletion();
    editedTruck.updateTruck(documentsToUpload, documentsToDelete);
  };

  return (
    <div className="add-edit-form-component">
      {editedTruck?.vehicleFullyFetched && (
        <div>
          <SaveFirstModal
            onCancel={discardChanges}
            onConfirm={saveChanges}
            open={editedTruck?.isSaveFirstModalOpen}
          />
          <div className="align-center">
            <FormPageTitle image={editTruckIcon} title={EDIT_TRUCK} />
            <span>
              <h2 className="text-medium color-primary">
                {editedTruck.vehicle_make} {editedTruck.vehicle_model_name}{' '}
                {editedTruck.vehicle_production_year}
              </h2>
            </span>
          </div>

          <GeneralInformation />

          <TruckInformation />

          <RegistrationInformation
            uploadNewRegistration={handleNewRegistrationUpload}
            newRegistration={newRegistrationAttachment}
            uploadRegistration={handleRegistrationUpload}
            registration={registrationAttachment}
          />

          {/* <TrackingInformation /> */}

          {/* <FinancingInformation /> */}

          {/* <InsuranceInformation /> */}

          {/* <Associations /> */}

          <EditInspectionsSection model={editedTruck} />

          <EditMaintenanceSection model={editedTruck} />

          <DocumentationSection
            uploadRegistration={handleRegistrationUpload}
            uploadTruckPictures={handleTruckPicturesUpload}
            uploadAdditionalFiles={handleAdditionalFilesUpload}
            // registration={registration}
            truckPictures={truckPictures}
            additionalFiles={additionalFiles}
          />

          <ErrorBox obj={editedTruck} />

          <FormButtonsSection
            onConfirm={saveChanges}
            onDiscard={discardChanges}
            confirmTitle={SAVE_CHANGES}
            discardTitle={DISCARD_CHANGES}
          />
        </div>
      )}
    </div>
  );
};

export default observer(EditTruckComponent);
