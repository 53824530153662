import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal } from 'antd';
import { CANCEL } from '../../../constants';
import addOrganization from '../../../assets/images/add-organization.svg';
import {
  COMPENSATION_DELETE,
  CONFIRM_DELETING_COMPENSATION,
} from '../../../constants/payroll';

const DeleteCompensationModal = () => {
  const {
    payrollPageState: {
      isDeleteCompensationModalOpened,
      setIsDeleteCompensationModalOpened,
      deletingCompensation,
    },
  } = useMst();

  const onCancel = () => {
    setIsDeleteCompensationModalOpened(false);
  };

  const onCompensationDelete = () => {
    deletingCompensation.deleteCompensation(
      deletingCompensation.compensation_id
    );
  };

  return (
    <div>
      <Modal
        visible={!!isDeleteCompensationModalOpened}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="p20">
          <div className="align-center mb20">
            <img src={addOrganization} alt="save" className="mr10" />
            <h3>{COMPENSATION_DELETE}</h3>
          </div>
          <p>{CONFIRM_DELETING_COMPENSATION}</p>
          <h3>{deletingCompensation?.compensation_name}</h3>

          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onCompensationDelete}>
              {COMPENSATION_DELETE}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(DeleteCompensationModal);
