import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import addTruckIcon from '../../../assets/images/add-truck.svg';
import ErrorBox from '../../ErrorBox';
import FormPageTitle from '../../FormPageTitle';
import GeneralInformation from './GeneralInformation';
import TruckInformation from './TruckInformation';
import FormButtonsSection from '../../FormButtonsSection';
import { ADD_TRUCK, DISCARD_CHANGES } from '../../../constants';

const AddTruckComponent = () => {
  const {
    fleetPageState: { newTruck, addTruck },
  } = useMst();

  const addNewTruck = () => {
    addTruck();
  };

  const onDiscardChanges = () => {
    newTruck.clearForm();
  };

  return (
    <div className="add-edit-form-component">
      {/* TITLE */}

      <FormPageTitle image={addTruckIcon} title={ADD_TRUCK} />

      {/* GENERAL INFORMATION */}

      <GeneralInformation />

      {/* TRUCK INFORMATION */}

      <TruckInformation />

      {/* ERROR BOX */}

      <ErrorBox obj={newTruck} />

      <FormButtonsSection
        onConfirm={addNewTruck}
        onDiscard={onDiscardChanges}
        confirmTitle={ADD_TRUCK}
        discardTitle={DISCARD_CHANGES}
      />
    </div>
  );
};

export default observer(AddTruckComponent);
