import React from 'react';
import {
  SHIPMENT_NO,
  PO_NUMBER,
  SHIPMENT_STATUS,
  ACTION_TYPE,
  CUSTOMER_NAME,
  NOT_AVAILABLE,
  PICKUP_LOCATION,
  DELIVERY_LOCATION,
  SHIPMENT_SIZE,
  DRIVER,
} from '../../../constants';
import moment from 'moment';
import {
  D_S,
  DELIVERY_DATE_TIME,
  DISPATCHER,
  P_S,
  PICKUP_DATE_TIME,
} from '../../../constants/dispatch';
import { rootInstance } from '../../RootModel';
import PageRoutes from '../../../utils/PageRoutes';

const onViewDriverDetails = (e, record) => {
  e.stopPropagation();
  const params = { driverId: record.driver.driver_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.ViewDriver.name),
    params
  );
};

const onShipmentView = (e, record) => {
  rootInstance.dispatchPageState.setSelectedDriverForMap([]);
  const params = { shipmentId: record.shipment_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.ViewShipment.name),
    params
  );
};

export const PlannerTableColumns = (rootInstance) => ({
  '1': {
    title: SHIPMENT_NO,
    dataIndex: 'shipment_number',
    key: 'shipment_number',
    filters: rootInstance?.dispatchPageState?.plannedShipments.map(
      (item, value) => {
        return {
          text: item.shipment_number,
          value: item.shipment_number,
        };
      }
    ),
    onFilter: (value: string, record) => {
      return record.shipment_number.indexOf(value) === 0;
    },
    sorter: (a, b) => a.shipment_number > b.shipment_number,
    sortDirections: ['descend', 'ascend'],
    defaultSortOrder: 'ascend',
    render: (text, record) => {
      return (
        <span
          className="color-title text-medium go-to"
          onClick={(e) => onShipmentView(e, record)}
        >
          {text}
        </span>
      );
    },
  },
  '2': {
    title: PO_NUMBER,
    dataIndex: 'pio_number',
    key: 'pio_number',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (!unique.some((obj) => obj.pio_number === o.pio_number)) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item.pio_number,
          value: item.pio_number,
        };
      }),
    onFilter: (value: string, record) => {
      return record.pio_number.indexOf(value) === 0;
    },
    sorter: (a, b) => a.pio_number.localeCompare(b.pio_number),
    sortDirections: ['ascend', 'descend'],
    render: (text) => {
      return <span className="color-title text-medium">{text}</span>;
    },
  },
  '3': {
    title: DISPATCHER,
    key: 'dispatcher',
    dataIndex: 'dispatcher',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (!unique.some((obj) => obj.dispatcher === o.dispatcher)) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.dispatcher,
          value: item?.dispatcher,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.dispatcher.indexOf(value) === 0;
    },

    sorter: (a, b) => a.dispatcher.localeCompare(b.dispatcher),
    sortDirections: ['ascend', 'descend'],
    render: (text, record) => {
      return (
        <span className="color-title text-medium">
          {record?.dispatcher ? record?.dispatcher : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '4': {
    title: SHIPMENT_STATUS,
    dataIndex: 'shipment_status',
    key: 'shipment_status',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (!unique.some((obj) => obj.shipment_status === o.shipment_status)) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.shipment_status,
          value: item?.shipment_status,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.shipment_status.indexOf(value) === 0;
    },
    sorter: (a, b) => a.shipment_status.localeCompare(b.shipment_status),
    sortDirections: ['ascend', 'descend'],
    render: (text) => {
      return (
        <span className="color-title text-medium">{text ?? NOT_AVAILABLE}</span>
      );
    },
  },
  '5': {
    title: SHIPMENT_SIZE,
    dataIndex: 'shipment_size',
    key: 'shipment_size',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (!unique.some((obj) => obj.shipment_size === o.shipment_size)) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.shipment_size,
          value: item?.shipment_size,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.shipment_size.indexOf(value) === 0;
    },
    sorter: (a, b) => a.shipment_size.localeCompare(b.shipment_size),
    sortDirections: ['ascend', 'descend'],
    render: (text) => {
      return (
        <span className="color-title text-medium">{text ?? NOT_AVAILABLE}</span>
      );
    },
  },
  '6': {
    title: ACTION_TYPE,
    dataIndex: 'action_type',
    key: 'action_type',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (!unique.some((obj) => obj.action_type[0] === o.action_type[0])) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.action_type.length ? item?.action_type[0] : NOT_AVAILABLE,
          value: item?.action_type.length
            ? item?.action_type[0]
            : NOT_AVAILABLE,
        };
      }),
    onFilter: (value: string, record) => {
      if (value === NOT_AVAILABLE) {
        return record?.action_type.length === 0;
      } else {
        if (record?.action_type.length === 0) return false;
        else return record?.action_type[0].indexOf(value) === 0;
      }
    },
    sorter: (a, b) => {
      if (a.action_type.length === 0) a.action_type[0] = NOT_AVAILABLE;
      else if (b.action_type.length === 0) b.action_type[0] = NOT_AVAILABLE;
      return a.action_type[0].localeCompare(b.action_type[0]);
    },
    sortDirections: ['ascend', 'descend'],
    render: (text) => {
      if (text?.length > 0) {
        if (text?.length > 1) {
          return (
            <span className="color-title text-medium">{text.join(', ')}</span>
          );
        } else {
          return <span className="color-title text-medium">{text}</span>;
        }
      } else {
        return <span className="color-title text-medium">{NOT_AVAILABLE}</span>;
      }
    },
  },
  '7': {
    title: CUSTOMER_NAME,
    dataIndex: 'customer_name',
    key: 'customer_name',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (!unique.some((obj) => obj.customer_name === o.customer_name)) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.customer_name,
          value: item?.customer_name,
        };
      }),
    onFilter: (value: string, record) => {
      return record.customer_name.indexOf(value) === 0;
    },
    sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
    sortDirections: ['ascend', 'descend'],
    render: (text) => {
      return (
        <span className="color-title text-medium">{text ?? NOT_AVAILABLE}</span>
      );
    },
  },
  '8': {
    title: PICKUP_DATE_TIME,
    dataIndex: 'pickup_date',
    key: 'pickup_date',
    render: (text, record) => {
      return record?.pickup_date?.length > 0 ? (
        record?.pickup_date?.map(
          (item, index) =>
            item && (
              <span className="color-title text-medium" key={index}>
                {moment(item).format('MMM D, hh:mm A')}
              </span>
            )
        )
      ) : (
        <span className="color-title text-medium">{NOT_AVAILABLE}</span>
      );
    },
  },
  '9': {
    title: PICKUP_LOCATION,
    dataIndex: 'pickup_location_city',
    key: 'pickup_location_city',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (
          !unique.some(
            (obj) => obj.pickup_location?.city === o.pickup_location?.city
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.pickup_location?.city,
          value: item?.pickup_location?.city,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.pickup_location?.city.indexOf(value) === 0;
    },
    sorter: (a, b) =>
      a?.pickup_location?.city.localeCompare(b?.pickup_location?.city),
    sortDirections: ['ascend', 'descend'],
    render: (text, record) => {
      return record?.pickup_location ? (
        <span className="color-title text-medium">
          {record?.pickup_location?.city
            ? record?.pickup_location?.city
            : NOT_AVAILABLE}
        </span>
      ) : (
        <span className="color-title text-medium">{NOT_AVAILABLE}</span>
      );
    },
  },
  '10': {
    title: P_S,
    dataIndex: 'pickup_location_state',
    key: 'pickup_location_state',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (
          !unique.some(
            (obj) =>
              obj.pickup_location?.state_code === o.pickup_location?.state_code
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.pickup_location?.state_code,
          value: item?.pickup_location?.state_code,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.pickup_location?.state_code.indexOf(value) === 0;
    },
    sorter: (a, b) =>
      a?.pickup_location?.state_code.localeCompare(
        b?.pickup_location?.state_code
      ),
    sortDirections: ['ascend', 'descend'],
    render: (text, record) => {
      return record?.pickup_location ? (
        <span className="color-title text-medium">
          {record?.pickup_location?.state_code
            ? record?.pickup_location?.state_code
            : NOT_AVAILABLE}
        </span>
      ) : (
        <span className="color-title text-medium">{NOT_AVAILABLE}</span>
      );
    },
  },
  '11': {
    title: DELIVERY_DATE_TIME,
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    render: (text, record) => {
      return record?.delivery_date?.length > 0 ? (
        record?.delivery_date?.map((item, index) => {
          return (
            item &&
            index === 0 && (
              <span className="color-title text-medium" key={index}>
                {moment(item).format('MMM D, HH:mm')}
              </span>
            )
          );
        })
      ) : (
        <span className="color-title text-medium">{NOT_AVAILABLE}</span>
      );
    },
  },
  '12': {
    title: DELIVERY_LOCATION,
    dataIndex: 'delivery_location_city',
    key: 'delivery_location_city',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (
          !unique.some(
            (obj) => obj.delivery_location?.city === o.delivery_location?.city
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.delivery_location?.city,
          value: item?.delivery_location?.city,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.delivery_location?.city.indexOf(value) === 0;
    },
    sorter: (a, b) =>
      a?.delivery_location?.city.localeCompare(b?.delivery_location?.city),
    sortDirections: ['ascend', 'descend'],
    render: (text, record) => {
      return record?.delivery_location ? (
        <span className="color-title text-medium">
          {record?.delivery_location?.city
            ? record?.delivery_location?.city
            : NOT_AVAILABLE}
        </span>
      ) : (
        <span className="color-title text-medium">{NOT_AVAILABLE}</span>
      );
    },
  },
  '13': {
    title: D_S,
    dataIndex: 'delivery_location_state',
    key: 'delivery_location_state',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (
          !unique.some(
            (obj) =>
              obj.delivery_location?.state_code ===
              o.delivery_location?.state_code
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.delivery_location?.state_code,
          value: item?.delivery_location?.state_code,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.delivery_location?.state_code.indexOf(value) === 0;
    },
    sorter: (a, b) =>
      a?.delivery_location?.state_code.localeCompare(
        b?.delivery_location?.state_code
      ),
    sortDirections: ['ascend', 'descend'],
    render: (text, record) => {
      return record?.delivery_location ? (
        <span className="color-title text-medium">
          {record?.delivery_location?.state_code
            ? record?.delivery_location?.state_code
            : NOT_AVAILABLE}
        </span>
      ) : (
        <span className="color-title text-medium">{NOT_AVAILABLE}</span>
      );
    },
  },
  '14': {
    title: DRIVER,
    dataIndex: 'driver_code_name',
    key: 'driver_code_name',
    filters: rootInstance?.dispatchPageState?.plannedShipments
      .reduce((unique, o) => {
        if (
          !unique.some(
            (obj) => obj.driver?.driver_code_name === o.driver?.driver_code_name
          )
        ) {
          unique.push(o);
        }
        return unique;
      }, [])
      .map((item) => {
        return {
          text: item?.driver?.driver_code_name,
          value: item?.driver?.driver_code_name,
        };
      }),
    onFilter: (value: string, record) => {
      return record?.driver?.driver_code_name.indexOf(value) === 0;
    },
    sorter: (a, b) =>
      a?.driver?.driver_code_name.localeCompare(b?.driver?.driver_code_name),
    sortDirections: ['ascend', 'descend'],
    render: (text, record) => {
      return record?.driver?.driver_code_name !== 'N/A' ? (
        <span
          className={`color-title text-medium ${
            record?.driver?.driver_code_name !== 'N/A' ? 'go-to' : ''
          }`}
          onClick={(e) => onViewDriverDetails(e, record)}
        >
          {record?.driver?.driver_code_name}
        </span>
      ) : (
        <span className="color-title text-medium">{NOT_AVAILABLE}</span>
      );
    },
  },
});
