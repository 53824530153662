import { types } from 'mobx-state-tree';

export const LicenceLightModel = types
  .model('LicenceLightModel', {
    licence_number: types.maybe(types.maybeNull(types.string)),
    issued_in: types.maybe(types.maybeNull(types.string)),
    expiry_date: types.maybe(types.maybeNull(types.string)),
    issued_on: types.maybe(types.maybeNull(types.string)),
    is_active: types.maybe(types.maybeNull(types.boolean)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setLicenceNumber(licence_number) {
        self.licence_number = licence_number;
      },
      setLicenceLocation(issued_in) {
        self.issued_in = issued_in;
      },
      setLicenceExpiryDate(expiry_date) {
        self.expiry_date = expiry_date;
      },
      setLicenceIssuedOn(issued_on) {
        self.issued_on = issued_on;
      },
    };
  });
