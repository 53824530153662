import { types } from 'mobx-state-tree';
import { DocumentModel } from '../DocumentModel';
import { rootInstance } from '../RootModel';

export const RegistrationModel = types
  .model('RegistrationModel', {
    vehicle_registration_id: types.identifierNumber,
    vehicle_id: types.maybe(types.maybeNull(types.number)),
    issued_in: types.maybe(types.maybeNull(types.string)),
    issued_on: types.maybe(types.maybeNull(types.string)),
    expiry_date: types.maybe(types.maybeNull(types.string)),
    is_active: types.maybe(types.maybeNull(types.boolean)),
    licence_plate: types.maybe(types.maybeNull(types.string)),
    document: types.maybe(types.maybeNull(DocumentModel)),
    country: types.maybe(types.maybeNull(types.string)),
    isChangedDuringEdit: false,
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get filteredStates() {
        const selectedCountry = self.country;
        return rootInstance.fleetPageState.editedVehicle.countries.find(
          (country) => {
            return country.name === selectedCountry;
          }
        );
      },
    };
  })
  .actions((self) => {
    return {
      setIsChangedDuringEdit(status) {
        self.isChangedDuringEdit = status;
      },
      setRegistrationCountry(country) {
        this.setIsChangedDuringEdit(true);
        self.country = country;
      },
      setRegistrationLocation(location) {
        this.setIsChangedDuringEdit(true);
        self.issued_in = location;
      },
      setRegistrationIssuedOn(issued_on) {
        this.setIsChangedDuringEdit(true);
        self.issued_on = issued_on;
      },
      setRegistrationExpirationDate(date) {
        this.setIsChangedDuringEdit(true);
        self.expiry_date = date;
      },
      setLicencePlate(licence_plate) {
        this.setIsChangedDuringEdit(true);
        self.licence_plate = licence_plate;
      },
      setRegistrationDocument(item) {
        item.uid = item.document_id;
        item.name = item.document_name;
        item.url = item.document_url;
        self.document = item;
      },
    };
  });
