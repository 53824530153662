import { getRoot, types } from 'mobx-state-tree';
import PageRoutes from '../../utils/PageRoutes';
import { validateEmail, getErrorText } from '../../utils/methods';
import { DecodedToken, RootType } from '../ModelTypes';
import { rootInstance } from '../RootModel';
import {
  EMAIL_ERROR_TEXT,
  PASSWORD_ERROR_TEXT,
  PASSWORDS_NOT_MATCHING_ERROR_TEXT,
  SUCCESS,
  PASSWORD_SUCCESSFULLY_CHANGED_TEXT,
  EMAIL_REQUIRED,
  FETCH_ACCOUNT_SETTINGS_ERROR,
} from '../../constants';
import { notification } from 'antd';
import {
  login,
  forgotPassword,
  resetPassword,
  createPassword,
} from '../../actions/auth';
import {
  getAccountAvailableRoles,
  getAccountActiveRole,
  getAllAccountDocumentation,
} from '../../actions/account';
import jwt_decode from 'jwt-decode';
import { EmployeeModel } from '../employees/EmployeeModel';
import {
  setFetchingInProgress,
  setCurrentUserLocalStoragePhoto,
} from '../../utils/methods';

// STEPS:
// 1 - Login form / Email & Password Input
// 2 - Forgot password screen

export const LoginModel = types
  .model('LoginForm', {
    password: '',
    username: '',
    code: '',
    newPassword: '',
    confirmedPassword: '',
    passwordVisible: false,
    newPasswordVisible: false,
    confirmedPasswordVisible: false,
    loading: false,
    errorText: types.maybeNull(types.string),
    infoText: types.maybeNull(types.string),
    step: 1,
    resetPasswordToken: types.maybe(types.maybeNull(types.string)),
    resetPasswordTokenDecoded: types.maybe(types.maybeNull(types.frozen())),
    createPasswordToken: types.maybe(types.maybeNull(types.string)),
    createPasswordTokenDecoded: types.maybe(types.maybeNull(types.frozen())),
    isAuth: false,
    currentUser: types.maybe(types.maybeNull(EmployeeModel)),
    permissions: types.frozen([]),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get firstTimeInApp() {
        return JSON.parse(localStorage.getItem('first_time_in_app'));
      },
    };
  })
  .actions((self) => {
    return {
      setPermissions(permissions) {
        self.permissions = permissions;
      },
      setAuth(status) {
        self.isAuth = status;
      },
      resetAllInputs() {
        this.setUsername('');
        this.setPassword('');
        this.setNewPassword('');
        this.setConfirmedPassword('');
        this.hideAllPasswords();
        this.clearAllErrors();
      },
      setUsername(username) {
        self.username = username;
      },
      setPassword(password) {
        self.password = password;
      },
      setNewPassword(password) {
        self.newPassword = password;
      },
      setConfirmedPassword(password) {
        self.confirmedPassword = password;
      },
      setNewPasswordVisible(status) {
        self.newPasswordVisible = status;
      },
      setConfirmedPasswordVisible(status) {
        self.confirmedPasswordVisible = status;
      },
      setPasswordVisible(status) {
        self.passwordVisible = status;
      },
      hideAllPasswords() {
        this.setPasswordVisible(false);
        this.setNewPasswordVisible(false);
        this.setConfirmedPasswordVisible(false);
      },
      setErrorText(str) {
        self.errorText = str;
      },
      setInfoText(str) {
        self.infoText = str;
      },
      clearAllErrors() {
        this.setErrorText(null);
        this.setInfoText(null);
      },
      setResetPasswordToken(token) {
        self.resetPasswordToken = token;
        self.resetPasswordTokenDecoded = token ? jwt_decode(token) : token;
      },
      validateResetPasswordToken() {
        const token = rootInstance.router.queryParams.token;
        try {
          const tokenDecoded: DecodedToken = jwt_decode(token);
          if (Date.now() >= tokenDecoded.exp * 1000) {
            console.log('token expired');
            return false;
          }
          this.setResetPasswordToken(token);
        } catch (error) {
          console.log('token invalid');
          return false;
        }
      },
      setCreatePasswordToken(token) {
        self.createPasswordToken = token;
        self.createPasswordTokenDecoded = token ? jwt_decode(token) : token;
      },
      validateCreatePasswordToken() {
        const token = rootInstance.router.queryParams.token;
        try {
          const tokenDecoded: DecodedToken = jwt_decode(token);
          if (Date.now() >= tokenDecoded.exp * 1000) {
            console.log('token expired');
            return false;
          }
          this.setCreatePasswordToken(token);
        } catch (error) {
          console.log('token invalid');
          return false;
        }
      },
      logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_photo');
        localStorage.removeItem('active_role');
        const { router } = getRoot(self);
        router.setView(router.views.get(PageRoutes.Login.name));
      },
      setStep(step) {
        this.clearAllErrors();
        this.hideAllPasswords();
        self.step = step;
      },
      setCurrentUserLocalStorageData(login_data) {
        const access_token = login_data.data.access_token;
        const token: DecodedToken = jwt_decode(access_token);
        const user = token.data;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('user', JSON.stringify(user));
      },
      validateLoginForm(username, password) {
        this.clearAllErrors();
        const emailValid = validateEmail(username);
        if (!username) {
          this.setErrorText(EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else if (!password.length) {
          this.setErrorText(PASSWORD_ERROR_TEXT);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      throwSubmitError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        this.toggleLoading(false);
      },
      redirectUserDependingOnFirstLogin() {
        if (self.firstTimeInApp) {
          rootInstance.router.setView(
            rootInstance.router.views.get(PageRoutes.Account.name)
          );
        } else {
          rootInstance.router.setView(
            rootInstance.router.views.get(PageRoutes.Dashboard.name)
          );
        }
      },
      submit(username: string, password: string) {
        if (this.validateLoginForm(username, password)) {
          this.toggleLoading(true);
          login(username, password)
            .then((login_data) => {
              this.setCurrentUserLocalStorageData(login_data);
              getAllAccountDocumentation()
                .then((documentation) => {
                  getAccountActiveRole()
                    .then((role) => {
                      setCurrentUserLocalStoragePhoto(documentation);
                      localStorage.setItem('role', JSON.stringify(role.data));
                      this.toggleLoading(false);
                      this.redirectUserDependingOnFirstLogin();
                    })
                    .catch((error) => {
                      this.throwSubmitError(error);
                    });
                })
                .catch((error) => {
                  this.logout();
                  this.throwSubmitError(error);
                });
            })
            .catch((error) => {
              this.throwSubmitError(error);
            });
        }
      },
      validateForgotPasswordForm() {
        this.clearAllErrors();
        const emailValid = validateEmail(String(self.username).toLowerCase());
        if (!emailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      onForgotPassword() {
        if (this.validateForgotPasswordForm()) {
          this.toggleLoading(true);
          forgotPassword(self.username)
            .then((response) => {
              this.setInfoText(response.data);
              this.toggleLoading(false);
            })
            .catch((error) => {
              this.throwSubmitError(error);
            });
        }
      },
      validateResetPasswordForm() {
        this.clearAllErrors();
        if (self.newPassword !== self.confirmedPassword) {
          this.setErrorText(PASSWORDS_NOT_MATCHING_ERROR_TEXT);
          return false;
        } else {
          this.setErrorText(null);
          return true;
        }
      },
      onResetPassword(token) {
        if (this.validateResetPasswordForm()) {
          this.toggleLoading(true);
          resetPassword(self.newPassword, self.confirmedPassword, token)
            .then((response) => {
              this.setResetPasswordToken(null);
              rootInstance.router.setView(
                rootInstance.router.views.get(PageRoutes.Login.name)
              );
              notification.success({
                message: SUCCESS,
                description: PASSWORD_SUCCESSFULLY_CHANGED_TEXT,
              });
              this.toggleLoading(false);
            })
            .catch((error) => {
              this.throwSubmitError(error);
            });
        }
      },
      validateCreatePasswordForm() {
        this.clearAllErrors();
        if (self.newPassword !== self.confirmedPassword) {
          this.setErrorText(PASSWORDS_NOT_MATCHING_ERROR_TEXT);
          return false;
        } else {
          this.setErrorText(null);
          return true;
        }
      },
      onCreatePassword(token) {
        const decodedToken: DecodedToken = jwt_decode(token);
        const userMail = decodedToken.data.email;
        this.clearAllErrors();
        if (this.validateCreatePasswordForm()) {
          this.toggleLoading(true);
          createPassword(self.newPassword, self.confirmedPassword, token)
            .then((response) => {
              localStorage.setItem('first_time_in_app', 'true');
              this.submit(userMail, self.newPassword);
            })
            .catch((error) => {
              this.throwSubmitError(error);
            });
        }
      },
      toggleLoading(status) {
        self.loading = status;
      },
      throwFetchAccountSettingsError(error) {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_ACCOUNT_SETTINGS_ERROR,
          description: errorText,
        });
        setFetchingInProgress(false);
      },
      getCurrentUser() {
        setFetchingInProgress(true);
        const data = localStorage.getItem('user');
        const user = JSON.parse(data);
        this.setCurrentUser(user);
        if (self.firstTimeInApp) {
          self.currentUser.setFirstTimeInApp(true);
        }
        getAccountAvailableRoles()
          .then((roles) => {
            getAccountActiveRole()
              .then((active_role) => {
                getAllAccountDocumentation()
                  .then((documentation) => {
                    self.currentUser.setUserAssignedRoles(roles.data);
                    self.currentUser.setActiveRole(active_role.data);
                    self.currentUser.setDocuments(documentation.data);
                    self.currentUser.getCountriesList();
                    setFetchingInProgress(false);
                  })
                  .catch((error) => {
                    this.throwFetchAccountSettingsError(error);
                  });
              })
              .catch((error) => {
                this.throwFetchAccountSettingsError(error);
              });
          })
          .catch((error) => {
            this.throwFetchAccountSettingsError(error);
          });
      },
      setCurrentUser(user) {
        self.currentUser = user;
      },
    };
  });
