import React from 'react';
import { observer } from 'mobx-react';
import ShipmentsTable from './ShipmentsTable';
import DiagramsSection from './DiagramsSection';
import {
  ALL,
  ASSETS_SHIPMENTS,
  BROKERAGE_SHIPMENTS_NEW,
  CREATE_SHIPMENT,
  EXPORT_TO_CSV,
  FETCH_SHIPMENTS_ERROR,
  FETCH_TRUCKS_ERROR,
  FILTER_SHIPMENTS,
  PRINT_PDF,
  START_TYPING_PLACEHOLDER_TEXT,
} from '../../../../constants';
import PageRoutes from '../../../../utils/PageRoutes';
import Link from '../../../Link';
import search from '../../../../assets/images/search.svg';
import { DebounceInput } from 'react-debounce-input';
import { useMst } from '../../../../state/RootModel';
import { notification, Select, Switch } from 'antd';
import {
  exportToPdf,
  getErrorText,
  htmlToCSV,
} from '../../../../utils/methods';
import {
  SHIPMENTS_TABLE_DOC_NAME,
  SHIPMENTS_TABLE_TITLE,
} from '../../../../constants/tableData';
import { getShipmentsForExport } from '../../../../actions/shipments';

const { Option } = Select;

const ShipmentsComponent = () => {
  const TABLE_CLASS_NAME = 'shipments-table';

  const {
    shipmentsPageState: {
      setSearchTerm,
      searchTerm,
      setCurrentPage,
      getShipments,
      availableShipmentStatuses,
      setStatus,
      status,
      activeStatus,
      archived,
      brokerageShipmentStatus,
      setBrokerageShipmentStatus,
      shipmentsArray,
      getShipmentsColumns,
      setShipments,
      setTotalItems,
    },
    userState: { canAddShipments },
  } = useMst();

  const handleShipmentSearch = (e) => {
    const searchText = e.target.value;
    setSearchTerm(searchText);
    setCurrentPage(1);
    getShipments({});
  };

  const onFilterSelect = (e) => {
    setStatus(e);
  };

  const onBrokerageStatusChange = (value) => {
    setBrokerageShipmentStatus(value);
  };

  const handleCsvExport = async () => {
    try {
      const shipments = await getShipmentsForExport(
        searchTerm,
        status,
        archived,
        brokerageShipmentStatus,
        activeStatus
      );
      const table = document.getElementsByClassName('ant-table-fixed')[0];
      setShipments(shipments.data.shipments);
      setTotalItems(shipments.data.total);
      htmlToCSV(table, TABLE_CLASS_NAME, SHIPMENTS_TABLE_DOC_NAME);
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_TRUCKS_ERROR,
        description: errorText,
      });
    } finally {
      getShipments({ page: 1 });
    }
  };

  const handlePDFExport = async () => {
    try {
      const shipments = await getShipmentsForExport(
        searchTerm,
        status,
        archived,
        brokerageShipmentStatus,
        activeStatus
      );

      const headersArr = [[]];
      getShipmentsColumns().map((head) => headersArr[0].push(head.title));
      headersArr[0].pop();

      const data = shipments.data.shipments.map((ord) => {
        // Broker
        let broker_name =
          ord.broker_name === null ? ' / ' : ord.broker_name + ' / ';
        let broker_phone = ord.broker_phone === null ? ' ' : ord.broker_phone;
        let broker_data = broker_name + broker_phone;
        // Trailer
        let trailer_id =
          ord.trailer_type.equipment_id === null
            ? ' / '
            : ord.trailer_type.equipment_id + ' / ';
        let trailer_type =
          ord.trailer_type.equipment_type === null
            ? ' '
            : ord.trailer_type.equipment_type;
        let trailer_data = trailer_id + trailer_type;
        return [
          ord.shipment_number,
          ord.shipment_by.company || '/',
          ord.company_name || '/',
          ord.pio_number,
          broker_data,
          trailer_data,
          ord.status.charAt(0).toUpperCase() + ord.status.slice(1),
        ];
      });

      exportToPdf(
        headersArr,
        data,
        SHIPMENTS_TABLE_TITLE,
        SHIPMENTS_TABLE_DOC_NAME
      );
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_SHIPMENTS_ERROR,
        description: errorText,
      });
    } finally {
      getShipments({ page: 1 });
    }
  };

  return (
    <div className="pv20 shipments-component">
      {canAddShipments && (
        <Link
          className={'btn primary no-wrap header-button'}
          view={PageRoutes.CreateShipment.name}
        >
          {CREATE_SHIPMENT}
        </Link>
      )}
      <div className="justify-between align-center mb20">
        <div className="search-wrapper border-bottom-search align-center">
          <img src={search} alt="search" className="search-icon" />
          <DebounceInput
            debounceTimeout={300}
            onChange={(e) => handleShipmentSearch(e)}
            style={{ width: 300 }}
            placeholder={START_TYPING_PLACEHOLDER_TEXT}
            value={searchTerm}
          />
        </div>
        <div className="filters-wrapper align-center">
          <p>{FILTER_SHIPMENTS}</p>
          <Select
            className="text-capitalize ml20"
            style={{ width: 200 }}
            onChange={onFilterSelect}
            value={status}
          >
            <Option key={ALL} value={ALL} className="text-capitalize">
              -
            </Option>
            {availableShipmentStatuses.map((status) => (
              <Option
                key={status.status_id}
                value={status.status_id}
                className="text-capitalize"
              >
                {status.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="align-center justify-between mt20 mb20">
        <div>
          <span>{ASSETS_SHIPMENTS}</span>
          <Switch
            checked={brokerageShipmentStatus}
            onChange={onBrokerageStatusChange}
            className="ml10 mr10"
          />
          <span>{BROKERAGE_SHIPMENTS_NEW}</span>
        </div>
        <div>
          <button
            type="button"
            disabled={shipmentsArray.length === 0}
            className={`btn light add ml-a ${
              shipmentsArray.length === 0 ? 'disabled' : ''
            }`}
            onClick={handlePDFExport}
          >
            {PRINT_PDF}
          </button>
          <button
            type="button"
            disabled={shipmentsArray.length === 0}
            className={`btn white add ml10 ${
              shipmentsArray.length === 0 ? 'disabled' : ''
            }`}
            onClick={handleCsvExport}
          >
            {EXPORT_TO_CSV}
          </button>
        </div>
      </div>
      <ShipmentsTable tableClassName={TABLE_CLASS_NAME} />
      <DiagramsSection />
    </div>
  );
};

export default observer(ShipmentsComponent);
