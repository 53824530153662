import { types } from 'mobx-state-tree';
import { SHIPMENT_NOTE_EMPTY_TEXT } from '../../constants';
import { rootInstance } from '../RootModel';
import { deleteShipmentNote, editShipmentNote } from '../../actions/shipments';
import { setFetchingInProgress } from '../../utils/methods';
import PageRoutes from '../../utils/PageRoutes';

export const ShipmentNoteModel = types
  .model('ShipmentNoteModel', {
    note_id: types.identifierNumber,
    shipment_id: types.maybe(types.maybeNull(types.number)),
    note_text: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setNoteText(text) {
        self.note_text = text;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      validateNote() {
        if (!self.note_text) {
          this.setErrorText(SHIPMENT_NOTE_EMPTY_TEXT);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveNote() {
        if (this.validateNote()) {
          setFetchingInProgress(true);
          const shipment_id =
            rootInstance.shipmentsPageState.editedShipment.shipment_id;
          const params = { shipmentId: shipment_id };
          editShipmentNote(shipment_id, self.note_id, self.note_text)
            .then((response) => {
              rootInstance.shipmentsPageState.editedShipment.setShipmentNoteToBeEdited(
                null
              );
              rootInstance.router.setView(
                rootInstance.router.views.get(PageRoutes.EditShipment.name),
                params
              );
            })
            .catch((error) => {
              console.log('error: ', error);
              setFetchingInProgress(false);
            });
        }
      },
      removeShipmentNote() {
        const shipment_id =
          rootInstance.shipmentsPageState.editedShipment.shipment_id;
        const params = { shipmentId: shipment_id };

        setFetchingInProgress(true);
        deleteShipmentNote(shipment_id, self.note_id)
          .then(() => {
            setFetchingInProgress(true);
            rootInstance.shipmentsPageState.editedShipment.setShipmentNoteToBeDeleted(
              null
            );
            rootInstance.router.setView(
              rootInstance.router.views.get(PageRoutes.EditShipment.name),
              params
            );
          })
          .then((error) => {
            setFetchingInProgress(false);
          });
      },
    };
  });
