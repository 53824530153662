import { types } from 'mobx-state-tree';
import { EmployeeRoleModel } from './EmployeeRoleModel';
import { DepartmentModel } from '../departments/DepartmentModel';
import { validateEmail } from '../../utils/methods';
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  EMAIL_REQUIRED,
  USER_TYPE_REQUIRED,
  EMAIL_ERROR_TEXT,
  CODE_NAME_REQUIRED,
} from '../../constants';

export const EmployeeLightModel = types
  .model('EmployeeLightModel', {
    first_name: types.maybe(types.maybeNull(types.string)),
    last_name: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    user_department: types.maybeNull(types.reference(DepartmentModel)),
    errorText: types.maybe(types.maybeNull(types.string)),
    selectedRoles: types.array(types.reference(EmployeeRoleModel)),
    code_name: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {
      get selectedRolesIds() {
        return self.selectedRoles.map((role) => role.role_id);
      },
    };
  })
  .actions((self) => {
    return {
      setCodeName(code_name) {
        self.code_name = code_name;
      },
      setUserAssignedRoles(roles) {
        self.selectedRoles = roles;
      },
      setEmail(email) {
        self.email = email;
      },
      setFirstName(name) {
        self.first_name = name;
      },
      setLastName(last_name) {
        self.last_name = last_name;
      },
      setDepartment(user_department) {
        self.user_department = user_department;
      },
      setPhoneNumber(phone_number) {
        self.phone_number = phone_number;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      clearForm() {
        this.setCodeName(null);
        this.setFirstName(null);
        this.setLastName(null);
        this.setEmail(null);
        this.setUserAssignedRoles([]);
        this.setDepartment(null);
        this.setErrorText(null);
      },
      validateForm() {
        const emailValid = validateEmail(self.email);
        if (!self.first_name) {
          this.setErrorText(FIRST_NAME_REQUIRED);
        } else if (!self.last_name) {
          this.setErrorText(LAST_NAME_REQUIRED);
        } else if (!self.email) {
          this.setErrorText(EMAIL_REQUIRED);
        } else if (!self.selectedRoles.length) {
          this.setErrorText(USER_TYPE_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else if (!self.code_name) {
          this.setErrorText(CODE_NAME_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
    };
  });
