import React from 'react';
import { useMst } from '../../../state/RootModel';
import { observer } from 'mobx-react';
import FormPageTitle from '../../FormPageTitle';
import addLocationIcon from '../../../assets/images/add-location.svg';
import {
  ADD_LOCATION,
  CITY,
  COMPANY,
  COUNTRY,
  EDIT_LOCATION,
  GENERAL_INFORMATION,
  LOCATION_CONTACT_NAME,
  LOCATION_INFORMATION,
  LOCATION_NAME,
  OTHER,
  PHONE_NUMBER,
  STATE,
  ZIP_CODE,
} from '../../../constants';
import { Input, Modal, Select } from 'antd';

import PhoneInput from 'react-phone-number-input';
import ErrorBox from '../../ErrorBox';
import MapComponent from './MapComponent';

const { Option } = Select;

const EditOrganizationLocationModal = () => {
  const {
    organizationState: { editedOrganization, countriesArray, filteredStates },
  } = useMst();

  const onCancel = () => {
    editedOrganization.setEditOrganizationLocationModalOpen(false);
  };

  const onSelectCustomer = (customer) => {
    editedOrganization?.editedLocation.setOwnerId(customer);
  };

  const onSaveChanges = () => {
    editedOrganization.editOrganizationDefaultLocation();
  };

  return (
    <div>
      <Modal
        visible={editedOrganization?.isEditOrganizationLocationModalOpen}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={1000}
        className="modal"
        destroyOnClose={true}
      >
        <div className="ph10 location-modal-content">
          <div className="align-center">
            <FormPageTitle image={addLocationIcon} title={ADD_LOCATION} />
          </div>
          <div className="form-page-subsection">
            <div className="subsection-header mt0">
              <p>{GENERAL_INFORMATION}</p>
            </div>
          </div>

          <div className="flex-alignitems-flex-start mt20">
            <div className="input-wrapper w35">
              <div className="input-label justify-between">
                <span>{LOCATION_NAME}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) =>
                  editedOrganization?.editedLocation?.setLocationName(
                    e.target.value
                  )
                }
                value={editedOrganization?.editedLocation?.location_name}
              />
            </div>
            <div className="input-wrapper w35 ml5pc">
              <div className="input-label justify-between">
                <span>{COMPANY}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className="w100"
                onChange={onSelectCustomer}
                // disabled={true}
                value={editedOrganization?.editedLocation?.owner_id}
              >
                {editedOrganization?.getOwnersArray &&
                  editedOrganization?.getOwnersArray.map((owner, index) => (
                    <Option key={index} value={owner.client_id}>
                      {owner.company}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="flex-alignitems-flex-start mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{LOCATION_CONTACT_NAME}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) =>
                  editedOrganization?.editedLocation?.setLocationContactName(
                    e.target.value
                  )
                }
                value={
                  editedOrganization?.editedLocation?.location_contact_name
                }
              />
            </div>
            <div className="input-wrapper ml5pc">
              <div className="input-label justify-between">
                <span>{PHONE_NUMBER}</span>
                <span className="color-secondary">*</span>
              </div>
              <PhoneInput
                placeholder="e.g. 712338645"
                value={editedOrganization?.editedLocation?.phone_number}
                onChange={(value) =>
                  editedOrganization?.editedLocation?.setPhoneNumber(value)
                }
                maxLength={15}
                displayInitialValueAsLocalNumber
              />
            </div>
          </div>

          <div className="form-page-subsection mt20">
            <div className="subsection-header mt0">
              <p>{LOCATION_INFORMATION}</p>
            </div>
          </div>
          <div className="flex-alignitems-flex-start mt20 w100">
            <div className="flex-column w35">
              <div className="flex-alignitems-flex-start">
                <div className="input-wrapper w25">
                  <div className="input-label justify-between">
                    <span>{ZIP_CODE}</span>
                  </div>
                  <Input
                    className="add-edit-input"
                    onChange={(e) =>
                      editedOrganization?.editedLocation?.setPostalCode(
                        e.target.value
                      )
                    }
                    value={editedOrganization?.editedLocation?.postal_code}
                  />
                </div>

                <div className="input-wrapper w70 ml5pc">
                  <div className="input-label justify-between">
                    <span>{COUNTRY}</span>
                    <span className="color-secondary">*</span>
                  </div>
                  <Select
                    className={'w100'}
                    onChange={(country) =>
                      editedOrganization?.editedLocation?.setCountry(country)
                    }
                    value={editedOrganization?.editedLocation?.country}
                  >
                    {countriesArray.map((country, index) => (
                      <Option key={index} value={country.name}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="input-wrapper w100 mt20">
                <div className="input-label justify-between">
                  <span>{STATE}</span>
                  <span className="color-secondary">*</span>
                </div>
                <Select
                  className={'w100'}
                  onChange={(state) =>
                    editedOrganization?.editedLocation?.setState(state)
                  }
                  value={editedOrganization?.editedLocation?.state}
                >
                  {filteredStates &&
                    filteredStates.states.map((state, index) => (
                      <Option key={index} value={state}>
                        {state}
                      </Option>
                    ))}
                  <Option key={'other'} value={'Other'}>
                    {OTHER}
                  </Option>
                </Select>
              </div>

              <div className="input-wrapper w100 mt20">
                <div className="input-label justify-between">
                  <span>{CITY}</span>
                  <span className="color-secondary">*</span>
                </div>
                <Input
                  className="add-edit-input"
                  onChange={(e) =>
                    editedOrganization?.editedLocation?.setCity(e.target.value)
                  }
                  value={editedOrganization?.editedLocation?.city}
                />
              </div>
            </div>
            <MapComponent />
          </div>

          <ErrorBox obj={editedOrganization} />

          <div>
            <button className="btn primary" onClick={onSaveChanges}>
              {EDIT_LOCATION}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditOrganizationLocationModal);
