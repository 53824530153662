import React from 'react';
import {
  UTILIZATION,
  MAKE_MODEL_YEAR,
  IDLE_TIME,
  DRIVING_TIME,
  TRUCKS,
} from '../../constants';

export const utilizationColumnsFunc = (rootInstance) => ({
  '1': {
    title: MAKE_MODEL_YEAR,
    key: 'truck_id',
    width: '30%',
    render: (text, record) => {
      return (
        <span>
          {rootInstance.fleetPageState.utilizationTableData === TRUCKS
            ? record.truck_make
            : record.trailer_make}{' '}
          {rootInstance.fleetPageState.utilizationTableData === TRUCKS
            ? record.truck_model
            : record.trailer_type}{' '}
          {record.year}
        </span>
      );
    },
  },
  '2': {
    title: UTILIZATION,
    key: 'utilization',
    dataIndex: 'utilization',
  },
  '3': {
    title: IDLE_TIME,
    key: 'idle_time',
    dataIndex: 'idle_time',
  },
  '4': {
    title: DRIVING_TIME,
    key: 'driving_time',
    dataIndex: 'driving_time',
  },
});
