import { rootInstance } from '../../state/RootModel';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getClients = (text, pageNumber, pageSize) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/clients?page=${pageNumber}&articles_per_page=${pageSize}${textQuery}`,
    config
  );
};

export const getClient = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/clients/${id}`, config);
};

export const searchClients = (text) => {
  const config = {
    headers: {
      Authorization: getToken(),
    },
    data: {
      text: text,
    },
  };
  return axios.get(
    `${rootInstance.baseUrl}/search_clients?text=${text}`,
    config
  );
};

export const addClient = (client) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/clients`,
    {
      company: client.company,
      address: client.address,
      city: client.city,
      state: client.state,
      country: client.country,
      mc: client.mc,
      postal_code: client.postal_code,
      phone_number: client.phone_number,
      email: client.email,
      billing_email: client.billing_email,
      notes: client.notes,
    },
    config
  );
};

export const deleteClient = (client_id) => {
  const config = getConfig();
  return axios.delete(`${rootInstance.baseUrl}/clients/${client_id}`, config);
};

export const editClient = (client) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/clients/${client.client_id}`,
    {
      company: client.company,
      address: client.address,
      city: client.city,
      state: client.state,
      country: client.country,
      postal_code: client.postal_code,
      phone_number: client.phone_number,
      email: client.email,
      billing_email: client.billing_email,
      mc: client.mc,
      notes: client.notes,
    },
    config
  );
};

export const getClientLocations = (client_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/clients/${client_id}/locations`,
    config
  );
};
