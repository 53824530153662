import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import FormPageTitle from '../../FormPageTitle';
import viewTruckIcon from '../../../assets/images/view-truck.svg';
import { EDIT_TRUCK, VIEW_TRUCK } from '../../../constants';
import PageRoutes from '../../../utils/PageRoutes';
import Link from '../../Link';
import GeneralInformation from './GeneralInformation';
import ViewDocumentationSection from '../SharedComponents/ViewComponents/ViewDocumentationSection';
import ViewRegistrationSection from '../SharedComponents/ViewComponents/ViewRegistrationSection';
import ViewInspectionsSection from '../SharedComponents/ViewComponents/ViewInspectionsSection';
import ViewMaintenanceSection from '../SharedComponents/ViewComponents/ViewMaintenanceSection';
import ViewAssociationsSection from './ViewAssociationsSection';

const ViewTruckComponent = () => {
  const {
    fleetPageState: { viewedTruck },
  } = useMst();

  return (
    <div className="add-edit-form-component">
      {viewedTruck?.vehicleFullyFetched && (
        <div>
          {/* TITLE */}
          <div className="align-center">
            <FormPageTitle image={viewTruckIcon} title={VIEW_TRUCK} />
            <span>
              <h2 className="text-medium color-primary">
                {viewedTruck.vehicle_make} {viewedTruck.vehicle_model_name}{' '}
                {viewedTruck.vehicle_production_year}
              </h2>
            </span>
            <Link
              className={'btn primary ml10 ph20 pv0'}
              view={PageRoutes.EditTruck.name}
              params={{ truckId: viewedTruck.vehicle_id }}
            >
              {EDIT_TRUCK}
            </Link>
          </div>
          <GeneralInformation />
          <ViewRegistrationSection model={viewedTruck} />
          <ViewAssociationsSection />
          <ViewInspectionsSection model={viewedTruck} />
          <ViewMaintenanceSection model={viewedTruck} />
          <ViewDocumentationSection model={viewedTruck} />
        </div>
      )}
    </div>
  );
};

export default observer(ViewTruckComponent);
