import React from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';
import {
  DRIVER_PAYROLL,
  ADD_COMPENSATION,
  DISCARD,
  ADD_COMPENSATION_TO_DRIVER,
} from '../../../constants';
import CompensationsTable from './CompensationsTable';
import CompensationValuesSection from './CompensationValuesSection';
import { CREATE_COMPENSATION_STRUCTURE } from '../../../constants/payroll';
import { CompensationsModel } from '../../../state/payroll/Compensations/CompensationsModel';
import PageRoutes from '../../../utils/PageRoutes';
const DriverPayroll = () => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-section-title-row align-center">
        <span className="form-section-title">{DRIVER_PAYROLL}</span>
        {!editedDriver.isAddCompensationFormOpen && (
          <button
            className="btn primary ml20 ph20 pv0"
            onClick={() => editedDriver.setIsAddCompensationFormOpen(true)}
          >
            {ADD_COMPENSATION}
          </button>
        )}
      </div>
      {editedDriver.isAddCompensationFormOpen ? (
        <>
          <button
            className="btn primary ml0 ph20 pv0 mb20"
            onClick={() => {
              const currentView = rootInstance.router.getCurrentView;
              rootInstance.router.setView(
                rootInstance.router.views.get(PageRoutes.AddCompensation.name)
              );
              rootInstance.router.setNextView(currentView);
            }}
          >
            {CREATE_COMPENSATION_STRUCTURE}
          </button>
          <CompensationValuesSection />
          {editedDriver.selectedCompensationToAdd && (
            <div className="flex justify-between align-center mt30 mb10">
              <button
                type="button"
                className="btn transparent discard text-capitalize ml10 mt10 mb20"
                onClick={() => editedDriver.setIsAddCompensationFormOpen(false)}
              >
                {DISCARD}
              </button>
              <button
                className="btn primary ml0 ph20 pv0 mb20"
                onClick={() => editedDriver.addCompensationToDriver()}
              >
                {ADD_COMPENSATION_TO_DRIVER}
              </button>
            </div>
          )}
        </>
      ) : (
        <CompensationsTable />
      )}
    </div>
  );
};

export default observer(DriverPayroll);
