import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import FormPageTitle from '../../FormPageTitle';
import addDocumentIcon from '../../../assets/images/menu/brokerage-active.svg';
import { ADD_NOTE, CANCEL, NOTE_TEXT } from '../../../constants';
import { Input, Modal } from 'antd';

const AddShipmentNoteModal = () => {
  const {
    shipmentsPageState: { currentShipment },
  } = useMst();

  const onCancel = () => {
    currentShipment.setNewShipmentNote(null);
  };

  const onConfirm = () => {
    currentShipment.newShipmentNote.onAddNewNote();
  };

  return (
    <div>
      <Modal
        visible={!!currentShipment?.newShipmentNote}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={600}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addDocumentIcon} title={ADD_NOTE} />
          </div>
          <div className="input-wrapper w100 mt20">
            <div className="input-label justify-between">
              <span>{NOTE_TEXT}</span>
              <span className="color-secondary">*</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={(e) =>
                currentShipment.newShipmentNote.setNoteText(e.target.value)
              }
              value={currentShipment.newShipmentNote.note_text}
            />
          </div>
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {ADD_NOTE}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddShipmentNoteModal);
