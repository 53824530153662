import React from 'react';
import { rootInstance } from '../../RootModel';

import { Tooltip } from 'antd';
import {
  CODE_NAME,
  CREATE_ACTION,
  CURRENT_ACTION,
  DISTANCE,
  ETA,
  HOS,
  ID,
  VIEW_AND_TRACK_ON_MAP,
} from '../../../constants/dispatch';
import { NOT_AVAILABLE_SHORT, STATUS } from '../../../constants';
import selectIcon from '../../../assets/images/select-icon.svg';
import { eta_format, toAmount } from '../../../utils/methods';
import createTrip from '../../../assets/images/dispatch/create_trip_icon.svg';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';

const onDriverChange = (e, record) => {
  e.stopPropagation();
  const driver = {
    driver_id: record.driver_id,
    driver_internal_company_id: record.internal_company_id,
    truck_id: record?.linked_truck_data?.vehicle_id,
    truck_internal_company: record?.linked_truck_data?.internal_company_id,
  };
  rootInstance.dispatchPageState.addNewRouteActionItem({ driver });
  setTimeout(() => {
    rootInstance.dispatchPageState.setIsAssetsListOpened(false);
  }, 100);
};
const onShowDriverOnMap = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsAssetsListOpened(false);
  rootInstance.dispatchPageState.setDriverToShowOnMap(record);
};

export const AssetsListDriversTableColumns = (rootInstance) => ({
  '1': {
    title: ID,
    dataIndex: 'internal_company_id',
    key: 'internal_company_id',
    width: 100,
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '2': {
    title: CODE_NAME,
    dataIndex: 'code_name',
    key: 'code_name',
    width: 200,
    render: (text, record) => {
      let type =
        record?.driver_type === COMPANY_DRIVER
          ? 'CD'
          : record?.driver_type === OWNER_OPERATOR
          ? 'OO'
          : NOT_AVAILABLE_SHORT;
      return (
        <p>
          <span>{text ? text : NOT_AVAILABLE_SHORT}</span>
          <span className="ml10">
            <strong
              className={`text-bold ${record?.driver_type ? 'text-blue' : ''}`}
            >
              {record?.driver_type ? type : NOT_AVAILABLE_SHORT}
            </strong>
          </span>
        </p>
      );
    },
  },
  '3': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (text) => {
      return (
        <span
          className={`text-capitalize text-bold ${
            text === 'Available' ? 'text-green' : 'text-orange'
          }`}
        >
          {text ? text : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '4': {
    title: CURRENT_ACTION,
    dataIndex: 'current_action',
    key: 'current_action',
    render: (text, record) => {
      return (
        <span>
          {record?.current_action?.name
            ? record?.current_action?.name
            : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '5': {
    title: HOS,
    dataIndex: 'driver_hos',
    key: 'driver_hos',
    width: '300px',
    render: (text, record) => {
      if (record?.driver_hos) {
        return (
          <div className="text-black">
            <span className="text-blue text-bold d-iblock">BR</span>{' '}
            {record?.driver_hos?.break
              ? record.driver_hos.break
              : NOT_AVAILABLE_SHORT}
            <span className="text-blue text-bold d-iblock ml5">DR</span>{' '}
            {record?.driver_hos?.drive
              ? record.driver_hos.drive
              : NOT_AVAILABLE_SHORT}
            <span className="text-blue text-bold d-iblock ml5">SH</span>{' '}
            {record?.driver_hos?.shift
              ? record.driver_hos.shift
              : NOT_AVAILABLE_SHORT}
            <span className="text-blue text-bold d-iblock ml5">CL</span>{' '}
            {record?.driver_hos?.cycle
              ? record.driver_hos.cycle
              : NOT_AVAILABLE_SHORT}
          </div>
        );
      }
      return <p>NA</p>;
    },
  },
  '6': {
    title: '',
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          {record?.linked_truck_data?.vehicle_latitude &&
            record?.linked_truck_data?.vehicle_longitude && (
              <Tooltip title={VIEW_AND_TRACK_ON_MAP}>
                <img
                  src={selectIcon}
                  className="cursor-pointer mr10"
                  alt={VIEW_AND_TRACK_ON_MAP}
                  onClick={(e) => onShowDriverOnMap(e, record)}
                />
              </Tooltip>
            )}
          <Tooltip title={CREATE_ACTION}>
            <img
              src={createTrip}
              className="cursor-pointer"
              alt={CREATE_ACTION}
              onClick={(e) => onDriverChange(e, record)}
            />
          </Tooltip>
        </div>
      );
    },
  },
});
