import React from 'react';
import { observer } from 'mobx-react';
import brokerageIcon from '../../../assets/images/menu/brokerage-active.svg';
import {
  EDIT_SHIPMENT,
  SHIPMENT_NO,
  STATUS,
  SHIPMENT_STATUS_SELECT_PLACEHOLDER,
  SHIPMENT_SIZE,
} from '../../../constants';
import { Input, Radio, Select } from 'antd';
import { useMst } from '../../../state/RootModel';

const { Option } = Select;

const EditShipmentHeader = () => {
  const {
    shipmentsPageState: {
      editedShipment,
      availableShipmentStatuses,
      availableShipmentSizes,
    },
  } = useMst();

  const onSelectStatus = (status) => {
    editedShipment.setStatus(status);
  };
  const onChange = (e) => {
    editedShipment.setShipmentSize(e.target.value);
  };
  return (
    <div className="page-section create-shipment-header">
      <div className="section-content align-center">
        <div className="page-title align-center">
          <img src={brokerageIcon} alt="brokerage" className="mr10" />
          <h3>{EDIT_SHIPMENT}</h3>
        </div>
        <div className="input-horizontal align-center ml10">
          <span className="no-wrap mr10">{SHIPMENT_NO}</span>
          <Input
            className="add-edit-input"
            disabled
            value={editedShipment?.shipment_number}
          />
        </div>
        <div className="input-horizontal align-center ml10">
          <span className="no-wrap mr10">{STATUS}</span>
          <Select
            placeholder={
              editedShipment.status ? null : SHIPMENT_STATUS_SELECT_PLACEHOLDER
            }
            onChange={onSelectStatus}
            className="text-capitalize"
            value={editedShipment.status}
          >
            {availableShipmentStatuses.map((status, index) => (
              <Option
                key={status.status_id}
                value={status.status_id}
                className="text-capitalize"
              >
                {status.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-horizontal align-center ml10">
          <span className="no-wrap mr10">{SHIPMENT_SIZE}</span>
          <Radio.Group onChange={onChange} value={editedShipment.shipment_size}>
            {availableShipmentSizes.map((shipment_size) => (
              <Radio key={shipment_size} value={shipment_size}>
                {shipment_size}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default observer(EditShipmentHeader);
