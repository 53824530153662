import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { ADD_ABSENCE_TEXT, WORKING_STATUS } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PageRoutes from '../../../utils/PageRoutes';
import Link from '../../Link';

const localizer = momentLocalizer(moment);

const WorkingStatusComponent = () => {
  const {
    employeesPageState: { viewedEmployee },
    userState: { canAddUserAbsences },
  } = useMst();

  const onEventClick = (absence) => {
    viewedEmployee.setViewedAbsence(absence.absence_id);
  };

  return (
    <div className="form-page-section view-driver-section pb20">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedEmployee.setIsWorkingStatusSectionOpen(
              !viewedEmployee.isWorkingStatusSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedEmployee.isWorkingStatusSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {WORKING_STATUS}
          </span>
          {canAddUserAbsences && (
            <Link
              className={'btn primary ml10 ph20 pv0'}
              view={PageRoutes.EditEmployee.name}
              params={{ employeeId: viewedEmployee.user_id }}
              queryParams={{ section: 'working-status' }}
            >
              {ADD_ABSENCE_TEXT}
            </Link>
          )}
        </div>
      </div>
      {viewedEmployee?.isWorkingStatusSectionOpen && (
        <div className="flex">
          <Calendar
            localizer={localizer}
            events={viewedEmployee.absencesArray}
            style={{ height: 500, width: '100%' }}
            defaultView="month"
            views={['month']}
            eventPropGetter={(event) => {
              return {
                style: { backgroundColor: event.color },
              };
            }}
            onSelectEvent={onEventClick}
          />
        </div>
      )}
    </div>
  );
};

export default observer(WorkingStatusComponent);
