import { types } from 'mobx-state-tree';
import { SHIPMENT_STATUS } from '../../../constants/shipmentSpecificsTypes';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { TITLE_REQUIRED } from '../../../constants';
import { addShipmentSpecific } from '../../../actions/shipmentSpecifics';
import { rootInstance } from '../../RootModel';

export const ShipmentStatusLightModel = types
  .model('ShipmentStatusModel', {
    type: SHIPMENT_STATUS,
    name: types.maybe(types.maybeNull(types.string)),
    status_for_active_shipment: types.maybe(types.maybeNull(types.boolean)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setName(value) {
        self.name = value;
      },
      setActive(value) {
        self.status_for_active_shipment = value;
      },
      clearModel() {
        this.setName(null);
        this.setActive(null);
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveItemError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.name) {
          this.setErrorText(TITLE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveItem() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addShipmentSpecific(self)
            .then(() => {
              rootInstance.shipmentSpecificsPageState.setNewShipmentSpecificModalOpen(
                false
              );
              rootInstance.shipmentSpecificsPageState.setNewShipmentStatus(
                null
              );
              rootInstance.shipmentSpecificsPageState.setSelectedShipmentSpecificType(
                SHIPMENT_STATUS
              );
            })
            .catch((error) => {
              this.throwSaveItemError(error);
            });
        }
      },
    };
  });
