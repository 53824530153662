import React from 'react';
import { observer } from 'mobx-react';

const ErrorBox = (props) => {
  const obj = { ...props.obj };

  return (
    <div className="error-box mt20 mb5">
      {obj.errorText && <p className="color-error">{obj.errorText}</p>}
    </div>
  );
};

export default observer(ErrorBox);
