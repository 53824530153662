import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal } from 'antd';
import {
  CONFIRM,
  CANCEL,
  CONFIRM_DELETE_ABSENCE_MODAL_TEXT,
} from '../../../constants';

const ConfirmDeleteAbsenceModal = () => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  const onCancel = () => {
    editedDriver.editedAbsence.setIsConfirmDeleteModalOpen(false);
  };

  const onConfirm = () => {
    editedDriver.editedAbsence.deleteAbsence();
  };

  return (
    <div>
      <Modal
        visible={editedDriver?.editedAbsence?.isConfirmDeleteModalOpen}
        footer={null}
        centered={true}
        closable={false}
        maskClosable={false}
        width={400}
        className="confirm-delete-modal"
      >
        <div className="p32">
          <div>
            <p>{CONFIRM_DELETE_ABSENCE_MODAL_TEXT}</p>
          </div>
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ConfirmDeleteAbsenceModal);
