import React from 'react';
import { observer } from 'mobx-react';
import brokerageIcon from '../../../assets/images/menu/brokerage-active.svg';
import {
  EDIT_SHIPMENT,
  LOCAL_PD,
  SHIPMENT_NO,
  ORGANIZE_TOUR,
  STATUS,
  VIEW_SHIPMENT,
  SHIPMENT_SIZE,
} from '../../../constants';
import { rootInstance, useMst } from '../../../state/RootModel';
import PageRoutes from '../../../utils/PageRoutes';

const ViewShipmentHeader = () => {
  const {
    shipmentsPageState: {
      viewedShipment,
      setIsSendDataToPlannerModalOpen,
      availableCreateShipmentStatuses,
    },
  } = useMst();

  const shipment_status = availableCreateShipmentStatuses.filter(
    (status) => status.status_id === viewedShipment.status
  );

  const onEditShipment = () => {
    const params = { shipmentId: viewedShipment.shipment_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.EditShipment.name),
      params
    );
  };

  const onOrganizeTour = () => {
    const params = { shipmentId: viewedShipment.shipment_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.Dispatch.name),
      params
    );
  };

  const onSendDataToPlanner = () => {
    setIsSendDataToPlannerModalOpen(true);
  };

  return (
    <div className="page-section create-shipment-header">
      <div className="section-content align-center">
        <div className="page-title align-center">
          <img src={brokerageIcon} alt="brokerage" className="mr10" />
          <h3>{VIEW_SHIPMENT}</h3>
        </div>
        <div className="align-center ml10">
          <span className="no-wrap mr10">{SHIPMENT_NO}:</span>
          <span className="text-medium">{viewedShipment?.shipment_number}</span>
        </div>
        <div className="align-center ml50">
          <span className="no-wrap mr10">{STATUS}:</span>
          <span className="text-medium text-capitalize">
            {availableCreateShipmentStatuses && shipment_status[0]?.name}
          </span>
        </div>
        <div className="input-horizontal align-center ml50">
          <span className="no-wrap mr10">
            {SHIPMENT_SIZE}: {viewedShipment?.shipment_size}
          </span>
        </div>

        <div className="align-center ml-a">
          {!viewedShipment.is_brokerage_shipment && (
            <div>
              {viewedShipment?.probillsArray.length > 0 && (
                <button className="btn btn-gray" onClick={onOrganizeTour}>
                  {ORGANIZE_TOUR}
                </button>
              )}
              <button
                className="btn primary ml10"
                onClick={onSendDataToPlanner}
              >
                {LOCAL_PD}
              </button>
            </div>
          )}

          <button className="btn primary ml10" onClick={onEditShipment}>
            {EDIT_SHIPMENT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ViewShipmentHeader);
