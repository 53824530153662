import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Empty, Table } from 'antd';
import { EMPTY_USER_ROLES_TABLE_TEXT } from '../../../constants/organization/userRole';

const UserRoleTable = () => {
  const {
    currentOrganization: { getUserRolesColumns, organizationUserRoles },
  } = useMst();

  return (
    <Table
      className="drivers-table fleet-table"
      rowKey={'role_id'}
      columns={getUserRolesColumns()}
      dataSource={organizationUserRoles}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={EMPTY_USER_ROLES_TABLE_TEXT}
          />
        ),
      }}
    />
  );
};

export default observer(UserRoleTable);
