import {
  deleteOrganizationImage,
  uploadOrganizationLogo,
} from '../../actions/organizations';
import { searchClients } from '../../actions/clients';

export const uploadOrganizationPhoto = (organization_id, document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    const documentType = document.originFileObj.documentType;
    formData.append('file', document.originFileObj);
    uploadOrganizationLogo(organization_id, formData, documentType)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteOrganizationPhoto = (organization_id, documentType) => {
  return new Promise((resolve, reject) => {
    deleteOrganizationImage(organization_id, documentType)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getClients = (text) => {
  return new Promise((resolve, reject) => {
    searchClients(text)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
