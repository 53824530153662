import { getClientLocations } from '../../actions/clients';
import { getLocation } from '../../actions/locations';

export const shipperLocations = (model) => {
  return new Promise((resolve, reject) => {
    getLocation(model.pickup_location_id)
      .then((location) => {
        model.setInitialShipperId(location.data.owner_id);
        getClientLocations(model.shipper_id).then((locations) => {
          model.setPickupLocations(locations.data);
          model.setSelectedPickupLocation(location.data);
          resolve(locations);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const receiverLocations = (model) => {
  return new Promise((resolve, reject) => {
    getLocation(model.delivery_location_id)
      .then((location) => {
        model.setInitialReceiverId(location.data.owner_id);
        getClientLocations(model.receiver_id).then((locations) => {
          model.setDeliveryLocations(locations.data);
          model.setSelectedDeliveryLocation(location.data);
          resolve(locations);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
