import { rootInstance } from '../../state/RootModel';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getDispatchDataByShipmentId = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/shipments/${id}/dispatch`, config);
};
export const getLocationsByShipmentId = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/shipments/${id}/locations`, config);
};

export const getAvailableVehiclesByShipment = (shipment) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipments/${shipment.id}/available_trucks?longitude=${shipment.long}&latitude=${shipment.lat}&departure_time=${shipment.departure}&distance_in_miles=${shipment.distance}`,
    config
  );
};
export const getAvailableTrailersByShipment = (shipment_id, radius) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/available_trailers?radius=${radius}`,
    config
  );
};

export const getAllTrackingShipments = (
  status = true,
  text = null,
  page = 1,
  items = 25
) => {
  const config = getConfig();

  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/tracking_shipments?active=${status}&page=${page}&articles_per_page=${items}${textQuery}`,
    config
  );
};

export const createRouteAction = (model) => {
  const config = getConfig();
  return axios.post(`${rootInstance.baseUrl}/route_actions`, model, config);
};

export const editRouteAction = (route_action_id, data) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/route_actions/${route_action_id}`,
    data,
    config
  );
};

export const getShipmentRouteActions = (shipment_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipment/${shipment_id}/route_actions`,
    config
  );
};
export const deleteShipmentRouteActions = (route_action_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/route_actions/${route_action_id}`,
    config
  );
};

export const getALlRouteActions = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/all_actions`, config);
};
export const getALlRouteActionStatuses = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/route_action_statuses`, config);
};
export const updateRouteActionStatus = (route_action_id, status) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/route_action/${route_action_id}/change_status?new_status=${status}`,
    '',
    config
  );
};
export const getRouteActionByID = (route_action_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/route_actions/${route_action_id}`,
    config
  );
};

export const getRouteActionTrucks = (text = null, page = 1, items = 10000) => {
  const config = getConfig();
  let text_search = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/route_action/available_trucks?page=${page}&articles_per_page=${items}${text_search}`,
    config
  );
};
export const getRouteActionDrivers = (
  filter,
  page = 1,
  location_id = null,
  text = null,
  items = 10
) => {
  const config = getConfig();
  const text_search = text ? `&text=${text}` : '';
  const location_search = location_id ? `&location_id=${location_id}` : '';
  let filter_search = filter ? filter.replace('&', '%26') : '';
  return axios.get(
    `${rootInstance.baseUrl}/route_action/available_drivers?filter=${filter_search}${text_search}&page=${page}&articles_per_page=${items}${location_search}`,
    config
  );
};
export const getRouteActionTrailers = (
  page = 1,
  location_id = null,
  text,
  shipment_id = null,
  filter = 'All',
  perPage = 10
) => {
  const location_search = location_id ? `&location_id=${location_id}` : '';
  const shipment_id_search = shipment_id ? `&shipment_id=${shipment_id}` : '';
  const text_search = text ? `&text=${text}` : '';
  let filter_search = filter ? filter.replace('&', '%26') : '';
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/route_action/available_trailers?filter=${filter_search}&page=${page}&articles_per_page=${perPage}${location_search}${shipment_id_search}${text_search}`,
    config
  );
};
export const getRouteActionShipments = (text = null) => {
  const text_search = text ? `?text=${text}` : '';
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/all_shipments${text_search}`,
    config
  );
};

export const getAssets = (
  asset,
  page = 1,
  perPage = 10,
  internal_company_id = null
) => {
  const config = getConfig();
  return axios.get(
    `${
      rootInstance.baseUrl
    }/assets?asset=${asset}&page=${page}&articles_per_page=${perPage}${
      internal_company_id ? `&internal_company_id=${internal_company_id}` : ''
    }`,
    config
  );
};
export const getPlannedShipments = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/shipment_list`, config);
};
export const relocatePlannerShipment = (data) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/shipments/${data.id}/relocate_shipment?action_type=${data.at}&dispatch_category=${data.dc}`,
    null,
    config
  );
};

export const drawRoute = (route_data) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/route`,
    {
      departure_position: [
        route_data.departure_position[0],
        route_data.departure_position[1],
      ],
      destination_position: [
        route_data.destination_position[0],
        route_data.destination_position[1],
      ],
    },
    config
  );
};

export const getDriverFilterOptions = (location = null) => {
  const location_search = location ? `?location_id=${location}` : '';
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/filters_for_drivers${location_search}`,
    config
  );
};

export const getRouteActionList = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/route_actions`, config);
};

export const getVehicleRouteActions = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicle/${vehicle_id}/route_actions`,
    config
  );
};

export const getRouteActionsList = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/route_actions`, config);
};

export const createRouteActionLocations = (route_action_id, data) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/route_actions/${route_action_id}/locations`,
    data,
    config
  );
};

export const getTrackingShipmentData = (shipment_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/tracking_shipment`,
    config
  );
};
