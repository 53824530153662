import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal } from 'antd';
import {
  EMPTY_AVAILABLE_TRUCKS_TABLE_TEXT,
  TRUCKS_LIST,
} from '../../../constants/dispatch';
import { DebounceInput } from 'react-debounce-input';
import AssetsTable from './AssetsTable';
import { SEARCH_BY_ID_STATUS } from '../../../constants';

const OrganizeTourTrucksModal = () => {
  const {
    dispatchPageState: {
      isOrganizeTourTrucksModalOpen,
      setIsOrganizeTourTrucksModalOpen,
      trucksModalSearch,
      setTrucksModalSearch,
      organizeTourTrucks,
      getAvailableTrucksTableColumns,
      setTruckOgPage,
      resetOgTrucksData,
    },
  } = useMst();

  const onClose = () => {
    setTruckPageNumber(1);
    resetOgTrucksData();
    setIsOrganizeTourTrucksModalOpen(false);
  };
  const onTruckSearch = (e) => {
    setTrucksModalSearch(e.target.value);
  };

  const [truckPage, setTruckPageNumber] = useState(2);
  const updateTrucks = () => {
    setTruckPageNumber((prev) => {
      return prev + 1;
    });
    setTruckOgPage(truckPage);
  };

  return (
    <div>
      <Modal
        visible={!!isOrganizeTourTrucksModalOpen}
        style={{ top: 88 }}
        footer={null}
        centered={false}
        closable={true}
        onCancel={onClose}
        width={1500}
        className="assign-trailer-modal"
      >
        <div className="p32">
          <div className="align-center mb40">
            <p className="mr50 color-title text-bold text-uppercase">
              {TRUCKS_LIST}
            </p>
            <div className="input-wrapper">
              <DebounceInput
                debounceTimeout={300}
                placeholder={SEARCH_BY_ID_STATUS}
                value={trucksModalSearch}
                onChange={(e) => onTruckSearch(e)}
                className="trailer-search-input"
              />
            </div>
          </div>
          {organizeTourTrucks && (
            <AssetsTable
              tableText={EMPTY_AVAILABLE_TRUCKS_TABLE_TEXT}
              data={organizeTourTrucks}
              column={getAvailableTrucksTableColumns()}
              update={updateTrucks}
            />
          )}
          {/*<Table*/}
          {/*    className="mb20"*/}
          {/*    rowKey={'vehicle_id'}*/}
          {/*    columns={getAvailableTrucksTableColumns()}*/}
          {/*    dataSource={organizeTourTrucks}*/}
          {/*    expandRowByClick={true}*/}
          {/*    expandedRowRender={record => <OrganizeTourTruckTableDetails data={record}/>}*/}
          {/*    expandIcon={() => null}*/}
          {/*    pagination={false}*/}
          {/*    // scroll={{y: 500}}*/}
          {/*    locale={{*/}
          {/*        emptyText: (*/}
          {/*            <Empty*/}
          {/*                image={Empty.PRESENTED_IMAGE_SIMPLE}*/}
          {/*                description={EMPTY_AVAILABLE_TRUCKS_TABLE_TEXT}*/}
          {/*            />*/}
          {/*        ),*/}
          {/*    }}*/}
          {/*/>*/}
          {/*<div className="table-footer">*/}
          {/*    <Pagination*/}
          {/*        onChange={paginationChange}*/}
          {/*        total={truckOgTotal}*/}
          {/*        current={truckOgPage}*/}
          {/*        pageSize={truckOgPerPage}*/}
          {/*    />*/}
          {/*</div>*/}
        </div>
      </Modal>
    </div>
  );
};

export default observer(OrganizeTourTrucksModal);
