import React from 'react';
import { useMst } from '../../../state/RootModel';
import { observer } from 'mobx-react';
import { Upload } from 'antd';
import questionMark from '../../../assets/images/question-mark.svg';
import fileUpload from '../../../assets/images/file-upload.svg';
import {
  EMPLOYEE_DOCUMENTATION,
  UPLOAD_DOCUMENTS_TEXT,
  CV_RESUME,
  EMPLOYEE_AGREEMENT,
  OTHER_DOCUMENTS,
} from '../../../constants';

const DocumentationSection = (props) => {
  const handleCVUpload = props.uploadCV;
  const cv = [...props.cv];
  const handleEmployeeAgreementUpload = props.uploadEmployeeAgreement;
  const employeeAgreement = [...props.employeeAgreement];
  const handleOtherDocumentsUpload = props.uploadOtherDocuments;
  const otherDocuments = [...props.otherDocuments];

  const {
    loginForm: { currentUser },
  } = useMst();

  const onFilePreview = (file) => {
    window.open(file.url, '_blank');
  };

  return (
    <div
      className={`form-page-section ${
        currentUser ? 'ant-upload-icons-removed' : ''
      }`}
    >
      <div className="table-title-row">
        <span className="color-primary text-medium ml10">
          {EMPLOYEE_DOCUMENTATION}
        </span>
        <img src={questionMark} alt="question mark" className="ml20" />
        <span className="ml10 text-regular text-italic">
          {UPLOAD_DOCUMENTS_TEXT}
        </span>
      </div>
      <div className="section-content flex">
        <div className="input-wrapper ml0">
          <div className="input-label">
            <Upload
              fileList={cv}
              onChange={handleCVUpload}
              data={{ CV_RESUME }}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              className="pointer"
              onPreview={(file) => {
                onFilePreview(file);
              }}
              openFileDialogOnClick={currentUser ? false : true}
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{CV_RESUME}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Upload
              fileList={employeeAgreement}
              onChange={handleEmployeeAgreementUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              onPreview={(file) => {
                onFilePreview(file);
              }}
              openFileDialogOnClick={currentUser ? false : true}
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary text-capitalize">
                  {EMPLOYEE_AGREEMENT}
                </span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Upload
              fileList={otherDocuments}
              onChange={handleOtherDocumentsUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              onPreview={(file) => {
                onFilePreview(file);
              }}
              openFileDialogOnClick={currentUser ? false : true}
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{OTHER_DOCUMENTS}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DocumentationSection);
