import React from 'react';
import { observer } from 'mobx-react';
import {
  CARRIER_CODE,
  CARRIER_COMPANY,
  CONTACT_NAME,
  CONTACT_PHONE,
  EMAIL,
  INSURANCE_BROKER,
  INSURANCE_CARGO_AMOUNT,
  INSURANCE_COMPANY_NAME,
  INSURANCE_EXPIRY_DATE,
  INSURANCE_LIABILITY_AMOUNT,
  INSURANCE_POLICY,
  MC_NUMBER,
  CARRIER_DETAILS,
  NOTES,
  NOT_AVAILABLE,
} from '../../../../../constants';
import { useMst } from '../../../../../state/RootModel';

const CarrierDetails = () => {
  const {
    carriersPageState: { viewedCarrier },
  } = useMst();

  return (
    <div className="w50">
      <div className="subsection-header align-center">
        <p>{CARRIER_DETAILS}</p>
      </div>
      <div className="flex-alignitems-flex-start">
        <div className="w30">
          <div className="shipment-detail mt20">
            <p>{CARRIER_COMPANY}</p>
            <p className="text-medium">{viewedCarrier.company_name}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{MC_NUMBER}</p>
            <p className="text-medium">{viewedCarrier.mc_number}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{CARRIER_CODE}</p>
            <p className="text-medium">{viewedCarrier.carrier_code}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{CONTACT_NAME}</p>
            <p className="text-medium">{viewedCarrier.contact_name}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{CONTACT_PHONE}</p>
            <p className="text-medium">{viewedCarrier.phone_number}</p>
          </div>
        </div>
        <div className="w30 ml5pc">
          <div className="shipment-detail mt20">
            <p>{EMAIL}</p>
            <p className="text-medium">{viewedCarrier.email}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{INSURANCE_COMPANY_NAME}</p>
            <p className="text-medium">{viewedCarrier.insurance_company}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{INSURANCE_BROKER}</p>
            <p className="text-medium">{viewedCarrier.insurance_broker}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{INSURANCE_POLICY}</p>
            <p className="text-medium">
              {viewedCarrier.insurance_policy_number}
            </p>
          </div>
          <div className="shipment-detail mt20">
            <p>{INSURANCE_EXPIRY_DATE}</p>
            <p className="text-medium">{viewedCarrier.insurance_expiry_date}</p>
          </div>
        </div>
        <div className="w30 ml5pc">
          <div className="shipment-detail mt20">
            <p>{INSURANCE_CARGO_AMOUNT}</p>
            <p className="text-medium">
              {viewedCarrier.insurance_cargo_amount}
            </p>
          </div>
          <div className="shipment-detail mt20">
            <p>{INSURANCE_LIABILITY_AMOUNT}</p>
            <p className="text-medium">
              {viewedCarrier.insurance_liability_amount}
            </p>
          </div>
          <div className="shipment-detail mt20">
            <p>{NOTES}</p>
            <p className="text-medium">
              {viewedCarrier.notes ? viewedCarrier.notes : NOT_AVAILABLE}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CarrierDetails);
