import { types } from 'mobx-state-tree';
import { DEPARTMENT_NAME_REQUIRED } from '../../../constants/organization/departments';
import {
  deleteDepartment,
  editDepartment,
} from '../../../actions/organization';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';
import { notification } from 'antd';

export const OrganizationDepartmentModel = types
  .model('OrganizationDepartmentModel', {
    department_id: types.identifierNumber,
    department_name: types.maybe(types.maybeNull(types.string)),
    number_of_people: types.maybe(types.maybeNull(types.number)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setDepartmentName(value) {
        self.department_name = value;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      clearForm() {
        this.setDepartmentName(null);
        this.setErrorText(null);
      },
      validateForm() {
        if (!self.department_name) {
          this.setErrorText(DEPARTMENT_NAME_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveEditedDepartment() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          editDepartment(self.department_id, self.department_name)
            .then((response) => {
              setFetchingInProgress(false);
              rootInstance.currentOrganization.getDepartmentsList();
              rootInstance.currentOrganization.setIsEditingDepartmentModalOpened(
                false
              );
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
      deleteDepartment(id) {
        setFetchingInProgress(true);
        deleteDepartment(id)
          .then((response) => {
            setFetchingInProgress(false);
            rootInstance.currentOrganization.setDepartmentToBeDeleted(null);
            rootInstance.currentOrganization.setIsDeleteDepartmentModalOpened(
              false
            );
            rootInstance.currentOrganization.getDepartmentsList();
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
    };
  });
