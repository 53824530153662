import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import {
  ACTION,
  CURRENT_LOCATION,
  MILES,
  NOT_AVAILABLE_SHORT,
  NOTES,
  SEE_DETAILS,
  START_DATE_TIME,
  STATUS,
  TARGET_LOCATION,
  TRAILER,
  TRUCK,
} from '../../../constants';
import moment from 'moment';
import { eta_format, toAmount } from '../../../utils/methods';
import blackTruck from '../../../assets/images/black-truck.svg';
import backArrow from '../../../assets/images/dispatch/black-back-arrow.svg';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';
import {
  BREAK,
  CYCLE,
  DEF,
  DRIVE,
  FUEL,
  REMAINING,
  SHIFT,
  SPEED,
  UPCOMING,
} from '../../../constants/dispatch';

const VehicleRouteActions = (props) => {
  const {
    dispatchPageState: { selectedVehicleTypeForRouteActions },
  } = useMst();

  const [isExpanded, setIsExpanded] = useState(false);
  const { data } = props;

  const onSetExpanded = () => {
    setIsExpanded(true);
  };
  const onSetCollapsed = () => {
    setIsExpanded(false);
  };
  return (
    <div className="dispatch-map-organize-tour-list__item">
      {!isExpanded && (
        <div className="trip-tour ph20 pv20">
          <div className="align-center mb20">
            <p className="w100 text-black align-center">
              <img src={blackTruck} className="mr10" alt="black truck" />
              {selectedVehicleTypeForRouteActions === TRAILER && (
                <span className="text-black text-bold">
                  {TRAILER}: {data?.trailer?.trailer_company_id}
                </span>
              )}
              {selectedVehicleTypeForRouteActions === TRUCK && (
                <span className="text-black text-bold">
                  {TRUCK}: {data?.truck?.truck_company_id}
                </span>
              )}
            </p>
          </div>
          <div className="ml30">
            {selectedVehicleTypeForRouteActions === TRUCK && (
              <p className="text-black">
                <span className="text-medium">{TRAILER}:</span>{' '}
                {data.trailer.trailer_company_id +
                  ' - ' +
                  data.trailer.trailer_type}
              </p>
            )}

            <p className="text-black">
              <span className="text-medium">{START_DATE_TIME}:</span>{' '}
              {moment(data.planned_start).format('DD.MM.YYYY HH:mm:ss')}
            </p>
            <p className="text-black">
              <span className="text-medium">{MILES}:</span>{' '}
              {toAmount(data.miles)}mi
            </p>
            {/*<p className="text-black"><span className="text-medium">{ETA}:</span> {eta_format(data.eta)}</p>*/}
          </div>
          <p className="text-black mt20 ml30">
            <span className="text-black text-bold">
              {data.driver.driver_code_name}
            </span>
            <span className="ml5 text-blue text-capitalize text-bold">
              {data.driver.driver_type === COMPANY_DRIVER
                ? 'CD'
                : data.driver.driver_type === OWNER_OPERATOR
                ? 'OO'
                : NOT_AVAILABLE_SHORT}
            </span>
            <span
              className={`ml5 text-capitalize 
                        ${
                          data.driver.driver_availability === 'Driving'
                            ? 'text-orange'
                            : 'text-green'
                        }`}
              style={{ fontSize: 12 }}
            >
              ({data.driver.driver_availability})
            </span>
          </p>
          <div className="align-center justify-between mt10 ml30">
            <p className="text-capitalize go-to" onClick={onSetExpanded}>
              {SEE_DETAILS}
            </p>
          </div>
        </div>
      )}
      {isExpanded && (
        <div>
          <div className="trip-driver flex-start pv20 ph20">
            <img
              onClick={onSetCollapsed}
              style={{ cursor: 'pointer', width: '20px' }}
              src={backArrow}
              alt=""
              className="mr10"
            />
            <div className="trip-driver-details" style={{ flex: 1 }}>
              <div className="w100 text-black trip-driver-details-name mb10">
                <span className="text-black text-bold">
                  {data.driver.driver_code_name}
                </span>
                <span className="ml5 text-blue text-capitalize text-bold">
                  {data.driver.driver_type === COMPANY_DRIVER
                    ? 'CD'
                    : data.driver.driver_type === OWNER_OPERATOR
                    ? 'OO'
                    : NOT_AVAILABLE_SHORT}
                </span>
                <span
                  className={`ml5 text-capitalize ${
                    data.driver.driver_availability === 'Driving'
                      ? 'text-orange'
                      : 'text-green'
                  }`}
                  style={{ fontSize: 12 }}
                >
                  ({data.driver.driver_availability})
                </span>
              </div>
              <div className="w100 text-bold">
                <span className="text-uppercase">Details for truck:</span>
                <p>
                  {data.truck.truck_company_id} - {data.truck.truck_model}
                </p>
              </div>
              <div className="align-center mt15">
                <div>
                  <p className="text-bold text-uppercase mr20">{FUEL}</p>
                  <span className="text-black">
                    {data?.truck?.fuel >= 0
                      ? data?.truck?.fuel > 0 || data?.truck?.fuel === 0
                        ? data?.truck?.fuel
                        : NOT_AVAILABLE_SHORT
                      : NOT_AVAILABLE_SHORT}
                  </span>
                </div>
                <div>
                  <p className="text-bold text-uppercase mr20">{SPEED}</p>
                  <span className="text-black">
                    {data?.truck?.speed >= 0
                      ? data.truck.speed > 0 || data.truck.speed === 0
                        ? data.truck.speed + data.truck.speed_unit
                        : NOT_AVAILABLE_SHORT
                      : NOT_AVAILABLE_SHORT}
                  </span>
                </div>
                <div>
                  <p className="text-bold text-uppercase mr20">{DEF}</p>
                  <span className="text-black">
                    {data?.def ? data.def : NOT_AVAILABLE_SHORT}
                  </span>
                </div>
              </div>
              <div className="align-center mt15">
                <div className="has-border-right mr20 pr10">
                  <p className="mb10">{UPCOMING}</p>
                  <p className="text-bold text-uppercase mr20">{BREAK}</p>
                  <span className="text-black">
                    {data?.driver?.driver_hos?.break
                      ? data.driver.driver_hos.break
                      : NOT_AVAILABLE_SHORT}
                  </span>
                </div>
                <div>
                  <p className="mb10">{REMAINING}</p>
                  <div className="align-center">
                    <div>
                      <p className="text-bold text-uppercase mr20">{DRIVE}</p>
                      <span className="text-black">
                        {data?.driver?.driver_hos?.drive
                          ? data.driver.driver_hos.drive
                          : NOT_AVAILABLE_SHORT}
                      </span>
                    </div>
                    <div>
                      <p className="text-bold text-uppercase mr20">{SHIFT}</p>
                      <span className="text-black">
                        {data?.driver?.driver_hos?.shift
                          ? data.driver.driver_hos.shift
                          : NOT_AVAILABLE_SHORT}
                      </span>
                    </div>
                    <div>
                      <p className="text-bold text-uppercase mr20">{CYCLE}</p>
                      <span className="text-black">
                        {data?.driver?.driver_hos?.cycle
                          ? data.driver.driver_hos.cycle
                          : NOT_AVAILABLE_SHORT}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="trip-tour ph20 pv20">
            <div className="ml25">
              <div className="align-center mb20">
                <p className="w100 text-black">
                  <span
                    className="text-black text-bold"
                    style={{ fontSize: '18px' }}
                  >
                    {ACTION + ':'} {data?.action.action_name}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-black">
                  <span className="text-medium">{TRAILER}:</span>{' '}
                  {data.trailer.trailer_company_id +
                    ' - ' +
                    data.trailer.trailer_type}
                </p>
                <p className="text-black">
                  <span className="text-medium">{START_DATE_TIME}:</span>{' '}
                  {moment(data.planned_start).format('DD.MM.YYYY HH:mm:ss')}
                </p>
                <p className="text-black">
                  <span className="text-medium">{STATUS}:</span> {data.status}
                </p>
              </div>
              <div className="w100 text-bold mt20">
                <span className="text-uppercase">{CURRENT_LOCATION}</span>
                <p className="text-black">{data.truck.current_location}</p>
                <span className="text-uppercase mt20 flex">
                  {TARGET_LOCATION}
                </span>
                <p className="text-black">{data.location.address}</p>
              </div>
              <div className="w100 mt20">
                <p>
                  <span
                    className="text-green"
                    style={{ fontSize: '20px', lineHeight: '22px' }}
                  >
                    {eta_format(data.eta)}
                  </span>
                  <span className="text-black ml10">
                    ({toAmount(data.miles)} {MILES})
                  </span>
                </p>
              </div>
              {data.notes && (
                <div className="w100 text-bold mt20">
                  <span className="text-uppercase">{NOTES}</span>
                  <p className="text-black">{data.notes}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(VehicleRouteActions);
