import { types } from 'mobx-state-tree';
import { FunctionModel } from '../FunctionModel';
import { rootInstance } from '../RootModel';

export const SideMenuItemModel = types
  .model('SideMenuItemModel', {
    id: types.identifier,
    title: types.string,
    icon: types.string,
    activeIcon: types.maybe(types.string),
    route: types.maybeNull(types.string),
    onClick: types.maybe(FunctionModel),
    active: false,
    descriptionOpen: false,
    searchPlaceholder: types.maybe(types.string),
    isAllowedToSee: false,
    limitedTo: types.maybe(types.array(types.string)),
  })
  .actions((self) => {
    return {
      setIsAllowedToSee(value) {
        self.isAllowedToSee = value;
      },
      setDescriptionOpen(status) {
        rootInstance.sideMenuState.menuItems.forEach((item) => {
          item.closeDescription();
        });
        if (status) {
          self.descriptionOpen = status;
        }
      },
      closeDescription() {
        self.descriptionOpen = false;
      },
      setActive(status) {
        rootInstance.sideMenuState.menuItems.forEach((item) => {
          item.active = false;
        });
        if (status) {
          self.active = true;
        }
      },
    };
  });
