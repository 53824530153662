import { getRoot, types } from 'mobx-state-tree';
import trucks from '../../assets/images/dispatch/filters/trucks.svg';
import trailers from '../../assets/images/dispatch/filters/trailers.svg';
import tolls from '../../assets/images/dispatch/filters/tolls.svg';
import gasStations from '../../assets/images/dispatch/filters/gas-stations.svg';
import restPlaces from '../../assets/images/dispatch/filters/rest-places.svg';
import restaurants from '../../assets/images/dispatch/filters/restaurants.svg';
import nextEvent from '../../assets/images/dispatch/next-event.svg';
import pinnedEvent from '../../assets/images/dispatch/pinned-event.svg';
import pickupEvent from '../../assets/images/dispatch/pickup-event.svg';
import deliveryEvent from '../../assets/images/dispatch/delivery-event.svg';

import {
  getErrorText,
  removeDuplicatesFromArray,
  setFetchingInProgress,
} from '../../utils/methods';
import moment from 'moment';
import {
  getALlRouteActionsPromise,
  getRouteActionAllShipmentsPromise,
  getRouteActionDetailsPromise,
  getRouteActionDriversPromise,
  getRouteActions,
  getRouteActionTrailersPromise,
  getRouteActionTrucksPromise,
  getShipmentDetails,
  getShipmentRouteActionsAdditionalPromise,
  getShipmentRouteActionsPromise,
  getTrackingShipmentDetailsPromise,
} from '../../promises/dispatch';
import { DispatchTripLightModel } from './routeAction/DispatchTripLightModel';
import { getLocations } from '../../actions/locations';
import { DispatchTripModel } from './routeAction/DispatchTripModel';
import {
  deleteShipmentRouteActions,
  drawRoute,
  getALlRouteActions,
  getALlRouteActionStatuses,
  getAssets,
  getDriverFilterOptions,
  getLocationsByShipmentId,
  getShipmentRouteActions,
  getPlannedShipments,
  getRouteActionDrivers,
  getRouteActionShipments,
  getRouteActionTrailers,
  getRouteActionTrucks,
  getVehicleRouteActions,
  getRouteActionsList,
  getTrackingShipmentData,
} from '../../actions/dispatch';
import { OrganizeTourShipmentsModalColumns } from './shipments/OrganizeTourShipmentsModalColumns';
import { DispatchShipmentModel } from './shipments/DispatchShipmentModel';
import { ShipmentsSidebarTableColumns } from './shipments/ShipmentsSidebarTableColumns';
import { getShipment } from '../../actions/shipments';
import { CustomersTableColumns } from './customers/CustomersTableColumns';
import { DispatchAvailableDriversTableColumns } from './drivers/DispatchAvailableDriversTableColumns';
import { DispatchAvailableTeamDriversTableColumns } from './drivers/DispatchAvailableTeamDriversTableColumns';
import { DispatchAvailableTrailersTableColumns } from './trailers/DispatchAvailableTrailersTableColumns';
import { ShipmentsDetailsTableColumns } from './shipments/ShipmentsDetailsTableColumns';
import { LocationModel } from './location/LocationModel';
import { rootInstance } from '../RootModel';
import { DispatchAvailableTrucksTableColumns } from './trucks/DispatchAvailableTrucksTableColumns';
import { parseInt } from 'lodash';
import { AssetsListTrucksTableColumns } from './trucks/AssetsListTrucksTableColumns';
import { AssetsListTrailersTableColumns } from './trailers/AssetsListTrailersTableColumns';
import { AssetsListDriversTableColumns } from './drivers/AssetsListDriversTableColumns';
import { PlannerTableColumns } from './planner/PlannerTableColumns';
import { PlannerProbillsTableColumns } from './planner/PlannerProbillsTableColumns';
import { notification } from 'antd';
import {
  FETCH_CUSTOMERS_ERROR,
  FETCH_DRIVERS_ERROR,
  FETCH_PLANNER_SHIPMENTS_ERROR,
  FETCH_TRAILERS_ERROR,
  FETCH_TRUCKS_ERROR,
  NOT_AVAILABLE,
  TRUCKS,
} from '../../constants';
import { getClients } from '../../actions/clients';
import { RouteActionsListTableColumns } from './routeActionList/RouteActionsListTableColumns';
import { RouteActionListModel } from './routeActionList/RouteActionListModel';
import { PDShipmentModel } from './shipments/PDShipmentModel';
import { ERROR_GETTING_SHIPMENT_DATA } from '../../constants/dispatch/errors';

const allSettled = require('promise.allsettled');

export const DispatchPageModel = types
  .model('DispatchPageModel', {
    /** Header Time EDT/GMT **/
    currentTimeEDT: '',
    currentTimeGMT: '',

    /** Selected shipment **/
    selectedShipment: types.maybe(types.maybeNull(types.number)),
    selectedShipmentDetails: types.frozen([]),
    selectedShipmentPickupLocations: types.map(LocationModel),
    selectedShipmentDeliveryLocations: types.map(LocationModel),
    selectedShipmentToDraw: types.frozen([]),

    /** Shipment Sidebar **/
    shipmentsListStatus: false,
    shipmentsList: types.map(DispatchShipmentModel),
    shipmentsListSearch: '',

    /** Sidebars **/
    isShipmentsSidebarShown: false,

    /** Tour locations **/
    showTourDetailsStatus: false,

    /** Organize Tour **/
    isOrganizeTourOpened: false,
    isOrganizeTourLoaded: false,
    isAllShipmentActionsOpened: false,
    shouldShipmentActionsHaveScroll: false,

    organizeTourShipments: types.map(DispatchShipmentModel),
    organizeTourCustomers: types.frozen([]),
    organizeTourDrivers: types.frozen([]),
    organizeTourTrucks: types.frozen([]),
    organizeTourTrailers: types.frozen([]),
    allOrganizeTourActions: types.frozen([]),

    isOrganizeTourShipmentsModalOpen: false,
    organizeTourShipmentsModalSearch: '',

    isOrganizeTourSuccessModalOpen: false,

    /** Customers **/
    isOrganizeTourCustomersModalOpen: false,
    customersListSearch: '',

    /** Drivers **/
    selectedDriverForMap: types.frozen([]),
    isOrganizeTourDriversModalOpen: false,
    driverModalSearch: '',
    driverModalLocationSearch: types.maybe(types.maybeNull(types.number)),
    driverFilterOptions: types.frozen([]),
    driverFilterOptionSearch: 'All',
    driverOgTotal: types.maybe(types.maybeNull(types.number)),
    driverOgPerPage: types.maybe(types.maybeNull(types.number)),
    driverOgPage: 1,
    allDriversLoaded: false,

    /** Team Drivers **/
    isOrganizeTourTeamDriversModalOpen: false,

    /** Trailers **/
    isOrganizeTourTrailersModalOpen: false,
    trailerModalSearch: '',
    selectedTrailerForMap: types.frozen([]),
    isSelectedTrailerChanged: false,
    trailerModalLocationSearch: types.maybe(types.maybeNull(types.number)),
    trailerModalShipmentIdSearch: types.maybe(types.maybeNull(types.number)),
    trailerOgTotal: types.maybe(types.maybeNull(types.number)),
    trailerOgPerPage: types.maybe(types.maybeNull(types.number)),
    trailerOgPage: 1,
    trailerFilterOptionSearch: 'All',
    allTrailersLoaded: false,

    /** Trucks **/
    isOrganizeTourTrucksModalOpen: false,
    trucksModalSearch: '',
    truckOgTotal: types.maybe(types.maybeNull(types.number)),
    truckOgPerPage: types.maybe(types.maybeNull(types.number)),
    truckOgLimit: types.maybe(types.maybeNull(types.number)),
    truckOgPage: 1,
    allTrucksLoaded: false,

    /** Route Actions **/
    routeActionItem: types.maybe(types.maybeNull(DispatchTripLightModel)),
    routeActionItems: types.map(DispatchTripModel),
    editedRouteActionItem: types.maybeNull(DispatchTripModel),

    /** Remove Route Action **/
    removeRouteActionModalOpen: false,
    routeActionToBeDeleted: types.maybe(types.maybeNull(types.number)),

    /** Statuses **/
    availableStatuses: types.frozen([]),

    /** Assets list **/
    isAssetsListOpened: false,
    assetsSelectedValue: TRUCKS,
    assetsListData: types.frozen([]),
    assetsDataFilter: '',
    assetsListTotal: types.maybe(types.maybeNull(types.number)),
    assetCurrentPage: 1,
    allAssetsLoaded: false,

    /** Locations **/
    availableLocations: types.frozen([]),

    /** Data to show on map **/
    truckToShowOnMap: types.frozen([]),
    trailerToShowOnMap: types.frozen([]),

    /** Planner **/
    isPlannerOpened: false,
    plannedShipments: types.frozen([]),

    /** Route action to draw **/
    routeActionToDraw: types.frozen([]),

    /** Zoom **/
    zoom: types.maybe(types.maybeNull(types.number)),
    isEditingRouteActionChanged: false,
    truckEditingToShowOnMap: types.frozen([]),
    trailerEditingToShowOnMap: types.frozen([]),
    shipmentToChangePD: types.maybeNull(PDShipmentModel),
    isShipmentPDSuccessModalOpened: false,
    selectedVehicleRouteActions: types.frozen([]),
    selectedVehicleForRouteActions: types.frozen([]),
    selectedVehicleTypeForRouteActions: '',

    /** Route Actions List **/
    isRouteActionListOpened: false,
    routeActionsList: types.map(RouteActionListModel),

    /** Tracking shipment **/
    trackingShipment: types.frozen([]),
    isTrackingShipmentDetailsOpened: false,
    isTrackingShipmentCurrentInformationOpened: false,

    locationsSearch: '',
  })
  .volatile((self) => {
    return {
      filterOptions: [
        // {label: 'Show Drivers', value: 'drivers', img: drivers},
        { label: 'Show Trucks', value: 'trucks', img: trucks },
        { label: 'Show Trailers', value: 'trailers', img: trailers },
        { label: 'Show Tolls', value: 'tolls', img: tolls },
        { label: 'Show Gas Stations', value: 'gas-stations', img: gasStations },
        { label: 'Show Rest Places', value: 'rest-places', img: restPlaces },
        { label: 'Show Restaurants', value: 'restaurants', img: restaurants },
      ],
      legendStatus: [
        { name: 'Available', class: 'legend-circle legend-green' },
        { name: 'In-Transit', class: 'legend-circle legend-orange' },
        { name: 'Out of Service', class: 'legend-circle legend-red' },
        { name: 'Not Available', class: 'legend-circle legend-gray' },
      ],
      routeStatus: [
        { name: 'Active Route', class: 'legend-line legend-blue' },
        { name: 'Inactive Route', class: 'legend-line legend-lightgray' },
        { name: 'Empty Miles', class: 'legend-line legend-lightorange' },
        { name: 'Heavy Trafic', class: 'legend-line legend-red' },
      ],
      filterEvents: [
        { name: 'Next Event', class: nextEvent },
        { name: 'Pinned Event', class: pinnedEvent },
        { name: 'Pickup Event', class: pickupEvent },
        { name: 'Delivery Event', class: deliveryEvent },
      ],
      assetsOptions: [
        { label: 'Trucks', value: 'Trucks' },
        { label: 'Trailers', value: 'Trailers' },
        { label: 'Drivers', value: 'Drivers' },
      ],
    };
  })
  .views((self) => {
    return {
      get getSelectedShipmentDetails() {
        return self.selectedShipmentDetails;
      },
      get getEditingRouteAction() {
        return self.editedRouteActionItem;
      },
      get getAllAvailableLocations() {
        return Array.from(self.availableLocations.values());
      },
      get getAllFilteredAvailableLocations() {
        let search = self.locationsSearch;
        return this.getAllAvailableLocations.filter((item) => {
          return item.location_name.toLowerCase().includes(search);
        });
      },
      get getAvailableAssetOptions() {
        return Array.from(self.assetsOptions.values());
      },
      get getAssetsListData() {
        return Array.from(self.assetsListData.values());
      },
      get getFilteredAssetsListData() {
        return removeDuplicatesFromArray(
          this.getAssetsListData,
          'internal_company_id'
        );
      },
      get getAvailableStatuses() {
        return Array.from(self.availableStatuses.values());
      },
      get getOrganizeTourShipments() {
        return Array.from(self.organizeTourShipments.values());
      },
      get getFilteredAvailableShipments() {
        let search = self.organizeTourShipmentsModalSearch;
        return this.getOrganizeTourShipments.filter((shipment) => {
          return (
            shipment.shipment_number.includes(search) ||
            shipment.shipment_id === parseInt(search)
          );
        });
      },
      get getOrganizeTourCustomers() {
        return Array.from(self.organizeTourCustomers.values());
      },
      get getFilteredAvailableCustomers() {
        let search = self.customersListSearch;
        return this.getOrganizeTourCustomers.filter((shipment) => {
          return (
            shipment.company.includes(search) ||
            shipment.client_id === parseInt(search)
          );
        });
      },
      get getAvailableDriverFilterOptions() {
        return Array.from(self.driverFilterOptions.values());
      },
      get getOrganizeTourDrivers() {
        return Array.from(self.organizeTourDrivers.values());
      },
      get getOrganizeTourTrailers() {
        return Array.from(self.organizeTourTrailers.values());
      },
      get getOrganizeTourTrucks() {
        return Array.from(self.organizeTourTrucks.values());
      },
      get getOrganizeTourAllActions() {
        return Array.from(self.allOrganizeTourActions.values());
      },
      get getSavedRouteTripItems() {
        return Array.from(self.routeActionItems.values());
      },
      get getNewRouteTripItem() {
        return self.routeActionItem;
      },
      get getAvailableShipments() {
        return Array.from(self.shipmentsList.values());
      },
      get getSidebarFilteredAvailableShipments() {
        let search = self.shipmentsListSearch;
        return this.getAvailableShipments.filter((shipment) => {
          return (
            shipment.shipment_number.includes(search) ||
            shipment.shipment_id === parseInt(search)
          );
        });
      },
      get shipmentPickupLocations() {
        return Array.from(self.selectedShipmentPickupLocations.values());
      },
      get shipmentDeliveryLocations() {
        return Array.from(self.selectedShipmentDeliveryLocations.values());
      },
      get getRouteActionListDataValues() {
        return Array.from(self.routeActionsList.values());
      },
    };
  })
  .actions((self) => {
    return {
      setIsTrackingShipmentCurrentInformationOpened(value) {
        self.isTrackingShipmentCurrentInformationOpened = value;
      },
      setLocationsSearchText(value) {
        self.locationsSearch = value;
      },
      setShouldShipmentActionsHaveScroll(value) {
        self.shouldShipmentActionsHaveScroll = value;
      },
      setIsShipmentPDSuccessModalOpened(value) {
        self.isShipmentPDSuccessModalOpened = value;
      },
      setIsTrackingShipmentDetailsOpened(value) {
        self.isTrackingShipmentDetailsOpened = value;
      },
      clearTrackingShipment() {
        self.trackingShipment = [];
      },
      loadSelectedShipmentData(shipment_id) {
        this.clearSavedRouteActionItems();
        setFetchingInProgress(true);
        let promises = [
          getTrackingShipmentDetailsPromise(self, shipment_id),
          getShipmentRouteActionsAdditionalPromise(self, shipment_id),
        ];
        allSettled(promises)
          .then(() => {
            this.setPlannerOpened(false);
            setFetchingInProgress(false);

            // this.setIsAllShipmentActionsOpened(true);
            this.setPlannerOpened(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error fetching data for tacking shipment!',
              description: errorText,
            });
          });
      },
      getTrackingShipmentDetails(shipment) {
        setFetchingInProgress(true);
        getTrackingShipmentData(shipment.shipment_id)
          .then((response) => {
            this.setTrackingShipment(response.data);
            this.setPlannerOpened(false);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_GETTING_SHIPMENT_DATA,
              description: errorText,
            });
          });
      },
      setTrackingShipment(tracking_data) {
        if (tracking_data) {
          self.trackingShipment = [tracking_data];
        } else {
          self.trackingShipment = [];
        }
      },
      setIsRouteActionListOpened(value) {
        self.isRouteActionListOpened = value;
        this.beforeExitClearSelectedShipmentId();
        if (value) {
          this.loadAvailableAssets(self.assetsSelectedValue);
          this.getRouteActionListData();
        }
      },
      setRouteActionListData(route_actions) {
        self.routeActionsList.clear();
        route_actions.forEach((item) => {
          self.routeActionsList.put({
            ...item,
          });
        });
      },
      getRouteActionListData() {
        setFetchingInProgress(true);
        getRouteActionsList()
          .then((response) => {
            response.data.forEach((item) => {
              item.trailer =
                item?.trailer?.trailer_id === 'N/A' ? null : item.trailer;
              item.truck = item?.truck?.truck_id === 'N/A' ? null : item.truck;
            });
            this.setRouteActionListData(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error fetching route actions!',
              description: errorText,
            });
          });
      },
      setSelectedDriverForMap(driver_details) {
        self.selectedDriverForMap = driver_details;
      },
      setShipmentToChangePD(shipment) {
        self.shipmentToChangePD = shipment;
      },
      assignSetEditingTrailerToShowOnMap(trailer) {
        self.trailerEditingToShowOnMap = trailer;
      },
      setEditingTrailerToShowOnMap(trailer) {
        getRouteActionTrailers(1, '', trailer)
          .then((response) => {
            this.assignSetEditingTrailerToShowOnMap(response.data.trailers);
          })
          .catch((error) => {
            console.log('Error: ', error);
          });
      },
      setEditingTruckToShowOnMap(truck) {
        this.beforeExitClearSelectedShipmentId();
        if (truck) {
          self.truckEditingToShowOnMap = [truck];
        } else {
          self.truckEditingToShowOnMap = null;
        }
      },
      setIsEditingRouteActionChanged(value) {
        self.isEditingRouteActionChanged = value;
      },
      setAssetsSearchFilter(value) {
        self.assetsDataFilter = value;
        self.assetCurrentPage = 1;
        this.loadAvailableAssets();
      },
      setIsAllShipmentActionsOpened(value) {
        self.isAllShipmentActionsOpened = value;
      },
      getRouteActionToDraw(action) {
        if (action?.locations?.length > 0) {
          setFetchingInProgress(true);
          const data = {
            departure_position: [
              action?.locations[0]?.longitude,
              action?.locations[0]?.latitude,
            ],
            destination_position: [
              action?.locations[action?.locations.length - 1].longitude,
              action?.locations[action?.locations.length - 1].latitude,
            ],
          };
          drawRoute(data)
            .then((response) => {
              let res_data = response.data[0];
              res_data.color = action?.color;
              res_data.action = action;
              this.setRouteActionToDraw(res_data);
              setFetchingInProgress(false);
            })
            .catch((error) => {
              console.log('Error: ', error);
              setFetchingInProgress(false);
            });
        } else {
          notification.error({
            message: 'Error drawing route!',
            description: 'Cannot draw route due to missing locations.',
          });
        }
      },
      setRouteActionToDraw(action) {
        self.routeActionToDraw = [action];
      },
      clearRouteActionItemToDraw() {
        self.routeActionToDraw = [];
      },
      /** Organize Tour **/
      setIsOrganizeTourSuccessModalOpen(value) {
        self.isOrganizeTourSuccessModalOpen = value;
      },
      /** Planner **/
      setPlannerOpened(value) {
        self.isPlannerOpened = value;
        if (value) {
          this.loadPlannedShipments();
        }
      },
      /** Trucks **/
      setTruckOgPerPage(page) {
        self.truckOgPerPage = page;
      },
      setTruckOgTotal(total) {
        self.truckOgTotal = total;
      },
      setTruckOgPage(page) {
        self.truckOgPage = page;
        this.loadRouteActionTrucks();
      },
      resetTruckOgPage() {
        self.truckOgPage = 1;
      },
      setIsOrganizeTourTrucksModalOpen(value) {
        self.isOrganizeTourTrucksModalOpen = value;
      },
      setTrucksModalSearch(value) {
        self.trucksModalSearch = value;
        this.setAllTrucksLoaded(false);
        this.setTruckItemsLimit(10);
        this.resetOgTrucksData();
        setTimeout(() => {
          this.loadRouteActionTrucks(self.truckOgLimit);
        }, 200);
      },
      setTruckItemsLimit(limit) {
        self.truckOgLimit = limit;
      },
      setTruckToShowOnMap(truck) {
        this.setSelectedDriverForMap([]);
        if (truck) {
          self.truckToShowOnMap = [truck];
        } else {
          self.truckToShowOnMap = [];
        }
      },
      setTruckForTracking(truck) {
        this.setSelectedDriverForMap([]);
        self.truckToShowOnMap = [truck];
      },
      /** Assets **/
      getSelectedVehicleRouteActions(vehicle, type) {
        this.setSelectedDriverForMap([]);
        setFetchingInProgress(true);
        this.setSelectedVehicleTypeForRouteActions(type);
        this.setSelectedVehicleForRouteActions(vehicle);
        getVehicleRouteActions(vehicle.vehicle_id)
          .then((response) => {
            this.setSelectedVehicleRouteActions(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(false);
          });
      },
      setSelectedVehicleForRouteActions(vehicle) {
        self.selectedVehicleForRouteActions = vehicle;
      },
      setSelectedVehicleTypeForRouteActions(type) {
        self.selectedVehicleTypeForRouteActions = type;
      },
      setSelectedVehicleRouteActions(vehicle_actions) {
        self.selectedVehicleRouteActions = vehicle_actions;
      },
      setIsAssetsListOpened(value) {
        self.isAssetsListOpened = value;
        if (value) {
          this.loadAvailableAssets(self.assetsSelectedValue);
        } else {
          self.assetsListData = [];
        }
      },
      setAssetsSelectedValue(value) {
        this.setAllAssetsLoaded(false);
        self.assetsSelectedValue = value;
        this.loadAvailableAssets();
        this.setAssetsSearchFilter('');
      },
      setAssetsListData(data) {
        self.assetsListData = data;
      },
      setAssetsListTotal(number) {
        self.assetsListTotal = number;
      },
      setAssetCurrentPage(page) {
        self.assetCurrentPage = page;
      },
      setAllAssetsLoaded(value) {
        self.allAssetsLoaded = value;
      },
      clearAssetsData() {
        self.assetsListData = [];
      },
      resetAssetsTotal() {
        self.assetsListTotal = 0;
      },
      loadAvailableAssets() {
        const selectedValue = self.assetsSelectedValue;
        setFetchingInProgress(true);
        getAssets(
          selectedValue,
          self.assetCurrentPage,
          undefined,
          self.assetsDataFilter
        )
          .then((response) => {
            this.setAssetsListData(
              self.assetCurrentPage > 1
                ? self.assetsListData.concat(response.data.assets)
                : response.data.assets
            );
            this.setAssetsListTotal(response.data.total);
            this.setAssetCurrentPage(self.assetCurrentPage);
            setFetchingInProgress(false);
            setTimeout(() => {
              if (self.assetsListData.length >= self.assetsListTotal) {
                this.setAllAssetsLoaded(true);
              }
            }, 100);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error fetching shipment route actions!',
              description: errorText,
            });
          });
      },
      /** Drivers **/
      resetOgDriverData() {
        self.driverOgPage = 1;
        self.driverOgTotal = 0;
        self.organizeTourDrivers = [];
      },
      setDriverOgPerPage(page) {
        self.driverOgPerPage = page;
      },
      setDriverOgTotal(total) {
        self.driverOgTotal = total;
      },
      setDriverOgPage(page) {
        self.driverOgPage = page;
        setFetchingInProgress(true);
        this.changeAvailableDrivers();
      },
      setDriverToShowOnMap(truck) {
        if (truck?.linked_truck_data) {
          self.truckToShowOnMap = [truck.linked_truck_data];
        }
      },
      setDriverModalSearch(search) {
        self.driverModalSearch = search;
        this.searchDrivers();
        // this.changeAvailableDrivers();
      },
      clearAvailableDrivers() {
        this.setOrganizeTourDrivers([]);
      },
      setDriverModalLocationSearch(location_id) {
        if (location_id) {
          self.driverModalLocationSearch = parseInt(location_id);
          // Calculate filter options
          this.clearAvailableDrivers();
          this.filterAvailableDrivers();
          this.loadAvailableDriverFilters();
        } else {
          this.setLocationsSearchText('');
          this.clearAvailableDrivers();
          self.driverModalLocationSearch = null;
          this.filterAvailableDrivers();
          this.loadAvailableDriverFilters();
        }
      },
      setAllDriversLoaded(value) {
        self.allDriversLoaded = value;
      },
      clearAllDriversData() {
        self.organizeTourDrivers = [];
      },
      filterAvailableDrivers() {
        setFetchingInProgress(true);
        getRouteActionDrivers(
          self.driverFilterOptionSearch,
          self.driverOgPage,
          self.driverModalLocationSearch,
          self.driverModalSearch
        )
          .then((response) => {
            if (response.data.drivers?.length > 0) {
              this.setOrganizeTourDrivers(response.data.drivers);
              this.setDriverOgTotal(response.data.max);
              if (self.organizeTourDrivers.length >= self.driverOgTotal) {
                this.setAllDriversLoaded(true);
              }
              this.loadRouteActionTrucks();
            } else {
              this.clearAvailableDrivers();
            }
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
          });
      },
      searchDrivers() {
        this.clearAvailableDrivers();
        setFetchingInProgress(true);
        getRouteActionDrivers(
          self.driverFilterOptionSearch,
          1,
          self.driverModalLocationSearch,
          self.driverModalSearch
        )
          .then((response) => {
            this.setOrganizeTourDrivers(response.data.drivers);
            this.setDriverOgTotal(response.data.max);
            this.loadRouteActionTrucks();
            // setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
          });
      },
      changeAvailableDrivers() {
        setFetchingInProgress(true);
        getRouteActionDrivers(
          self.driverFilterOptionSearch,
          self.driverOgPage,
          self.driverModalLocationSearch,
          self.driverModalSearch
        )
          .then((response) => {
            if (response.data.drivers?.length > 0) {
              this.setOrganizeTourDrivers(
                self.organizeTourDrivers.concat(response.data.drivers)
              );
              this.setDriverOgTotal(response.data.max);
              if (self.organizeTourDrivers.length >= self.driverOgTotal) {
                this.setAllDriversLoaded(true);
              }
              this.loadRouteActionTrucks();
            } else {
              this.clearAvailableDrivers();
            }
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
          });
      },
      /** Customers **/
      setCustomerListSearch(value) {
        self.customersListSearch = value;
      },
      /** Trailers **/
      resetOgTrailerData() {
        self.trailerOgPerPage = 1;
        self.trailerOgTotal = 0;
        self.organizeTourTrailers = [];
      },
      setTrailerOgPerPage(page) {
        self.trailerOgPerPage = page;
      },
      setTrailerOgTotal(total) {
        self.trailerOgTotal = total;
      },
      setAllTrailersLoaded(value) {
        self.allTrailersLoaded = value;
      },
      setTrailerOgPage(page) {
        self.trailerOgPage = page;
        this.loadRouteActionTrailers();
      },
      assignSetTrailerToShowOnMap(trailer) {
        self.trailerToShowOnMap = trailer;
      },
      setTrailerToShowOnMap(trailer) {
        getRouteActionTrailers(1, '', trailer)
          .then((response) => {
            this.assignSetTrailerToShowOnMap(response.data.trailers);
          })
          .catch((error) => {
            console.log('#rror: ', error);
          });
      },
      setTrailerModalSearch(search) {
        self.trailerModalSearch = search;
        this.loadRouteActionTrailers();
      },
      setTrailerForTracking(trailer) {
        trailer.trailer_current_action = {
          location_latitude: trailer.vehicle_latitude,
          location_longitude: trailer.vehicle_longitude,
        };
        if (trailer?.vehicle_latitude && trailer?.vehicle_longitude) {
          self.trailerToShowOnMap = [trailer];
        }
      },
      setSelectedTrailerForMap(trailer) {
        self.selectedTrailerForMap = trailer;
        this.setIsSelectedTrailerChanged(true);
      },
      setTrailerModalShipmentIdSearch(shipment_id) {
        self.trailerModalShipmentIdSearch = shipment_id;
        this.changeOrganizeTourTrailers();
      },
      setTrailerModalLocationSearch(location) {
        self.trailerModalLocationSearch = location;
        self.organizeTourTrailers = [];
        this.loadAvailableTrailerFilters();
        this.changeOrganizeTourTrailers();
      },
      setIsSelectedTrailerChanged(value) {
        self.isSelectedTrailerChanged = value;
      },
      changeOrganizeTourTrailers() {
        setFetchingInProgress(true);
        this.loadRouteActionTrailers();
      },
      /** Removing Route Action **/
      setRemoveRouteActionModalOpen(value) {
        self.removeRouteActionModalOpen = value;
      },
      setRouteActionToBeDeleted(id) {
        self.routeActionToBeDeleted = id;
      },
      clearSavedRouteItems() {
        self.routeActionItems.clear();
      },
      deleteShipmentRouteAction() {
        setFetchingInProgress(true);
        deleteShipmentRouteActions(self.routeActionToBeDeleted)
          .then((response) => {
            // Loading trip events/route actions
            this.setRouteActionToBeDeleted(null);
            this.setRemoveRouteActionModalOpen(false);
            // @ts-ignore
            this.loadRouteItems(self.getSelectedShipmentDetails.shipment_id);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(false);
          });
      },
      loadRouteItems(shipment_id) {
        this.clearSavedRouteItems();
        getShipmentRouteActions(shipment_id)
          .then((response) => {
            getTrackingShipmentData(shipment_id)
              .then((res) => {
                this.settingSavedRouteActionItems(response.data);
                this.setTrackingShipment(res.data);
                this.setPlannerOpened(false);

                setFetchingInProgress(false);
              })
              .catch((error) => {
                console.log('Error: ', error);
                const errorText = getErrorText(error);
                notification.error({
                  message: ERROR_GETTING_SHIPMENT_DATA,
                  description: errorText,
                });
                setFetchingInProgress(false);
              });
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(false);
          });
      },
      /** Route Action Statuses **/
      loadAvailableStatuses() {
        getALlRouteActionStatuses()
          .then((response) => {
            this.setAvailableStatuses(response.data);
          })
          .catch((error) => {
            console.log('Error: ', error);
          });
      },
      setAvailableStatuses(statuses) {
        self.availableStatuses = statuses;
      },
      /** Header time **/
      setCurrentTimeEDT() {
        let dt = moment();
        self.currentTimeEDT = dt.utc().subtract(5, 'hours').format('HH:mm');
      },
      setCurrentTimeGMT() {
        let dt = moment();
        self.currentTimeGMT = dt
          .subtract(dt.parseZone().utcOffset(), 'minutes')
          .format('HH:mm');
      },
      clearSpecificShipmentDetails() {
        self.selectedShipmentDetails = [];
      },
      getShipmentDetails(shipment_id) {
        if (shipment_id) {
          getShipment(shipment_id).then((response) => {
            this.setSelectedShipmentDetails(response.data);
          });
        } else {
          this.setSelectedShipmentDetails({
            shipment_number: 0,
          });
        }
      },
      setSelectedShipmentDetails(shipment) {
        self.selectedShipmentDetails = shipment;
      },
      clearSelectedShipment() {
        self.selectedShipment = null;
      },
      setSelectedShipment(shipment) {
        self.selectedShipment = shipment;
      },
      setShipmentListSearch(value) {
        self.shipmentsListSearch = value;
      },
      // Tour details
      setTourDetailsStatus(value) {
        self.showTourDetailsStatus = value;
      },
      /**
       * Organize tour
       * @param value
       */
      setIsOrganizeTourOpened(value) {
        self.isOrganizeTourOpened = value;
      },
      setIsOrganizeTourLoaded(value) {
        self.isOrganizeTourLoaded = value;
      },
      setOrganizeTourShipments(shipments) {
        shipments.map((shipment) => {
          self.organizeTourShipments.put({
            ...shipment,
          });
        });
      },

      resetOgTrucksData() {
        self.truckOgPage = 1;
        self.truckOgTotal = 0;
        self.organizeTourTrucks = [];
      },
      setOrganizeTourCustomers(customers) {
        self.organizeTourCustomers = customers;
      },
      setOrganizeTourDrivers(drivers) {
        self.organizeTourDrivers = drivers;
      },
      setOrganizeTourTrucks(trucks) {
        self.organizeTourTrucks = trucks;
      },
      setOrganizeTourTrailers(trailers) {
        self.organizeTourTrailers = trailers;
      },
      setOrganizeTourAllActions(actions) {
        self.allOrganizeTourActions = actions;
      },
      loadRouteActionShipments() {
        setFetchingInProgress(true);
        getRouteActionShipments()
          .then((response) => {
            response.data.shipments.map((shipment) => {
              getShipmentRouteActions(shipment.shipment_id)
                .then((res) => {
                  setFetchingInProgress(false);
                  shipment.details = res.data;
                  this.setOrganizeTourShipments(response.data.shipments);
                })
                .catch((error) => {
                  console.log('Error: ', error);
                  setFetchingInProgress(false);
                });
            });
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error fetching shipment route actions!',
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      loadRouteActionCustomers() {
        setFetchingInProgress(true);
        getClients('', 1, 100)
          .then((response) => {
            this.setOrganizeTourCustomers(response.data.clients);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_CUSTOMERS_ERROR,
              description: errorText,
            });
          });
      },
      loadRouteActionDrivers() {
        setFetchingInProgress(true);
        getRouteActionDrivers(self.driverFilterOptionSearch)
          .then((response) => {
            this.setOrganizeTourDrivers(response.data.drivers);
            this.setDriverOgTotal(response.data.max);
            this.setDriverOgPerPage(response.data.min);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_DRIVERS_ERROR,
              description: errorText,
            });
          });
      },
      setAllTrucksLoaded(value) {
        self.allTrucksLoaded = value;
      },
      loadRouteActionTrucks(limit = 10) {
        setFetchingInProgress(true);
        getRouteActionTrucks(self.trucksModalSearch, self.truckOgPage, limit)
          .then((response) => {
            this.setOrganizeTourTrucks(
              self.organizeTourTrucks.concat(response.data.trucks)
            );
            this.setTruckOgTotal(response.data.max);
            this.setTruckOgPerPage(response.data.min);
            if (self.organizeTourTrucks.length >= self.truckOgTotal) {
              this.setAllTrucksLoaded(true);
            }
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_TRUCKS_ERROR,
              description: errorText,
            });
          });
      },
      setTrailersFilterOption(value) {
        self.trailerFilterOptionSearch = value;
        this.loadRouteActionTrailers();
      },
      loadRouteActionTrailers() {
        setFetchingInProgress(true);
        getRouteActionTrailers(
          self.trailerOgPage,
          self.trailerModalLocationSearch,
          self.trailerModalSearch,
          self.trailerModalShipmentIdSearch,
          self.trailerFilterOptionSearch
        )
          .then((response) => {
            this.resetOgTrailerData();
            this.setOrganizeTourTrailers(
              self.organizeTourTrailers.concat(response.data.trailers)
            );
            this.setTrailerOgTotal(response.data.max);
            this.setTrailerOgPerPage(response.data.min);
            if (self.organizeTourTrailers.length >= self.trailerOgTotal) {
              this.setAllTrailersLoaded(true);
            }
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_TRAILERS_ERROR,
              description: errorText,
            });
          });
      },
      loadRouteActions() {
        setFetchingInProgress(true);
        getALlRouteActions()
          .then((response) => {
            this.setOrganizeTourAllActions(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_DRIVERS_ERROR,
              description: errorText,
            });
          });
      },
      loadRouteActionDetails(data) {
        /**
         * 1. get shipments - DONE
         * 2. get customers - DONE
         * 3. get drivers - DONE
         * 4. get trucks - DONE
         * 5. get trailers - DONE
         */
        setFetchingInProgress(true);
        let promises = [
          getRouteActionAllShipmentsPromise(self),
          getRouteActionDriversPromise(self, data.driver.driver_code_name),
          // Missing team driver - resolve
          getRouteActionTrucksPromise(
            self,
            data.truck.truck_company_id,
            1,
            1000
          ),
          getRouteActionTrailersPromise(self, data.trailer.trailer_company_id),
          getALlRouteActionsPromise(self),
          getRouteActionDetailsPromise(data.route_action_id),
        ];
        allSettled(promises)
          .then((response) => {
            setFetchingInProgress(false);
            // this.setIsOrganizeTourLoaded(true)
            this.setEditingRouteActionItem(data);

            self.editedRouteActionItem.setSelectedShipmentID(
              data.shipment.shipment_id
            );
            self.editedRouteActionItem.setActionID(data.action.action_id);
            self.editedRouteActionItem.setSelectedDriver(data.driver.driver_id);
            self.editedRouteActionItem.changeSelectedTruck(data.truck.truck_id);
            self.editedRouteActionItem.changeSelectedTrailer(
              data.trailer.trailer_id
            );
            self.editedRouteActionItem.setNote(data.notes);
            if (response[5]?.value?.locations) {
              self.editedRouteActionItem.setSelectedLocations(
                response[5]?.value?.locations
              );
            }
            if (response[5]?.value?.planned_start) {
              self.editedRouteActionItem.setStartDate(
                moment(response[5].value.planned_start).format('YYYY-MM-DD')
              );
              self.editedRouteActionItem.setStartTime(
                moment(response[5].value.planned_start).format('HH:mm')
              );
            }
          })
          .catch((error) => {
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      setEditingRouteActionItem(item) {
        self.editedRouteActionItem = { ...item };
      },
      clearNewRouteActionItem() {
        self.routeActionItem = null;
      },
      putNewRouteActionItem(data) {
        self.routeActionItem = data;
      },
      addNewRouteActionItem(item) {
        if (item) {
          setFetchingInProgress(true);
          this.loadAvailableAssets(self.assetsSelectedValue);

          let promises = [
            getRouteActions(self),
            getRouteActionAllShipmentsPromise(self),
            // getShipmentFullDetailsPromise(self),
          ];
          if (item.shipment_id) {
            promises.push(getShipmentRouteActionsPromise(item.shipment_id));
          }
          if (self.selectedShipment) {
            promises.push(getShipmentDetails(self.selectedShipment));
          }
          allSettled(promises)
            .then((response) => {
              const selected_route_action = response[0].value.filter(
                (action) => action.name === item.action_type
              );
              if (selected_route_action.length > 0) {
                item.action_id = selected_route_action[0].action_id;
              }
              item.shipment_id = self.selectedShipment;

              const shipments = response[1].value;
              this.setOrganizeTourShipments(shipments);

              /** Setting location **/
              let routeActionsLocations = [];
              if (response[3]?.value?.locations) {
                if (response[3].value.locations?.pickup_locations) {
                  response[3].value.locations?.pickup_locations.forEach(
                    (location) => {
                      routeActionsLocations.push(location);
                    }
                  );
                }
                if (response[3].value.locations?.delivery_locations) {
                  response[3].value.locations?.delivery_locations.forEach(
                    (location) => {
                      routeActionsLocations.push(location);
                    }
                  );
                }
              }

              this.putNewRouteActionItem(item);
              this.setIsOrganizeTourOpened(true);
              self.getNewRouteTripItem.setSelectedLocations(
                routeActionsLocations
              );
              if (response[3]?.value?.trailer_id) {
                // item.trailer_id = ;
                self.getNewRouteTripItem.changeSelectedTrailer(
                  response[3]?.value?.trailer_id
                );
              }

              setFetchingInProgress(false);
            })
            .catch((error) => {
              console.log('Error: ', error);
              setFetchingInProgress(false);
              const errorText = getErrorText(error);
              notification.error({
                message: FETCH_PLANNER_SHIPMENTS_ERROR,
                description: errorText,
              });
            });
        }
      },
      settingSavedRouteActionItems(items) {
        let selectedColor = '';
        let colors = [
          '#96AFCD',
          '#7AD0C6',
          '#7A88D0',
          '#B07AD0',
          '#D07AA4',
          '#4A607A',
          '#5AA79E',
          '#55609B',
          '#8557A0',
          '#984D71',
          '#A8CD96',
          '#E7DD84',
          '#F29E51',
          '#ED4D4D',
          '#80A56E',
          '#B3A847',
          '#B17034',
          '#C02A2A',
        ];
        colors = colors.filter((color) => color !== selectedColor);

        items.forEach((item) => {
          item.color = colors[Math.floor(Math.random() * colors.length)];
          selectedColor = item.color;
          self.routeActionItems.put({
            ...item,
          });
        });
      },
      clearSavedRouteActionItems() {
        self.routeActionItems.clear();
      },
      removeTripItem() {
        // this.clearSelectedShipment();
        // this.clearSpecificShipmentDetails();
        this.clearRouteActionItemToDraw();
        this.clearLocations();
        this.clearSelectedShipmentToDraw();
        // this.clearSavedRouteItems();
        this.setSelectedDriverForMap([]);
        this.setTruckToShowOnMap(null);
        this.setTrailerToShowOnMap(null);
        this.setTruckToShowOnMap(null);
        this.setIsShipmentsSidebarShown(false);
        this.setShipmentsListStatus(false);
        this.setTrailerToShowOnMap(null);
        this.putNewRouteActionItem(null);
        this.setIsOrganizeTourOpened(false);
      },
      removeEditingRouteItem() {
        self.editedRouteActionItem = null;
      },
      setIsOrganizeTourShipmentsModalOpen(value) {
        self.isOrganizeTourShipmentsModalOpen = value;
      },
      setOrganizeTourShipmentsModalSearch(value) {
        self.organizeTourShipmentsModalSearch = value;
      },
      setIsOrganizeTourCustomersModalOpen(value) {
        self.isOrganizeTourCustomersModalOpen = value;
      },
      setIsOrganizeTourDriversModalOpen(value) {
        self.isOrganizeTourDriversModalOpen = value;
        if (value) {
          this.setDriverModalSearch('');
          this.setDriverModalLocationSearch(null);
          this.setDriverFilterOption('All');
          this.setLocationsSearchText('');
          if (
            rootInstance.dispatchPageState?.routeActionItem
              ?.selected_location_id
          ) {
            this.setDriverModalLocationSearch(
              rootInstance.dispatchPageState?.routeActionItem
                ?.selected_location_id
            );
          }
        }
      },
      setIsOrganizeTourTeamDriversModalOpen(value) {
        self.isOrganizeTourTeamDriversModalOpen = value;
        if (value) {
          this.setDriverModalSearch('');
          this.setDriverModalLocationSearch(null);
          this.setDriverFilterOption('All');
          this.setLocationsSearchText('');
        }
      },
      setIsOrganizeTourTrailersModalOpen(value) {
        self.isOrganizeTourTrailersModalOpen = value;
        if (value) {
          if (rootInstance.dispatchPageState?.routeActionItem?.shipment_id) {
            this.setTrailerModalShipmentIdSearch(
              rootInstance.dispatchPageState?.routeActionItem?.shipment_id
            );
          }
          if (
            rootInstance.dispatchPageState?.routeActionItem
              ?.selected_location_id
          ) {
            this.setTrailerModalLocationSearch(
              rootInstance.dispatchPageState?.routeActionItem
                ?.selected_location_id
            );
          }
        }
        this.setIsSelectedTrailerChanged(false);
      },
      /**
       * Columns
       */
      getOrganizeTourShipmentModalColumns() {
        return Object.values(OrganizeTourShipmentsModalColumns(getRoot(self)));
      },
      getAvailableShipmentsColumns() {
        return Object.values(ShipmentsSidebarTableColumns(getRoot(self)));
      },
      getCustomersTableColumns() {
        return Object.values(CustomersTableColumns(getRoot(self)));
      },
      getAvailableDriversTableColumns() {
        return Object.values(
          DispatchAvailableDriversTableColumns(getRoot(self))
        );
      },
      getAvailableTeamDriversTableColumns() {
        return Object.values(
          DispatchAvailableTeamDriversTableColumns(getRoot(self))
        );
      },
      getAvailableTrailersTableColumns() {
        return Object.values(
          DispatchAvailableTrailersTableColumns(getRoot(self))
        );
      },
      getAvailableTrucksTableColumns() {
        return Object.values(
          DispatchAvailableTrucksTableColumns(getRoot(self))
        );
      },
      getShipmentDetailsTableColumns() {
        return Object.values(ShipmentsDetailsTableColumns(getRoot(self)));
      },
      getAssetsListTruckColumns() {
        return Object.values(AssetsListTrucksTableColumns(getRoot(self)));
      },
      getAssetsListTrailerColumns() {
        return Object.values(AssetsListTrailersTableColumns(getRoot(self)));
      },
      getAssetsListDriverColumns() {
        return Object.values(AssetsListDriversTableColumns(getRoot(self)));
      },
      getShipmentListTableColumns() {
        return Object.values(PlannerTableColumns(getRoot(self)));
      },
      getShipmentListProbillsTableColumns() {
        return Object.values(PlannerProbillsTableColumns(getRoot(self)));
      },
      getRouteActionListTableColumns() {
        return Object.values(RouteActionsListTableColumns(getRoot(self)));
      },
      /**
       * Set shipments modal
       */
      setIsShipmentsSidebarShown(value) {
        self.isShipmentsSidebarShown = value;
        if (value) {
          this.loadAvailableAssets(self.assetsSelectedValue);
          this.getAllShipments();
        }
      },
      setShipmentsListStatus(value) {
        self.shipmentsListStatus = value;
        this.getAllShipments();
      },
      setAvailableShipments(shipments) {
        self.shipmentsList.clear();
        shipments.forEach((item) => {
          self.shipmentsList.put({
            ...item,
          });
        });
      },
      getAllShipments() {
        // setFetchingInProgress(true);
        // const status = !self.shipmentsListStatus;
        // getAllTrackingShipments(status)
        //   .then((response) => {
        //     this.setAvailableShipments(response.data.shipments);
        //     setFetchingInProgress(false);
        //   })
        //   .catch((error) => {
        //     console.log('Error: ', error);
        //     setFetchingInProgress(false);
        //   });
      },
      setZoom(value) {
        self.zoom = value;
      },
      getLocationsById(shipment_id) {
        this.clearLocations();
        getLocationsByShipmentId(shipment_id).then((response) => {
          const data = response.data;
          if (response.data.distance < 100) {
            this.setZoom(10);
          } else {
            this.setZoom(5);
          }
          this.setSelectedShipmentPickupLocations(data.pickup_locations);
          this.setSelectedShipmentDeliveryLocations(data.delivery_locations);
        });
      },
      clearLocations() {
        self.selectedShipmentPickupLocations.clear();
        self.selectedShipmentDeliveryLocations.clear();
      },
      setSelectedShipmentPickupLocations(locations) {
        locations.forEach((location) => {
          self.selectedShipmentPickupLocations.put({
            ...location,
          });
        });
      },
      setSelectedShipmentDeliveryLocations(locations) {
        locations.forEach((location) => {
          self.selectedShipmentDeliveryLocations.put({
            ...location,
          });
        });
      },
      setSelectedShipmentToDraw(action) {
        self.selectedShipmentToDraw = [action];
      },
      clearSelectedShipmentToDraw() {
        self.selectedShipmentToDraw = [];
      },
      onViewShipmentActions(shipment_id) {
        setFetchingInProgress(true);
        this.clearSavedRouteActionItems();
        getShipmentRouteActions(shipment_id)
          .then((response) => {
            this.settingSavedRouteActionItems(response.data);
            this.setIsAllShipmentActionsOpened(true);
            if (response.data.length > 0) {
              response.data.map((action) => {
                if (action.action_name === 'In Progress') {
                  this.setSelectedShipmentToDraw(action);
                }
              });
            }
            this.getShipmentDetails(shipment_id);
            setFetchingInProgress(false);
            this.setPlannerOpened(false);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(false);
          });
      },
      getRouteActionsList(shipment_id) {
        this.clearSelectedShipmentToDraw();
        this.clearSavedRouteItems();
        getShipmentRouteActions(shipment_id)
          .then((response) => {
            this.settingSavedRouteActionItems(response.data);
            if (response.data.length > 0) {
              response.data.map((action) => {
                if (action.status === 'In Progress') {
                  this.setSelectedShipmentToDraw(action);
                }
              });
            }
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('Error: ', error);
            setFetchingInProgress(false);
          });
      },
      setDriverFilterOption(value) {
        self.driverFilterOptionSearch = value;
        this.clearAvailableDrivers();
        this.filterAvailableDrivers();
      },
      setAvailableDriverFilters(filters) {
        self.driverFilterOptions = filters;
      },
      loadAvailableDriverFilters() {
        getDriverFilterOptions(self.driverModalLocationSearch).then(
          (response) => {
            this.setAvailableDriverFilters(response.data);
          }
        );
      },
      loadAvailableTrailerFilters() {
        getDriverFilterOptions(self.trailerModalLocationSearch).then(
          (response) => {
            this.setAvailableDriverFilters(response.data);
          }
        );
      },
      loadAvailableLocations() {
        getLocations('', 1, 100).then((response) => {
          this.setAvailableLocations(response.data.locations);
        });
      },
      setAvailableLocations(locations) {
        self.availableLocations = locations;
      },
      setPlannedShipments(shipments) {
        self.plannedShipments = shipments;
      },
      loadPlannedShipments() {
        self.plannedShipments = [];
        setFetchingInProgress(true);
        getPlannedShipments()
          .then((response) => {
            response.data.forEach((item, index) => {
              item.index = index;
              item.dispatcher = item.dispatcher
                ? item.dispatcher
                : NOT_AVAILABLE;
              item.pickup_location.city = item?.pickup_location?.city
                ? item?.pickup_location?.city
                : NOT_AVAILABLE;
            });
            this.setPlannedShipments(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_PLANNER_SHIPMENTS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      /** Enter and exit actions **/
      beforeEnterSetSelectedShipment(id) {
        // if (id) !== 0
        if (id) {
          setFetchingInProgress(true);
          this.getShipmentDetails(id);
          this.setSelectedShipment(id);
          this.getRouteActionsList(id);
          this.getLocationsById(id);
        }
      },
      closeAllSidebars() {
        this.setPlannerOpened(false);
        this.setIsAssetsListOpened(false);
        this.setIsRouteActionListOpened(false);
      },
      beforeExitClearSelectedShipmentId() {
        this.clearTrackingShipment();
        this.clearSelectedShipment();
        this.clearSpecificShipmentDetails();
        this.clearRouteActionItemToDraw();
        this.clearLocations();
        this.clearSelectedShipmentToDraw();
        this.clearSavedRouteItems();
        this.setSelectedDriverForMap([]);
        this.setTruckToShowOnMap(null);
        this.setTrailerToShowOnMap(null);
        this.setTruckToShowOnMap(null);
        this.setIsShipmentsSidebarShown(false);
        this.setShipmentsListStatus(false);
        this.setTrailerToShowOnMap(null);
        this.clearSavedRouteItems();
        this.putNewRouteActionItem(null);
        this.setIsAllShipmentActionsOpened(false);

        this.setTourDetailsStatus(false);
        this.setIsOrganizeTourOpened(false);
        this.removeTripItem();
      },
    };
  });
