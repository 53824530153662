import React from 'react';
import {
  DOCUMENT_TYPE,
  DATE_ADDED,
  ATTACHED_FILE,
  VIEW_FILE,
  REMOVE_DOCUMENT,
} from '../../constants';
import trash from '../../assets/images/trash.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentsPageState.currentShipment.setDocumentToBeDeleted(
    record.uid
  );
};

export const documentsColumnsFunc = (rootInstance) => ({
  '1': {
    title: DOCUMENT_TYPE,
    dataIndex: 'document_type.document_type_name',
    key: 'document_type.document_type_name',
  },
  '2': {
    title: DATE_ADDED,
    key: 'date_added',
    dataIndex: 'date_added',
  },
  '3': {
    title: ATTACHED_FILE,
    key: 'name',
    dataIndex: 'name',
    render: (text, record) => {
      return (
        <Tooltip placement="top" title={VIEW_FILE}>
          <a href={record.document_url} target="blank">
            {record.name}
          </a>
        </Tooltip>
      );
    },
  },
  '4': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        !rootInstance.shipmentsPageState.isViewShipmentPage && (
          <div className="align-center">
            <Tooltip title={REMOVE_DOCUMENT}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          </div>
        )
      );
    },
  },
});
