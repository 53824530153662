import React from 'react';
import { observer } from 'mobx-react';
import ViewShipmentComponent from '../../components/brokerage/ViewShipment/ViewShipmentComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import { VIEW_SHIPMENT } from '../../constants';
import { useMst } from '../../state/RootModel';

const ViewShipmentPage = () => {
  const {
    shipmentsPageState: { viewedShipment },
  } = useMst();

  return (
    <div className="page create-shipment-page">
      <BreadcrumsComponent
        page={`${VIEW_SHIPMENT}`}
        breadcrumbsBackLink={PageRoutes.Brokerage.name}
      />
      {viewedShipment && <ViewShipmentComponent />}
    </div>
  );
};

export default observer(ViewShipmentPage);
