import { Tooltip } from 'antd';
import React from 'react';
import { ACTION, NOT_AVAILABLE } from '../../../constants';
import edit from '../../../assets/images/edit-item.svg';
import {
  USER_ROLE_EDIT,
  USER_ROLE_EMPLOYEES,
  USER_ROLE_NAME,
  USER_ROLE_NO,
} from '../../../constants/organization/userRole';
import PageRoutes from '../../../utils/PageRoutes';
import { rootInstance } from '../../RootModel';
import { replaceUnderscore } from '../../../utils/methods';

const onUserRoleEdit = (e, role_id) => {
  e.preventDefault();
  const params = { roleId: role_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditUserRole.name),
    params
  );
};

export const organizationUserRolesColumnsFunc = (rootInstance) => ({
  '1': {
    title: USER_ROLE_NO,
    dataIndex: 'role_id',
    key: 'role_id',
  },
  '2': {
    title: USER_ROLE_NAME,
    dataIndex: 'role_name',
    key: 'role_name',
    render: (text) => {
      return (
        <p className="text-capitalize">
          {text ? replaceUnderscore(text) : NOT_AVAILABLE}
        </p>
      );
    },
  },
  '3': {
    title: USER_ROLE_EMPLOYEES,
    dataIndex: 'number_of_people',
    key: 'number_of_people',
    render: (text) => {
      return <p className="text-capitalize">{text ? text : NOT_AVAILABLE}</p>;
    },
  },
  '7': {
    title: ACTION,
    render: (text, record) => {
      return (
        <div className="align-center">
          {/*                    <Tooltip title={USER_ROLE_DELETE}>
                        <img
                            src={trash}
                            alt={USER_ROLE_DELETE}
                            className="pointer ph10"
                            onClick={(e) => onUserRoleDelete(e, record.role_id)}
                        />
                    </Tooltip>*/}
          {rootInstance.userState.canEditRoles && (
            <Tooltip title={USER_ROLE_EDIT}>
              {record.role_name !== 'Super Admin' && (
                <img
                  src={edit}
                  alt={USER_ROLE_EDIT}
                  className="pointer ph10"
                  onClick={(e) => onUserRoleEdit(e, record.role_id)}
                />
              )}
            </Tooltip>
          )}
        </div>
      );
    },
  },
});
