import { Tooltip } from 'antd';
import React from 'react';
import {
  ORGANIZATION_EDIT,
  ORGANIZATION_NAME,
  ORGANIZATION_NO,
} from '../../constants/organizations/organizations';
import {
  ACTION,
  ADDRESS,
  EMAIL,
  NOT_AVAILABLE,
  PHONE_NUMBER,
} from '../../constants';
import edit from '../../assets/images/edit-item.svg';
import { rootInstance } from '../RootModel';
import PageRoutes from '../../utils/PageRoutes';

const onOrganizationEdit = (organization_id) => {
  const params = { organizationId: organization_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditOrganization.name),
    params
  );
};
// const onOrganizationDelete = (organization_id) => {
//     rootInstance.organizationState.setDeleteOrganizationModalOpen(true)
//     rootInstance.organizationState.getDeletedOrganization(organization_id)
// }
//
// const onOrganizationAdminChange = (organization_id) => {
//     rootInstance.organizationState.setEditOrganizationAdminModalOpened(true)
//     rootInstance.organizationState.getEditedOrganization(organization_id)
// }

export const organizationColumnsFunc = (rootInstance) => ({
  '1': {
    title: ORGANIZATION_NO,
    dataIndex: 'organization_id',
    key: 'organization_id',
  },
  '2': {
    title: ORGANIZATION_NAME,
    dataIndex: 'organization_name',
    key: 'organization_name',
    render: (text, record) => {
      return <span>{text}</span>;
    },
  },
  '3': {
    title: ADDRESS,
    dataIndex: 'address',
    key: 'address',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '4': {
    title: PHONE_NUMBER,
    dataIndex: 'phone_number',
    key: 'phone_number',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '5': {
    title: EMAIL,
    dataIndex: 'email',
    key: 'email',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  // '6': {
  //     title: ORGANIZATION_SUPER_ADMIN,
  //     dataIndex: 'super_admin_email',
  //     key: 'super_admin_email',
  //     render: (text, record) => {
  //         return (
  //             <div className="align-center">
  //                 {text ? text : NOT_AVAILABLE}
  //                 {text && (
  //                     <Tooltip title={ORGANIZATION_CHANGE_SUPER_ADMIN}>
  //                         <img
  //                             src={edit}
  //                             alt={ORGANIZATION_CHANGE_SUPER_ADMIN}
  //                             className="pointer ph10"
  //                             onClick={(e) => onOrganizationAdminChange(record.organization_id)}
  //                         />
  //                     </Tooltip>
  //                 )}
  //
  //             </div>
  //         )
  //     }
  // },
  '7': {
    title: ACTION,
    render: (text, record) => {
      return (
        <div className="align-center">
          {/*<Tooltip title={ORGANIZATION_DELETE}>*/}
          {/*    <img*/}
          {/*        src={trash}*/}
          {/*        alt={ORGANIZATION_DELETE}*/}
          {/*        className="pointer ph10"*/}
          {/*        onClick={(e) => onOrganizationDelete(record.organization_id)}*/}
          {/*    />*/}
          {/*</Tooltip>*/}
          {rootInstance.userState.canEditOrganization && (
            <Tooltip title={ORGANIZATION_EDIT}>
              <img
                src={edit}
                alt={ORGANIZATION_EDIT}
                className="pointer ph10"
                onClick={(e) => onOrganizationEdit(record.organization_id)}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
});
