import React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import {
  CARRIER_CODE,
  CARRIER_COMPANY,
  CARRIER_DETAILS,
  CONTACT_NAME,
  CONTACT_POSITION,
  DOT_NUMBER,
  EMAIL,
  FAX_NUMBER,
  MC_NUMBER,
  NOTES,
  PHONE_NUMBER,
} from '../../../../../constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useMst } from '../../../../../state/RootModel';

const CarrierDetails = () => {
  const {
    carriersPageState: { editedCarrier },
  } = useMst();

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header align-center">
        <p>{CARRIER_DETAILS}</p>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <div className="input-label justify-between">
            <span>{CARRIER_COMPANY}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setCompanyName(e.target.value)}
            value={editedCarrier.company_name}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{CONTACT_NAME}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setContactName(e.target.value)}
            value={editedCarrier.contact_name}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{CONTACT_POSITION}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setContactPosition(e.target.value)}
            value={editedCarrier.contact_position}
          />
        </div>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <div className="input-label justify-between">
            <span>{DOT_NUMBER}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setDotNumber(e.target.value)}
            value={editedCarrier.dot_number}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{MC_NUMBER}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setMcNumber(e.target.value)}
            value={editedCarrier.mc_number}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{CARRIER_CODE}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setCarrierCode(e.target.value)}
            value={editedCarrier.carrier_code}
          />
        </div>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <div className="input-label justify-between">
            <span>{PHONE_NUMBER}</span>
            <span className="color-secondary">*</span>
          </div>
          <PhoneInput
            placeholder="e.g. 712338645"
            value={editedCarrier.phone_number}
            onChange={(value) => editedCarrier.setPhoneNumber(value)}
            maxLength={15}
            displayInitialValueAsLocalNumber
            className="phone-input"
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{FAX_NUMBER}</span>
          </div>
          <PhoneInput
            placeholder="e.g. 712338645"
            value={editedCarrier.fax_number}
            onChange={(value) => editedCarrier.setFaxNumber(value)}
            maxLength={15}
            displayInitialValueAsLocalNumber
            className="phone-input"
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{EMAIL}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setEmail(e.target.value)}
            value={editedCarrier.email}
          />
        </div>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <div className="input-label justify-between">
            <span>{NOTES}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedCarrier.setNotes(e.target.value)}
            value={editedCarrier.notes}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(CarrierDetails);
