import React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import { useMst } from '../../../../state/RootModel';
import { TITLE, DESCRIPTION } from '../../../../constants';

const { TextArea } = Input;

const NewShipmentDocumentTypeComponent = () => {
  const {
    shipmentSpecificsPageState: { newShipmentDocumentType },
  } = useMst();

  const onChangeDescription = (e) => {
    newShipmentDocumentType.setDescription(e.target.value);
  };

  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) =>
            newShipmentDocumentType.setDocumentTypeName(e.target.value)
          }
          value={newShipmentDocumentType?.document_type_name}
        />
      </div>
      <div className="input-wrapper mt20 w100">
        <div className="input-label justify-between">
          <span>{DESCRIPTION}</span>
        </div>
        <TextArea
          rows={4}
          onChange={onChangeDescription}
          value={newShipmentDocumentType?.description}
        />
      </div>
    </div>
  );
};

export default observer(NewShipmentDocumentTypeComponent);
