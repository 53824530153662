import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import {
  BROKERAGE_CHARGE,
  CANCEL,
  CURRENCY,
  DESCRIPTION,
  EDIT,
  PRICE,
  TITLE,
} from '../../../../../constants';
import { Checkbox, Input, Select } from 'antd';
import ErrorBox from '../../../../ErrorBox';
const { Option } = Select;
const { TextArea } = Input;

const EditedShipmentCharge = () => {
  const {
    shipmentSpecificsPageState: {
      shipmentChargeToBeEdited,
      currencyTypes,
      editedShipmentSpecificType,
      setEditingShipmentSpecific,
      setShipmentChargeToBeEdited,
    },
  } = useMst();

  const onCurrencySelect = (value) => {
    shipmentChargeToBeEdited.setCurrency(value);
  };

  const onChangeDescription = (e) => {
    shipmentChargeToBeEdited.setDescription(e.target.value);
  };

  const onSetPrice = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      shipmentChargeToBeEdited.setPrice(null);
    } else {
      shipmentChargeToBeEdited.setPrice(Number(e.target.value));
    }
  };

  const onSelectBrokerageCharge = () => {
    shipmentChargeToBeEdited.setIsBrokerage(
      !shipmentChargeToBeEdited.is_brokerage_shipment
    );
  };

  const onEdit = () => {
    shipmentChargeToBeEdited.saveItem();
    // newLocation.setOwnerId(customer.client_id);
  };
  const onCancel = () => {
    setEditingShipmentSpecific(false);
    setShipmentChargeToBeEdited(null);
  };

  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="align-center">
          <Checkbox
            checked={shipmentChargeToBeEdited?.is_brokerage_shipment}
            onChange={onSelectBrokerageCharge}
            className="align-center"
          >
            <p>{BROKERAGE_CHARGE}</p>
          </Checkbox>
        </div>
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) =>
            shipmentChargeToBeEdited.setChargeName(e.target.value)
          }
          value={shipmentChargeToBeEdited?.charge_name}
        />
      </div>
      <div className="flex-alignitems-flex-start w100 mt20">
        <div className="input-wrapper w25">
          <div className="input-label justify-between">
            <span>{CURRENCY}</span>
          </div>
          <Select
            className="text-capitalize"
            style={{ width: '100%' }}
            onChange={onCurrencySelect}
            value={shipmentChargeToBeEdited?.currency}
          >
            {currencyTypes.map((type) => (
              <Option key={type} value={type} className="text-capitalize">
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper w70 ml5pc">
          <div className="input-label justify-between">
            <span>{PRICE}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={onSetPrice}
            value={shipmentChargeToBeEdited?.price}
          />
        </div>
      </div>
      <div className="input-wrapper mt20 w100">
        <div className="input-label justify-between">
          <span>{DESCRIPTION}</span>
        </div>
        <TextArea
          rows={4}
          onChange={onChangeDescription}
          value={shipmentChargeToBeEdited?.description}
        />
      </div>
      <ErrorBox obj={editedShipmentSpecificType} />

      <div className="justify-end mt20">
        <button className="btn white mr10" onClick={onCancel}>
          {CANCEL}
        </button>
        <button className="btn primary" onClick={onEdit}>
          {EDIT}
        </button>
      </div>
    </div>
  );
};

export default observer(EditedShipmentCharge);
