import { types } from 'mobx-state-tree';
import {
  addUserAbsence,
  uploadUserAbsenceDocument,
} from '../../actions/employees';
import {
  addDriverAbsence,
  uploadDriverAbsenceDocument,
} from '../../actions/fleet';
import {
  ABSENCE_TYPE_REQUIRED,
  END_DATE_REQUIRED,
  START_DATE_REQUIRED,
} from '../../constants';
import { EDIT_DRIVER, EDIT_EMPLOYEE } from '../../constants/menu';
import { STATUS_FREE_DAY } from '../../constants/workingStatuses';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { rootInstance } from '../RootModel';

export const AbsenceLightModel = types
  .model('AbsenceLightModel', {
    user_id: types.maybe(types.maybeNull(types.number)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    starting_date: types.maybe(types.maybeNull(types.string)),
    end_date: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {
      get shouldDisplayDatePicker() {
        if (self.status && self.status === STATUS_FREE_DAY) {
          return true;
        }
        return false;
      },
      get isEditEmployeePage() {
        return rootInstance.router.currentView.name === EDIT_EMPLOYEE;
      },
      get isEditDriverPage() {
        return rootInstance.router.currentView.name === EDIT_DRIVER;
      },
    };
  })
  .actions((self) => {
    return {
      setStartingDate(date) {
        self.starting_date = date;
      },
      setEndDate(date) {
        self.end_date = date;
      },
      setStatus(status) {
        self.status = status;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwAddAbsenceError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      redirectAfterSave() {
        if (self.isEditEmployeePage) {
          rootInstance.employeesPageState.getEditedEmployee(self.user_id);
        } else if (self.isEditDriverPage) {
          rootInstance.fleetPageState.getEditedDriver(self.driver_id);
        }
      },
      validateForm() {
        if (!self.starting_date) {
          this.setErrorText(START_DATE_REQUIRED);
        } else if (!self.end_date) {
          this.setErrorText(END_DATE_REQUIRED);
        } else if (!self.status) {
          this.setErrorText(ABSENCE_TYPE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveAbsence(document) {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          if (self.status === STATUS_FREE_DAY) {
            this.setEndDate(self.starting_date);
          }
          if (self.isEditEmployeePage) {
            addUserAbsence(self)
              .then((absence) => {
                if (document) {
                  const formData = new FormData();
                  formData.append('file', document.originFileObj);
                  uploadUserAbsenceDocument(absence.data.absence_id, formData)
                    .then((document) => {
                      this.redirectAfterSave();
                    })
                    .catch((error) => {
                      this.throwAddAbsenceError(error);
                    });
                } else {
                  this.redirectAfterSave();
                }
              })
              .catch((error) => {
                this.throwAddAbsenceError(error);
              });
          } else if (self.isEditDriverPage) {
            addDriverAbsence(self)
              .then((absence) => {
                if (document) {
                  const formData = new FormData();
                  formData.append('file', document.originFileObj);
                  uploadDriverAbsenceDocument(absence.data.absence_id, formData)
                    .then((document) => {
                      this.redirectAfterSave();
                    })
                    .catch((error) => {
                      this.throwAddAbsenceError(error);
                    });
                } else {
                  this.redirectAfterSave();
                }
              })
              .catch((error) => {
                this.throwAddAbsenceError(error);
              });
          }
        }
      },
    };
  });
