import { types } from 'mobx-state-tree';
import {
  addDriverCompliancy,
  uploadDriverCompliancyDocument,
} from '../../../actions/fleet';
import {
  COMPLIANCY_DESCRIPTION_REQUIRED,
  COMPLIANCY_TYPE_REQUIRED,
  EXPIRATION_DATE_REQUIRED,
} from '../../../constants';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';

export const CompliancyLightModel = types
  .model('CompliancyLightModel', {
    compliancy_type: types.maybe(types.maybeNull(types.string)),
    expiration_date: types.maybe(types.maybeNull(types.string)),
    compliancy_description: types.maybe(types.maybeNull(types.string)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setType(type) {
        self.compliancy_type = type;
      },
      setDescription(text) {
        self.compliancy_description = text;
      },
      setExpirationDate(date) {
        self.expiration_date = date;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwAddCompliancyError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.compliancy_type) {
          this.setErrorText(COMPLIANCY_TYPE_REQUIRED);
        } else if (!self.compliancy_description) {
          this.setErrorText(COMPLIANCY_DESCRIPTION_REQUIRED);
        } else if (!self.expiration_date) {
          this.setErrorText(EXPIRATION_DATE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveCompliancy(document) {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addDriverCompliancy(self)
            .then((compliancy) => {
              if (document) {
                const formData = new FormData();
                formData.append('file', document.originFileObj);
                uploadDriverCompliancyDocument(
                  compliancy.data.compliancy_id,
                  formData
                )
                  .then((document) => {
                    rootInstance.fleetPageState.getEditedDriver(self.driver_id);
                  })
                  .catch((error) => {
                    this.throwAddCompliancyError(error);
                  });
              } else {
                rootInstance.fleetPageState.getEditedDriver(self.driver_id);
              }
            })
            .catch((error) => {
              this.throwAddCompliancyError(error);
            });
        }
      },
    };
  });
