import { types } from 'mobx-state-tree';
import { isNaN } from 'lodash';

export const ShipmentRelatedChargeModel = types
  .model('ShipmentRelatedChargeModel', {
    shipment_charge_id: types.identifierNumber,
    charge_id: types.maybe(types.maybeNull(types.number)),
    charge_name: types.maybe(types.maybeNull(types.string)),
    currency: types.maybe(types.maybeNull(types.string)),
    shipment_id: types.maybe(types.maybeNull(types.number)),
    price: types.maybe(types.maybeNull(types.number)),
    chargeItem: types.maybe(types.maybeNull(types.frozen({}))),
    isNew: false,
    isChangedDuringEdit: false,
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setIsChangedDuringEdit(status) {
        self.isChangedDuringEdit = status;
      },
      setIsNew(status) {
        self.isNew = status;
      },
      setCurrency(value) {
        self.currency = value;
        if (!self.isNew) {
          this.setIsChangedDuringEdit(true);
        }
      },
      setChargeId(value) {
        self.charge_id = value;
        if (!self.isNew) {
          this.setIsChangedDuringEdit(true);
        }
      },
      setChargeItem(value) {
        if (self.chargeItem?.['charge_id']) {
          this.clearCharge();
        }
        self.chargeItem = value;
        self.chargeItem?.['currency']
          ? this.setCurrency(self.chargeItem?.['currency'])
          : this.setCurrency(self.currency);
        self.chargeItem?.['price']
          ? this.setAmount(self.chargeItem?.['price'])
          : this.setAmount(self.price);
        this.setChargeId(self.chargeItem?.['charge_id']);
        this.setChargeName(self.chargeItem?.['charge_name']);
        if (!self.isNew) {
          this.setIsChangedDuringEdit(true);
        }
      },
      setAmount(value) {
        if (!isNaN(value)) {
          self.price = value;
        }
        if (!self.isNew) {
          this.setIsChangedDuringEdit(true);
        }
      },
      setChargeName(value) {
        self.charge_name = value;
      },
      clearCharge() {
        this.setChargeId(null);
        this.setCurrency(null);
        this.setAmount(null);
      },
    };
  });
