import React from 'react';
import { observer } from 'mobx-react';
import { REGISTRATION } from '../../../../constants';
import dropdownIcon from '../../../../assets/images/dropdown-icon.svg';
import ViewRegistrationTable from './ViewRegistrationTable';

const ViewRegistrationSection = ({ model }) => {
  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            model.setIsRegistrationSectionOpen(
              !model.isRegistrationSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              model.isRegistrationSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {REGISTRATION}
          </span>
        </div>
      </div>
      {model?.isRegistrationSectionOpen && (
        <ViewRegistrationTable model={model} />
      )}
    </div>
  );
};

export default observer(ViewRegistrationSection);
