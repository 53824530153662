import { types } from 'mobx-state-tree';
import {
  ORGANIZATION_ADMIN_EMAIL_INVALID,
  ORGANIZATION_ADMIN_EMAIL_REQUIRED,
  ORGANIZATION_ADMIN_FIRSTNAME_REQUIRED,
  ORGANIZATION_ADMIN_LASTNAME_REQUIRED,
  ORGANIZATION_NAME_REQUIRED,
  ORGANIZATION_OTHER_FIELDS_REQUIRED,
} from '../../constants/organizations/organizations';
import { rootInstance } from '../RootModel';
import {
  getErrorText,
  setFetchingInProgress,
  validateEmail,
} from '../../utils/methods';
import {
  createOrganization,
  createOrganizationAdmin,
} from '../../actions/organizations';
import { notification } from 'antd';
import { FETCH_LOCATIONS_ERROR } from '../../constants';

export const OrganizationLightModel = types
  .model('OrganizationLightModel', {
    key: types.maybe(types.maybeNull(types.number)),
    organization_name: types.maybe(types.maybeNull(types.string)),
    mc: types.maybe(types.maybeNull(types.string)),
    dot: types.maybe(types.maybeNull(types.string)),
    cvor: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    admin_email: types.maybe(types.maybeNull(types.string)),
    admin_firstname: types.maybe(types.maybeNull(types.string)),
    admin_lastname: types.maybe(types.maybeNull(types.string)),
    isAdminEmailSet: false,
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setIsAdminEmailSet(value) {
        self.isAdminEmailSet = value;
      },
      setAdminEmail(value) {
        self.admin_email = value;
      },
      setAdminFirstname(value) {
        self.admin_firstname = value;
      },
      setAdminLastname(value) {
        self.admin_lastname = value;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setOrganizationName(value) {
        self.organization_name = value;
      },
      setOrganizationMC(value) {
        self.mc = value;
      },
      setOrganizationDOT(value) {
        self.dot = value;
      },
      setOrganizationCVOR(value) {
        self.cvor = value;
      },
      clearNewOrganizationForm() {
        this.setErrorText('');
        this.setOrganizationName('');
        this.setOrganizationMC('');
        this.setOrganizationDOT('');
        this.setOrganizationCVOR('');
        this.setAdminEmail('');
      },
      validateNewOrganization() {
        if (!self.organization_name) {
          this.setErrorText(ORGANIZATION_NAME_REQUIRED);
        } else if (!self.mc && !self.dot && !self.cvor) {
          this.setErrorText(ORGANIZATION_OTHER_FIELDS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveNewOrganization() {
        if (this.validateNewOrganization()) {
          this.setIsAdminEmailSet(true);
        }
      },
      validateNewOrganizationAdmin() {
        const emailValid = validateEmail(self.admin_email);
        if (!self.admin_firstname) {
          this.setErrorText(ORGANIZATION_ADMIN_FIRSTNAME_REQUIRED);
        } else if (!self.admin_lastname) {
          this.setErrorText(ORGANIZATION_ADMIN_LASTNAME_REQUIRED);
        } else if (!self.admin_email) {
          this.setErrorText(ORGANIZATION_ADMIN_EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(ORGANIZATION_ADMIN_EMAIL_INVALID);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      setNewOrganizationAdmin() {
        if (this.validateNewOrganizationAdmin()) {
          setFetchingInProgress(true);
          createOrganization(self)
            .then((response) => {
              createOrganizationAdmin(response.data.organization_id, self)
                .then((response) => {
                  setFetchingInProgress(false);
                  rootInstance.organizationState.setAddOrganizationModalOpen(
                    false
                  );
                  rootInstance.organizationState.setOrganizationSuccessModalOpened(
                    true
                  );
                })
                .catch((error) => {
                  setFetchingInProgress(false);
                  const errorText = getErrorText(error);
                  notification.error({
                    message: FETCH_LOCATIONS_ERROR,
                    description: errorText,
                  });
                });
            })
            .catch((error) => {
              setFetchingInProgress(false);
              const errorText = getErrorText(error);
              notification.error({
                message: FETCH_LOCATIONS_ERROR,
                description: errorText,
              });
            });
        }
      },
    };
  });
