import React from 'react';
import { observer } from 'mobx-react';
import { Input, DatePicker } from 'antd';
import {
  CARGO_AMOUNT,
  INSURANCE_BROKER,
  INSURANCE_COMPANY_NAME,
  INSURANCE_DETAILS,
  INSURANCE_EXPIRY_DATE,
  INSURANCE_POLICY,
  LIABILITY_AMOUNT,
} from '../../../../../constants';
import 'react-phone-number-input/style.css';
import { useMst } from '../../../../../state/RootModel';
import moment from 'moment';

const InsuranceDetails = () => {
  const {
    carriersPageState: { newCarrier },
  } = useMst();

  const onInsuranceExpiryDateChange = (value, string) => {
    newCarrier.setInsuranceExpiryDate(string);
  };

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header align-center">
        <p>{INSURANCE_DETAILS}</p>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <div className="input-label justify-between">
            <span>{INSURANCE_COMPANY_NAME}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => newCarrier.setInsuranceCompany(e.target.value)}
            value={newCarrier.insurance_company}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{INSURANCE_BROKER}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => newCarrier.setInsuranceBroker(e.target.value)}
            value={newCarrier.insurance_broker}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{INSURANCE_POLICY}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) =>
              newCarrier.setInsurancePolicyNumber(e.target.value)
            }
            value={newCarrier.insurance_policy_number}
          />
        </div>
      </div>
      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <div className="input-label justify-between">
            <span>{INSURANCE_EXPIRY_DATE}</span>
          </div>
          <DatePicker
            value={
              newCarrier.insurance_expiry_date
                ? moment(newCarrier.insurance_expiry_date)
                : null
            }
            onChange={onInsuranceExpiryDateChange}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{CARGO_AMOUNT}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => newCarrier.setInsuranceCargoAmount(e.target.value)}
            value={newCarrier.insurance_cargo_amount}
          />
        </div>
        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{LIABILITY_AMOUNT}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) =>
              newCarrier.setInsuranceLiabilityAmount(e.target.value)
            }
            value={newCarrier.insurance_liability_amount}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(InsuranceDetails);
