import React from 'react';

import { Tooltip } from 'antd';
import {
  DELIVERY_LOCATION,
  EDIT_SHIPMENT,
  NOT_AVAILABLE_SHORT,
  SHIPMENT_NO,
  PICKUP_LOCATION,
  SELECT_SHIPMENT,
  STATUS,
} from '../../../constants';
import { CURRENT_ACTION } from '../../../constants/dispatch';
import editIcon from '../../../assets/images/edit-equipment.svg';
import selectIcon from '../../../assets/images/select-icon.svg';
import { rootInstance } from '../../RootModel';
import PageRoutes from '../../../utils/PageRoutes';

const onShipmentSwitch = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsOrganizeTourShipmentsModalOpen(false);
  rootInstance.dispatchPageState.beforeEnterSetSelectedShipment(
    record.shipment_id
  );
  rootInstance.dispatchPageState?.routeActionItem?.setSelectedShipmentID(
    record.shipment_id
  );
};
const onShipmentEdit = (e, record) => {
  e.stopPropagation();
  const params = { shipmentId: record.shipment_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditShipment.name),
    params
  );
};

export const OrganizeTourShipmentsModalColumns = (rootInstance) => ({
  '1': {
    title: SHIPMENT_NO,
    dataIndex: 'shipment_number',
    key: 'shipment_number',
    width: 100,
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '3': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      let color;
      switch (text) {
        case 'scheduled':
          color = 'text-blue';
          break;
        case 'pending assignment':
        case 'canceled':
          color = 'text-dkred';
          break;
        case 'on time':
          color = 'text-green';
          break;
        case 'delayed':
          color = 'text-orange';
          break;
      }
      return (
        <span className={`${color} text-capitalize text-bold`}>{text}</span>
      );
    },
  },
  '4': {
    title: CURRENT_ACTION,
    dataIndex: 'all_actions',
    key: 'all_actions',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '5': {
    title: PICKUP_LOCATION,
    dataIndex: 'pickup_location',
    key: 'pickup_location',
    render: (text, record) => {
      return (
        <p>
          <span className="flex text-bold text-black">
            {record?.pickup_location?.company
              ? record?.pickup_location?.company
              : NOT_AVAILABLE_SHORT}
          </span>
          {record?.pickup_location
            ? record?.pickup_location?.city
            : NOT_AVAILABLE_SHORT}
          {record?.pickup_location
            ? ', ' + record?.pickup_location?.state
            : NOT_AVAILABLE_SHORT}
        </p>
      );
    },
  },
  '6': {
    title: DELIVERY_LOCATION,
    dataIndex: 'delivery_location',
    key: 'delivery_location',
    render: (text, record) => {
      return (
        <p>
          <span className="flex text-bold text-black">
            {record?.delivery_location?.company
              ? record?.delivery_location?.company
              : NOT_AVAILABLE_SHORT}
          </span>
          {record?.delivery_location
            ? record?.delivery_location?.city
            : NOT_AVAILABLE_SHORT}
          {record?.delivery_location
            ? ', ' + record?.delivery_location?.state
            : NOT_AVAILABLE_SHORT}
        </p>
      );
    },
  },
  '9': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          <Tooltip
            placement="top"
            title={EDIT_SHIPMENT + ':' + record.shipment_id}
            className="align-center"
          >
            <img
              style={{ cursor: 'pointer' }}
              onClick={(e) => onShipmentEdit(e, record)}
              src={editIcon}
              alt=""
            />
          </Tooltip>
          <Tooltip
            placement="top"
            title={SELECT_SHIPMENT + ':' + record.shipment_id}
            className="align-center"
          >
            <img
              className="ml10"
              style={{ cursor: 'pointer' }}
              onClick={(e) => onShipmentSwitch(e, record)}
              src={selectIcon}
              alt=""
            />
          </Tooltip>
        </div>
      );
    },
  },
});
