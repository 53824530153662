import React from 'react';
import { observer } from 'mobx-react';
import Login from '../../components/login/LoginComponent';
import BottomMenu from '../../components/login/BottomMenu';
import ContentContainer from '../../components/login/ContentContainer';
import bgImage from '../../assets/images/bg-image.png';
import { BACKGROUND } from '../../constants';

const LoginPage = () => {
  return (
    <div className="login-layout wrapper">
      <div className="background-image">
        <img src={bgImage} alt={BACKGROUND} />
      </div>
      <div className="login-layout-content">
        <Login />
        <ContentContainer />
        <BottomMenu />
      </div>
    </div>
  );
};

export default observer(LoginPage);
