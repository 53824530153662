import React from 'react';
import { observer } from 'mobx-react';
import { FUEL, SPEED } from '../../../constants/dispatch';
import {
  CURRENT_LOCATION,
  NOT_AVAILABLE_SHORT,
  TRUCK_INFORMATION,
} from '../../../constants';

const AssetsListTruckTableDetails = (props) => {
  const { data } = props;
  return (
    <div className="dispatch-trip-modal__driver-details">
      <div className="top_details">
        <span className="text-bold text-uppercase">{TRUCK_INFORMATION}</span>
        {/*<span className='text-dkred text-capitalize ml15 d-iblock'>Registration expired</span>*/}
      </div>
      <div className="flex-start flex-wrap">
        <div className="dispatch-trip-modal__driver-details-item w20 has-border-right">
          <div className="align-center">
            <div>
              <p className="text-bold text-uppercase color-light-gray">
                {FUEL}
              </p>
              <span className="text-black">
                {data?.fuel ? data?.fuel + '%' : NOT_AVAILABLE_SHORT}
              </span>
            </div>
            <div className="ph20">
              <p className="text-bold text-uppercase color-light-gray">
                {SPEED}
              </p>
              <span className="text-black">
                {data?.speed ? data?.speed + 'mph' : NOT_AVAILABLE_SHORT}
              </span>
            </div>
          </div>
        </div>
        <div className="dispatch-trip-modal__driver-details-item w50 ph20 align-center">
          <div className="w100">
            <p className="text-bold text-uppercase color-light-gray">
              {TRUCK_INFORMATION}
            </p>
            <span className="text-black">
              {data?.internal_company_id + ' - ' + data?.vehicle_make}
            </span>
          </div>
          <div className="w100">
            <p className="text-bold text-uppercase color-light-gray">
              {CURRENT_LOCATION}
            </p>
            <span className="text-black">
              {data?.location_description
                ? data?.location_description
                : NOT_AVAILABLE_SHORT}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AssetsListTruckTableDetails);
