import React from 'react';
import { useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import EditEmployeeComponent from '../../components/employees/EditEmployee/EditEmployeeComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import {
  EDIT_EMPLOYEE,
  MODAL_SUCCESS_EDIT_EMPLOYEE_TEXT,
  MODAL_SUCCESS_TITLE,
  MODAL_SUCCESS_EDIT_EMPLOYEE_BUTTON_TEXT,
} from '../../constants';
import SuccessModal from '../../components/SuccessModal';
import AddAbsenceModal from '../../components/employees/EditEmployee/AddAbsenceModal';
import EditAbsenceModal from '../../components/employees/EditEmployee/EditAbsenceModal';
import ConfirmDeleteAbsenceModal from '../../components/employees/EditEmployee/ConfirmDeleteAbsenceModal';

const EditEmployeePage = () => {
  const {
    employeesPageState: { editedEmployee },
  } = useMst();

  const backLink = PageRoutes.EditEmployee.name;

  const params = { employeeId: editedEmployee?.user_id.toString() };

  return (
    editedEmployee && (
      <div>
        <div className="page edit-employee-page">
          <BreadcrumsComponent
            page={EDIT_EMPLOYEE}
            breadcrumbsBackLink={PageRoutes.Employees.name}
          />
          <SuccessModal
            title={MODAL_SUCCESS_TITLE}
            text={MODAL_SUCCESS_EDIT_EMPLOYEE_TEXT}
            buttonText={MODAL_SUCCESS_EDIT_EMPLOYEE_BUTTON_TEXT}
            backLink={backLink}
            params={params}
            open={editedEmployee.successModalOpen}
            closeModal={() => editedEmployee.setSuccessModalOpen(false)}
          />
          <AddAbsenceModal />
          <EditAbsenceModal />
          <ConfirmDeleteAbsenceModal />
          <div className="page-content">
            <EditEmployeeComponent />
          </div>
        </div>
      </div>
    )
  );
};

export default observer(EditEmployeePage);
