import React from 'react';
import {
  EMAIL,
  PHONE_NUMBER,
  CITY_STATE,
  DELETE_DRIVER,
  EDIT_DRIVER,
  COPY_TO_CLIPBOARD,
  CODE_NAME,
  CATEGORY,
  CLASS,
  LABEL_ID,
  STATUS,
} from '../../../constants';
import trash from '../../../assets/images/trash.svg';
import edit from '../../../assets/images/edit.svg';
import copy from '../../../assets/images/copy.svg';
import { Tooltip } from 'antd';
import PageRoutes from '../../../utils/PageRoutes';
import Link from '../../../components/Link';
import { snakeCaseToString, copyToClipboard } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.fleetPageState.setDriverToBeDeleted(record.driver_id);
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  const params = { driverId: record.driver_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditDriver.name),
    params
  );
};

const onClickCopy = (e, record) => {
  e.stopPropagation();
  copyToClipboard(record.email);
};

export const driversColumnsFunc = (rootInstance) => ({
  '1': {
    title: LABEL_ID,
    dataIndex: 'internal_company_id',
    key: 'internal_company_id',
    render: (text, record) => {
      return <span>{text ? text : '/'}</span>;
    },
  },
  '2': {
    title: CLASS,
    dataIndex: 'driver_type',
    key: 'driver_type',
    render: (text, record) => {
      return <span>{snakeCaseToString(text)}</span>;
    },
  },
  '3': {
    title: CODE_NAME,
    key: 'code_name',
    dataIndex: 'code_name',
    render: (text, record) => {
      return <span>{text ? text : '/'}</span>;
    },
  },
  '4': {
    title: CATEGORY,
    key: 'driver_category',
    dataIndex: 'driver_category',
    render: (text, record) => {
      return <span className="text-capitalize">{text ? text : '/'}</span>;
    },
  },
  '5': {
    title: EMAIL,
    key: 'email',
    dataIndex: 'email',
    render: (text, record) => {
      return (
        text && (
          <div className="align-center">
            <span>{record.email}</span>
            <Tooltip title={COPY_TO_CLIPBOARD}>
              <img
                src={copy}
                alt="copy"
                className="pointer ph10"
                onClick={(e) => {
                  onClickCopy(e, record);
                }}
              />
            </Tooltip>
          </div>
        )
      );
    },
  },
  '6': {
    title: PHONE_NUMBER,
    key: 'phone_number',
    dataIndex: 'phone_number',
    render: (text, record) => {
      return <span>{text ? text : '/'}</span>;
    },
  },
  '7': {
    title: CITY_STATE,
    dataIndex: 'city_state',
    key: 'city_state',
    render: (text, record) => {
      return (
        <span>
          {`${record.city && record.city + ' / '}${
            record.state && record.state
          }`}
        </span>
      );
    },
  },
  '8': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      return (
        <div className="align-center">
          <span className="text-capitalize">{text}</span>
          <span
            className={`status-dot ml10 ${
              record.status === 'Available' ? '' : 'unavailable'
            }`}
          ></span>
        </div>
      );
    },
  },
  '9': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          {rootInstance.userState.canRemoveDrivers && (
            <Tooltip title={DELETE_DRIVER}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          )}
          {rootInstance.userState.canEditDrivers && (
            <Link
              view={PageRoutes.EditDriver.name}
              params={{ driverId: record.driver_id }}
              onClick={(e) => onClickEdit(e, record)}
            >
              <Tooltip title={EDIT_DRIVER}>
                <img src={edit} alt="edit" className="pointer ph10" />
              </Tooltip>
            </Link>
          )}
        </div>
      );
    },
  },
});
