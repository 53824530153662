import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Select } from 'antd';
import {
  COMPENSATION_NAME,
  COMPENSATION_PERIOD,
  COMPENSATION_STRUCTURE_INFORMATION,
  COMPENSATION_TYPE,
} from '../../../constants/payroll';

const { Option } = Select;

const AddCompensationForm = () => {
  const {
    payrollPageState: {
      editedCompensation,
      getAllCompensationPaymentPeriods,
      editedCompensationName,
      editedCompensationPeriod,
      setEditedCompensationName,
      setEditedCompensationPeriod,
    },
  } = useMst();

  return (
    <div className="form-page-section">
      <div className="form-section-title-row pb10 mb10">
        <span className="form-section-title">
          {COMPENSATION_STRUCTURE_INFORMATION}
        </span>
      </div>
      <div className="section-content flex-alignitems-flex-start pb20">
        <div className="input-wrapper ml0 w30">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_NAME}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Input
            type="text"
            className="add-edit-input"
            onChange={(e) => setEditedCompensationName(e.target.value)}
            value={editedCompensationName}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_TYPE}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Input
            disabled
            type="text"
            className="add-edit-input"
            value={editedCompensation?.compensation_type}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_PERIOD}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Select
            className={'w100'}
            onChange={(ct) => setEditedCompensationPeriod(ct)}
            value={editedCompensationPeriod}
          >
            {getAllCompensationPaymentPeriods.map((ct, index) => (
              <Option key={index} value={ct}>
                {ct}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default observer(AddCompensationForm);
