import React from 'react';
import { observer } from 'mobx-react';
import { ADD_MAINTENANCE, MAINTENANCE_HISTORY } from '../../../../constants';
import EditMaintenanceTable from './EditMaintenanceTable';

const onAddMaintenance = (vehicle) => {
  vehicle.setNewMaintenance({ vehicle_id: vehicle.vehicle_id });
};

const EditMaintenanceSection = ({ model }) => {
  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-section-title-row align-center">
        <span className="form-section-title">{MAINTENANCE_HISTORY}</span>
        <button
          className="btn primary ml20 ph20 pv0"
          onClick={() => onAddMaintenance(model)}
          disabled={!!model.newMaintenance}
        >
          {ADD_MAINTENANCE}
        </button>
      </div>
      <EditMaintenanceTable model={model} />
    </div>
  );
};

export default observer(EditMaintenanceSection);
