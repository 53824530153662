import { Empty, Input, Select, Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ADD, ALL, DISCARD } from '../../../constants';
import {
  DEDUCTIONS,
  EMPTY_COMPENSATION_DEDUCTIONS_TABLE_TEXT,
  EMPTY_COMPENSATION_VALUES_TABLE_TEXT,
  FIRST_COLUMN,
  LAST_COLUMN,
  VALUES,
} from '../../../constants/payroll';
import { useMst } from '../../../state/RootModel';

const { Option } = Select;
const CompensationValuesSection = () => {
  const {
    fleetPageState: { editedDriver, getAllCompensationsArray },
  } = useMst();

  return (
    <div className="add-driver-section form-page-section">
      <div className="input-wrapper ml0 mb20 w30">
        <span>Select compensation:</span>
        <Select
          className={'w100 mt10'}
          onChange={(ct) => {
            let compensation = getAllCompensationsArray.filter((comp) => {
              return comp.compensation_id === ct;
            });
            editedDriver.setSelectedCompensationToAdd(compensation[0]);
          }}
          defaultValue={editedDriver.selectedCompensationToAdd?.compensation_id}
        >
          {getAllCompensationsArray.map((ct, index) => (
            <Option key={index} value={ct.compensation_id}>
              {ct.compensation_name}
            </Option>
          ))}
        </Select>
      </div>
      {editedDriver.selectedCompensationToAdd && (
        <Table
          className="compensations-table"
          columns={editedDriver
            .getCompensationValuesOrDeductionsTableColumns(VALUES)
            .slice(FIRST_COLUMN, LAST_COLUMN)}
          dataSource={editedDriver.selectedCompensationToAdd?.compensationsValuesArray.map(
            (el) => (el.based_on === null ? { ...el, based_on: 'N/A' } : el)
          )}
          pagination={false}
          rowKey={(record, index) => index.toString()}
          rowClassName={() => 'compensation-table-expanded-row'}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_COMPENSATION_VALUES_TABLE_TEXT}
              />
            ),
          }}
        />
      )}
      {editedDriver.selectedCompensationToAdd && (
        <Table
          className="compensations-table"
          columns={editedDriver
            .getCompensationValuesOrDeductionsTableColumns(DEDUCTIONS)
            .slice(FIRST_COLUMN, LAST_COLUMN)}
          dataSource={editedDriver.selectedCompensationToAdd?.compensationsDeductionsArray.map(
            (el) => (el.based_on === null ? { ...el, based_on: 'N/A' } : el)
          )}
          pagination={false}
          rowKey={(record, index) => index.toString()}
          rowClassName={() => 'compensation-table-expanded-row'}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_COMPENSATION_DEDUCTIONS_TABLE_TEXT}
              />
            ),
          }}
        />
      )}
    </div>
  );
};

export default observer(CompensationValuesSection);
