import { rootInstance } from '../../state/RootModel';
const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getCurrentUserAccount = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/account`, config);
};

export const getAllAccountDocumentation = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/account/documentation`, config);
};

export const getAccountAvailableRoles = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/account/roles`, config);
};

export const getAccountActiveRole = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/account/active_role`, config);
};

export const changeAccountActiveRole = (roleId) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/account/active_role?role_id=${roleId}`,
    {},
    config
  );
};

export const uploadAccountPhoto = (file) => {
  const config = getConfig();
  return axios.post(`${rootInstance.baseUrl}/account/photo`, file, config);
};

export const deleteAccountPhoto = (photo_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/account/photo/${photo_id}`,
    config
  );
};

export const getCurrentUserPermissions = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/account/permissions`, config);
};
