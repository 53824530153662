import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import {
  ADD_DEFAULT_ORGANIZATION_LOCATION,
  EDIT_DEFAULT_ORGANIZATION_LOCATION,
  LOCATION_ORGANIZATION_INFORMATION,
} from '../../../constants/organizations/organizations';

const EditOrganizationLocation = () => {
  const {
    organizationState: { editedOrganization },
  } = useMst();

  const onLaunchDefaultLocationCreation = () => {
    editedOrganization.setNewLocation({});
    editedOrganization.loadLocationClient();
  };

  const onLaunchDefaultLocationEdit = () => {
    editedOrganization.getLocationToEdit();
    editedOrganization.loadLocationClient();
  };

  return (
    <div className="add-trailer-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">
          {LOCATION_ORGANIZATION_INFORMATION}
        </span>
      </div>
      {editedOrganization?.organizationDefaultLocation && (
        <div className="w70">
          <h4 className="ph10">Current default location details:</h4>
          <div className="section-content flex-alignitems-flex-start pb20 justify-between">
            {editedOrganization?.organizationDefaultLocation?.location_name && (
              <div className="view-page-item-wrapper">
                <p className="label">Name:</p>
                <p className="content">
                  {
                    editedOrganization?.organizationDefaultLocation
                      ?.location_name
                  }
                </p>
              </div>
            )}
            {editedOrganization?.organizationDefaultLocation?.address && (
              <div className="view-page-item-wrapper">
                <p className="label">Address:</p>
                <p className="content">
                  {editedOrganization?.organizationDefaultLocation?.address}
                </p>
              </div>
            )}
            {editedOrganization?.organizationDefaultLocation?.city && (
              <div className="view-page-item-wrapper">
                <p className="label">City:</p>
                <p className="content">
                  {editedOrganization?.organizationDefaultLocation?.city}
                </p>
              </div>
            )}
            {editedOrganization?.organizationDefaultLocation?.state && (
              <div className="view-page-item-wrapper">
                <p className="label">State:</p>
                <p className="content">
                  {editedOrganization?.organizationDefaultLocation?.state}
                </p>
              </div>
            )}
            {editedOrganization?.organizationDefaultLocation?.country && (
              <div className="view-page-item-wrapper">
                <p className="label">Country:</p>
                <p className="content">
                  {editedOrganization?.organizationDefaultLocation?.country}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {!editedOrganization?.hasCompany &&
        !editedOrganization?.default_location_id && (
          <p>
            Please fill in next fields so that you can add default location for
            this organization:
            <span className="text-dkred">
              Organization email, Organization phone number, Organization state,
              Organization city, Organization address, Organization country
            </span>
          </p>
        )}
      {!editedOrganization?.default_location_id &&
        editedOrganization?.hasCompany && (
          <button
            className="btn primary mt15"
            onClick={onLaunchDefaultLocationCreation}
          >
            {ADD_DEFAULT_ORGANIZATION_LOCATION}
          </button>
        )}
      {editedOrganization?.default_location_id && (
        <button className="btn primary" onClick={onLaunchDefaultLocationEdit}>
          {EDIT_DEFAULT_ORGANIZATION_LOCATION}
        </button>
      )}
    </div>
  );
};

export default observer(EditOrganizationLocation);
