import {
  getCompensationBasedOnTypes,
  getCompensationDeductionTypes,
  getCompensationPaymentPeriods,
  getCompensationPaymentRates,
  getAllCompensationTypes,
  getCompensationValuesByType,
  getCompensationCurrencies,
  deleteCompensationById,
  deleteCompensationValueById,
  deleteCompensationDeductionById,
  editCompensation,
  editCompensationValue,
  editCompensationDeduction,
  addValuesToCompensation,
  addDeductionsToCompensation,
} from '../../actions/payroll';

export const getCompensationTypesPromise = (model) => {
  return new Promise((resolve, reject) => {
    getAllCompensationTypes()
      .then((response) => {
        model.setNewCompensationTypes(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompensationPaymentPeriodsPromise = (model) => {
  return new Promise((resolve, reject) => {
    getCompensationPaymentPeriods()
      .then((response) => {
        model.setNewCompensationPaymentPeriods(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompensationBasedOnTypesPromise = (model) => {
  return new Promise((resolve, reject) => {
    getCompensationBasedOnTypes()
      .then((response) => {
        model.setNewCompensationBasedOnTypes(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompensationPaymentRatesPromise = (model) => {
  return new Promise((resolve, reject) => {
    getCompensationPaymentRates()
      .then((response) => {
        model.setNewCompensationPaymentRates(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompensationDeductionTypesPromise = (model) => {
  return new Promise((resolve, reject) => {
    getCompensationDeductionTypes()
      .then((response) => {
        model.setNewCompensationDeductionTypes(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompensationValuesByTypePromise = (
  compensation_type: string
) => {
  return new Promise((resolve, reject) => {
    getCompensationValuesByType(compensation_type)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCompensationCurrenciesPromise = (model) => {
  return new Promise((resolve, reject) => {
    getCompensationCurrencies()
      .then((response) => {
        model.setCompensationCurrencies(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCompensationPromise = (id) => {
  return new Promise((resolve, reject) => {
    deleteCompensationById(id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCompensationValuePromise = (
  commpensation_id,
  compensation_value_id
) => {
  return new Promise((resolve, reject) => {
    deleteCompensationValueById(commpensation_id, compensation_value_id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteCompensationDeductionPromise = (
  commpensation_id,
  compensation_deduction_id
) => {
  return new Promise((resolve, reject) => {
    deleteCompensationDeductionById(commpensation_id, compensation_deduction_id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editCompensationPromise = (
  commpensation_id,
  editedCompensationName,
  editedCompensationPeriod
) => {
  return new Promise((resolve, reject) => {
    editCompensation(
      commpensation_id,
      editedCompensationName,
      editedCompensationPeriod
    )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editCompensationValuePromise = (commpensation_id, editedValue) => {
  return new Promise((resolve, reject) => {
    editCompensationValue(commpensation_id, editedValue)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editCompensationDeductionPromise = (
  commpensation_id,
  editedDeduction
) => {
  return new Promise((resolve, reject) => {
    editCompensationDeduction(commpensation_id, editedDeduction)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addValuesToCompensationPromise = (commpensation_id, values) => {
  return new Promise((resolve, reject) => {
    addValuesToCompensation(commpensation_id, values)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addDeductionsToCompensationPromise = (
  commpensation_id,
  deductions
) => {
  return new Promise((resolve, reject) => {
    addDeductionsToCompensation(commpensation_id, deductions)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
