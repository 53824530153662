export const TRACKING_SHIPMENT = 'Tracking shipment:';
export const LEGEND = 'Legend';
export const FILTERS = 'Filters';
export const PICKUP_TYPE = 'pickup';
export const DELIVERY_TYPE = 'delivery';
export const PICKUP = 'Pickup';
export const DELIVERY = 'Delivery';
export const START = 'Start';
export const STOP = 'Stop';
export const LOAD_BOARD_SEARCH = 'Load board search';
export const SHOW_YARD = 'Show Yard';
export const SHOW = 'Show';
export const YARD = 'yard';
export const PENTAGON_LOGISTICS = 'Pentagon Logistics, CA';
export const TRACK_SHIPMENTS = 'Track shipments';
export const TRACK_SHIPMENT = 'Track shipment';
export const SHIPMENTS_LIST = 'Shipments list';
export const LINK_SHIPMENT = 'Link shipment';
export const ROUTE = 'Route';
export const ROUTES = 'Routes';
export const ALL_SHIPMENT_ACTIONS = 'All shipment actions';
export const ACTIONS = 'Actions';
export const TOUR_DETAILS = 'Tour details';
export const ORGANIZE_TOUR = 'Organize tour';
export const CHOOSE_DRIVER = 'Choose driver';
export const CHOOSE_TEAM_DRIVER = 'Choose team driver';
export const SELECT_DRIVER = 'Select driver';
export const ADD_TRIP = 'Add trip';
export const ADD_ACTION = 'Add action';
export const VEHICLE_ACTIONS = 'All route actions';
export const SAVE_ACTION = 'Save action';
export const START_DESTINATION = 'Start Destination';
export const END_DESTINATION = 'End Destination';
export const DRIVER_REQUIRED_MESSAGE = 'Driver required';
export const START_LOCATION_MESSAGE = 'Start location required';
export const END_LOCATION_MESSAGE = 'End location required';
export const PLANNED_START_DATE_REQUIRED_MESSAGE =
  'Planned start date required';
export const PLANNED_START_TIME_REQUIRED_MESSAGE =
  'Planned start time required';
export const ACTION_REQUIRED_MESSAGE = 'Action required';
export const LOCATION_REQUIRED_MESSAGE = 'Location required';
export const ONE_LOCATION_REQUIRED_MESSAGE = 'At least one location required';
export const ROUTE_REQUIRED_MESSAGE = 'Route action required';
export const ASSIGN_TRAILER = 'Assign trailer';
export const CHOOSE_TRAILER = 'Choose trailer';
export const CHOOSE_TRUCK = 'Choose truck';
export const DEFINE_RADIUS = 'Define radius';
export const ENTER_RADIUS = 'Enter radius';
export const EMPTY_AVAILABLE_TRAILERS_TABLE_TEXT = 'No available trailers';
export const EMPTY_AVAILABLE_DRIVERS_TABLE_TEXT = 'No available drivers';
export const EMPTY_AVAILABLE_TRUCKS_TABLE_TEXT = 'No available trucks';
export const EMPTY_AVAILABLE_PLANNER_TABLE_TEXT = 'No available planner data';
export const EMPTY_AVAILABLE_TRIPS_TABLE_TEXT = 'No available trips';
export const EMPTY_AVAILABLE_CUSTOMERS_TABLE_TEXT = 'No available customers';
export const EMPTY_AVAILABLE_ROUTE_ACTIONS_TABLE_TEXT =
  'No available route actions';
export const LOADED_MILES = 'Loaded Miles';
export const DRIVERS_LIST = 'Drivers list';
export const TRAILERS_LIST = 'Trailers list';
export const TRUCKS_LIST = 'Trucks list';
export const CODE_NAME = 'Code name';
export const CURRENT_SHIPMENT = 'Current shipment';
export const CURRENT_EVENT = 'Current event';
export const DISTANCE = 'Distance';
export const EMPTY_MILES = 'Empty miles';
export const HOS = 'Hours of Service';
export const ID = 'ID';
export const ETA = 'Eta';
export const ETA_FOR_CURRENT_EVENT = 'Eta for current event';
export const FUEL = 'Fuel';
export const SPEED = 'Speed';
export const DEF = 'DEF';
export const BREAK = 'Break';
export const DRIVE = 'Drive';
export const SHIFT = 'Shift';
export const CYCLE = 'Cycle';
export const TODAY = 'Today';
export const UPCOMING = 'Upcoming';
export const REMAINING = 'Remaining';
export const TOMORROW = 'Tomorrow';
export const ESTIMATED_TIME_OF_ARRIVAL = 'Estimated time of arrival';
export const SAVE_TRIP = 'Save trip';
export const ADD_TRIP_EVENT = 'Add trip event';
export const LAST_EVENT = 'Last Event';
export const LAST_TRIP_EVENTS = 'Last Trip Events';
export const AVAILABLE_FROM = 'Available From';
export const AVAILABLE_AT = 'Available At';
export const ESTIMATED_FINISH = 'Estimated Finish';
export const MILES_FROM_PICKUP = 'Mi From Pickup';
export const SEE_ON_MAP = 'See on map';
export const LINKED_DRIVER = 'Linked driver';
export const DISTANCE_FROM_DRIVER = 'Distance from Selected Driver';
export const SCHEDULED_PICKUP_DATE_TIME = 'Scheduled Pickup Date & Time';
export const PICKUP_DATE_TIME = 'Pickup Date/Time';
export const PICKUP_DATE = 'Pickup date';
export const DELIVERY_DATE_TIME = 'Delivery Date/Time';
export const TRIP_BOARD = 'Trip board';
export const DATE_TIME = 'Date & Time';
export const DRIVER_TRUCK = 'Driver / Truck';
export const TRIP_ID_TYPE = 'Trip ID / Type';
export const CREATE_ACTION = 'CREATE ACTION';
export const SHIPMENT_LIST = 'Shipment list';
export const SHIPMENT_LIST_PLANNED = 'Planned';
export const SHIPMENT_LIST_SCHEDULED = 'Scheduled';
export const ACTIVE_TOURS = 'Active tours';
export const INACTIVE_TOURS = 'Inactive tours';
export const CURRENT_TRIP_EVENT = 'Current Trip Event';
export const CURRENT_ACTION = 'Current Action';
export const ALL_ACTIONS = 'All Actions';
export const NO_SHIPMENT_SELECTED = 'No shipment selected';
export const CUSTOMERS_LIST = 'Customers list';
export const VIEW_AND_TRACK_ON_MAP = 'VIEW AND TRACK ON MAP';
export const VIEW_VEHICLE_ROUTE_ACTIONS = 'View vehicle route actions';
export const DISPATCHER = 'Dispatcher';
export const P_S = 'P/S'; //Pickup State
export const D_S = 'D/S'; //Delivery State
export const HOURS = 'Hours'; //Delivery State
export const MILES = 'Miles'; //Delivery State
export const LOCAL_PICKUP = 'Local pickup';
export const LOCAL_DELIVERY = 'Local delivery';
export const DELIVERY_PICKUP = 'Delivery pickup';
export const EDIT_P_AND_D = 'Edit P&D';
export const NO_LOCATIONS_LOADED = 'No locations loaded.';
export const CURRENT_INFORMATION = 'Current information';
export const MILES_REQUIRED_MESSAGE = 'Miles required';
