export const ORGANIZATION = 'Organization';
export const SEARCH_ORGANIZATIONS = 'Search organizations';
export const EMPTY_ORGANIZATION_TABLE_TEXT = 'No organizations.';
export const ORGANIZATION_NO = 'Organization No.';
export const ORGANIZATION_NAME = 'Organization Name';
export const ORGANIZATION_DELETE = 'Delete organization';
export const ORGANIZATION_EDIT = 'Edit organization';
export const ORGANIZATION_ADMIN = 'Organization admin';
export const ORGANIZATION_EMAIL = 'Organization email';
export const ORGANIZATION_ADMIN_EMAIL = 'Admin email address';
export const ORGANIZATION_ADMIN_FIRSTNAME = 'Admin firstname';
export const ORGANIZATION_ADMIN_LASTNAME = 'Admin lastname';
export const ORGANIZATION_SUPER_ADMIN = 'Super admin';
export const ORGANIZATION_DESCRIPTION = 'Organization description';
export const ORGANIZATION_CITY = 'Organization city';
export const ORGANIZATION_ZIP = 'Organization ZIP';
export const ORGANIZATION_PHONE = 'Organization phone number';
export const ORGANIZATION_ADDRESS = 'Organization address';
export const ORGANIZATION_STATE = 'Organization state';
export const ORGANIZATION_COUNTRY = 'Organization country';
export const ORGANIZATION_WEBSITE = 'Organization website';
export const ORGANIZATION_CHANGE_SUPER_ADMIN =
  'Change organization super admin';
export const ADD_NEW_ORGANIZATION = 'Add new organization';
export const MC = 'MC#';
export const DOT = 'DOT#';
export const CVOR = 'CVOR#';
export const FEIN = 'FEIN';
export const DUNS_NUMBER = 'Duns number';
export const SCAC = 'SCAC';
export const CBSA = 'CBSA';
export const CTPAT_NUMBER = 'Ctpat number';
export const PIP = 'PIP';
export const FAST = 'FAST';

/** Other **/
export const ADDITIONAL_ORGANIZATION_INFORMATION = 'Additional information';
export const BRANDING_ORGANIZATION_INFORMATION = 'Logo and Thumbnail';
export const LOCATION_ORGANIZATION_INFORMATION = 'Default location';
export const ORGANIZATION_EMAIL_INVALID = 'Organization email not valid.';
export const ORGANIZATION_ADMIN_EMAIL_INVALID =
  'Organization admin email required.';
export const DELETE_ORGANIZATION = 'Delete organization';
export const EDIT_ORGANIZATION = 'Edit organization';
export const CONFIRM_DELETING_ORGANIZATION =
  'Are you sure that you want to remove selected organization?';

export const MODAL_SUCCESS_CREATE_ORGANIZATION_TEXT =
  'Organization has been successfully created.';
export const MODAL_SUCCESS_EDIT_ORGANIZATION_TEXT =
  'Organization has been successfully edited.';
export const MODAL_SUCCESS_ORGANIZATION_BUTTON_TEXT = 'Back to organizations';

export const ADD_DEFAULT_ORGANIZATION_LOCATION =
  'Add default organization location';
export const EDIT_DEFAULT_ORGANIZATION_LOCATION =
  'Edit default organization location';
export const DEFAULT_LOCATION_ADDED =
  'Organization default location has been added';

/** Required **/
export const ORGANIZATION_NAME_REQUIRED = 'Organization name required';
export const ORGANIZATION_PHONE_REQUIRED = 'Organization phone required';
export const ORGANIZATION_STATE_REQUIRED = 'Organization state required';
export const ORGANIZATION_COUNTRY_REQUIRED = 'Organization country required';
export const ORGANIZATION_ADDRESS_REQUIRED = 'Organization address required';
export const ORGANIZATION_CITY_REQUIRED = 'Organization city required';
export const ORGANIZATION_ADMIN_EMAIL_REQUIRED =
  'Organization admin email required';
export const ORGANIZATION_EMAIL_REQUIRED = 'Organization email required';
export const ORGANIZATION_ADMIN_FIRSTNAME_REQUIRED =
  'Organization admin firstname required';
export const ORGANIZATION_ADMIN_LASTNAME_REQUIRED =
  'Organization admin lastname required';
export const ORGANIZATION_OTHER_FIELDS_REQUIRED =
  'One of these fields are required: MC#, DOT#, CVOR#';
