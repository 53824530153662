import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Select } from 'antd';
import { ASSOCIATIONS, LINK_TO_TRUCK } from '../../../constants';
import { getRandomInt } from '../../../utils/methods';

const { Option } = Select;

const Associations = () => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  const onAssociationChange = (value) => {
    let association = editedDriver.trucksAvailableForAssociation?.find(
      (vehicle) => {
        return vehicle.vehicle_id === value;
      }
    );
    if (!association.association_id) {
      association.association_id = getRandomInt();
    }
    association.internal_company_id = +association.internal_company_id;
    editedDriver.setAssociation(association);
  };

  return (
    <div className="form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{ASSOCIATIONS}</span>
      </div>

      <div className="section-content associations-section  align-center">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{LINK_TO_TRUCK}</span>
          </div>
          <Select
            className="w100 text-capitalize"
            value={editedDriver.association?.vehicle_id}
            onChange={onAssociationChange}
          >
            {editedDriver.trucksAvailableForAssociation?.map((vehicle) => (
              <Option
                key={vehicle.vehicle_id}
                value={vehicle.vehicle_id}
                className="text-capitalize"
              >
                {+vehicle.internal_company_id} / {vehicle.vehicle_make} /{' '}
                {vehicle.ownership_type}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default observer(Associations);
