import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Tabs } from 'antd';
import ErrorBox from '../../ErrorBox';
import { useMst } from '../../../state/RootModel';
import addFileIcon from '../../../assets/images/add-file.svg';
import FormPageTitle from '../../FormPageTitle';
import { ADD_STOP, CARGO, GENERAL } from '../../../constants';
import CargoTabComponent from './addProbill/CargoTab';
import GeneralTabComponent from './addProbill/GeneralTab';

const { TabPane } = Tabs;

const AddProbillModal = () => {
  const {
    shipmentsPageState: { currentShipment },
  } = useMst();

  const onSaveChanges = () => {
    currentShipment.newProbill.saveProbill();
  };

  const onCancel = () => {
    currentShipment.setNewProbill(null);
  };

  const onTabChange = (key) => {
    currentShipment.newProbill.setActiveTab(key);
  };

  return (
    <div>
      <Modal
        visible={!!currentShipment?.newProbill}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={700}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addFileIcon} title={ADD_STOP} />
          </div>

          <Tabs
            activeKey={currentShipment?.newProbill?.activeTab}
            onChange={onTabChange}
          >
            <TabPane tab={GENERAL} key={GENERAL}>
              <GeneralTabComponent />
            </TabPane>
            <TabPane tab={CARGO} key={CARGO}>
              <CargoTabComponent />
            </TabPane>
          </Tabs>

          <ErrorBox obj={currentShipment?.newProbill} />

          <button className="btn primary" onClick={onSaveChanges}>
            {ADD_STOP}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddProbillModal);
