import { types } from 'mobx-state-tree';
import { DocumentModel } from '../../DocumentModel';
import {
  COMPLIANCY_DESCRIPTION_REQUIRED,
  COMPLIANCY_TYPE_REQUIRED,
  EXPIRATION_DATE_REQUIRED,
} from '../../../constants';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';
import {
  deleteDriverDocumentPromise,
  editDriverCompliancy,
} from '../../../promises/fleet';
import { uploadDriverCompliancyDocument } from '../../../actions/fleet';

export const CompliancyModel = types
  .model('CompliancyModel', {
    compliancy_id: types.identifierNumber,
    compliancy_description: types.maybe(types.maybeNull(types.string)),
    compliancy_type: types.maybe(types.maybeNull(types.string)),
    expiration_date: types.maybe(types.maybeNull(types.string)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    errorText: types.maybe(types.maybeNull(types.string)),
    is_active: types.maybe(types.maybeNull(types.boolean)),
    document: types.maybe(types.maybeNull(DocumentModel)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setType(type) {
        self.compliancy_type = type;
      },
      setDescription(text) {
        self.compliancy_description = text;
      },
      setExpirationDate(date) {
        self.expiration_date = date;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setDocument(item) {
        item.uid = item.document_id;
        item.name = item.document_name;
        item.url = item.document_url;
        self.document = item;
      },
      throwEditCompliancyError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.compliancy_type) {
          this.setErrorText(COMPLIANCY_TYPE_REQUIRED);
        } else if (!self.compliancy_description) {
          this.setErrorText(COMPLIANCY_DESCRIPTION_REQUIRED);
        } else if (!self.expiration_date) {
          this.setErrorText(EXPIRATION_DATE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveCompliancy(documentToUpload, documentToDelete) {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          let promises = [];

          if (documentToDelete) {
            promises.push(
              deleteDriverDocumentPromise(self.driver_id, documentToDelete)
            );
          }

          if (documentToUpload) {
            const formData = new FormData();
            formData.append('file', documentToUpload.originFileObj);
            promises.push(
              uploadDriverCompliancyDocument(self.compliancy_id, formData)
            );
          }

          promises.push(editDriverCompliancy(self));

          Promise.all(promises)
            .then(() => {
              rootInstance.fleetPageState.getEditedDriver(self.driver_id);
            })
            .catch((error) => {
              this.throwEditCompliancyError(error);
            });
        }
      },
    };
  });
