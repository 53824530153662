import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import FormPageTitle from '../../FormPageTitle';
import editEmployee from '../../../assets/images/menu/edit-employee.svg';
import {
  DEFAULT_LOCATION_ADDED,
  EDIT_ORGANIZATION,
  MODAL_SUCCESS_CREATE_ORGANIZATION_TEXT,
} from '../../../constants/organizations/organizations';
import EditOrganizationGeneralInformation from './EditOrganizationGeneralInformation';
import EditOrganizationAdditionalInformation from './EditOrganizationAdditionalInformation';
import ErrorBox from '../../ErrorBox';
import {
  CONFIRM,
  LOGO,
  MODAL_SUCCESS_TITLE,
  SUCCESS,
} from '../../../constants';
import SuccessModal from '../../SuccessModal';
import { useMst } from '../../../state/RootModel';
import PageRoutes from '../../../utils/PageRoutes';
import EditOrganizationBranding from './EditOrganizationBranding';
import EditOrganizationLocation from './EditOrganizationLocation';
import AddOrganizationLocationModal from './AddOrganizationLocationModal';
import EditOrganizationLocationModal from './EditOrganizationLocationModal';

const EditOrganizationComponent = () => {
  const {
    organizationState: { editedOrganization },
  } = useMst();

  const backLink = PageRoutes.EditOrganization.name;
  const params = { organizationId: editedOrganization?.organization_id };

  const [logo, setLogoPhoto] = useState([]);
  useEffect(() => {
    setLogoPhoto(editedOrganization?.logo ? [editedOrganization?.logo] : []);
  }, [editedOrganization?.logo]);

  const handleLogoChange = ({ file, fileList }) => {
    editedOrganization.logo &&
      editedOrganization.logo.setIsChangedDuringEdit(true);
    if (fileList.length) {
      fileList[0].originFileObj.documentType = LOGO;
      fileList[0].isChangedDuringEdit = true;
    }
    if (file.status !== 'uploading') {
      setLogoPhoto(fileList);
    }
  };
  const [thumbnail, setThumbnailPhoto] = useState([]);
  useEffect(() => {
    setThumbnailPhoto(
      editedOrganization?.thumbnail ? [editedOrganization?.thumbnail] : []
    );
  }, [editedOrganization?.thumbnail]);
  const handleThumbnailChange = ({ file, fileList }) => {
    editedOrganization.thumbnail &&
      editedOrganization.thumbnail.setIsChangedDuringEdit(true);
    if (fileList.length) {
      fileList[0].originFileObj.documentType = 'Thumbnail';
      fileList[0].isChangedDuringEdit = true;
    }
    if (file.status !== 'uploading') {
      setThumbnailPhoto(fileList);
    }
  };

  const [background, setBackgroundPhoto] = useState([]);
  useEffect(() => {
    setBackgroundPhoto(
      editedOrganization?.background ? [editedOrganization?.background] : []
    );
  }, [editedOrganization?.background]);
  const handleBackgroundChange = ({ file, fileList }) => {
    editedOrganization.background &&
      editedOrganization.background.setIsChangedDuringEdit(true);
    if (fileList.length) {
      fileList[0].originFileObj.documentType = 'Background Image';
      fileList[0].isChangedDuringEdit = true;
    }
    if (file.status !== 'uploading') {
      setBackgroundPhoto(fileList);
    }
  };
  const getDocumentsToDelete = () => {
    return [
      editedOrganization.logo,
      editedOrganization.thumbnail,
      editedOrganization.background,
    ].filter((document) => document?.isChangedDuringEdit === true);
  };
  const getDocumentsForUpload = () => {
    return [...logo, ...thumbnail, ...background].filter(
      (document) => document.isChangedDuringEdit === true
    );
  };

  const onSaveOrganization = () => {
    const documentsToUpload = getDocumentsForUpload();
    const documentsToDelete = getDocumentsToDelete();

    editedOrganization.updateOrganization(documentsToUpload, documentsToDelete);
  };
  return (
    <div className="add-edit-form-component">
      <FormPageTitle image={editEmployee} title={EDIT_ORGANIZATION} />
      <EditOrganizationBranding
        onLogoChange={handleLogoChange}
        logo={logo}
        onThumbnailChange={handleThumbnailChange}
        thumbnail={thumbnail}
        onBackgroundImageChange={handleBackgroundChange}
        background={background}
      />

      <EditOrganizationGeneralInformation />
      <EditOrganizationAdditionalInformation />
      <EditOrganizationLocation />

      <ErrorBox obj={editedOrganization} />
      <button className="btn primary" onClick={onSaveOrganization}>
        {CONFIRM}
      </button>
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_CREATE_ORGANIZATION_TEXT}
        buttonText={SUCCESS}
        backLink={backLink}
        params={params}
        open={editedOrganization?.isOrganizationEdited}
        closeModal={() => editedOrganization.setIsOrganizationEdited(false)}
      />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={DEFAULT_LOCATION_ADDED}
        buttonText={SUCCESS}
        backLink={backLink}
        params={params}
        open={editedOrganization?.defaultLocationAdded}
        closeModal={() => editedOrganization.setDefaultLocationAdded(false)}
      />
      <AddOrganizationLocationModal />
      <EditOrganizationLocationModal />
    </div>
  );
};
export default observer(EditOrganizationComponent);
