import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Input, Select } from 'antd';
import AsyncSelect from 'react-select/async';
import ErrorBox from '../../../ErrorBox';
import { useMst } from '../../../../state/RootModel';
import addLocationIcon from '../../../../assets/images/add-location.svg';
import FormPageTitle from '../../../FormPageTitle';
import {
  CITY,
  COMPANY,
  COUNTRY,
  EDIT_LOCATION,
  GENERAL_INFORMATION,
  LOCATION_CONTACT_NAME,
  LOCATION_INFORMATION,
  LOCATION_NAME,
  NOTES,
  OTHER,
  PHONE_NUMBER,
  SAVE_CHANGES,
  START_TYPING_PLACEHOLDER_TEXT,
  STATE,
  ZIP_CODE,
} from '../../../../constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import MapComponent from './MapComponent';

const debounce = require('lodash.debounce');
const { Option } = Select;
const EditLocationModal = () => {
  const {
    locationsPageState: {
      setEditedLocation,
      editedLocation,
      countriesArray,
      filteredStates,
    },
    clientsPageState: { getFilteredClients },
  } = useMst();

  const onSaveChanges = () => {
    editedLocation.saveLocation();
  };

  const onCancel = () => {
    setEditedLocation(null);
  };

  const loadSuggestions = debounce(getFilteredClients, 300);

  const onSelectCustomer = (customer) => {
    editedLocation.setOwnerId(customer.client_id);
  };

  return (
    <div>
      <Modal
        visible={!!editedLocation}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={1000}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 location-modal-content">
          <div className="align-center">
            <FormPageTitle image={addLocationIcon} title={EDIT_LOCATION} />
          </div>

          <div className="form-page-subsection">
            <div className="subsection-header mt0">
              <p>{GENERAL_INFORMATION}</p>
            </div>
          </div>

          <div className="flex-alignitems-flex-start mt20">
            <div className="input-wrapper w35">
              <div className="input-label justify-between">
                <span>{LOCATION_NAME}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => editedLocation.setLocationName(e.target.value)}
                value={editedLocation.location_name}
              />
            </div>

            <div className="input-wrapper w35 ml5pc">
              <div className="input-label justify-between">
                <span>{COMPANY}</span>
                <span className="color-secondary">*</span>
              </div>
              <AsyncSelect
                loadOptions={loadSuggestions}
                onChange={onSelectCustomer}
                placeholder={START_TYPING_PLACEHOLDER_TEXT}
                defaultInputValue={editedLocation.owner_name}
              />
            </div>
          </div>

          <div className="flex-alignitems-flex-start mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{LOCATION_CONTACT_NAME}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) =>
                  editedLocation.setLocationContactName(e.target.value)
                }
                value={editedLocation.location_contact_name}
              />
            </div>
            <div className="input-wrapper ml5pc">
              <div className="input-label justify-between">
                <span>{PHONE_NUMBER}</span>
                <span className="color-secondary">*</span>
              </div>
              <PhoneInput
                placeholder="e.g. 712338645"
                value={editedLocation.phone_number}
                onChange={(value) => editedLocation.setPhoneNumber(value)}
                maxLength={15}
                displayInitialValueAsLocalNumber
              />
            </div>
          </div>
          <div className="flex-alignitems-flex-start mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{NOTES}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={(e) => editedLocation.setNotes(e.target.value)}
                value={editedLocation.notes}
              />
            </div>
          </div>

          <div className="form-page-subsection mt20">
            <div className="subsection-header mt0">
              <p>{LOCATION_INFORMATION}</p>
            </div>
          </div>

          <div className="flex-alignitems-flex-start mt20 w100">
            <div className="flex-column w35">
              <div className="flex-alignitems-flex-start">
                <div className="input-wrapper w25">
                  <div className="input-label justify-between">
                    <span>{ZIP_CODE}</span>
                  </div>
                  <Input
                    className="add-edit-input"
                    onChange={(e) =>
                      editedLocation.setPostalCode(e.target.value)
                    }
                    value={editedLocation.postal_code}
                  />
                </div>

                <div className="input-wrapper w70 ml5pc">
                  <div className="input-label justify-between">
                    <span>{COUNTRY}</span>
                    <span className="color-secondary">*</span>
                  </div>
                  <Select
                    className={'w100'}
                    onChange={(country) => editedLocation.setCountry(country)}
                    value={editedLocation.country}
                  >
                    {countriesArray.map((country, index) => (
                      <Option key={index} value={country.name}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div className="input-wrapper w100 mt20">
                <div className="input-label justify-between">
                  <span>{STATE}</span>
                  <span className="color-secondary">*</span>
                </div>
                <Select
                  className={'w100'}
                  onChange={(state) => editedLocation.setState(state)}
                  value={editedLocation.state}
                >
                  {filteredStates &&
                    filteredStates.states.map((state, index) => (
                      <Option key={index} value={state}>
                        {state}
                      </Option>
                    ))}
                  <Option key={'other'} value={'Other'}>
                    {OTHER}
                  </Option>
                </Select>
                {/*<Input*/}
                {/*    className="add-edit-input"*/}
                {/*    onChange={(e) => editedLocation.setState(e.target.value)}*/}
                {/*    value={editedLocation.state}*/}
                {/*/>*/}
              </div>

              <div className="input-wrapper w100 mt20">
                <div className="input-label justify-between">
                  <span>{CITY}</span>
                  <span className="color-secondary">*</span>
                </div>
                <Input
                  className="add-edit-input"
                  onChange={(e) => editedLocation.setCity(e.target.value)}
                  value={editedLocation.city}
                />
              </div>
            </div>
            <MapComponent />
          </div>

          <ErrorBox obj={editedLocation} />

          <div>
            <button className="btn primary" onClick={onSaveChanges}>
              {SAVE_CHANGES}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditLocationModal);
