import { types } from 'mobx-state-tree';

export const ShipmentDocumentModel = types
  .model('ShipmentDocumentModel', {
    uid: types.identifierNumber,
    name: types.maybe(types.maybeNull(types.string)),
    url: types.maybe(types.maybeNull(types.string)),
    document_type: types.frozen({}),
    date_added: types.maybe(types.maybeNull(types.string)),
    document_url: types.maybe(types.maybeNull(types.string)),
    shipment_id: types.maybe(types.maybeNull(types.number)),
    isChangedDuringEdit: false,
  })
  .actions((self) => {
    return {
      setIsChangedDuringEdit(status) {
        self.isChangedDuringEdit = status;
      },
    };
  });
