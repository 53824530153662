import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { CLOSE } from '../../constants';
import close from '../../assets/images/close-sign.svg';

const ContentContainer = () => {
  const {
    bottomMenuState: { setActiveItem, activeItem },
  } = useMst();

  return (
    <div className={`content-container ${activeItem ? 'open' : null}`}>
      {activeItem && (
        <div className="inner-content">
          <div className="justify-between align-center mb40">
            <h2>{activeItem.title}</h2>
            <span
              className="close-icon-wrapper"
              onClick={() => setActiveItem(null)}
            >
              <img src={close} alt={CLOSE} />
            </span>
          </div>
          {activeItem.text.map((paragraph, index) => {
            return (
              <p className="mb20" key={index}>
                {paragraph}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default observer(ContentContainer);
