export const DASHBOARD = 'Dashboard';
export const EMPLOYEES = 'Employees';
export const FLEET = 'Fleet';
export const BROKERAGE = 'Brokerage';
export const SHIPMENT_BOARD = 'Shipment Board';
export const CREATE_SHIPMENT = 'CreateShipment';
export const EDIT_SHIPMENT = 'EditShipment';
export const VIEW_SHIPMENT = 'ViewShipment';
export const VIEW_CARRIER = 'ViewCarrier';
export const REVENUE = 'Revenue';
export const DISPATCH = 'Dispatch';
export const DISPATCH_MAP = 'Dispatcher Map';
export const PAYROLL = 'Payroll';
export const PAYROLL_AND_BILLING = 'Payroll & Billing';
export const REPORTS = 'Reports';
export const SUPPORT = 'Support';
export const ACCOUNT = 'Account';
export const LOGIN = 'Login';
export const LOGOUT = 'Logout';
export const ADD_EMPLOYEE = 'AddEmployee';
export const EDIT_EMPLOYEE = 'EditEmployee';
export const ADD_TRUCK = 'AddTruck';
export const EDIT_TRUCK = 'EditTruck';
export const ADD_TRAILER = 'AddTrailer';
export const EDIT_TRAILER = 'EditTrailer';
export const ADD_DRIVER = 'AddDriver';
export const EDIT_DRIVER = 'EditDriver';
export const VIEW_DRIVER = 'ViewDriver';
export const VIEW_TRUCK = 'ViewTruck';
export const VIEW_TRAILER = 'ViewTrailer';
export const VIEW_EMPLOYEE = 'ViewEmployee';
export const RESET_PASSWORD = 'ResetPassword';
export const CREATE_PASSWORD = 'CreatePassword';
export const ORGANIZATIONS = 'Organizations';
export const EDIT_ORGANIZATION = 'EditOrganization';
export const ORGANIZATION = 'Organization';
export const ADD_ROLE = 'AddUserRole';
export const EDIT_ROLE = 'EditUserRole';
export const ADD_COMPENSATION = 'AddCompensation';
export const EDIT_COMPENSATION = 'EditCompensation';
