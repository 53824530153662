import React from 'react';
import { observer } from 'mobx-react';
import { MAINTENANCE_HISTORY } from '../../../../constants';
import dropdownIcon from '../../../../assets/images/dropdown-icon.svg';
import ViewMaintenanceTable from './ViewMaintenanceTable';

const ViewMaintenanceSection = ({ model }) => {
  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            model.setIsMaintenanceSectionOpen(!model.isMaintenanceSectionOpen);
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              model.isMaintenanceSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {MAINTENANCE_HISTORY}
          </span>
        </div>
      </div>
      {model?.isMaintenanceSectionOpen && (
        <ViewMaintenanceTable model={model} />
      )}
    </div>
  );
};

export default observer(ViewMaintenanceSection);
