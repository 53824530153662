import { types } from 'mobx-state-tree';
import { rootInstance } from '../RootModel';

export const RegistrationLightModel = types
  .model('RegistrationLightModel', {
    vehicle_id: types.maybe(types.maybeNull(types.number)),
    issued_in: types.maybe(types.maybeNull(types.string)),
    issued_on: types.maybe(types.maybeNull(types.string)),
    expiry_date: types.maybe(types.maybeNull(types.string)),
    licence_plate: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get filteredStates() {
        const selectedCountry = self.country;
        return rootInstance.fleetPageState.editedVehicle.countries.find(
          (country) => {
            return country.name === selectedCountry;
          }
        );
      },
    };
  })
  .actions((self) => {
    return {
      setRegistrationCountry(country) {
        self.country = country;
      },
      setRegistrationLocation(location) {
        self.issued_in = location;
      },
      setRegistrationIssuedOn(issued_on) {
        self.issued_on = issued_on;
      },
      setRegistrationExpirationDate(date) {
        self.expiry_date = date;
      },
      setLicencePlate(licence_plate) {
        self.licence_plate = licence_plate;
      },
    };
  });
