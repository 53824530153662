import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import editDriverIcon from '../../../assets/images/edit-driver.svg';
import ErrorBox from '../../ErrorBox';
import {
  EDIT_DRIVER,
  SAVE_CHANGES,
  DISCARD_CHANGES,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
} from '../../../constants';
import FormPageTitle from '../../FormPageTitle';
import GeneralInformation from './GeneralInformation';
import DriverInformation from './DriverInformation';
import WorkingStatusComponent from './WorkingStatus';
import LicenceInformation from './LicenceInformation';
import DocumentationSection from './DocumentationSection';
import Associations from './Associations';
import ComplianciesSection from './ComplianciesSection';
import { notification } from 'antd';
import SaveFirstModal from '../../SaveFirstModal';
import { getRandomInt } from '../../../utils/methods';
import {
  DOCUMENT_TYPE_ADDITIONAL,
  DOCUMENT_TYPE_REFERENCE,
  DOCUMENT_TYPE_SSN,
  DOCUMENT_TYPE_LICENCE,
  DOCUMENT_TYPE_RESUME,
} from '../../../constants/documents';
import DriverPayroll from './DriverPayroll';

const EditDriverComponent = () => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  // added to reset state on discard changes
  const [key, setKey] = useState(getRandomInt());

  const [newLicenceAttachment, setNewLicenceAttachment] = useState([]);
  const handleNewLicenceUpload = ({ file, fileList }) => {
    if (fileList) {
      const listToUpload = fileList.length && [fileList[fileList.length - 1]];
      if (listToUpload && listToUpload.length) {
        listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_LICENCE;
        if (file.status !== 'uploading') {
          setNewLicenceAttachment(listToUpload);
        }
      } else {
        setNewLicenceAttachment(fileList);
      }
    } else {
      setNewLicenceAttachment([]);
    }
  };

  const [licenceAttachment, setLicenceAttachment] = useState([]);
  useEffect(() => {
    setLicenceAttachment(
      editedDriver?.licence?.licenceAttachment
        ? [editedDriver.licence.licenceAttachment]
        : []
    );
  }, [editedDriver?.licence?.licenceAttachment, key]);
  const handleLicenceUpload = ({ file, fileList }) => {
    editedDriver?.licence?.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_LICENCE;
      if (file.status !== 'uploading') {
        setLicenceAttachment(listToUpload);
      }
    } else {
      setLicenceAttachment(fileList);
    }
  };

  const [cv, setCv] = useState([]);
  useEffect(() => {
    setCv(editedDriver?.cv ? [editedDriver.cv] : []);
  }, [editedDriver?.cv, key]);
  const handleCVUpload = ({ file, fileList }) => {
    editedDriver.cv && editedDriver.cv.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_RESUME;
      if (file.status !== 'uploading') {
        setCv(listToUpload);
      }
    } else {
      setCv(fileList);
    }
  };

  const [reccomendationLetter, setReccomendationLetter] = useState([]);
  useEffect(() => {
    setReccomendationLetter(
      editedDriver?.reccomendationLetter
        ? [editedDriver.reccomendationLetter]
        : []
    );
  }, [editedDriver?.reccomendationLetter, key]);
  const handleReccomendationLetterUpload = ({ file, fileList }) => {
    editedDriver?.reccomendationLetter &&
      editedDriver.reccomendationLetter.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_REFERENCE;
      if (file.status !== 'uploading') {
        setReccomendationLetter(listToUpload);
      }
    } else {
      setReccomendationLetter(fileList);
    }
  };

  const [ssnAttachment, setSsnAttachment] = useState([]);
  useEffect(() => {
    setSsnAttachment(
      editedDriver?.ssnAttachment ? [editedDriver.ssnAttachment] : []
    );
  }, [editedDriver?.ssnAttachment, key]);
  const handleSsnUpload = ({ file, fileList }) => {
    editedDriver?.ssnAttachment &&
      editedDriver.ssnAttachment.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_SSN;
      if (file.status !== 'uploading') {
        setSsnAttachment(listToUpload);
      }
    } else {
      setSsnAttachment(fileList);
    }
  };

  const [additionalFiles, setAdditionalFiles] = useState([]);
  useEffect(() => {
    setAdditionalFiles(
      editedDriver?.driverAdditionalFiles
        ? [...editedDriver.driverAdditionalFiles]
        : []
    );
  }, [editedDriver?.driverAdditionalFiles, key]);
  const handleAdditionalFilesUpload = ({ file, fileList }) => {
    const isUploadAllowed = () => {
      return fileList.length < 6 || fileList.length < additionalFiles.length;
    };
    if (file.status !== 'uploading') {
      if (isUploadAllowed()) {
        fileList.forEach((file) => {
          !('originFileObj' in file) && (file.originFileObj = {});
          file.originFileObj.documentType = DOCUMENT_TYPE_ADDITIONAL;
        });
        setAdditionalFiles(fileList);
      } else {
        notification.error({
          message: UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
          description: UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
        });
      }
    }
  };

  const getDocumentsForUpload = () => {
    const currentDocuments = [
      ...cv,
      ...ssnAttachment,
      ...reccomendationLetter,
      ...additionalFiles,
      ...licenceAttachment,
      ...newLicenceAttachment,
    ];

    const uploadedDocuments = [...editedDriver.documentsArray];
    editedDriver.licence?.licenceAttachment &&
      uploadedDocuments.push(editedDriver.licence.licenceAttachment);

    const documentsToUpload = currentDocuments.filter((document) => {
      return !uploadedDocuments.includes(document);
    });
    return documentsToUpload;
  };

  const getDocumentsForDeletion = () => {
    const currentDocuments = [
      ...cv,
      ...ssnAttachment,
      ...reccomendationLetter,
      ...additionalFiles,
      ...licenceAttachment,
    ];

    const uploadedDocuments = [...editedDriver.documentsArray];
    editedDriver.licence?.licenceAttachment &&
      uploadedDocuments.push(editedDriver.licence.licenceAttachment);

    const documentsToDelete = uploadedDocuments.filter((document) => {
      return !currentDocuments.includes(document);
    });

    return documentsToDelete;
  };

  const discardChanges = () => {
    setKey(getRandomInt());
    editedDriver.cancelChanges();
  };

  const saveChanges = () => {
    const documentsToUpload = getDocumentsForUpload();
    const documentsToDelete = getDocumentsForDeletion();
    editedDriver.updateDriver(documentsToUpload, documentsToDelete);
  };

  return (
    <div className="add-edit-form-component">
      {editedDriver?.driverFullyFetched && (
        <div>
          <SaveFirstModal
            onCancel={discardChanges}
            onConfirm={saveChanges}
            open={editedDriver?.isSaveFirstModalOpen}
          />
          {/* TITLE */}
          <div className="align-center">
            <FormPageTitle image={editDriverIcon} title={EDIT_DRIVER} />
            <span>
              <h2 className="text-medium color-primary">
                {editedDriver.first_name} {editedDriver.last_name}
              </h2>
            </span>
          </div>
          <GeneralInformation />
          <DriverInformation />
          <WorkingStatusComponent />
          <LicenceInformation
            uploadLicence={handleLicenceUpload}
            licence={licenceAttachment}
            uploadNewLicence={handleNewLicenceUpload}
            newLicence={newLicenceAttachment}
          />
          <Associations />
          <ComplianciesSection />
          <DriverPayroll />
          <DocumentationSection
            uploadCV={handleCVUpload}
            cv={cv}
            reccomendationLetter={reccomendationLetter}
            uploadReccomendationLetter={handleReccomendationLetterUpload}
            uploadSsnAttachment={handleSsnUpload}
            ssn={ssnAttachment}
            uploadAdditionalFiles={handleAdditionalFilesUpload}
            additionalFiles={additionalFiles}
          />
          <ErrorBox obj={editedDriver} />
          <button className="btn primary mt20" onClick={saveChanges}>
            {SAVE_CHANGES}
          </button>
          <button className="btn white ml20 mt20" onClick={discardChanges}>
            {DISCARD_CHANGES}
          </button>
        </div>
      )}
    </div>
  );
};

export default observer(EditDriverComponent);
