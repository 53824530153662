import { Tooltip } from 'antd';
import React from 'react';
import {
  ATTACHED_FILE,
  COMPLIANCY_DESCRIPTION,
  COMPLIANCY_TYPE,
  EDIT_COMPLIANCY,
  EXPIRY_DATE,
  VIEW_FILE,
} from '../../../constants';
import edit from '../../../assets/images/edit-equipment.svg';
import { rootInstance } from '../../RootModel';

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.fleetPageState.editedDriver.setEditedCompliancy(
    record.compliancy_id
  );
};

export const compliancesColumnsFunc = (rootInstance) => ({
  '1': {
    title: COMPLIANCY_TYPE,
    dataIndex: 'compliancy_type',
    key: 'compliancy_type',
    render: (text, record) => {
      return <span className="text-capitalize">{text}</span>;
    },
  },
  '2': {
    title: COMPLIANCY_DESCRIPTION,
    dataIndex: 'compliancy_description',
    key: 'compliancy_description',
  },
  '3': {
    title: EXPIRY_DATE,
    dataIndex: 'expiration_date',
    key: 'expiration_date',
  },
  '4': {
    title: ATTACHED_FILE,
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <Tooltip placement="top" title={VIEW_FILE}>
          <a href={record.document?.document_url} target="blank">
            {record.document?.name}
          </a>
        </Tooltip>
      );
    },
  },
  '5': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        rootInstance.fleetPageState.isEditDriverPage && (
          <div>
            <Tooltip title={EDIT_COMPLIANCY}>
              <img
                src={edit}
                alt="edit"
                className="pointer ph10"
                onClick={(e) => onClickEdit(e, record)}
              />
            </Tooltip>
          </div>
        )
      );
    },
  },
});
