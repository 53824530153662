import { rootInstance } from '../../state/RootModel';
const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getAvailableWeightUnits = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/weight_units`, config);
};

export const getAvailableQuantityUnits = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/quantity_units`, config);
};

export const getAllEquipments = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/equipment`, config);
};

export const getAllCountries = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/countries`, config);
};
