import { types, getSnapshot, applySnapshot } from 'mobx-state-tree';
import {
  STATUS_FREE_DAY,
  STATUS_HOLIDAY,
  STATUS_SICK_LEAVE,
  STATUS_VACATION,
} from '../../constants/workingStatuses';
import {
  ABSENCE_TYPE_REQUIRED,
  END_DATE_REQUIRED,
  START_DATE_REQUIRED,
} from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { deleteUserAbsence } from '../../actions/employees';
import { rootInstance } from '../RootModel';
import { deleteDriverAbsence } from '../../actions/fleet';
import { EDIT_DRIVER, EDIT_EMPLOYEE } from '../../constants/menu';
import { DocumentModel } from '../DocumentModel';
import {
  deleteAbsenceDocumentPromise,
  editUserAbsence,
  uploadAbsenceDocumentPromise,
} from '../../promises/employees';
import {
  deleteDriverDocumentPromise,
  editDriverAbsence,
  uploadDriverAbsenceDocumentPromise,
} from '../../promises/fleet';

export const AbsenceModel = types
  .model('AbsenceModel', {
    absence_id: types.identifierNumber,
    user_id: types.maybe(types.maybeNull(types.number)),
    driver_id: types.maybe(types.maybeNull(types.number)),
    starting_date: types.maybe(types.maybeNull(types.string)),
    end_date: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    title: types.maybe(types.maybeNull(types.string)),
    start: types.maybe(types.maybeNull(types.Date)),
    end: types.maybe(types.maybeNull(types.Date)),
    document: types.maybe(types.maybeNull(DocumentModel)),
    errorText: types.maybe(types.maybeNull(types.string)),
    isConfirmDeleteModalOpen: false,
  })
  .views((self) => {
    return {
      get color() {
        if (self.status === STATUS_VACATION) {
          return 'blue';
        } else if (self.status === STATUS_FREE_DAY) {
          return 'purple';
        } else if (self.status === STATUS_SICK_LEAVE) {
          return 'red';
        } else if (self.status === STATUS_HOLIDAY) {
          return 'pink';
        } else {
          return 'orange';
        }
      },
      get shouldDisplayDatePicker() {
        if (self.status && self.status === STATUS_FREE_DAY) {
          return true;
        }
        return false;
      },
      get isEditEmployeePage() {
        return rootInstance.router.currentView.name === EDIT_EMPLOYEE;
      },
      get isEditDriverPage() {
        return rootInstance.router.currentView.name === EDIT_DRIVER;
      },
    };
  })
  .actions((self) => {
    const rootSnapshot = getSnapshot(self);
    return {
      cancelChanges() {
        applySnapshot(self, rootSnapshot);
      },
      setStartingDate(date) {
        self.starting_date = date;
      },
      setEndDate(date) {
        self.end_date = date;
      },
      setStatus(status) {
        self.status = status;
      },
      setDocument(item) {
        item.uid = item.document_id;
        item.name = item.document_name;
        item.url = item.document_url;
        self.document = item;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setIsConfirmDeleteModalOpen(status) {
        self.isConfirmDeleteModalOpen = status;
      },
      throwEditAbsenceError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      redirectAfterSave() {
        if (self.isEditEmployeePage) {
          rootInstance.employeesPageState.getEditedEmployee(self.user_id);
        } else if (self.isEditDriverPage) {
          rootInstance.fleetPageState.getEditedDriver(self.driver_id);
        }
      },
      validateForm() {
        if (!self.starting_date) {
          this.setErrorText(START_DATE_REQUIRED);
        } else if (!self.end_date) {
          this.setErrorText(END_DATE_REQUIRED);
        } else if (!self.status) {
          this.setErrorText(ABSENCE_TYPE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveAbsence(documentToUpload, documentToDelete) {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          if (self.status === STATUS_FREE_DAY) {
            this.setEndDate(self.starting_date);
          }
          if (self.isEditEmployeePage) {
            let promises = [];

            if (documentToDelete) {
              promises.push(deleteAbsenceDocumentPromise(documentToDelete.uid));
            }

            if (documentToUpload) {
              promises.push(
                uploadAbsenceDocumentPromise(self.absence_id, documentToUpload)
              );
            }

            promises.push(editUserAbsence(self));

            Promise.all(promises)
              .then(() => {
                this.redirectAfterSave();
              })
              .catch((error) => {
                this.throwEditAbsenceError(error);
              });
          } else if (self.isEditDriverPage) {
            let promises = [];

            if (documentToDelete) {
              promises.push(
                deleteDriverDocumentPromise(self.driver_id, documentToDelete)
              );
            }

            if (documentToUpload) {
              promises.push(
                uploadDriverAbsenceDocumentPromise(
                  self.absence_id,
                  documentToUpload
                )
              );
            }

            promises.push(editDriverAbsence(self));

            Promise.all(promises)
              .then(() => {
                this.redirectAfterSave();
              })
              .catch((error) => {
                this.throwEditAbsenceError(error);
              });
          }
        }
      },
      deleteAbsence() {
        setFetchingInProgress(true);
        if (self.isEditEmployeePage) {
          deleteUserAbsence(self.user_id, self.absence_id)
            .then((response) => {
              rootInstance.employeesPageState.getEditedEmployee(self.user_id);
            })
            .catch((error) => {
              this.setIsConfirmDeleteModalOpen(false);
              this.throwEditAbsenceError(error);
            });
        } else if (self.isEditDriverPage) {
          deleteDriverAbsence(self.driver_id, self.absence_id)
            .then((response) => {
              rootInstance.fleetPageState.getEditedDriver(self.driver_id);
            })
            .catch((error) => {
              this.setIsConfirmDeleteModalOpen(false);
              this.throwEditAbsenceError(error);
            });
        }
      },
    };
  });
