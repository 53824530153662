import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { useMst } from '../../../state/RootModel';

const ShipmentListProbillDetails = ({ data }) => {
  const {
    dispatchPageState: { getShipmentListProbillsTableColumns },
  } = useMst();

  return (
    <div className="dispatch-trip-modal__driver-details">
      <Table
        dataSource={data}
        rowKey={'index'}
        columns={getShipmentListProbillsTableColumns()}
        pagination={false}
      />
    </div>
  );
};

export default observer(ShipmentListProbillDetails);
