import React from 'react';
import { observer } from 'mobx-react';
import { SHIPMENT_PROFIT, TOTAL_PROFIT } from '../../../constants';
import { useMst } from '../../../state/RootModel';
import { toAmount } from '../../../utils/methods';

const ShipmentProfit = () => {
  const {
    shipmentsPageState: {
      currentShipment: { shipmentChargesArray, totalChargesAmount },
    },
  } = useMst();

  return (
    <div className="page-section shipment-details-section w30 ml5pc">
      <div className="subsection-header align-center">
        <p>{SHIPMENT_PROFIT}</p>
      </div>
      <ul className="subsection-content flex-column w90">
        {shipmentChargesArray.map(
          (item) =>
            item.charge_name && (
              <li
                className="align-center justify-between pv10"
                key={item.shipment_charge_id}
              >
                <div className="no-wrap">{item.charge_name}:</div>
                <div>{toAmount(item.price)}</div>
              </li>
            )
        )}
        <li className="w100 align-center justify-between pv10 bt1solid">
          <div className="no-wrap">{TOTAL_PROFIT}:</div>
          <div>{totalChargesAmount}</div>
        </li>
      </ul>
    </div>
  );
};

export default observer(ShipmentProfit);
