import React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import { useMst } from '../../../../../state/RootModel';
import { TITLE, DESCRIPTION, CANCEL, EDIT } from '../../../../../constants';
import ErrorBox from '../../../../ErrorBox';

const { TextArea } = Input;

const EditedShipmentDocumentTypeComponent = () => {
  const {
    shipmentSpecificsPageState: {
      shipmentDocumentTypeToBeEdited,
      setShipmentDocumentTypeToBeEdited,
      editedShipmentSpecificType,
      setEditingShipmentSpecific,
    },
  } = useMst();

  const onChangeDescription = (e) => {
    shipmentDocumentTypeToBeEdited.setDescription(e.target.value);
  };
  const onCancel = () => {
    setEditingShipmentSpecific(false);
    setShipmentDocumentTypeToBeEdited(null);
  };
  const onEdit = () => {
    shipmentDocumentTypeToBeEdited.saveItem();
    // newLocation.setOwnerId(customer.client_id);
  };

  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) =>
            shipmentDocumentTypeToBeEdited.setDocumentTypeName(e.target.value)
          }
          value={shipmentDocumentTypeToBeEdited?.document_type_name}
        />
      </div>
      <div className="input-wrapper mt20 w100">
        <div className="input-label justify-between">
          <span>{DESCRIPTION}</span>
        </div>
        <TextArea
          rows={4}
          onChange={onChangeDescription}
          value={shipmentDocumentTypeToBeEdited?.description}
        />
      </div>
      <ErrorBox obj={editedShipmentSpecificType} />

      <div className="justify-end mt20">
        <button className="btn white mr10" onClick={onCancel}>
          {CANCEL}
        </button>
        <button className="btn primary" onClick={onEdit}>
          {EDIT}
        </button>
      </div>
    </div>
  );
};

export default observer(EditedShipmentDocumentTypeComponent);
