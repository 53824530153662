import { rootInstance } from '../../state/RootModel';
const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getAllCompensations = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/compensations`, config);
};

export const getAllCompensationTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/compensation_types`, config);
};

export const getCompensationPaymentPeriods = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/payment_periods`, config);
};

export const getCompensationBasedOnTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/based_on_types`, config);
};

export const getCompensationPaymentRates = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/payment_rates`, config);
};

export const getCompensationDeductionTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/deduction_types`, config);
};

export const getCompensationCurrencies = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/currency_types`, config);
};

export const getCompensationValuesByType = (compensation_type) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/compensation_values_by_type?compensation_type=${compensation_type}`,
    config
  );
};

export const createNewCompensationStructure = (data) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/compensations`,
    {
      data: {
        compensation_name: data.compensation_name,
        compensation_type: data.compensation_type,
        period: data.compensation_payment_period,
      },
      compensation_values: data.new_compensation_values,
      compensation_deductions: data.new_compensation_deductions,
    },
    config
  );
};

export const deleteCompensationById = (id) => {
  const config = getConfig();
  return axios.delete(`${rootInstance.baseUrl}/compensations/${id}`, config);
};

export const deleteCompensationValueById = (
  compensation_id,
  compensation_value_id
) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/compensations/${compensation_id}/compensation_values/${compensation_value_id}`,
    config
  );
};

export const deleteCompensationDeductionById = (
  compensation_id,
  compensation_deduction_id
) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/compensations/${compensation_id}/deductions/${compensation_deduction_id}`,
    config
  );
};

export const editCompensation = (compensationId, name, period) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/compensations/${compensationId}`,
    {
      compensation_name: name,
      period: period,
    },
    config
  );
};

export const editCompensationValue = (compensationId, data) => {
  const config = getConfig();

  return axios.put(
    `${rootInstance.baseUrl}/compensations/${compensationId}/compensation_values/${data.compensation_value_id}`,
    {
      based_on: data.based_on,
      rate: data.rate,
      default_value: data.default_value,
      currency: data.currency,
    },
    config
  );
};

export const editCompensationDeduction = (compensationId, data) => {
  const config = getConfig();

  return axios.put(
    `${rootInstance.baseUrl}/compensations/${compensationId}/deductions/${data.compensation_deduction_id}`,
    {
      based_on: data.based_on,
      rate: data.rate,
      default_value: data.default_value,
      currency: data.currency,
    },
    config
  );
};

export const getCompensationById = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/compensations/${id}`, config);
};

export const addValuesToCompensation = (compensationId, values) => {
  const config = getConfig();

  return axios.put(
    `${rootInstance.baseUrl}/compensations/${compensationId}/compensation_values`,
    values,
    config
  );
};

export const addDeductionsToCompensation = (compensationId, deductions) => {
  const config = getConfig();

  return axios.put(
    `${rootInstance.baseUrl}/compensations/${compensationId}/deductions`,
    deductions,
    config
  );
};
