import { types } from 'mobx-state-tree';
import {
  ADDRESS_REQUIRED,
  CARRIERS,
  CITY_REQUIRED,
  COMPANY_NAME_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_ERROR_TEXT,
  EMAIL_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  STATE_REQUIRED,
} from '../../constants';
import {
  getErrorText,
  getRandomInt,
  removeByAttr,
  setFetchingInProgress,
  validateEmail,
} from '../../utils/methods';
import {
  addCarrierEquipments,
  editCarrier,
  removeCarrierEquipments,
} from '../../actions/carriers';
import { rootInstance } from '../RootModel';
import { EquipmentRelatedModel } from '../shipments/EquipmentRelatedModel';

export const CarrierModel = types
  .model('CarrierModel', {
    company_name: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    address: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    fax_number: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    dot_number: types.maybe(types.maybeNull(types.string)),
    mc_number: types.maybe(types.maybeNull(types.string)),
    carrier_code: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    contact_name: types.maybe(types.maybeNull(types.string)),
    contact_position: types.maybe(types.maybeNull(types.string)),
    insurance_company: types.maybe(types.maybeNull(types.string)),
    insurance_broker: types.maybe(types.maybeNull(types.string)),
    insurance_policy_number: types.maybe(types.maybeNull(types.string)),
    insurance_expiry_date: types.maybe(types.maybeNull(types.string)),
    insurance_cargo_amount: types.maybe(types.maybeNull(types.number)),
    insurance_liability_amount: types.maybe(types.maybeNull(types.number)),
    carrier_id: types.identifierNumber,
    organization_id: types.maybe(types.maybeNull(types.number)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),
    notes: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),

    isGeneralInfoSectionOpen: true,
    fullyFetched: false,

    equipmentItems: types.map(EquipmentRelatedModel),
    isEquipmentChanged: false,
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get getEquipmentItemsArray() {
        return Array.from(self.equipmentItems.values());
      },
    };
  })
  .actions((self) => {
    return {
      setNotes(notes) {
        self.notes = notes;
      },
      addNewEquipmentItem(item) {
        item.carrier_equipment_id = getRandomInt();
        self.equipmentItems.put({
          ...item,
        });
      },
      setEquipmentChanged(value) {
        self.isEquipmentChanged = value;
      },
      setFullyFetched(status) {
        self.fullyFetched = status;
      },
      setIsGeneralInfoSectionOpen(status) {
        self.isGeneralInfoSectionOpen = status;
      },
      setStatus(value) {
        self.status = value;
      },
      setCompanyName(value) {
        self.company_name = value;
      },
      setContactName(value) {
        self.contact_name = value;
      },
      setContactPosition(value) {
        self.contact_position = value;
      },
      setDotNumber(value) {
        self.dot_number = value;
      },
      setMcNumber(value) {
        self.mc_number = value;
      },
      setCarrierCode(value) {
        self.carrier_code = value;
      },
      setPhoneNumber(value) {
        self.phone_number = value;
      },
      setFaxNumber(value) {
        self.fax_number = value;
      },
      setEmail(value) {
        self.email = value;
      },
      setInsuranceCompany(value) {
        self.insurance_company = value;
      },
      setInsuranceBroker(value) {
        self.insurance_broker = value;
      },
      setInsurancePolicyNumber(value) {
        self.insurance_policy_number = value;
      },
      setInsuranceExpiryDate(value) {
        self.insurance_expiry_date = value;
      },
      setInsuranceCargoAmount(value) {
        self.insurance_cargo_amount = parseInt(value);
      },
      setInsuranceLiabilityAmount(value) {
        self.insurance_liability_amount = parseInt(value);
      },
      setPostalCode(value) {
        self.postal_code = value;
      },
      setCountry(value) {
        self.country = value;
      },
      setState(value) {
        self.state = value;
      },
      setCity(value) {
        self.city = value;
      },
      setAddress(value) {
        self.address = value;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveCarrierError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      setEquipments(equipments) {
        equipments.forEach((equipment) => {
          const eq = {
            carrier_equipment_id: equipment.carrier_equipment_id,
            equipment_category: equipment.equipment.equipment_category,
            equipment_id: equipment.equipment.equipment_id,
            equipment_type: equipment.equipment.equipment_type,
          };
          self.equipmentItems.put(eq);
        });
      },
      setEditEquipmentItem(carrier_id, equipment) {
        /** Removing item from array by carrier_equipment_id **/
        removeByAttr(self.equipmentItems, 'carrier_equipment_id', carrier_id);

        /** Adding new item to array **/
        equipment.carrier_equipment_id = carrier_id;
        self.equipmentItems.put(equipment);
        this.setEquipmentChanged(true);
      },
      validateForm() {
        const emailValid = validateEmail(self.email);
        if (!self.company_name) {
          this.setErrorText(COMPANY_NAME_REQUIRED);
        } else if (!self.phone_number) {
          this.setErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!self.email) {
          this.setErrorText(EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else if (!self.country) {
          this.setErrorText(COUNTRY_REQUIRED);
        } else if (!self.state) {
          this.setErrorText(STATE_REQUIRED);
        } else if (!self.city) {
          this.setErrorText(CITY_REQUIRED);
        } else if (!self.address) {
          this.setErrorText(ADDRESS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveCarrier() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          let idsToRemove = [];
          let newEquipmentItems = [];
          self.getEquipmentItemsArray.map((equipment) => {
            idsToRemove.push(equipment.carrier_equipment_id);
            const eqItem = {
              equipment_id: equipment.equipment_id,
              equipment_type: equipment.equipment_type,
              equipment_category: equipment.equipment_category,
            };
            newEquipmentItems.push(eqItem);
          });
          if (self.isEquipmentChanged) {
            removeCarrierEquipments(self.carrier_id, idsToRemove)
              .then((response) => {
                addCarrierEquipments(self.carrier_id, newEquipmentItems)
                  .then((res) => {
                    editCarrier(self)
                      .then(() => {
                        rootInstance.carriersPageState.setEditedCarrier(null);
                        if (rootInstance.carriersPageState.isBrokeragePage) {
                          rootInstance.brokeragePageState.setActiveTab(
                            CARRIERS
                          );
                        }
                        setFetchingInProgress(false);
                        rootInstance.carriersPageState.setEditCarrierSuccessModalOpen(
                          true
                        );
                      })
                      .catch((error) => {
                        this.throwSaveCarrierError(error);
                      });
                  })
                  .catch((error) => {
                    this.throwSaveCarrierError(error);
                  });
              })
              .catch((error) => {
                this.throwSaveCarrierError(error);
              });
            setFetchingInProgress(false);
          } else {
            editCarrier(self)
              .then(() => {
                rootInstance.carriersPageState.setEditedCarrier(null);
                if (rootInstance.carriersPageState.isBrokeragePage) {
                  rootInstance.brokeragePageState.setActiveTab(CARRIERS);
                }
                setFetchingInProgress(false);
                rootInstance.carriersPageState.setEditCarrierSuccessModalOpen(
                  true
                );
              })
              .catch((error) => {
                this.throwSaveCarrierError(error);
              });
          }
          // return;
          // setFetchingInProgress(true);
          // editCarrier(self)
          //     .then(() => {
          //         rootInstance.carriersPageState.setEditedCarrier(null);
          //         if (rootInstance.carriersPageState.isBrokeragePage) {
          //             rootInstance.brokeragePageState.setActiveTab(CARRIERS);
          //         }
          //         setFetchingInProgress(false);
          //         rootInstance.carriersPageState.setEditCarrierSuccessModalOpen(
          //             true,
          //         );
          //     })
          //     .catch((error) => {
          //         this.throwSaveCarrierError(error);
          //     });
        }
      },
    };
  });
