import React from 'react';
import { observer } from 'mobx-react';
import {
  ADD_STOP,
  EMPTY_STOP_TABLE_TEXT,
  SHIPMENT_ITINERARY,
} from '../../../constants';
import { useMst } from '../../../state/RootModel';
import { Empty, Table } from 'antd';

const ProbillsSection = () => {
  const {
    shipmentsPageState: { currentShipment, isViewShipmentPage },
    userState: { canAddProbills },
  } = useMst();

  const onAddProbill = () => {
    currentShipment.setNewProbill({});
  };

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header align-center">
        <p>{SHIPMENT_ITINERARY}</p>
        {!isViewShipmentPage && canAddProbills && (
          <button className="btn primary ml20" onClick={onAddProbill}>
            {ADD_STOP}
          </button>
        )}
      </div>
      <Table
        className="probills-table fleet-table"
        rowKey={'probill_id'}
        columns={currentShipment.getProbillsColumns()}
        dataSource={currentShipment.probillsArray}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_STOP_TABLE_TEXT}
            />
          ),
        }}
      />
    </div>
  );
};

export default observer(ProbillsSection);
