import React from 'react';
import { observer } from 'mobx-react';
import { NOT_AVAILABLE } from '../../../constants';

const TackingShipmentDetails = (props) => {
  const { data } = props;

  return (
    <div className="dispatch-map-organize-tour__details tracking-shipment ph10">
      {data?.locations &&
        data.locations.map((stop, index) => (
          <div key={index} className="details-top pl40 pb10">
            <p className="headline mb5 dispatch-map-marker__type text-black">
              {stop?.location_name}
            </p>
            <p className="sub-headline">
              <span className="flex">
                {stop?.address ? stop.address : NOT_AVAILABLE}
              </span>
            </p>
          </div>
        ))}
      {/*<div className="details-top pl40 pb10">*/}
      {/*  <p className="headline mb5 dispatch-map-marker__type text-green">*/}
      {/*    {data?.first_location?.location_name} ({PICKUP_LOCATION})*/}
      {/*  </p>*/}
      {/*  {data?.first_location?.address && (*/}
      {/*    <p className="sub-headline">*/}
      {/*      <span className="flex">{data?.first_location?.address}</span>*/}
      {/*    </p>*/}
      {/*  )}*/}
      {/*</div>*/}
      {/*<div className="has-bl mb30"></div>*/}
      {/*<div className="details-bottom pl40 pb10 mb20 has-bl">*/}
      {/*  <p className="headline mb5 dispatch-map-marker__type text-dkred">*/}
      {/*    {data?.last_location?.location_name} ({DELIVERY_LOCATION})*/}
      {/*  </p>*/}
      {/*  {data?.last_location?.address && (*/}
      {/*    <p className="sub-headline">*/}
      {/*      <span className="flex">{data?.last_location?.address}</span>*/}
      {/*    </p>*/}
      {/*  )}*/}
      {/*</div>*/}
    </div>
  );
};
export default observer(TackingShipmentDetails);
