import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Select, Radio } from 'antd';
import questionMark from '../../../assets/images/question-mark.svg';
import {
  ADVANCED_OPTIONS,
  USER_ROLE,
  DEPARTMENT,
  CHOOSE_DEPARTMENT_TEXT,
  NO_ROLES_ASSIGNED,
} from '../../../constants';

const { Option } = Select;

const AdvancedOptions = () => {
  const {
    employeesPageState: { newEmployee, employeeRolesArray, departmentsArray },
  } = useMst();

  const onCheckedDepartment = (e) => {
    newEmployee.setDepartment(e.target.value);
  };

  return (
    <div className="form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{ADVANCED_OPTIONS}</span>
      </div>
      <div className="section-content align-center">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{USER_ROLE}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            mode={'multiple'}
            className="w100 text-capitalize"
            onChange={newEmployee.setUserAssignedRoles}
            placeholder={
              newEmployee.selectedRoles.length ? null : NO_ROLES_ASSIGNED
            }
            value={newEmployee.selectedRolesIds}
          >
            {employeeRolesArray.map((role) => (
              <Option
                key={role.role_id}
                value={role.role_id}
                className="text-capitalize"
              >
                {role.role_name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="checkbox-wrapper ml50">
          <div className="input-label">
            <span>{DEPARTMENT}</span>
            <img src={questionMark} alt="question mark" className="ml20" />
            <span className="ml10 color-primary text-regular text-italic">
              {CHOOSE_DEPARTMENT_TEXT}
            </span>
          </div>
          <Radio.Group
            value={
              newEmployee.user_department &&
              newEmployee.user_department.department_id
            }
            onChange={onCheckedDepartment}
            className="mt10 text-capitalize"
          >
            {departmentsArray.map((department) => (
              <Radio
                key={department.department_id}
                value={department.department_id}
              >
                {department.department_name}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default observer(AdvancedOptions);
