import React from 'react';
import { observer } from 'mobx-react';
import { DOCUMENTS, EMPTY_DOCUMENTS_TABLE_TEXT } from '../../../constants';
import { useMst } from '../../../state/RootModel';
import plusCircle from '../../../assets/images/plus-circle.svg';
import { Table, Empty } from 'antd';

const DocumentsSection = () => {
  const {
    shipmentsPageState: { currentShipment },
  } = useMst();

  return (
    <div className="page-section shipment-details-section w100">
      <div className="subsection-header align-center">
        <p>{DOCUMENTS}</p>
        <img
          src={plusCircle}
          alt="add detail"
          className="ml20 pointer"
          onClick={() => currentShipment.openAddDocumentModal()}
        />
      </div>
      <div className="subsection-content">
        <Table
          className="drivers-table fleet-table"
          rowKey={'uid'}
          columns={currentShipment?.getDocumentsColumns()}
          dataSource={currentShipment?.documentsArray}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_DOCUMENTS_TABLE_TEXT}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default observer(DocumentsSection);
