import React from 'react';
import { observer } from 'mobx-react';
import { COMPLETED_SHIPMENTS } from '../../../../constants';
import BarChartComponent from '../../../barChart/BarChartComponent';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const bars = [
  {
    value: 0,
    text: '$0.00',
    title: 'September',
  },
  {
    value: 32,
    text: '$32,064.78',
    title: 'October',
  },
  {
    value: 65,
    text: '$30,064.78',
    title: 'November',
  },
  {
    value: 100,
    text: '$31,064.78',
    title: 'December',
  },
];

const CompletedShipmentsComponent = () => {
  return (
    <div className="w40 pl10">
      <div className="diagrams-section-chart completed-shipments-box">
        <div className="align-center justify-between">
          <h3 className="no-wrap">{COMPLETED_SHIPMENTS}</h3>
          <RangePicker value={[moment('2021-01-01'), moment('2021-08-31')]} />
        </div>
        <BarChartComponent bars={bars} />
      </div>
    </div>
  );
};

export default observer(CompletedShipmentsComponent);
