import React from 'react';
import { observer } from 'mobx-react';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import { DRIVER_PAYROLL } from '../../../constants';
import { useMst } from '../../../state/RootModel';
import CompensationsTable from './CompensationsTable';

const DriverPayroll = () => {
  const {
    fleetPageState: { viewedDriver },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedDriver.setIsPayrollSectionOpen(
              !viewedDriver.isPayrollSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedDriver.isPayrollSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {DRIVER_PAYROLL}
          </span>
        </div>
      </div>
      {viewedDriver?.isPayrollSectionOpen && <CompensationsTable />}
    </div>
  );
};

export default observer(DriverPayroll);
