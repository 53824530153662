import {
  changeAccountActiveRole,
  deleteAccountPhoto,
  uploadAccountPhoto,
} from '../../actions/account';
import {
  deleteUserAbsenceDocument,
  deleteUserDocument,
  getAllUserAbsences,
  getUserAbsence,
  getUserAbsenceDocument,
  getUserDocumentation,
  updateUserAbsence,
  uploadUserAbsenceDocument,
  uploadUserDocument,
} from '../../actions/employees';
import { getAllCountries } from '../../actions/common';

const allSettled = require('promise.allsettled');

/** EMPLOOYEE PROMISES */

export const userDocumentation = (employee) => {
  return new Promise((resolve, reject) => {
    getUserDocumentation(employee.user_id)
      .then((documentation) => {
        employee.setDocuments(documentation.data);
        resolve(documentation);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userAbsences = (employee) => {
  return new Promise((resolve, reject) => {
    getAllUserAbsences(employee.user_id)
      .then((absences) => {
        employee.setAbsences(absences.data);
        let documentsPromises = [];
        employee.absencesArray.forEach((absence) => {
          if (
            employee.current_absence_id &&
            employee.current_absence_id === absence.absence_id
          ) {
            employee.setCurrentAbsence(absence.absence_id);
          }
          documentsPromises.push(
            new Promise((resolve, reject) => {
              getUserAbsenceDocument(absence.absence_id)
                .then((document) => {
                  absence.setDocument(document.data);
                  resolve(document);
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );
        });
        allSettled(documentsPromises).then(() => {
          resolve(absences);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const userCurrentAbsence = (employee) => {
  return new Promise((resolve, reject) => {
    getUserAbsence(employee.user_id, employee.current_absence_id)
      .then((absence) => {
        employee.setCurrentAbsence(absence.data.absence_id);
        resolve(absence);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editUserAbsence = (absence) => {
  return new Promise((resolve, reject) => {
    updateUserAbsence(absence)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadAbsenceDocumentPromise = (absence_id, document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', document.originFileObj);
    uploadUserAbsenceDocument(absence_id, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteAbsenceDocumentPromise = (document_id) => {
  return new Promise((resolve, reject) => {
    deleteUserAbsenceDocument(document_id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteEmployeeDocument = (employee_id, document_uid) => {
  return new Promise((resolve, reject) => {
    deleteUserDocument(employee_id, document_uid)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadEmployeeDocument = (employee_id, document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    const documentType = document.originFileObj.documentType;
    formData.append('file', document.originFileObj);
    uploadUserDocument(employee_id, formData, documentType)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/** CURRENT USER PROMISES */

export const deleteUserPhoto = (document_uid) => {
  return new Promise((resolve, reject) => {
    deleteAccountPhoto(document_uid)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadUserPhoto = (document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', document.originFileObj);
    uploadAccountPhoto(formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeUserActiveRole = (role_id) => {
  return new Promise((resolve, reject) => {
    changeAccountActiveRole(role_id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const allCountries = (employee) => {
  return new Promise((resolve, reject) => {
    getAllCountries()
      .then((response) => {
        employee.setCountries(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
