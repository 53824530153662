import { types } from 'mobx-state-tree';
import { rootInstance } from '../../RootModel';

export const DriversCompensationsModel = types
  .model('CompensationsModel', {
    compensation_id: types.identifierNumber,
    compensation_name: types.maybe(types.maybeNull(types.string)),
    compensation_type: types.maybe(types.maybeNull(types.string)),
    period: types.maybe(types.maybeNull(types.string)),
    compensation_values: types.frozen([]),
    compensation_deductions: types.frozen([]),
    is_active: types.boolean,
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      getEditingValue(compensation_value_id) {
        let selectedValue = self.compensation_values.filter(
          (org) => org.compensation_value_id === compensation_value_id
        )[0];
        rootInstance.payrollPageState.setEditingValue(selectedValue);
      },
      getEditingDeduction(compensation_deduction_id) {
        let selectedDeduction = self.compensation_deductions.filter(
          (org) => org.compensation_deduction_id === compensation_deduction_id
        )[0];
        rootInstance.payrollPageState.setEditingDeduction(selectedDeduction);
      },
      setCompensationType(type) {
        self.compensation_type = type;
      },

      setCompensationName(value) {
        self.compensation_name = value;
      },
      setCompensationPeriod(period) {
        self.period = period;
      },
    };
  });
