import { types } from 'mobx-state-tree';

export const EquipmentRelatedModel = types
  .model('EquipmentRelatedModel', {
    carrier_equipment_id: types.identifierNumber,
    equipment_id: types.maybe(types.maybeNull(types.number)),
    equipment_category: types.maybe(types.maybeNull(types.string)),
    equipment_type: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
