import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { ADD_ABSENCE_TEXT, WORKING_STATUS } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PageRoutes from '../../../utils/PageRoutes';
import Link from '../../Link';

const localizer = momentLocalizer(moment);

const WorkingStatusComponent = () => {
  const {
    fleetPageState: { viewedDriver },
  } = useMst();

  const onEventClick = (absence) => {
    viewedDriver.setViewedAbsence(absence.absence_id);
  };

  return (
    <div className="form-page-section view-driver-section pb20">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedDriver.setIsWorkingStatusSectionOpen(
              !viewedDriver.isWorkingStatusSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedDriver.isWorkingStatusSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {WORKING_STATUS}
          </span>
        </div>
        <Link
          className={'btn primary ml10 ph20 pv0'}
          view={PageRoutes.EditDriver.name}
          params={{
            driverId: viewedDriver.driver_id,
            section: 'working-status',
          }}
        >
          {ADD_ABSENCE_TEXT}
        </Link>
        {/*<button*/}
        {/*    className={'btn primary ml10 ph20 pv0'}*/}
        {/*    type="button">{ADD_ABSENCE_TEXT}</button>*/}
      </div>
      {viewedDriver?.isWorkingStatusSectionOpen && (
        <div className="flex">
          <Calendar
            localizer={localizer}
            events={viewedDriver.absencesArray}
            style={{ height: 500, width: '100%' }}
            defaultView="month"
            views={['month']}
            eventPropGetter={(event) => {
              return {
                style: { backgroundColor: event.color },
              };
            }}
            onSelectEvent={onEventClick}
          />
        </div>
      )}
    </div>
  );
};

export default observer(WorkingStatusComponent);
