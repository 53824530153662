import {
  COMPENSATION_DEDUCTION_NAME,
  COMPENSATION_VALUE_BASED_ON,
  COMPENSATION_VALUE_CURRENCY,
  COMPENSATION_VALUE_DEFAULT_VALUE,
  COMPENSATION_VALUE_NAME,
  COMPENSATION_VALUE_RATE,
  DEDUCTIONS,
  VALUES,
} from '../../../constants/payroll';

export const valuesOrDeductionsColumnsFunc = (rootInstance, type) => ({
  '1': {
    title:
      type === VALUES
        ? COMPENSATION_VALUE_NAME
        : type === DEDUCTIONS
        ? COMPENSATION_DEDUCTION_NAME
        : null,
    dataIndex:
      type === VALUES
        ? 'compensation_value'
        : type === DEDUCTIONS
        ? 'deduction_type'
        : null,
    key:
      type === VALUES
        ? 'compensation_value_name'
        : type === DEDUCTIONS
        ? 'deduction_type_name'
        : null,
    width: '25%',
  },
  '2': {
    title: COMPENSATION_VALUE_BASED_ON,
    dataIndex: 'based_on',
    key: 'compensation_based_on',
    width: '15%',
  },
  '3': {
    title: COMPENSATION_VALUE_RATE,
    dataIndex: 'rate',
    key: 'compensation_rate',
    width: '15%',
  },
  '4': {
    title: COMPENSATION_VALUE_DEFAULT_VALUE,
    dataIndex: 'default_value',
    key: 'compensation_default_value',
    width: '15%',
  },
  '5': {
    title: COMPENSATION_VALUE_CURRENCY,
    dataIndex: 'currency',
    key: 'compensation_currency',
    width: '15%',
  },
});
