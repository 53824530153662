import { getSnapshot, types } from 'mobx-state-tree';
import {
  ORGANIZATION_ADDRESS_REQUIRED,
  ORGANIZATION_CITY_REQUIRED,
  ORGANIZATION_COUNTRY_REQUIRED,
  ORGANIZATION_EMAIL_INVALID,
  ORGANIZATION_EMAIL_REQUIRED,
  ORGANIZATION_NAME_REQUIRED,
  ORGANIZATION_OTHER_FIELDS_REQUIRED,
  ORGANIZATION_PHONE_REQUIRED,
  ORGANIZATION_STATE_REQUIRED,
} from '../../constants/organizations/organizations';
import {
  getErrorText,
  setFetchingInProgress,
  validateEmail,
} from '../../utils/methods';
import {
  editOrganization,
  setOrganizationDefaultLocation,
} from '../../actions/organizations';
import { notification } from 'antd';
import {
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COMPANY_REQUIRED,
  COUNTRY_REQUIRED,
  FETCH_LOCATIONS_ERROR,
  LOCATION_NAME_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  STATE_REQUIRED,
} from '../../constants';
import {
  deleteOrganizationPhoto,
  getClients,
  uploadOrganizationPhoto,
} from '../../promises/organizations';
import { OrganizationImageModel } from './OrganizationImageModel';
import { rootInstance } from '../RootModel';
import { isEqual } from 'lodash';
import { LocationLightModel } from '../locations/LocationLightModel';
import { ClientModel } from '../clients/ClientModel';
import {
  addLocation,
  editLocation,
  getLocation,
} from '../../actions/locations';
import { LocationModel } from '../locations/LocationModel';

export const OrganizationModel = types
  .model('OrganizationModel', {
    organization_id: types.identifierNumber,
    organization_name: types.maybe(types.maybeNull(types.string)),
    mc: types.maybe(types.maybeNull(types.string)),
    dot: types.maybe(types.maybeNull(types.string)),
    cvor: types.maybe(types.maybeNull(types.string)),
    super_admin_email: types.maybe(types.maybeNull(types.string)),

    logo_s3_file_key: types.maybe(types.maybeNull(types.string)),
    thumbnail_s3_file_key: types.maybe(types.maybeNull(types.string)),
    background_image_s3_file_key: types.maybe(types.maybeNull(types.string)),

    description: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    address: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    organization_site: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    default_location_id: types.maybe(types.maybeNull(types.number)),

    ifta: types.maybe(types.maybeNull(types.string)),
    fein: types.maybe(types.maybeNull(types.string)),
    duns_number: types.maybe(types.maybeNull(types.string)),
    scac: types.maybe(types.maybeNull(types.string)),
    cbsa: types.maybe(types.maybeNull(types.string)),
    ctpat_number: types.maybe(types.maybeNull(types.string)),
    pip: types.maybe(types.maybeNull(types.string)),
    fast: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),

    errorText: types.maybe(types.maybeNull(types.string)),
    isOrganizationEdited: false,
    logo: types.maybe(types.maybeNull(OrganizationImageModel)),
    thumbnail: types.maybe(types.maybeNull(OrganizationImageModel)),
    background: types.maybe(types.maybeNull(OrganizationImageModel)),

    isOrganizationLocationModalOpen: false,
    isEditOrganizationLocationModalOpen: false,
    owners: types.map(ClientModel),
    newLocation: types.maybe(LocationLightModel),
    editedLocation: types.maybe(LocationModel),
    organizationDefaultLocation: types.maybe(LocationModel),
    hasCompany: false,

    defaultLocationAdded: false,
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get getOwnersArray() {
        return Array.from(self.owners.values());
      },
      get isChangedDuringEdit() {
        const snapshot = getSnapshot(self);
        return !isEqual(
          snapshot,
          rootInstance.organizationState.initialLoadedOrganization
        );
      },
    };
  })
  .actions((self) => {
    return {
      setDefaultLocationAdded(value) {
        self.defaultLocationAdded = value;
      },
      setHasCompany(value) {
        self.hasCompany = value;
      },
      setOrganizationDefaultLocation(location) {
        self.organizationDefaultLocation = location;
      },
      setEditedLocation(location) {
        self.editedLocation = location;
      },
      getLocationToEdit() {
        setFetchingInProgress(true);
        getLocation(self.default_location_id)
          .then((response) => {
            setFetchingInProgress(false);
            this.setEditedLocation(response.data);
            this.loadLocationClient();
            this.setEditOrganizationLocationModalOpen(true);
          })
          .catch((error) => {
            console.log('error: ', error);
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      setNewLocation(location) {
        self.newLocation = location;
      },
      setNewLocationClient(clients) {
        self.owners.clear();
        clients.forEach((item) => {
          self.owners.put({
            ...item,
          });
        });
      },
      loadLocationClient() {
        getClients(self.organization_name)
          .then((response) => {
            this.setNewLocationClient(response);
            if (self.newLocation) {
              self.newLocation.setOwnerId(response[0].client_id);
            } else if (self.editedLocation) {
              self.editedLocation.setOwnerId(response[0].client_id);
            }
            if (self.newLocation) {
              this.setOrganizationLocationModalOpen(true);
            }
          })
          .catch((error) => {
            console.log('Error: ', error);
          });
      },
      setOrganizationLocationModalOpen(value) {
        self.isOrganizationLocationModalOpen = value;
      },
      setEditOrganizationLocationModalOpen(value) {
        self.isEditOrganizationLocationModalOpen = value;
      },
      setOrganizationLogo(logo) {
        self.logo = logo;
      },
      setOrganizationThumbnail(thumbnail) {
        self.thumbnail = thumbnail;
      },
      setOrganizationBackground(background) {
        self.background = background;
      },
      setOrganizationName(value) {
        self.organization_name = value;
      },
      setOrganizationMC(value) {
        self.mc = value;
      },
      setOrganizationDOT(value) {
        self.dot = value;
      },
      setOrganizationCVOR(value) {
        self.cvor = value;
      },
      setOrganizationAdminEmail(value) {
        self.super_admin_email = value;
      },
      setOrganizationEmail(value) {
        self.email = value;
      },
      setOrganizationDescription(value) {
        self.description = value;
      },
      setOrganizationPostalCode(value) {
        self.postal_code = value;
      },
      setOrganizationCity(value) {
        self.city = value;
      },
      setOrganizationState(value) {
        self.state = value;
      },
      setOrganizationCountry(value) {
        self.country = value;
      },
      setOrganizationAddress(value) {
        self.address = value;
      },
      setOrganizationPhoneNumber(value) {
        self.phone_number = value;
      },
      setOrganizationWebsite(value) {
        self.organization_site = value;
      },
      setIfta(value) {
        self.ifta = value;
      },
      setFein(value) {
        self.fein = value;
      },
      setDunsNumber(value) {
        self.duns_number = value;
      },
      setScac(value) {
        self.scac = value;
      },
      setCbsa(value) {
        self.cbsa = value;
      },
      setCtpatNumber(value) {
        self.ctpat_number = value;
      },
      setPip(value) {
        self.pip = value;
      },
      setFast(value) {
        self.fast = value;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setIsOrganizationEdited(value) {
        self.isOrganizationEdited = value;
      },
      validateEditedOrganization() {
        const emailValid = validateEmail(self.email);
        if (!self.organization_name) {
          this.setErrorText(ORGANIZATION_NAME_REQUIRED);
        } else if (!self.email) {
          this.setErrorText(ORGANIZATION_EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(ORGANIZATION_EMAIL_INVALID);
        } else if (!self.phone_number) {
          this.setErrorText(ORGANIZATION_PHONE_REQUIRED);
        } else if (!self.state) {
          this.setErrorText(ORGANIZATION_STATE_REQUIRED);
        } else if (!self.city) {
          this.setErrorText(ORGANIZATION_CITY_REQUIRED);
        } else if (!self.country) {
          this.setErrorText(ORGANIZATION_COUNTRY_REQUIRED);
        } else if (!self.address) {
          this.setErrorText(ORGANIZATION_ADDRESS_REQUIRED);
        } else if (!self.mc && !self.dot && !self.cvor) {
          this.setErrorText(ORGANIZATION_OTHER_FIELDS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      validateNewLocationForm() {
        if (!self.newLocation.location_name) {
          this.setErrorText(LOCATION_NAME_REQUIRED);
        } else if (!self.newLocation.owner_id) {
          this.setErrorText(COMPANY_REQUIRED);
        } else if (!self.newLocation.phone_number) {
          this.setErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!self.newLocation.country) {
          this.setErrorText(COUNTRY_REQUIRED);
        } else if (!self.newLocation.state) {
          this.setErrorText(STATE_REQUIRED);
        } else if (!self.newLocation.city) {
          this.setErrorText(CITY_REQUIRED);
        } else if (!self.newLocation.address) {
          this.setErrorText(ADDRESS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      validateEditedLocationForm() {
        if (!self.editedLocation.location_name) {
          this.setErrorText(LOCATION_NAME_REQUIRED);
        } else if (!self.editedLocation.owner_id) {
          this.setErrorText(COMPANY_REQUIRED);
        } else if (!self.editedLocation.phone_number) {
          this.setErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!self.editedLocation.country) {
          this.setErrorText(COUNTRY_REQUIRED);
        } else if (!self.editedLocation.state) {
          this.setErrorText(STATE_REQUIRED);
        } else if (!self.editedLocation.city) {
          this.setErrorText(CITY_REQUIRED);
        } else if (!self.editedLocation.address) {
          this.setErrorText(ADDRESS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      addOrganizationDefaultLocation() {
        this.validateNewLocationForm();
        if (this.validateNewLocationForm()) {
          setFetchingInProgress(true);
          addLocation(self.newLocation)
            .then((location) => {
              const location_id = location.data.location_id;
              setOrganizationDefaultLocation(self.organization_id, location_id)
                .then((response) => {
                  setFetchingInProgress(false);
                  this.setOrganizationLocationModalOpen(false);
                  this.setDefaultLocationAdded(true);
                })
                .catch((error) => {
                  console.log('error: ', error);
                  setFetchingInProgress(false);
                  const errorText = getErrorText(error);
                  notification.error({
                    message: FETCH_LOCATIONS_ERROR,
                    description: errorText,
                  });
                });
            })
            .catch((error) => {
              console.log('error: ', error);
              setFetchingInProgress(false);
              const errorText = getErrorText(error);
              notification.error({
                message: FETCH_LOCATIONS_ERROR,
                description: errorText,
              });
            });
        }
      },
      editOrganizationDefaultLocation() {
        this.validateEditedLocationForm();
        if (this.validateEditedLocationForm()) {
          setFetchingInProgress(true);
          editLocation(self.editedLocation)
            .then((location) => {
              this.setEditOrganizationLocationModalOpen(false);
              setFetchingInProgress(false);
            })
            .catch((error) => {
              console.log('error: ', error);
              setFetchingInProgress(false);
              const errorText = getErrorText(error);
              notification.error({
                message: FETCH_LOCATIONS_ERROR,
                description: errorText,
              });
            });
        }
      },
      updateOrganization(documentsToUpload, documentsToDelete) {
        /**
         * If form is valid, delete all documents (call: DELETE /organizations/${organization_id}/photos?type=Logo|Thumbnail|Background Image)
         * After images are deleted, reupload them
         */
        if (this.validateEditedOrganization()) {
          setFetchingInProgress(true);
          let promises = [];
          if (documentsToDelete.length > 0) {
            documentsToDelete.forEach((document) => {
              promises.push(
                deleteOrganizationPhoto(self.organization_id, document.type)
              );
            });
          }

          if (documentsToUpload.length > 0) {
            documentsToUpload.forEach((document) => {
              promises.push(
                uploadOrganizationPhoto(self.organization_id, document)
              );
            });
          }

          Promise.all(promises)
            .then(() => {
              editOrganization(self.organization_id, self)
                .then((response) => {
                  setFetchingInProgress(false);
                  this.setIsOrganizationEdited(true);
                })
                .catch((error) => {
                  setFetchingInProgress(false);
                  const errorText = getErrorText(error);
                  notification.error({
                    message: FETCH_LOCATIONS_ERROR,
                    description: errorText,
                  });
                });
            })
            .catch((error) => {
              console.log('Error: ', error);
              setFetchingInProgress(false);
              const errorText = getErrorText(error);
              notification.error({
                message: FETCH_LOCATIONS_ERROR,
                description: errorText,
              });
            });
        }
      },
      deleteOrganization(id) {
        console.log('Delete organization: ', self.organization_id);
      },
      changeOrganizationAdmin(id) {
        console.log(
          `Change admin for organization: ${id} to ${self.super_admin_email}`
        );
      },
    };
  });
