import React from 'react';
import { useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import AddEmployeeComponent from '../../components/employees/AddEmployee/AddEmployeeComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import SuccessModal from '../../components/SuccessModal';
import PageRoutes from '../../utils/PageRoutes';
import {
  ADD_EMPLOYEE,
  MODAL_SUCCESS_ADD_EMPLOYEE_TEXT,
  MODAL_SUCCESS_ADD_EMPLOYEE_BUTTON_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../constants';

const AddEmployeePage = () => {
  const {
    employeesPageState: {
      addEmployeeSuccessModalOpen,
      setAddEmployeeSuccessModalOpen,
    },
  } = useMst();

  const backLink = PageRoutes.Employees.name;

  return (
    <div>
      <div className="page add-employee-page">
        <BreadcrumsComponent
          page={`${ADD_EMPLOYEE}`}
          breadcrumbsBackLink={PageRoutes.Employees.name}
        />
        <SuccessModal
          title={MODAL_SUCCESS_TITLE}
          text={MODAL_SUCCESS_ADD_EMPLOYEE_TEXT}
          buttonText={MODAL_SUCCESS_ADD_EMPLOYEE_BUTTON_TEXT}
          backLink={backLink}
          open={addEmployeeSuccessModalOpen}
          closeModal={() => setAddEmployeeSuccessModalOpen(false)}
        />
        <div className="page-content">
          <AddEmployeeComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(AddEmployeePage);
