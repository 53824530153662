import { notification } from 'antd';
import { types } from 'mobx-state-tree';
import {
  COMPENSATION_NAME_REQUIRED,
  COMPENSATION_PAYMENT_PERIOD_REQUIRED,
  COMPENSATION_TYPE_REQUIRED,
  ERROR_DELETING_COMPENSATION,
} from '../../../constants/payroll';
import {
  deleteCompensationPromise,
  editCompensationPromise,
} from '../../../promises/payroll';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';

export const CompensationsModel = types
  .model('CompensationsModel', {
    compensation_id: types.identifierNumber,
    compensation_name: types.maybe(types.maybeNull(types.string)),
    compensation_type: types.maybe(types.maybeNull(types.string)),
    period: types.maybe(types.maybeNull(types.string)),
    compensation_values: types.frozen([]),
    compensation_deductions: types.frozen([]),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get compensationsValuesArray() {
        return Array.from(self.compensation_values.values());
      },
      get compensationsDeductionsArray() {
        return Array.from(self.compensation_deductions.values());
      },
    };
  })
  .actions((self) => {
    return {
      getValueToBeDeleted(compensation_value_id) {
        let selectedValue = self.compensation_values.filter(
          (org) => org.compensation_value_id === compensation_value_id
        )[0];
        rootInstance.payrollPageState.setValueToBeDeleted(selectedValue);
      },
      getDeductionToBeDeleted(deduction_id) {
        let selectedDeduction = self.compensation_deductions.filter(
          (org) => org.deduction_id === deduction_id
        )[0];
        rootInstance.payrollPageState.setDeductionToBeDeleted(
          selectedDeduction
        );
      },
      getEditingValue(compensation_value_id) {
        let selectedValue = self.compensation_values.filter(
          (org) => org.compensation_value_id === compensation_value_id
        )[0];
        console.log(selectedValue);
        rootInstance.payrollPageState.setEditingValue(selectedValue);
      },
      getEditingDeduction(compensation_deduction_id) {
        let selectedDeduction = self.compensation_deductions.filter(
          (org) => org.compensation_deduction_id === compensation_deduction_id
        )[0];
        rootInstance.payrollPageState.setEditingDeduction(selectedDeduction);
      },
      setCompensationType(type) {
        self.compensation_type = type;
      },

      setCompensationName(value) {
        self.compensation_name = value;
      },
      setCompensationPeriod(period) {
        self.period = period;
      },
      validateForm() {
        if (!self.compensation_name) {
          this.setErrorText(COMPENSATION_NAME_REQUIRED);
        }
        if (!self.period) {
          this.setErrorText(COMPENSATION_PAYMENT_PERIOD_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      clearForm() {
        this.setCompensationName(null);
        this.setErrorText(null);
      },

      deleteCompensation(id) {
        setFetchingInProgress(true);
        deleteCompensationPromise(id)
          .then(() => {
            setFetchingInProgress(false);
            rootInstance.payrollPageState.setCompensationToBeDeleted(null);
            rootInstance.payrollPageState.setIsDeleteCompensationModalOpened(
              false
            );
            rootInstance.payrollPageState.setSelectedCompensationStructure(
              null
            );
            rootInstance.payrollPageState.getCompensationStructures();
          })

          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION,
              description: errorText,
            });
          });
      },
    };
  });
