import React from 'react';
import LoginPage from '../views/login/Login';
import ResetPasswordPage from '../views/login/ResetPassword';
import CreatePasswordPage from '../views/login/CreatePassword';
import OrganizationsPage from '../views/organization/Organization';
import OrganizationSettingsPage from '../views/organizationSettings/OrganizationSettings';
import DashboardPage from '../views/dashboard/Dashboard';
import EmployeesPage from '../views/employees/Employees';
import AddEmployeePage from '../views/employees/AddEmployee';
import EditEmployeePage from '../views/employees/EditEmployee';
import ViewEmployeePage from '../views/employees/ViewEmployee';
import AccountPage from '../views/account/Account';
import FleetPage from '../views/fleet/Fleet';
import AddTruckPage from '../views/fleet/AddTruck';
import AddTrailerPage from '../views/fleet/AddTrailer';
import AddDriverPage from '../views/fleet/AddDriver';
import EditDriverPage from '../views/fleet/EditDriver';
import EditTruckPage from '../views/fleet/EditTruck';
import EditTrailerPage from '../views/fleet/EditTrailer';
import ViewTrailerPage from '../views/fleet/ViewTrailer';
import ViewDriverPage from '../views/fleet/ViewDriver';
import ViewTruckPage from '../views/fleet/ViewTruck';
import BrokeragePage from '../views/brokerage/Brokerage';
import CreateShipmentPage from '../views/brokerage/CreateShipment';
import EditShipmentPage from '../views/brokerage/EditShipment';
import ViewShipmentPage from '../views/brokerage/ViewShipment';
import ViewCarrierPage from '../views/brokerage/ViewCarrier';
import Dispatch from '../views/dispatch/Dispatch';
import PayrollPage from '../views/payroll/Payroll';
import ReportsPage from '../views/reports/Reports';
import SupportPage from '../views/support/Support';
import AddCompensationPage from '../views/payroll/AddCompensation';
import EditCompensationPage from '../views/payroll/EditCompensation';
import { getParent, getRoot } from 'mobx-state-tree';
import { permissions } from '../constants/permissions';
import {
  ACCOUNT,
  ADD_DRIVER,
  ADD_EMPLOYEE,
  ADD_ROLE,
  ADD_TRAILER,
  ADD_TRUCK,
  ADD_COMPENSATION,
  BROKERAGE,
  CREATE_SHIPMENT,
  CREATE_PASSWORD,
  DASHBOARD,
  DISPATCH,
  EDIT_DRIVER,
  EDIT_EMPLOYEE,
  EDIT_SHIPMENT,
  EDIT_ORGANIZATION,
  EDIT_ROLE,
  EDIT_TRAILER,
  EDIT_TRUCK,
  EMPLOYEES,
  FLEET,
  LOGIN,
  ORGANIZATION,
  ORGANIZATIONS,
  PAYROLL,
  REPORTS,
  RESET_PASSWORD,
  SUPPORT,
  VIEW_CARRIER,
  VIEW_DRIVER,
  VIEW_EMPLOYEE,
  VIEW_SHIPMENT,
  VIEW_TRAILER,
  VIEW_TRUCK,
  SHIPMENT_BOARD,
  EDIT_COMPENSATION,
} from '../constants/menu';
import { isCurrentUser } from './methods';
import { SHIPMENTS } from '../constants';
import AddUserRolePage from '../views/role/AddUserRole';
import EditUserRolePage from '../views/role/EditUserRole';
import EditOrganization from '../views/organization/EditOrganization';

const PREFIX_PATH = '';
window['getRoot'] = getRoot;
window['getParent'] = getParent;

export const PageRoutes = {
  Organizations: {
    component: <OrganizationsPage />,
    name: ORGANIZATIONS,
    path: `${PREFIX_PATH}/organizations`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_ORGANIZATIONS],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(ORGANIZATIONS);
        const { organizationState } = getRoot(self);
        organizationState.beforeEnterPage();
      },
      beforeExit(self, params) {},
    },
  },
  EditOrganization: {
    component: <EditOrganization />,
    name: EDIT_ORGANIZATION,
    path: `${PREFIX_PATH}/organizations/edit/:organizationId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_ORGANIZATION],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(ORGANIZATIONS);
        const id = params.organizationId;
        const { organizationState } = getRoot(self);
        organizationState.getCountriesList();
        organizationState.getEditedOrganization(id);
      },
      beforeExit(self, params) {},
    },
  },
  Organization: {
    component: <OrganizationSettingsPage />,
    name: ORGANIZATION,
    path: `${PREFIX_PATH}/organization`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_ORGANIZATION],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(ORGANIZATION);
        const { currentOrganization } = getRoot(self);
        currentOrganization.beforeEnterOrganizationPage();
      },
      beforeExit(self, params) {},
    },
  },
  AddUserRole: {
    component: <AddUserRolePage />,
    name: ADD_ROLE,
    path: `${PREFIX_PATH}/role/add`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.ADD_ROLES],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(ORGANIZATION);
        const { currentOrganization } = getRoot(self);
        currentOrganization.beforeEnterAddRolePage();
      },
      beforeExit(self, params) {},
    },
  },
  EditUserRole: {
    component: <EditUserRolePage />,
    name: EDIT_ROLE,
    path: `${PREFIX_PATH}/role/edit/:roleId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_ROLES],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(ORGANIZATION);
        const id = params.roleId;
        const { currentOrganization } = getRoot(self);
        currentOrganization.getEditedRole(id);
      },
      beforeExit(self, params) {},
    },
  },
  Dashboard: {
    component: <DashboardPage />,
    name: DASHBOARD,
    path: `${PREFIX_PATH}/`,
    isAuthenticationRequired: true,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(DASHBOARD);
      },
      beforeExit(self, params) {},
    },
  },
  Employees: {
    component: <EmployeesPage />,
    name: EMPLOYEES,
    path: `${PREFIX_PATH}/employees`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_USERS],
    hooks: {
      beforeEnter(self) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(EMPLOYEES);
        const { employeesPageState } = getRoot(self);
        employeesPageState.beforeEnterEmployeesRoute();
      },
      beforeExit(self, params) {
        const { employeesPageState } = getRoot(self);
        employeesPageState.beforeExitEmployeesRoute();
      },
    },
  },
  Account: {
    component: <AccountPage />,
    name: ACCOUNT,
    path: `${PREFIX_PATH}/account`,
    isAuthenticationRequired: true,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(null);
        const { loginForm } = getRoot(self);
        loginForm.getCurrentUser();
      },
      beforeExit(self, params) {
        const { loginForm } = getRoot(self);
        loginForm.setCurrentUser(null);
      },
    },
  },
  AddEmployee: {
    component: <AddEmployeePage />,
    name: ADD_EMPLOYEE,
    path: `${PREFIX_PATH}/employee/add`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.ADD_NEW_USERS],
    hooks: {
      beforeEnter(self) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(EMPLOYEES);
        const { employeesPageState } = getRoot(self);
        employeesPageState.beforeAddEmployeeEnterRoute();
      },
      beforeExit(self, params) {
        const { employeesPageState } = getRoot(self);
        employeesPageState.newEmployee.clearForm();
      },
    },
  },
  EditEmployee: {
    component: <EditEmployeePage />,
    name: EDIT_EMPLOYEE,
    path: `${PREFIX_PATH}/employee/edit/:employeeId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_USERS],
    hooks: {
      onEnter(self, params, queryParams) {
        if (queryParams?.section) {
          setTimeout(() => {
            const body = document.getElementById('working-status');
            window.scrollTo({
              top: body.offsetTop - 100,
              behavior: 'smooth',
            });
          }, 500);
        }
      },
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        const { router } = getRoot(self);
        sideMenuState.setActiveItem(EMPLOYEES);
        const id = params.employeeId;
        const { employeesPageState } = getRoot(self);
        if (isCurrentUser(id)) {
          router.setView(router.views.get(PageRoutes.Account.name));
        } else {
          employeesPageState.beforeEditEmployeeEnterRoute(id);
        }
      },
      beforeExit(self, params) {
        const { employeesPageState } = getRoot(self);
        employeesPageState.setEditedEmployee(null);
      },
    },
  },
  ViewEmployee: {
    component: <ViewEmployeePage />,
    name: VIEW_EMPLOYEE,
    path: `${PREFIX_PATH}/employee/view/:employeeId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_USERS],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(EMPLOYEES);
        const id = params.employeeId;
        const { employeesPageState } = getRoot(self);
        employeesPageState.beforeViewEmployeeEnterRoute(id);
      },
      beforeExit(self, params) {
        const { employeesPageState } = getRoot(self);
        employeesPageState.beforeViewEmployeeExitRoute();
      },
    },
  },
  Fleet: {
    component: <FleetPage />,
    name: FLEET,
    path: `${PREFIX_PATH}/fleet`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_VEHICLES, permissions.VIEW_DRIVERS],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeFleetRouteEnter();
      },
      beforeExit(self, params) {},
    },
  },
  AddTruck: {
    component: <AddTruckPage />,
    name: ADD_TRUCK,
    path: `${PREFIX_PATH}/trucks/add`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.ADD_VEHICLES],
    hooks: {
      beforeEnter(self) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeAddTruckRouteEnter();
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.newTruck.clearForm();
      },
    },
  },
  EditTruck: {
    component: <EditTruckPage />,
    name: EDIT_TRUCK,
    path: `${PREFIX_PATH}/truck/edit/:truckId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_VEHICLES],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const id = params.truckId;
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeEditTruckRouteEnter(id);
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeEditTruckRouteExit();
      },
    },
  },
  ViewTruck: {
    component: <ViewTruckPage />,
    name: VIEW_TRUCK,
    path: `${PREFIX_PATH}/truck/view/:truckId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_VEHICLES],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const id = params.truckId;
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeViewTruckRouteEnter(id);
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeViewTruckRouteExit();
      },
    },
  },
  AddTrailer: {
    component: <AddTrailerPage />,
    name: ADD_TRAILER,
    path: `${PREFIX_PATH}/trailers/add`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.ADD_VEHICLES],
    hooks: {
      beforeEnter(self) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeAddTrailerRouteEnter();
      },
    },
  },
  EditTrailer: {
    component: <EditTrailerPage />,
    name: EDIT_TRAILER,
    path: `${PREFIX_PATH}/trailer/edit/:trailerId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_VEHICLES],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const id = params.trailerId;
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeEditTrailerRouteEnter(id);
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeEditTrailerRouteExit();
      },
    },
  },
  ViewTrailer: {
    component: <ViewTrailerPage />,
    name: VIEW_TRAILER,
    path: `${PREFIX_PATH}/trailer/view/:trailerId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_VEHICLES],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const id = params.trailerId;
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeViewTrailerRouteEnter(id);
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeViewTrailerRouteExit();
      },
    },
  },
  AddDriver: {
    component: <AddDriverPage />,
    name: ADD_DRIVER,
    path: `${PREFIX_PATH}/drivers/add`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.ADD_DRIVERS],
    hooks: {
      beforeEnter(self) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeAddDriverRouteEnter();
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.newDriver.clearForm();
      },
    },
  },
  EditDriver: {
    component: <EditDriverPage />,
    name: EDIT_DRIVER,
    path: `${PREFIX_PATH}/driver/edit/:driverId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_DRIVERS],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const id = params.driverId;
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeEditDriverRouteEnter(id);
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeEditDriverRouteExit();
      },
    },
  },
  ViewDriver: {
    component: <ViewDriverPage />,
    name: VIEW_DRIVER,
    path: `${PREFIX_PATH}/driver/view/:driverId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_DRIVERS],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(FLEET);
        const id = params.driverId;
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeViewDriverRouteEnter(id);
      },
      beforeExit(self, params) {
        const { fleetPageState } = getRoot(self);
        fleetPageState.beforeViewDriverRouteExit();
      },
    },
  },
  Brokerage: {
    component: <BrokeragePage />,
    name: BROKERAGE,
    path: `${PREFIX_PATH}/brokerage`,
    isAuthenticationRequired: true,
    limitedTo: [
      permissions.VIEW_SHIPMENTS,
      permissions.VIEW_CLIENTS,
      permissions.VIEW_LOCATIONS,
      permissions.VIEW_CARRIERS,
      permissions.VIEW_SHIPMENT_BILLING_CHARGES,
      permissions.VIEW_SHIPMENT_ACTIONS,
      permissions.VIEW_SHIPMENT_DOCUMENT_TYPES,
    ],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState, brokeragePageState } = getRoot(self);
        sideMenuState.setActiveItem(SHIPMENT_BOARD);
        brokeragePageState.beforePageEnter();
      },
      beforeExit(self, params) {
        const { brokeragePageState, router } = getRoot(self);
        router.nextView.name !== SHIPMENT_BOARD &&
          brokeragePageState.setActiveTab(SHIPMENTS);
      },
    },
  },
  CreateShipment: {
    component: <CreateShipmentPage />,
    name: CREATE_SHIPMENT,
    path: `${PREFIX_PATH}/shipment/create`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.ADD_SHIPMENTS],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(SHIPMENT_BOARD);
        const { shipmentsPageState } = getRoot(self);
        shipmentsPageState.beforeEnterCreateShipmentPage();
      },
      beforeExit(self, params) {
        const { shipmentsPageState } = getRoot(self);
        shipmentsPageState.beforeExitCreateShipmentPage();
      },
    },
  },
  EditShipment: {
    component: <EditShipmentPage />,
    name: EDIT_SHIPMENT,
    path: `${PREFIX_PATH}/shipment/edit/:shipmentId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_SHIPMENTS],
    hooks: {
      beforeEnter(self, params) {
        const id = params.shipmentId;
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(SHIPMENT_BOARD);
        const { shipmentsPageState } = getRoot(self);
        shipmentsPageState.getAvailableCreateShipmentStatuses();
        shipmentsPageState.beforeEnterEditShipmentPage(id);
      },
      beforeExit(self, params) {
        const { shipmentsPageState } = getRoot(self);
        shipmentsPageState.beforeExitEditShipmentPage();
      },
    },
  },
  ViewShipment: {
    component: <ViewShipmentPage />,
    name: VIEW_SHIPMENT,
    path: `${PREFIX_PATH}/shipment/view/:shipmentId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_SHIPMENTS],
    hooks: {
      beforeEnter(self, params) {
        const id = params.shipmentId;
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(SHIPMENT_BOARD);
        const { shipmentsPageState } = getRoot(self);
        shipmentsPageState.getAvailableCreateShipmentStatuses();
        shipmentsPageState.beforeEnterViewShipmentPage(id);
      },
      beforeExit(self, params) {
        const { shipmentsPageState } = getRoot(self);
        shipmentsPageState.beforeExitViewShipmentPage();
      },
    },
  },
  ViewCarrier: {
    component: <ViewCarrierPage />,
    name: VIEW_CARRIER,
    path: `${PREFIX_PATH}/carrier/view/:carrierId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.VIEW_CARRIERS],
    hooks: {
      beforeEnter(self, params) {
        const id = params.carrierId;
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(SHIPMENT_BOARD);
        const { carriersPageState } = getRoot(self);
        carriersPageState.beforeEnterViewCarrierPage(id);
      },
      beforeExit(self, params) {
        const { carriersPageState } = getRoot(self);
        carriersPageState.beforeExitViewCarrierPage();
      },
    },
  },
  Dispatch: {
    component: <Dispatch />,
    name: 'Dispatch',
    path: `${PREFIX_PATH}/dispatch`,
    isAuthenticationRequired: true,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(DISPATCH);
        const { dispatchPageState } = getRoot(self);
        dispatchPageState.clearSelectedShipment();
        dispatchPageState.clearSpecificShipmentDetails();
        // dispatchPageState.loadRouteActionDetails()
        dispatchPageState.loadAvailableStatuses();
        dispatchPageState.loadAvailableDriverFilters();
        dispatchPageState.loadAvailableLocations();
        dispatchPageState.assignSetTrailerToShowOnMap(null);
        if (params?.shipmentId) {
          dispatchPageState.beforeEnterSetSelectedShipment(params.shipmentId);
          // dispatchPageState.addNewRouteActionItem({})
        }
      },
      beforeExit(self, params) {
        const { dispatchPageState } = getRoot(self);
        dispatchPageState.beforeExitClearSelectedShipmentId();
        dispatchPageState.closeAllSidebars();
      },
    },
  },
  Payroll: {
    component: <PayrollPage />,
    name: PAYROLL,
    path: `${PREFIX_PATH}/payroll`,
    isAuthenticationRequired: true,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState, payrollPageState } = getRoot(self);
        sideMenuState.setActiveItem(PAYROLL);
        payrollPageState.setExpandedRowKeys([]);
        payrollPageState.beforePageEnter();
      },
    },
  },
  AddCompensation: {
    component: <AddCompensationPage />,
    name: ADD_COMPENSATION,
    path: `${PREFIX_PATH}/compensation/add`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.ADD_COMPENSATIONS],
    hooks: {
      beforeEnter(self) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(PAYROLL);
        const { payrollPageState } = getRoot(self);
        payrollPageState.beforeAddCompensationRouteEnter();
      },
      beforeExit(self, params) {
        const { payrollPageState } = getRoot(self);
        payrollPageState.newCompensationStructure.clearForm();
      },
    },
  },
  EditCompensation: {
    component: <EditCompensationPage />,
    name: EDIT_COMPENSATION,
    path: `${PREFIX_PATH}/compensation/edit/:compensationId`,
    isAuthenticationRequired: true,
    limitedTo: [permissions.EDIT_COMPENSATIONS],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(PAYROLL);
        const { payrollPageState } = getRoot(self);
        const id = params.compensationId;
        payrollPageState.getAllPaymentPeriods();
        payrollPageState.getAllPaymentRates();
        payrollPageState.getAllCurrencies();
        payrollPageState.getAllBasedOnTypes();
        payrollPageState.getEditingCompensation(id);
      },
      beforeExit(self, params) {
        const { payrollPageState } = getRoot(self);
        payrollPageState.clearAddingValueToEditingCompensationForm();
        payrollPageState.clearAddingDeductionToEditingCompensationForm();
        payrollPageState.setIsValueAddingFormOpen(false);
        payrollPageState.setIsDeductionAddingFormOpen(false);
      },
    },
  },
  Reports: {
    component: <ReportsPage />,
    name: REPORTS,
    path: `${PREFIX_PATH}/reports`,
    isAuthenticationRequired: true,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState, reportsPageState } = getRoot(self);
        sideMenuState.setActiveItem(REPORTS);
        reportsPageState.beforePageEnter();
      },
      beforeExit(self, params) {},
    },
  },
  Support: {
    component: <SupportPage />,
    name: SUPPORT,
    path: `${PREFIX_PATH}/support`,
    isAuthenticationRequired: true,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { sideMenuState } = getRoot(self);
        sideMenuState.setActiveItem(SUPPORT);
      },
    },
  },
  Login: {
    component: <LoginPage />,
    name: LOGIN,
    path: `${PREFIX_PATH}/login`,
    isAuthenticationRequired: false,
    limitedTo: [],
  },
  ResetPassword: {
    component: <ResetPasswordPage />,
    name: RESET_PASSWORD,
    path: `${PREFIX_PATH}/reset_password`,
    isAuthenticationRequired: false,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { loginForm } = getRoot(self);
        loginForm.validateResetPasswordToken();
      },
      beforeExit(self, params) {},
    },
  },
  CreatePassword: {
    component: <CreatePasswordPage />,
    name: CREATE_PASSWORD,
    path: `${PREFIX_PATH}/user/account`,
    isAuthenticationRequired: false,
    limitedTo: [],
    hooks: {
      beforeEnter(self, params) {
        const { loginForm } = getRoot(self);
        loginForm.validateCreatePasswordToken();
      },
      beforeExit(self, params) {},
    },
  },
};
export default PageRoutes;
