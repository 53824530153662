import React from 'react';
import { observer } from 'mobx-react';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import { VIEW_TRAILER } from '../../constants';
import ViewTrailerComponent from '../../components/fleet/ViewTrailer/ViewTrailerComponent';

const ViewTrailerPage = () => {
  return (
    <div>
      <div className="page fleet-page">
        <BreadcrumsComponent
          breadcrumbsBackLink={PageRoutes.Fleet.name}
          page={VIEW_TRAILER}
        />
        <div className="page-content">
          <ViewTrailerComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(ViewTrailerPage);
