import React from 'react';
import { observer } from 'mobx-react';
import EditShipmentHeader from './EditShipmentHeader';
import ShipmentDetails from './ShipmentDetails';
import {
  SAVE_CHANGES,
  MODAL_SUCCESS_TITLE,
  MODAL_SUCCESS_EDIT_SHIPMENT_TEXT,
  MODAL_SUCCESS_EDIT_SHIPMENT_BUTTON_TEXT,
} from '../../../constants';
import { useMst } from '../../../state/RootModel';
import ErrorBox from '../../ErrorBox';
import ProbillsSection from '../Probills/ProbillsSection';
import AddProbillModal from '../Probills/AddProbillModal';
import EditProbillModal from '../Probills/EditProbillModal';
import ConfirmDeleteProbillModal from '../Probills/ConfirmDeleteProbillModal';
import BillingDetails from '../ShipmentCommons/BillingDetails';
import ShipmentTotalCharges from '../ShipmentCommons/ShipmentTotalCharges';
import DocumentsSection from './DocumentsSection';
import AddDocumentModal from './AddDocumentModal';
import SuccessModal from '../../SuccessModal';
import PageRoutes from '../../../utils/PageRoutes';
import ConfirmDeleteDocumentModal from '../ShipmentCommons/ConfirmDeleteDocument';
import ConfirmDeleteShipmentRelatedCharge from '../ShipmentCommons/ConfirmDeleteShipmentRelatedCharge';
import ShipmentSpecificDetails from '../ShipmentCommons/ShipmentSpecificDetails';
import ShipmentProfit from '../ShipmentCommons/ShipmentProfit';
import ShipmentCarriersListModal from '../ShipmentCommons/ShipmentCarriersListModal';
import EditProbillLocationModal from '../Probills/EditProbillLocationModal';
import NotesSection from '../ShipmentCommons/NotesSection';
import ConfirmDeleteNoteModal from '../ShipmentCommons/ConfirmDeleteNoteModal';
import AddShipmentNoteModal from './AddShipmentNoteModal';
import EditShipmentNoteModal from './EditShipmentNoteModal';

const EditShipmentComponent = () => {
  const {
    shipmentsPageState: {
      editedShipment,
      editShipmentSuccessModalOpen,
      setEditShipmentSuccessModalOpen,
    },
    userState: { canViewProbills },
  } = useMst();

  const handleConfirm = () => {
    editedShipment.saveShipment();
  };

  const backLink = PageRoutes.ViewShipment.name;

  const params = { shipmentId: editedShipment?.shipment_id?.toString() };

  return (
    <div className="page-content">
      <EditShipmentHeader />
      <ShipmentDetails />
      <NotesSection />
      {canViewProbills && <ProbillsSection />}
      {editedShipment?.newShipmentNote && <AddShipmentNoteModal />}
      {editedShipment?.shipmentNoteToBeDeleted && <ConfirmDeleteNoteModal />}
      {editedShipment?.shipmentNoteToBeEdited && <EditShipmentNoteModal />}
      {editedShipment?.newProbill && <AddProbillModal />}
      {editedShipment?.editedProbill && <EditProbillModal />}
      <div className="w100 mt20 flex-alignitems-flex-start">
        <BillingDetails />
        {editedShipment?.isBrokerageShipment && <ShipmentProfit />}
        {!editedShipment?.isBrokerageShipment && <ShipmentTotalCharges />}
        <ShipmentSpecificDetails />
      </div>
      <DocumentsSection />
      {editedShipment?.addDocumentModalOpen && <AddDocumentModal />}

      <ErrorBox obj={editedShipment} />

      {editedShipment && (
        <button className="btn primary mt20" onClick={handleConfirm}>
          {SAVE_CHANGES}
        </button>
      )}
      {editedShipment?.probillToBeDeleted && <ConfirmDeleteProbillModal />}
      {editedShipment?.documentToBeDeleted && <ConfirmDeleteDocumentModal />}
      {editedShipment?.shipmentChargeToBeDeleted && (
        <ConfirmDeleteShipmentRelatedCharge />
      )}
      <ShipmentCarriersListModal />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_SHIPMENT_TEXT}
        buttonText={MODAL_SUCCESS_EDIT_SHIPMENT_BUTTON_TEXT}
        backLink={backLink}
        params={params}
        open={editShipmentSuccessModalOpen}
        closeModal={() => setEditShipmentSuccessModalOpen(false)}
      />
      <EditProbillLocationModal />
    </div>
  );
};

export default observer(EditShipmentComponent);
