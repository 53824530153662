import { getRoot, types } from 'mobx-state-tree';
import {
  COMPENSATION_DEDUCTIONS,
  COMPENSATION_DEDUCTION_CURRENCY_REQUIRED,
  COMPENSATION_DEDUCTION_RATE_REQUIRED,
  COMPENSATION_DEDUCTION_REQUIRED,
  COMPENSATION_DEDUCTION_UNIQUE,
  COMPENSATION_NAME_REQUIRED,
  COMPENSATION_PAYMENT_PERIOD_REQUIRED,
  COMPENSATION_STRUCTURES,
  COMPENSATION_VALUES,
  COMPENSATION_VALUE_CURRENCY_REQUIRED,
  COMPENSATION_VALUE_RATE_REQUIRED,
  COMPENSATION_VALUE_REQUIRED,
  COMPENSATION_VALUE_UNIQUE,
  DEFAULT_VALUE,
  ERROR_DELETING_COMPENSATION_VALUE_BASED_ON_TYPES,
  ERROR_DELETING_COMPENSATION_VALUE_CURRENCIES,
  ERROR_DELETING_COMPENSATION_VALUE_PERIODS,
  ERROR_DELETING_COMPENSATION_VALUE_RATES,
  ERROR_FETCHING_ALL_COMPENSATIONS,
  ERROR_FETCHING_COMPENSATION,
  ERROR_FETCHING_COMPENSATION_DEDUCTIONS,
  ERROR_FETCHING_COMPENSATION_TYPES,
  ERROR_FETCHING_COMPENSATION_VALUES,
  ERROR_LOADING_DATA,
  FILTER_DEFAULT_VALUE,
} from '../../constants/payroll';
import {
  getAllCompensations,
  getAllCompensationTypes,
  getCompensationBasedOnTypes,
  getCompensationById,
  getCompensationCurrencies,
  getCompensationDeductionTypes,
  getCompensationPaymentPeriods,
  getCompensationPaymentRates,
  getCompensationValuesByType,
} from '../../actions/payroll';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { notification } from 'antd';
import { CompensationsModel } from './Compensations/CompensationsModel';
import { compensationsTableColumns } from './Compensations/CompensationsTableColumns';
import {
  addDeductionsToCompensationPromise,
  addValuesToCompensationPromise,
  editCompensationDeductionPromise,
  editCompensationPromise,
  editCompensationValuePromise,
  getCompensationBasedOnTypesPromise,
  getCompensationCurrenciesPromise,
  getCompensationDeductionTypesPromise,
  getCompensationPaymentPeriodsPromise,
  getCompensationPaymentRatesPromise,
  getCompensationTypesPromise,
} from '../../promises/payroll';
import { CompensationStructureLightModel } from './CompensationStructureLightModel';
import { compensationValuesOrDeductionsTableColumns } from './Compensations/CompensationValuesOrDeductionsTableColumns';
import { rootInstance } from '../RootModel';
import { compensationsValuesTableColumns } from './Compensations/CompensationsValuesTableColumns';
import { compensationsDeductionsTableColumns } from './Compensations/CompensationsDeductionsTableColumns';
import { CompensationValueModel } from './Compensations/CompensationValueModel';
import { CompensationDeductionModel } from './Compensations/CompensationDeductionModel';
import PageRoutes from '../../utils/PageRoutes';
const allSettled = require('promise.allsettled');

export const PayrollPageModel = types
  .model('PayrollPageModel', {
    activeTab: types.maybe(types.string),
    compensations: types.map(CompensationsModel),
    isAddCompensationModalOpen: false,
    compensationTypes: types.frozen([]),
    compensationPaymentPeriods: types.frozen([]),
    compensationPaymentRates: types.frozen([]),
    compensationCurrencies: types.frozen([]),
    compensationBasedOnTypes: types.frozen([]),
    valueFilterType: types.optional(types.string, FILTER_DEFAULT_VALUE),
    newCompensationStructure: types.maybe(CompensationStructureLightModel),
    compensationValuesByType: types.frozen([]),
    compensationDeductionsTypes: types.frozen([]),

    isDeleteCompensationModalOpened: false,
    deletingCompensation: types.maybeNull(CompensationsModel),
    isDeleteValueModalOpen: false,
    deletingValue: types.maybeNull(CompensationValueModel),
    isDeleteDeductionModalOpen: false,
    deletingDeduction: types.maybeNull(CompensationDeductionModel),

    editedCompensation: types.maybeNull(CompensationsModel),
    isEditingCompensationValueModalOpen: false,
    editedValue: types.maybeNull(CompensationValueModel),
    isEditingCompensationDeductionModalOpen: false,
    editedDeduction: types.maybeNull(CompensationDeductionModel),
    addCompensationSuccessModalOpen: false,

    expandedRowKeys: types.frozen([]),
    selectedCompensationStructure: types.maybe(
      types.maybeNull(CompensationsModel)
    ),
    errorText: types.maybe(types.maybeNull(types.string)),

    //Editing compensation
    editedCompensationName: types.maybe(types.maybeNull(types.string)),
    editedCompensationPeriod: types.maybe(types.maybeNull(types.string)),
    editedCompensationValues: types.frozen([]),
    editedCompensationDeductions: types.frozen([]),
    isValueAddingFormOpen: false,
    isDeductionAddingFormOpen: false,

    //Adding value to compensation
    edited_compensation_value: types.maybe(types.maybeNull(types.string)),
    edited_compensation_based_on: types.maybe(types.maybeNull(types.string)),
    edited_compensation_currency: types.maybe(types.maybeNull(types.string)),
    edited_compensation_rate: types.maybe(types.maybeNull(types.string)),
    edited_compensation_default_value: types.maybeNull(
      types.optional(types.number, 0.0)
    ),
    edited_value_error_text: types.maybe(types.maybeNull(types.string)),

    value_default_value_temp: types.maybeNull(
      types.optional(types.string, DEFAULT_VALUE)
    ),

    //Adding deduction to compensation
    edited_compensation_deduction: types.maybe(types.maybeNull(types.string)),
    edited_compensation_deduction_based_on: types.maybe(
      types.maybeNull(types.string)
    ),
    edited_compensation_deduction_currency: types.maybe(
      types.maybeNull(types.string)
    ),
    edited_compensation_deduction_rate: types.maybe(
      types.maybeNull(types.string)
    ),
    edited_compensation_deduction_default_value: types.maybeNull(
      types.optional(types.number, 0.0)
    ),
    edited_deduction_error_text: types.maybe(types.maybeNull(types.string)),

    deduction_default_value_temp: types.maybeNull(
      types.optional(types.string, DEFAULT_VALUE)
    ),
  })
  .views((self) => {
    return {
      get getCompensationsList() {
        return Array.from(self.compensations.values());
      },
      get getAllCompensationTypes() {
        return Array.from(self.compensationTypes.values());
      },
      get getAllCompensationPaymentPeriods() {
        return Array.from(self.compensationPaymentPeriods.values());
      },
      get getAllCompensationPaymentRates() {
        return Array.from(self.compensationPaymentRates.values());
      },
      get getAllCompensationCurrencies() {
        return Array.from(self.compensationCurrencies.values());
      },
      get getAllCompensationBasedOnTypes() {
        return Array.from(self.compensationBasedOnTypes.values());
      },
      get getAllEditedCompensationValues() {
        return Array.from(self.editedCompensationValues.values());
      },
      get getAllEditedCompensationDeductions() {
        return Array.from(self.editedCompensationDeductions.values());
      },
      get filteredCompensationsList() {
        const searchText = rootInstance.headerState.searchText.toLowerCase();
        return this.getCompensationsList.filter((compensation) => {
          return compensation.compensation_name
            .toLowerCase()
            .includes(searchText);
        });
      },
    };
  })
  .actions((self) => {
    return {
      setActiveTab(key) {
        self.activeTab = key;
        self.activeTab === COMPENSATION_STRUCTURES &&
          this.getCompensationStructures();
        self.activeTab === COMPENSATION_VALUES && this.getCompensationValues();
        self.activeTab === COMPENSATION_DEDUCTIONS &&
          this.getCompensationDeductions();
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setErrorTextForValue(text) {
        self.edited_value_error_text = text;
      },
      setErrorTextForDeduction(text) {
        self.edited_deduction_error_text = text;
      },
      setValueFilterType(type) {
        self.valueFilterType = type;
      },
      setIsValueAddingFormOpen(value) {
        self.isValueAddingFormOpen = value;
      },
      setIsDeductionAddingFormOpen(value) {
        self.isDeductionAddingFormOpen = value;
      },
      setCompensationPaymentPeriods(value) {
        self.compensationPaymentPeriods = value;
      },
      setCompensationBasedOnTypes(value) {
        self.compensationBasedOnTypes = value;
      },
      setCompensationPaymentRates(value) {
        self.compensationPaymentRates = value;
      },
      setCompensationCurrencies(value) {
        self.compensationCurrencies = value;
      },
      setIsDeleteCompensationModalOpened(value) {
        self.isDeleteCompensationModalOpened = value;
      },
      setIsEditingCompensationValueModalOpen(value) {
        self.isEditingCompensationValueModalOpen = value;
      },
      setIsEditingCompensationDeductionModalOpen(value) {
        self.isEditingCompensationDeductionModalOpen = value;
      },
      setIsDeleteValueModalOpen(value) {
        self.isDeleteValueModalOpen = value;
      },
      setIsDeleteDeductionModalOpen(value) {
        self.isDeleteDeductionModalOpen = value;
      },
      setCompensationToBeDeleted(compensation) {
        self.deletingCompensation = compensation ? { ...compensation } : null;
      },
      setValueToBeDeleted(value) {
        self.deletingValue = value;
      },
      setEditingValue(value) {
        self.editedValue = value;
        self.editedValue.default_value_temp = value.default_value.toString();
      },
      setEditingDeduction(value) {
        self.editedDeduction = value;
        self.editedDeduction.default_value_temp =
          value.default_value.toString();
      },
      setEditingCompensation(value) {
        self.editedCompensation = value;
      },
      setEditedCompensationValues(values) {
        self.editedCompensationValues = values;
      },
      setEditedCompensationDeductions(values) {
        self.editedCompensationDeductions = values;
      },
      setDeductionToBeDeleted(value) {
        self.deletingDeduction = value;
      },
      setAddCompensationSuccessModalOpen(value) {
        self.addCompensationSuccessModalOpen = value;
      },
      setEditedCompensationValue(value) {
        self.edited_compensation_value = value;
      },
      setEditedCompensationDeduction(value) {
        self.edited_compensation_deduction = value;
      },
      setEditedCompensationValueBasedOn(value) {
        self.edited_compensation_based_on = value;
      },
      setEditedCompensationDeductionBasedOn(value) {
        self.edited_compensation_deduction_based_on = value;
      },
      setEditedCompensationValueDefaultValue(value) {
        self.edited_compensation_default_value = value;
      },
      setEditedCompensationDeductionDefaultValue(value) {
        self.edited_compensation_deduction_default_value = value;
      },
      setEditedCompensationValueCurrency(value) {
        self.edited_compensation_currency = value;
      },
      setEditedCompensationDeductionCurrency(value) {
        self.edited_compensation_deduction_currency = value;
      },
      setEditedCompensationValueRate(value) {
        self.edited_compensation_rate = value;
      },
      setEditedCompensationDeductionRate(value) {
        self.edited_compensation_deduction_rate = value;
      },
      setValueDefaultValueTemp(value) {
        self.value_default_value_temp = value;
        self.edited_compensation_default_value = parseFloat(value);
      },
      setDeductionDefaultValueTemp(value) {
        self.deduction_default_value_temp = value;
        self.edited_compensation_deduction_default_value = parseFloat(value);
      },
      isValueUnique() {
        for (const el of self.editedCompensationValues) {
          if (
            el.compensation_value === self.edited_compensation_value &&
            el.rate === self.edited_compensation_rate &&
            el.based_on === self.edited_compensation_based_on
          ) {
            return false;
          }
        }
        return true;
      },
      isDeductionUnique() {
        for (const el of self.editedCompensationDeductions) {
          if (
            el.deduction_type === self.edited_compensation_deduction &&
            el.rate === self.edited_compensation_deduction_rate &&
            el.based_on === self.edited_compensation_deduction_based_on
          ) {
            return false;
          }
        }
        return true;
      },
      validateAddingValueToEditingCompensationForm() {
        if (!self.edited_compensation_value) {
          this.setErrorTextForValue(COMPENSATION_VALUE_REQUIRED);
          return false;
        }
        if (!self.edited_compensation_rate) {
          this.setErrorTextForValue(COMPENSATION_VALUE_RATE_REQUIRED);
          return false;
        }
        if (!self.edited_compensation_currency) {
          this.setErrorTextForValue(COMPENSATION_VALUE_CURRENCY_REQUIRED);
          return false;
        }
        if (!this.isValueUnique()) {
          this.setErrorTextForValue(COMPENSATION_VALUE_UNIQUE);
          return false;
        } else {
          this.setErrorTextForValue(null);
          return true;
        }
      },
      validateAddingDeductionToEditingCompensationForm() {
        if (!self.edited_compensation_deduction) {
          this.setErrorTextForValue(COMPENSATION_DEDUCTION_REQUIRED);
          return false;
        }
        if (!self.edited_compensation_deduction_rate) {
          this.setErrorTextForValue(COMPENSATION_DEDUCTION_RATE_REQUIRED);
          return false;
        }
        if (!self.edited_compensation_deduction_currency) {
          this.setErrorTextForValue(COMPENSATION_DEDUCTION_CURRENCY_REQUIRED);
          return false;
        }
        if (!this.isDeductionUnique()) {
          this.setErrorTextForDeduction(COMPENSATION_DEDUCTION_UNIQUE);
          return false;
        } else {
          this.setErrorTextForValue(null);
          return true;
        }
      },
      clearAddingValueToEditingCompensationForm() {
        this.setEditedCompensationValue(null);
        this.setEditedCompensationValueBasedOn(null);
        this.setEditedCompensationValueCurrency(null);
        this.setEditedCompensationValueRate(null);
        this.setValueDefaultValueTemp(DEFAULT_VALUE);
        this.setErrorTextForValue(null);
      },
      clearAddingDeductionToEditingCompensationForm() {
        this.setEditedCompensationDeduction(null);
        this.setEditedCompensationDeductionBasedOn(null);
        this.setEditedCompensationDeductionCurrency(null);
        this.setEditedCompensationDeductionRate(null);
        this.setDeductionDefaultValueTemp(DEFAULT_VALUE);
        this.setErrorTextForDeduction(null);
      },
      addValueToEditedCompensationValuesList() {
        if (this.validateAddingValueToEditingCompensationForm()) {
          const values = self.editedCompensationValues.map((item) => item);
          values.push({
            based_on: self.edited_compensation_based_on,
            compensation_value: self.edited_compensation_value,
            currency: self.edited_compensation_currency,
            default_value: self.edited_compensation_default_value,
            rate: self.edited_compensation_rate,
          });
          this.setEditedCompensationValues(values);
          this.clearAddingValueToEditingCompensationForm();
        }
      },
      addDeductionToEditedCompensationValuesList() {
        if (this.validateAddingDeductionToEditingCompensationForm()) {
          const deductions = self.editedCompensationDeductions.map(
            (item) => item
          );
          deductions.push({
            based_on: self.edited_compensation_deduction_based_on,
            deduction_type: self.edited_compensation_deduction,
            currency: self.edited_compensation_deduction_currency,
            default_value: self.edited_compensation_deduction_default_value,
            rate: self.edited_compensation_deduction_rate,
          });
          this.setEditedCompensationDeductions(deductions);
          this.clearAddingDeductionToEditingCompensationForm();
        }
      },
      deleteValueFromEditedCompensationValuesList(index) {
        const values = self.editedCompensationValues.map((item) => item);
        values.splice(index, 1);
        this.setEditedCompensationValues(values);
      },
      deleteDeductionFromEditedCompensationDeductionsList(index) {
        const deductions = self.editedCompensationDeductions.map(
          (item) => item
        );
        deductions.splice(index, 1);
        this.setEditedCompensationDeductions(deductions);
      },
      getEditingCompensation(compensation_id) {
        setFetchingInProgress(true);
        getCompensationById(compensation_id)
          .then((response) => {
            this.setEditingCompensation(response.data);
            this.setEditedCompensationName(response.data.compensation_name);
            this.setEditedCompensationPeriod(response.data.period);
            this.setEditedCompensationValues(
              response.data.compensation_values.filter((el) => {
                delete el.compensation_value_id;
                return el;
              })
            );
            this.setEditedCompensationDeductions(
              response.data.compensation_deductions.filter((el) => {
                delete el.compensation_deduction_id;
                return el;
              })
            );
            this.getCompensationValuesByTypeForEditedCompensation();
            this.getCompensationDeductionTypes();
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_COMPENSATION,
              description: errorText,
            });
          });
      },
      setEditedCompensation(compensation) {
        self.editedCompensation = compensation ? { ...compensation } : null;
      },
      getCompensationToBeDeleted(compensation_id) {
        let selectedCompensation = self.getCompensationsList.filter(
          (org) => org.compensation_id === compensation_id
        )[0];
        this.setCompensationToBeDeleted(selectedCompensation);
      },
      setSelectedCompensationStructure(compensation) {
        self.selectedCompensationStructure = compensation
          ? { ...compensation }
          : null;
      },
      setEditedCompensationName(name) {
        self.editedCompensationName = name;
      },
      setEditedCompensationPeriod(period) {
        self.editedCompensationPeriod = period;
      },
      getValueToBeDeleted(compensation_value_id) {
        self.selectedCompensationStructure.getValueToBeDeleted(
          compensation_value_id
        );
      },
      getEditingValue(value) {
        self.editedValue = value;
        self.editedValue.default_value_temp = value.default_value.toString();
      },
      getEditingDeduction(deduction) {
        self.editedDeduction = deduction;
        self.editedDeduction.default_value_temp =
          deduction.default_value.toString();
      },
      getDeductionToBeDeleted(deduction_id) {
        self.selectedCompensationStructure.getDeductionToBeDeleted(
          deduction_id
        );
      },

      getCompensationTableColumns() {
        return Object.values(compensationsTableColumns(getRoot(self)));
      },
      getCompensationValuesOrDeductionsTableColumns(type: string) {
        return Object.values(
          compensationValuesOrDeductionsTableColumns(getRoot(self), type)
        );
      },
      getCompensationValuesTableColumns() {
        return Object.values(compensationsValuesTableColumns(getRoot(self)));
      },
      getCompensationDeductionsTableColumns() {
        return Object.values(
          compensationsDeductionsTableColumns(getRoot(self))
        );
      },
      setCompensations(compensations) {
        self.compensations.clear();
        compensations.forEach((item) => {
          self.compensations.put({
            ...item,
          });
        });
      },
      setExpandedRowKeys(keys) {
        self.expandedRowKeys = keys;
      },
      getCompensationStructures() {
        setFetchingInProgress(true);
        getAllCompensations()
          .then((response) => {
            this.setCompensations(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_ALL_COMPENSATIONS,
              description: errorText,
            });
          });
      },
      setCompensationTypes(compensationTypes) {
        self.compensationTypes = compensationTypes;
      },
      getCompensationTypes() {
        setFetchingInProgress(true);
        getAllCompensationTypes()
          .then((response) => {
            this.setCompensationTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_COMPENSATION_TYPES,
              description: errorText,
            });
          });
      },
      setCompensationValuesByType(compensationValues) {
        self.compensationValuesByType = compensationValues;
      },
      setCompensationDeductionTypes(compensationDeductions) {
        self.compensationDeductionsTypes = compensationDeductions;
      },
      getCompensationValuesByType() {
        setFetchingInProgress(true);
        console.log(self.valueFilterType);
        getCompensationValuesByType(self.valueFilterType)
          .then((response) => {
            this.setCompensationValuesByType(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_COMPENSATION_VALUES,
              description: errorText,
            });
          });
      },
      getCompensationValuesByTypeForNewCompensation() {
        setFetchingInProgress(true);
        getCompensationValuesByType(
          self.newCompensationStructure.compensation_type
        )
          .then((response) => {
            this.setCompensationValuesByType(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_COMPENSATION_VALUES,
              description: errorText,
            });
          });
      },
      getCompensationValuesByTypeForEditedCompensation() {
        setFetchingInProgress(true);
        getCompensationValuesByType(self.editedCompensation?.compensation_type)
          .then((response) => {
            this.setCompensationValuesByType(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_COMPENSATION_VALUES,
              description: errorText,
            });
          });
      },
      getCompensationDeductionTypes() {
        setFetchingInProgress(true);
        getCompensationDeductionTypes()
          .then((response) => {
            this.setCompensationDeductionTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_COMPENSATION_DEDUCTIONS,
              description: errorText,
            });
          });
      },
      getAllPaymentPeriods() {
        setFetchingInProgress(true);
        getCompensationPaymentPeriods()
          .then((response) => {
            this.setCompensationPaymentPeriods(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION_VALUE_PERIODS,
              description: errorText,
            });
          });
      },
      getAllCurrencies() {
        setFetchingInProgress(true);
        getCompensationCurrencies()
          .then((response) => {
            this.setCompensationCurrencies(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION_VALUE_CURRENCIES,
              description: errorText,
            });
          });
      },
      getAllBasedOnTypes() {
        setFetchingInProgress(true);
        getCompensationBasedOnTypes()
          .then((response) => {
            this.setCompensationBasedOnTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION_VALUE_BASED_ON_TYPES,
              description: errorText,
            });
          });
      },
      getAllPaymentRates() {
        setFetchingInProgress(true);
        getCompensationPaymentRates()
          .then((response) => {
            this.setCompensationPaymentRates(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION_VALUE_RATES,
              description: errorText,
            });
          });
      },
      validateEditingCompensationForm() {
        if (!self.editedCompensationName) {
          this.setErrorText(COMPENSATION_NAME_REQUIRED);
          return false;
        }
        if (!self.editedCompensationPeriod) {
          this.setErrorText(COMPENSATION_PAYMENT_PERIOD_REQUIRED);
          return false;
        } else {
          this.setErrorText(null);
          return true;
        }
      },

      clearEditingCompensationForm() {
        this.setCompensationName(null);
        this.setErrorText(null);
      },
      saveEditedCompensation() {
        if (this.validateEditingCompensationForm()) {
          setFetchingInProgress(true);
          editCompensationPromise(
            self.editedCompensation.compensation_id,
            self.editedCompensationName,
            self.editedCompensationPeriod
          )
            .then((response) => {
              setFetchingInProgress(false);
              this.addValuesToCompensation();
              this.addDeductionsToCompensation();
              rootInstance.payrollPageState.getCompensationStructures();
              rootInstance.router.setView(
                rootInstance.router.views.get(PageRoutes.Payroll.name)
              );
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
      addValuesToCompensation() {
        setFetchingInProgress(true);
        addValuesToCompensationPromise(
          self.editedCompensation.compensation_id,
          self.editedCompensationValues
        )
          .then((response) => {
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      addDeductionsToCompensation() {
        setFetchingInProgress(true);
        addDeductionsToCompensationPromise(
          self.editedCompensation.compensation_id,
          self.editedCompensationDeductions
        )
          .then((response) => {
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      validateEditingCompensationValueForm() {
        if (!self.editedValue.rate) {
          this.setErrorText(COMPENSATION_VALUE_RATE_REQUIRED);
          return false;
        }
        if (!self.editedValue.currency) {
          this.setErrorText(COMPENSATION_VALUE_CURRENCY_REQUIRED);
          return false;
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveEditedCompensationValue() {
        if (this.validateEditingCompensationValueForm()) {
          setFetchingInProgress(true);
          editCompensationValuePromise(
            self.selectedCompensationStructure.compensation_id,
            self.editedValue
          )
            .then((response) => {
              setFetchingInProgress(false);
              this.getCompensationStructures();
              this.setIsEditingCompensationValueModalOpen(false);
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
      validateEditingCompensationDeductionForm() {
        if (!self.editedDeduction.rate) {
          this.setErrorText(COMPENSATION_DEDUCTION_RATE_REQUIRED);
          return false;
        }
        if (!self.editedDeduction.currency) {
          this.setErrorText(COMPENSATION_DEDUCTION_CURRENCY_REQUIRED);
          return false;
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveEditedCompensationDeduction() {
        if (this.validateEditingCompensationDeductionForm()) {
          setFetchingInProgress(true);
          editCompensationDeductionPromise(
            self.selectedCompensationStructure.compensation_id,
            self.editedDeduction
          )
            .then((response) => {
              setFetchingInProgress(false);
              rootInstance.payrollPageState.getCompensationStructures();
              rootInstance.payrollPageState.setIsEditingCompensationDeductionModalOpen(
                false
              );
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
      getCompensationValues() {
        this.getCompensationTypes();
        this.getCompensationValuesByType();
      },
      getCompensationDeductions() {
        this.getCompensationDeductionTypes();
      },
      beforeAddCompensationRouteEnter() {
        setFetchingInProgress(true);
        let promises = [
          getCompensationTypesPromise(self.newCompensationStructure),
          getCompensationPaymentPeriodsPromise(self.newCompensationStructure),
          getCompensationBasedOnTypesPromise(self.newCompensationStructure),
          getCompensationPaymentRatesPromise(self.newCompensationStructure),
          getCompensationDeductionTypesPromise(self.newCompensationStructure),
          getCompensationCurrenciesPromise(self.newCompensationStructure),
        ];

        allSettled(promises)
          .then((res) => {
            this.setAddCompensationSuccessModalOpen(false);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_LOADING_DATA,
              description: errorText,
            });
          });
      },
      beforePageEnter() {
        this.setActiveTab(COMPENSATION_STRUCTURES);
        this.setExpandedRowKeys([]);
        this.getCompensationTypes();
        this.getAllPaymentPeriods();
        this.getAllPaymentRates();
        this.getAllCurrencies();
        this.getAllBasedOnTypes();
      },
    };
  });
