import { getSnapshot, types } from 'mobx-state-tree';
import { DocumentModel } from '../DocumentModel';
import { AbsenceModel } from './AbsenceModel';
import { AbsenceLightModel } from './AbsenceLightModel';
import { EmployeeRoleModel } from './EmployeeRoleModel';
import { editEmployee } from '../../actions/employees';
import {
  getCurrentUserAccount,
  getAllAccountDocumentation,
  getAccountActiveRole,
} from '../../actions/account';
import { editCurrentUser, changePassword } from '../../actions/auth';
import {
  validatePhoneNumber,
  validateEmail,
  setFetchingInProgress,
  getErrorText,
  setCurrentUserLocalStoragePhoto,
} from '../../utils/methods';
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  EMAIL_REQUIRED,
  USER_TYPE_REQUIRED,
  EMAIL_ERROR_TEXT,
  PHONE_NUMBER_REQUIRED,
  PHONE_NUMBER_ERROR_TEXT,
  CURRENT_PASSWORD_REQUIRED_TEXT,
  NEW_PASSWORD_REQUIRED_TEXT,
  CONFIRMED_PASSWORD_REQUIRED_TEXT,
  PASSWORDS_NOT_MATCHING_ERROR_TEXT,
  ABSENCE_ERROR_TEXT,
  DATE_OF_BIRTH_REQUIRED,
  FETCH_LOCATIONS_ERROR,
  CODE_NAME_REQUIRED,
} from '../../constants';
import {
  DOCUMENT_TYPE_EMPLOYEE_AGREEMENT,
  DOCUMENT_TYPE_EMPLOYEE_IMAGE,
  DOCUMENT_TYPE_REFERENCE,
  DOCUMENT_TYPE_RESUME,
} from '../../constants/documents';
import { STATUS_AVAILABLE } from '../../constants/workingStatuses';
import { rootInstance } from '../RootModel';
import moment from 'moment';
import {
  changeUserActiveRole,
  deleteEmployeeDocument,
  deleteUserPhoto,
  uploadEmployeeDocument,
  uploadUserPhoto,
} from '../../promises/employees';
import { getAllCountries } from '../../actions/common';
import { notification } from 'antd';

const isEqual = require('lodash.isequal');

export const EmployeeModel = types
  .model('EmployeeModel', {
    user_id: types.identifierNumber,
    active: types.maybe(types.maybeNull(types.boolean)),
    email: types.maybe(types.maybeNull(types.string)),
    first_name: types.maybe(types.maybeNull(types.string)),
    last_name: types.maybe(types.maybeNull(types.string)),
    code_name: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    user_department: types.maybe(types.maybeNull(types.number)),
    user_assigned_roles: types.frozen([]),
    active_role: types.maybe(types.maybeNull(EmployeeRoleModel)),
    tracking_departments: types.frozen(),
    purpose: types.maybe(types.maybeNull(types.string)),
    photo_path: types.maybe(types.maybeNull(types.string)),
    street_address: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    user_organization_id: types.maybe(types.maybeNull(types.number)),
    city: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    social_security_number: types.maybe(types.maybeNull(types.string)),
    account_status: types.maybe(types.maybeNull(types.string)),
    is_regular_working_status: types.maybe(types.maybeNull(types.boolean)),
    current_absence_id: types.maybe(types.maybeNull(types.number)),
    date_of_birth: types.maybe(types.maybeNull(types.string)),
    date_hired: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    documents: types.map(DocumentModel),
    currentAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),
    absences: types.map(AbsenceModel),
    newAbsence: types.maybe(types.maybeNull(AbsenceLightModel)),
    editedAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),
    current_password: types.maybe(types.maybeNull(types.string)),
    new_password: types.maybe(types.maybeNull(types.string)),
    confirmed_password: types.maybe(types.maybeNull(types.string)),
    current_password_visible: false,
    new_password_visible: false,
    confirmed_password_visible: false,
    change_password_error_text: types.maybe(types.maybeNull(types.string)),
    successModalOpen: false,
    firstTimeInApp: false,
    changePasswordModalOpen: false,
    isSaveFirstModalOpen: false,

    employeeFullyFetched: false,

    countries: types.frozen([]),
  })
  .views((self) => {
    return {
      get fullName() {
        return `${self.first_name} ${self.last_name}`;
      },
      get employeeWorkingStatus() {
        const unavailableStatus =
          rootInstance.employeesPageState.availableAbsencesTypes.find(
            (status) => status === self.currentAbsence?.status
          );
        if (unavailableStatus) {
          return unavailableStatus;
        } else {
          return STATUS_AVAILABLE;
        }
      },
      get isEmployeeOff() {
        return !!this.current_absence_id;
      },
      get documentsArray() {
        return Array.from(self.documents.values());
      },
      get employeeCv() {
        return [...this.documentsArray]
          .reverse()
          .find((document) => document.document_type === DOCUMENT_TYPE_RESUME);
      },
      get employeeAgreement() {
        return [...this.documentsArray]
          .reverse()
          .find(
            (document) =>
              document.document_type === DOCUMENT_TYPE_EMPLOYEE_AGREEMENT
          );
      },
      get employeeOtherDocuments() {
        return [...this.documentsArray].filter(
          (document) => document.document_type === DOCUMENT_TYPE_REFERENCE
        );
      },
      get employeeImage() {
        return [...this.documentsArray]
          .reverse()
          .find(
            (document) =>
              document.document_type.user_document_type_name ===
              DOCUMENT_TYPE_EMPLOYEE_IMAGE
          );
      },
      get isNewAbsenceValid() {
        if (
          !self.newAbsence ||
          (self.newAbsence && self.newAbsence.status === STATUS_AVAILABLE)
        ) {
          return true;
        } else if (
          self.newAbsence &&
          self.newAbsence.status !== STATUS_AVAILABLE &&
          (!self.newAbsence.starting_date || !self.newAbsence.end_date)
        ) {
          return false;
        }
        return true;
      },
      get isCurrentAbsenceValid() {
        if (
          !self.currentAbsence ||
          (self.currentAbsence &&
            self.currentAbsence.status === STATUS_AVAILABLE)
        ) {
          return true;
        } else if (
          self.currentAbsence &&
          self.currentAbsence.status !== STATUS_AVAILABLE &&
          (!self.currentAbsence.starting_date || !self.currentAbsence.end_date)
        ) {
          return false;
        }
        return true;
      },
      get absencesArray() {
        return Array.from(self.absences.values());
      },
      get isChangedDuringEdit() {
        const snapshot = getSnapshot(self);
        return !isEqual(
          snapshot,
          rootInstance.employeesPageState.initialLoadedEmployee
        );
      },
      get countriesArray() {
        return Array.from(self.countries.values());
      },
      get filteredStates() {
        const selectedCountry = self.country;
        return this.countriesArray.find((country) => {
          return country.name === selectedCountry;
        });
      },
    };
  })
  .actions((self) => {
    return {
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      setEmployeeFullyFetched(status) {
        self.employeeFullyFetched = status;
      },
      setIsSaveFirstModalOpen(status) {
        self.isSaveFirstModalOpen = status;
      },
      setActiveRole(role) {
        self.active_role = role;
      },
      setAbsences(items) {
        const endOfDay = '23:59:59';
        items.forEach((item) => {
          item.start = moment(item.starting_date).toDate();
          item.end = moment(item.end_date + ' ' + endOfDay).toDate();
          item.title = item.status.toUpperCase();
          self.absences.put({
            ...item,
          });
        });
      },
      setNewAbsence(absence) {
        self.newAbsence = absence;
      },
      setEditedAbsence(absence_id) {
        if (absence_id && self.isChangedDuringEdit) {
          this.setIsSaveFirstModalOpen(true);
        } else {
          self.editedAbsence = absence_id;
        }
      },
      createNewAbsence() {
        if (self.isChangedDuringEdit) {
          this.setIsSaveFirstModalOpen(true);
        } else {
          this.setNewAbsence({
            user_id: self.user_id,
          });
        }
      },
      setCurrentAbsence(absence_id) {
        self.currentAbsence = absence_id;
      },
      setUserAssignedRoles(roles) {
        self.user_assigned_roles = roles;
      },
      setChangePasswordModalOpen(status) {
        this.clearInputFields();
        self.changePasswordModalOpen = status;
      },
      clearInputFields() {
        this.setChangePasswordErrorText(null);
        this.setCurrentPassword(null);
        this.setNewPassword(null);
        this.setConfirmedPassword(null);
      },
      setChangePasswordErrorText(text) {
        self.change_password_error_text = text;
      },
      setCurrentPassword(password) {
        self.current_password = password;
      },
      setNewPassword(password) {
        self.new_password = password;
      },
      setConfirmedPassword(password) {
        self.confirmed_password = password;
      },
      setCurrentPasswordVisible(status) {
        self.current_password_visible = status;
      },
      setNewPasswordVisible(status) {
        self.new_password_visible = status;
      },
      setConfirmedPasswordVisible(status) {
        self.confirmed_password_visible = status;
      },
      setFirstTimeInApp(status) {
        self.firstTimeInApp = status;
      },
      setSuccessModalOpen(status) {
        if (!status) {
          this.setFirstTimeInApp(false);
        }
        self.successModalOpen = status;
      },
      setEmail(email) {
        self.email = email;
      },
      setFirstName(name) {
        self.first_name = name;
      },
      setLastName(lastName) {
        self.last_name = lastName;
      },
      setCodeName(codeName) {
        self.code_name = codeName;
      },
      setDateOfBirth(date) {
        self.date_of_birth = date;
      },
      setDepartment(department) {
        // if(department !== 0) {
        //   self.user_department = department;
        // }
        self.user_department = department;
      },
      setPhoneNumber(phoneNumber) {
        self.phone_number = phoneNumber;
      },
      setStreetAddress(street_address) {
        self.street_address = street_address;
      },
      setCity(city) {
        self.city = city;
      },
      setState(state) {
        self.state = state;
      },
      setCountry(country) {
        self.country = country;
      },
      setPostalCode(postal_code) {
        self.postal_code = postal_code;
      },
      setSocialSecurityNumber(social_security_number) {
        self.social_security_number = social_security_number;
      },
      setAccountStatus(account_status) {
        self.account_status = account_status;
      },
      setDocuments(items) {
        self.documents.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.documents.put({
            ...item,
          });
        });
      },
      setEditEmployeeErrorText(text) {
        self.errorText = text;
      },
      throwEditEmployeeError(error) {
        const errorText = getErrorText(error);
        this.setEditEmployeeErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateEmployeeUpdateForm() {
        const emailValid = validateEmail(self.email);
        const phoneNumberValid = validatePhoneNumber(self.phone_number);
        if (!self.first_name) {
          this.setEditEmployeeErrorText(FIRST_NAME_REQUIRED);
        } else if (!self.last_name) {
          this.setEditEmployeeErrorText(LAST_NAME_REQUIRED);
        } else if (!self.code_name) {
          this.setEditEmployeeErrorText(CODE_NAME_REQUIRED);
        } else if (!self.date_of_birth) {
          this.setEditEmployeeErrorText(DATE_OF_BIRTH_REQUIRED);
        } else if (!self.email) {
          this.setEditEmployeeErrorText(EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setEditEmployeeErrorText(EMAIL_ERROR_TEXT);
        } else if (!self.phone_number) {
          this.setEditEmployeeErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!phoneNumberValid) {
          this.setEditEmployeeErrorText(PHONE_NUMBER_ERROR_TEXT);
        } else if (!self.user_assigned_roles.length) {
          this.setEditEmployeeErrorText(USER_TYPE_REQUIRED);
        } else if (!self.isNewAbsenceValid || !self.isCurrentAbsenceValid) {
          this.setEditEmployeeErrorText(ABSENCE_ERROR_TEXT);
        } else {
          this.setEditEmployeeErrorText(null);
          return true;
        }
        return false;
      },
      validateCurrentUserUpdateForm() {
        const emailValid = validateEmail(self.email);
        const phoneNumberValid = validatePhoneNumber(self.phone_number);
        if (!self.first_name) {
          this.setEditEmployeeErrorText(FIRST_NAME_REQUIRED);
        } else if (!self.last_name) {
          this.setEditEmployeeErrorText(LAST_NAME_REQUIRED);
        } else if (!self.code_name) {
          this.setEditEmployeeErrorText(CODE_NAME_REQUIRED);
        } else if (!self.email) {
          this.setEditEmployeeErrorText(EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setEditEmployeeErrorText(EMAIL_ERROR_TEXT);
        } else if (!self.phone_number) {
          this.setEditEmployeeErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!phoneNumberValid) {
          this.setEditEmployeeErrorText(PHONE_NUMBER_ERROR_TEXT);
        } else {
          this.setEditEmployeeErrorText(null);
          return true;
        }
        return false;
      },
      updateEmployee(documents, documentsToDelete) {
        if (this.validateEmployeeUpdateForm()) {
          setFetchingInProgress(true);
          let promises = [];

          documentsToDelete.forEach((document_uid) => {
            promises.push(deleteEmployeeDocument(self.user_id, document_uid));
          });

          documents.forEach((document) => {
            promises.push(uploadEmployeeDocument(self.user_id, document));
          });

          Promise.all(promises)
            .then(() => {
              editEmployee(self)
                .then(() => {
                  this.setSuccessModalOpen(true);
                  setFetchingInProgress(false);
                })
                .catch((error) => {
                  this.throwEditEmployeeError(error);
                });
            })
            .catch((error) => {
              this.throwEditEmployeeError(error);
            });
        }
      },
      getCurrentUser() {
        getCurrentUserAccount()
          .then((current_user) => {
            getAllAccountDocumentation()
              .then((documentation) => {
                getAccountActiveRole()
                  .then((role) => {
                    this.setCurrentUser(current_user, documentation, role);
                  })
                  .catch((error) => {
                    this.throwEditEmployeeError(error);
                  });
              })
              .catch((error) => {
                this.throwEditEmployeeError(error);
              });
          })
          .catch((error) => {
            this.throwEditEmployeeError(error);
          });
      },
      setCurrentUser(user, documentation, role) {
        localStorage.removeItem('user');
        localStorage.removeItem('user_photo');
        localStorage.removeItem('active_role');
        localStorage.setItem('user', JSON.stringify(user.data));
        setCurrentUserLocalStoragePhoto(documentation);
        localStorage.setItem('active_role', JSON.stringify(role.data));
        this.setSuccessModalOpen(true);
        setFetchingInProgress(false);
      },
      updateCurrentUser(documents, documentsToDelete) {
        if (this.validateCurrentUserUpdateForm()) {
          setFetchingInProgress(true);
          const userActiveRole = JSON.parse(localStorage.getItem('role'));

          let promises = [];
          if (userActiveRole.role_id !== self.active_role.role_id) {
            promises.push(changeUserActiveRole(self.active_role.role_id));
          }

          documentsToDelete.forEach((document_uid) => {
            promises.push(deleteUserPhoto(document_uid));
          });

          documents.forEach((document) => {
            promises.push(uploadUserPhoto(document));
          });

          Promise.all(promises)
            .then(() => {
              editCurrentUser(self)
                .then(() => {
                  this.getCurrentUser();
                })
                .catch((error) => {
                  this.throwEditEmployeeError(error);
                });
            })
            .catch((error) => {
              this.throwEditEmployeeError(error);
            });
        }
      },
      changeCurrentUserPassword() {
        if (!self.current_password) {
          this.setChangePasswordErrorText(CURRENT_PASSWORD_REQUIRED_TEXT);
        } else if (!self.new_password) {
          this.setChangePasswordErrorText(NEW_PASSWORD_REQUIRED_TEXT);
        } else if (!self.confirmed_password) {
          this.setChangePasswordErrorText(CONFIRMED_PASSWORD_REQUIRED_TEXT);
        } else if (self.confirmed_password !== self.new_password) {
          this.setChangePasswordErrorText(PASSWORDS_NOT_MATCHING_ERROR_TEXT);
        } else {
          setFetchingInProgress(true);
          changePassword(
            self.current_password,
            self.new_password,
            self.confirmed_password
          )
            .then((response) => {
              this.setChangePasswordModalOpen(false);
              setFetchingInProgress(false);
              this.setSuccessModalOpen(true);
            })
            .catch((error) => {
              this.throwEditEmployeeError(error);
            });
        }
      },
    };
  });
