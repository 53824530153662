import { Empty, Input, Select, Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ADD, ADD_COMPENSATION_VALUE, ALL, DISCARD } from '../../../constants';
import {
  COMPENSATION_VALUES,
  COMPENSATION_VALUE_BASED_ON,
  COMPENSATION_VALUE_CURRENCY,
  COMPENSATION_VALUE_DEFAULT_VALUE,
  COMPENSATION_VALUE_NAME,
  COMPENSATION_VALUE_RATE,
  DEFAULT_VALUE,
  DEFAULT_VALUE_REGEX,
  EMPTY_COMPENSATION_VALUES_TABLE_TEXT,
  VALUES_DELETE,
} from '../../../constants/payroll';
import { useMst } from '../../../state/RootModel';
import ErrorBox from '../../ErrorBox';

const { Option } = Select;
const CompensationValuesSection = () => {
  const {
    payrollPageState: {
      getCompensationValuesOrDeductionsTableColumns,
      compensationCurrencies,
      compensationPaymentRates,
      compensationBasedOnTypes,

      compensationValuesByType,
      getAllEditedCompensationValues,
      edited_compensation_value,
      edited_compensation_based_on,
      edited_compensation_currency,
      edited_compensation_rate,
      value_default_value_temp,
      edited_value_error_text,
      setEditedCompensationValue,
      setEditedCompensationValueBasedOn,
      setEditedCompensationValueCurrency,
      setEditedCompensationValueRate,
      setValueDefaultValueTemp,
      addValueToEditedCompensationValuesList,
      setIsValueAddingFormOpen,
      isValueAddingFormOpen,
      clearAddingValueToEditingCompensationForm,
    },
  } = useMst();

  const onCancel = () => {
    setIsValueAddingFormOpen(false);
    clearAddingValueToEditingCompensationForm();
  };

  return (
    <div className="form-page-section">
      <div className="form-section-title-row pb10 mb10">
        <span className="form-section-title">{COMPENSATION_VALUES}</span>
        {!isValueAddingFormOpen && (
          <button
            className="btn primary ml20 ph20 pv0"
            onClick={() => setIsValueAddingFormOpen(true)}
          >
            {ADD_COMPENSATION_VALUE}
          </button>
        )}
      </div>
      <Table
        className="compensations-table"
        columns={getCompensationValuesOrDeductionsTableColumns(VALUES_DELETE)}
        dataSource={getAllEditedCompensationValues.map((el) =>
          el.based_on === null ? { ...el, based_on: 'N/A' } : el
        )}
        pagination={false}
        rowKey={(record, index) => index.toString()}
        rowClassName={() => 'compensation-table-expanded-row'}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_COMPENSATION_VALUES_TABLE_TEXT}
            />
          ),
        }}
      />
      {isValueAddingFormOpen && (
        <>
          <div className="section-content flex-alignitems-flex-start pb20 mt10">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>
                  {COMPENSATION_VALUE_NAME}
                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationValue(ct)}
                value={edited_compensation_value}
              >
                {compensationValuesByType.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{COMPENSATION_VALUE_BASED_ON}</span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationValueBasedOn(ct)}
                value={edited_compensation_based_on}
              >
                <Option key={ALL} value={null} className="text-capitalize">
                  -
                </Option>
                {compensationBasedOnTypes.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>
                  {COMPENSATION_VALUE_RATE}
                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationValueRate(ct)}
                value={edited_compensation_rate}
              >
                {compensationPaymentRates.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="section-content flex-alignitems-flex-start pb20">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{COMPENSATION_VALUE_DEFAULT_VALUE}</span>
              </div>
              <Input
                type="text"
                onFocus={() => {
                  if (value_default_value_temp === DEFAULT_VALUE)
                    setValueDefaultValueTemp(null);
                }}
                onBlur={() => {
                  if (!value_default_value_temp)
                    setValueDefaultValueTemp(DEFAULT_VALUE);
                }}
                onChange={(e) => {
                  var rgx = DEFAULT_VALUE_REGEX;

                  if (rgx.test(e.target.value) && e.target.value)
                    setValueDefaultValueTemp(e.target.value);
                  else {
                    setValueDefaultValueTemp(null);
                  }
                }}
                className="add-edit-input"
                value={value_default_value_temp}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>
                  {COMPENSATION_VALUE_CURRENCY}

                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationValueCurrency(ct)}
                value={edited_compensation_currency}
              >
                {compensationCurrencies.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="ml10">
            <ErrorBox obj={{ errorText: edited_value_error_text }} />
          </div>
          <div className="align-center justify-end">
            <button
              className="btn primary add no-wrap header-button mt10 mb20"
              onClick={() => addValueToEditedCompensationValuesList()}
            >
              {ADD}
            </button>
            <button
              type="button"
              className="btn transparent discard text-capitalize ml10 mt10 mb20"
              onClick={onCancel}
            >
              {DISCARD}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(CompensationValuesSection);
