import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Select, DatePicker } from 'antd';
import {
  GENERAL_INFORMATION,
  VIN,
  TYPE_OF_FINANCING,
  DATE_HIRED,
  OWNERSHIP_TYPE,
  STATUS,
  FLEET_OUT_OF_SERVICE_STATUS,
  NOTES,
} from '../../../constants';
import moment from 'moment';
import editPen from '../../../assets/images/edit-pen.svg';
import { replaceUnderscore } from '../../../utils/methods';
const { Option } = Select;

const GeneralInformation = () => {
  const {
    fleetPageState: {
      editedTruck,
      vehicleOwnershipTypes,
      vehicleFinancingTypes,
    },
  } = useMst();

  const onSelectFinancingType = (value) => {
    editedTruck.updateFinancingType(value);
  };

  const onSelectOwnershipType = (value) => {
    editedTruck.updateOwnershipType(value);
  };

  const onSelectStatus = (value) => {
    editedTruck.updateStatus(value);
    // editedTruck.setInitMaintenance({ vehicle_id: editedTruck.vehicle_id });
  };

  const onDateHiredChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedTruck.updateDateHired(date);
  };

  const openOutOfServiceModal = (e) => {
    e.stopPropagation();
    editedTruck.setOutOfServiceModalOpen(true);
  };

  return (
    <div className="form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{GENERAL_INFORMATION}</span>
      </div>

      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{VIN}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedTruck.updateVin(e.target.value);
            }}
            value={editedTruck.vin}
            className="add-edit-input"
            required
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{OWNERSHIP_TYPE}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectOwnershipType}
            value={editedTruck.ownership_type}
          >
            {vehicleOwnershipTypes.map((type) => (
              <Option key={type} value={type} className="text-capitalize">
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{TYPE_OF_FINANCING}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectFinancingType}
            value={editedTruck.type_of_financing}
          >
            {vehicleFinancingTypes.map((financingType) => (
              <Option
                key={financingType}
                value={financingType}
                className="text-capitalize"
              >
                {financingType}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{STATUS}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectStatus}
            value={replaceUnderscore(editedTruck.status)}
          >
            {editedTruck.availableStatuses.map((status, index) => (
              <Option key={index} value={status} className="text-capitalize">
                <div className="align-center">
                  <div
                    className={`status-dot mr10 ${
                      status === FLEET_OUT_OF_SERVICE_STATUS
                        ? 'unavailable'
                        : ''
                    }`}
                  />
                  <div>{replaceUnderscore(status)}</div>
                  {status === FLEET_OUT_OF_SERVICE_STATUS && (
                    <img
                      className="ml10"
                      src={editPen}
                      alt="edit pen"
                      onClick={openOutOfServiceModal}
                    />
                  )}
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label">
            <span>{DATE_HIRED}</span>
          </div>
          <DatePicker
            allowClear={false}
            onChange={onDateHiredChange}
            value={
              editedTruck.date_hired ? moment(editedTruck.date_hired) : null
            }
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{NOTES}</span>
          </div>
          <Input
            onChange={(e) => {
              editedTruck.setNotes(e.target.value);
            }}
            className="add-edit-input"
            value={editedTruck.notes}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformation);
