import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Select } from 'antd';
import {
  COMPENSATION_NAME,
  COMPENSATION_PERIOD,
  COMPENSATION_STRUCTURE_INFORMATION,
  COMPENSATION_TYPE,
} from '../../../constants/payroll';

const { Option } = Select;

const AddCompensationForm = () => {
  const {
    payrollPageState: {
      newCompensationStructure,
      getCompensationValuesByTypeForNewCompensation,
    },
  } = useMst();

  return (
    <div className="add-driver-section form-page-section">
      <div className="form-section-title-row pb10 mb10">
        {COMPENSATION_STRUCTURE_INFORMATION}
        <span className="form-section-title"></span>
      </div>
      <div className="section-content flex-alignitems-flex-start pb20">
        <div className="input-wrapper ml0 w30">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_NAME}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Input
            type="text"
            className="add-edit-input"
            onChange={(e) => newCompensationStructure.setName(e.target.value)}
            value={newCompensationStructure.compensation_name}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_TYPE}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Select
            className={'w100'}
            onChange={(ct) => {
              newCompensationStructure.setCompensationType(ct);
              getCompensationValuesByTypeForNewCompensation();
            }}
            value={newCompensationStructure.compensation_type}
          >
            {newCompensationStructure?.allCompensationTypes.map((ct, index) => (
              <Option key={index} value={ct}>
                {ct}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_PERIOD}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Select
            className={'w100'}
            onChange={(ct) =>
              newCompensationStructure.setCompensationPeriod(ct)
            }
            value={newCompensationStructure.compensation_payment_period}
          >
            {newCompensationStructure?.allCompensationPaymentPeriods.map(
              (ct, index) => (
                <Option key={index} value={ct}>
                  {ct}
                </Option>
              )
            )}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default observer(AddCompensationForm);
