import React from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../../../state/RootModel';
import FormPageTitle from '../../../../FormPageTitle';
import {
  EDIT_CARRIER,
  MODAL_SUCCESS_TITLE,
  VIEW_CARRIER,
  MODAL_SUCCESS_EDIT_CARRIER_TEXT,
  MODAL_SUCCESS_EDIT_CARRIER_BUTTON_TEXT,
} from '../../../../../constants';
import GeneralInformation from './GeneralInformation';
import addCarrier from '../../../../../assets/images/add-carrier.svg';
import EditCarrierModal from '../editCarrier/EditCarrierModal';
import SuccessModal from '../../../../SuccessModal';
import PageRoutes from '../../../../../utils/PageRoutes';

const ViewCarrierComponent = () => {
  const {
    carriersPageState: {
      viewedCarrier,
      editedCarrier,
      editCarrierSuccessModalOpen,
      setEditCarrierSuccessModalOpen,
    },
  } = useMst();

  const onEditCarrier = () => {
    rootInstance.carriersPageState.getEditedCarrier(viewedCarrier.carrier_id);
  };

  const backLink = PageRoutes.ViewCarrier.name;

  const params = { carrierId: viewedCarrier.carrier_id };

  return (
    <div className="page-content">
      {viewedCarrier?.fullyFetched && (
        <div>
          {/* TITLE */}
          <div className="align-center">
            <FormPageTitle image={addCarrier} title={VIEW_CARRIER} />
            <span>
              <h2 className="text-medium color-primary">
                {viewedCarrier.company_name}
              </h2>
            </span>
            <button className="btn primary ml50" onClick={onEditCarrier}>
              {EDIT_CARRIER}
            </button>
          </div>

          <GeneralInformation />
        </div>
      )}
      {editedCarrier && <EditCarrierModal />}
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_CARRIER_TEXT}
        buttonText={MODAL_SUCCESS_EDIT_CARRIER_BUTTON_TEXT}
        backLink={backLink}
        params={params}
        open={editCarrierSuccessModalOpen}
        closeModal={() => setEditCarrierSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(ViewCarrierComponent);
