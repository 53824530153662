import React from 'react';
import { observer } from 'mobx-react';
import { Input, Icon, Form } from 'antd';
import { useMst } from '../../state/RootModel';
import showPassword from '../../assets/images/show-password.svg';
import hidePassword from '../../assets/images/hide-password.svg';
import {
  PASSWORD,
  LOGIN,
  FORGOT_PASSWORD,
  ENTER_LOGIN_CREDENTIALS_TEXT,
  EMAIL_INPUT_PLACEHOLDER,
} from '../../constants';
import LoginErrorBox from './LoginErrorBox';

const LoginFormComponent = () => {
  const loginForm = useMst().loginForm;

  const onSubmit = (e) => {
    e.preventDefault();
    loginForm.submit(loginForm.username, loginForm.password);
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    if (!loginForm.loading) {
      loginForm.setStep(2);
    }
  };

  return (
    <div className="login-form-content">
      <div className="form-content">
        <h3 className="title">{LOGIN}</h3>
        <p className="mb10">{ENTER_LOGIN_CREDENTIALS_TEXT}</p>
        <Form onSubmit={onSubmit}>
          <Form.Item>
            <Input
              onChange={(e) => loginForm.setUsername(e.target.value)}
              placeholder={EMAIL_INPUT_PLACEHOLDER}
              value={loginForm.username}
              className="login-input"
              required
            />
          </Form.Item>
          <Form.Item>
            <Input
              onChange={(e) => loginForm.setPassword(e.target.value)}
              placeholder={PASSWORD}
              type={loginForm.passwordVisible ? 'text' : 'password'}
              value={loginForm.password}
              className="login-input mb10"
              required
            />
            {loginForm.passwordVisible ? (
              <img
                className="show-password-sign"
                src={hidePassword}
                alt="eye"
                height="18"
                onClick={() => loginForm.setPasswordVisible(false)}
              />
            ) : (
              <img
                className="show-password-sign"
                src={showPassword}
                alt="eye"
                height="18"
                onClick={() => loginForm.setPasswordVisible(true)}
              />
            )}
          </Form.Item>

          <div className="justify-end">
            <a
              className={`forgot-password-link ${
                loginForm.loading && 'disabled'
              }`}
              onClick={(e) => forgotPassword(e)}
              href="# "
            >
              {FORGOT_PASSWORD}
            </a>
          </div>
          <div className="login-box">
            {loginForm.errorText && <LoginErrorBox />}
          </div>
          <button
            className={`btn secondary login-btn mt20 ${
              loginForm.loading && 'disabled'
            }`}
            type={'submit'}
            disabled={loginForm.loading}
          >
            {loginForm.loading && (
              <span className="mr10">
                <Icon type="loading" />
              </span>
            )}{' '}
            {LOGIN}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default observer(LoginFormComponent);
