import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { WORKING_STATUS, ADD_ABSENCE_TEXT } from '../../../constants';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

const localizer = momentLocalizer(moment);

const WorkingStatusComponent = () => {
  const {
    employeesPageState: { editedEmployee },
    loginForm: { currentUser },
    userState: { canEditUserAbsences, canAddUserAbsences },
  } = useMst();

  const employee = editedEmployee
    ? editedEmployee
    : currentUser
    ? currentUser
    : null;

  const onAddAbsence = () => {
    employee.createNewAbsence();
  };

  const onEventClick = (absence) => {
    canEditUserAbsences && employee.setEditedAbsence(absence.absence_id);
  };

  return (
    <div
      className="form-page-section edit-employee-section pb20"
      id="working-status"
    >
      <div className="form-section-title-row align-center">
        <span className="form-section-title">{WORKING_STATUS}</span>
        {canAddUserAbsences && (
          <button className="btn primary ml20 ph20 pv0" onClick={onAddAbsence}>
            {ADD_ABSENCE_TEXT}
          </button>
        )}
      </div>
      <div className="flex">
        <Calendar
          localizer={localizer}
          events={employee?.absencesArray}
          style={{ height: 500, width: '100%' }}
          defaultView="month"
          views={['month']}
          eventPropGetter={(event) => {
            return {
              style: { backgroundColor: event.color },
            };
          }}
          onSelectEvent={onEventClick}
        />
      </div>
    </div>
  );
};

export default observer(WorkingStatusComponent);
