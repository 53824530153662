import React from 'react';
import { observer } from 'mobx-react';
import CustomersTable from './CustomersTable';
import {
  ADD_CUSTOMER,
  MODAL_SUCCESS_ADD_CUSTOMER_BUTTON_TEXT,
  MODAL_SUCCESS_ADD_CUSTOMER_TEXT,
  MODAL_SUCCESS_EDIT_CUSTOMER_BUTTON_TEXT,
  MODAL_SUCCESS_EDIT_CUSTOMER_TEXT,
  MODAL_SUCCESS_TITLE,
  START_TYPING_PLACEHOLDER_TEXT,
} from '../../../../constants';
import { useMst } from '../../../../state/RootModel';
import AddCustomerModal from './AddCustomerModal';
import SuccessModal from '../../../SuccessModal';
import PageRoutes from '../../../../utils/PageRoutes';
import { DebounceInput } from 'react-debounce-input';
import search from '../../../../assets/images/search.svg';
import EditCustomerModal from './EditCustomerModal';

const CustomersComponent = () => {
  const {
    clientsPageState: {
      setNewCustomer,
      newCustomer,
      addCustomerSuccessModalOpen,
      setAddClientSuccessModalOpen,
      setSearchTerm,
      searchTerm,
      getClients,
      setCurrentPage,
      editedCustomer,
      editCustomerSuccessModalOpen,
      setEditClientSuccessModalOpen,
    },
    userState: { canAddClients },
  } = useMst();

  const onAddCustomer = () => {
    setNewCustomer({});
  };

  const handleCustomerSearch = (e) => {
    const searchText = e.target.value;
    setSearchTerm(searchText);
    setCurrentPage(1);
    getClients({});
  };

  const backLink = PageRoutes.Brokerage.name;

  return (
    <div className="pv20 customers-component">
      {canAddClients && (
        <button
          className={'btn primary no-wrap header-button'}
          onClick={onAddCustomer}
        >
          {ADD_CUSTOMER}
        </button>
      )}
      <div className="search-wrapper border-bottom-search align-center pb20">
        <img src={search} alt="search" className="search-icon" />
        <DebounceInput
          debounceTimeout={300}
          onChange={(e) => handleCustomerSearch(e)}
          style={{ width: 300 }}
          placeholder={START_TYPING_PLACEHOLDER_TEXT}
          value={searchTerm}
        />
      </div>
      <CustomersTable />
      {newCustomer && <AddCustomerModal />}
      {editedCustomer && <EditCustomerModal />}
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_ADD_CUSTOMER_TEXT}
        buttonText={MODAL_SUCCESS_ADD_CUSTOMER_BUTTON_TEXT}
        backLink={backLink}
        open={addCustomerSuccessModalOpen}
        closeModal={() => setAddClientSuccessModalOpen(false)}
      />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_CUSTOMER_TEXT}
        buttonText={MODAL_SUCCESS_EDIT_CUSTOMER_BUTTON_TEXT}
        backLink={backLink}
        open={editCustomerSuccessModalOpen}
        closeModal={() => setEditClientSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(CustomersComponent);
