import {
  getDepartments,
  getPermissions,
  getUserRoles,
  getRoleById,
} from '../../actions/organization';

//Permissions
export const getPermissionsPromise = (model) => {
  return new Promise((resolve, reject) => {
    getPermissions()
      .then((response) => {
        model.setPermissionsList(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//Departments
export const getDepartmentsPromise = (model) => {
  return new Promise((resolve, reject) => {
    getDepartments()
      .then((response) => {
        model.setDepartmentsList(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//User roles
export const getUserRolesPromise = (model) => {
  return new Promise((resolve, reject) => {
    getUserRoles()
      .then((response) => {
        model.setUserRolesList(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Get role permissions

export const getRoleByIdPromise = (model, role_id) => {
  return new Promise((resolve, reject) => {
    getRoleById(role_id)
      .then((response) => {
        model.editedUserRole.setRoleName(response.data.role_name);
        model.editedUserRole.setPermissions(response.data.permissions);
        resolve(resolve);
      })
      .catch((error) => {
        console.log('error: ', error);
        reject(error);
      });
  });
};
