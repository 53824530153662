import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import FormPageTitle from '../../FormPageTitle';
import viewEmployeeIcon from '../../../assets/images/view-employee.svg';
import { EDIT_EMPLOYEE, VIEW_EMPLOYEE } from '../../../constants';
import PageRoutes from '../../../utils/PageRoutes';
import Link from '../../Link';
import GeneralInformation from './GeneralInformation';
import WorkingStatusComponent from './WorkingStatusComponent';
import ViewDocumentationSection from '../../fleet/SharedComponents/ViewComponents/ViewDocumentationSection';

const ViewEmployeeComponent = () => {
  const {
    employeesPageState: { viewedEmployee },
    userState: { canEditUsers, canViewUserAbsences, canViewUserDocuments },
  } = useMst();

  return (
    <div className="add-edit-form-component">
      {viewedEmployee?.employeeFullyFetched && (
        <div>
          <div className="align-center">
            <FormPageTitle image={viewEmployeeIcon} title={VIEW_EMPLOYEE} />
            <span>
              <h2 className="text-medium color-primary">
                {viewedEmployee.fullName}
              </h2>
            </span>
            {canEditUsers && (
              <Link
                className={'btn primary ml10 ph20 pv0'}
                view={PageRoutes.EditEmployee.name}
                params={{ employeeId: viewedEmployee.user_id }}
              >
                {EDIT_EMPLOYEE}
              </Link>
            )}
          </div>
          <GeneralInformation />
          {canViewUserAbsences && <WorkingStatusComponent />}
          {canViewUserDocuments && (
            <ViewDocumentationSection model={viewedEmployee} />
          )}
        </div>
      )}
    </div>
  );
};

export default observer(ViewEmployeeComponent);
