import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';

export const Link = observer(
  ({ children, className, onClick, params, queryParams, view }) => {
    const { router } = useMst();
    if (typeof view === 'string') {
      view = router.views.get(view);
    }
    const clickLink = (e) => {
      e.preventDefault();
      onClick && onClick(e);
      router.setView(view, params, queryParams);
    };
    return (
      <div onClick={clickLink} className={`${className} link`}>
        {children}
      </div>
    );
  }
);

export default Link;
