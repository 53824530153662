import React from 'react';
import { observer } from 'mobx-react';
import { BILLING_DETAILS } from '../../../constants';
import { useMst } from '../../../state/RootModel';
import { toAmount } from '../../../utils/methods';

const BillingDetails = () => {
  const {
    shipmentsPageState: {
      currentShipment: { shipmentChargesArray },
    },
  } = useMst();

  return (
    <div className="page-section shipment-details-section w50">
      <div className="subsection-header align-center">
        <p>{BILLING_DETAILS}</p>
      </div>
      <ul className="subsection-content flex-column w100">
        {shipmentChargesArray.map((item) => (
          <li key={item.shipment_charge_id} className="w100 align-center mt10">
            <div className="w20">{item.currency}</div>
            <div className="w50 ml5pc">{item.charge_name}</div>
            <div className="w25 ml5pc">{toAmount(item.price)}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(BillingDetails);
