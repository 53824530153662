import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty, Pagination, notification } from 'antd';
import { useMst } from '../../state/RootModel';
import PageRoutes from '../../utils/PageRoutes';
import Link from '../../components/Link';
import {
  EMPTY_EMPLOYEES_TABLE_TEXT,
  DRIVERS,
  SEARCH_DRIVERS,
  PRINT_PDF,
  FETCH_DRIVERS_ERROR,
  EXPORT_TO_CSV,
  ADD_DRIVER,
} from '../../constants';
import { DebounceInput } from 'react-debounce-input';
import search from '../../assets/images/search.svg';

import {
  snakeCaseToString,
  exportToPdf,
  getErrorText,
  htmlToCSV,
} from '../../utils/methods';
import { getDriversForExport } from '../../actions/fleet';
import {
  DRIVERS_TABLE_DOC_NAME,
  DRIVERS_TABLE_TITLE,
} from '../../constants/tableData';

const DriversTable = () => {
  const TABLE_CLASS_NAME = 'drivers-table';

  const {
    fleetPageState: {
      getDriversColumns,
      filteredDriversArray,
      driversCurrentPage,
      driversPageSize,
      driversSearchTerm,
      driversTotalItems,
      setDriversCurrentPage,
      setDriversSearchTerm,
      getDrivers,
      setDrivers,
      setDriversTotalItems,
    },
    userState: { canAddDrivers },
    router,
  } = useMst();

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setDriversSearchTerm(searchText);
    setDriversCurrentPage(1);
    getDrivers({});
  };

  const onRowClick = (e, record) => {
    e.stopPropagation();
    const params = { driverId: record.driver_id };
    router.setView(router.views.get(PageRoutes.ViewDriver.name), params);
  };

  const paginationChange = (val) => {
    setDriversCurrentPage(val);
    getDrivers({ page: val });
  };

  const handleCsvExport = async () => {
    try {
      const drivers = await getDriversForExport(driversSearchTerm);
      const table = document.getElementsByClassName('ant-table-fixed')[0];
      setDrivers(drivers.data.drivers);
      setDriversTotalItems(drivers.data.total);
      htmlToCSV(table, TABLE_CLASS_NAME, DRIVERS_TABLE_DOC_NAME);
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_DRIVERS_ERROR,
        description: errorText,
      });
    } finally {
      getDrivers({ page: 1 });
    }
  };

  const handlePDFExport = async () => {
    try {
      const drivers = await getDriversForExport(driversSearchTerm);

      const headersArr = [[]];
      getDriversColumns().map((head) => headersArr[0].push(head.title));
      headersArr[0].pop();
      const data = drivers.data.drivers.map((elt) => {
        const driverType = snakeCaseToString(elt.driver_type);
        const driverCategory =
          elt.driver_category.charAt(0).toUpperCase() +
          elt.driver_category.slice(1);
        return [
          elt.internal_company_id,
          driverType,
          elt.code_name,
          driverCategory,
          elt.email,
          elt.phone_number ? elt.phone_number : '/',
          elt.city + ', ' + elt.state,
          elt.status.charAt(0).toUpperCase() + elt.status.slice(1),
        ];
      });

      exportToPdf(
        headersArr,
        data,
        DRIVERS_TABLE_TITLE,
        DRIVERS_TABLE_DOC_NAME
      );
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_DRIVERS_ERROR,
        description: errorText,
      });
    } finally {
      getDrivers({ page: 1 });
    }
  };

  return (
    <div>
      <div className="table-header mb20 mt20 align-center">
        <span className="text-capitalize color-primary text-medium ml10">
          {DRIVERS}
        </span>
        <div className="search-wrapper border-bottom-search create-shipment-search align-center ml30">
          <img src={search} alt="search" className="search-icon" />
          <DebounceInput
            debounceTimeout={250}
            onChange={(e) => handleSearch(e)}
            style={{ width: 300 }}
            placeholder={SEARCH_DRIVERS}
            value={driversSearchTerm}
          />
        </div>
        {canAddDrivers && (
          <Link className="btn light add ml30" view={PageRoutes.AddDriver.name}>
            {ADD_DRIVER}
          </Link>
        )}
        <button
          type="button"
          className="btn light add ml30"
          onClick={handlePDFExport}
        >
          {PRINT_PDF}
        </button>
        <button
          type="button"
          className="btn white add ml30"
          onClick={handleCsvExport}
        >
          {EXPORT_TO_CSV}
        </button>
      </div>
      <Table
        className={`${TABLE_CLASS_NAME} fleet-table`}
        rowKey={'driver_id'}
        columns={getDriversColumns()}
        dataSource={filteredDriversArray}
        pagination={false}
        onRow={(r) => ({
          onClick: (e) => onRowClick(e, r),
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_EMPLOYEES_TABLE_TEXT}
            />
          ),
        }}
      />
      <div className="table-footer">
        <Pagination
          onChange={paginationChange}
          total={driversTotalItems}
          current={driversCurrentPage}
          pageSize={driversPageSize}
        />
      </div>
    </div>
  );
};

export default observer(DriversTable);
