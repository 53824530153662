import React from 'react';
import { observer } from 'mobx-react';
import { ADD, MODAL_SUCCESS_TITLE } from '../../../constants';
import search from '../../../assets/images/search.svg';
import { DebounceInput } from 'react-debounce-input';
import {
  ORGANIZATION,
  SEARCH_ORGANIZATIONS,
  EMPTY_ORGANIZATION_TABLE_TEXT,
  MODAL_SUCCESS_CREATE_ORGANIZATION_TEXT,
  MODAL_SUCCESS_ORGANIZATION_BUTTON_TEXT,
} from '../../../constants/organizations/organizations';
import { Empty, Table } from 'antd';
import { useMst } from '../../../state/RootModel';
import SuccessModal from '../../SuccessModal';
import PageRoutes from '../../../utils/PageRoutes';

const OrganizationTable = () => {
  const {
    organizationState: {
      getFilteredOrganizations,
      setOrganizationSearchTerm,
      setAddOrganizationModalOpen,
      getOrganizationColumns,
      newOrganization,
      setOrganizationSuccessModalOpened,
      organizationSuccessModalOpened,
    },
    userState: { canAddOrganizations },
  } = useMst();

  const organizationsSearchTerm = '';
  const handleSearch = (e) => {
    setOrganizationSearchTerm(e.target.value);
  };

  const onOrganizationModalOpen = (e) => {
    setAddOrganizationModalOpen(true);
    newOrganization.clearNewOrganizationForm();
  };
  const onCloseOrganizationSuccessModal = () => {
    setOrganizationSuccessModalOpened(false);
  };
  const backLink = PageRoutes.Organizations.name;

  return (
    <div>
      <div className="table-header mb20 mt20 align-center">
        <div className="search-wrapper border-bottom-search create-shipment-search align-center ml30">
          <img src={search} alt="search" className="search-icon" />
          <DebounceInput
            debounceTimeout={250}
            onChange={(e) => handleSearch(e)}
            style={{ width: 300 }}
            placeholder={SEARCH_ORGANIZATIONS}
            value={organizationsSearchTerm}
            className="trailer-search-input"
          />
        </div>
        {canAddOrganizations && (
          <button
            onClick={onOrganizationModalOpen}
            className="btn light add ml30"
          >
            <span>
              {ADD} {ORGANIZATION}
            </span>
          </button>
        )}
      </div>
      <Table
        className="drivers-table fleet-table"
        rowKey={'organization_id'}
        columns={getOrganizationColumns()}
        dataSource={getFilteredOrganizations}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_ORGANIZATION_TABLE_TEXT}
            />
          ),
        }}
      />
      {/*<div className="table-footer">*/}
      {/*    <Pagination*/}
      {/*        onChange={paginationChange}*/}
      {/*        total={driversTotalItems}*/}
      {/*        current={driversCurrentPage}*/}
      {/*        pageSize={driversPageSize}*/}
      {/*    />*/}
      {/*</div>*/}
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_CREATE_ORGANIZATION_TEXT}
        buttonText={MODAL_SUCCESS_ORGANIZATION_BUTTON_TEXT}
        backLink={backLink}
        open={organizationSuccessModalOpened}
        closeModal={() => onCloseOrganizationSuccessModal()}
      />
    </div>
  );
};

export default observer(OrganizationTable);
