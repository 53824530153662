import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { PageRoutes } from '../../utils/PageRoutes';
import DepartmentsList from '../../components/employees/DepartmentsList';
import SelectEmployeesRow from '../../components/employees/SelectEmployeesRow';
import ConfirmDeleteEmployeeModal from '../../components/employees/ConfirmDeleteEmployeeModal';
import ConfirmDeleteEmployeesModal from '../../components/employees/ConfirmDeleteEmployeesModal';
import ConfirmReactivateEmployeeModal from '../../components/employees/ConfirmReactivateEmployeeModal';
import SuccessModal from '../../components/SuccessModal';
import {
  DELETE_MODAL_SUCCESS_TITLE,
  DELETE_EMPLOYEE_MODAL_SUCCESS_TEXT,
  DELETE_EMPLOYEE_MODAL_SUCCESS_BUTTON_TEXT,
  DELETE_EMPLOYEES_MODAL_SUCCESS_TEXT,
  REACTIVATE_EMPLOYEE_MODAL_SUCCESS_TITLE,
  REACTIVATE_EMPLOYEE_MODAL_SUCCESS_TEXT,
  REACTIVATE_EMPLOYEES_MODAL_SUCCESS_TEXT,
} from '../../constants';
import ConfirmReactivateEmployeesModal from '../../components/employees/ConfirmReactivateEmployeesModal';

const EmployeesPage = () => {
  const {
    employeesPageState: {
      successDeleteEmployeeModalOpen,
      setSuccessDeleteEmployeeModalOpen,
      successDeleteEmployeesModalOpen,
      setSuccessDeleteEmployeesModalOpen,
      successReactivateEmployeeModalOpen,
      setSuccessReactivateEmployeeModalOpen,
      successReactivateEmployeesModalOpen,
      setSuccessReactivateEmployeesModalOpen,
    },
  } = useMst();

  const backLink = PageRoutes.Employees.name;

  return (
    <div>
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_EMPLOYEE_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteEmployeeModalOpen}
        buttonText={DELETE_EMPLOYEE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setSuccessDeleteEmployeeModalOpen(false)}
      />
      <ConfirmDeleteEmployeeModal />

      <ConfirmDeleteEmployeesModal />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_EMPLOYEES_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteEmployeesModalOpen}
        buttonText={DELETE_EMPLOYEE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setSuccessDeleteEmployeesModalOpen(false)}
      />

      <ConfirmReactivateEmployeeModal />
      <SuccessModal
        title={REACTIVATE_EMPLOYEE_MODAL_SUCCESS_TITLE}
        text={REACTIVATE_EMPLOYEE_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successReactivateEmployeeModalOpen}
        buttonText={DELETE_EMPLOYEE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setSuccessReactivateEmployeeModalOpen(false)}
      />

      <ConfirmReactivateEmployeesModal />
      <SuccessModal
        title={REACTIVATE_EMPLOYEE_MODAL_SUCCESS_TITLE}
        text={REACTIVATE_EMPLOYEES_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successReactivateEmployeesModalOpen}
        buttonText={DELETE_EMPLOYEE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setSuccessReactivateEmployeesModalOpen(false)}
      />

      <div className="page employees-page">
        <div className="page-content">
          <DepartmentsList />
          <SelectEmployeesRow />
        </div>
      </div>
    </div>
  );
};

export default observer(EmployeesPage);
