import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import PageRoutes from '../../../../../utils/PageRoutes';
import { Empty, Table } from 'antd';
import {
  EMPTY_SHIPMENT_DOCUMENT_TYPES_TABLE_TEXT,
  MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_BUTTON_TEXT,
  MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../../../../constants';
import SuccessModal from '../../../../SuccessModal';
import ConfirmDeleteShipmentStatus from './ConfirmDeleteShipmentStatus';

const ShipmentStatusTable = () => {
  const {
    shipmentSpecificsPageState: {
      getShipmentStatusesColumns,
      shipmentStatusesArray,
      setDeleteShipmentStatusSuccessModalOpen,
      successDeleteShipmentStatusModalOpen,
    },
  } = useMst();

  const backLink = PageRoutes.Brokerage.name;

  return (
    <div>
      <Table
        className="drivers-table fleet-table mt20"
        rowKey={'status_id'}
        columns={getShipmentStatusesColumns()}
        dataSource={shipmentStatusesArray}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_SHIPMENT_DOCUMENT_TYPES_TABLE_TEXT}
            />
          ),
        }}
      />
      <ConfirmDeleteShipmentStatus />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_TEXT}
        buttonText={MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_BUTTON_TEXT}
        backLink={backLink}
        open={successDeleteShipmentStatusModalOpen}
        closeModal={() => setDeleteShipmentStatusSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(ShipmentStatusTable);
