import React from 'react';

import {
  SHIPMENT_NO,
  PICKUP_COUNTRY,
  DELIVERY_CONTRY,
  SUM_EMPTY_MILES,
  SUM_LOADED_MILES,
  SUM_TOTAL_MILES,
  SUM_TOTAL_CHARGE,
  SUM_TOTAL_COST,
  SUM_PROFIT,
  AVERAGE_REVENUE_MILE,
  AVERAGE_COST_MILE,
  AVERAGE_PROFIT_MILE,
  AVERAGE_DRIVER_MILE,
} from '../../constants/reports';

export const reportsTableColumns = (rootInstance) => ({
  '1': {
    title: SHIPMENT_NO,
    dataIndex: 'shipment_number',
    key: 'shipment_number',
  },

  '2': {
    title: PICKUP_COUNTRY,
    dataIndex: 'pick_up_country',
    key: 'pick_up_country',
  },

  '3': {
    title: DELIVERY_CONTRY,
    dataIndex: 'delivery_country',
    key: 'delivery_country',
  },

  '4': {
    title: SUM_EMPTY_MILES,
    dataIndex: 'sum_of_empty_miles',
    key: 'sum_of_empty_miles',
  },

  '5': {
    title: SUM_LOADED_MILES,
    dataIndex: 'sum_of_loaded_miles',
    key: 'sum_of_loaded_miles',
  },

  '6': {
    title: SUM_TOTAL_MILES,
    dataIndex: 'sum_of_total_miles',
    key: 'sum_of_total_miles',
  },

  '7': {
    title: SUM_TOTAL_CHARGE,
    dataIndex: 'sum_of_total_charge',
    key: 'sum_of_total_charge',
    render: (value) => (
      <>{`${value?.shipment_charge} ${value?.currency ?? ''}`}</>
    ),
  },

  '8': {
    title: SUM_TOTAL_COST,
    dataIndex: 'sum_of_total_cost',
    key: 'sum_of_total_cost',
    render: (value) => (
      <>{`${value?.total_cost?.toFixed?.(2)} ${value?.currency ?? ''}`}</>
    ),
  },

  '9': {
    title: SUM_PROFIT,
    dataIndex: 'sum_of_profit',
    key: 'sum_of_profit',
  },

  '10': {
    title: AVERAGE_REVENUE_MILE,
    dataIndex: 'average_of_ravenue_per_mile',
    key: 'average_of_ravenue_per_mile',
  },

  '11': {
    title: AVERAGE_COST_MILE,
    dataIndex: 'average_of_cost_per_mile',
    key: 'average_of_cost_per_mile',
  },

  '12': {
    title: AVERAGE_PROFIT_MILE,
    dataIndex: 'average_of_profit_per_mile',
    key: 'average_of_profit_per_mile',
  },

  '13': {
    title: AVERAGE_DRIVER_MILE,
    dataIndex: 'average_of_driver_per_mile',
    key: 'average_of_driver_per_mile',
  },
});
