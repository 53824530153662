import React from 'react';
import { observer } from 'mobx-react';
import { Marker, Popup } from 'react-leaflet';
import { deliveryIcon, pickupIcon } from '../Icon';
import moment from 'moment';
import { PICKUP_TYPE } from '../../../constants/dispatch';

const DispatchMapMarker = (props) => {
  const { data } = props;
  const { type } = props;

  function LocationMarker() {
    const onClick = (record) => {};
    // const onClick = record => ({
    //     onContextMenu: event => {
    //         event.preventDefault();
    //         console.log('Selected shipment: ', selectedShipment)
    //     }
    // })

    // @ts-ignore
    return (
      <Marker
        // @ts-ignore
        position={[data.location_latitude, data.location_longitude]}
        // @ts-ignore
        icon={type === PICKUP_TYPE ? pickupIcon : deliveryIcon}
        className="dispatch-map-marker"
        eventHandlers={{ click: (e) => onClick(e) }}
      >
        <Popup>
          <p
            className={`dispatch-map-marker__type text-bold ${
              type === PICKUP_TYPE ? 'pickup' : 'delivery'
            }`}
          >
            {/*{type === PICKUP_TYPE ? PICKUP_LOCATION : DELIVERY_LOCATION}*/}
            {data.location_name}
            {data.scheduled_pickup_time_start && (
              <span className="ml10">
                (
                {moment(data.scheduled_pickup_time_start).format('HH:MM') + 'h'}
                )
              </span>
            )}
            {data.scheduled_delivery_time_start && (
              <span className="ml10">
                (
                {moment(data.scheduled_delivery_time_start).format('HH:MM') +
                  'h'}
                )
              </span>
            )}
          </p>
          <p className="dispatch-map-marker__info">
            <span className="dispatch-map-marker__company text-bold">
              {data.company}
            </span>
            <br />
            {data.address},{data.city},{data.state}
          </p>
        </Popup>
      </Marker>
    );
  }

  return <LocationMarker />;
};

export default observer(DispatchMapMarker);
