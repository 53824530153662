export enum permissions {
  REMOVE_CARRIER_EQUIPMENTS = 'Remove carrier equipments',
  ADD_CARRIER_EQUIPMENTS = 'Add carrier equipments',
  VIEW_CARRIER_EQUIPMENTS = 'View carrier equipments',
  VIEW_PERMISSIONS_FOR_ROLES = 'View permissions for roles',
  REMOVE_PERMISSIONS_FOR_ROLES = 'Remove permissions for roles',
  REMOVE_ROLES = 'Remove roles',
  VIEW_USER_ROLES = "View user's roles",
  ADD_USER_ROLES = "Add user's roles",
  REMOVE_USER_ROLES = "Remove user's roles",
  ADD_USER_ACTIVE_ROLES = "Add user's active roles",
  ADD_DRIVER_DRIVES_VEHICLE_ASSOCIATIONS = 'Add driver drives vehicle associations',
  ADD_NEW_USERS = 'Add new users',
  VIEW_USERS = 'View users',
  EDIT_USERS = 'Edit users',
  EDIT_USER_ABSENCES = 'Edit user absences',
  VIEW_USERS_DEPARTMENTS = "View user's departments",
  DEACTIVATE_USER_ACCOUNT = 'Deactivate user account',
  ACTIVATE_USER_ACCOUNT = 'Activate user account',
  VIEW_USER_DOCUMENT_TYPES = 'View user document types',
  VIEW_USER_DOCUMENTS = 'View user documents',
  REMOVE_USER_DOCUMENTS = 'Remove user documents',
  VIEW_DEPARTMENTS = 'View departments',
  EDIT_DEPARTMENTS = 'Edit departments',
  ADD_USER_TO_DEPARTMENT = 'Add users to department',
  REMOVE_DEPARTMENT = 'Remove departments',
  ADD_NEW_DEPARTMENTS = 'Add new departments',
  REMOVE_USERS_FROM_DEPARTMENT = 'Remove users from department',
  VIEW_DRIVERS = 'View drivers',
  ADD_DRIVERS = 'Add drivers',
  EDIT_DRIVERS = 'Edit drivers',
  REMOVE_DRIVERS = 'Remove drivers',
  ACTIVATE_DRIVERS = 'Activate drivers',
  VIEW_DRIVER_LICENCES = "View driver's licences",
  REMOVE_DRIVER_ABSENCES = "Remove driver's absences",
  VIEW_DRIVER_ABSENCES = "View driver's absences",
  EDIT_DRIVER_ABSENCES = "Edit driver's absences",
  ADD_DRIVER_ABSENCES = "Add driver's absences",
  VIEW_DRIVER_LICENCE_DOCUMENTS = "View driver's licence documents",
  REMOVE_DRIVER_LICENCE_DOCUMENTS = "Remove driver's licence documents",
  ADD_DRIVER_LICENCE_DOCUMENTS = "Add driver's licence documents",
  ADD_DRIVER_VEHICLE_ASSOCIATIONS = 'Add driver vehicle associations',
  VIEW_DRIVER_VEHICLE_ASSOCIATIONS = 'View driver vehicle associations',
  EDIT_DRIVER_VEHICLE_ASSOCIATIONS = 'Edit driver vehicle associations',
  EDIT_DRIVER_DRIVES_VEHICLE_ASSOCIATIONS = 'Edit driver drives vehicle associations',
  VIEW_VEHICLES = 'View vehicles',
  EDIT_VEHICLE_REGISTRATIONS = 'Edit vehicle registrations',
  ADD_VEHICLE_REGISTRATIONS = 'Add vehicle registrations',
  ADD_VEHICLE_DOCUMENTS = 'Add vehicle documents',
  ADD_VEHICLES = 'Add vehicles',
  EDIT_VEHICLES = 'Edit vehicles',
  REMOVE_VEHICLES = 'Remove vehicles',
  VIEW_VEHICLE_REGISTRATIONS = 'View vehicle registrations',
  VIEW_VEHICLE_DOCUMENTS = 'View vehicle documents',
  REMOVE_VEHICLE_DOCUMENTS = 'Remove vehicle documents',
  VIEW_VEHICLE_TOLL_PROVIDERS = 'View vehicle toll providers',
  VIEW_VEHICLE_INSPECTIONS = 'View vehicle inspections',
  EDIT_SHIPMENTS = 'Edit shipments',
  ADD_SHIPMENTS = 'Add shipments',
  VIEW_SHIPMENTS = 'View shipments',
  REMOVE_SHIPMENTS = 'Remove shipments',
  REMOVE_PROBILLS = 'Remove probills',
  ADD_PROBILLS = 'Add probills',
  VIEW_PROBILLS = 'View probills',
  EDIT_PROBILLS = 'Edit probills',
  REMOVE_ROUTE_ACTION = 'Remove route action',
  ADD_ROUTE_ACTION = 'Add route action',
  EDIT_ROUTE_ACTION = 'Edit route action',
  VIEW_ROUTE_ACTION = 'View route action',
  VIEW_SHIPMENT_DOCUMENTS = 'View shipment documents',
  ADD_SHIPMENT_DOCUMENTS = 'Add shipment documents',
  REMOVE_SHIPMENT_DOCUMENTS = 'Remove shipment documents',
  VIEW_SHIPMENT_DOCUMENT_TYPES = 'View shipment document types',
  ADD_SHIPMENT_DOCUMENT_TYPES = 'Add shipment document types',
  EDIT_SHIPMENT_DOCUMENT_TYPES = 'Edit shipment document types',
  VIEW_EQUIPMENTS = 'View equipments',
  VIEW_CARRIERS = 'View carriers',
  ADD_CARRIERS = 'Add carriers',
  VIEW_USER_ABSENCES = 'View user absences',
  REMOVE_USER_ABSENCES = 'Remove user absences',
  ADD_USER_ABSENCES = 'Add user absences',
  REMOVE_ABSENCE_DOCUMENTS = 'Remove absence documents',
  VIEW_ABSENCE_DOCUMENTS = 'View absence documents',
  ADD_ABSENCE_DOCUMENTS = 'Add absence documents',
  EDIT_DRIVER_LICENCES = "Edit driver's licences",
  ADD_DRIVER_LICENCES = "Add driver's licences",
  ADD_DRIVER_COMPLIANCES = "Add driver's compliances",
  VIEW_DRIVER_COMPLIANCES = "View driver's compliances",
  EDIT_DRIVER_COMPLIANCES = "Edit driver's compliances",
  ADD_COMPLIANCY_DOCUMENTS = 'Add compliancy documents',
  VIEW_COMPLIANCY_DOCUMENTS = 'View compliancy documents',
  REMOVE_COMPLIANCY_DOCUMENTS = 'Remove compliancy documents',
  REMOVE_DRIVER_DOCUMENTS = "Remove driver's documents",
  VIEW_DRIVER_DOCUMENTS = "View driver's documents",
  ADD_DRIVER_DOCUMENTS = "Add driver's documents",
  VIEW_DRIVER_DRIVES_VEHICLE_ASSOCIATIONS = 'View driver drives vehicle associations',
  EDIT_VEHICLE_INSPECTIONS = 'Edit vehicle inspections',
  ADD_VEHICLE_INSPECTIONS = 'Add vehicle inspections',
  REMOVE_VEHICLE_INSPECTIONS = 'Remove vehicle inspections',
  ADD_VEHICLE_INSPECTION_DOCUMENTS = 'Add vehicle inspection documents',
  VIEW_VEHICLE_INSPECTION_DOCUMENTS = 'View vehicle inspection documents',
  REMOVE_VEHICLE_INSPECTION_DOCUMENTS = 'Remove vehicle inspection documents',
  REMOVE_VEHICLE_REGISTRATION_DOCUMENTS = 'Remove vehicle registration documents',
  ADD_VEHICLE_REGISTRATION_DOCUMENTS = 'Add vehicle registration documents',
  VIEW_VEHICLE_REGISTRATION_DOCUMENTS = 'View vehicle registration documents',
  VIEW_VEHICLE_MAINTENANCES = 'View vehicle maintenances',
  EDIT_VEHICLE_MAINTENANCES = 'Edit vehicle maintenances',
  ADD_VEHICLE_MAINTENANCES = 'Add vehicle maintenances',
  REMOVE_VEHICLE_MAINTENANCES = 'Remove vehicle maintenances',
  REMOVE_VEHICLE_MAINTENANCE_DOCUMENTS = 'Remove vehicle maintenance documents',
  ADD_VEHICLE_MAINTENANCE_DOCUMENTS = 'Add vehicle maintenance documents',
  VIEW_VEHICLE_MAINTENANCE_DOCUMENTS = 'View vehicle maintenance documents',
  VIEW_TRUCK_TRAILER_ASSOCIATIONS = 'View truck trailer associations',
  ADD_TRUCK_TRAILER_ASSOCIATIONS = 'Add truck trailer associations',
  REMOVE_TRUCK_TRAILER_ASSOCIATIONS = 'Remove truck trailer associations',
  REMOVE_SHIPMENT_BILLING_CHARGES = 'Remove shipment billing charges',
  ADD_SHIPMENT_BILLING_CHARGES = 'Add shipment billing charges',
  EDIT_SHIPMENT_BILLING_CHARGES = 'Edit shipment billing charges',
  VIEW_SHIPMENT_BILLING_CHARGES = 'View shipment billing charges',
  ADD_BROKERS = 'Add brokers',
  VIEW_BROKERS = 'View brokers',
  VIEW_SHIPMENT_ACTIONS = 'View shipment actions',
  ADD_SHIPMENT_ACTIONS = 'Add shipment actions',
  EDIT_SHIPMENT_ACTIONS = 'Edit shipment actions',
  REMOVE_SHIPMENT_ACTIONS = 'Remove shipment actions',
  EDIT_CARRIERS = 'Edit carriers',
  REMOVE_CARRIERS = 'Remove carriers',
  ADD_CLIENTS = 'Add clients',
  VIEW_CLIENTS = 'View clients',
  EDIT_CLIENTS = 'Edit clients',
  EDIT_LOCATIONS = 'Edit locations',
  VIEW_LOCATIONS = 'View locations',
  REMOVE_LOCATIONS = 'Remove locations',
  ADD_PROVINCES = 'Add provinces',
  ADD_LOCATIONS = 'Add locations',
  VIEW_PERMISSIONS = 'View permissions',
  EDIT_ROLES = 'Edit roles',
  ADD_ROLES = 'Add roles',
  ADD_PERMISSIONS_TO_ROLES = 'Add permissions to roles',
  VIEW_ROLES = 'View roles',
  ADD_TELEMETRICS = 'Add telemetrics',
  VIEW_VEHICLE_LOCATION = "View vehicle's location",
  REMOVE_CLIENTS = 'Remove clients',
  EDIT_ORGANIZATION = 'Edit organization ',
  VIEW_ORGANIZATION = 'View organization',
  SUPER_ADMIN_PERMISSIONS = 'Super Admin permissions',
  VIEW_ORGANIZATIONS = 'View organizations',
  ADD_ORGANIZATIONS = 'Add organizations',
  EDIT_ORGANIZATIONS = 'Edit organizations',
  REMOVE_PHOTOS_FROM_ORGANIZATION = 'Remove photos from organization',
  ADD_PHOTOS_TO_ORGANIZATION = 'Add photos to organization',
  ADD_DEFAULT_ORGANIZATION_LOCATION = 'Add default organization location',
  EDIT_TRUCK_TRAILER_ASSOCIATIONS = 'Edit truck trailer associations',
  EDIT_BILLING_CHARGES = 'Edit billing charges',
  REMOVE_BILLING_CHARGES = 'Remove billing charges',
  VIEW_BILLING_CHARGES = 'View billing  charges',
  ADD_BILLING_CHARGES = 'Add billing charges',
  VIEW_PLANNED_SHIPMENTS = 'View planned shipments',
  ADD_PLANNED_SHIPMENTS = 'Add planned shipments',
  EDIT_PLANNED_SHIPMENTS = 'Edit planned shipments',
  REMOVE_PLANNED_SHIPMENTS = 'Remove planned shipments',
  REMOVE_SHIPMENT_DOCUMENT_TYPES = 'Remove shipment document types',
  ADD_COMPENSATIONS = 'Add compensations',
  EDIT_COMPENSATIONS = 'Edit compensations',
}
