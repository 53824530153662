import { types } from 'mobx-state-tree';
import { SHIPMENT_NOTE_EMPTY_TEXT } from '../../constants';
import { addNewNote } from '../../actions/shipments';
import { rootInstance } from '../RootModel';
import PageRoutes from '../../utils/PageRoutes';

export const ShipmentNoteLightModel = types
  .model('ShipmentNoteLightModel', {
    note_text: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setNoteText(text) {
        self.note_text = text;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      validateNote() {
        if (!self.note_text) {
          this.setErrorText(SHIPMENT_NOTE_EMPTY_TEXT);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      onAddNewNote() {
        if (this.validateNote()) {
          const shipment_id =
            rootInstance.shipmentsPageState.editedShipment.shipment_id;
          const params = { shipmentId: shipment_id };

          addNewNote(shipment_id, self.note_text)
            .then((response) => {
              rootInstance.shipmentsPageState.editedShipment.setNewShipmentNote(
                null
              );
              rootInstance.router.setView(
                rootInstance.router.views.get(PageRoutes.EditShipment.name),
                params
              );
            })
            .catch((error) => {
              console.log('Error: ', error);
            });
        }
      },
    };
  });
