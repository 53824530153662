import { rootInstance } from '../state/RootModel';
import { notification } from 'antd';
import { COPIED_TO_CLIPBOARD, DEFAULT_ERROR_TEXT } from '../constants';
import { DOCUMENT_TYPE_EMPLOYEE_IMAGE } from '../constants/documents';
import { permissions } from '../constants/permissions';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(String(email).toLowerCase())) {
    return true;
  }
  return false;
};

export const validatePhoneNumber = (phoneNumber) => {
  const regPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;
  if (regPhone.test(phoneNumber)) {
    return true;
  }
  return false;
};

export const setFetchingInProgress = (status) => {
  rootInstance.loaderState.setFetchingInProgress(status);
  // disable scrolling while fetching in progress
  const body = document.querySelector('body');
  if (status) {
    body.classList.add('no-scroll');
  } else {
    body.classList.remove('no-scroll');
  }
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  notification.success({
    message: COPIED_TO_CLIPBOARD,
  });
};

export const concatenateArrayMembersNames = (arr) => {
  let concatenatedNames = '';
  arr.length &&
    arr.forEach((element, index) => {
      concatenatedNames =
        concatenatedNames +
        element.name +
        (arr.length !== index + 1 ? ', ' : '');
    });
  return concatenatedNames;
};

export const getErrorText = (error) => {
  if (error.response) {
    if (
      error.response.data &&
      error.response.data.detail &&
      error.response.data.detail.constructor === Array
    ) {
      return error.response.data.detail[0].msg;
    } else if (error.response.data && error.response.data.message) {
      return error.response.data.message;
    } else if (error.response.data && error.response.data.detail) {
      return error.response.data.detail;
    }
    return DEFAULT_ERROR_TEXT;
  } else {
    return DEFAULT_ERROR_TEXT;
  }
};

export const setCurrentUserLocalStoragePhoto = (documentation) => {
  const employeeImage = documentation.data
    .reverse()
    .find(
      (document) =>
        document.document_type.user_document_type_name ===
        DOCUMENT_TYPE_EMPLOYEE_IMAGE
    );
  if (employeeImage) {
    localStorage.setItem('user_photo', employeeImage.document_url);
  } else {
    localStorage.removeItem('user_photo');
  }
};

export const snakeCaseToString = (str: string) => {
  return str
    ?.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
    .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);
};

export const onFilePreview = (file) => {
  window.open(file.url, '_blank');
};

export const getRandomInt = () => {
  return Math.floor(Math.random() * 999999);
};

export const isCurrentUser = (id) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  return id === currentUser.user_id;
};

export const toAmount = (value) => {
  return Number(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const capitalizeFirstLetter = (string) => {
  const lowercaseString = string.toLowerCase();
  return (
    lowercaseString &&
    lowercaseString[0].toUpperCase() + lowercaseString.slice(1)
  );
};

export const replaceUnderscore = (string) => {
  return string.split('_').join(' ');
};

export const removeByAttr = function (arr, attr, value) {
  let i = arr.length;
  while (i--) {
    if (
      arr[i] &&
      arr[i].hasOwnProperty(attr) &&
      arguments.length > 2 &&
      arr[i][attr] === value
    ) {
      arr.splice(i, 1);
    }
  }
  return arr;
};

export const ordinal_suffix_of = (i) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const time_format = (time) => {
  // Hours, minutes and seconds
  let hrs = ~~(time / 3600);
  let mins = ~~((time % 3600) / 60);
  let secs = ~~time % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';
  if (hrs > 0) {
    ret += '' + hrs + 'hr ';
  }
  if (mins > 0) {
    ret += ' ' + mins + 'min';
  }
  if (secs > 0) {
    ret += ' ' + secs + 'sec';
  }

  return ret;
};
export const eta_format = (time) => {
  let ret = '';
  const time_formatting = time.split(':');
  let hrs = time_formatting[0];
  let min = time_formatting[1];
  // let secs = time_formatting[2];

  if (hrs > 0) {
    ret += '' + hrs + 'h ';
  }
  if (min > 0) {
    ret += '' + min + 'min ';
  }
  // if (secs > 0) {
  //     ret += "" + secs + "s"
  // }
  return ret;
};

export const getIsUserPermittedForRoute = (permissionsArray) => {
  const userPermissions = rootInstance.userState.permissions;
  const intersection = permissionsArray.filter((element) =>
    userPermissions.includes(element)
  );
  return Boolean(
    intersection.length ||
      userPermissions.includes(permissions.SUPER_ADMIN_PERMISSIONS)
  );
  // if (intersection.length || userPermissions.includes(permissions.SUPER_ADMIN_PERMISSIONS)) {
  //     return true;
  // }
  // return false;
  // permissions.forEach(element => {
  //
  // });
  // return Boolean(userPermissions.find(element => element === permissionsArray || element === permissions.SUPER_ADMIN_PERMISSIONS));
};

export const exportToPdf = (header, data, title, docName) => {
  const today = new Date();
  const date =
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1) +
    '-' +
    today.getDate() +
    ' ' +
    today.getHours() +
    ':' +
    today.getMinutes() +
    ':' +
    today.getSeconds();
  const date_formatted = date.split(/[- :]/).join('_');

  const unit = 'pt';
  const size = 'A4'; // Use A1, A2, A3 or A4
  const orientation = 'landscape'; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(15);

  const pdfTitle = `${title} ${date}`;

  let content = {
    startY: 50,
    head: header,
    body: data,
  };

  doc.text(pdfTitle, marginLeft, 40);
  doc.autoTable(content);
  doc.save(`${docName}${date_formatted}.pdf`);
};

export const downloadCSVFile = (csv, filename) => {
  let csv_file, download_link;

  csv_file = new Blob([csv], { type: 'text/csv' });

  download_link = document.createElement('a');

  download_link.download = filename;

  download_link.href = window.URL.createObjectURL(csv_file);

  download_link.style.display = 'none';

  document.body.appendChild(download_link);

  download_link.click();
};

export const htmlToCSV = (html, tableClassName, filename) => {
  const today = new Date();
  const date =
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1) +
    '-' +
    today.getDate() +
    ' ' +
    today.getHours() +
    ':' +
    today.getMinutes() +
    ':' +
    today.getSeconds();
  const date_formatted = date.split(/[- :]/).join('_');

  let data = [];
  let rows = document.querySelectorAll(`.${tableClassName} tr`);

  for (let i = 0; i < rows.length; i++) {
    const row = [];
    const cols: any = rows[i].querySelectorAll(`.${tableClassName} td, th`);

    const getColumnInnerText = (col) => {
      if (col.getElementsByClassName('title-text')[0]) {
        return col.getElementsByClassName('title-text')[0].innerText;
      }
      return col.innerText;
    };

    for (let j = 0; j < cols.length; j++) {
      row.push(getColumnInnerText(cols[j]));
    }

    data.push(row.join(','));
  }
  //
  downloadCSVFile(data.join('\n'), `${filename}${date_formatted}.csv`);
};

export const isNil = (value) => typeof value === 'undefined' || value === null;

export const removeDuplicatesFromArray = (arr = [], key) => [
  ...new Map(arr.map((item) => [item[key], item])).values(),
];
