import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Modal, Form, Input } from 'antd';
import {
  CHANGE_PASSWORD,
  PASSWORD_INSTRUCTIONS_TEXT,
  CURRENT_PASSWORD,
  NEW_PASSWORD,
  RETYPE_PASSWORD,
} from '../../constants';
import lock from '../../assets/images/lock.svg';
import close from '../../assets/images/close-sign.svg';
import hidePassword from '../../assets/images/hide-password.svg';
import showPassword from '../../assets/images/show-password.svg';
import errorSign from '../../assets/images/error-sign.svg';

const ChangePasswordModal = () => {
  const {
    loginForm: { currentUser },
  } = useMst();

  const onSubmit = (e) => {
    e.preventDefault();
    currentUser.changeCurrentUserPassword();
  };

  return (
    <div>
      <Modal
        visible={currentUser.changePasswordModalOpen}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={400}
        className="change-password-modal"
      >
        <div className="p32 change-password-modal-content">
          <div className="justify-between">
            <div className="align-center">
              <img src={lock} alt="lock" />
              <h3 className="ml10 text-medium">{CHANGE_PASSWORD}</h3>
            </div>
            <img
              src={close}
              alt="close"
              height="20px"
              className="pointer"
              onClick={() => currentUser.setChangePasswordModalOpen(false)}
            />
          </div>
          <div className="mt30">
            <Form onSubmit={onSubmit}>
              <p>{CURRENT_PASSWORD}</p>
              <Form.Item>
                <Input
                  onChange={(e) =>
                    currentUser.setCurrentPassword(e.target.value)
                  }
                  type={
                    currentUser.current_password_visible ? 'text' : 'password'
                  }
                  value={currentUser.current_password}
                  className="change-password-input mb20"
                />
                {currentUser.current_password_visible ? (
                  <img
                    className="show-password-sign"
                    src={hidePassword}
                    alt="eye"
                    height="18"
                    onClick={() => currentUser.setCurrentPasswordVisible(false)}
                  />
                ) : (
                  <img
                    className="show-password-sign"
                    src={showPassword}
                    alt="eye"
                    height="18"
                    onClick={() => currentUser.setCurrentPasswordVisible(true)}
                  />
                )}
              </Form.Item>
              <p>{NEW_PASSWORD}</p>
              <Form.Item>
                <Input
                  onChange={(e) => currentUser.setNewPassword(e.target.value)}
                  type={currentUser.new_password_visible ? 'text' : 'password'}
                  value={currentUser.new_password}
                  className="change-password-input mb20"
                />
                {currentUser.new_password_visible ? (
                  <img
                    className="show-password-sign"
                    src={hidePassword}
                    alt="eye"
                    height="18"
                    onClick={() => currentUser.setNewPasswordVisible(false)}
                  />
                ) : (
                  <img
                    className="show-password-sign"
                    src={showPassword}
                    alt="eye"
                    height="18"
                    onClick={() => currentUser.setNewPasswordVisible(true)}
                  />
                )}
              </Form.Item>
              <p>{RETYPE_PASSWORD}</p>
              <Form.Item>
                <Input
                  onChange={(e) =>
                    currentUser.setConfirmedPassword(e.target.value)
                  }
                  type={
                    currentUser.confirmed_password_visible ? 'text' : 'password'
                  }
                  value={currentUser.confirmed_password}
                  className="change-password-input mb20"
                />
                {currentUser.confirmed_password_visible ? (
                  <img
                    className="show-password-sign"
                    src={hidePassword}
                    alt="eye"
                    height="18"
                    onClick={() =>
                      currentUser.setConfirmedPasswordVisible(false)
                    }
                  />
                ) : (
                  <img
                    className="show-password-sign"
                    src={showPassword}
                    alt="eye"
                    height="18"
                    onClick={() =>
                      currentUser.setConfirmedPasswordVisible(true)
                    }
                  />
                )}
              </Form.Item>
              <p className="text-italic mb20">{PASSWORD_INSTRUCTIONS_TEXT}</p>
              <div className="text-center">
                {currentUser.change_password_error_text && (
                  <div className="align-center">
                    <img src={errorSign} alt="error sign" height="24" />
                    <p className="error-text ml5">
                      {currentUser.change_password_error_text}
                    </p>
                  </div>
                )}

                <button
                  className="btn primary center-content mt10"
                  type="submit"
                >
                  {CHANGE_PASSWORD}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ChangePasswordModal);
