import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';
import { notification } from 'antd';
import addDriverIcon from '../../../assets/images/add-driver.svg';
import FormPageTitle from '../../FormPageTitle';
import GeneralInformation from './GeneralInformation';
import DocumentationSection from './DocumentationSection';
import ErrorBox from '../../ErrorBox';
import FormButtonsSection from '../../FormButtonsSection';
import {
  ADD_DRIVER,
  DISCARD_CHANGES,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
} from '../../../constants';
import {
  DOCUMENT_TYPE_ADDITIONAL,
  DOCUMENT_TYPE_REFERENCE,
  DOCUMENT_TYPE_RESUME,
} from '../../../constants/documents';
import PageRoutes from '../../../utils/PageRoutes';

const AddDriverComponent = () => {
  const {
    fleetPageState: { newDriver, addDriver },
  } = useMst();

  const [cv, setCv] = useState([]);
  const handleCVUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_RESUME;
      if (file.status !== 'uploading') {
        setCv(listToUpload);
      }
    } else {
      setCv(fileList);
    }
  };

  const [reccomendationLetter, setReccomendationLetter] = useState([]);
  const handleReccomendationLetterUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_REFERENCE;
      if (file.status !== 'uploading') {
        setReccomendationLetter(listToUpload);
      }
    } else {
      setReccomendationLetter(fileList);
    }
  };

  const [additionalFiles, setAdditionalFiles] = useState([]);
  const handleAdditionalFilesUpload = ({ file, fileList }) => {
    const isUploadAllowed = () => {
      return fileList.length < 6 || fileList.length < additionalFiles.length;
    };
    if (file.status !== 'uploading') {
      if (isUploadAllowed()) {
        fileList.forEach((file) => {
          !('originFileObj' in file) && (file.originFileObj = {});
          file.originFileObj.documentType = DOCUMENT_TYPE_ADDITIONAL;
        });
        setAdditionalFiles(fileList);
      } else {
        notification.error({
          message: UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
          description: UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
        });
      }
    }
  };

  const getDocumentsForUpload = () => {
    return [...cv, ...reccomendationLetter, ...additionalFiles];
  };

  const addNewDriver = () => {
    const documentsToUpload = getDocumentsForUpload();
    addDriver(documentsToUpload);
  };

  const discardChanges = () => {
    newDriver.clearForm();
    setAdditionalFiles([]);
    setCv([]);
    setReccomendationLetter([]);
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.Fleet.name)
    );
  };

  return (
    <div className="add-edit-form-component">
      <FormPageTitle image={addDriverIcon} title={ADD_DRIVER} />

      <GeneralInformation />

      <DocumentationSection
        uploadCV={handleCVUpload}
        uploadReccomendationLetter={handleReccomendationLetterUpload}
        uploadAdditionalFiles={handleAdditionalFilesUpload}
        cv={cv}
        reccomendationLetter={reccomendationLetter}
        additionalFiles={additionalFiles}
      />

      <ErrorBox obj={newDriver} />

      <FormButtonsSection
        onConfirm={addNewDriver}
        onDiscard={discardChanges}
        confirmTitle={ADD_DRIVER}
        discardTitle={DISCARD_CHANGES}
      />
    </div>
  );
};

export default observer(AddDriverComponent);
