import React from 'react';
import { observer } from 'mobx-react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import { pickupIcon, trailerIcon, truckIcon } from './Icon';
import {
  MODAL_SUCCESS_TITLE,
  MODAL_SUCCESS_EDIT_SHIPMENT_TEXT,
  CLOSE,
  BLUE_TRAILER,
  BLUE_TRUCK,
  TRAILER,
} from '../../constants';
import L from 'leaflet';
import { useMst } from '../../state/RootModel';
import { DELIVERY_TYPE, PICKUP_TYPE } from '../../constants/dispatch';
import {
  VEHICLE_TYPE_TRAILER,
  VEHICLE_TYPE_TRUCK,
} from '../../constants/vehicleTypes';
import DispatchMapMarker from './common/DispatchMapMarker';
import DispatchMapVehicleMarker from './common/DispatchMapVehicleMarker';
import DispatchRoutingMachine from './routing-machine/DispatchRoutingMachine';
import DispatchSuccessModal from './common/DispatchSuccessModal';
import EditShipmentPD from './shipment_list/EditShipmentPD';
import TrackingShipmentMapDetails from './common/TrackingShipmentMapDetails';

const mapStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
};

const DispatchMap = () => {
  const routerRef = React.useRef();
  const [routerReady, setRouterReady] = React.useState(false);

  const {
    dispatchPageState: {
      shipmentPickupLocations,
      shipmentDeliveryLocations,
      truckToShowOnMap,
      trailerToShowOnMap,
      isOrganizeTourSuccessModalOpen,
      setIsOrganizeTourSuccessModalOpen,
      routeActionToDraw,
      selectedShipmentToDraw,
      zoom,
      getEditingRouteAction,
      truckEditingToShowOnMap,
      trailerEditingToShowOnMap,
      shipmentToChangePD,
      selectedDriverForMap,
      isShipmentPDSuccessModalOpened,
      setIsShipmentPDSuccessModalOpened,
      trackingShipment,
    },
  } = useMst();

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };

  const yardLocation = [43.59628530932063, -79.59428137289142];

  return (
    // @ts-ignore
    <div className="dispatch-map-container" style={mapStyle}>
      <MapContainer
        style={{ width: '100%', height: '100%', position: 'relative', top: 0 }}
        center={yardLocation}
        zoom={5}
        className="view-fleet-map"
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {trackingShipment.length > 0 &&
          trackingShipment[0]?.locations?.length > 0 && (
            <TrackingShipmentMapDetails data={trackingShipment} />
          )}
        {selectedShipmentToDraw &&
          selectedShipmentToDraw.length > 0 &&
          selectedShipmentToDraw[0]?.trailer?.trailer_latitude &&
          selectedShipmentToDraw[0]?.trailer?.trailer_longitude && (
            <Marker
              position={[
                selectedShipmentToDraw[0]?.trailer?.trailer_latitude,
                selectedShipmentToDraw[0]?.trailer?.trailer_longitude,
              ]}
              // @ts-ignore
              icon={trailerIcon}
              className="dispatch-map-marker"
            >
              <Popup>
                <p className="dispatch-map-marker__type text-bold text-black">
                  {`${TRAILER}: `}
                </p>
                <span className="text-black">
                  {selectedShipmentToDraw[0]?.trailer?.trailer_company_id + ' '}
                  {'/ ' + selectedShipmentToDraw[0]?.trailer?.trailer_type}
                </span>
              </Popup>
            </Marker>
          )}
        {routeActionToDraw?.length > 0 && (
          <div>
            <Marker
              position={[
                routeActionToDraw[0]?.Steps[0].StartPosition[1],
                routeActionToDraw[0]?.Steps[0].StartPosition[0],
              ]}
              // @ts-ignore
              icon={
                routeActionToDraw[0].action.action.action_name === 'Hook'
                  ? truckIcon
                  : pickupIcon
              }
              className="dispatch-map-marker"
            >
              <Popup>
                <p className="dispatch-map-marker__type text-bold text-black">
                  {routeActionToDraw[0].action.trailer.trailer_company_id + ' '}
                  {'/ ' + routeActionToDraw[0].action.trailer.trailer_type}
                </p>
              </Popup>
            </Marker>
            <Marker
              position={[
                routeActionToDraw[0]?.Steps[
                  routeActionToDraw[0]?.Steps.length - 1
                ].StartPosition[1],
                routeActionToDraw[0]?.Steps[
                  routeActionToDraw[0]?.Steps.length - 1
                ].StartPosition[0],
              ]}
              // @ts-ignore
              icon={
                routeActionToDraw[0].action.action.action_name === 'Hook'
                  ? trailerIcon
                  : pickupIcon
              }
              className="dispatch-map-marker"
            >
              <Popup>
                <p className="dispatch-map-marker__type text-bold text-black">
                  {routeActionToDraw[0].action.truck.truck_company_id + ' '}
                  {'/ ' + routeActionToDraw[0].action.truck.truck_model}
                </p>
              </Popup>
            </Marker>
            <DispatchRoutingMachine
              ref={routerRef}
              icon={{ trailerIcon, truckIcon }}
              waypoints={routeActionToDraw[0]?.Steps.map((step) =>
                L.latLng([step.StartPosition[1], step.StartPosition[0]])
              )}
              lineOptions={{
                styles: [{ color: routeActionToDraw[0].color, weight: 6 }],
              }}
              onRoutesFound={() => {
                if (!routerReady) {
                  setRouterReady(true);
                }
              }}
            />
          </div>
        )}

        {shipmentPickupLocations && shipmentDeliveryLocations && (
          <div>
            {shipmentPickupLocations?.map((location, index) => {
              return (
                <div key={index}>
                  <ChangeView
                    center={[
                      location.location_latitude,
                      location.location_longitude,
                    ]}
                    zoom={zoom ? zoom : 5}
                  />
                  <DispatchMapMarker
                    key={index}
                    type={PICKUP_TYPE}
                    data={location}
                  />
                </div>
              );
            })}
            {shipmentDeliveryLocations?.map((location, index) => {
              return (
                <div key={index}>
                  <DispatchMapMarker
                    key={index}
                    type={DELIVERY_TYPE}
                    data={location}
                  />
                </div>
              );
            })}
          </div>
        )}
        {selectedDriverForMap && selectedDriverForMap.length > 0 && (
          <div>
            <ChangeView
              center={[
                selectedDriverForMap[0]?.driver_latitude,
                selectedDriverForMap[0]?.driver_longitude,
              ]}
              zoom={5}
            />
            <Marker
              position={[
                selectedDriverForMap[0]?.driver_latitude,
                selectedDriverForMap[0]?.driver_longitude,
              ]}
              // @ts-ignore
              className="dispatch-map-marker"
            >
              <Popup>
                <p className="dispatch-map-marker__type text-bold text-black">
                  {selectedDriverForMap[0].driver_id}
                  {' / '}
                  {selectedDriverForMap[0].driver_code_name}
                </p>
              </Popup>
            </Marker>
          </div>
        )}
        {getEditingRouteAction && (
          <div>
            {truckEditingToShowOnMap && truckEditingToShowOnMap.length > 0 && (
              <div>
                <ChangeView
                  center={[
                    truckEditingToShowOnMap[0]?.vehicle_latitude,
                    truckEditingToShowOnMap[0]?.vehicle_longitude,
                  ]}
                  zoom={5}
                />
                <DispatchMapVehicleMarker
                  key={truckEditingToShowOnMap[0]?.vehicle_id}
                  type={BLUE_TRUCK}
                  data={truckEditingToShowOnMap}
                />
              </div>
            )}
            {/*Show trailer on Map*/}
            {trailerEditingToShowOnMap &&
              trailerEditingToShowOnMap[0]?.trailer_current_action
                ?.location_latitude &&
              trailerEditingToShowOnMap[0]?.trailer_current_action
                ?.location_longitude && (
                <div>
                  <ChangeView
                    center={[
                      trailerEditingToShowOnMap[0]?.trailer_current_action
                        ?.location_latitude,
                      trailerEditingToShowOnMap[0]?.trailer_current_action
                        ?.location_longitude,
                    ]}
                    zoom={5}
                  />
                  <DispatchMapVehicleMarker
                    key={trailerEditingToShowOnMap.vehicle_id}
                    type={BLUE_TRAILER}
                    data={trailerEditingToShowOnMap[0]}
                  />
                </div>
              )}
          </div>
          // trailerEditingToShowOnMap
        )}
        {!getEditingRouteAction && (
          <div>
            {/*Show truck on Map*/}
            {truckToShowOnMap &&
              truckToShowOnMap.length > 0 &&
              truckToShowOnMap[0]?.vehicle_latitude &&
              truckToShowOnMap[0]?.vehicle_longitude && (
                <div>
                  <ChangeView
                    center={[
                      truckToShowOnMap[0]?.vehicle_latitude,
                      truckToShowOnMap[0]?.vehicle_longitude,
                    ]}
                    zoom={5}
                  />
                  <DispatchMapVehicleMarker
                    key={truckToShowOnMap.vehicle_id}
                    type={VEHICLE_TYPE_TRUCK}
                    data={truckToShowOnMap}
                  />
                </div>
              )}

            {/*Show trailer on Map*/}
            {trailerToShowOnMap &&
              trailerToShowOnMap[0]?.trailer_current_action
                ?.location_latitude &&
              trailerToShowOnMap[0]?.trailer_current_action
                ?.location_longitude && (
                <div>
                  <ChangeView
                    center={[
                      trailerToShowOnMap[0]?.trailer_current_action
                        ?.location_latitude,
                      trailerToShowOnMap[0]?.trailer_current_action
                        ?.location_longitude,
                    ]}
                    zoom={5}
                  />
                  <DispatchMapVehicleMarker
                    key={trailerToShowOnMap.vehicle_id}
                    type={VEHICLE_TYPE_TRAILER}
                    data={trailerToShowOnMap[0]}
                  />
                </div>
              )}
          </div>
        )}
      </MapContainer>
      {!!shipmentToChangePD && <EditShipmentPD />}
      <DispatchSuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_SHIPMENT_TEXT}
        buttonText={CLOSE}
        open={isOrganizeTourSuccessModalOpen}
        closeModal={() => setIsOrganizeTourSuccessModalOpen(false)}
      />
      <DispatchSuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_SHIPMENT_TEXT}
        buttonText={CLOSE}
        open={isShipmentPDSuccessModalOpened}
        closeModal={() => setIsShipmentPDSuccessModalOpened(false)}
      />
    </div>
  );
};

export default observer(DispatchMap);
