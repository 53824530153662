import React from 'react';
import { observer } from 'mobx-react';
import { INSPECTION_HISTORY } from '../../../../constants';
import dropdownIcon from '../../../../assets/images/dropdown-icon.svg';
import ViewInspectionsTable from './ViewInspectionsTable';

const ViewInspectionsSection = ({ model }) => {
  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            model.setIsInspectionsSectionOpen(!model.isInspectionsSectionOpen);
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              model.isInspectionsSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {INSPECTION_HISTORY}
          </span>
        </div>
      </div>
      {model?.isInspectionsSectionOpen && (
        <ViewInspectionsTable model={model} />
      )}
    </div>
  );
};

export default observer(ViewInspectionsSection);
