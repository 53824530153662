import React from 'react';
import { observer } from 'mobx-react';

const SupportPage = () => {
  return (
    <div className="page">
      <div className="page-content">
        <h1 className="text-center page-title">Support Page</h1>
      </div>
    </div>
  );
};

export default observer(SupportPage);
