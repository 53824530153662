import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty, Pagination, notification } from 'antd';
import { useMst } from '../../state/RootModel';
import PageRoutes from '../../utils/PageRoutes';
import Link from '../../components/Link';
import {
  EMPTY_TRAILERS_TABLE_TEXT,
  TRAILERS,
  SEARCH_TRAILERS,
  PRINT_PDF,
  FETCH_TRUCKS_ERROR,
  FETCH_TRAILERS_ERROR,
  EXPORT_TO_CSV,
  ADD_TRAILER,
} from '../../constants';
import { DebounceInput } from 'react-debounce-input';
import search from '../../assets/images/search.svg';
import { getTrucksForExport } from '../../actions/fleet';
import { exportToPdf, getErrorText, htmlToCSV } from '../../utils/methods';
import {
  TRAILERS_TABLE_DOC_NAME,
  TRAILERS_TABLE_TITLE,
} from '../../constants/tableData';

const TrailersTable = () => {
  const TABLE_CLASS_NAME = 'trailers-table';
  const {
    fleetPageState: {
      getTrailersColumns,
      filteredTrailersArray,
      trailersCurrentPage,
      trailersPageSize,
      trailersSearchTerm,
      trailersTotalItems,
      setTrailersCurrentPage,
      setTrailersSearchTerm,
      getTrailers,
      setTrailers,
      setTrailersTotalItems,
    },
    userState: { canAddVehicles },
    router,
  } = useMst();

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setTrailersSearchTerm(searchText);
    setTrailersCurrentPage(1);
    getTrailers({});
  };

  const onRowClick = (e, record) => {
    e.stopPropagation();
    const params = { trailerId: record.vehicle_id };
    router.setView(router.views.get(PageRoutes.ViewTrailer.name), params);
  };

  const paginationChange = (val) => {
    setTrailersCurrentPage(val);
    getTrailers({ page: val });
  };

  const handleCsvExport = async () => {
    try {
      const trailers = await getTrucksForExport(trailersSearchTerm);
      const table = document.getElementsByClassName('ant-table-fixed')[0];
      setTrailers(trailers.data.vehicles);
      setTrailersTotalItems(trailers.data.total);
      htmlToCSV(table, TABLE_CLASS_NAME, TRAILERS_TABLE_DOC_NAME);
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_TRAILERS_ERROR,
        description: errorText,
      });
    } finally {
      getTrailers({ page: 1 });
    }
  };

  const handlePDFExport = async () => {
    try {
      const trailers = await getTrucksForExport(trailersSearchTerm);

      const headersArr = [[]];
      getTrailersColumns().map((head) => {
        headersArr[0].push(head.title);
      });
      headersArr[0].pop();

      const data = trailers.data.vehicles.map((elt) => {
        return [
          elt.internal_company_id,
          elt.ownership_type.charAt(0).toUpperCase() +
            elt.ownership_type.slice(1),
          elt.vehicle_model_name ? elt.vehicle_model_name : '/',
          elt.vehicle_make ? elt.vehicle_make : '/',
          elt.linked_truck ? elt.linked_truck : '/',
          elt.vin,
          elt.status === 'active' ? 'Available' : 'Out of service',
        ];
      });

      exportToPdf(
        headersArr,
        data,
        TRAILERS_TABLE_TITLE,
        TRAILERS_TABLE_DOC_NAME
      );
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_TRUCKS_ERROR,
        description: errorText,
      });
    } finally {
      getTrailers({ page: 1 });
    }
  };

  return (
    <div>
      <div className="table-header mb20 mt20 align-center">
        <span className="text-capitalize color-primary text-medium ml10">
          {TRAILERS}
        </span>
        <div className="search-wrapper border-bottom-search create-shipment-search align-center ml30">
          <img src={search} alt="search" className="search-icon" />
          <DebounceInput
            debounceTimeout={300}
            onChange={(e) => handleSearch(e)}
            style={{ width: 250 }}
            placeholder={SEARCH_TRAILERS}
            value={trailersSearchTerm}
          />
        </div>
        {canAddVehicles && (
          <Link
            className="btn light add ml30"
            view={PageRoutes.AddTrailer.name}
          >
            {ADD_TRAILER}
          </Link>
        )}
        <button
          type="button"
          className="btn light add ml30"
          onClick={handlePDFExport}
        >
          {PRINT_PDF}
        </button>
        <button
          type="button"
          className="btn white add ml30"
          onClick={handleCsvExport}
        >
          {EXPORT_TO_CSV}
        </button>
      </div>
      <Table
        className={`${TABLE_CLASS_NAME} fleet-table`}
        rowKey={'vehicle_id'}
        columns={getTrailersColumns()}
        dataSource={filteredTrailersArray}
        pagination={false}
        onRow={(r) => ({
          onClick: (e) => onRowClick(e, r),
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_TRAILERS_TABLE_TEXT}
            />
          ),
        }}
      />
      <div className="table-footer">
        <Pagination
          onChange={paginationChange}
          total={trailersTotalItems}
          current={trailersCurrentPage}
          pageSize={trailersPageSize}
        />
      </div>
    </div>
  );
};

export default observer(TrailersTable);
