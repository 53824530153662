import React from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';
import {
  DRIVER_INFORMATION,
  PHONE_NUMBER,
  EMAIL,
  DATE_OF_BIRTH,
  DATE_HIRED,
  NOT_AVAILABLE,
  CLASS,
  FULLNAME,
  COMPANY,
  CONNECTED_TRUCK,
  CATEGORY,
  HOME_ADDRESS,
  CODE_NAME,
  WORKING_STATUS,
  CONNECTED_TRAILER,
  NOTES,
} from '../../../constants';
import { snakeCaseToString } from '../../../utils/methods';
import PageRoutes from '../../../utils/PageRoutes';
import moment from 'moment';
import { ID } from '../../../constants/dispatch';

const DriverInformation = () => {
  const {
    fleetPageState: { viewedDriver },
  } = useMst();

  const viewTruckInformation = (e, driver) => {
    const params = { truckId: driver.connected_truck_data.vehicle_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.ViewTruck.name),
      params
    );
  };
  const viewTrailerInformation = (e, driver) => {
    const params = { trailerId: driver.connected_trailer_data.vehicle_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.ViewTrailer.name),
      params
    );
  };
  return (
    <div className="edit-driver-section form-page-section w50 pr10">
      <div className="form-page-subsection">
        <div className="subsection-header mt0">
          <p>{DRIVER_INFORMATION}</p>
        </div>
        <div className="section-content align-center pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{ID}</p>
            <p className="content">
              {viewedDriver.internal_company_id
                ? viewedDriver.internal_company_id
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{CODE_NAME}</p>
            <p className="content">
              {viewedDriver.code_name ? viewedDriver.code_name : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{WORKING_STATUS}</p>
            <div className="align-center">
              <div
                className={`status-dot mr10 ${
                  viewedDriver.isDriverOff ? 'unavailable' : ''
                }`}
              />
              <span className="text-capitalize">
                {viewedDriver.driverWorkingStatus}
              </span>
            </div>
          </div>
        </div>
        <div className="section-content align-center pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{FULLNAME}</p>
            <p className="content">
              {viewedDriver.fullName ? viewedDriver.fullName : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{PHONE_NUMBER}</p>
            <p className="content">
              {viewedDriver.phone_number
                ? viewedDriver.phone_number
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{CLASS}</p>
            <p className="content">
              {viewedDriver.driver_type
                ? snakeCaseToString(viewedDriver.driver_type)
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content align-center pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{COMPANY}</p>
            <p className="content">
              {viewedDriver.company ? viewedDriver.company : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{DATE_OF_BIRTH}</p>
            <p className="content">
              {viewedDriver.date_of_birth
                ? moment(viewedDriver.date_of_birth).format('DD.MM.YYYY')
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{EMAIL}</p>
            <p className="content">
              {viewedDriver.email ? viewedDriver.email : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content align-center pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{HOME_ADDRESS}</p>
            <p className="content">
              {viewedDriver.street_address
                ? viewedDriver.street_address
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{CATEGORY}</p>
            <p className="content text-capitalize">
              {viewedDriver.driver_category
                ? viewedDriver.driver_category
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{DATE_HIRED}</p>
            <p className="content">
              {viewedDriver.date_hired
                ? moment(viewedDriver.date_hired).format('DD.MM.YYYY')
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content align-center pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{CONNECTED_TRUCK}</p>
            {viewedDriver.connectedTruckData !== NOT_AVAILABLE ? (
              <p
                className="content go-to"
                onClick={(e) => viewTruckInformation(e, viewedDriver)}
              >
                {viewedDriver.connectedTruckData}
              </p>
            ) : (
              <p className="content">{viewedDriver.connectedTruckData}</p>
            )}
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{CONNECTED_TRAILER}</p>
            {viewedDriver.connectedTrailerData !== NOT_AVAILABLE ? (
              <p
                className="content go-to"
                onClick={(e) => viewTrailerInformation(e, viewedDriver)}
              >
                {viewedDriver.connectedTrailerData}
              </p>
            ) : (
              <p className="content">{viewedDriver.connectedTrailerData}</p>
            )}
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{NOTES}</p>
            <p className="content">
              {viewedDriver.notes ? viewedDriver.notes : NOT_AVAILABLE}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DriverInformation);
