import { types, getRoot } from 'mobx-state-tree';
import { LicenceModel } from './LicenceModel';
import { licencesColumnsFunc } from './LicencesTableColumns';
import { AssociationModel } from './AssociationModel';
import { associationsColumnsFunc } from './AssociationsTableColumns';
import { DocumentModel } from '../../DocumentModel';
import { documentsColumnsFunc } from '../../DocumentationTableColumns';
import { CompliancyModel } from './CompliancyModel';
import { compliancesColumnsFunc } from './CompliancesTableColumns';
import { AbsenceModel } from '../../employees/AbsenceModel';
import moment from 'moment';
import { rootInstance } from '../../RootModel';
import { STATUS_AVAILABLE } from '../../../constants/workingStatuses';
import { NOT_AVAILABLE } from '../../../constants';
import { CurrentLocationModel } from '../trucks/CurrentLocationModel';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';
import { compensationsTableColumns } from './CompensationsTableColumns';
import { valuesOrDeductionsColumnsFunc } from './ValuesOrDeductionsColumns';
import { DriversCompensationsModel } from './DriversCompensationModel';

export const DriverViewModel = types
  .model('DriverViewModel', {
    driver_id: types.identifierNumber,
    first_name: types.maybe(types.maybeNull(types.string)),
    last_name: types.maybe(types.maybeNull(types.string)),
    code_name: types.maybe(types.maybeNull(types.string)),
    current_absence_id: types.maybe(types.maybeNull(types.number)),
    currentAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),
    driver_type: types.maybe(types.maybeNull(types.string)),
    date_hired: types.maybe(types.maybeNull(types.string)),
    driver_category: types.maybe(types.maybeNull(types.string)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),
    organization_id: types.maybe(types.maybeNull(types.number)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    date_of_birth: types.maybe(types.maybeNull(types.string)),
    is_regular_working_status: types.maybe(types.maybeNull(types.boolean)),
    street_address: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    company: types.maybe(types.maybeNull(types.string)),
    owner: types.maybe(types.maybeNull(types.string)),
    documents: types.map(DocumentModel),

    licences: types.map(LicenceModel),
    associations: types.map(AssociationModel),
    compliances: types.map(CompliancyModel),
    absences: types.map(AbsenceModel),
    compensations: types.map(DriversCompensationsModel),
    viewedAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),

    isGeneralInfoSectionOpen: true,
    isWorkingStatusSectionOpen: true,
    isLicenceInfoSectionOpen: true,
    isDocumentationSectionOpen: true,
    isAssociationsSectionOpen: true,
    isCompliancesSectionOpen: true,
    isPayrollSectionOpen: true,

    driverFullyFetched: false,
    location: types.maybe(types.maybeNull(CurrentLocationModel)),
    internal_company_id: types.maybe(types.maybeNull(types.string)),
    connected_truck_data: types.frozen([]),
    connected_trailer_data: types.frozen([]),
    hos_data: types.frozen([]),
    notes: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {
      position: [51.505, -0.09],
    };
  })
  .views((self) => {
    return {
      get fullName() {
        return `${self.first_name} ${self.last_name}`;
      },
      get isOwnerOperator() {
        return self.driver_type === OWNER_OPERATOR;
      },
      get isCompanyDriver() {
        return self.driver_type === COMPANY_DRIVER;
      },
      get licencesArray() {
        return Array.from(self.licences.values());
      },
      get compensationsArray() {
        return Array.from(self.compensations.values());
      },
      get documentsArray() {
        return Array.from(self.documents.values());
      },
      get associationsArray() {
        return Array.from(self.associations.values());
      },
      get complianciesArray() {
        return Array.from(self.compliances.values());
      },
      get absencesArray() {
        return Array.from(self.absences.values());
      },
      get isDriverOff() {
        return !!this.current_absence_id;
      },
      get driverWorkingStatus() {
        const unavailableStatus =
          rootInstance.fleetPageState.availableAbsencesTypes.find(
            (status) => status === self.currentAbsence?.status
          );
        if (unavailableStatus) {
          return unavailableStatus;
        } else {
          return STATUS_AVAILABLE;
        }
      },
      get lastAssociation() {
        if (this.associationsArray.length) {
          return `${this.associationsArray[0].vehicle_make} / ${this.associationsArray[0].vehicle_model_name}`;
        }
        return NOT_AVAILABLE;
      },
      get connectedTrailerData() {
        if (this.connected_trailer_data) {
          return `${this.connected_trailer_data.internal_company_id} - ${this.connected_trailer_data.vehicle_make}`;
        }
        return NOT_AVAILABLE;
      },
      get connectedTruckData() {
        if (this.connected_truck_data) {
          return `${this.connected_truck_data.internal_company_id} - ${this.connected_truck_data.vehicle_make}`;
        }
        return NOT_AVAILABLE;
      },
    };
  })
  .actions((self) => {
    return {
      setNotes(notes) {
        self.notes = notes;
      },
      setDocuments(items) {
        self.documents.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.documents.put({
            ...item,
          });
        });
      },
      setDriverFullyFetched(status) {
        self.driverFullyFetched = status;
      },
      setCompensations(items) {
        self.compensations.clear();
        items.forEach((item) => {
          self.compensations.put({
            ...item,
          });
        });
      },
      setIsGeneralInfoSectionOpen(status) {
        self.isGeneralInfoSectionOpen = status;
      },
      setIsPayrollSectionOpen(status) {
        self.isPayrollSectionOpen = status;
      },
      setIsWorkingStatusSectionOpen(status) {
        self.isWorkingStatusSectionOpen = status;
      },
      setIsLicenceInfoSectionOpen(status) {
        self.isLicenceInfoSectionOpen = status;
      },
      setIsDocumentationSectionOpen(status) {
        self.isDocumentationSectionOpen = status;
      },
      setIsAssociationsSectionOpen(status) {
        self.isAssociationsSectionOpen = status;
      },
      setIsCompliancesSectionOpen(status) {
        self.isCompliancesSectionOpen = status;
      },
      getLicencesColumns() {
        return Object.values(licencesColumnsFunc(getRoot(self)));
      },
      getCompensationValuesOrDeductionsTableColumns(type) {
        return Object.values(
          valuesOrDeductionsColumnsFunc(getRoot(self), type)
        );
      },
      getCompensationsColumns() {
        return Object.values(compensationsTableColumns(getRoot(self)));
      },
      getDocumentsColumns() {
        return Object.values(documentsColumnsFunc(getRoot(self)));
      },
      getAssociationsColumns() {
        return Object.values(associationsColumnsFunc(getRoot(self)));
      },
      getCompliancesColumns() {
        return Object.values(compliancesColumnsFunc(getRoot(self)));
      },
      setDriversLicences(items) {
        items.forEach((item) => {
          self.licences.put({
            ...item,
          });
        });
      },
      setLocation(location) {
        self.location = location;
      },
      setAssociations(items) {
        items.forEach((item, index) => {
          // ******** REMOVE THIS LINE *********** //
          item.association_id = index;
          self.associations.put({
            ...item,
          });
        });
      },
      setCompliances(items) {
        items.forEach((item) => {
          self.compliances.put({
            ...item,
          });
        });
      },
      setAbsences(items) {
        const endOfDay = '23:59:59';
        items.forEach((item) => {
          item.start = moment(item.starting_date).toDate();
          item.end = moment(item.end_date + ' ' + endOfDay).toDate();
          item.title = item.status.toUpperCase();
          self.absences.put({
            ...item,
          });
        });
      },
      setViewedAbsence(absence_id) {
        self.viewedAbsence = absence_id;
      },
      setCurrentAbsence(absence_id) {
        self.currentAbsence = absence_id;
      },
    };
  });
