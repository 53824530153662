import { rootInstance } from '../../state/RootModel';
const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getOrganizations = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/organizations`, config);
};
export const getOrganizationById = (organization_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/organizations/${organization_id}`,
    config
  );
};

export const createOrganization = (data) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/organizations`,
    {
      organization_name: data.organization_name,
      mc: data.mc,
      dot: data.dot,
      cvor: data.cvor,
    },
    config
  );
};
export const createOrganizationAdmin = (organization_id, data) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/organizations/${organization_id}/add_admin`,
    {
      email: data.admin_email,
      first_name: data.admin_firstname,
      last_name: data.admin_lastname,
    },
    config
  );
};

export const editOrganization = (organization_id, data) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/organizations/${organization_id}`,
    {
      description: data.description,
      organization_site: data.organization_site,
      mc: data.mc,
      dot: data.dot,
      cvor: data.cvor,
      ifta: data.ifta,
      fein: data.fein,
      duns_number: data.duns_number,
      scac: data.scac,
      cbsa: data.cbsa,
      ctpat_number: data.ctpat_number,
      pip: data.pip,
      fast: data.fast,
      phone_number: data.phone_number,
      address: data.address,
      city: data.city,
      state: data.state,
      country: data.country,
      // "postal_code" : data.postal_code,
      organization_name: data.organization_name,
      email: data.email,
    },
    config
  );
};

export const uploadOrganizationLogo = (organization_id, file, fileType) => {
  const config = getConfig();
  return axios.patch(
    `${rootInstance.baseUrl}/organizations/${organization_id}/upload_photos?type=${fileType}`,
    file,
    config
  );
};

export const deleteOrganizationImage = (organization_id, fileType) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/organizations/${organization_id}/photos?type=${fileType}`,
    config
  );
};

export const getOrganizationDefaultLocation = (organization_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/organizations/${organization_id}/default_location`,
    config
  );
};
export const setOrganizationDefaultLocation = (
  organization_id,
  location_id
) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/organizations/${organization_id}/default_location?location_id=${location_id}`,
    {},
    config
  );
};
