import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { DRIVER_COMPLIANCY } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import CompliancesTable from './CompliancesTable';

const CompliancesSection = () => {
  const {
    fleetPageState: { viewedDriver },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedDriver.setIsCompliancesSectionOpen(
              !viewedDriver.isCompliancesSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedDriver.isCompliancesSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {DRIVER_COMPLIANCY}
          </span>
        </div>
      </div>
      {viewedDriver?.isCompliancesSectionOpen && <CompliancesTable />}
    </div>
  );
};

export default observer(CompliancesSection);
