import { types } from 'mobx-state-tree';

export const WindowStateModel = types
  .model('WindowState', {
    windowInnerWidth: 0,
    windowInnerHeight: 0,
    scrollPosition: 0,
  })
  .actions((self) => {
    return {
      afterCreate() {
        this.setWindowInnerWidth(window.innerWidth);
        this.setWindowInnerHeight(window.innerHeight);
        window.addEventListener('resize', () => {
          this.setWindowInnerWidth(window.innerWidth);
          this.setWindowInnerHeight(window.innerHeight);
        });
        // window.addEventListener("scroll", () => {
        //   self.scrollPosition = window.scrollY;
        //   console.log(self.scrollPosition);
        // });
      },
      setWindowInnerWidth(width: number) {
        self.windowInnerWidth = width;
      },
      setWindowInnerHeight(height: number) {
        self.windowInnerHeight = height;
      },
      setScrollPosition(y: number) {
        self.scrollPosition = y;
      },
    };
  });
