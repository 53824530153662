import {
  SHIPMENT_CHARGE,
  SHIPMENT_DOCUMENT_TYPE,
  ROUTE_ACTION,
} from '../../constants/shipmentSpecificsTypes';
import { rootInstance } from '../../state/RootModel';
import { SHIPMENT_STATUS } from '../../constants';
const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getAvailableShipmentSpecificTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/get_other`, config);
};

export const getShipmentSpecifics = (type) => {
  const config = getConfig();
  const typeQuery = type ? `?item=${type}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/shipment_other${typeQuery}`,
    config
  );
};

export const addShipmentSpecific = (item) => {
  const config = getConfig();
  const typeQuery = `?item=${item.type}`;
  const itemName =
    item.type === SHIPMENT_CHARGE
      ? item.charge_name
      : item.type === SHIPMENT_DOCUMENT_TYPE
      ? item.document_type_name
      : item.type === ROUTE_ACTION
      ? item.trip_event_name
      : item.type === SHIPMENT_STATUS
      ? item.name
      : null;
  let data;
  if (item.type === ROUTE_ACTION) {
    data = {
      name: itemName,
      description: item.description,
      requires_truck: !!item.requires_truck,
      requires_trailer: !!item.requires_trailer,
    };
  } else if (item.type === SHIPMENT_STATUS) {
    data = {
      name: itemName,
      status_for_active_shipment: !!item.status_for_active_shipment,
    };
  } else {
    data = {
      name: itemName,
      price: item.price,
      currency: item.currency,
      description: item.description,
      is_brokerage_shipment: !!item.is_brokerage_shipment,
    };
  }
  return axios.post(
    `${rootInstance.baseUrl}/shipment_other${typeQuery}`,
    data,
    config
  );
};

export const editShipmentCharge = (charge) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/billing_charges/${charge.charge_id}`,
    {
      charge_name: charge.charge_name,
      price: charge.price,
      currency: charge.currency,
      description: charge.description,
      is_brokerage_shipment: charge.is_brokerage_shipment
        ? charge.is_brokerage_shipment
        : false,
    },
    config
  );
};

export const deleteShipmentCharge = (charge_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/billing_charges/${charge_id}`,
    config
  );
};
export const editShipmentStatus = (status) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/shipment_statuses/${status.status_id}`,
    {
      name: status.name,
      status_for_active_shipment: !!status.status_for_active_shipment,
    },
    config
  );
};
export const deleteShipmentStatus = (status_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/shipment_statuses/${status_id}`,
    config
  );
};

export const editShipmentDocumentType = (document) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/shipment_document_types/${document.document_type_id}`,
    {
      document_type_name: document.document_type_name,
      description: document.description,
    },
    config
  );
};
export const deleteShipmentDocumentType = (document_type_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/shipment_document_types/${document_type_id}`,
    config
  );
};

export const editShipmentRouteAction = (route_action) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/actions/${route_action.action_id}`,
    {
      name: route_action.name,
      description: route_action.description,
      requires_truck: !!route_action.requires_truck,
      requires_trailer: !!route_action.requires_trailer,
    },
    config
  );
};
export const deleteShipmentRouteAction = (action_id) => {
  const config = getConfig();
  return axios.delete(`${rootInstance.baseUrl}/actions/${action_id}`, config);
};
