import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { GENERAL_INFORMATION, OTHER } from '../../../constants';
import { Input, Select } from 'antd';
import {
  ORGANIZATION_ADDRESS,
  ORGANIZATION_CITY,
  ORGANIZATION_COUNTRY,
  ORGANIZATION_DESCRIPTION,
  ORGANIZATION_EMAIL,
  ORGANIZATION_NAME,
  ORGANIZATION_PHONE,
  ORGANIZATION_STATE,
  ORGANIZATION_WEBSITE,
  ORGANIZATION_ZIP,
} from '../../../constants/organizations/organizations';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

const { TextArea } = Input;
const { Option } = Select;

const EditOrganizationGeneralInformation = () => {
  const {
    organizationState: { editedOrganization, countriesArray, filteredStates },
  } = useMst();

  const onCountryChange = (country) => {
    editedOrganization.setOrganizationCountry(country);
  };

  const onChangeState = (state) => {
    editedOrganization.setOrganizationState(state);
  };

  return (
    <div className="add-trailer-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{GENERAL_INFORMATION}</span>
      </div>
      <div className="section-content flex-alignitems-flex-start pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedOrganization.setOrganizationName(e.target.value);
            }}
            placeholder={ORGANIZATION_NAME}
            value={editedOrganization?.organization_name}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_EMAIL}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedOrganization.setOrganizationEmail(e.target.value);
            }}
            placeholder={ORGANIZATION_EMAIL}
            value={editedOrganization?.email}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_PHONE}</span>
            <span className="color-secondary">*</span>
          </div>
          <PhoneInput
            placeholder="e.g. 712338645"
            value={editedOrganization?.phone_number}
            onChange={(value) =>
              editedOrganization.setOrganizationPhoneNumber(value)
            }
            maxLength={15}
            displayInitialValueAsLocalNumber
            className="phone-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_WEBSITE}</span>
          </div>
          <Input
            onChange={(e) => {
              editedOrganization.setOrganizationWebsite(e.target.value);
            }}
            placeholder={ORGANIZATION_WEBSITE}
            value={editedOrganization?.organization_site}
            className="add-edit-input"
          />
        </div>
      </div>
      <div className="section-content flex-alignitems-flex-start pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_ZIP}</span>
          </div>
          <Input
            onChange={(e) => {
              editedOrganization.setOrganizationPostalCode(e.target.value);
            }}
            placeholder={ORGANIZATION_ZIP}
            value={editedOrganization?.postal_code}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_CITY}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedOrganization.setOrganizationCity(e.target.value);
            }}
            placeholder={ORGANIZATION_CITY}
            value={editedOrganization?.city}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_COUNTRY}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className={'w100'}
            onChange={(country) => onCountryChange(country)}
            value={editedOrganization?.country}
          >
            {countriesArray.map((country, index) => (
              <Option key={index} value={country.name}>
                {country.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_STATE}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className={'w100'}
            onChange={(state) => onChangeState(state)}
            value={editedOrganization?.state}
          >
            {filteredStates &&
              filteredStates.states.map((state, index) => (
                <Option key={index} value={state}>
                  {state}
                </Option>
              ))}
            <Option key={'other'} value={'Other'}>
              {OTHER}
            </Option>
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_ADDRESS}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedOrganization.setOrganizationAddress(e.target.value);
            }}
            placeholder={ORGANIZATION_ADDRESS}
            value={editedOrganization?.address}
            className="add-edit-input"
          />
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{ORGANIZATION_DESCRIPTION}</span>
          </div>
          <TextArea
            rows={4}
            placeholder={ORGANIZATION_DESCRIPTION}
            onChange={(e) => {
              editedOrganization.setOrganizationDescription(e.target.value);
            }}
            value={editedOrganization?.description}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(EditOrganizationGeneralInformation);
