import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import infoSign from '../../assets/images/info-sign.svg';

const LoginErrorBox = () => {
  const loginForm = useMst().loginForm;

  return (
    <div className="login-info info">
      <div className="w100 justify-between align-center">
        <div className="flex-alignitems-flex-end">
          <img src={infoSign} alt="error sign" height="24" />
          <p className="ml5">{loginForm.infoText}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(LoginErrorBox);
