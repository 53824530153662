import { types } from 'mobx-state-tree';

export const CompensationValueLightModel = types
  .model('CompensationValueLightModel', {
    compensation_value: types.maybe(types.maybeNull(types.string)),
    based_on: types.maybe(types.maybeNull(types.string)),
    rate: types.maybe(types.maybeNull(types.string)),
    default_value: types.maybe(types.maybeNull(types.number)),
    currency: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
