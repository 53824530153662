import React from 'react';
import { observer } from 'mobx-react';
import { Empty, Table } from 'antd';
import { EMPTY_SHIPMENTS_TABLE_DETAILS_TEXT } from '../../../constants';
import { useMst } from '../../../state/RootModel';

const OrganizeTourShipmentsTableDetails = (props) => {
  const {
    dispatchPageState: { getShipmentDetailsTableColumns },
  } = useMst();
  const { data } = props;

  return (
    <div className="dispatch-table-shipment-details">
      <Table
        dataSource={data.details}
        columns={getShipmentDetailsTableColumns()}
        rowKey={'route_action_id'}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_SHIPMENTS_TABLE_DETAILS_TEXT}
            />
          ),
        }}
      />
    </div>
  );
};

export default observer(OrganizeTourShipmentsTableDetails);
