import React from 'react';

import {
  ACTION,
  ACTION_ID,
  LOCATION,
  NOT_AVAILABLE_SHORT,
  SHIPMENT_NO,
  STATUS,
  TRAILER_ID_TYPE,
  TRUCK_ID_TYPE,
} from '../../../constants';
import { DATE_TIME } from '../../../constants/dispatch';
import moment from 'moment';

export const ShipmentsDetailsTableColumns = (rootInstance) => ({
  '1': {
    title: ACTION_ID,
    dataIndex: 'action_id',
    key: 'action_id',
    width: 100,
    render: (text, record) => {
      return (
        <span>
          {record?.action?.action_id
            ? record?.action?.action_id
            : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '2': {
    title: SHIPMENT_NO,
    dataIndex: 'shipment_number',
    key: 'shipment_number',
    width: 100,
    render: (text, record) => {
      return (
        <span className="go-to">
          {record?.shipment?.shipment_number
            ? record?.shipment?.shipment_number
            : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '4': {
    title: DATE_TIME,
    dataIndex: 'planned_start',
    key: 'planned_start',
    width: 150,
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('DD.MM.YYYY HH:MM') : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '5': {
    title: ACTION,
    dataIndex: 'action',
    key: 'action',
    width: 100,
    render: (text, record) => {
      return (
        <span>
          {record?.action?.action_name
            ? record?.action?.action_name
            : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '6': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    width: 120,
    render: (text, record) => {
      return (
        <span
          className={`text-bold ${
            text === 'In Progress'
              ? 'text-orange'
              : text === 'On Time'
              ? 'text-green'
              : 'text-black'
          }`}
        >
          {text ? text : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '7': {
    title: TRUCK_ID_TYPE,
    dataIndex: 'truck_company_id',
    key: 'truck_company_id',
    width: 200,
    render: (text, record) => {
      return (
        <p>
          <span>
            {record?.truck?.truck_company_id
              ? record?.truck?.truck_company_id + ' / '
              : 'NA / '}
          </span>
          <span>
            {record?.truck?.truck_model
              ? record.truck?.truck_model
              : NOT_AVAILABLE_SHORT}
          </span>
        </p>
      );
    },
  },
  '8': {
    title: TRAILER_ID_TYPE,
    dataIndex: 'trailer_id',
    key: 'trailer_id',
    render: (text, record) => {
      return (
        <p>
          <span>
            {record?.trailer?.trailer_company_id
              ? record?.trailer?.trailer_company_id + ' / '
              : 'NA / '}
          </span>
          <span>
            {record?.trailer?.trailer_type
              ? record.trailer?.trailer_type
              : NOT_AVAILABLE_SHORT}
          </span>
        </p>
      );
    },
  },
  '9': {
    title: LOCATION,
    dataIndex: 'location',
    key: 'location',
    render: (text, record) => {
      return (
        <p>
          <span className="text-black text-bold">
            {record?.location?.location_name
              ? record?.location?.location_name
              : NOT_AVAILABLE_SHORT}
          </span>{' '}
          <br />
          <span>
            {record?.location?.address
              ? record?.location?.address
              : NOT_AVAILABLE_SHORT}
          </span>
        </p>
      );
    },
  },
});
