import React from 'react';
import { observer } from 'mobx-react';
import { Marker, Popup } from 'react-leaflet';
import { pointIcon, trailerIcon } from '../Icon';
import { STOP } from '../../../constants/dispatch';
import DispatchRoutingMachine from '../routing-machine/DispatchRoutingMachine';
import L from 'leaflet';
import TrackingShipmentPickupLocation from '../trackingShipment/TrackingShipmentPickupLocation';
import TrackingShipmentDeliveryLocation from '../trackingShipment/TrackingShipmentDeliveryLocation';

const TrackingShipmentMapDetails = (props) => {
  const { data } = props;
  const routerRef = React.useRef();
  const [routerReady, setRouterReady] = React.useState(false);

  return (
    <div>
      <div>
        {data[0]?.has_started && (
          <>
            {data[0]?.has_route_action_in_progress &&
              data[0]?.completed_locations?.length === 0 && (
                <>
                  {data[0].trailer?.vehicle_latitude &&
                    data[0].trailer?.vehicle_longitude && (
                      <DispatchRoutingMachine
                        ref={routerRef}
                        waypoints={[
                          {
                            lat: data[0]?.locations[0]?.location_latitude,
                            lng: data[0]?.locations[0]?.location_longitude,
                          },
                          {
                            lat: data[0].trailer?.vehicle_latitude,
                            lng: data[0].trailer?.vehicle_longitude,
                          },
                        ]}
                        lineOptions={{
                          styles: [{ color: 'blue', weight: 4, opacity: 1 }],
                        }}
                        onRoutesFound={() => {
                          if (!routerReady) {
                            setRouterReady(true);
                          }
                        }}
                      />
                    )}
                </>
              )}
            {data[0]?.has_route_action_in_progress &&
              data[0]?.completed_locations?.length > 0 && (
                <>
                  <DispatchRoutingMachine
                    ref={routerRef}
                    waypoints={data[0]?.completed_locations.map((location) =>
                      L.latLng([
                        location.location_latitude,
                        location.location_longitude,
                      ])
                    )}
                    lineOptions={{
                      styles: [{ color: 'blue', weight: 4, opacity: 1 }],
                    }}
                    onRoutesFound={() => {
                      if (!routerReady) {
                        setRouterReady(true);
                      }
                    }}
                  />
                  <DispatchRoutingMachine
                    ref={routerRef}
                    waypoints={[
                      {
                        lat: data[0]?.completed_locations[
                          data[0]?.completed_locations.length - 1
                        ]?.location_latitude,
                        lng: data[0]?.completed_locations[
                          data[0]?.completed_locations.length - 1
                        ]?.location_longitude,
                      },
                      {
                        lat: data[0].trailer?.vehicle_latitude,
                        lng: data[0].trailer?.vehicle_longitude,
                      },
                    ]}
                    lineOptions={{
                      styles: [{ color: 'blue', weight: 4, opacity: 1 }],
                    }}
                    onRoutesFound={() => {
                      if (!routerReady) {
                        setRouterReady(true);
                      }
                    }}
                  />
                </>
              )}
            {!data[0]?.has_route_action_in_progress && (
              <>
                <DispatchRoutingMachine
                  ref={routerRef}
                  waypoints={data[0]?.completed_locations.map((location) =>
                    L.latLng([
                      location.location_latitude,
                      location.location_longitude,
                    ])
                  )}
                  lineOptions={{
                    styles: [{ color: 'blue', weight: 4, opacity: 1 }],
                  }}
                  onRoutesFound={() => {
                    if (!routerReady) {
                      setRouterReady(true);
                    }
                  }}
                />
              </>
            )}
          </>
        )}
        <TrackingShipmentPickupLocation data={data} />
        <TrackingShipmentDeliveryLocation data={data} />
        {data[0]?.tracking_stops &&
          data[0]?.tracking_stops?.length > 0 &&
          data[0]?.tracking_stops.map((location, index) => (
            <Marker
              key={index}
              position={[
                location.location_latitude,
                location.location_longitude,
              ]}
              // @ts-ignore
              icon={pointIcon}
              className="dispatch-map-marker"
            >
              <Popup>
                <p className="dispatch-map-marker__type text-bold">
                  {STOP + ': '}
                  {index + 1}
                  {location?.location_name
                    ? ' - ' + location.location_name
                    : ''}
                </p>
                <span className="text-black">{location.address}</span>
              </Popup>
            </Marker>
          ))}
        {data[0].trailer?.vehicle_latitude &&
          data[0].trailer?.vehicle_longitude && (
            <div>
              <Marker
                position={[
                  data[0].trailer.vehicle_latitude,
                  data[0].trailer.vehicle_longitude,
                ]}
                // @ts-ignore
                icon={trailerIcon}
                className="dispatch-map-marker"
              />
            </div>
          )}
        {data[0]?.locations?.length > 0 && (
          <DispatchRoutingMachine
            ref={routerRef}
            waypoints={data[0]?.locations.map((location) =>
              L.latLng([
                location.location_latitude,
                location.location_longitude,
              ])
            )}
            lineOptions={{
              styles: [{ color: 'orange', weight: 4, opacity: 0.5 }],
            }}
            onRoutesFound={() => {
              if (!routerReady) {
                setRouterReady(true);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default observer(TrackingShipmentMapDetails);
