import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Empty, Table } from 'antd';
import {
  CREATE_COMPENSATION_STRUCTURE,
  DEDUCTIONS,
  DEDUCTIONS_EDIT,
  EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT,
  VALUES_EDIT,
} from '../../../constants/payroll';
import PageRoutes from '../../../utils/PageRoutes';
import Link from '../../Link';
const CompensationsComponent = () => {
  const {
    payrollPageState: {
      getCompensationTableColumns,
      getCompensationValuesOrDeductionsTableColumns,
      filteredCompensationsList,
      expandedRowKeys,
      setExpandedRowKeys,
      setSelectedCompensationStructure,
    },
  } = useMst();

  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if (
      expanded &&
      (record.compensation_values.length !== 0 ||
        record.compensation_deductions.length !== 0)
    ) {
      keys.push(record.compensation_id);
    }
    setExpandedRowKeys(keys);
  };

  return (
    <div className="pv20 compensation-structures-component">
      <Link
        className="btn primary add no-wrap header-button"
        view={PageRoutes.AddCompensation.name}
      >
        {CREATE_COMPENSATION_STRUCTURE}
      </Link>
      <Table
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedCompensationStructure(record);
            },
          };
        }}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
        className="compensations-table"
        rowKey={'compensation_id'}
        columns={getCompensationTableColumns()}
        dataSource={filteredCompensationsList}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT}
            />
          ),
        }}
        expandRowByClick
        expandIconAsCell={false}
        expandIcon={() => <></>}
        rowClassName={() => 'compensation-table-row'}
        expandedRowRender={(record: any) => {
          return (
            <div>
              {!!record.compensation_values.length && (
                <Table
                  expandedRowKeys={[record.compensation_value_id]}
                  className="compensations-table mt10"
                  columns={getCompensationValuesOrDeductionsTableColumns(
                    VALUES_EDIT
                  )}
                  dataSource={record.compensation_values.map((el) =>
                    el.based_on === null ? { ...el, based_on: 'N/A' } : el
                  )}
                  pagination={false}
                  rowKey={record.compensation_value_id}
                  rowClassName={() => 'compensation-table-expanded-row'}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT}
                      />
                    ),
                  }}
                />
              )}
              {!!record.compensation_deductions.length && (
                <Table
                  className="compensations-table mt10"
                  columns={getCompensationValuesOrDeductionsTableColumns(
                    DEDUCTIONS_EDIT
                  )}
                  dataSource={record.compensation_deductions.map((el) =>
                    el.based_on === null ? { ...el, based_on: 'N/A' } : el
                  )}
                  pagination={false}
                  rowKey={record.compensation_deduction_id}
                  rowClassName={() => 'compensation-table-expanded-row'}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT}
                      />
                    ),
                  }}
                />
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default observer(CompensationsComponent);
