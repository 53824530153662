import { types } from 'mobx-state-tree';

export const EmployeeRoleModel = types
  .model('EmployeeRoleModel', {
    role_id: types.identifierNumber,
    role_name: types.maybe(types.maybeNull(types.string)),
    role_department_id: types.maybe(types.maybeNull(types.number)),
    parent_role_id: types.maybe(types.maybeNull(types.number)),
    role_organization_id: types.maybe(types.maybeNull(types.number)),
  })
  .actions((self) => {
    return {};
  });
