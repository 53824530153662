import { rootInstance } from '../../state/RootModel';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

//Permissions
export const getPermissions = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/permissions`, config);
};

//Departments
export const getDepartments = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/departments`, config);
};
export const createNewDepartment = (departmentName) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/departments?department_name=${departmentName}`,
    {},
    config
  );
};
export const editDepartment = (departmentId, newDepartmentName) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/department/${departmentId}?new_name=${newDepartmentName}`,
    {},
    config
  );
};
export const deleteDepartment = (departmentId) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/department/${departmentId}`,
    config
  );
};

//User roles
export const getUserRoles = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/all_roles`, config);
};

// Create role
export const createRole = (role_name) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/roles?role_name=${role_name}`,
    {},
    config
  );
};

export const deleteUserRole = (id) => {
  // const config = getConfig();
  // return axios.delete(
  //     `${rootInstance.baseUrl}/roles/${id}`,
  //     config
  // )
};

// Create/Add role permissions
export const createRolePermissions = (role_id, permissions) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/roles/${role_id}/permissions`,
    permissions,
    config
  );
};
//Edit role permissions
export const updateRolePermissions = (role_id, permissions) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/roles/${role_id}/permissions`,
    permissions,
    config
  );
};

//Edit role name
export const updateRoleName = (role_id, new_name) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/roles/${role_id}?new_name=${new_name}`,
    {},
    config
  );
};

// Get role
export const getRoleById = (role_id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/roles/${role_id}`, config);
};

// Get role permissions
export const getRolePermissions = (role_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/roles/${role_id}/permissions`,
    config
  );
};
