import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import addTrailerIcon from '../../../assets/images/add-trailer.svg';
import FormPageTitle from '../../FormPageTitle';
import GeneralInformation from './GeneralInformation';
import TrailerInformation from './TrailerInformation';
import FormButtonsSection from '../../FormButtonsSection';
import ErrorBox from '../../ErrorBox';
import { ADD_TRAILER, DISCARD_CHANGES } from '../../../constants';

const AddTrailerComponent = () => {
  const {
    fleetPageState: { newTrailer, addTrailer },
  } = useMst();

  const addNewTrailer = () => {
    addTrailer();
  };

  const onDiscardChanges = () => {
    newTrailer.clearForm();
  };

  return (
    <div className="add-edit-form-component">
      <FormPageTitle image={addTrailerIcon} title={ADD_TRAILER} />

      <GeneralInformation />

      <TrailerInformation />

      <ErrorBox obj={newTrailer} />

      <FormButtonsSection
        onConfirm={addNewTrailer}
        onDiscard={onDiscardChanges}
        confirmTitle={ADD_TRAILER}
        discardTitle={DISCARD_CHANGES}
      />
    </div>
  );
};

export default observer(AddTrailerComponent);
