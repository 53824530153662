import React from 'react';
import { observer } from 'mobx-react';
import {
  CURRENT_EVENT,
  CURRENT_SHIPMENT,
  DEF,
  ETA_FOR_CURRENT_EVENT,
  FUEL,
  HOS,
  SPEED,
  TODAY,
} from '../../../constants/dispatch';
import {
  CURRENT_LOCATION,
  NOT_AVAILABLE_SHORT,
  SHIPMENT_INFORMATION,
  TRUCK_INFORMATION,
} from '../../../constants';
import moment from 'moment';

const AssetsListDriverTableDetails = (props) => {
  const { data } = props;

  return (
    <div className="dispatch-trip-modal__driver-details">
      <div className="top_details">
        <span className="text-bold text-uppercase">{TRUCK_INFORMATION}</span>
        {/*<span className='text-dkred text-capitalize ml15 d-iblock'>2 Fault codes detected</span>*/}
      </div>
      <div className="flex-start flex-wrap">
        <div className="dispatch-trip-modal__driver-details-item w20 has-border-right">
          <div className="align-center">
            <div className="pr20">
              <p className="text-bold text-uppercase color-light-gray">
                {FUEL}
              </p>
              <span className="text-black">
                {data?.linked_truck_data?.fuel >= 0
                  ? data?.linked_truck_data?.fuel > 0 ||
                    data?.linked_truck_data?.fuel === 0
                    ? data?.linked_truck_data?.fuel
                    : NOT_AVAILABLE_SHORT
                  : NOT_AVAILABLE_SHORT}
              </span>
            </div>
            <div className="ph20">
              <p className="text-bold text-uppercase color-light-gray">
                {SPEED}
              </p>
              <span className="text-black">
                {data?.linked_truck_data?.speed >= 0
                  ? data.linked_truck_data.speed > 0 ||
                    data.linked_truck_data.speed === 0
                    ? data.linked_truck_data.speed +
                      data.linked_truck_data.speed_unit
                    : NOT_AVAILABLE_SHORT
                  : NOT_AVAILABLE_SHORT}
              </span>
            </div>
            <div className="ph20">
              <p className="text-bold text-uppercase color-light-gray">{DEF}</p>
              <span className="text-black">
                {data?.linked_truck_data?.def
                  ? data?.linked_truck_data?.def + '%'
                  : NOT_AVAILABLE_SHORT}
              </span>
            </div>
          </div>
        </div>
        <div className="dispatch-trip-modal__driver-details-item w40 ph20 align-center">
          <div className="w100">
            <p className="text-bold text-uppercase color-light-gray">
              {TRUCK_INFORMATION}
            </p>
            <span className="text-black">
              {data?.linked_truck_data?.internal_company_id
                ? data?.linked_truck_data?.internal_company_id + ' - '
                : 'NA - '}
              {data?.linked_truck_data?.vehicle_make
                ? data?.linked_truck_data?.vehicle_make
                : NOT_AVAILABLE_SHORT}
            </span>
          </div>
          <div className="w100">
            <p className="text-bold text-uppercase color-light-gray">
              {CURRENT_LOCATION}
            </p>
            <span className="text-black">
              {data?.linked_truck_data?.location_description
                ? data?.linked_truck_data?.location_description
                : NOT_AVAILABLE_SHORT}
            </span>
          </div>
        </div>

        {/*Bottom part*/}
        <div className="w50 mt40 pr20">
          <div className="top_details">
            <span className="text-bold text-uppercase">
              {SHIPMENT_INFORMATION}
            </span>
          </div>
          <div className="align-center">
            <div className="mb20 w50">
              <p className="text-bold text-uppercase color-light-gray">
                {CURRENT_SHIPMENT}
              </p>
              <div
                className={data?.current_action?.shipment_number ? 'go-to' : ''}
              >
                {data?.current_action?.shipment_number
                  ? data?.current_action?.shipment_number
                  : NOT_AVAILABLE_SHORT}
              </div>
            </div>
            <div className="mb20 w50">
              <p className="text-bold text-uppercase color-light-gray">
                {CURRENT_EVENT}
              </p>
              <div>
                {data?.current_action?.name
                  ? data?.current_action?.name
                  : NOT_AVAILABLE_SHORT}
              </div>
            </div>
            <div className="mb20 w100">
              <p className="text-bold text-uppercase color-light-gray">
                {ETA_FOR_CURRENT_EVENT}
              </p>
              <p className="w50">
                {data?.eta_for_current_action
                  ? moment(data.eta_for_current_action).format(
                      'DD.MM.YYYY HH:mm'
                    )
                  : NOT_AVAILABLE_SHORT}
              </p>
            </div>
          </div>
        </div>
        <div className="w50 mt40 pl20">
          <div className="top_details">
            <span className="text-bold text-uppercase">{HOS}</span>
            {/*<span className='text-dkred ml15 d-iblock'>2 HoS violations detected</span>*/}
          </div>
          <div className="align-center">
            <div className="w100">
              <div className="dispatch-trip-modal__driver-details-item">
                <div className="mb20">
                  <p className="text-bold text-uppercase color-light-gray">
                    {TODAY}
                  </p>
                  {data?.driver_hos && (
                    <div className="text-black">
                      <span className="text-blue text-bold d-iblock">BR</span>{' '}
                      {data?.driver_hos?.break
                        ? data.driver_hos.break
                        : NOT_AVAILABLE_SHORT}
                      <span className="text-blue text-bold d-iblock ml5">
                        DR
                      </span>{' '}
                      {data?.driver_hos?.drive
                        ? data.driver_hos.drive
                        : NOT_AVAILABLE_SHORT}
                      <span className="text-blue text-bold d-iblock ml5">
                        SH
                      </span>{' '}
                      {data?.driver_hos?.shift
                        ? data.driver_hos.shift
                        : NOT_AVAILABLE_SHORT}
                      <span className="text-blue text-bold d-iblock ml5">
                        CL
                      </span>{' '}
                      {data?.driver_hos?.cycle
                        ? data.driver_hos.cycle
                        : NOT_AVAILABLE_SHORT}
                    </div>
                  )}
                  {!data?.driver_hos && <p>NA</p>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AssetsListDriverTableDetails);
