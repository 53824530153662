import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import { Switch, Tooltip, Popover } from 'antd';
import { DebounceInput } from 'react-debounce-input';
import search from '../assets/images/search.svg';
import trash from '../assets/images/trash.svg';
import notificationIcon from '../assets/images/notification-bell.svg';
import notificationDot from '../assets/images/notification-dot.svg';
import userPlaceholder from '../assets/images/user-placeholder.png';
import settingsIcon from '../assets/images/menu/settings-icon.svg';
import viewActiveIcon from '../assets/images/view-active.svg';
import {
  VIEW_ARCHIVED_TEXT,
  VIEW_ACTIVE_TEXT,
  ACCOUNT_SETTINGS,
  ADD,
  ADD_EMPLOYEE,
  ARCHIVED,
  INACTIVE_SHIPMENTS,
  ACTIVE_SHIPMENTS,
} from '../constants';
import {
  DISPATCH,
  DISPATCH_MAP,
  EMPLOYEES,
  PAYROLL,
  PAYROLL_AND_BILLING,
} from '../constants/menu';
import { PageRoutes } from '../utils/PageRoutes';
import { Link } from './Link';
import addEmployee from '../assets/images/menu/add-employee.svg';

import Notifications from './notifications/Notifications';

import { NOTIFICATIONS } from '../constants';

const Header = () => {
  const {
    sideMenuState: { activeMenuItem },
    loginForm,
    router,
    headerState: {
      searchText,
      setSearchText,
      isSearchInputVisible,
      isArchivedMode,
      isBrokeragePage,

      getAllNotifications,
      loadNotifications,
      hasUnresolvedNotifications,
      resolveNotification,
    },
    employeesPageState: { setEmployeesDisplayedStatus },
    brokeragePageState: { isShipmentsTabActive },
    shipmentsPageState: { activeStatus, setActiveStatus },
    dispatchPageState: {
      currentTimeEDT,
      currentTimeGMT,
      setCurrentTimeEDT,
      setCurrentTimeGMT,
    },
    userState: { canAddNewUsers },
  } = useMst();

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const currentUser = JSON.parse(localStorage.getItem('user'));

  const userRole = JSON.parse(localStorage.getItem('role'));

  const userPhoto = localStorage.getItem('user_photo');

  const userThumb = userPhoto ? userPhoto : userPlaceholder;

  const isEmployeesPage = () => {
    return router.currentView.name === EMPLOYEES;
  };

  const onAddEmployee = (e) => {
    e.preventDefault();
    router.setView(router.views.get(PageRoutes.AddEmployee.name));
  };

  const onToggleArchivedEmployees = (e) => {
    e.preventDefault();
    isArchivedMode
      ? setEmployeesDisplayedStatus(null)
      : setEmployeesDisplayedStatus(ARCHIVED);
    router.setView(router.views.get(PageRoutes.Employees.name));
  };

  const onShipmentsStatusChange = (value) => {
    setActiveStatus(value);
  };

  const isDispatchPage = () => {
    return router.currentView.name === DISPATCH;
  };

  const currentTime = () => {
    setCurrentTimeEDT();
    setCurrentTimeGMT();
  };

  setTimeout(() => {
    currentTime();
    setInterval(() => {
      currentTime();
    }, 60 * 1000);
  }, 500);

  let menuTitle = activeMenuItem?.title;
  if (menuTitle === DISPATCH) {
    menuTitle = DISPATCH_MAP;
  }
  if (menuTitle === PAYROLL) {
    menuTitle = PAYROLL_AND_BILLING;
  }

  return (
    <div
      className={`header justify-between align-center ${
        isArchivedMode ? 'bg-header-bg-dark' : 'bg-header-bg-light'
      }`}
    >
      <div className="interface-wrapper align-center">
        {activeMenuItem && (
          <div className="align-center">
            <div className="active-menu-item">
              <div>
                <img
                  src={activeMenuItem.activeIcon}
                  alt={activeMenuItem.title}
                />
                <span
                  className={`ml10 ${
                    isArchivedMode ? 'color-white' : 'color-title'
                  }`}
                >
                  {menuTitle}
                </span>
              </div>
            </div>
            {isSearchInputVisible && (
              <div className="align-center">
                <div className="search-wrapper align-center ml10">
                  <img src={search} alt="search" className="search-icon" />
                  <DebounceInput
                    debounceTimeout={300}
                    onChange={(e) => handleSearch(e)}
                    style={{ width: 300 }}
                    placeholder={
                      activeMenuItem.searchPlaceholder
                        ? activeMenuItem.searchPlaceholder
                        : ''
                    }
                    value={searchText}
                  />
                </div>
                {isEmployeesPage() && canAddNewUsers && (
                  <a
                    className="ml20"
                    onClick={(e) => onAddEmployee(e)}
                    href="# "
                  >
                    <Tooltip title={ADD_EMPLOYEE}>
                      <img src={addEmployee} alt={ADD} />
                    </Tooltip>
                  </a>
                )}
                {isEmployeesPage() && (
                  <a
                    className="view-archived ml20"
                    onClick={(e) => onToggleArchivedEmployees(e)}
                    href="# "
                  >
                    <img
                      src={isArchivedMode ? viewActiveIcon : trash}
                      alt="trash"
                      className="trash-icon"
                    />
                    <span
                      className={`ml5 ${
                        isArchivedMode
                          ? 'color-header-show-active-text'
                          : 'color-title'
                      }`}
                    >
                      {isArchivedMode ? VIEW_ACTIVE_TEXT : VIEW_ARCHIVED_TEXT}
                    </span>
                  </a>
                )}
              </div>
            )}
            {isBrokeragePage && isShipmentsTabActive && (
              <div className="align-center ml20">
                <span>{INACTIVE_SHIPMENTS}</span>
                <Switch
                  checked={activeStatus}
                  onChange={onShipmentsStatusChange}
                  className="ml10 mr10"
                />
                <span>{ACTIVE_SHIPMENTS}</span>
              </div>
            )}
          </div>
        )}
        {loginForm.currentUser && (
          <div className="align-center">
            <div className="active-menu-item">
              <div>
                <img src={settingsIcon} alt="settings" />
                <span className="ml10 color-title">{ACCOUNT_SETTINGS}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      {isDispatchPage() && (
        <div className="current-time align-center ml-a mr50">
          <p className="current-time__timezone pv10 ph20 ml10 mr10">
            {currentTimeEDT} EDT
          </p>
          <p className="current-time__timezone pv10 ph20 ml10 mr10">
            {currentTimeGMT} GMT
          </p>
        </div>
      )}
      <div className="user-info-wrapper" id="user-info">
        <Popover
          trigger="click"
          onVisibleChange={() => setOpen(true)}
          placement="bottom"
          title={<h3>{NOTIFICATIONS}</h3>}
          content={
            <Notifications
              notifications={getAllNotifications}
              markNotificationAsDone={resolveNotification}
            />
          }
          getPopupContainer={() => document.getElementById('user-info')}
        >
          <div className="notification-info-wrapper">
            <img
              src={notificationIcon}
              alt="notification icon"
              className="notification-icon"
            />
            {hasUnresolvedNotifications && !open && (
              <img
                src={notificationDot}
                alt="notification dot"
                className="notification-dot"
              />
            )}
          </div>
        </Popover>
        {currentUser && (
          <Link view={PageRoutes.Account.name}>
            <Tooltip placement="bottom" title={ACCOUNT_SETTINGS}>
              <div className="user-info ml10 align-center">
                <div
                  className="user-photo detail-thumb"
                  style={{ backgroundImage: `url(${userThumb})` }}
                />
                <div className="user-info-text ml5">
                  <h3
                    className={`${
                      isArchivedMode ? 'color-white' : 'color-title'
                    }`}
                  >
                    {currentUser.first_name} {currentUser.last_name}
                  </h3>
                  <p className="color-secondary text-capitalize">
                    {userRole && userRole.role_name}
                  </p>
                </div>
              </div>
            </Tooltip>
          </Link>
        )}
      </div>
    </div>
  );
};

export default observer(Header);
