import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import edit from '../../../assets/images/edit.svg';
import { Input, Modal, Select } from 'antd';
import ErrorBox from '../../ErrorBox';
import { CANCEL, CONFIRM } from '../../../constants';
import {
  COMPENSATION_EDIT,
  COMPENSATION_NAME,
  COMPENSATION_PERIOD,
} from '../../../constants/payroll';

const { Option } = Select;

const EditCompensationModal = () => {
  const {
    payrollPageState: {
      isEditingCompensationModalOpen,
      setIsEditingCompensationModalOpen,
      editedCompensationName,
      editedCompensationPeriod,
      getAllCompensationPaymentPeriods,
      setEditedCompensationName,
      setEditedCompensationPeriod,
      saveEditedCompensation,
    },
  } = useMst();

  const onSetCompensationName = (e) => {
    setEditedCompensationName(e.target.value);
  };

  const onCancel = () => {
    setIsEditingCompensationModalOpen(false);
  };
  const onConfirm = () => {
    saveEditedCompensation();
  };
  return (
    <div>
      <Modal
        visible={!!isEditingCompensationModalOpen}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="modal-content p20">
          <div className="align-center mb20">
            <img src={edit} alt="save" className="mr10" />
            <h3>{COMPENSATION_EDIT}</h3>
          </div>
          <div className="flex-column">
            <div className="input-wrapper w100">
              <div className="input-label justify-between">
                <span>
                  {COMPENSATION_NAME}
                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Input
                type="text"
                value={editedCompensationName}
                placeholder={COMPENSATION_NAME}
                onChange={(e) => onSetCompensationName(e)}
                className="trailer-search-input"
              />
            </div>
            <div className="input-wrapper w50 mt20">
              <div className="input-label align-center">
                <span>{COMPENSATION_PERIOD}</span>
                <span className="color-secondary ml10">*</span>
              </div>
              <Select
                className={'w100'}
                onChange={(ct) => setEditedCompensationPeriod(ct)}
                value={editedCompensationPeriod}
              >
                {getAllCompensationPaymentPeriods.map((ct, index) => (
                  <Option key={index} value={ct}>
                    {ct}
                  </Option>
                ))}
              </Select>
            </div>
          </div>

          {/* <ErrorBox obj={editedCompensation} /> */}
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditCompensationModal);
