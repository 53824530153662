import { types } from 'mobx-state-tree';
import {
  CARRIERS,
  CUSTOMERS,
  LOCATIONS,
  SHIPMENTS,
  OTHER,
} from '../../constants';
import { rootInstance } from '../RootModel';
import { DASHBOARD } from '../../constants/menu';

export const BrokeragePageModel = types
  .model('BrokeragePageModel', {
    activeTab: types.maybe(types.string),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get isShipmentsTabActive() {
        return self.activeTab === SHIPMENTS;
      },
    };
  })
  .actions((self) => {
    return {
      beforePageEnter() {
        if (rootInstance.userState.canViewShipments) {
          this.setActiveTab(SHIPMENTS);
        } else if (rootInstance.userState.canViewClients) {
          this.setActiveTab(CUSTOMERS);
        } else if (rootInstance.userState.canViewLocations) {
          this.setActiveTab(LOCATIONS);
        } else if (rootInstance.userState.canViewCarriers) {
          this.setActiveTab(CARRIERS);
        } else if (
          rootInstance.userState.canViewShipmentBillingCharges ||
          rootInstance.userState.canViewShipmentActions ||
          rootInstance.userState.canViewShipmentDocumentTypes
        ) {
          this.setActiveTab(OTHER);
        } else {
          rootInstance.router.setView(rootInstance.router.views.get(DASHBOARD));
        }
      },
      setActiveTab(key) {
        self.activeTab = key;
        self.activeTab === SHIPMENTS && this.getShipmentsTab();
        self.activeTab === CUSTOMERS && this.getCustomersTab();
        self.activeTab === LOCATIONS && this.getLocationsTab();
        self.activeTab === CARRIERS && this.getCarriersTab();
        self.activeTab === OTHER &&
          rootInstance.shipmentSpecificsPageState.getShipmentSpecifics();
      },
      clearAllData() {
        rootInstance.shipmentsPageState.clearShipments();
        rootInstance.clientsPageState.clearClients();
        rootInstance.locationsPageState.clearLocations();
        rootInstance.carriersPageState.clearCarriers();
      },
      getShipmentsTab() {
        this.clearAllData();
        rootInstance.shipmentsPageState.getShipments({});
      },
      getCustomersTab() {
        this.clearAllData();
        rootInstance.clientsPageState.getClients({});
      },
      getLocationsTab() {
        this.clearAllData();
        rootInstance.locationsPageState.getLocations({});
      },
      getCarriersTab() {
        this.clearAllData();
        rootInstance.carriersPageState.getCarriers({});
      },
    };
  });
