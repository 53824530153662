import { types } from 'mobx-state-tree';

export const LoaderModel = types
  .model('LoaderModel', {
    fetchingInProgress: false,
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setFetchingInProgress(status) {
        self.fetchingInProgress = status;
      },
    };
  });
