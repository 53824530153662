import React from 'react';
import {
  ADDRESS,
  COUNTRY_STATE_CITY,
  DELETE_RECORD_TEXT,
  EDIT_RECORD_TEXT,
  CUSTOMER_NAME,
  EMAIL,
  PHONE_NUMBER,
} from '../../constants';
import trash from '../../assets/images/trash.svg';
import edit from '../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.clientsPageState.setCustomerToBeDeleted(record.client_id);
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.clientsPageState.getEditedCustomer(record.client_id);
};

export const customersColumnsFunc = (rootInstance) => ({
  '1': {
    title: CUSTOMER_NAME,
    dataIndex: 'company',
    key: 'company',
  },
  '2': {
    title: COUNTRY_STATE_CITY,
    key: 'country_state_city',
    dataIndex: 'current_driver',
    render: (text, record) => {
      return (
        <span>
          {record.country} / {record.state} / {record.city}
        </span>
      );
    },
  },
  '3': {
    title: ADDRESS,
    key: 'address',
    dataIndex: 'address',
  },
  '4': {
    title: EMAIL,
    key: 'email',
    dataIndex: 'email',
  },
  '5': {
    title: PHONE_NUMBER,
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  '7': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          {rootInstance.userState.canRemoveClients && (
            <Tooltip title={DELETE_RECORD_TEXT}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          )}
          {rootInstance.userState.canEditClients && (
            <div onClick={(e) => onClickEdit(e, record)}>
              <Tooltip title={EDIT_RECORD_TEXT}>
                <img src={edit} alt="edit" className="pointer ph10" />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
  },
});
