import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { EMPTY_MAINTENANCE_TABLE_TEXT } from '../../../../constants';

const EditMaintenanceTable = ({ model }) => {
  return (
    <Table
      className="mb20"
      rowKey={'maintenance_id'}
      columns={model.getMaintenanceColumns()}
      dataSource={model.maintenancesArray}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={EMPTY_MAINTENANCE_TABLE_TEXT}
          />
        ),
      }}
    />
  );
};

export default observer(EditMaintenanceTable);
