import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Modal, Select, Input, DatePicker, TimePicker, Upload } from 'antd';
import {
  SAVE_CHANGES,
  INSPECTION_TYPE,
  ODOMETER,
  NO_TYPE_SELECTED,
  DATE,
  TIME,
  EDIT_INSPECTION,
  INSPECTION_DOCUMENT,
  NOTES,
  NOTES_PLACEHOLDER,
  DELETE_RECORD_TEXT,
} from '../../constants';
import FormPageTitle from '../FormPageTitle';
import inspectionIcon from '../../assets/images/inspection.svg';
import fileUpload from '../../assets/images/file-upload.svg';
import moment from 'moment';
import ErrorBox from '../ErrorBox';
import { getRandomInt } from '../../utils/methods';
import { DOCUMENT_TYPE_INSPECTION } from '../../constants/documents';
import FormButtonsSection from '../FormButtonsSection';
const isEqual = require('lodash.isequal');

const { Option } = Select;
const { TextArea } = Input;

const EditInspectionModal = () => {
  const {
    fleetPageState: {
      editedTruck,
      inspectionTypes,
      availableOdometerUnits,
      editedVehicle,
    },
  } = useMst();

  // added to reset state on discard changes
  const [key, setKey] = useState(getRandomInt());

  const [document, setDocument] = useState([]);
  useEffect(() => {
    setDocument(
      editedVehicle.editedInspection?.document
        ? [editedVehicle.editedInspection.document]
        : []
    );
  }, [editedVehicle.editedInspection?.document, key]);
  const handleDocumentUpload = ({ file, fileList }) => {
    editedVehicle?.licence?.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_INSPECTION;
      if (file.status !== 'uploading') {
        setDocument(listToUpload);
      }
    } else {
      setDocument(fileList);
    }
  };

  const onSelectType = (value) => {
    editedVehicle.editedInspection.setType(value);
  };

  const onChangeNotes = (e) => {
    editedVehicle.editedInspection.setNotes(e.target.value);
  };

  const onDateChange = (value, string) => {
    editedVehicle.editedInspection.setDate(string);
  };

  const onTimeChange = (value, string) => {
    editedVehicle.editedInspection.setTime(string);
  };

  const onChangeOdometer = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      editedVehicle.editedInspection.setOdometer(null);
    } else {
      editedVehicle.editedInspection.setOdometer(Number(e.target.value));
    }
  };

  const onSelectOdometerUnit = (value) => {
    editedVehicle.editedInspection.setOdometerUnit(value);
  };

  const selectUnits = (
    <Select
      className="w100 select-odometer-units"
      onChange={onSelectOdometerUnit}
      value={editedVehicle.editedInspection?.odometer_unit}
    >
      {availableOdometerUnits.map((unit, index) => (
        <Option key={index} value={unit}>
          {unit}
        </Option>
      ))}
    </Select>
  );

  // const onChangeSignature = (e) => {
  //   editedVehicle.editedInspection.setSignature(e.target.value);
  // };

  const getDocumentForUpload = () => {
    if (document[0] && !editedVehicle.editedInspection.document) {
      return document[0];
    } else if (
      document[0] &&
      editedVehicle.editedInspection.document &&
      !isEqual(editedVehicle.editedInspection.document, document[0])
    ) {
      return document[0];
    }
    return false;
  };

  const getDocumentForDeletion = () => {
    if (
      editedVehicle.editedInspection.document &&
      !isEqual(editedVehicle.editedInspection.document, document[0])
    ) {
      return editedVehicle.editedInspection.document;
    }
    return false;
  };

  const onSaveChanges = () => {
    const documentToUpload = getDocumentForUpload();
    const documentToDelete = getDocumentForDeletion();
    editedVehicle.editedInspection.updateInspection(
      documentToUpload,
      documentToDelete
    );
  };

  const onDelete = () => {
    editedVehicle.setInspectionToBeDeleted(
      editedVehicle.editedInspection.inspection_id
    );
  };

  const onCancel = () => {
    setKey(getRandomInt());
    editedVehicle.setEditedInspection(null);
  };

  return (
    editedVehicle && (
      <div>
        <Modal
          visible={!!editedVehicle.editedInspection}
          footer={null}
          centered={true}
          onCancel={onCancel}
          maskClosable={false}
          width={470}
          className="modal"
          destroyOnClose={true}
        >
          <div className="p32">
            <div className="align-center">
              <FormPageTitle image={inspectionIcon} title={EDIT_INSPECTION} />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{INSPECTION_TYPE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className="w100"
                onChange={onSelectType}
                value={editedVehicle.editedInspection?.inspection_type}
                placeholder={
                  !editedVehicle.editedInspection?.inspection_type &&
                  NO_TYPE_SELECTED
                }
              >
                {inspectionTypes.map((type) => (
                  <Option key={type} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper mt20">
              <div className="input-label justify-between">
                <span>{DATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onDateChange}
                value={
                  editedVehicle.editedInspection?.date
                    ? moment(editedVehicle.editedInspection.date)
                    : null
                }
              />
            </div>
            <div className="input-wrapper mt20">
              <div className="input-label justify-between">
                <span>{TIME}</span>
                <span className="color-secondary">*</span>
              </div>
              <TimePicker
                allowClear={false}
                onChange={onTimeChange}
                format={'HH:mm'}
                value={
                  editedVehicle.editedInspection?.time
                    ? moment(editedVehicle.editedInspection.time, 'HH:mm')
                    : null
                }
              />
            </div>
            {editedTruck && (
              <div className="input-wrapper mt20">
                <div className="input-label justify-between">
                  <span>{ODOMETER}</span>
                </div>
                <Input
                  className="add-edit-input"
                  onChange={onChangeOdometer}
                  value={editedVehicle.editedInspection?.odometer}
                  addonAfter={selectUnits}
                />
              </div>
            )}
            <div className="input-wrapper mt20">
              <div className="input-label justify-between">
                <span>{NOTES}</span>
                <span className="color-secondary">*</span>
              </div>
              <TextArea
                rows={4}
                placeholder={NOTES_PLACEHOLDER}
                onChange={onChangeNotes}
                value={editedVehicle.editedInspection?.notes}
              />
            </div>
            <div className="input-wrapper mt20">
              <div className="input-label">
                <Upload
                  fileList={document}
                  onChange={handleDocumentUpload}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <div className="align-center justify-between pointer">
                    <span className="color-primary">{INSPECTION_DOCUMENT}</span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>
            {/*<div className="input-wrapper mt20">*/}
            {/*  <div className="input-label justify-between">*/}
            {/*    <span>{SIGNATURE}</span>*/}
            {/*  </div>*/}
            {/*  <Input*/}
            {/*    className="add-edit-input"*/}
            {/*    onChange={onChangeSignature}*/}
            {/*    value={editedVehicle.editedInspection?.signature}*/}
            {/*  />*/}
            {/*</div>*/}

            <ErrorBox obj={editedVehicle.editedInspection} />

            <FormButtonsSection
              onConfirm={onSaveChanges}
              onDiscard={onDelete}
              confirmTitle={SAVE_CHANGES}
              discardTitle={DELETE_RECORD_TEXT}
            />
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(EditInspectionModal);
