import React from 'react';
import { observer } from 'mobx-react';
import EditShipmentComponent from '../../components/brokerage/EditShipment/EditShipmentComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import {
  EDIT_SHIPMENT,
  MODAL_SUCCESS_EDIT_SHIPMENT_TEXT,
  MODAL_SUCCESS_EDIT_SHIPMENT_BUTTON_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../constants';
import { useMst } from '../../state/RootModel';
import SuccessModal from '../../components/SuccessModal';

const EditShipmentPage = () => {
  const {
    shipmentsPageState: {
      editedShipment,
      editShipmentSuccessModalOpen,
      setEditShipmentSuccessModalOpen,
    },
  } = useMst();

  const backLink = PageRoutes.ViewShipment.name;

  const params = { shipmentId: editedShipment?.shipment_id.toString() };

  return (
    <div className="page create-shipment-page">
      <BreadcrumsComponent
        page={`${EDIT_SHIPMENT}`}
        breadcrumbsBackLink={PageRoutes.Brokerage.name}
      />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_SHIPMENT_TEXT}
        buttonText={MODAL_SUCCESS_EDIT_SHIPMENT_BUTTON_TEXT}
        backLink={backLink}
        params={params}
        open={editShipmentSuccessModalOpen}
        closeModal={() => setEditShipmentSuccessModalOpen(false)}
      />
      {editedShipment && <EditShipmentComponent />}
    </div>
  );
};

export default observer(EditShipmentPage);
