import React from 'react';
import { observer } from 'mobx-react';
import { DRIVER, LAST_LOCATION, TRAILER } from '../../../../constants';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import ViewHoursOfService from '../../ViewDriver/ViewHoursOfService';
import { ConsoleSqlOutlined } from '@ant-design/icons';

const LocationInformation = ({ model, type }) => {
  const defaultCenter = [39.742043, -104.991531];
  let position;
  if (type === DRIVER) {
    if (model.connected_truck_data) {
      position = [
        model.connected_truck_data.vehicle_latitude
          ? model.connected_truck_data.vehicle_latitude
          : defaultCenter[0],
        model.connected_truck_data.vehicle_longitude
          ? model.connected_truck_data.vehicle_longitude
          : defaultCenter[1],
      ];
    } else {
      position = defaultCenter;
    }
  } else if (type === TRAILER) {
    if (model.vehicle_latitude && model.vehicle_longitude) {
      position = [model.vehicle_latitude, model.vehicle_longitude];
    } else {
      position = defaultCenter;
    }
  } else {
    if (model.vehicle_latitude && model.vehicle_longitude) {
      position = [model.vehicle_latitude, model.vehicle_longitude];
    } else {
      position = defaultCenter;
    }
  }

  return (
    <div className="edit-driver-section form-page-section w50 pl10">
      {type === DRIVER && <ViewHoursOfService model={model} />}
      <div className="form-page-subsection">
        <div className="subsection-header mt0">
          <p>{LAST_LOCATION}</p>
        </div>

        <div className="map-wrapper">
          <MapContainer
            center={position[0] && position[1] ? position : defaultCenter}
            zoom={13}
            scrollWheelZoom={false}
            className="view-fleet-map"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {position[0] && position[1] && (
              <Marker position={position}>
                {type === DRIVER && model.connected_truck_data && (
                  <Popup>
                    <p>
                      {model.connected_truck_data.internal_company_id} -{' '}
                      {model.connected_truck_data.vehicle_make}
                    </p>
                    {/*<p>{model.licence}</p>*/}
                  </Popup>
                )}
                {type !== DRIVER && (
                  <Popup>
                    <p>
                      {model.vehicle_make} {model.vehicle_model_name}
                    </p>
                    <p>{model.licence}</p>
                  </Popup>
                )}
              </Marker>
            )}
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default observer(LocationInformation);
