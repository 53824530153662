import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { useMst } from '../../../state/RootModel';
import { NO, YES, OVERWRITE_CURRENT_LOCATION } from '../../../constants';
import save from '../../../assets/images/save.svg';

const EditProbillLocationModal = () => {
  const {
    shipmentsPageState: {
      currentShipment,
      showEditedProbillModal,
      setShowEditedProbillModal,
    },
  } = useMst();

  const onCancel = () => {
    setShowEditedProbillModal(false);
  };
  const onSave = () => {
    currentShipment?.newProbill?.selectedPickupLocation.saveLocation();
  };
  return (
    <div>
      <Modal
        visible={!!showEditedProbillModal}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={360}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 probill-edit-location-modal">
          <div className="align-center">
            <img src={save} alt="save-probill-edited-location" />
            <p className="probill-modal-title ml5">
              {OVERWRITE_CURRENT_LOCATION}
            </p>
          </div>
          <div className="justify-start mt50">
            <button className="btn white mr10 add" onClick={onCancel}>
              {NO}
            </button>
            <button className="btn primary add" onClick={onSave}>
              {YES}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditProbillLocationModal);
