import React from 'react';
import { observer } from 'mobx-react';
import expanded from '../../assets/images/dispatch/expand.svg';
import expandedWhite from '../../assets/images/dispatch/expand-white.svg';
import collapseWhite from '../../assets/images/dispatch/collapse-white.svg';
import { ASSETS, ROUTE_ACTION_LIST } from '../../constants';
import { useMst } from '../../state/RootModel';
import { CURRENT_INFORMATION, SHIPMENT_LIST } from '../../constants/dispatch';
import TrackingShipmentCurrentInformation from './trackingShipment/TrackingShipmentCurrentInformation';

const DispatchHeader = () => {
  const {
    dispatchPageState: {
      setIsAssetsListOpened,
      setPlannerOpened,
      setSelectedVehicleRouteActions,
      setIsRouteActionListOpened,
      trackingShipment,
      setIsTrackingShipmentCurrentInformationOpened,
      isTrackingShipmentCurrentInformationOpened,
    },
  } = useMst();

  const onAssetsListOpen = () => {
    setIsAssetsListOpened(true);
    setPlannerOpened(false);
    setIsRouteActionListOpened(false);
    setSelectedVehicleRouteActions(null);
  };
  const onPlannerOpen = () => {
    setPlannerOpened(true);
    setIsAssetsListOpened(false);
    setIsRouteActionListOpened(false);
    setSelectedVehicleRouteActions(null);
  };
  const onRouteActionListOpened = () => {
    setIsRouteActionListOpened(true);
    setPlannerOpened(false);
    setIsAssetsListOpened(false);
    setSelectedVehicleRouteActions(null);
  };

  const onCurrentInformationOpened = () => {
    setIsTrackingShipmentCurrentInformationOpened(
      !isTrackingShipmentCurrentInformationOpened
    );
  };

  return (
    <div className="dispatch-map-header align-center">
      <div
        className="planner-wrapper align-center pl10 pr10 filter-trigger-item cursor-pointer"
        onClick={onPlannerOpen}
      >
        <p className="text-bold text-uppercase">{SHIPMENT_LIST}</p>
        <img src={expanded} alt="filter toggle" />
      </div>
      <div
        className="drivers-wrapper align-center pl10 pr10 ml10 filter-trigger-item cursor-pointer"
        onClick={onRouteActionListOpened}
      >
        <p className="text-bold text-uppercase">{ROUTE_ACTION_LIST}</p>
        <img src={expanded} alt="filter toggle" />
      </div>
      <div
        className="drivers-wrapper align-center pl10 pr10 ml10 filter-trigger-item cursor-pointer"
        onClick={onAssetsListOpen}
      >
        <p className="text-bold text-uppercase">{ASSETS}</p>
        <img src={expanded} alt="filter toggle" />
      </div>
      {trackingShipment?.length > 0 && (
        <div className="tracking-shipment__map-wrapper ml10">
          <div
            className="justify-between align-center text-bold text-uppercase tracking-shipment__top-wrapper pl10 pr10 cursor-pointer"
            onClick={onCurrentInformationOpened}
          >
            {CURRENT_INFORMATION}
            <img
              src={
                isTrackingShipmentCurrentInformationOpened
                  ? collapseWhite
                  : expandedWhite
              }
              alt="filter toggle"
            />
          </div>

          {isTrackingShipmentCurrentInformationOpened && (
            <div className="tracking-shipment__bottom-wrapper">
              <TrackingShipmentCurrentInformation data={trackingShipment} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(DispatchHeader);
