import { types } from 'mobx-state-tree';

export const EquipmentModel = types
  .model('EquipmentModel', {
    equipment_id: types.identifierNumber,
    equipment_category: types.maybe(types.maybeNull(types.string)),
    equipment_type: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
