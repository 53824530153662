import React from 'react';
import { observer } from 'mobx-react';
import ShipperInfo from './ShipperInfo';
import ReceiverInfo from './ReceiverInfo';

const GeneralTabComponent = () => {
  return (
    <div className="page-section shipment-details-section">
      <div className="content justify-between w100 mt20">
        <ShipperInfo />
        <ReceiverInfo />
      </div>
    </div>
  );
};

export default observer(GeneralTabComponent);
