import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Tabs } from 'antd';

import Reports from '../../components/reports/Reports';

import { REPORTS } from '../../constants/reports';

const { TabPane } = Tabs;

const ReportsPage = () => {
  const {
    reportsPageState: { activeTab, setActiveTab },
  } = useMst();

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="page reports-page">
      <div className="page-content pv20">
        <Tabs activeKey={activeTab} onChange={onTabChange}>
          <TabPane tab={'Reports'} key={REPORTS}>
            <Reports />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default observer(ReportsPage);
