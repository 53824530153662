import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMst } from '../../../state/RootModel';
import { observer } from 'mobx-react';

/** DOCS { https://github.com/ankeetmaini/react-infinite-scroll-component#readme } */

function InfiniteScrollWrapper({
  isInfiniteScrollOn,
  lengthData,
  functionNext,
  children,
}) {
  const {
    dispatchPageState: { allTrucksLoaded },
  } = useMst();
  if (!isInfiniteScrollOn) return <>{children}</>;

  return (
    <div
      id="infiniteScrollDiv"
      style={{
        height: 400,
        overflow: 'auto',
      }}
    >
      <InfiniteScroll
        dataLength={lengthData} //This is important field to render the next data
        next={functionNext}
        hasMore={!allTrucksLoaded}
        loader={<h4>Loading...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>No more data</b>
          </p>
        }
        scrollableTarget="infiniteScrollDiv"
      >
        {children} {/** !!! DO NOT TOUCH !!! => It wraps the table */}
      </InfiniteScroll>
    </div>
  );
}

export default observer(InfiniteScrollWrapper);
