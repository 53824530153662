import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal, DatePicker, Select, Tooltip } from 'antd';
import {
  DATE,
  ABSENCE_TYPE,
  NO_ABSENCE_TYPE_SELECTED,
  VIEW_ABSENCE_TEXT,
  ABSENCE_DOCUMENT,
  VIEW_FILE,
} from '../../../constants';
import calendar from '../../../assets/images/calendar.svg';
import moment from 'moment';

const { RangePicker } = DatePicker;

const ViewAbsenceModal = () => {
  const {
    employeesPageState: { viewedEmployee },
  } = useMst();

  const onCancel = () => {
    viewedEmployee.setViewedAbsence(null);
  };

  return (
    !!viewedEmployee?.viewedAbsence && (
      <div>
        <Modal
          visible={!!viewedEmployee.viewedAbsence}
          footer={null}
          centered={true}
          onCancel={onCancel}
          width={500}
          className="confirm-delete-modal"
          maskClosable={false}
        >
          <div className="p32 pb50">
            <div className="align-center mb20">
              <img src={calendar} alt="calendar" className="mr10" />
              <h3>{VIEW_ABSENCE_TEXT}</h3>
            </div>
            <div className="input-wrapper mb20">
              <div className="input-label justify-between">
                <span>{ABSENCE_TYPE}</span>
              </div>
              <Select
                className="w100 text-capitalize"
                value={
                  viewedEmployee?.viewedAbsence?.status &&
                  viewedEmployee.viewedAbsence.status
                }
                placeholder={
                  !viewedEmployee?.viewedAbsence?.status &&
                  NO_ABSENCE_TYPE_SELECTED
                }
                disabled
              ></Select>
            </div>
            {viewedEmployee.viewedAbsence?.status &&
              (viewedEmployee.viewedAbsence.shouldDisplayDatePicker ? (
                <div className="mt20">
                  <p>{DATE}</p>
                  <DatePicker
                    value={
                      viewedEmployee.viewedAbsence?.starting_date
                        ? moment(viewedEmployee.viewedAbsence.starting_date)
                        : null
                    }
                    disabled
                  />
                </div>
              ) : (
                <div>
                  <p>{DATE}</p>
                  <RangePicker
                    value={[
                      viewedEmployee.viewedAbsence?.starting_date
                        ? moment(viewedEmployee.viewedAbsence.starting_date)
                        : null,
                      viewedEmployee.viewedAbsence?.end_date
                        ? moment(viewedEmployee.viewedAbsence.end_date)
                        : null,
                    ]}
                    disabled
                  />
                </div>
              ))}
            {viewedEmployee.viewedAbsence?.document && (
              <div className="input-wrapper mt20">
                <div className="input-label justify-between">
                  <span>{ABSENCE_DOCUMENT}</span>
                </div>
                <Tooltip placement="top" title={VIEW_FILE}>
                  <a
                    href={viewedEmployee.viewedAbsence?.document?.document_url}
                    target="blank"
                  >
                    {viewedEmployee.viewedAbsence?.document?.name}
                  </a>
                </Tooltip>
              </div>
            )}
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(ViewAbsenceModal);
