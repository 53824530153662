import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal } from 'antd';
import { CANCEL } from '../../../constants';
import {
  CONFIRM_DELETING_ORGANIZATION,
  DELETE_ORGANIZATION,
} from '../../../constants/organizations/organizations';
import addOrganization from '../../../assets/images/add-organization.svg';

const DeleteOrganizationModal = () => {
  const {
    organizationState: {
      deleteOrganizationModalOpened,
      setDeleteOrganizationModalOpen,
      organizationToBeDeleted,
    },
  } = useMst();

  const onCancel = () => {
    setDeleteOrganizationModalOpen(false);
  };

  const onOrganizationDelete = () => {
    organizationToBeDeleted.deleteOrganization(organizationToBeDeleted.key);
  };

  return (
    <div>
      <Modal
        visible={!!deleteOrganizationModalOpened}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="p20">
          <div className="align-center mb20">
            <img src={addOrganization} alt="save" className="mr10" />
            <h3>{DELETE_ORGANIZATION}</h3>
          </div>
          <p>{CONFIRM_DELETING_ORGANIZATION}</p>
          <h3>{organizationToBeDeleted?.organization_name}</h3>

          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onOrganizationDelete}>
              {DELETE_ORGANIZATION}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(DeleteOrganizationModal);
