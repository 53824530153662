import { types } from 'mobx-state-tree';
import { SHIPMENT_DOCUMENT_TYPE } from '../../../constants/shipmentSpecificsTypes';
import { TITLE_REQUIRED } from '../../../constants';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';
import { addShipmentSpecific } from '../../../actions/shipmentSpecifics';

export const ShipmentDocumentTypeLightModel = types
  .model('ShipmentDocumentTypeLightModel', {
    type: SHIPMENT_DOCUMENT_TYPE,
    organization_id: types.maybe(types.maybeNull(types.number)),
    document_type_name: types.maybe(types.maybeNull(types.string)),
    description: types.maybe(types.maybeNull(types.string)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),

    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setDocumentTypeName(value) {
        self.document_type_name = value;
      },
      setDescription(value) {
        self.description = value;
      },
      clearModel() {
        this.setDocumentTypeName(null);
        this.setDescription(null);
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveItemError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.document_type_name) {
          this.setErrorText(TITLE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveItem() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addShipmentSpecific(self)
            .then(() => {
              rootInstance.shipmentSpecificsPageState.setNewShipmentSpecificModalOpen(
                false
              );
              rootInstance.shipmentSpecificsPageState.setNewShipmentCharge(
                null
              );
              rootInstance.shipmentSpecificsPageState.setSelectedShipmentSpecificType(
                SHIPMENT_DOCUMENT_TYPE
              );
              rootInstance.shipmentSpecificsPageState.setAddShipmentSpecificSuccessModalOpen(
                true
              );
            })
            .catch((error) => {
              this.throwSaveItemError(error);
            });
        }
      },
    };
  });
