import { types, getRoot, getSnapshot } from 'mobx-state-tree';
import { rootInstance } from '../../RootModel';
import { DocumentModel } from '../../DocumentModel';
import { MaintenanceModel } from '../MaintenanceModel';
import { MaintenanceLightModel } from '../MaintenanceLightModel';
import { InspectionModel } from '../InspectionModel';
import { InspectionLightModel } from '../InspectionLightModel';
import { RegistrationModel } from '../RegistrationModel';
import { RegistrationLightModel } from '../RegistrationLightModel';
import { truckInspectionsColumnsFunc } from '../InspectionTableColumns';
import { maintenanceColumnsFunc } from '../MaintenanceTableColumns';
import { associationColumnsFunc } from './AssociationTableColumns';
import {
  VIN_REQUIRED,
  FINANCING_TYPE_REQUIRED,
  OWNERSHIP_TYPE_REQUIRED,
  STATUS_REQUIRED,
  MAINTENANCE,
  LICENCE_PLATE_REQUIRED,
  NOT_ASSOCIATED,
  FETCH_LOCATIONS_ERROR,
  FLEET_OUT_OF_SERVICE_STATUS,
} from '../../../constants';
import { setFetchingInProgress, getErrorText } from '../../../utils/methods';
import {
  DOCUMENT_TYPE_ADDITIONAL,
  DOCUMENT_TYPE_VEHICLE_IMAGE,
  DOCUMENT_TYPE_REGISTRATION,
} from '../../../constants/documents';
import moment from 'moment';
import { VEHICLE_TYPE_TRUCK } from '../../../constants/vehicleTypes';
import {
  addRegistration,
  deleteDocument,
  editRegistration,
  uploadDocument,
  editTruckPromise,
} from '../../../promises/fleet';
import { AssociationModel } from './AssociationModel';
import { ASSOCIATION_STATUS_CURRENT } from '../../../constants/associationStatuses';
import { getAllCountries } from '../../../actions/common';
import { notification } from 'antd';

const isEqual = require('lodash.isequal');

export const TruckModel = types
  .model('TruckModel', {
    vehicle_id: types.identifierNumber,
    vehicle_type: VEHICLE_TYPE_TRUCK,
    vin: types.maybe(types.maybeNull(types.string)),
    ownership_type: types.maybe(types.maybeNull(types.string)),
    type_of_financing: types.maybe(types.maybeNull(types.string)),
    date_hired: types.maybe(types.maybeNull(types.string)),
    vehicle_model_type: types.maybe(types.maybeNull(types.string)),
    vehicle_make: types.maybe(types.maybeNull(types.string)),
    vehicle_model_name: types.maybe(types.maybeNull(types.string)),
    vehicle_production_year: types.maybe(types.maybeNull(types.number)),
    fuel_type: types.maybe(types.maybeNull(types.string)),
    vehicle_weight: types.maybe(types.maybeNull(types.number)),
    vehicle_weight_unit: types.maybe(types.maybeNull(types.string)),
    vehicle_body_class: types.maybe(types.maybeNull(types.string)),
    odometer_when_hired: types.maybe(types.maybeNull(types.number)),
    odometer_units: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    telematics_provider: types.maybe(types.maybeNull(types.string)),
    tracking_id: types.maybe(types.maybeNull(types.string)),
    ifta: types.maybe(types.maybeNull(types.string)),
    internal_company_id: types.maybe(types.maybeNull(types.string)),
    toll_provider: types.maybe(types.maybeNull(types.string)),
    toll_number: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    documents: types.map(DocumentModel),
    successModalOpen: false,
    inspections: types.map(InspectionModel),
    maintenances: types.map(MaintenanceModel),
    assigned_driver: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),
    linked_driver: types.frozen([]),
    is_archived: types.maybe(types.maybeNull(types.boolean)),

    newLicence: types.maybe(types.maybeNull(RegistrationLightModel)),
    licence: types.maybe(types.maybeNull(RegistrationModel)),

    newInspection: types.maybe(types.maybeNull(InspectionLightModel)),
    initInspection: types.maybe(types.maybeNull(InspectionLightModel)),
    ispectionToBeDeleted: types.maybe(
      types.maybeNull(types.reference(InspectionModel))
    ),
    editedInspection: types.maybe(
      types.maybeNull(types.reference(InspectionModel))
    ),

    newMaintenance: types.maybe(types.maybeNull(MaintenanceLightModel)),
    initMaintenance: types.maybe(types.maybeNull(MaintenanceLightModel)),
    editedMaintenance: types.maybe(
      types.maybeNull(types.reference(MaintenanceModel))
    ),
    maintenanceToBeDeleted: types.maybe(
      types.maybeNull(types.reference(MaintenanceModel))
    ),

    associations: types.map(AssociationModel),
    currentAssociation: types.maybe(
      types.maybeNull(types.reference(AssociationModel))
    ),

    isSaveFirstModalOpen: false,

    outOfServiceModalOpen: false,
    outOfServiceReason: types.maybe(types.maybeNull(types.string)),
    outOfServiceMtceType: types.maybe(types.maybeNull(types.string)),
    outOfServiceInspectionType: types.maybe(types.maybeNull(types.string)),
    outOfServiceMtceNote: types.maybe(types.maybeNull(types.string)),
    outOfServiceInspectionNote: types.maybe(types.maybeNull(types.string)),

    availableMtceTypes: types.frozen([]),

    vehicleFullyFetched: false,
    countries: types.frozen([]),
    available_at: types.maybe(types.maybeNull(types.string)),
    available_from: types.maybe(types.maybeNull(types.string)),
    location_description: types.maybe(types.maybeNull(types.string)),
    availableStatuses: types.frozen([]),
  })
  .views((self) => {
    return {
      get associationsArray() {
        return Array.from(self.associations.values());
      },
      get currentTrailerAssociation() {
        return this.associationsArray.find((association) => {
          return association.status === ASSOCIATION_STATUS_CURRENT;
        });
      },
      get currentTrailerAssociationDataStringified() {
        if (this.currentTrailerAssociation) {
          return `${this.currentTrailerAssociation.trailer_id}`;
        } else {
          return NOT_ASSOCIATED;
        }
      },
      get documentsArray() {
        return Array.from(self.documents.values());
      },
      get registrationDocument() {
        return [...this.documentsArray]
          .reverse()
          .find(
            (document) => document.document_type === DOCUMENT_TYPE_REGISTRATION
          );
      },
      get additionalDocuments() {
        return [...this.documentsArray].filter(
          (document) => document.document_type === DOCUMENT_TYPE_ADDITIONAL
        );
      },
      get vehicleImages() {
        return [...this.documentsArray].filter(
          (document) => document.document_type === DOCUMENT_TYPE_VEHICLE_IMAGE
        );
      },
      get isTruckAvailable() {
        return self.status === FLEET_OUT_OF_SERVICE_STATUS;
      },
      get isInMtce() {
        return self.outOfServiceReason === MAINTENANCE;
      },
      get inspectionsArray() {
        return Array.from(self.inspections.values());
      },
      get maintenancesArray() {
        return Array.from(self.maintenances.values());
      },
      get isChangedDuringEdit() {
        const snapshot = getSnapshot(self);
        return !isEqual(
          snapshot,
          rootInstance.fleetPageState.initialLoadedTruck
        );
      },
      get countriesArray() {
        return Array.from(self.countries.values());
      },
    };
  })
  .volatile((self) => {
    return {
      availableTelematicsProviders: ['NIS', 'EPS', 'JP Putevi Srbije'],
      availableTollProviders: ['BIP', 'LAV', 'MKS'],
      availableWeightUnits: ['t', 'kg', 'lb'],
      availableOutOfServiceReasons: ['maintenance', 'inspection'],
      availableInspectionTypes: [
        'Annual inspection',
        'Pre-trip inspection',
        'Emergency',
      ],
    };
  })
  .actions((self) => {
    return {
      setNotes(notes) {
        self.notes = notes;
      },
      setAvailableStatuses(statuses) {
        self.availableStatuses = statuses;
      },
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      setVehicleFullyFetched(status) {
        this.getCountriesList();
        self.vehicleFullyFetched = status;
      },
      cancelChanges() {
        rootInstance.fleetPageState.getEditedTruck(self.vehicle_id);
      },
      setAssociations(items) {
        self.associations.clear();
        items.forEach((item) => {
          self.associations.put({
            ...item,
          });
        });
      },
      setMaintenances(items) {
        self.maintenances.clear();
        items.forEach((item) => {
          self.maintenances.put({
            ...item,
          });
        });
      },
      setInitMaintenance(maintenance) {
        self.initMaintenance = maintenance;
      },
      setNewMaintenance(maintenance) {
        if (maintenance && self.isChangedDuringEdit) {
          this.setIsSaveFirstModalOpen(true);
        } else {
          self.newMaintenance = maintenance;
        }
      },
      setEditedMaintenance(maintenance_id) {
        if (maintenance_id && self.isChangedDuringEdit) {
          this.setIsSaveFirstModalOpen(true);
        } else {
          self.editedMaintenance = maintenance_id;
          if (maintenance_id) {
            self.editedMaintenance.setDate(
              moment(self.editedMaintenance.date_time).format('YYYY-MM-DD')
            );
            self.editedMaintenance.setTime(
              moment(self.editedMaintenance.date_time).format('HH:mm')
            );
          }
        }
      },
      setMaintenanceToBeDeleted(maintenance_id) {
        self.maintenanceToBeDeleted = maintenance_id;
      },
      setAvailableMtceTypes(items) {
        self.availableMtceTypes = items;
      },
      setInspections(items) {
        self.inspections.clear();
        items.forEach((item) => {
          self.inspections.put({
            ...item,
          });
        });
      },
      getInspectionsColumns() {
        return Object.values(truckInspectionsColumnsFunc(getRoot(self)));
      },
      getMaintenanceColumns() {
        return Object.values(maintenanceColumnsFunc(getRoot(self)));
      },
      getAssociationsColumns() {
        return Object.values(associationColumnsFunc(getRoot(self)));
      },
      setInspectionToBeDeleted(inspection_id) {
        self.ispectionToBeDeleted = inspection_id;
      },
      setEditedInspection(inspection_id) {
        if (inspection_id && self.isChangedDuringEdit) {
          this.setIsSaveFirstModalOpen(true);
        } else {
          self.editedInspection = inspection_id;
          if (inspection_id) {
            self.editedInspection.setDate(
              moment(self.editedInspection.inspection_date_time).format(
                'YYYY-MM-DD'
              )
            );
            self.editedInspection.setTime(
              moment(self.editedInspection.inspection_date_time).format('HH:mm')
            );
          }
        }
      },
      setIsSaveFirstModalOpen(status) {
        self.isSaveFirstModalOpen = status;
      },
      setDocuments(items) {
        self.documents.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.documents.put({
            ...item,
          });
        });
      },
      createNewLicence() {
        if (self.isChangedDuringEdit) {
          this.setIsSaveFirstModalOpen(true);
        } else {
          this.setNewLicence({
            vehicle_id: self.vehicle_id,
          });
        }
      },
      setNewLicence(licence) {
        self.newLicence = licence;
      },
      setInitInspection(inspection) {
        self.initInspection = inspection;
      },
      setNewInspection(inspection) {
        if (inspection && self.isChangedDuringEdit) {
          this.setIsSaveFirstModalOpen(true);
        } else {
          self.newInspection = inspection;
        }
      },
      setOutOfServiceModalOpen(status) {
        self.outOfServiceModalOpen = status;
      },
      setOutOfServiceReason(reason) {
        this.clearOutOfServiceForm();
        self.outOfServiceReason = reason;
      },
      setOutOfServiceMtceType(mtceType) {
        self.outOfServiceMtceType = mtceType;
      },
      setOutOfServiceInspectionType(inspectionType) {
        self.outOfServiceInspectionType = inspectionType;
      },
      setOutOfServiceMtceNote(mtceNote) {
        self.outOfServiceMtceNote = mtceNote;
      },
      setOutOfServiceInspectionNote(inspectionNote) {
        self.outOfServiceInspectionNote = inspectionNote;
      },
      clearOutOfServiceForm() {
        this.setOutOfServiceMtceType(null);
        this.setOutOfServiceInspectionType(null);
        this.setOutOfServiceMtceNote(null);
        this.setOutOfServiceInspectionNote(null);
      },
      updateVin(vin) {
        self.vin = vin;
      },
      updateOwnershipType(type) {
        self.ownership_type = type;
      },
      updateDateHired(date) {
        self.date_hired = date;
      },
      updateStatus(status) {
        self.status = status;
        self.isTruckAvailable
          ? this.setOutOfServiceModalOpen(true)
          : this.setOutOfServiceReason(null);
      },
      updateFinancingType(type) {
        self.type_of_financing = type;
      },
      updateTelematicsProvider(provider) {
        self.telematics_provider = provider;
      },
      updateTrackingId(id) {
        self.tracking_id = id;
      },
      updateIfta(ifta) {
        self.ifta = ifta;
      },
      updateTollProvider(provider) {
        self.toll_provider = provider;
      },
      updateTollNumber(number) {
        self.toll_number = number;
      },
      updateOdometer(odometer) {
        self.odometer_when_hired = odometer;
      },
      updateOdometerUnits(odometer_units) {
        self.odometer_units = odometer_units;
      },
      updateYear(year) {
        self.vehicle_production_year = year;
      },
      updateMake(make) {
        self.vehicle_make = make;
      },
      updateModel(model) {
        self.vehicle_model_name = model;
      },
      updateType(type) {
        self.vehicle_model_type = type;
      },
      updateWeight(weight) {
        self.vehicle_weight = weight;
      },
      updateWeightUnits(weight_units) {
        self.vehicle_weight_unit = weight_units;
      },
      updateClass(truck_class) {
        self.vehicle_body_class = truck_class;
      },
      updateFuelType(fuel_type) {
        self.fuel_type = fuel_type;
      },
      setLicence(licence) {
        self.licence = licence;
      },
      setSuccessModalOpen(status) {
        self.successModalOpen = status;
      },
      setErrorText(text) {
        this.setIsSaveFirstModalOpen(false);
        self.errorText = text;
      },
      throwEditTruckError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        this.setIsSaveFirstModalOpen(false);
        setFetchingInProgress(false);
      },
      validateUpdateTruckForm() {
        if (!self.vin) {
          this.setErrorText(VIN_REQUIRED);
        } else if (!self.ownership_type) {
          this.setErrorText(OWNERSHIP_TYPE_REQUIRED);
        } else if (!self.type_of_financing) {
          this.setErrorText(FINANCING_TYPE_REQUIRED);
        } else if (!self.status) {
          this.setErrorText(STATUS_REQUIRED);
        } else if (
          (self.licence && !self.licence.licence_plate) ||
          (self.newLicence && !self.newLicence.licence_plate)
        ) {
          this.setErrorText(LICENCE_PLATE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },

      updateTruck(documentsToUpload, documentsToDelete) {
        if (this.validateUpdateTruckForm()) {
          setFetchingInProgress(true);
          let promises = [];

          documentsToDelete.forEach((document) => {
            if (document.document_type) {
              promises.push(deleteDocument(self.vehicle_id, document));
            }
          });

          documentsToUpload.forEach((document) => {
            const documentType = document.originFileObj?.documentType;
            if (documentType && documentType !== DOCUMENT_TYPE_REGISTRATION) {
              promises.push(uploadDocument(self.vehicle_id, document));
            }
          });

          if (self.newLicence) {
            promises.push(addRegistration(self.newLicence, documentsToUpload));
          }

          if (
            self.licence?.vehicle_registration_id &&
            self.licence.isChangedDuringEdit
          ) {
            promises.push(editRegistration(self.licence, documentsToUpload));
          }

          promises.push(editTruckPromise(self));

          Promise.all(promises)
            .then(() => {
              this.setSuccessModalOpen(true);
              setFetchingInProgress(false);
              // self.licence.setIsChangedDuringEdit(false);
            })
            .catch((error) => {
              this.throwEditTruckError(error);
            });
        }
      },
    };
  });
