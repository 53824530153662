import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../state/RootModel';
import AsyncSelect from 'react-select/async';
import {
  ADDRESS,
  CITY,
  COUNTRY,
  PHONE_NUMBER,
  PICKUP_LOCATION,
  PICKUP_NUMBER,
  PICKUP_TIME,
  PICKUP_TYPE,
  SCHEDULED,
  SHIPPER,
  START_TYPING_PLACEHOLDER_TEXT,
  STATE,
  ZIP_CODE,
  ZONE,
} from '../../../../constants';
import { Input, Select, TimePicker, DatePicker } from 'antd';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';

const debounce = require('lodash.debounce');
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShipperInfo = () => {
  const {
    shipmentsPageState: { currentShipment },
    clientsPageState: { getFilteredClients },
  } = useMst();

  const [searchPickupLocationValue, setSearchPickupLocationValue] =
    useState('');

  const loadSuggestions = debounce(getFilteredClients, 300);

  const onSelectShipper = (client) => {
    currentShipment?.editedProbill.setShipperId(client.client_id);
  };

  const onChangePickupLocation = (locationId) => {
    const location = currentShipment?.editedProbill.pickup_locations.find(
      (item) => {
        return item.location_id === locationId;
      }
    );
    location &&
      currentShipment?.editedProbill.setSelectedPickupLocation(location);
  };

  const onSearchPickupLocation = (value) => {
    setSearchPickupLocationValue(value);
  };

  const onPickupTimeFromChange = (value, string) => {
    currentShipment?.editedProbill.setPickupTimeStartSelected(string);
  };

  const onPickupTimeToChange = (value, string) => {
    currentShipment?.editedProbill.setPickupTimeEndSelected(string);
  };

  const onPickupDatesChange = (dates) => {
    const starting_date = moment(dates[0]).format('YYYY-MM-DD');
    const end_date = moment(dates[1]).format('YYYY-MM-DD');
    currentShipment?.editedProbill.setPickupDateStartSelected(starting_date);
    currentShipment?.editedProbill.setPickupDateEndSelected(end_date);
  };

  const getDisabledFromHours = () => {
    if (currentShipment?.editedProbill?.isPickupRangeSetInSameDate) {
      const toHours = currentShipment?.editedProbill?.pickupTimeEndSelected
        ? moment(
            currentShipment?.editedProbill.pickupTimeEndSelected,
            'HH:mm'
          ).hours()
        : null;

      let hours = [];
      if (toHours) {
        for (let i = toHours + 1; i < 24; i++) {
          hours.push(i);
        }
      }

      return hours;
    }
  };
  const getDisabledToHours = () => {
    if (currentShipment?.editedProbill?.isPickupRangeSetInSameDate) {
      const fromHours = currentShipment?.editedProbill?.pickupTimeStartSelected
        ? moment(
            currentShipment?.editedProbill.pickupTimeStartSelected,
            'HH:mm'
          ).hours()
        : null;

      let hours = [];
      if (fromHours) {
        for (let i = fromHours - 1; i >= 0; i--) {
          hours.push(i);
        }
      }
      return hours;
    }
  };
  const getDisabledFromMinutes = (hour) => {
    if (currentShipment?.editedProbill?.isPickupRangeSetInSameDate) {
      const toHours = currentShipment?.editedProbill?.pickupTimeEndSelected
        ? moment(
            currentShipment?.editedProbill.pickupTimeEndSelected,
            'HH:mm'
          ).hours()
        : null;
      const toMinutes = currentShipment?.editedProbill?.pickupTimeEndSelected
        ? moment(
            currentShipment?.editedProbill.pickupTimeEndSelected,
            'HH:mm'
          ).minutes()
        : null;

      let minutes = [];

      if (hour === toHours) {
        for (let i = toMinutes + 1; i < 60; i++) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  };

  const getDisabledToMinutes = (hour) => {
    if (currentShipment?.editedProbill?.isPickupRangeSetInSameDate) {
      const fromHours = currentShipment?.editedProbill?.pickupTimeStartSelected
        ? moment(
            currentShipment?.editedProbill.pickupTimeStartSelected,
            'HH:mm'
          ).hours()
        : null;
      const fromMinutes = currentShipment?.editedProbill
        ?.pickupTimeStartSelected
        ? moment(
            currentShipment?.editedProbill.pickupTimeStartSelected,
            'HH:mm'
          ).minutes()
        : null;

      let minutes = [];

      if (hour === fromHours) {
        for (let i = fromMinutes - 1; i >= 0; i--) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  };

  const disabledDate = (value) => {
    // Can not select days before pickup start date
    if (currentShipment?.editedProbill?.deliveryDateStartSelected) {
      return (
        value >
        moment(currentShipment?.editedProbill.deliveryDateStartSelected).endOf(
          'day'
        )
      );
    }
  };

  const onPickupNumberChange = (e) => {
    currentShipment?.editedProbill.setPickupNumber(e.target.value);
  };
  const onPickupDateChange = (value, string) => {
    currentShipment?.editedProbill.setPickupDateStartSelected(string);
    currentShipment?.editedProbill.setPickupDateEndSelected(string);
  };
  const onSelectPickupType = (value) => {
    currentShipment?.editedProbill?.setPickupTimeType(value);
  };

  return (
    currentShipment?.editedProbill?.fullyFetched && (
      <div className="pickup-info w45">
        <div className="input-wrapper w100">
          <div className="input-label justify-between">
            <span>{SHIPPER}</span>
          </div>
          <AsyncSelect
            loadOptions={loadSuggestions}
            onChange={onSelectShipper}
            placeholder={START_TYPING_PLACEHOLDER_TEXT}
            defaultInputValue={
              currentShipment?.editedProbill?.selectedPickupLocation?.owner_name
            }
          />
        </div>
        <div className="input-wrapper w100 mt20">
          <div className="input-label justify-between">
            <span>{PICKUP_LOCATION}</span>
          </div>
          <Select
            disabled={!currentShipment?.editedProbill?.shipper_id}
            showSearch
            style={{ width: '100%' }}
            placeholder={
              !searchPickupLocationValue.length &&
              !currentShipment?.editedProbill?.pickup_location_id
                ? START_TYPING_PLACEHOLDER_TEXT
                : ''
            }
            optionFilterProp="children"
            onChange={onChangePickupLocation}
            onSearch={onSearchPickupLocation}
            filterOption={(input, option) =>
              option.props.children
                // @ts-ignore
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            value={
              currentShipment?.editedProbill?.selectedPickupLocation
                ?.location_id
            }
          >
            {currentShipment?.editedProbill?.pickup_locations.map((item) => {
              return (
                <Option key={item.location_id} value={item.location_id}>
                  {item.location_name}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="justify-between mt20">
          <div className="input-wrapper w70">
            <div className="input-label justify-between">
              <span>{ADDRESS}</span>
            </div>
            <Input
              className="add-edit-input"
              value={
                currentShipment?.editedProbill?.selectedPickupLocation?.address
              }
              disabled
            />
          </div>
          <div className="input-wrapper w25">
            <div className="input-label justify-between">
              <span>{ZONE}</span>
            </div>
            <Input
              className="add-edit-input"
              value={
                currentShipment?.editedProbill?.selectedPickupLocation?.zone
              }
              disabled
            />
          </div>
        </div>
        <div className="justify-between mt20">
          <div className="input-wrapper w35">
            <div className="input-label justify-between">
              <span>{ZIP_CODE}</span>
            </div>
            <Input
              className="add-edit-input"
              value={
                currentShipment?.editedProbill?.selectedPickupLocation
                  ?.postal_code
              }
              disabled
            />
          </div>
          <div className="input-wrapper w60">
            <div className="input-label justify-between">
              <span>{COUNTRY}</span>
            </div>
            <Input
              className="add-edit-input"
              value={
                currentShipment?.editedProbill?.selectedPickupLocation?.country
              }
              disabled
            />
          </div>
        </div>
        <div className="justify-between mt20">
          <div className="input-wrapper w45">
            <div className="input-label justify-between">
              <span>{STATE}</span>
            </div>
            <Input
              className="add-edit-input"
              value={
                currentShipment?.editedProbill?.selectedPickupLocation?.state
              }
              disabled
            />
          </div>
          <div className="input-wrapper w50">
            <div className="input-label justify-between">
              <span>{CITY}</span>
            </div>
            <Input
              className="add-edit-input"
              value={
                currentShipment?.editedProbill?.selectedPickupLocation?.city
              }
              disabled
            />
          </div>
        </div>
        <div className="input-wrapper w100 mt20">
          <div className="input-label justify-between">
            <span>{PHONE_NUMBER}</span>
          </div>
          <PhoneInput
            value={
              currentShipment?.editedProbill?.selectedPickupLocation
                ?.phone_number
            }
            displayInitialValueAsLocalNumber
            disabled
          />
        </div>
        <div className="input-label mt20">
          <span>{PICKUP_TYPE}</span>
          <div className="flex-alignitems-flex-start w100">
            <div className="input-wrapper w100">
              <Select
                className="w100 text-capitalize"
                onChange={onSelectPickupType}
                value={currentShipment?.editedProbill?.pickup_time_type}
              >
                {currentShipment?.editedProbill?.timeTypes.map(
                  (type, index) => (
                    <Option key={index} value={type}>
                      {type}
                    </Option>
                  )
                )}
              </Select>
            </div>
          </div>
        </div>
        <div className="input-wrapper w95 mt20">
          <div className="input-label justify-between">
            <span>{SCHEDULED}</span>
          </div>
          {['Appointment', '24/7'].includes(
            currentShipment?.editedProbill?.pickup_time_type
          ) && (
            <DatePicker
              value={
                currentShipment?.editedProbill.pickupDateStartSelected
                  ? moment(
                      currentShipment?.editedProbill.pickupDateStartSelected
                    )
                  : null
              }
              disabledDate={disabledDate}
              onChange={onPickupDateChange}
            />
          )}
          {currentShipment?.editedProbill?.pickup_time_type ===
            'Time Frame' && (
            <RangePicker
              value={[
                currentShipment?.editedProbill?.pickupDateStartSelected
                  ? moment(
                      currentShipment?.editedProbill.pickupDateStartSelected
                    )
                  : null,
                currentShipment?.editedProbill?.pickupDateEndSelected
                  ? moment(currentShipment?.editedProbill.pickupDateEndSelected)
                  : null,
              ]}
              onChange={onPickupDatesChange}
              disabledDate={disabledDate}
            />
          )}
          {currentShipment?.editedProbill?.pickup_time_type ===
            'Not Assigned' && <p>Not assigned</p>}
        </div>
        <div className="input-label justify-between mt20">
          <span>{PICKUP_TIME}</span>
        </div>
        <div className="flex-alignitems-flex-start w100">
          <div className="input-wrapper w45">
            <TimePicker
              onChange={onPickupTimeFromChange}
              disabledHours={getDisabledFromHours}
              disabledMinutes={getDisabledFromMinutes}
              allowClear={false}
              format={'HH:mm'}
              value={
                currentShipment?.editedProbill?.pickupTimeStartSelected
                  ? moment(
                      currentShipment?.editedProbill?.pickupTimeStartSelected,
                      'HH:mm'
                    )
                  : null
              }
              disabled={!currentShipment?.editedProbill.isPickupDateRangeSet}
            />
          </div>
          <div className="input-wrapper w45 ml5pc">
            {currentShipment?.editedProbill?.pickup_time_type ===
              'Time Frame' && (
              <TimePicker
                onChange={onPickupTimeToChange}
                disabledHours={getDisabledToHours}
                disabledMinutes={getDisabledToMinutes}
                format={'HH:mm'}
                value={
                  currentShipment?.editedProbill?.pickupTimeEndSelected
                    ? moment(
                        currentShipment?.editedProbill?.pickupTimeEndSelected,
                        'HH:mm'
                      )
                    : null
                }
                disabled={!currentShipment?.editedProbill.isPickupDateRangeSet}
              />
            )}
          </div>
        </div>
        <div className="input-wrapper w95 mt20">
          <div className="input-label justify-between">
            <span>{PICKUP_NUMBER}</span>
          </div>
          <Input
            className="add-edit-input"
            value={currentShipment?.editedProbill?.pickup_number}
            onChange={onPickupNumberChange}
          />
        </div>
      </div>
    )
  );
};

export default observer(ShipperInfo);
