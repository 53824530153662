import { types, getRoot, getSnapshot } from 'mobx-state-tree';
import { rootInstance } from '../RootModel';
import { notification } from 'antd';
import { DriverModel } from './drivers/DriverModel';
import { DriverLightModel } from './drivers/DriverLightModel';
import { DriverViewModel } from './drivers/DriverViewModel';
import { TruckModel } from './trucks/TruckModel';
import { TruckLightModel } from './trucks/TruckLightModel';
import { TruckViewModel } from './trucks/TruckViewModel';
import { TrailerModel } from './trailers/TrailerModel';
import { TrailerLightModel } from './trailers/TrailerLightModel';
import { TrailerViewModel } from './trailers/TrailerViewModel';
import { driversColumnsFunc } from './drivers/DriversTableColumns';
import { trucksColumnsFunc } from './trucks/TrucksTableColumns';
import { trailersColumnsFunc } from './trailers/TrailersTableColumns';
import { utilizationColumnsFunc } from './UtilizationTableColumns';
import { leasingCalculationColumnsFunc } from './LeasingCalculationTableColumns';
import {
  TRUCKS,
  TRAILERS,
  FETCH_TRUCKS_ERROR,
  FETCH_TRAILERS_ERROR,
  FETCH_VEHICLE_ERROR,
  FETCH_VEHICLE_OWNERSHIP_TYPES_ERROR,
  FETCH_VEHICLE_FINANCING_TYPES_ERROR,
  FETCH_VEHICLE_FUEL_TYPES_ERROR,
  FETCH_DRIVER_ERROR,
  DELETE_DRIVER_ERROR,
  VIEW_DRIVER,
  DELETE_VEHICLE_ERROR,
  FETCH_VEHICLE_INSPECTION_TYPES_ERROR,
  FETCH_AVAILABLE_ODOMETER_UNITS_ERROR,
  FETCH_ABSENCE_TYPES_ERROR,
  FETCH_COMPLIANCY_TYPES_ERROR,
  FETCH_WORKING_STATUSES_ERROR,
  FETCH_DRIVERS_ERROR,
  CHANGE_DRIVER_COMPENSATION_ERROR,
} from '../../constants';
import {
  addTruck,
  addTrailer,
  addDriver,
  getTrucks,
  getTrailers,
  getVehicle,
  getVehicleOwnershipTypes,
  getVehicleFinancingTypes,
  getVehicleFuelTypes,
  getDrivers,
  uploadDriverDocument,
  getDriver,
  deleteDriver,
  deleteVehicle,
  getVehicleInspectionTypes,
  getAvailableOdometerUnits,
  getDriverCompliancyTypes,
  getAllTrucks,
  getAllDriversCompensations,
} from '../../actions/fleet';
import { setFetchingInProgress, getErrorText } from '../../utils/methods';
import PageRoutes from '../../utils/PageRoutes';
import {
  EDIT_TRAILER,
  EDIT_TRUCK,
  VIEW_TRUCK,
  EDIT_DRIVER,
} from '../../constants/menu';
import {
  companyDriversAssociations,
  driverCompliances,
  driverDocumentation,
  driverLicences,
  ownerDriversAssociations,
  driverActiveLicence,
  companyDriversCurrentAssociation,
  ownerDriversCurrentAssociation,
  maintenanceTypes,
  allVehicleMaintenances,
  vehicleDocumentation,
  allVehicleInspections,
  vehicleRegistration,
  allVehicleRegistrations,
  driverAbsences,
  getVehicleAssociationsPromise,
  availableTrucksforDriverAssociation,
  vehicleLocation,
  getAllVehicleAssociationsPromise,
  getAllDriverTypes,
  getVehicleStatusesPromise,
  driverCompensations,
  changeDriversCompensationActivityPromise,
  editCompensationValueForDriverPromise,
  editCompensationDeductionForDriverPromise,
  getAllDriversCompensationsPromise,
} from '../../promises/fleet';
import {
  getAllAbsenceTypes,
  getWorkingStatuses,
} from '../../actions/employees';
import { CompensationValueModel } from '../payroll/Compensations/CompensationValueModel';
import {
  COMPENSATION_DEDUCTION_CURRENCY_REQUIRED,
  COMPENSATION_VALUE_CURRENCY_REQUIRED,
  ERROR_DELETING_COMPENSATION_VALUE_CURRENCIES,
  ERROR_FETCHING_ALL_COMPENSATIONS,
  ERROR_FETCHING_COMPENSATION_TYPES,
} from '../../constants/payroll';
import {
  getAllCompensations,
  getAllCompensationTypes,
  getCompensationCurrencies,
} from '../../actions/payroll';
import { CompensationsModel } from '../payroll/Compensations/CompensationsModel';
import { editCompensationValuePromise } from '../../promises/payroll';
import { CompensationDeductionModel } from '../payroll/Compensations/CompensationDeductionModel';
const allSettled = require('promise.allsettled');

export const FleetPageModel = types
  .model('FleetPageModel', {
    trucks: types.map(TruckModel),
    isTruckListOpen: false,
    trucksCurrentPage: 1,
    trucksPageSize: 10,
    trucksSearchTerm: '',
    trucksTotalItems: types.maybe(types.maybeNull(types.number)),

    trailers: types.map(TrailerModel),
    isTrailerListOpen: false,
    trailersCurrentPage: 1,
    trailersPageSize: 10,
    trailersSearchTerm: '',
    trailersTotalItems: types.maybe(types.maybeNull(types.number)),

    drivers: types.map(DriverModel),
    isDriverListOpen: false,
    driversCurrentPage: 1,
    driversPageSize: 10,
    driversSearchTerm: '',
    driversTotalItems: types.maybe(types.maybeNull(types.number)),

    isFleetListOpen: true,
    utilizationTableData: types.maybe(types.maybeNull(types.string)),
    leasingCalculationTableData: types.maybe(types.maybeNull(types.string)),
    addFleetErrorText: types.maybe(types.maybeNull(types.string)),

    // adding new driver
    newDriver: types.maybe(DriverLightModel),
    addDriverSuccessModalOpen: false,
    // end adding new driver

    // updating driver
    editDriverSuccessModalOpen: false,
    editedDriver: types.maybeNull(DriverModel),
    // end updating driver

    //view driver
    viewedDriver: types.maybeNull(DriverViewModel),
    // end view driver

    // delete driver
    driverToBeDeleted: types.maybeNull(types.reference(DriverModel)),
    confirmDeleteDriverModalOpen: false,
    successDeleteDriverModalOpen: false,
    // end delete driver

    // adding new truck
    newTruck: types.maybe(TruckLightModel),
    addTruckSuccessModalOpen: false,
    // end adding new truck

    // updating truck
    editedTruck: types.maybeNull(TruckModel),
    // end updating truck

    //view truck
    viewedTruck: types.maybeNull(TruckViewModel),
    // end view truck

    // delete truck
    truckToBeDeleted: types.maybeNull(types.reference(TruckModel)),
    confirmDeleteTruckModalOpen: false,
    successDeleteTruckModalOpen: false,
    // end delete truck

    // adding new trailer
    newTrailer: types.maybe(TrailerLightModel),
    addTrailerSuccessModalOpen: false,
    // end adding new trailer

    // updating trailer
    editedTrailer: types.maybeNull(TrailerModel),
    editTrailerSuccessModalOpen: false,
    // end updating trailer

    //view trailer
    viewedTrailer: types.maybeNull(TrailerViewModel),
    // end view trailer

    // delete trailer
    trailerToBeDeleted: types.maybeNull(types.reference(TrailerModel)),
    confirmDeleteTrailerModalOpen: false,
    successDeleteTrailerModalOpen: false,
    // end delete trailer

    vehicleOwnershipTypes: types.frozen([]),
    vehicleFinancingTypes: types.frozen([]),
    vehicleFuelTypes: types.frozen([]),
    inspectionTypes: types.frozen([]),
    availableOdometerUnits: types.frozen([]),
    availableAbsencesTypes: types.frozen([]),
    availableCompliancesTypes: types.frozen([]),
    driverWorkingStatuses: types.frozen([]),
    allTrucksData: types.frozen([]),
    driverTypes: types.frozen([]),

    isEditingCompensationValueModalOpen: false,
    editedValue: types.maybeNull(CompensationValueModel),

    compensationCurrencies: types.frozen([]),
    selectedCompensationStructureId: types.maybe(types.number),

    isEditingCompensationDeductionModalOpen: false,
    editedDeduction: types.maybeNull(CompensationDeductionModel),

    allCompensations: types.map(CompensationsModel),
    expandedRowKeys: types.frozen([]),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {
      // move back to model when backend is ready
      // driverTypes: [COMPANY_DRIVER, OWNER_OPERATOR],
      driverCategories: ['Highway', 'Local', 'Any'],

      // used to compare changes between initial loaded and current state of models
      initialLoadedDriver: types.maybe(types.maybeNull(DriverModel)),
      initialLoadedTruck: types.maybe(types.maybeNull(TruckModel)),
      initialLoadedTrailer: types.maybe(types.maybeNull(TrailerModel)),
    };
  })
  .views((self) => {
    return {
      get getAllCompensationsArray() {
        return Array.from(self.allCompensations.values());
      },
      get getAllCompensationCurrencies() {
        return Array.from(self.compensationCurrencies.values());
      },
      get driversArray() {
        return Array.from(self.drivers.values());
      },
      get filteredDriversArray() {
        const searchText = rootInstance.headerState.searchText.toLowerCase();
        const filteredArray = this.driversArray.filter((driver) => {
          return (
            driver.first_name.toLowerCase().includes(searchText) ||
            driver.last_name.toLowerCase().includes(searchText) ||
            driver.email.toLowerCase().includes(searchText)
          );
        });
        return filteredArray;
      },
      get trucksArray() {
        return Array.from(self.trucks.values());
      },
      get filteredTrucksArray() {
        const searchText = rootInstance.headerState.searchText.toLowerCase();
        const filteredArray = this.trucksArray.filter((truck) => {
          return (
            truck.ownership_type.toLowerCase().includes(searchText) ||
            (truck.vehicle_make &&
              truck.vehicle_make.toLowerCase().includes(searchText)) ||
            (truck.vehicle_model_name &&
              truck.vehicle_model_name.toLowerCase().includes(searchText)) ||
            truck.vin.toLowerCase().includes(searchText)
          );
        });
        return filteredArray;
      },
      get trailersArray() {
        return Array.from(self.trailers.values());
      },
      get filteredTrailersArray() {
        const searchText = rootInstance.headerState.searchText.toLowerCase();
        const filteredArray = this.trailersArray.filter((trailer) => {
          return (
            trailer.ownership_type.toLowerCase().includes(searchText) ||
            (trailer.vehicle_make &&
              trailer.vehicle_make.toLowerCase().includes(searchText)) ||
            trailer.vin.toLowerCase().includes(searchText)
          );
        });
        return filteredArray;
      },
      get utilizationTableDataArray() {
        if (self.utilizationTableData === TRUCKS) {
          return this.trucksArray;
        } else if (self.utilizationTableData === TRAILERS) {
          return this.trailersArray;
        }
        return [];
      },
      get leasingCalculationTableDataArray() {
        if (self.leasingCalculationTableData === TRUCKS) {
          return this.trucksArray;
        } else if (self.leasingCalculationTableData === TRAILERS) {
          return this.trailersArray;
        }
        return [];
      },
      get isEditDriverPage() {
        return rootInstance.router.currentView.name === EDIT_DRIVER;
      },
      get isEditTruckPage() {
        return rootInstance.router.currentView.name === EDIT_TRUCK;
      },
      get isEditTrailerPage() {
        return rootInstance.router.currentView.name === EDIT_TRAILER;
      },
      get isEditVehiclePage() {
        return (
          rootInstance.router.currentView.name === EDIT_TRUCK ||
          rootInstance.router.currentView.name === EDIT_TRAILER
        );
      },
      get editedVehicle() {
        if (self.editedTruck) {
          return self.editedTruck;
        } else if (self.editedTrailer) {
          return self.editedTrailer;
        } else {
          return false;
        }
      },
      get viewedVehicle() {
        if (self.viewedTruck) {
          return self.viewedTruck;
        } else if (self.viewedTrailer) {
          return self.viewedTrailer;
        } else {
          return false;
        }
      },
      get isTruckPage() {
        return self.editedTruck || self.viewedTruck;
      },
      get trucksDataArray() {
        return self.allTrucksData;
      },
    };
  })
  .actions((self) => {
    return {
      setAllCompensations(compensations) {
        compensations.forEach((item) => {
          self.allCompensations.put({
            ...item,
          });
        });
      },
      getCompensationStructures() {
        setFetchingInProgress(true);
        getAllCompensations()
          .then((response) => {
            this.setAllCompensations(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_COMPENSATION_TYPES,
              description: errorText,
            });
          });
      },
      setErrorText(value) {
        self.errorText = value;
      },
      setSelectedCompensationStructureId(value) {
        self.selectedCompensationStructureId = value;
      },
      setTrucksCurrentPage(number) {
        self.trucksCurrentPage = number;
      },
      setIsEditingCompensationValueModalOpen(value) {
        self.isEditingCompensationValueModalOpen = value;
      },
      setIsEditingCompensationDeductionModalOpen(value) {
        self.isEditingCompensationDeductionModalOpen = value;
      },
      setExpandedRowKeys(keys) {
        self.expandedRowKeys = keys;
      },
      setTrucksSearchTerm(text) {
        self.trucksSearchTerm = text;
      },
      getDriverWorkingStatuses() {
        setFetchingInProgress(true);
        getWorkingStatuses()
          .then((response) => {
            this.setWorkingStatuses(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_WORKING_STATUSES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setWorkingStatuses(statuses) {
        self.driverWorkingStatuses = statuses;
      },
      getAvailableCompliancesTypes() {
        setFetchingInProgress(true);
        getDriverCompliancyTypes()
          .then((response) => {
            this.setAvailableCompliancesTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_COMPLIANCY_TYPES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableCompliancesTypes(items) {
        self.availableCompliancesTypes = items;
      },
      setCompensationCurrencies(items) {
        self.compensationCurrencies = items;
      },
      getAllCurrencies() {
        setFetchingInProgress(true);
        getCompensationCurrencies()
          .then((response) => {
            this.setCompensationCurrencies(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION_VALUE_CURRENCIES,
              description: errorText,
            });
          });
      },
      getAvailableAbsenceTypes() {
        setFetchingInProgress(true);
        getAllAbsenceTypes()
          .then((response) => {
            this.setAvailableAbsencesTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_ABSENCE_TYPES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableAbsencesTypes(items) {
        self.availableAbsencesTypes = items;
      },
      // adding new driver
      setAddDriverSuccessModalOpen(status) {
        self.addDriverSuccessModalOpen = status;
      },
      setInspectionTypes(items) {
        self.inspectionTypes = items;
      },
      throwAddDriverError(error) {
        const errorText = getErrorText(error);
        self.newDriver.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateEditingCompensationValueForm() {
        if (!self.editedValue.currency) {
          this.setErrorText(COMPENSATION_VALUE_CURRENCY_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      validateEditingCompensationDeductionForm() {
        if (!self.editedDeduction.currency) {
          this.setErrorText(COMPENSATION_DEDUCTION_CURRENCY_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      setEditingValue(value) {
        self.editedValue = value;
        self.editedValue.default_value_temp = value.default_value.toString();
      },
      setEditingDeduction(value) {
        self.editedDeduction = value;
        self.editedDeduction.default_value_temp =
          value.default_value.toString();
      },
      getEditingValue() {},
      changeCompensationActivity(compensation_id) {
        setFetchingInProgress(true);
        changeDriversCompensationActivityPromise(
          self.editedDriver.driver_id,
          compensation_id
        )
          .then((response) => {
            setFetchingInProgress(false);
            this.getDriverCompensationStructuresForEditing(
              self.editedDriver.driver_id
            );
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: CHANGE_DRIVER_COMPENSATION_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      saveEditedCompensationValue() {
        if (this.validateEditingCompensationValueForm()) {
          console.log(self.editedValue);
          setFetchingInProgress(true);
          editCompensationValueForDriverPromise(
            self.editedDriver.driver_id,
            self.selectedCompensationStructureId,
            self.editedValue
          )
            .then((response) => {
              setFetchingInProgress(false);
              this.getDriverCompensationStructuresForEditing(
                self.editedDriver.driver_id
              );
              this.setIsEditingCompensationValueModalOpen(false);
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
      saveEditedCompensationDeduction() {
        if (this.validateEditingCompensationDeductionForm()) {
          setFetchingInProgress(true);
          editCompensationDeductionForDriverPromise(
            self.editedDriver.driver_id,
            self.selectedCompensationStructureId,
            self.editedDeduction
          )
            .then((response) => {
              setFetchingInProgress(false);
              this.getDriverCompensationStructuresForEditing(
                self.editedDriver.driver_id
              );
              this.setIsEditingCompensationDeductionModalOpen(false);
            })
            .catch((error) => {
              setFetchingInProgress(false);
              console.log('Error: ', error);
              const errorText = getErrorText(error);
              notification.error({
                message: 'Error',
                description: errorText,
              });
            });
        }
      },
      addDriver(documents) {
        if (self.newDriver.validateForm()) {
          setFetchingInProgress(true);
          addDriver(self.newDriver)
            .then((driver) => {
              if (documents?.length) {
                let promises = [];
                documents.forEach((document) => {
                  promises.push(
                    new Promise((resolve, reject) => {
                      const formData = new FormData();
                      const documentType = document.originFileObj.documentType;
                      formData.append('file', document.originFileObj);
                      uploadDriverDocument(
                        driver.data.driver_id,
                        formData,
                        documentType
                      )
                        .then((response) => {
                          resolve(response);
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    })
                  );
                });
                Promise.all(promises)
                  .then(() => {
                    setFetchingInProgress(false);
                    this.setAddDriverSuccessModalOpen(true);
                  })
                  .catch((error) => {
                    this.throwAddDriverError(error);
                  });
              } else {
                setFetchingInProgress(false);
                this.setAddDriverSuccessModalOpen(true);
              }
            })
            .catch((error) => {
              this.throwAddDriverError(error);
            });
        }
      },
      // end adding new driver

      // viewing driver
      getViewedDriver(id) {
        setFetchingInProgress(true);
        getDriver(id)
          .then((driver_data) => {
            const driver = driver_data.data;
            this.setViewedDriver(driver);

            let promises = [
              driverDocumentation(self.viewedDriver),
              driverLicences(self.viewedDriver),
              driverCompliances(self.viewedDriver),
              driverAbsences(self.viewedDriver),
              companyDriversAssociations(self.viewedDriver),
              // driverCompensations(self.viewedDriver),
              // driverCurrentAbsence(self.viewedDriver),
            ];
            // if (self.viewedDriver.isOwnerOperator) {
            //   promises.push(ownerDriversAssociations(self.viewedDriver));
            // } else if (self.viewedDriver.isCompanyDriver) {
            //   promises.push(companyDriversAssociations(self.viewedDriver));
            // }
            allSettled(promises).then(() => {
              this.getDriverCompensationStructures(self.viewedDriver.driver_id);
              self.viewedDriver.setDriverFullyFetched(true);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchDriverError(error);
          });
      },
      setViewedDriver(driver) {
        self.viewedDriver = driver;
      },
      // end viewing driver

      // updating driver
      setEditDriverSuccessModalOpen(status) {
        self.editDriverSuccessModalOpen = status;
      },
      throwFetchDriverError(error) {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_DRIVER_ERROR,
          description: errorText,
        });
        setFetchingInProgress(false);
        rootInstance.router.setView(
          rootInstance.router.views.get(PageRoutes.Fleet.name)
        );
      },
      setInitialLoadedDriver(driver) {
        self.initialLoadedDriver = driver;
      },
      setDriverTypes(types) {
        self.driverTypes = types;
      },
      getEditedDriver(id) {
        setFetchingInProgress(true);
        getDriver(id)
          .then((driver_data) => {
            const driver = driver_data.data;
            this.setEditedDriver(driver);
            let promises = [
              driverDocumentation(self.editedDriver),
              driverActiveLicence(self.editedDriver),
              driverCompliances(self.editedDriver),
              driverAbsences(self.editedDriver),
              availableTrucksforDriverAssociation(self.editedDriver),
              getAllDriverTypes(self),
            ];
            if (self.editedDriver.isOwnerOperator) {
              promises.push(ownerDriversCurrentAssociation(self.editedDriver));
            } else if (self.editedDriver.isCompanyDriver) {
              promises.push(
                companyDriversCurrentAssociation(self.editedDriver)
              );
            }

            allSettled(promises).then(() => {
              self.editedDriver.getCountriesList();
              self.editedDriver.setDriverFullyFetched(true);
              this.getDriverCompensationStructuresForEditing(
                self.editedDriver.driver_id
              );
              const snapshot = getSnapshot(self.editedDriver);
              this.setInitialLoadedDriver(snapshot);

              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchDriverError(error);
          });
      },
      setEditedDriver(driver) {
        self.editedDriver = driver;
      },
      // end updating driver

      // delete driver
      setDriverToBeDeleted(driver_id) {
        self.driverToBeDeleted = null;
        if (driver_id) {
          this.setConfirmDeleteDriverModalOpen(true);
        }
        self.driverToBeDeleted = driver_id;
      },
      setConfirmDeleteDriverModalOpen(status) {
        if (!status) {
          this.setDriverToBeDeleted(null);
        }
        self.confirmDeleteDriverModalOpen = status;
      },
      deleteDriver() {
        setFetchingInProgress(true);
        deleteDriver(self.driverToBeDeleted.driver_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteDriverModalOpen(false);
            this.setDeleteDriverSuccessModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_DRIVER_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDeleteDriverSuccessModalOpen(status) {
        self.successDeleteDriverModalOpen = status;
      },
      // end delete driver

      // adding new truck
      setAddTruckSuccessModalOpen(status) {
        self.addTruckSuccessModalOpen = status;
      },
      addTruck() {
        if (self.newTruck.validateForm()) {
          setFetchingInProgress(true);
          addTruck(self.newTruck)
            .then((response) => {
              setFetchingInProgress(false);
              this.setAddTruckSuccessModalOpen(true);
            })
            .catch((error) => {
              const errorText = getErrorText(error);
              self.newTruck.setErrorText(errorText);
              setFetchingInProgress(false);
            });
        }
      },
      // end adding new truck

      // updating truck
      throwFetchVehicleError(error) {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_VEHICLE_ERROR,
          description: errorText,
        });
        setFetchingInProgress(false);
        rootInstance.router.setView(
          rootInstance.router.views.get(PageRoutes.Fleet.name)
        );
      },
      setInitialLoadedTruck(truck) {
        self.initialLoadedTruck = truck;
      },
      getEditedTruck(id) {
        setFetchingInProgress(true);
        getVehicle(id)
          .then((vehicle) => {
            const truck = vehicle.data;
            this.setEditedTruck(truck);
            let promises = [
              maintenanceTypes(self.editedTruck),
              allVehicleMaintenances(self.editedTruck),
              vehicleDocumentation(self.editedTruck),
              allVehicleInspections(self.editedTruck),
              vehicleRegistration(self.editedTruck),
              getVehicleAssociationsPromise(self.editedTruck),
              getVehicleStatusesPromise(self.editedTruck, 'truck'),
              self.editedTruck.getCountriesList(),
            ];
            allSettled(promises).then(() => {
              self.editedTruck.setVehicleFullyFetched(true);
              const snapshot = getSnapshot(self.editedTruck);
              this.setInitialLoadedTruck(snapshot);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchVehicleError(error);
          });
      },
      setEditedTruck(truck) {
        self.editedTruck = truck;
      },
      // end updating truck

      // delete truck
      setTruckToBeDeleted(truck_id) {
        if (truck_id) {
          this.setConfirmDeleteTruckModalOpen(true);
        }
        self.truckToBeDeleted = truck_id;
      },
      setConfirmDeleteTruckModalOpen(status) {
        if (!status) {
          this.setTruckToBeDeleted(null);
        }
        self.confirmDeleteTruckModalOpen = status;
      },
      setDeleteTruckSuccessModalOpen(status) {
        self.successDeleteTruckModalOpen = status;
      },
      deleteTruck() {
        setFetchingInProgress(true);
        deleteVehicle(self.truckToBeDeleted.vehicle_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteTruckModalOpen(false);
            this.setDeleteTruckSuccessModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_VEHICLE_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      // end delete truck

      // view truck
      getViewedTruck(id) {
        setFetchingInProgress(true);
        getVehicle(id)
          .then((vehicle) => {
            const truck = vehicle.data;
            this.setViewedTruck(truck);
            let promises = [
              vehicleDocumentation(self.viewedTruck),
              allVehicleMaintenances(self.viewedTruck),
              allVehicleInspections(self.viewedTruck),
              allVehicleRegistrations(self.viewedTruck),
              vehicleLocation(self.viewedTruck),
              getVehicleAssociationsPromise(self.viewedTruck),
            ];
            allSettled(promises).then(() => {
              self.viewedTruck.setVehicleFullyFetched(true);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchVehicleError(error);
          });
      },
      setViewedTruck(truck) {
        self.viewedTruck = truck;
      },
      // end view truck

      // adding new trailer
      setAddTrailerSuccessModalOpen(status) {
        self.addTrailerSuccessModalOpen = status;
      },
      addTrailer() {
        if (self.newTrailer.validateForm()) {
          setFetchingInProgress(true);
          addTrailer(self.newTrailer)
            .then((response) => {
              setFetchingInProgress(false);
              this.setAddTrailerSuccessModalOpen(true);
            })
            .catch((error) => {
              const errorText = getErrorText(error);
              self.newTrailer.setErrorText(errorText);
              setFetchingInProgress(false);
            });
        }
      },
      // end adding new trailer

      // updating trailer
      setEditTrailerSuccessModalOpen(status) {
        self.editTrailerSuccessModalOpen = status;
      },
      setInitialLoadedTrailer(trailer) {
        self.initialLoadedTrailer = trailer;
      },
      getEditedTrailer(id) {
        setFetchingInProgress(true);
        getVehicle(id)
          .then((vehicle) => {
            const trailer = vehicle.data;
            this.setEditedTrailer(trailer);
            let promises = [
              maintenanceTypes(self.editedTrailer),
              allVehicleMaintenances(self.editedTrailer),
              vehicleDocumentation(self.editedTrailer),
              allVehicleInspections(self.editedTrailer),
              vehicleRegistration(self.editedTrailer),
              getVehicleStatusesPromise(self.editedTrailer, 'trailer'),
              self.editedTrailer.getCountriesList(),
              self.editedTrailer.getEquipments(),
            ];
            allSettled(promises).then(() => {
              self.editedTrailer.setVehicleFullyFetched(true);
              const snapshot = getSnapshot(self.editedTrailer);
              this.setInitialLoadedTrailer(snapshot);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchVehicleError(error);
          });
      },
      setEditedTrailer(trailer) {
        self.editedTrailer = trailer;
      },
      // end updating trailer

      // view trailer
      getViewedTrailer(id) {
        setFetchingInProgress(true);
        getVehicle(id)
          .then((vehicle) => {
            const trailer = vehicle.data;
            this.setViewedTrailer(trailer);
            // let promises = [];
            let promises = [
              vehicleDocumentation(self.viewedTrailer),
              allVehicleMaintenances(self.viewedTrailer),
              allVehicleInspections(self.viewedTrailer),
              allVehicleRegistrations(self.viewedTrailer),
              getVehicleAssociationsPromise(self.viewedTrailer),
              getAllVehicleAssociationsPromise(self.viewedTrailer),
            ];
            allSettled(promises).then(() => {
              self.viewedTrailer.setVehicleFullyFetched(true);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            this.throwFetchVehicleError(error);
          });
      },
      setViewedTrailer(trailer) {
        self.viewedTrailer = trailer;
      },
      // end view trailer

      // delete trailer
      setTrailerToBeDeleted(trailer_id) {
        if (trailer_id) {
          this.setConfirmDeleteTrailerModalOpen(true);
        }
        self.trailerToBeDeleted = trailer_id;
      },
      setConfirmDeleteTrailerModalOpen(status) {
        if (!status) {
          this.setTrailerToBeDeleted(null);
        }
        self.confirmDeleteTrailerModalOpen = status;
      },
      setDeleteTrailerSuccessModalOpen(status) {
        self.successDeleteTrailerModalOpen = status;
      },
      deleteTrailer() {
        setFetchingInProgress(true);
        deleteVehicle(self.trailerToBeDeleted.vehicle_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteTrailerModalOpen(false);
            this.setDeleteTrailerSuccessModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_VEHICLE_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      // end delete trailer

      setAddFleetErrorText(text) {
        self.addFleetErrorText = text;
      },
      setLeasingCalculationTableData(type) {
        self.leasingCalculationTableData = type;
      },
      setUtilizationTableData(type) {
        self.utilizationTableData = type;
      },
      setIsFleetListOpen(status) {
        self.isFleetListOpen = status;
      },
      getFleet() {
        this.getDrivers({});
        this.getTrucks({});
        this.getTrailers();
      },
      setIsDriverListOpen(status) {
        self.isDriverListOpen = status;
        if (status) {
          this.getDrivers({});
        }
      },
      setIsTruckListOpen(status) {
        self.isTruckListOpen = status;
      },
      setIsTrailerListOpen(status) {
        self.isTrailerListOpen = status;
      },
      async getDrivers({ page = null, pageSize = 10 }) {
        setFetchingInProgress(true);
        try {
          page && this.setDriversCurrentPage(page);
          pageSize && this.setDriversPageSize(pageSize);
          const drivers = await getDrivers(
            self.driversSearchTerm,
            self.driversCurrentPage,
            self.driversPageSize
          );
          this.setDrivers(drivers.data.drivers);
          this.setDriversTotalItems(drivers.data.total);
        } catch (error) {
          const errorText = getErrorText(error);
          notification.error({
            message: FETCH_DRIVERS_ERROR,
            description: errorText,
          });
        } finally {
          setFetchingInProgress(false);
        }
      },
      setDrivers(items) {
        self.drivers.clear();
        items.forEach((item) => {
          self.drivers.put({
            ...item,
          });
        });
      },
      clearDrivers() {
        self.drivers.clear();
      },
      setDriversTotalItems(number) {
        self.driversTotalItems = number;
      },
      setDriversCurrentPage(number) {
        self.driversCurrentPage = number;
      },
      setDriversSearchTerm(text) {
        self.driversSearchTerm = text;
      },
      setDriversPageSize(number) {
        self.driversPageSize = number;
      },
      getDriversColumns() {
        return Object.values(driversColumnsFunc(getRoot(self)));
      },
      getTrucksColumns() {
        return Object.values(trucksColumnsFunc(getRoot(self)));
      },
      getTrailersColumns() {
        return Object.values(trailersColumnsFunc(getRoot(self)));
      },
      getUtilizationColumns() {
        return Object.values(utilizationColumnsFunc(getRoot(self)));
      },
      getLeasingCalculationColumns() {
        return Object.values(leasingCalculationColumnsFunc(getRoot(self)));
      },
      beforeFleetRouteEnter() {
        this.getFleet();
        this.setUtilizationTableData(TRUCKS);
        this.setLeasingCalculationTableData(TRUCKS);
        this.getAllTrucksData();
      },
      setTrucksData(trucks) {
        self.allTrucksData = trucks;
      },
      getAllTrucksData() {
        getAllTrucks().then((res) => {
          this.setTrucksData(res.data.vehicles);
        });
      },
      beforeAddTruckRouteEnter() {
        !self.vehicleOwnershipTypes.length && this.getVehicleOwnershipTypes();
        !self.vehicleFinancingTypes.length && this.getVehicleFinancingTypes();
        !self.vehicleFuelTypes.length && this.getVehicleFuelTypes();
        !self.availableOdometerUnits.length && this.getAvailableOdometerUnits();
        self.newTruck.setWeightUnits(self.newTruck.availableWeightUnits[1]);
      },
      beforeEditTruckRouteEnter(id) {
        !self.vehicleOwnershipTypes.length && this.getVehicleOwnershipTypes();
        !self.vehicleFinancingTypes.length && this.getVehicleFinancingTypes();
        !self.vehicleFuelTypes.length && this.getVehicleFuelTypes();
        !self.inspectionTypes.length && this.getVehicleInspectionTypes();
        !self.availableOdometerUnits.length && this.getAvailableOdometerUnits();
        this.getEditedTruck(id);
      },
      beforeEditTruckRouteExit() {
        rootInstance.router.nextView?.name !== EDIT_TRUCK &&
          this.setEditedTruck(null);
      },
      beforeViewTruckRouteEnter(id) {
        this.getViewedTruck(id);
      },
      beforeViewTruckRouteExit() {
        rootInstance.router.nextView?.name !== VIEW_TRUCK &&
          this.setViewedTruck(null);
      },
      beforeAddTrailerRouteEnter() {
        !self.vehicleOwnershipTypes.length && this.getVehicleOwnershipTypes();
        !self.vehicleFinancingTypes.length && this.getVehicleFinancingTypes();
        self.newTrailer.setWeightUnits(self.newTrailer.availableWeightUnits[1]);
      },
      beforeEditTrailerRouteEnter(id) {
        !self.vehicleOwnershipTypes.length && this.getVehicleOwnershipTypes();
        !self.vehicleFinancingTypes.length && this.getVehicleFinancingTypes();
        !self.inspectionTypes.length && this.getVehicleInspectionTypes();
        this.getEditedTrailer(id);
      },
      beforeEditTrailerRouteExit() {
        rootInstance.router.nextView?.name !== EDIT_TRAILER &&
          this.setEditedTrailer(null);
      },
      beforeViewTrailerRouteEnter(id) {
        this.getViewedTrailer(id);
      },
      beforeViewTrailerRouteExit() {
        rootInstance.router.nextView?.name !== VIEW_TRUCK &&
          this.setViewedTrailer(null);
      },
      beforeAddDriverRouteEnter() {
        let promise = [getAllDriverTypes(self)];
        allSettled(promise).then(() => {
          !self.driverTypes.length && this.getDriverTypes();
          !self.driverCategories.length && this.getDriverCategories();
        });
      },
      beforeEditDriverRouteEnter(id) {
        !self.driverTypes.length && this.getDriverTypes();
        !self.driverCategories.length && this.getDriverCategories();
        !self.availableAbsencesTypes.length && this.getAvailableAbsenceTypes();
        !self.availableCompliancesTypes.length &&
          this.getAvailableCompliancesTypes();
        !self.driverWorkingStatuses.length && this.getDriverWorkingStatuses();
        this.getEditedDriver(id);
        this.getAllCurrencies();
        this.getCompensationStructures();
      },
      beforeEditDriverRouteExit() {
        rootInstance.router.nextView?.name !== EDIT_DRIVER &&
          this.setEditedDriver(null);
      },
      beforeViewDriverRouteEnter(id) {
        !self.availableAbsencesTypes.length && this.getAvailableAbsenceTypes();
        this.getViewedDriver(id);
      },
      beforeViewDriverRouteExit() {
        rootInstance.router.nextView?.name !== VIEW_DRIVER &&
          this.setViewedDriver(null);
      },
      getDriverCompensationStructures(id) {
        setFetchingInProgress(true);
        getAllDriversCompensations(id)
          .then((response) => {
            self.viewedDriver.setCompensations(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('greska');
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_ALL_COMPENSATIONS,
              description: errorText,
            });
          });
      },
      getDriverCompensationStructuresForEditing(id) {
        setFetchingInProgress(true);
        getAllDriversCompensations(id)
          .then((response) => {
            self.editedDriver.setCompensations(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_FETCHING_ALL_COMPENSATIONS,
              description: errorText,
            });
          });
      },
      getTrucks({ page = null }) {
        setFetchingInProgress(true);
        this.clearTrucks();
        // if page is specified in request, set current page to page requested
        page && this.setTrucksCurrentPage(page);
        getTrucks(
          self.trucksSearchTerm,
          self.trucksCurrentPage,
          self.trucksPageSize
        )
          .then((trucks) => {
            this.setTrucks(trucks.data.vehicles);
            this.setTrucksTotalItems(trucks.data.total);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_TRUCKS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setTrucks(items) {
        self.trucks.clear();
        items.forEach((item) => {
          self.trucks.put({
            ...item,
          });
        });
      },
      clearTrucks() {
        self.trucks.clear();
      },
      setTrucksTotalItems(number) {
        self.trucksTotalItems = number;
      },
      getTrailers() {
        setFetchingInProgress(true);
        this.clearTrailers();
        getTrailers(
          self.trailersSearchTerm,
          self.trailersCurrentPage,
          self.trailersPageSize
        )
          .then((response) => {
            this.setTrailers(response.data.vehicles);
            this.setTrailersTotalItems(response.data.total);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_TRAILERS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setTrailers(items) {
        self.trailers.clear();
        items.forEach((item) => {
          self.trailers.put({
            ...item,
          });
        });
      },
      clearTrailers() {
        self.trailers.clear();
      },
      setTrailersTotalItems(number) {
        self.trailersTotalItems = number;
      },
      setTrailersCurrentPage(number) {
        self.trailersCurrentPage = number;
      },
      setTrailersSearchTerm(text) {
        self.trailersSearchTerm = text;
      },
      getDriverTypes() {
        return self.driverTypes;
      },
      getDriverCategories() {
        return self.driverCategories;
      },
      getAvailableOdometerUnits() {
        setFetchingInProgress(true);
        getAvailableOdometerUnits()
          .then((response) => {
            this.setAvailableOdometerUnits(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_AVAILABLE_ODOMETER_UNITS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableOdometerUnits(items) {
        self.availableOdometerUnits = items;
      },
      getVehicleOwnershipTypes() {
        setFetchingInProgress(true);
        getVehicleOwnershipTypes()
          .then((response) => {
            this.setVehicleOwnershipTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_VEHICLE_OWNERSHIP_TYPES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      getVehicleInspectionTypes() {
        setFetchingInProgress(true);
        getVehicleInspectionTypes()
          .then((response) => {
            this.setInspectionTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_VEHICLE_INSPECTION_TYPES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setVehicleOwnershipTypes(types) {
        self.vehicleOwnershipTypes = types;
      },
      getVehicleFinancingTypes() {
        setFetchingInProgress(true);
        getVehicleFinancingTypes()
          .then((response) => {
            this.setVehicleFinancingTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_VEHICLE_FINANCING_TYPES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setVehicleFinancingTypes(types) {
        self.vehicleFinancingTypes = types;
      },
      getVehicleFuelTypes() {
        setFetchingInProgress(true);
        getVehicleFuelTypes()
          .then((response) => {
            this.setVehicleFuelTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_VEHICLE_FUEL_TYPES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setVehicleFuelTypes(types) {
        self.vehicleFuelTypes = types;
      },
    };
  });
