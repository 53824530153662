import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty, Pagination, notification } from 'antd';
import { useMst } from '../../state/RootModel';
import PageRoutes from '../../utils/PageRoutes';
import Link from '../../components/Link';
import {
  EMPTY_TRUCKS_TABLE_TEXT,
  TRUCKS,
  SEARCH_TRUCKS,
  PRINT_PDF,
  FETCH_TRUCKS_ERROR,
  EXPORT_TO_CSV,
  ADD_TRUCK,
} from '../../constants';
import { DebounceInput } from 'react-debounce-input';
import search from '../../assets/images/search.svg';
import { exportToPdf, getErrorText, htmlToCSV } from '../../utils/methods';
import {
  TRUCKS_TABLE_DOC_NAME,
  TRUCKS_TABLE_TITLE,
} from '../../constants/tableData';
import { getTrucksForExport } from '../../actions/fleet';

const TrucksTable = () => {
  const TABLE_CLASS_NAME = 'trucks-table';
  const {
    fleetPageState: {
      getTrucksColumns,
      filteredTrucksArray,
      trucksCurrentPage,
      trucksPageSize,
      trucksSearchTerm,
      trucksTotalItems,
      setTrucksCurrentPage,
      setTrucksSearchTerm,
      getTrucks,
      setTrucks,
      setTrucksTotalItems,
    },
    userState: { canAddVehicles },
    router,
  } = useMst();

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setTrucksSearchTerm(searchText);
    setTrucksCurrentPage(1);
    getTrucks({});
  };

  const onRowClick = (e, record) => {
    e.stopPropagation();
    const params = { truckId: record.vehicle_id };
    router.setView(router.views.get(PageRoutes.ViewTruck.name), params);
  };

  const paginationChange = (val) => {
    getTrucks({ page: val });
  };

  const handleCsvExport = async () => {
    try {
      const trucks = await getTrucksForExport(trucksSearchTerm);
      const table = document.getElementsByClassName('ant-table-fixed')[0];
      setTrucks(trucks.data.vehicles);
      setTrucksTotalItems(trucks.data.total);
      htmlToCSV(table, TABLE_CLASS_NAME, TRUCKS_TABLE_DOC_NAME);
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_TRUCKS_ERROR,
        description: errorText,
      });
    } finally {
      getTrucks({ page: 1 });
    }
  };

  const handlePDFExport = async () => {
    try {
      const trucks = await getTrucksForExport(trucksSearchTerm);

      const headersArr = [[]];
      getTrucksColumns().map((head) => {
        headersArr[0].push(head.title);
      });
      headersArr[0].pop();

      const data = trucks.data.vehicles.map((elt) => {
        return [
          elt.internal_company_id,
          elt.ownership_type.charAt(0).toUpperCase() +
            elt.ownership_type.slice(1),
          elt.vehicle_model_name ? elt.vehicle_model_name : '/',
          elt.vehicle_make ? elt.vehicle_make : '/',
          elt.assigned_driver ? elt.assigned_driver : '/',
          elt.vin,
          elt.status === 'active' ? 'Available' : 'Out of service',
        ];
      });

      exportToPdf(headersArr, data, TRUCKS_TABLE_TITLE, TRUCKS_TABLE_DOC_NAME);
    } catch (error) {
      const errorText = getErrorText(error);
      notification.error({
        message: FETCH_TRUCKS_ERROR,
        description: errorText,
      });
    } finally {
      getTrucks({ page: 1 });
    }
  };

  return (
    <div>
      <div className="table-header mb20 mt20 align-center">
        <span className="text-capitalize color-primary text-medium ml10">
          {TRUCKS}
        </span>
        <div className="search-wrapper border-bottom-search create-shipment-search align-center ml30">
          <img src={search} alt="search" className="search-icon" />
          <DebounceInput
            debounceTimeout={300}
            onChange={(e) => handleSearch(e)}
            style={{ width: 250 }}
            placeholder={SEARCH_TRUCKS}
            value={trucksSearchTerm}
          />
        </div>
        {canAddVehicles && (
          <Link className="btn light add ml30" view={PageRoutes.AddTruck.name}>
            <span>{ADD_TRUCK}</span>
          </Link>
        )}
        <button
          type="button"
          className="btn light add ml30"
          onClick={handlePDFExport}
        >
          {PRINT_PDF}
        </button>
        <button
          type="button"
          className="btn white add ml30"
          onClick={handleCsvExport}
        >
          {EXPORT_TO_CSV}
        </button>
      </div>
      <Table
        className={`${TABLE_CLASS_NAME} fleet-table`}
        rowKey={'vehicle_id'}
        columns={getTrucksColumns()}
        dataSource={filteredTrucksArray}
        pagination={false}
        onRow={(r) => ({
          onClick: (e) => onRowClick(e, r),
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_TRUCKS_TABLE_TEXT}
            />
          ),
        }}
      />
      <div className="table-footer">
        <Pagination
          onChange={paginationChange}
          total={trucksTotalItems}
          current={trucksCurrentPage}
          pageSize={trucksPageSize}
        />
      </div>
    </div>
  );
};

export default observer(TrucksTable);
