import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import SelectEmployeesDropdown from './SelectEmployeesDropdown';
import dropdownIcon from '../../assets/images/dropdown-icon.svg';
import deleteIcon from '../../assets/images/trash.svg';
import setActiveIcon from '../../assets/images/view-active.svg';
import {
  SELECT,
  ALL,
  DELETE_SELECTED_EMPLOYEES,
  REACTIVATE_SELECTED_EMPLOYEES,
} from '../../constants';
import { Checkbox, Tooltip } from 'antd';

const SelectEmployeesRow = () => {
  const {
    employeesPageState: {
      selectEmployeesDropdownOpen,
      setSelectEmployeesDropdownOpen,
      selectAllEmployees,
      selectedEmployeesArray,
      setConfirmDeleteEmployeesModalOpen,
      setConfirmReactivateEmployeesModalOpen,
    },
    headerState: { isArchivedMode },
    userState: { canRemoveUsersFromDepartment },
  } = useMst();

  const onChange = (e) => {
    selectAllEmployees(e.target.checked);
  };

  return (
    <div className="mt20 select-employees-row">
      <div className="table-title-row align-center">
        <div>
          <span className="text-capitalize color-primary text-medium">
            <Checkbox onChange={onChange} />
            <span className="ml10">
              {SELECT} {ALL}
            </span>
          </span>
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`ml100 pointer dropdown-icon ${
              selectEmployeesDropdownOpen ? 'open' : ''
            }`}
            height="14"
            onClick={() =>
              setSelectEmployeesDropdownOpen(!selectEmployeesDropdownOpen)
            }
          />
          {selectedEmployeesArray.length && canRemoveUsersFromDepartment ? (
            isArchivedMode ? (
              <Tooltip title={REACTIVATE_SELECTED_EMPLOYEES}>
                <img
                  src={setActiveIcon}
                  alt="set active"
                  className="ml50 pointer"
                  height="14"
                  onClick={() => setConfirmReactivateEmployeesModalOpen(true)}
                />
              </Tooltip>
            ) : (
              <Tooltip title={DELETE_SELECTED_EMPLOYEES}>
                <img
                  src={deleteIcon}
                  alt="delete"
                  className="ml50 pointer"
                  height="14"
                  onClick={() => setConfirmDeleteEmployeesModalOpen(true)}
                />
              </Tooltip>
            )
          ) : null}
        </div>
      </div>
      {selectEmployeesDropdownOpen && <SelectEmployeesDropdown />}
    </div>
  );
};

export default observer(SelectEmployeesRow);
