import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../state/RootModel';
import {
  CARGO_DESCRIPTION,
  CARGO_DETAILS,
  QUANTITY,
  UNIT_LOAD,
  WEIGHT,
} from '../../../../constants';
import { Input, Select } from 'antd';

const { Option } = Select;

const CargoTabComponent = () => {
  const {
    shipmentsPageState: {
      currentShipment: { editedProbill },
      availableWeightUnits,
      availableQuantityUnits,
    },
  } = useMst();

  const onSelectQuantityUnit = (unit) => {
    editedProbill.setQuantityUnit(unit);
  };

  const onChangeQuantity = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      editedProbill.setQuantity(null);
    } else {
      editedProbill.setQuantity(Number(e.target.value));
    }
  };

  const onChangeWeight = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      editedProbill.setWeight(null);
    } else {
      editedProbill.setWeight(Number(e.target.value));
    }
  };

  const onSelectWeightUnits = (unit) => {
    editedProbill.setWeightUnit(unit);
  };

  const selectUnits = (
    <Select
      className="w100 select-weight-units"
      onChange={onSelectWeightUnits}
      value={editedProbill.weight_unit}
    >
      {availableWeightUnits.map((unit, index) => (
        <Option key={index} value={unit}>
          {unit}
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header">
        <p>{CARGO_DETAILS}</p>
      </div>

      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{CARGO_DESCRIPTION}</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) => editedProbill.setDescription(e.target.value)}
          value={editedProbill.description}
        />
      </div>

      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <span className="input-label justify-between">{UNIT_LOAD}</span>
          <Select
            onChange={onSelectQuantityUnit}
            className="text-capitalize w100"
            value={editedProbill.quantity_unit}
          >
            {availableQuantityUnits.map((unit, index) => (
              <Option key={index} value={unit} className="text-capitalize">
                {unit}
              </Option>
            ))}
          </Select>
        </div>

        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{QUANTITY}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={onChangeQuantity}
            value={editedProbill.quantity}
          />
        </div>

        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{WEIGHT}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={onChangeWeight}
            value={editedProbill.weight}
            addonAfter={selectUnits}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(CargoTabComponent);
