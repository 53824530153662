import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, DatePicker, Upload } from 'antd';
import {
  LICENCE_INFORMATION,
  LICENCE_NUMBER,
  EXPIRY_DATE,
  ISSUED_IN,
  LICENCE_ATTACHMENT,
  ADD_LICENCE,
  CURRENT_LICENCE,
  NEW_LICENCE,
  REMOVE,
  ISSUED_ON,
} from '../../../constants';
import moment from 'moment';
import fileUpload from '../../../assets/images/file-upload.svg';

const LicenceInformation = ({
  licence,
  uploadLicence,
  newLicence,
  uploadNewLicence,
}) => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  // const onSelectState = (value) => {
  //   editedDriver.updateDriverLicenceState(value);
  // };

  const onLicenceExpiryDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedDriver.licence.setLicenceExpiryDate(date);
  };

  const onNewLicenceExpiryDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedDriver.newLicence.setLicenceExpiryDate(date);
  };

  const onLicenceIssuedOnChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedDriver.licence.setLicenceIssuedOn(date);
  };

  const onNewLicenceIssuedOnChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedDriver.newLicence.setLicenceIssuedOn(date);
  };

  const onAddLicence = () => {
    editedDriver.createNewLicence();
  };

  const onRemoveLicence = () => {
    uploadNewLicence({});
    editedDriver.setNewLicence(null);
  };

  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-section-title-row align-center">
        <span className="form-section-title">{LICENCE_INFORMATION}</span>
        <button
          className="btn primary ml20 ph20 pv0"
          onClick={onAddLicence}
          disabled={!!editedDriver.newLicence}
        >
          {ADD_LICENCE}
        </button>
      </div>

      {editedDriver.newLicence && (
        <div
          className={`section-content ${editedDriver.licence ? 'pb20' : ''}`}
        >
          {editedDriver.licence && (
            <p className="mb10 text-underline">{NEW_LICENCE}</p>
          )}
          <div className="align-center">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{LICENCE_NUMBER}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => {
                  editedDriver.newLicence.setLicenceNumber(e.target.value);
                }}
                className="add-edit-input"
                value={editedDriver.newLicence?.licence_number}
                required
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_IN}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => {
                  editedDriver.newLicence.setLicenceLocation(e.target.value);
                }}
                className="add-edit-input"
                value={editedDriver.newLicence?.issued_in}
                required
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_ON}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onNewLicenceIssuedOnChange}
                value={
                  editedDriver.newLicence?.issued_on
                    ? moment(editedDriver.newLicence.issued_on)
                    : editedDriver.newLicence?.issued_on
                }
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{EXPIRY_DATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onNewLicenceExpiryDateChange}
                value={
                  editedDriver.newLicence?.expiry_date
                    ? moment(editedDriver.newLicence.expiry_date)
                    : editedDriver.newLicence?.expiry_date
                }
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label">
                <Upload
                  fileList={newLicence}
                  onChange={uploadNewLicence}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <div className="align-center justify-between pointer">
                    <span className="color-primary">{LICENCE_ATTACHMENT}</span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>
            <button
              className="btn primary ml30 ph20 pv0"
              onClick={onRemoveLicence}
            >
              {REMOVE}
            </button>
          </div>
        </div>
      )}

      {editedDriver.licence && (
        <div className="section-content">
          {editedDriver.newLicence && (
            <p className="mb10 text-underline">{CURRENT_LICENCE}</p>
          )}
          <div className="align-center">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{LICENCE_NUMBER}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => {
                  editedDriver.licence.setLicenceNumber(e.target.value);
                }}
                className="add-edit-input"
                value={editedDriver.licence?.licence_number}
                disabled={!!editedDriver.newLicence}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_IN}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => {
                  editedDriver.licence.setLicenceLocation(e.target.value);
                }}
                className="add-edit-input"
                value={editedDriver.licence?.issued_in}
                disabled={!!editedDriver.newLicence}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ISSUED_ON}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onLicenceIssuedOnChange}
                value={
                  editedDriver.licence?.issued_on
                    ? moment(editedDriver.licence.issued_on)
                    : editedDriver.licence?.issued_on
                }
                disabled={!!editedDriver.newLicence}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{EXPIRY_DATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onLicenceExpiryDateChange}
                value={
                  editedDriver.licence?.expiry_date
                    ? moment(editedDriver.licence.expiry_date)
                    : editedDriver.licence?.expiry_date
                }
                disabled={!!editedDriver.newLicence}
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label">
                <Upload
                  fileList={licence}
                  onChange={uploadLicence}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                  disabled={!!editedDriver.newLicence}
                >
                  <div
                    className={`align-center justify-between ${
                      editedDriver.newLicence ? 'not-allowed' : 'pointer'
                    }`}
                  >
                    <span className="color-primary">{LICENCE_ATTACHMENT}</span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(LicenceInformation);
