import { getRoot, getSnapshot, types } from 'mobx-state-tree';
import { OrganizationLightModel } from './OrganizationLightModel';
import { organizationColumnsFunc } from './OrganizationsTableColumns';
import { OrganizationModel } from './OrganizationModel';
import {
  getOrganizationById,
  getOrganizations,
} from '../../actions/organizations';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { notification } from 'antd';
import { getAllCountries } from '../../actions/common';
import { FETCH_LOCATIONS_ERROR } from '../../constants';
import { getLocation } from '../../actions/locations';
import { getClients } from '../../promises/organizations';

export const OrganizationPageModel = types
  .model('OrganizationPageModel', {
    /** Show/hide organizations list **/
    isOrganizationsListOpened: true,

    /** Search term **/
    organizationSearchTerm: '',

    /** Add organization modal **/
    addOrganizationModalOpened: false,
    /** Edit organization modal **/
    editOrganizationModalOpened: false,
    editOrganizationAdminModalOpened: false,
    /** Delete organization modal **/
    deleteOrganizationModalOpened: false,

    newOrganization: types.maybe(OrganizationLightModel),
    editedOrganization: types.maybeNull(OrganizationModel),
    organizationToBeDeleted: types.maybe(types.reference(OrganizationModel)),
    organizations: types.map(OrganizationModel),
    organizationSuccessModalOpened: false,
    countries: types.frozen([]),
  })
  .volatile((self) => {
    return {
      initialLoadedOrganization: types.maybe(
        types.maybeNull(OrganizationModel)
      ),
    };
  })
  .views((self) => {
    return {
      get countriesArray() {
        return Array.from(self.countries.values());
      },
      get filteredStates() {
        const selectedCountry = self?.editedOrganization?.country;
        return this.countriesArray.find((country) => {
          return country.name === selectedCountry;
        });
      },
      get getOrganizations() {
        return Array.from(self.organizations.values());
      },
      get getFilteredOrganizations() {
        let search = self.organizationSearchTerm;
        return this.getOrganizations.filter((organization) => {
          return (
            organization.organization_name.includes(search) ||
            organization.organization_id === parseInt(search)
          );
        });
      },
    };
  })
  .actions((self) => {
    return {
      setOrganizationSuccessModalOpened(value) {
        self.organizationSuccessModalOpened = value;
      },
      setOrganizationListOpen(value) {
        self.isOrganizationsListOpened = value;
      },
      getOrganizationColumns() {
        return Object.values(organizationColumnsFunc(getRoot(self)));
      },
      setOrganizationSearchTerm(value) {
        self.organizationSearchTerm = value;
      },
      setAddOrganizationModalOpen(value) {
        self.addOrganizationModalOpened = value;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      setCountries(countries) {
        self.countries = countries;
      },
      /** Organization Edit **/
      setEditOrganizationAdminModalOpened(value) {
        self.editOrganizationAdminModalOpened = value;
      },
      setEditOrganizationModalOpen(value) {
        self.editOrganizationModalOpened = value;
      },
      setEditedOrganization(organization) {
        self.editedOrganization = organization;
      },
      setEditedOrganizationLogo(logo) {
        self.editedOrganization.setOrganizationLogo(logo);
      },
      setEditedOrganizationThumbnail(thumbnail) {
        self.editedOrganization.setOrganizationThumbnail(thumbnail);
      },
      setEditedOrganizationBackground(background) {
        self.editedOrganization.setOrganizationBackground(background);
      },
      setEditedOrganizationHasCompany(value) {
        self.editedOrganization.setHasCompany(value);
      },
      setEditedOrganizationDefaultLocation(location) {
        self.editedOrganization.setOrganizationDefaultLocation(location);
      },
      setInitialLoadedOrganization(organization) {
        self.initialLoadedOrganization = organization;
      },
      getEditedOrganization(organization_id) {
        setFetchingInProgress(true);
        getOrganizationById(organization_id)
          .then((response) => {
            getClients(response.data.organization_name)
              .then((client_response) => {
                this.setEditedOrganization(response.data);
                const snapshot = getSnapshot(self.editedOrganization);
                this.setInitialLoadedOrganization(snapshot);
                if (response.data?.logo_url) {
                  let logo_uid = response.data?.logo_s3_file_key
                    .split('.')[0]
                    .replace(/\D/g, '');
                  const logo = {
                    uid: parseInt(logo_uid),
                    url: response.data?.logo_url,
                    type: 'Logo',
                  };
                  this.setEditedOrganizationLogo(logo);
                }
                if (response.data?.thumbnail_url) {
                  let thumbnail_uid = response.data?.thumbnail_s3_file_key
                    .split('.')[0]
                    .replace(/\D/g, '');
                  const thumbnail = {
                    uid: parseInt(thumbnail_uid),
                    url: response.data?.thumbnail_url,
                    type: 'Thumbnail',
                  };
                  this.setEditedOrganizationThumbnail(thumbnail);
                }
                if (response.data?.background_image_url) {
                  let background_uid =
                    response.data?.background_image_s3_file_key
                      .split('.')[0]
                      .replace(/\D/g, '');
                  const background = {
                    uid: parseInt(background_uid),
                    url: response.data?.background_image_url,
                    type: 'Background Image',
                  };
                  this.setEditedOrganizationBackground(background);
                }

                if (response.data.default_location_id) {
                  getLocation(response.data.default_location_id)
                    .then((response) => {
                      this.setEditedOrganizationDefaultLocation(response.data);
                    })
                    .catch((error) => {
                      console.log('error: ');
                    });
                }
                // @ts-ignore
                if (client_response?.length > 0) {
                  this.setEditedOrganizationHasCompany(true);
                }

                setFetchingInProgress(false);
              })
              .catch((error) => {
                console.log('error: ', error);
                setFetchingInProgress(false);
              });
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
      /** Organization delete **/
      setDeleteOrganizationModalOpen(value) {
        self.deleteOrganizationModalOpened = value;
      },
      setOrganizationToBeDeleted(organization) {
        self.organizationToBeDeleted = organization;
      },
      getDeletedOrganization(organization_id) {
        let result = self.getOrganizations.filter(
          (organization) => organization.organization_id === organization_id
        )[0];
        this.setOrganizationToBeDeleted(result);
      },
      /** Getting initial organizations data **/
      setOrganizations(organizations) {
        organizations.forEach((item) => {
          self.organizations.put({
            ...item,
          });
        });
      },
      beforeEnterPage() {
        setFetchingInProgress(true);
        getOrganizations()
          .then((response) => {
            setFetchingInProgress(false);
            this.setOrganizations(response.data);
          })
          .catch((error) => {
            setFetchingInProgress(false);
            console.log('Error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error',
              description: errorText,
            });
          });
      },
    };
  });
