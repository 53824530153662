import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { EMPTY_INSPECTIONS_TABLE_TEXT } from '../../../../constants';

const ViewInspectionsTable = ({ model }) => {
  return (
    <Table
      className="mb20"
      rowKey={'inspection_id'}
      columns={model.getInspectionsColumns()}
      dataSource={model.inspectionsArray}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={EMPTY_INSPECTIONS_TABLE_TEXT}
          />
        ),
      }}
    />
  );
};

export default observer(ViewInspectionsTable);
