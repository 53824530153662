import React from 'react';
import { rootInstance } from '../../RootModel';
import {
  CODE_NAME,
  CURRENT_ACTION,
  DISTANCE,
  ETA,
  ID,
  SELECT_DRIVER,
} from '../../../constants/dispatch';
import { NOT_AVAILABLE_SHORT, STATUS } from '../../../constants';
import selectIcon from '../../../assets/images/select-icon.svg';
import { eta_format, toAmount } from '../../../utils/methods';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';
import { Tooltip } from 'antd';

const onDriverChange = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsOrganizeTourDriversModalOpen(false);
  if (rootInstance.dispatchPageState?.routeActionItem) {
    rootInstance.dispatchPageState?.routeActionItem?.setSelectedDriver(
      record.driver_id
    );
  } else {
    rootInstance.dispatchPageState?.editedRouteActionItem?.setSelectedDriver(
      record.driver_id
    );
  }
};

export const DispatchAvailableDriversTableColumns = (rootInstance) => ({
  '1': {
    title: ID,
    dataIndex: 'internal_company_id',
    key: 'internal_company_id',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '2': {
    title: CODE_NAME,
    dataIndex: 'code_name',
    key: 'code_name',
    render: (text, record) => {
      let type =
        record?.driver_type === COMPANY_DRIVER
          ? 'CD'
          : record?.driver_type === OWNER_OPERATOR
          ? 'OO'
          : NOT_AVAILABLE_SHORT;
      return (
        <p>
          <span>{text ? text : NOT_AVAILABLE_SHORT}</span>
          <span className="ml10">
            <strong
              className={`text-bold ${record?.driver_type ? 'text-blue' : ''}`}
            >
              {record?.driver_type ? type : NOT_AVAILABLE_SHORT}
            </strong>
          </span>
        </p>
      );
    },
  },
  '3': {
    title: STATUS,
    dataIndex: 'drivers_availability',
    key: 'drivers_availability',
    render: (text) => {
      return (
        <span
          className={`text-capitalize text-bold ${
            text === 'Available' ? 'text-green' : 'text-orange'
          }`}
        >
          {text ? text : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '4': {
    title: CURRENT_ACTION,
    dataIndex: 'driver_current_action',
    key: 'driver_current_action',
    render: (text, record) => {
      return (
        <span>
          {record?.driver_current_action?.name
            ? record?.driver_current_action?.name
            : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '5': {
    title: DISTANCE,
    dataIndex: 'distance',
    key: 'distance',
    render: (text) => {
      return <span>{text ? toAmount(text) + 'mi' : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '6': {
    title: ETA,
    dataIndex: 'eta',
    key: 'eta',
    render: (text) => {
      return <span>{text ? eta_format(text) : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  // '8': {
  //     title: EMPTY_MILES,
  //     dataIndex: 'empty_miles',
  //     key: 'empty_miles',
  //     width: '300px',
  //     render: (text) => {
  //         return <span>{text ? text + 'mi' : NOT_AVAILABLE_SHORT}</span>;
  //     },
  // },
  '9': {
    title: '',
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <Tooltip title={SELECT_DRIVER}>
          <img
            className="cursor-pointer"
            onClick={(e) => onDriverChange(e, record)}
            src={selectIcon}
            alt=""
          />
        </Tooltip>
      );
    },
  },
});
