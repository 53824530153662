import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Select, DatePicker } from 'antd';
import {
  TRAILER_INFORMATION,
  TYPE,
  MAKE,
  MODEL,
  YEAR,
  SELECT_YEAR,
  GROSS_WEIGHT,
} from '../../../constants';
import moment from 'moment';

const { Option } = Select;

const GeneralInformation = () => {
  const {
    fleetPageState: { editedTrailer },
  } = useMst();

  const onYearChange = (value) => {
    const year = Number(moment(value).format('YYYY'));
    editedTrailer.updateYear(year);
  };

  const onChangeWeight = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      editedTrailer.updateWeight(null);
    } else {
      editedTrailer.updateWeight(Number(e.target.value));
    }
  };

  const onSelectWeightUnits = (value) => {
    editedTrailer.updateWeightUnits(value);
  };

  const selectUnits = (
    <Select
      className="w100 select-weight-units"
      onChange={onSelectWeightUnits}
      value={editedTrailer.vehicle_weight_unit}
    >
      {editedTrailer.availableWeightUnits.map((unit, index) => (
        <Option key={index} value={unit}>
          {unit}
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="form-page-section">
      {/* truck information subsection */}

      <div className="form-page-subsection">
        <div className="subsection-header">
          <p>{TRAILER_INFORMATION}</p>
        </div>
        <div className="section-content align-center pb20">
          <div className="input-wrapper ml0">
            <div className="input-label">
              <span>{TYPE}</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={(e) => {
                editedTrailer.updateType(e.target.value);
              }}
              value={editedTrailer.vehicle_model_type}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              <span>{MAKE}</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={(e) => {
                editedTrailer.updateMake(e.target.value);
              }}
              value={editedTrailer.vehicle_make}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              <span>{MODEL}</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={(e) => {
                editedTrailer.updateModel(e.target.value);
              }}
              value={editedTrailer.vehicle_model_name}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              <span>{YEAR}</span>
            </div>
            <DatePicker
              mode={'year'}
              allowClear={false}
              value={
                editedTrailer.vehicle_production_year
                  ? moment(editedTrailer.vehicle_production_year.toString())
                  : null
              }
              onPanelChange={onYearChange}
              placeholder={SELECT_YEAR}
              format={'YYYY'}
            />
          </div>
        </div>
        <div className="section-content align-center">
          <div className="input-wrapper ml0">
            <div className="input-label">
              <span>{GROSS_WEIGHT}</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={onChangeWeight}
              value={editedTrailer.vehicle_weight}
              addonAfter={selectUnits}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformation);
