import React from 'react';
import {
  DELETE_SHIPMENT,
  EDIT_SHIPMENT,
  CUSTOMER,
  SHIPMENT_NO,
  PO_NUMBER,
  BROKER_NAME_PHONE,
  TRAILER_ID_TYPE,
  STATUS,
} from '../../constants';
import delivery from '../../assets/images/delivery.svg';
import trash from '../../assets/images/trash.svg';
import edit from '../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import PageRoutes from '../../utils/PageRoutes';
import { rootInstance } from '../RootModel';

const onClickDelivery = (e, record) => {
  e.stopPropagation();
};

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentsPageState.setShipmentToBeDeleted(record.shipment_id);
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  const params = { shipmentId: record.shipment_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditShipment.name),
    params
  );
};

const onCarrierClick = (e, record) => {
  e.stopPropagation();
  const params = { carrierId: record.carrier.carrier_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.ViewCarrier.name),
    params
  );
};
export const shipmentsColumnsFunc = (rootInstance) => ({
  '1': {
    title: SHIPMENT_NO,
    dataIndex: 'shipment_number',
    key: 'shipment_number',
  },
  '2': {
    title: CUSTOMER,
    key: 'shipment_by',
    dataIndex: 'shipment_by',
    render: (text, record) => {
      return <span>{record.shipment_by?.company}</span>;
    },
  },
  '3': {
    title: 'Carrier Company',
    key: 'company_name',
    dataIndex: 'company_name',
    render: (text, record) => {
      return (
        <span className="go-to" onClick={(e) => onCarrierClick(e, record)}>
          {record.carrier?.company_name}
        </span>
      );
    },
  },
  '4': {
    title: PO_NUMBER,
    key: 'pio_number',
    dataIndex: 'pio_number',
  },
  '5': {
    title: BROKER_NAME_PHONE,
    key: 'broker_name',
    dataIndex: 'broker_name',
    render: (text, record) => {
      return (
        <span>
          {record.broker_name} / {record.broker_phone}
        </span>
      );
    },
  },
  '6': {
    title: TRAILER_ID_TYPE,
    dataIndex: 'trailer_type',
    key: 'trailer_type',
    render: (text, record) => {
      return (
        <span>
          {record.trailer_type?.equipment_id} /{' '}
          {record.trailer_type?.equipment_type}
        </span>
      );
    },
  },
  '7': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      const shipment_status =
        rootInstance.shipmentsPageState.availableShipmentStatuses.filter(
          (status) => status.status_id === record.status
        )[0].name;
      return <span className="text-capitalize">{shipment_status}</span>;
    },
  },
  '8': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          <Tooltip title={'Delivery'}>
            <img
              src={delivery}
              alt="delete"
              className="pointer ph10"
              onClick={(e) => onClickDelivery(e, record)}
            />
          </Tooltip>
          {rootInstance.userState.canRemoveShipments && (
            <Tooltip title={DELETE_SHIPMENT}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          )}
          {rootInstance.userState.canEditShipments && (
            <div onClick={(e) => onClickEdit(e, record)}>
              <Tooltip title={EDIT_SHIPMENT}>
                <img src={edit} alt="edit" className="pointer ph10" />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
  },
});
