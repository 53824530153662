import { types } from 'mobx-state-tree';
import { notification } from 'antd';
import { rootInstance } from './RootModel';
import { BROKERAGE, EMPLOYEES, PAYROLL } from '../constants/menu';
import { ARCHIVED } from '../constants';

import {
  getNotifications,
  markNotificationAsResolved,
} from '../actions/notifications';
import { getErrorText } from '../utils/methods';

export const HeaderModel = types
  .model('HeaderModel', {
    searchText: '',

    notifications: types.frozen([]),
  })
  .views((self) => {
    return {
      get isSearchInputVisible() {
        return (
          rootInstance.router.currentView.name === EMPLOYEES ||
          rootInstance.router.currentView.name === PAYROLL
        );
      },
      get isArchivedMode() {
        return (
          rootInstance.employeesPageState.employeesDisplayedStatus === ARCHIVED
        );
      },
      get isBrokeragePage() {
        return rootInstance.router.currentView.name === BROKERAGE;
      },

      get getAllNotifications() {
        return Array.from(self.notifications.values());
      },

      get hasUnresolvedNotifications() {
        return Array.from(self.notifications.values()).some(
          (notification) => !notification.is_resolved
        );
      },
    };
  })
  .actions((self) => {
    return {
      setSearchText(text) {
        self.searchText = text;
      },

      async loadNotifications() {
        const promises = ['driver_notifications', 'vehicle_notifications'].map(
          getNotifications
        );
        try {
          const results = (await Promise.all(promises))
            .map((result) => result.data)
            .flat();
          this.setNotifications(results);
        } catch (error) {
          const errorText = getErrorText(error);
          notification.error({
            message: 'Error fetching data for notifications!',
            description: errorText,
          });
        }
      },

      setNotifications(notifications) {
        self.notifications = notifications;
      },

      async resolveNotification(notificationType, data) {
        try {
          await markNotificationAsResolved(notificationType, data);
          await this.loadNotifications();
        } catch (error) {
          const errorText = getErrorText(error);
          notification.error({
            message: 'Error while updating notification!',
            description: errorText,
          });
        }
      },
    };
  });
