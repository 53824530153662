import React from 'react';
import { observer } from 'mobx-react';
import {
  DRIVER,
  LOCATION,
  MILES,
  NOT_AVAILABLE,
  NOT_AVAILABLE_SHORT,
  TRAILER,
  TRUCK,
} from '../../../constants';
import {
  BREAK,
  CYCLE,
  DEF,
  DRIVE,
  ETA,
  FUEL,
  REMAINING,
  SHIFT,
  SPEED,
  UPCOMING,
} from '../../../constants/dispatch';
import { eta_format } from '../../../utils/methods';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';

const TrackingShipmentCurrentInformation = (props) => {
  const { data } = props;
  return (
    <div className="tracking-shipment__current-info">
      <div className="tracking-shipment__current-info-top">
        <div className="input-wrapper mb10 w100 justify-between">
          {data[0]?.trailer && (
            <div className="w50">
              <p className="text-bold text-uppercase">{TRAILER}</p>
              <span className="text-black">
                {data[0]?.trailer?.internal_company_id}
                {data[0]?.trailer?.trailer_type
                  ? ' - ' + data[0]?.trailer?.trailer_type
                  : ''}
              </span>
            </div>
          )}

          {data[0]?.truck && (
            <div className="w50">
              <p className="text-bold text-uppercase">{TRUCK}</p>
              {data[0]?.truck !== NOT_AVAILABLE && (
                <span className="text-black">
                  {data[0]?.truck?.internal_company_id}
                  {data[0]?.truck?.vehicle_make
                    ? ' - ' + data[0]?.truck?.vehicle_make
                    : ''}
                </span>
              )}
              {data[0]?.truck === NOT_AVAILABLE && (
                <span className="text-black">{NOT_AVAILABLE}</span>
              )}
            </div>
          )}
        </div>
        <div className="input-wrapper mb10 w100">
          <div className="align-center">
            <div>
              <p className="text-bold text-uppercase mr20">{FUEL}</p>
              <span className="text-black">{NOT_AVAILABLE_SHORT}</span>
            </div>
            <div>
              <p className="text-bold text-uppercase mr20">{SPEED}</p>
              <span className="text-black">
                {data[0]?.truck?.speed
                  ? data[0].truck.speed + ' ' + data[0].truck.speed_unit
                  : NOT_AVAILABLE_SHORT}
              </span>
            </div>
            <div>
              <p className="text-bold text-uppercase mr20">{DEF}</p>
              <span className="text-black">{NOT_AVAILABLE_SHORT}</span>
            </div>
          </div>
        </div>
        {data[0].current_driver && (
          <div className="input-wrapper mb10 w100 justify-between">
            <div className="w100 text-black trip-driver-details-name mb10">
              <p className="text-bold text-uppercase mr20">{DRIVER}</p>
              {data[0]?.current_driver !== NOT_AVAILABLE && (
                <>
                  <span className="text-black text-bold">
                    {data[0]?.current_driver?.code_name
                      ? data[0]?.current_driver?.code_name
                      : NOT_AVAILABLE_SHORT}
                  </span>
                  <span className="ml5 text-blue text-capitalize text-bold">
                    {data[0].current_driver?.driver_type === COMPANY_DRIVER
                      ? 'CD'
                      : data[0].current_driver?.driver_type === OWNER_OPERATOR
                      ? 'OO'
                      : NOT_AVAILABLE_SHORT}
                  </span>
                </>
              )}
              {data[0]?.current_driver === NOT_AVAILABLE && (
                <span className="text-black ">{NOT_AVAILABLE}</span>
              )}
            </div>
          </div>
        )}
        <div className="align-center">
          <div className="has-border-right mr20 pr10">
            <p className="mb10">{UPCOMING}</p>
            <p className="text-bold text-uppercase mr20">{BREAK}</p>
            <span className="text-black">
              {data[0]?.current_driver?.hos_data?.break
                ? data[0].current_driver.hos_data.break
                : NOT_AVAILABLE_SHORT}
            </span>
          </div>
          <div>
            <p className="mb10">{REMAINING}</p>
            <div className="align-center">
              <div>
                <p className="text-bold text-uppercase mr20">{DRIVE}</p>
                <span className="text-black">
                  {data[0]?.current_driver?.hos_data?.drive
                    ? data[0].current_driver.hos_data.drive
                    : NOT_AVAILABLE_SHORT}
                </span>
              </div>
              <div>
                <p className="text-bold text-uppercase mr20">{SHIFT}</p>
                <span className="text-black">
                  {data[0]?.current_driver?.hos_data?.shift
                    ? data[0].current_driver.hos_data.shift
                    : NOT_AVAILABLE_SHORT}
                </span>
              </div>
              <div>
                <p className="text-bold text-uppercase mr20">{CYCLE}</p>
                <span className="text-black">
                  {data[0]?.current_driver?.hos_data?.cycle
                    ? data[0].current_driver.hos_data.cycle
                    : NOT_AVAILABLE_SHORT}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tracking-shipment__current-info-bottom">
        {data[0]?.current_location && (
          <div className="w100 text-bold mb10">
            <p className="text-bold text-uppercase">{LOCATION}</p>
            <p className="text-black">
              {data[0]?.current_location ?? NOT_AVAILABLE_SHORT}
            </p>
          </div>
        )}
        <div className="input-wrapper w100">
          <div className="align-center">
            <div className="flex-50 align-center">
              <p className="text-bold text-uppercase mr5">{MILES}</p>
              <span className="text-black">
                {data[0]?.miles_to_delivery
                  ? data[0]?.miles_to_delivery + 'mi'
                  : NOT_AVAILABLE_SHORT}
              </span>
            </div>
            <div className="flex-50 align-center">
              <p className="text-bold text-uppercase mr5">{ETA}</p>
              <span className="text-black">
                {data[0]?.eta ? eta_format(data[0]?.eta) : NOT_AVAILABLE_SHORT}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TrackingShipmentCurrentInformation);
