import React from 'react';
import { observer } from 'mobx-react';
import AssetsWrapper from './AssetsWrapper';
import { Empty, Table } from 'antd';

const AssetsTable = ({ tableText, data, column, update }) => {
  return (
    <AssetsWrapper
      isInfiniteScrollOn={true}
      lengthData={data.length}
      functionNext={update}
    >
      <Table
        className="mb20"
        rowKey={'internal_company_id'}
        dataSource={data}
        columns={column}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={tableText}
            />
          ),
        }}
      />
    </AssetsWrapper>
  );
};

export default observer(AssetsTable);
