import React from 'react';
import { observer } from 'mobx-react';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import ViewCarrierComponent from '../../components/brokerage/Brokerage/Carriers/viewCarrier/ViewCarrierComponent';
import PageRoutes from '../../utils/PageRoutes';
import { VIEW_CARRIER } from '../../constants';
import { useMst } from '../../state/RootModel';

const ViewCarrierPage = () => {
  const {
    carriersPageState: { viewedCarrier },
  } = useMst();

  return (
    <div className="page create-shipment-page">
      <BreadcrumsComponent
        page={`${VIEW_CARRIER}`}
        breadcrumbsBackLink={PageRoutes.Brokerage.name}
      />
      {viewedCarrier && <ViewCarrierComponent />}
    </div>
  );
};

export default observer(ViewCarrierPage);
