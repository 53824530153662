import { notification } from 'antd';
import { types } from 'mobx-state-tree';
import { getClientLocations } from '../../actions/clients';
import {
  DELIVERY_DATE_REQUIRED,
  DELIVERY_LOCATION_REQUIRED,
  DELIVERY_NUMBER_REQUIRED,
  DELIVERY_TIME_REQUIRED,
  FETCH_LOCATIONS_ERROR,
  GENERAL,
  PICKUP_DATE_REQUIRED,
  PICKUP_LOCATION_REQUIRED,
  PICKUP_NUMBER_REQUIRED,
  PICKUP_TIME_REQUIRED,
  RECEIVER_REQUIRED,
  SHIPPER_REQUIRED,
  WEIGHT_REQUIRED,
  WEIGHT_UNIT_REQUIRED,
} from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { LocationModel } from '../locations/LocationModel';
import { rootInstance } from '../RootModel';
import { addProbill, getProbillTimeTypes } from '../../actions/probills';
import { getAllCountries } from '../../actions/common';

export const ProbillLightModel = types
  .model('ProbillLightModel', {
    pickup_location_id: types.maybe(types.maybeNull(types.number)),
    delivery_location_id: types.maybe(types.maybeNull(types.number)),
    scheduled_pickup_time_start: types.maybe(types.maybeNull(types.string)),
    scheduled_pickup_time_end: types.maybe(types.maybeNull(types.string)),
    pickup_time_type: types.maybe(types.maybeNull(types.string)),
    scheduled_delivery_time_start: types.maybe(types.maybeNull(types.string)),
    scheduled_delivery_time_end: types.maybe(types.maybeNull(types.string)),
    delivery_time_type: types.maybe(types.maybeNull(types.string)),
    description: types.maybe(types.maybeNull(types.string)),
    weight: types.maybe(types.maybeNull(types.number)),
    weight_unit: types.maybe(types.maybeNull(types.string)),
    quantity: types.maybe(types.maybeNull(types.number)),
    quantity_unit: types.maybe(types.maybeNull(types.string)),
    pickup_number: types.maybe(types.maybeNull(types.string)),
    delivery_number: types.maybe(types.maybeNull(types.string)),
    probill_number: types.maybe(types.maybeNull(types.string)),
    shipment_id: types.maybe(types.maybeNull(types.number)),
    organization_id: types.maybe(types.maybeNull(types.number)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),
    trip_id: types.maybe(types.maybeNull(types.number)),
    actual_pickup_time: types.maybe(types.maybeNull(types.string)),
    actual_delivery_time: types.maybe(types.maybeNull(types.string)),

    shipper_id: types.maybe(types.maybeNull(types.number)),
    pickup_locations: types.frozen([]),
    selectedPickupLocation: types.maybe(types.maybeNull(LocationModel)),
    pickupTimeStartSelected: types.maybe(types.maybeNull(types.string)),
    pickupTimeEndSelected: types.maybe(types.maybeNull(types.string)),
    pickupDateStartSelected: types.maybe(types.maybeNull(types.string)),
    pickupDateEndSelected: types.maybe(types.maybeNull(types.string)),

    receiver_id: types.maybe(types.maybeNull(types.number)),
    delivery_locations: types.frozen([]),
    selectedDeliveryLocation: types.maybe(types.maybeNull(LocationModel)),
    deliveryTimeStartSelected: types.maybe(types.maybeNull(types.string)),
    deliveryTimeEndSelected: types.maybe(types.maybeNull(types.string)),
    deliveryDateStartSelected: types.maybe(types.maybeNull(types.string)),
    deliveryDateEndSelected: types.maybe(types.maybeNull(types.string)),

    activeTab: GENERAL,
    errorText: types.maybe(types.maybeNull(types.string)),
    countries: types.frozen([]),
    states: types.frozen([]),
    timeTypes: types.frozen([]),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get isPickupDateRangeSet() {
        return self.pickupDateStartSelected && self.pickupDateEndSelected;
      },
      get isDeliveryDateRangeSet() {
        return self.deliveryDateStartSelected && self.deliveryDateEndSelected;
      },
      get isPickupRangeSetInSameDate() {
        return (
          self.pickupDateStartSelected &&
          self.pickupDateEndSelected &&
          self.pickupDateStartSelected === self.pickupDateEndSelected
        );
      },
      get isDeliveryRangeSetInSameDate() {
        return (
          self.deliveryDateStartSelected &&
          self.deliveryDateEndSelected &&
          self.deliveryDateStartSelected === self.deliveryDateEndSelected
        );
      },
      get pickupDateTimeStart() {
        return `${self.pickupDateStartSelected}T${self.pickupTimeStartSelected}Z`;
      },
      get pickupDateTimeEnd() {
        return `${self.pickupDateEndSelected}T${self.pickupTimeEndSelected}Z`;
      },
      get deliveryDateTimeStart() {
        return `${self.deliveryDateStartSelected}T${self.deliveryTimeStartSelected}Z`;
      },
      get deliveryDateTimeEnd() {
        return `${self.deliveryDateEndSelected}T${self.deliveryTimeEndSelected}Z`;
      },
      get probillShipmentId() {
        const shipment = rootInstance.shipmentsPageState.isCreateShipmentPage
          ? rootInstance.shipmentsPageState.shipmentInCreation
          : rootInstance.shipmentsPageState.isEditShipmentPage
          ? rootInstance.shipmentsPageState.editedShipment
          : null;

        return shipment.shipment_id;
      },
    };
  })
  .actions((self) => {
    return {
      setDeliveryTimeType(type) {
        self.delivery_time_type = type;
      },
      setPickupTimeType(type) {
        self.pickup_time_type = type;
      },
      setTimeTypes(types) {
        self.timeTypes = types;
      },
      getTimeTypes() {
        getProbillTimeTypes()
          .then((response) => {
            this.setTimeTypes(response.data);
          })
          .catch((error) => {
            console.log('Error: ', error);
          });
      },
      setStates(country) {
        self.states = self.countries.find((c) => {
          return c.name === country;
        });
      },
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      setActiveTab(key) {
        self.activeTab = key;
      },
      setPickupNumber(text) {
        self.pickup_number = text;
      },
      setDeliveryNumber(text) {
        self.delivery_number = text;
      },
      setDescription(text) {
        self.description = text;
      },
      setQuantityUnit(unit) {
        self.quantity_unit = unit;
      },
      setQuantity(qty) {
        self.quantity = qty;
      },
      setWeightUnit(unit) {
        self.weight_unit = unit;
      },
      setWeight(weight) {
        self.weight = weight;
      },
      setShipperId(id) {
        setFetchingInProgress(true);
        self.shipper_id = id;
        getClientLocations(self.shipper_id)
          .then((response) => {
            this.setPickupLocations(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setPickupLocations(items) {
        self.pickup_locations = items;
      },
      setReceiverId(id) {
        setFetchingInProgress(true);
        self.receiver_id = id;
        getClientLocations(self.receiver_id)
          .then((response) => {
            this.setDeliveryLocations(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDeliveryLocations(items) {
        self.delivery_locations = items;
      },
      setSelectedPickupLocation(location) {
        self.selectedPickupLocation = location;
        self.pickup_location_id = location.location_id;
      },
      setSelectedDeliveryLocation(location) {
        self.selectedDeliveryLocation = location;
        self.delivery_location_id = location.location_id;
      },
      setPickupTimeStartSelected(time) {
        self.pickupTimeStartSelected = time;
      },
      setPickupTimeEndSelected(time) {
        self.pickupTimeEndSelected = time;
      },
      setPickupDateStartSelected(time) {
        self.pickupDateStartSelected = time;
      },
      setPickupDateEndSelected(time) {
        self.pickupDateEndSelected = time;
      },
      setDeliveryTimeStartSelected(time) {
        self.deliveryTimeStartSelected = time;
      },
      setDeliveryTimeEndSelected(time) {
        self.deliveryTimeEndSelected = time;
      },
      setDeliveryDateStartSelected(time) {
        self.deliveryDateStartSelected = time;
      },
      setDeliveryDateEndSelected(time) {
        self.deliveryDateEndSelected = time;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveProbillError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateProbill() {
        if (!self.shipper_id) {
          this.setErrorText(SHIPPER_REQUIRED);
        } else if (!self.pickup_location_id) {
          this.setErrorText(PICKUP_LOCATION_REQUIRED);
        } else if (!self.pickupDateStartSelected) {
          this.setErrorText(PICKUP_DATE_REQUIRED);
        } else if (!self.pickupDateEndSelected) {
          this.setErrorText(PICKUP_DATE_REQUIRED);
        } else if (!self.pickupTimeStartSelected) {
          this.setErrorText(PICKUP_TIME_REQUIRED);
        } else if (!self.pickup_number) {
          this.setErrorText(PICKUP_NUMBER_REQUIRED);
        } else if (!self.receiver_id) {
          this.setErrorText(RECEIVER_REQUIRED);
        } else if (!self.delivery_location_id) {
          this.setErrorText(DELIVERY_LOCATION_REQUIRED);
        } else if (!self.deliveryDateStartSelected) {
          this.setErrorText(DELIVERY_DATE_REQUIRED);
        } else if (!self.deliveryDateEndSelected) {
          this.setErrorText(DELIVERY_DATE_REQUIRED);
        } else if (!self.deliveryTimeStartSelected) {
          this.setErrorText(DELIVERY_TIME_REQUIRED);
        } else if (!self.delivery_number) {
          this.setErrorText(DELIVERY_NUMBER_REQUIRED);
        } else if (!self.weight) {
          this.setErrorText(WEIGHT_REQUIRED);
        } else if (!self.weight_unit) {
          this.setErrorText(WEIGHT_UNIT_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveProbill() {
        if (this.validateProbill()) {
          switch (self.pickup_time_type) {
            case 'Appointment':
              this.setPickupDateEndSelected(null);
              this.setPickupTimeEndSelected(null);
              break;
            case '24/7':
              this.setPickupDateStartSelected(self.pickupDateStartSelected);
              this.setPickupTimeStartSelected('00:00:00');
              this.setPickupDateEndSelected(null);
              this.setPickupTimeEndSelected(null);
              break;
            case 'Not Assigned':
              this.setPickupDateStartSelected(null);
              this.setPickupTimeStartSelected(null);
              this.setPickupDateEndSelected(null);
              this.setPickupTimeEndSelected(null);
              break;
          }
          switch (self.delivery_time_type) {
            case 'Appointment':
              this.setDeliveryDateEndSelected(null);
              this.setDeliveryTimeEndSelected(null);
              break;
            case '24/7':
              this.setDeliveryDateStartSelected(self.pickupDateStartSelected);
              this.setDeliveryTimeStartSelected('00:00:00');
              this.setDeliveryDateEndSelected(null);
              this.setDeliveryTimeEndSelected(null);
              break;
            case 'Not Assigned':
              this.setDeliveryDateStartSelected(null);
              this.setDeliveryTimeStartSelected(null);
              this.setDeliveryDateEndSelected(null);
              this.setDeliveryTimeEndSelected(null);
              break;
          }
          // if (!self.pickupTimeEndSelected) {
          //   this.setPickupTimeEndSelected(self.pickupTimeStartSelected);
          // }
          // if (!self.deliveryTimeEndSelected) {
          //   this.setDeliveryTimeEndSelected(self.deliveryTimeStartSelected);
          // }

          setFetchingInProgress(true);
          addProbill(self)
            .then((response) => {
              const shipment = rootInstance.shipmentsPageState
                .isCreateShipmentPage
                ? rootInstance.shipmentsPageState.shipmentInCreation
                : rootInstance.shipmentsPageState.isEditShipmentPage
                ? rootInstance.shipmentsPageState.editedShipment
                : null;

              shipment.setNewProbill(null);
              shipment.getProbills();
            })
            .catch((error) => {
              this.throwSaveProbillError(error);
            });
        }
      },
    };
  });
