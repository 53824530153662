import { observer } from 'mobx-react';
import React from 'react';
import { ADD_COMPENSATION, DISCARD_CHANGES } from '../../../constants';
import FormPageTitle from '../../FormPageTitle';
import addCompensationIcon from '../../../assets/images/add-file.svg';
import ErrorBox from '../../ErrorBox';
import { rootInstance, useMst } from '../../../state/RootModel';
import FormButtonsSection from '../../FormButtonsSection';
import PageRoutes from '../../../utils/PageRoutes';
import CompensationValuesSection from './CompensationValuesSection';
import CompensationDeductionsSection from './CompensationDeductionsSection';
import CompensationStructureInformation from './CompensationStructureInformation';

const AddCompensationComponent = () => {
  const {
    payrollPageState: { newCompensationStructure },
  } = useMst();

  const discardChanges = () => {
    newCompensationStructure.clearForm();
    newCompensationStructure.setNewCompensationDeductions([]);
    newCompensationStructure.setNewCompensationValues([]);

    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.Payroll.name)
    );
  };

  const addNewCompensation = () => {
    newCompensationStructure.saveNewCompensationStructure();
  };

  return (
    <div className="add-edit-form-component">
      <FormPageTitle image={addCompensationIcon} title={ADD_COMPENSATION} />

      <CompensationStructureInformation />

      <CompensationValuesSection />

      <CompensationDeductionsSection />

      <div className="align-center">
        <FormButtonsSection
          onConfirm={addNewCompensation}
          onDiscard={discardChanges}
          confirmTitle={ADD_COMPENSATION}
          discardTitle={DISCARD_CHANGES}
        />
        <div className="ml40">
          <ErrorBox obj={newCompensationStructure} />
        </div>
      </div>
    </div>
  );
};

export default observer(AddCompensationComponent);
