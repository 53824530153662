import { types } from 'mobx-state-tree';

export const OrganizationImageModel = types
  .model('OrganizationImageModel', {
    uid: types.identifierNumber,
    url: types.maybe(types.maybeNull(types.string)),
    type: types.maybe(types.maybeNull(types.string)),
    isChangedDuringEdit: false,
  })
  .actions((self) => {
    return {
      setIsChangedDuringEdit(value) {
        self.isChangedDuringEdit = value;
      },
    };
  });
