import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Empty, Table } from 'antd';
import collapse from '../../../assets/images/dispatch/collapse.svg';
import { ROUTE_ACTION_LIST } from '../../../constants';
import { EMPTY_AVAILABLE_ROUTE_ACTIONS_TABLE_TEXT } from '../../../constants/dispatch';

const RouteActionsList = () => {
  const {
    dispatchPageState: {
      setIsRouteActionListOpened,
      getRouteActionListDataValues,
      getRouteActionListTableColumns,
    },
  } = useMst();

  const onRouteActionListHide = () => {
    setIsRouteActionListOpened(false);
  };

  const expandIcon = () => {
    return null;
  };

  return (
    <div className="driver-trailer-list assets-list ph10">
      <div className="align-center mt20 mb20">
        <p className="color-title text-bold text-uppercase">
          {ROUTE_ACTION_LIST}
        </p>
        <img
          src={collapse}
          alt="collapse shipments list"
          className="ml-a"
          style={{ cursor: 'pointer' }}
          onClick={onRouteActionListHide}
        />
      </div>
      <Table
        rowKey={'route_action_id'}
        dataSource={getRouteActionListDataValues}
        columns={getRouteActionListTableColumns()}
        pagination={false}
        expandRowByClick={true}
        expandIcon={expandIcon}
        // scroll={{ y: 500 }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_AVAILABLE_ROUTE_ACTIONS_TABLE_TEXT}
            />
          ),
        }}
      />
    </div>
  );
};
export default observer(RouteActionsList);
