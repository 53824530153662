import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal, DatePicker, Select, Upload } from 'antd';
import {
  SAVE_CHANGES,
  DATE,
  ADD_COMPLIANCY,
  COMPLIANCY_DOCUMENT,
  COMPLIANCY_TYPE,
  DESCRIPTION_RESULTS,
  NOTES_PLACEHOLDER,
  NO_COMPLIANCY_TYPE_SELECTED,
} from '../../../constants';
import calendar from '../../../assets/images/calendar-edit.svg';
import moment from 'moment';
import ErrorBox from '../../ErrorBox';
import fileUpload from '../../../assets/images/file-upload.svg';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const AddAbsenceModal = () => {
  const {
    fleetPageState: { editedDriver, availableCompliancesTypes },
  } = useMst();

  const [document, setDocument] = useState([]);
  const handleDocumentUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      if (file.status !== 'uploading') {
        setDocument(listToUpload);
      }
    } else {
      setDocument(fileList);
    }
  };

  const onSelectCompliancyType = (type) => {
    editedDriver.newCompliancy.setType(type);
  };

  const onChangeDescription = (e) => {
    editedDriver.newCompliancy.setDescription(e.target.value);
  };

  const onDateChange = (value, string) => {
    editedDriver.newCompliancy.setExpirationDate(string);
  };

  const onCancel = () => {
    setDocument([]);
    editedDriver.setNewCompliancy(null);
  };

  const onConfirm = () => {
    editedDriver.newCompliancy.saveCompliancy(...document);
  };

  return (
    !!editedDriver?.newCompliancy && (
      <div>
        <Modal
          visible={!!editedDriver.newCompliancy}
          footer={null}
          centered={true}
          onCancel={onCancel}
          width={400}
          className="confirm-delete-modal"
          maskClosable={false}
        >
          <div className="p32">
            <div className="align-center mb20">
              <img src={calendar} alt="calendar" className="mr10" />
              <h3>{ADD_COMPLIANCY}</h3>
            </div>

            <div className="input-wrapper mb20">
              <div className="input-label justify-between">
                <span>{COMPLIANCY_TYPE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className="w100 text-capitalize"
                onChange={onSelectCompliancyType}
                value={
                  editedDriver.newCompliancy?.compliancy_type &&
                  editedDriver.newCompliancy.compliancy_type
                }
                placeholder={
                  !editedDriver.newCompliancy.compliancy_type &&
                  NO_COMPLIANCY_TYPE_SELECTED
                }
              >
                {availableCompliancesTypes.map((type, index) => (
                  <Option key={index} value={type} className="text-capitalize">
                    {type}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="input-wrapper mt20">
              <div className="input-label justify-between">
                <span>{DESCRIPTION_RESULTS}</span>
                <span className="color-secondary">*</span>
              </div>
              <TextArea
                rows={4}
                placeholder={NOTES_PLACEHOLDER}
                onChange={onChangeDescription}
                value={editedDriver.newCompliancy?.compliancy_description}
              />
            </div>

            <div className="input-wrapper mt20">
              <div className="input-label justify-between">
                <span>{DATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onDateChange}
                value={
                  editedDriver.newCompliancy?.expiration_date
                    ? moment(editedDriver.newCompliancy.expiration_date)
                    : null
                }
              />
            </div>

            <div className="input-wrapper mt20 mb20">
              <div className="input-label">
                <Upload
                  fileList={document}
                  onChange={handleDocumentUpload}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <div className="align-center justify-between pointer">
                    <span className="color-primary mr5">
                      {COMPLIANCY_DOCUMENT}
                    </span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>

            <ErrorBox obj={editedDriver.newCompliancy} />
            <div>
              <button className="btn primary" onClick={onConfirm}>
                {SAVE_CHANGES}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(AddAbsenceModal);
