import React from 'react';
import { observer } from 'mobx-react';
import { EMPTY_NOTES_TABLE_TEXT, OTHER_NOTES } from '../../../constants';
import { useMst } from '../../../state/RootModel';
import { Table, Empty } from 'antd';
import plusCircle from '../../../assets/images/plus-circle.svg';

const NotesSection = () => {
  const {
    shipmentsPageState: { currentShipment, editedShipment },
  } = useMst();

  return (
    <div className="page-section shipment-details-section w100">
      <div className="subsection-header align-center">
        <p>{OTHER_NOTES}</p>
        {editedShipment && (
          <img
            src={plusCircle}
            alt="add detail"
            className="ml20 pointer"
            onClick={() => currentShipment.setNewShipmentNote({})}
          />
        )}
      </div>
      <div className="subsection-content">
        <Table
          className="drivers-table fleet-table"
          rowKey={'note_id'}
          columns={currentShipment?.getNotesColumns()}
          dataSource={currentShipment?.getShipmentNotesArray()}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_NOTES_TABLE_TEXT}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default observer(NotesSection);
