import { rootInstance } from '../../state/RootModel';
import { TRUCK, TRAILER } from '../../constants';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getVehicleOwnershipTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/vehicle_ownership_types`, config);
};

export const getVehicleFinancingTypes = () => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicle_types_of_financing`,
    config
  );
};

export const getVehicleFuelTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/fuel_types`, config);
};

export const getVehicleInspectionTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/inspection_type`, config);
};

export const getTrucks = (text, pageNumber, pageSize) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/vehicles?vehicle_type=truck${textQuery}&page=${pageNumber}&articles_per_page=${pageSize}`,
    config
  );
};
export const getAllTrucks = () => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicles?vehicle_type=truck&&articles_per_page=100`,
    config
  );
};
export const getTrucksForExport = (text) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/vehicles?vehicle_type=truck&page=1&articles_per_page=10000${textQuery}`,
    config
  );
};

export const getTrailers = (text, pageNumber, pageSize) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/vehicles?vehicle_type=trailer${textQuery}&page=${pageNumber}&articles_per_page=${pageSize}`,
    config
  );
};

export const getTrailersForExport = (text) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/vehicles?vehicle_type=trailer&page=1&articles_per_page=10000${textQuery}`,
    config
  );
};

export const getVehicle = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/vehicles/${id}`, config);
};

export const deleteVehicle = (vehicle_id) => {
  const config = getConfig();
  return axios.delete(`${rootInstance.baseUrl}/vehicles/${vehicle_id}`, config);
};

export const getAllVehicleRegistrations = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicles/${vehicle_id}/all_registration`,
    config
  );
};

export const getVehicleRegistration = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicles/${vehicle_id}/registration`,
    config
  );
};

export const getVehicleRegistrationDocument = (registration_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/registrations/${registration_id}/document`,
    config
  );
};

export const addVehicleRegistration = (licence) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/vehicles/${licence.vehicle_id}/registration`,
    {
      country: licence.country,
      issued_in: licence.issued_in,
      issued_on: licence.issued_on,
      expiry_date: licence.expiry_date,
      licence_plate: licence.licence_plate,
    },
    config
  );
};

export const editVehicleRegistration = (licence) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/vehicles/${licence.vehicle_id}/registration`,
    {
      country: licence.country,
      issued_in: licence.issued_in,
      issued_on: licence.issued_on,
      expiry_date: licence.expiry_date,
      licence_plate: licence.licence_plate,
    },
    config
  );
};

export const uploadRegistrationDocument = (registrationId, file) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/registrations/${registrationId}/document`,
    file,
    config
  );
};

// Inspections //
export const getAllVehicleInspections = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicles/${vehicle_id}/inspections`,
    config
  );
};

export const getVehicleInspectionDocument = (inspection_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/inspections/${inspection_id}/document`,
    config
  );
};

export const addVehicleInspection = (inspection) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/vehicles/${inspection.vehicle_id}/inspections`,
    {
      inspection_type: inspection.inspection_type,
      inspection_date_time: inspection.inspectionDateTime,
      odometer: inspection.odometer,
      odometer_unit: inspection.odometer_unit,
      notes: inspection.notes,
      signature: inspection.signature,
    },
    config
  );
};

export const updateVehicleInspection = (inspection) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/vehicles/${inspection.vehicle_id}/inspections/${inspection.inspection_id}`,
    {
      inspection_type: inspection.inspection_type,
      inspection_date_time: inspection.inspectionDateTime,
      odometer: inspection.odometer,
      odometer_unit: inspection.odometer_unit,
      notes: inspection.notes,
      signature: inspection.signature,
    },
    config
  );
};

export const uploadInspectionDocument = (inspection_id, file) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/inspections/${inspection_id}/document`,
    file,
    config
  );
};

export const deleteVehicleInspection = (inspection) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/vehicles/${inspection.vehicle_id}/inspections/${inspection.inspection_id}`,
    config
  );
};

// Maintenance //
export const getMaintenanceTypes = (vehicle_type) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/maintenance_type?vehicle_type=${vehicle_type}`,
    config
  );
};

export const getAllVehicleMaintenances = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicles/${vehicle_id}/maintenances`,
    config
  );
};

export const getVehicleMaintenanceDocument = (maintenance_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/maintenances/${maintenance_id}/document`,
    config
  );
};

export const addVehicleMaintenance = (maintenance) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/vehicles/${maintenance.vehicle_id}/maintenance`,
    {
      maintenance_type: maintenance.maintenance_type,
      date_time: maintenance.dateTime,
      odometer: maintenance.odometer,
      odometer_unit: maintenance.odometer_unit,
      notes: maintenance.notes,
      signature: maintenance.signature,
    },
    config
  );
};

export const uploadMaintenanceDocument = (maintenance_id, file) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/maintenances/${maintenance_id}/document`,
    file,
    config
  );
};

export const updateVehicleMaintenance = (maintenance) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/vehicles/${maintenance.vehicle_id}/maintenances/${maintenance.maintenance_id}`,
    {
      maintenance_type: maintenance.maintenance_type,
      date_time: maintenance.dateTime,
      odometer: maintenance.odometer,
      odometer_unit: maintenance.odometer_unit,
      notes: maintenance.notes,
      signature: maintenance.signature,
    },
    config
  );
};

export const deleteVehicleMaintenance = (maintenance) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/vehicles/${maintenance.vehicle_id}/maintenances/${maintenance.maintenance_id}`,
    config
  );
};

export const addTruck = (truck) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/vehicles`,
    {
      vin: truck.vin,
      vehicle_type: TRUCK.toLowerCase(),
      type_of_financing: truck.type_of_financing,
      ownership_type: truck.ownership_type,
      date_hired: truck.date_hired,
      vehicle_model_type: truck.vehicle_model_type,
      vehicle_make: truck.vehicle_make,
      vehicle_model_name: truck.vehicle_model_name,
      vehicle_production_year: truck.vehicle_production_year,
      vehicle_weight: truck.vehicle_weight,
      vehicle_weight_unit: truck.vehicle_weight_unit,
      vehicle_body_class: truck.vehicle_body_class,
      fuel_type: truck.fuel_type,
    },
    config
  );
};

export const addTrailer = (trailer) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/vehicles`,
    {
      vin: trailer.vin,
      vehicle_type: TRAILER.toLowerCase(),
      type_of_financing: trailer.type_of_financing,
      ownership_type: trailer.ownership_type,
      date_hired: trailer.date_hired,
      vehicle_model_type: trailer.vehicle_model_type,
      vehicle_make: trailer.vehicle_make,
      vehicle_model_name: trailer.vehicle_model_name,
      vehicle_production_year: trailer.vehicle_production_year,
      vehicle_weight: trailer.vehicle_weight,
      vehicle_weight_unit: trailer.vehicle_weight_unit,
      vehicle_body_class: trailer.vehicle_body_class,
    },
    config
  );
};

export const editTruck = (truck) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/vehicles/${truck.vehicle_id}`,
    {
      vin: truck.vin,
      vehicle_type: truck.vehicle_type,
      status: truck.status,
      type_of_financing: truck.type_of_financing,
      ownership_type: truck.ownership_type,
      date_hired: truck.date_hired,
      vehicle_model_type: truck.vehicle_model_type,
      vehicle_make: truck.vehicle_make,
      vehicle_model_name: truck.vehicle_model_name,
      vehicle_production_year: truck.vehicle_production_year,
      vehicle_weight: truck.vehicle_weight,
      vehicle_weight_unit: truck.vehicle_weight_unit,
      vehicle_body_class: truck.vehicle_body_class,
      fuel_type: truck.fuel_type,
      odometer_when_hired: truck.odometer_when_hired,
      odometer_units: truck.odometer_units,
      notes: truck.notes,
    },
    config
  );
};

export const editTrailer = (trailer) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/vehicles/${trailer.vehicle_id}`,
    {
      vin: trailer.vin,
      vehicle_type: TRAILER.toLowerCase(),
      status: trailer.status,
      type_of_financing: trailer.type_of_financing,
      ownership_type: trailer.ownership_type,
      date_hired: trailer.date_hired,
      vehicle_model_type: trailer.vehicle_model_type,
      vehicle_make: trailer.vehicle_make,
      vehicle_model_name: trailer.vehicle_model_name,
      vehicle_production_year: trailer.vehicle_production_year,
      vehicle_weight: trailer.vehicle_weight,
      vehicle_weight_unit: trailer.vehicle_weight_unit,
      trailer_type_id: trailer.trailer_type_id,
      notes: trailer.notes,
    },
    config
  );
};

export const getVehicleDocumentation = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicles/${vehicle_id}/documents`,
    config
  );
};

export const uploadVehicleDocument = (vehicleId, file, fileType) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/vehicles/${vehicleId}/documents?document_type=${fileType}`,
    file,
    config
  );
};

export const deleteVehicleDocument = (
  vehicle_id,
  document_id,
  document_type
) => {
  const config = {
    headers: { Authorization: getToken() },
    data: { document_type: document_type },
  };
  return axios.delete(
    `${rootInstance.baseUrl}/vehicles/${vehicle_id}/documents/${document_id}?document_type=${document_type}`,
    config
  );
};

// Associations
export const getVehicleAssociations = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicles/${vehicle_id}/associations`,
    config
  );
};

export const getAvailableOdometerUnits = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/odometer_units`, config);
};

export const getVehicleLocation = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/telemetrics/vehicle_locations/${vehicle_id}`,
    config
  );
};

////////////////////// DRIVER ENDPOINTS //////////////////////

export const getDriverTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/driver_type`, config);
};

export const getDrivers = (text, pageNumber, pageSize) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/drivers?page=${pageNumber}&articles_per_page=${pageSize}${textQuery}`,
    config
  );
};

export const getDriversForExport = (text) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/drivers?page=1&articles_per_page=10000${textQuery}`,
    config
  );
};

export const addDriver = (driver) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers`,
    {
      internal_company_id: driver.internal_company_id,
      code_name: driver.code_name,
      first_name: driver.first_name,
      last_name: driver.last_name,
      driver_type: driver.driver_type,
      driver_category: driver.driver_category,
    },
    config
  );
};

export const uploadDriverDocument = (driverId, file, fileType) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers/${driverId}/documents?document_type=${fileType}`,
    file,
    config
  );
};

export const deleteDriverDocument = (driver_id, document_id, document_type) => {
  const config = {
    headers: { Authorization: getToken() },
    data: { document_type: document_type },
  };
  return axios.delete(
    `${rootInstance.baseUrl}/drivers/${driver_id}/documents/${document_id}?document_type=${document_type}`,
    config
  );
};

export const getDriver = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/drivers/${id}`, config);
};

export const getDriverDocumentation = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/documents`,
    config
  );
};

export const editDriver = (driver) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/drivers/${driver.driver_id}`,
    {
      code_name: driver.code_name,
      first_name: driver.first_name,
      last_name: driver.last_name,
      driver_type: driver.driver_type,
      driver_category: driver.driver_category,
      phone_number: driver.phone_number,
      email: driver.email,
      date_of_birth: driver.date_of_birth,
      street_address: driver.street_address,
      city: driver.city,
      state: driver.state,
      country: driver.country,
      postal_code: driver.postal_code,
      internal_company_id: driver.internal_company_id,
      notes: driver.notes,
    },
    config
  );
};

export const getAllDriversLicences = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/licences`,
    config
  );
};

export const getAllDriversCompensations = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/compensations`,
    config
  );
};

export const getDriversActiveLicence = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/licence`,
    config
  );
};

export const getDriversLicenceAttachment = (licence_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/licence/${licence_id}/documents`,
    config
  );
};

export const addDriverLicence = (licence) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers/${licence.driver_id}/licence`,
    {
      licence_number: licence.licence_number,
      issued_in: licence.issued_in,
      expiry_date: licence.expiry_date,
      issued_on: licence.issued_on,
    },
    config
  );
};

export const editDriverLicence = (licence) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/drivers/${licence.driver_id}/licence/${licence.licence_id}`,
    {
      licence_number: licence.licence_number,
      issued_in: licence.issued_in,
      issued_on: licence.issued_on,
      expiry_date: licence.expiry_date,
    },
    config
  );
};

export const uploadLicenceDocument = (licenceId, file) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/licence/${licenceId}/document`,
    file,
    config
  );
};

export const deleteDriver = (driver_id) => {
  const config = getConfig();
  return axios.delete(`${rootInstance.baseUrl}/drivers/${driver_id}`, config);
};

export const getCompanyDriversAssociations = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/drive_associations`,
    config
  );
};

export const getTrailerTruckAssociations = (vehicle_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicle/${vehicle_id}/truck_trailer_associations`,
    config
  );
};

export const setCompanyDriverAssociations = (driver) => {
  const driver_id = driver.driver_id;
  const vehicle_id = driver.association.vehicle_id;
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers/${driver_id}/drive_association?vehicle_id=${vehicle_id}`,
    {},
    config
  );
};

export const setOwnDriverAssociations = (driver) => {
  const driver_id = driver.driver_id;
  const vehicle_id = driver.association.vehicle_id;
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers/${driver_id}/own_association?vehicle_id=${vehicle_id}`,
    {},
    config
  );
};

export const getOwnerDriversAssociations = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/own_associations`,
    config
  );
};

export const getAvailableTrucksforDriverAssociation = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/association/trucks`,
    config
  );
};

export const getDriverCompliances = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/compliances`,
    config
  );
};

export const getDriverCompliancyTypes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/compliancy_type`, config);
};

export const getDriverCompliancyDocument = (compliancy_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/compliancies/${compliancy_id}/document`,
    config
  );
};

export const addDriverCompliancy = (compliancy) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers/${compliancy.driver_id}/compliancy`,
    {
      compliancy_type: compliancy.compliancy_type,
      compliancy_description: compliancy.compliancy_description,
      expiration_date: compliancy.expiration_date,
    },
    config
  );
};

export const updateDriverCompliancy = (compliancy) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/drivers/${compliancy.driver_id}/compliancy/${compliancy.compliancy_id}`,
    {
      compliancy_type: compliancy.compliancy_type,
      compliancy_description: compliancy.compliancy_description,
      expiration_date: compliancy.expiration_date,
    },
    config
  );
};

export const uploadDriverCompliancyDocument = (compliancyId, file) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/compliancies/${compliancyId}/document`,
    file,
    config
  );
};

export const getAllDriverAbsences = (driver_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/absences`,
    config
  );
};

export const getDriverAbsence = (driver_id, absence_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/drivers/${driver_id}/absences/${absence_id}`,
    config
  );
};

export const getDriverAbsenceDocument = (absence_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/absences/${absence_id}/document?absence_type=driver`,
    config
  );
};

export const uploadDriverAbsenceDocument = (absenceId, file) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/absences/${absenceId}/document?absence_type=driver`,
    file,
    config
  );
};

export const addDriverAbsence = (absence) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers/${absence.driver_id}/absences`,
    {
      starting_date: absence.starting_date,
      end_date: absence.end_date,
      status: absence.status,
    },
    config
  );
};

export const updateDriverAbsence = (absence) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/drivers/${absence.driver_id}/absences/${absence.absence_id}`,
    {
      starting_date: absence.starting_date,
      end_date: absence.end_date,
      status: absence.status,
    },
    config
  );
};

export const deleteDriverAbsence = (driver_id, absence_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/drivers/${driver_id}/absences/${absence_id}`,
    config
  );
};

export const getVehicleStatuses = (type) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/vehicle_statuses?vehicle_type=${type}`,
    config
  );
};

export const changeDriversCompensationActivity = (
  driver_id,
  compensation_id
) => {
  const config = getConfig();

  return axios.put(
    `${rootInstance.baseUrl}/drivers/${driver_id}/compensations/${compensation_id}`,
    {},
    config
  );
};

export const addCompensationToDriver = (driver_id, compensation_id) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/drivers/${driver_id}/compensations/${compensation_id}`,
    {},
    config
  );
};

export const editCompensationValueForDriver = (
  driver_id,
  compensation_id,
  editedValue
) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/drivers/${driver_id}/compensations/${compensation_id}/compensation_values/${editedValue.compensation_value_id}`,
    {
      value: editedValue.default_value,
      currency: editedValue.currency,
    },
    config
  );
};

export const editCompensationDeductionForDriver = (
  driver_id,
  compensation_id,
  editedDeduction
) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/drivers/${driver_id}/compensations/${compensation_id}/deductions/${editedDeduction.compensation_deduction_id}`,
    {
      value: editedDeduction.default_value,
      currency: editedDeduction.currency,
    },
    config
  );
};
