import React from 'react';
import { observer } from 'mobx-react';
import LocationsTable from './LocationsTable';
import AddLocationModal from './AddLocationModal';
import {
  ADD_LOCATION,
  MODAL_SUCCESS_ADD_LOCATION_BUTTON_TEXT,
  MODAL_SUCCESS_ADD_LOCATION_TEXT,
  MODAL_SUCCESS_EDIT_LOCATION_BUTTON_TEXT,
  MODAL_SUCCESS_EDIT_LOCATION_TEXT,
  MODAL_SUCCESS_TITLE,
  START_TYPING_PLACEHOLDER_TEXT,
} from '../../../../constants';
import { useMst } from '../../../../state/RootModel';
import SuccessModal from '../../../SuccessModal';
import PageRoutes from '../../../../utils/PageRoutes';
import search from '../../../../assets/images/search.svg';
import { DebounceInput } from 'react-debounce-input';
import EditLocationCoordinatesModal from './EditLocationCoordinatesModal';
import EditLocationModal from './EditLocationModal';

const LocationsComponent = () => {
  const {
    locationsPageState: {
      setNewLocation,
      newLocation,
      addLocationSuccessModalOpen,
      setAddLocationSuccessModalOpen,
      setSearchTerm,
      searchTerm,
      setCurrentPage,
      getLocations,
      draggableEditedLocation,
      editedLocation,
      editLocationSuccessModalOpen,
      setEditLocationSuccessModalOpen,
    },
    userState: { canAddLocations },
  } = useMst();

  const onAddLocation = () => {
    setNewLocation({});
  };

  const handleLocationSearch = (e) => {
    const searchText = e.target.value;
    setSearchTerm(searchText);
    setCurrentPage(1);
    getLocations({});
  };

  const backLink = PageRoutes.Brokerage.name;

  return (
    <div className="pv20 locations-component">
      {canAddLocations && (
        <button
          className={'btn primary no-wrap header-button'}
          onClick={onAddLocation}
        >
          {ADD_LOCATION}
        </button>
      )}
      <div className="search-wrapper border-bottom-search align-center pb20">
        <img src={search} alt="search" className="search-icon" />
        <DebounceInput
          debounceTimeout={300}
          onChange={(e) => handleLocationSearch(e)}
          style={{ width: 300 }}
          placeholder={START_TYPING_PLACEHOLDER_TEXT}
          value={searchTerm}
        />
      </div>
      <LocationsTable />
      {newLocation && <AddLocationModal />}
      {draggableEditedLocation && <EditLocationCoordinatesModal />}
      {editedLocation && <EditLocationModal />}
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_ADD_LOCATION_TEXT}
        buttonText={MODAL_SUCCESS_ADD_LOCATION_BUTTON_TEXT}
        backLink={backLink}
        open={addLocationSuccessModalOpen}
        closeModal={() => setAddLocationSuccessModalOpen(false)}
      />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_EDIT_LOCATION_TEXT}
        buttonText={MODAL_SUCCESS_EDIT_LOCATION_BUTTON_TEXT}
        backLink={backLink}
        open={editLocationSuccessModalOpen}
        closeModal={() => setEditLocationSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(LocationsComponent);
