import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty, Pagination } from 'antd';
import { rootInstance, useMst } from '../../../../state/RootModel';
import { EMPTY_CARRIERS_TABLE_TEXT } from '../../../../constants';
import PageRoutes from '../../../../utils/PageRoutes';

const CarriersTable = () => {
  const {
    carriersPageState: {
      getCarriersColumns,
      carriersArray,
      getCarriers,
      totalItems,
      currentPage,
      pageSize,
    },
  } = useMst();

  const paginationChange = (val) => {
    getCarriers({ page: val });
  };

  const onRowClick = (e, record) => {
    e.stopPropagation();
    const params = { carrierId: record.carrier_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.ViewCarrier.name),
      params
    );
  };

  return (
    <div>
      <Table
        className="drivers-table fleet-table"
        rowKey={'carrier_id'}
        columns={getCarriersColumns()}
        dataSource={carriersArray}
        pagination={false}
        onRow={(r) => ({
          onClick: (e) => onRowClick(e, r),
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_CARRIERS_TABLE_TEXT}
            />
          ),
        }}
      />
      <div className="table-footer">
        <Pagination
          onChange={paginationChange}
          total={totalItems}
          current={currentPage}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default observer(CarriersTable);
