import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { notification } from 'antd';
import editEmployee from '../../../assets/images/menu/edit-employee.svg';
import FormPageTitle from '../../FormPageTitle';
import ErrorBox from '../../ErrorBox';
import FormButtonsSection from '../../FormButtonsSection';
import AdvancedOptions from './AdvancedOptions';
import GeneralInformation from './GeneralInformation';
import DocumentationSection from './DocumentationSection';
import WorkingStatusComponent from './WorkingStatusComponent';
import {
  EDIT_EMPLOYEE,
  ACCOUNT_SETTINGS,
  SAVE_CHANGES,
  DISCARD_CHANGES,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
  UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
} from '../../../constants';
import ChangePasswordModal from '../ChangePasswordModal';
import Link from '../../Link';
import PageRoutes from '../../../utils/PageRoutes';
import {
  DOCUMENT_TYPE_EMPLOYEE_AGREEMENT,
  DOCUMENT_TYPE_EMPLOYEE_IMAGE,
  DOCUMENT_TYPE_REFERENCE,
  DOCUMENT_TYPE_RESUME,
} from '../../../constants/documents';
import SaveFirstModal from '../../SaveFirstModal';

const EditEmployeeComponent = () => {
  const {
    employeesPageState: { editedEmployee, getEditedEmployee },
    loginForm: { currentUser, getCurrentUser },
    userState: { canViewUserAbsences, canViewUserDocuments },
  } = useMst();

  const employee = editedEmployee
    ? editedEmployee
    : currentUser
    ? currentUser
    : null;

  const [profilePhoto, setProfilePhoto] = useState([]);
  useEffect(() => {
    setProfilePhoto(employee.employeeImage ? [employee.employeeImage] : []);
  }, [employee.employeeImage]);

  const [otherDocuments, setOtherDocuments] = useState([]);
  useEffect(() => {
    setOtherDocuments(
      employee.employeeOtherDocuments
        ? [...employee.employeeOtherDocuments]
        : []
    );
  }, [employee.employeeOtherDocuments]);

  const [cv, setCv] = useState([]);
  useEffect(() => {
    setCv(employee.employeeCv ? [employee.employeeCv] : []);
  }, [employee.employeeCv]);

  const [employeeAgreement, setEmployeeAgreement] = useState([]);
  useEffect(() => {
    setEmployeeAgreement(
      employee.employeeAgreement ? [employee.employeeAgreement] : []
    );
  }, [employee.employeeAgreement]);

  const handleProfilePhotoUpload = ({ file, fileList }) => {
    employee.employeeImage &&
      employee.employeeImage.setIsChangedDuringEdit(true);
    if (fileList.length) {
      fileList[0].originFileObj.documentType = DOCUMENT_TYPE_EMPLOYEE_IMAGE;
    }
    if (file.status !== 'uploading') {
      setProfilePhoto(fileList);
    }
  };

  const handleOtherDocumentsUpload = ({ file, fileList }) => {
    const isUploadAllowed = () => {
      return fileList.length < 6 || fileList.length < otherDocuments.length;
    };
    if (file.status !== 'uploading') {
      if (isUploadAllowed()) {
        fileList.forEach((file) => {
          !('originFileObj' in file) && (file.originFileObj = {});
          file.originFileObj.documentType = DOCUMENT_TYPE_REFERENCE;
        });
        setOtherDocuments(fileList);
      } else {
        notification.error({
          message: UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
          description: UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
        });
      }
    }
  };

  const handleCVUpload = ({ file, fileList }) => {
    employee.employeeCv && employee.employeeCv.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_RESUME;
      if (file.status !== 'uploading') {
        setCv(listToUpload);
      }
    } else {
      setCv(fileList);
    }
  };

  const handleEmployeeAgreementUpload = ({ file, fileList }) => {
    employee.employeeAgreement &&
      employee.employeeAgreement.setIsChangedDuringEdit(true);
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType =
        DOCUMENT_TYPE_EMPLOYEE_AGREEMENT;
      if (file.status !== 'uploading') {
        setEmployeeAgreement(listToUpload);
      }
    } else {
      setEmployeeAgreement(fileList);
    }
  };

  const discardChanges = () => {
    editedEmployee
      ? getEditedEmployee(editedEmployee.user_id)
      : currentUser
      ? getCurrentUser()
      : console.log('no user');
  };

  const getDocumentsForDeletion = () => {
    const currentDocumentsIds = [
      ...profilePhoto,
      ...cv,
      ...employeeAgreement,
      ...otherDocuments,
    ].map((document) => {
      return document.uid;
    });
    const idsToDelete = employee.documentsArray
      .filter((document) => {
        return !currentDocumentsIds.includes(document.uid);
      })
      .map((deletedDocument) => {
        return deletedDocument.uid;
      });
    return idsToDelete;
  };

  const getDocumentsForUpload = () => {
    const currentDocuments = [
      ...profilePhoto,
      ...cv,
      ...employeeAgreement,
      ...otherDocuments,
    ];
    const uploadedDocumentsIds = employee.documentsArray.map((document) => {
      return document.uid;
    });
    const documentsToUpload = currentDocuments.filter((document) => {
      return !uploadedDocumentsIds.includes(document.uid);
    });
    return documentsToUpload;
  };

  const saveChanges = () => {
    const documentsToUpload = getDocumentsForUpload();
    const documentsToDelete = getDocumentsForDeletion();
    editedEmployee
      ? editedEmployee.updateEmployee(documentsToUpload, documentsToDelete)
      : currentUser
      ? currentUser.updateCurrentUser(documentsToUpload, documentsToDelete)
      : console.log('no user');
  };

  return (
    <div className="add-edit-form-component">
      {employee && (
        <div>
          <SaveFirstModal
            onCancel={discardChanges}
            onConfirm={saveChanges}
            open={employee?.isSaveFirstModalOpen}
          />
          {currentUser && <ChangePasswordModal />}
          <div className="align-center">
            <FormPageTitle
              image={editEmployee}
              title={currentUser ? ACCOUNT_SETTINGS : EDIT_EMPLOYEE}
            />
            {!currentUser && (
              <Link
                className={'text-medium color-primary fs150'}
                view={PageRoutes.ViewEmployee.name}
                params={{ employeeId: editedEmployee.user_id }}
              >
                {employee.first_name} {employee.last_name}
              </Link>
            )}
          </div>

          <AdvancedOptions />

          <GeneralInformation
            uploadProfilePhoto={handleProfilePhotoUpload}
            profilePhoto={profilePhoto}
          />

          {editedEmployee && canViewUserAbsences && <WorkingStatusComponent />}

          {canViewUserDocuments && (
            <DocumentationSection
              uploadCV={handleCVUpload}
              uploadEmployeeAgreement={handleEmployeeAgreementUpload}
              uploadOtherDocuments={handleOtherDocumentsUpload}
              cv={cv}
              employeeAgreement={employeeAgreement}
              otherDocuments={otherDocuments}
            />
          )}
          <ErrorBox obj={employee} />

          <FormButtonsSection
            onConfirm={saveChanges}
            onDiscard={discardChanges}
            confirmTitle={SAVE_CHANGES}
            discardTitle={DISCARD_CHANGES}
          />
        </div>
      )}
    </div>
  );
};

export default observer(EditEmployeeComponent);
