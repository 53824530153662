import { observer } from 'mobx-react';
import React from 'react';
import {
  ADD_COMPENSATION,
  DISCARD_CHANGES,
  EDIT_COMPENSATION,
  SAVE_CHANGES,
} from '../../../constants';
import FormPageTitle from '../../FormPageTitle';
import editCompensationIcon from '../../../assets/images/edit-pen.svg';
import ErrorBox from '../../ErrorBox';
import { rootInstance, useMst } from '../../../state/RootModel';
import FormButtonsSection from '../../FormButtonsSection';
import PageRoutes from '../../../utils/PageRoutes';
import CompensationStructureInformation from './CompensationStructureInformation';
import CompensationValuesSection from './CompensationValuesSection';
import CompensationDeductionsSection from './CompensationDeductionsSection';

const EditCompensationComponent = () => {
  const {
    payrollPageState: { errorText, saveEditedCompensation },
  } = useMst();

  const discardChanges = () => {
    // newCompensationStructure.setNewCompensationDeductions([]);
    // newCompensationStructure.setNewCompensationValues([]);

    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.Payroll.name)
    );
  };

  const saveChanges = () => {
    saveEditedCompensation();
  };

  return (
    <div className="add-edit-form-component">
      <FormPageTitle image={editCompensationIcon} title={EDIT_COMPENSATION} />

      <CompensationStructureInformation />

      <CompensationValuesSection />

      <CompensationDeductionsSection />

      <div className="align-center">
        <FormButtonsSection
          onConfirm={saveChanges}
          onDiscard={discardChanges}
          confirmTitle={SAVE_CHANGES}
          discardTitle={DISCARD_CHANGES}
        />
        <div className="ml40">
          <ErrorBox obj={{ errorText }} />
        </div>
      </div>
    </div>
  );
};

export default observer(EditCompensationComponent);
