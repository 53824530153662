import React from 'react';
import { DISPATCHER, ETA, ID } from '../../../constants/dispatch';
import {
  ACTION_TYPE,
  BILL_TO,
  CUSTOMER,
  DRIVER,
  END_LOCATION,
  MILES,
  NOT_AVAILABLE,
  NOTES,
  START_DATE_TIME,
  STATUS,
  TRAILER,
  TRUCK,
} from '../../../constants';
import { eta_format } from '../../../utils/methods';
import moment from 'moment';

export const RouteActionsListTableColumns = (rootInstance) => ({
  '1': {
    title: ID,
    dataIndex: 'route_action_id',
    key: 'route_action_id',
    width: 100,
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '2': {
    title: DISPATCHER,
    dataIndex: 'dispatcher',
    key: 'dispatcher',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '3': {
    title: DRIVER,
    dataIndex: 'driver',
    key: 'driver',
    render: (text, record) => {
      return (
        <span>
          {record?.driver?.driver_code_name
            ? record.driver.driver_code_name
            : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '4': {
    title: TRUCK,
    dataIndex: 'truck',
    key: 'truck',
    render: (text, record) => {
      return (
        <span>
          {record?.truck?.truck_company_id
            ? record.truck.truck_company_id
            : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '5': {
    title: TRAILER,
    dataIndex: 'trailer',
    key: 'trailer',
    render: (text, record) => {
      return (
        <span>
          {record?.trailer?.trailer_company_id
            ? record.trailer.trailer_company_id
            : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '6': {
    title: ACTION_TYPE,
    dataIndex: 'action_type',
    key: 'action_type',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '7': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '8': {
    title: START_DATE_TIME,
    dataIndex: 'start',
    key: 'start',
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('MMM D, hh:mm A') : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '9': {
    title: END_LOCATION,
    dataIndex: 'end_location',
    key: 'end_location',
    render: (text, record) => {
      return (
        <span>
          {record?.end_location?.location_name
            ? record.end_location.location_name
            : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '10': {
    title: MILES,
    dataIndex: 'miles',
    key: 'miles',
    render: (text, record) => {
      return <span>{text ? text.toFixed(2) + ' miles' : NOT_AVAILABLE}</span>;
    },
  },
  '11': {
    title: ETA,
    dataIndex: 'eta',
    key: 'eta',
    render: (text, record) => {
      return <span>{text ? eta_format(text) : NOT_AVAILABLE}</span>;
    },
  },
  '12': {
    title: NOTES,
    dataIndex: 'notes',
    key: 'notes',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '13': {
    title: BILL_TO + ' ' + CUSTOMER,
    dataIndex: 'bill_to_customer',
    key: 'bill_to_customer',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  // '8': {
  //   title: '',
  //   dataIndex: 'action',
  //   render: (text, record) => {
  //     return (
  //       <div className="align-center">
  //         {record?.linked_truck_data?.vehicle_latitude &&
  //           record?.linked_truck_data?.vehicle_longitude && (
  //             <Tooltip title={VIEW_AND_TRACK_ON_MAP}>
  //               <img
  //                 src={selectIcon}
  //                 className="cursor-pointer mr10"
  //                 alt={VIEW_AND_TRACK_ON_MAP}
  //                 onClick={(e) => onShowDriverOnMap(e, record)}
  //               />
  //             </Tooltip>
  //           )}
  //         <Tooltip title={CREATE_ACTION}>
  //           <img
  //             src={createTrip}
  //             className="cursor-pointer"
  //             alt={CREATE_ACTION}
  //             onClick={(e) => onDriverChange(e, record)}
  //           />
  //         </Tooltip>
  //       </div>
  //     );
  //   },
  // },
});
