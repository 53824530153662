import { observable } from 'mobx';
import { types } from 'mobx-state-tree';
import { searchClients } from '../../actions/clients';
import { addLocation } from '../../actions/locations';
import {
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COMPANY_REQUIRED,
  COUNTRY_REQUIRED,
  LOCATIONS,
  LOCATION_NAME_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  STATE_REQUIRED,
} from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { rootInstance } from '../RootModel';

export const LocationLightModel = types
  .model('LocationLightModel', {
    owner_id: types.maybe(types.maybeNull(types.number)),
    address: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    location_name: types.maybe(types.maybeNull(types.string)),
    location_contact_name: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    customerSearchTerm: types.maybe(types.maybeNull(types.string)),

    location_latitude: types.maybe(types.maybeNull(types.number)),
    location_longitude: types.maybe(types.maybeNull(types.number)),
    isSearchFieldVisible: false,
    notes: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {
      companies: observable([]),
      latlng: observable([]),
    };
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setNotes(notes) {
        self.notes = notes;
      },
      setLocationLatitude(lat) {
        self.location_latitude = lat;
      },
      setLocationLongitude(lng) {
        self.location_longitude = lng;
      },
      setIsSearchFieldVisible(status) {
        self.isSearchFieldVisible = status;
      },
      setLatLng(coordinates) {
        self.latlng = coordinates;
      },
      setCompanies(items) {
        self.companies = items;
      },
      setCustomerSearchTerm(text) {
        self.customerSearchTerm = text;
        this.searchCustomers();
      },
      searchCustomers(text) {
        searchClients(text).then((response) => {
          response.data.forEach((item) => {
            item.value = item.client_id;
            item.label = item.company;
          });
          return response.data;
        });
      },
      setOwnerId(number) {
        self.owner_id = number;
      },
      setLocationName(text) {
        self.location_name = text;
      },
      setLocationContactName(text) {
        self.location_contact_name = text;
      },
      setPhoneNumber(phone_number) {
        self.phone_number = phone_number;
      },
      setAddress(text) {
        self.address = text;
      },
      setCountry(text) {
        self.country = text;
        // this.setCountry('')
      },
      setCity(text) {
        self.city = text;
      },
      setState(text) {
        self.state = text;
      },
      setPostalCode(text) {
        self.postal_code = text;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveLocationError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.location_name) {
          this.setErrorText(LOCATION_NAME_REQUIRED);
        } else if (!self.owner_id) {
          this.setErrorText(COMPANY_REQUIRED);
        } else if (!self.phone_number) {
          this.setErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!self.country) {
          this.setErrorText(COUNTRY_REQUIRED);
        } else if (!self.state) {
          this.setErrorText(STATE_REQUIRED);
        } else if (!self.city) {
          this.setErrorText(CITY_REQUIRED);
        } else if (!self.address) {
          this.setErrorText(ADDRESS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveLocation() {
        this.validateForm();
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addLocation(self)
            .then((location) => {
              rootInstance.locationsPageState.setNewLocation(null);
              rootInstance.brokeragePageState.setActiveTab(LOCATIONS);
              rootInstance.locationsPageState.getDraggableEditedLocation(
                location.data.location_id
              );
              setFetchingInProgress(false);
            })
            .catch((error) => {
              this.throwSaveLocationError(error);
            });
        }
      },
    };
  });
