import React from 'react';
import { observer } from 'mobx-react';
import { BREAK, CYCLE, DRIVE, HOS, SHIFT } from '../../../constants/dispatch';
import { NOT_AVAILABLE } from '../../../constants';

const ViewHoursOfService = ({ model }) => {
  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-page-subsection">
        <div className="subsection-header mt0">
          <p>{HOS}</p>
        </div>
        <div className="section-content align-center pb20">
          <p className="w15">
            <span className="color-border-gray text-bold text-uppercase flex">
              {BREAK}
            </span>
            <span className="text-black">
              {model.hos_data.break ? model.hos_data.break : NOT_AVAILABLE}
            </span>
          </p>
          <p className="w15">
            <span className="color-border-gray text-bold text-uppercase flex">
              {DRIVE}
            </span>
            <span className="text-black">
              {model.hos_data.drive ? model.hos_data.drive : NOT_AVAILABLE}
            </span>
          </p>
          <p className="w15">
            <span className="color-border-gray text-bold text-uppercase flex">
              {SHIFT}
            </span>
            <span className="text-black">
              {model.hos_data.shift ? model.hos_data.shift : NOT_AVAILABLE}
            </span>
          </p>
          <p className="w15">
            <span className="color-border-gray text-bold text-uppercase flex">
              {CYCLE}
            </span>
            <span className="text-black">
              {model.hos_data.cycle ? model.hos_data.cycle : NOT_AVAILABLE}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default observer(ViewHoursOfService);
