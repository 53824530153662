import { Tooltip } from 'antd';
import { NONAME } from 'dns';
import React from 'react';
import {
  COMPENSATION_DELETE,
  COMPENSATION_EDIT,
  COMPENSATION_NAME,
  COMPENSATION_PERIOD,
  COMPENSATION_TYPE,
} from '../../../constants/payroll';
import trash from '../../../assets/images/trash.svg';
import edit from '../../../assets/images/edit-equipment.svg';
import { rootInstance } from '../../RootModel';
import PageRoutes from '../../../utils/PageRoutes';

const onClickEdit = (e, compensation_id) => {
  e.stopPropagation();
  const params = { compensationId: compensation_id };
  // rootInstance.payrollPageState.getEditingCompensation(compensation_id);
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditCompensation.name),
    params
  );
  // rootInstance.payrollPageState.setIsEditingCompensationModalOpen(true);
};

const onClickDelete = (e, id) => {
  e.stopPropagation();
  rootInstance.payrollPageState.setIsDeleteCompensationModalOpened(true);
  rootInstance.payrollPageState.getCompensationToBeDeleted(id);
};

export const compensationsTableColumns = (rootInstance) => ({
  '1': {
    title: COMPENSATION_NAME,
    dataIndex: 'compensation_name',
    key: 'compensation_name',
  },
  '2': {
    title: COMPENSATION_TYPE,
    dataIndex: 'compensation_type',
    key: 'compensation_type',
    width: '15%',
  },
  '3': {
    title: COMPENSATION_PERIOD,
    dataIndex: 'period',
    key: 'period',
    width: '15%',
  },
  '4': {
    title: NONAME,
    render: (text, record) => {
      return (
        <div className="align-center">
          <div>
            <Tooltip title={COMPENSATION_EDIT}>
              <img
                src={edit}
                alt={COMPENSATION_EDIT}
                className="pointer ph10"
                onClick={(e) => onClickEdit(e, record.compensation_id)}
              />
            </Tooltip>
          </div>
          <Tooltip title={COMPENSATION_DELETE}>
            <img
              src={trash}
              alt={COMPENSATION_DELETE}
              className="pointer ph10"
              onClick={(e) => onClickDelete(e, record.compensation_id)}
            />
          </Tooltip>
        </div>
      );
    },
    width: '15%',
  },
});
