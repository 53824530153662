import React from 'react';
import { observer } from 'mobx-react';
import { Upload } from 'antd';
import {
  DOCUMENTATION,
  CV_RESUME,
  RECOMMENDATION_LETTER,
  SSN_ATTACHMENT,
  ADDITIONAL_DOCUMENTATION,
} from '../../../constants';
import fileUpload from '../../../assets/images/file-upload.svg';

const DocumentationSection = (props) => {
  const handleCVUpload = props.uploadCV;
  const cv = [...props.cv];
  const handleReccomendationLetterUpload = props.uploadReccomendationLetter;
  const reccomendationLetter = [...props.reccomendationLetter];
  const handleSsnUpload = props.uploadSsnAttachment;
  const ssn = [...props.ssn];
  const handleAdditionalFilesUpload = props.uploadAdditionalFiles;
  const additionalFiles = [...props.additionalFiles];

  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{DOCUMENTATION}</span>
      </div>

      <div className="section-content flex">
        <div className="input-wrapper ml0">
          <div className="input-label">
            <Upload
              fileList={cv}
              onChange={handleCVUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{CV_RESUME}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Upload
              fileList={reccomendationLetter}
              onChange={handleReccomendationLetterUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{RECOMMENDATION_LETTER}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Upload
              fileList={ssn}
              onChange={handleSsnUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{SSN_ATTACHMENT}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
      </div>

      {/* ADDITIONAL DOCUMENTATION */}

      <div className="form-page-subsection">
        <div className="subsection-header">
          <p>{ADDITIONAL_DOCUMENTATION}</p>
        </div>
        <div className="section-content align-center">
          <div className="input-wrapper ml0">
            <div className="input-label">
              <Upload
                fileList={additionalFiles}
                onChange={handleAdditionalFilesUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              >
                <div className="align-center justify-between pointer">
                  <span className="color-primary">
                    {ADDITIONAL_DOCUMENTATION}
                  </span>
                  <img src={fileUpload} alt="file upload" />
                </div>
              </Upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DocumentationSection);
