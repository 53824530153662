import React from 'react';
import { observer } from 'mobx-react';
import { MapContainer, TileLayer, useMap } from 'react-leaflet';
import L from 'leaflet';
import * as ELG from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import { ADDRESS, SEARCH_ADDRESS } from '../../../../constants';
import { useMst } from '../../../../state/RootModel';

const MapComponent = () => {
  const {
    locationsPageState: { mapLocation },
  } = useMst();

  const defaultCenter = [39.742043, -104.991531];
  const apiKey =
    'AAPK976cceb1751b4ef792e7d3cce1796e5fxZ2KtJINAnCX079Rw3QL28ONoBIu9XS6q7DibOy4ABKl1m9Bz6pDNaS6DVjxKmaO';

  const Geocoder = ({ address }) => {
    const map = useMap();

    if (
      !mapLocation?.isSearchFieldVisible &&
      mapLocation?.location_latitude &&
      mapLocation?.location_longitude
    ) {
      const results = new L.LayerGroup().addTo(map);
      results.addLayer(
        L.marker([
          Number(mapLocation.location_latitude),
          Number(mapLocation.location_longitude),
        ])
      );
    }

    if (!mapLocation?.isSearchFieldVisible) {
      const control = ELG.geosearch({
        providers: [
          ELG.arcgisOnlineProvider({
            apikey: apiKey,
          }),
        ],
        expanded: true,
        placeholder: SEARCH_ADDRESS,
        collapseAfterResult: false,
        useMapBounds: false,
      }).addTo(map);

      mapLocation.setIsSearchFieldVisible(true);

      const results = new L.LayerGroup().addTo(map);
      control.on('results', function (data) {
        results.clearLayers();
        for (let i = data.results.length - 1; i >= 0; i--) {
          results.addLayer(L.marker(data.results[i].latlng));
        }
        const locationData = data.results[0]?.properties;

        mapLocation.setPostalCode(locationData?.Postal);
        mapLocation.setCountry(locationData?.Country);
        mapLocation.setState(locationData?.Region);
        mapLocation.setCity(locationData?.City);

        mapLocation.setAddress(data.text);
        // original address has been changed, coordinates have to be reset
        mapLocation.setLocationLatitude(null);
        mapLocation.setLocationLongitude(null);
      });
    }

    ELG.geocode({ apikey: apiKey })
      .text(address)
      .run((err, results, response) => {
        if (mapLocation?.location_latitude && mapLocation?.location_longitude) {
          map.setView(
            [
              Number(mapLocation.location_latitude),
              Number(mapLocation.location_longitude),
            ],
            13
          );
        } else if (!mapLocation?.address) {
          map.setView(defaultCenter, 3);
        }
        const latLng = [
          results.results[0]?.latlng.lat,
          results.results[0]?.latlng.lng,
        ];
        mapLocation?.setLatLng(latLng);
      });

    return null;
  };

  return (
    <div className="map-wrapper ml5pc w60">
      <div className="input-wrapper w100 mb10">
        <div className="input-label justify-between">
          <span>{ADDRESS}</span>
          <span className="color-secondary">*</span>
        </div>
        <p className="text-medium">{mapLocation?.address}</p>
      </div>
      <MapContainer scrollWheelZoom={false} className="view-fleet-map map">
        <TileLayer
          // @ts-ignore
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Geocoder address={defaultCenter} />
      </MapContainer>
    </div>
  );
};

export default observer(MapComponent);
