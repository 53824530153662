import { types, getSnapshot, getRoot } from 'mobx-state-tree';
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  DRIVER_TYPE_REQUIRED,
  DRIVER_CATEGORY_REQUIRED,
  DRIVER_CODE_NAME_REQUIRED,
  EMAIL_REQUIRED,
  BIRTH_DATE_REQUIRED,
  LICENCE_ISSUED_IN_REQUIRED,
  LICENCE_EXPIRY_DATE_REQUIRED,
  LICENCE_NUMBER_REQUIRED,
  EMAIL_ERROR_TEXT,
  PHONE_NUMBER_ERROR_TEXT,
  FETCH_LOCATIONS_ERROR,
  DRIVER_COMPANY_ID_REQUIRED,
} from '../../../constants';
import { DocumentModel } from '../../DocumentModel';
import { rootInstance } from '../../RootModel';
// import { driverCompliancyColumnsFunc } from './DriverCompliancyColumns';
import { CompliancyLightModel } from './CompliancyLightModel';
import { CompliancyModel } from './CompliancyModel';
import { LicenceModel } from './LicenceModel';
import { LicenceLightModel } from './LicenceLightModel';
import { AssociationModel } from './AssociationModel';
import { AbsenceModel } from '../../employees/AbsenceModel';
import {
  getErrorText,
  getRandomInt,
  setFetchingInProgress,
  validateEmail,
  validatePhoneNumber,
} from '../../../utils/methods';
import {
  addCompensationToDriver,
  addDriverLicence,
  deleteDriverDocument,
  editDriver,
  editDriverLicence,
  setCompanyDriverAssociations,
  setOwnDriverAssociations,
  uploadDriverDocument,
  uploadLicenceDocument,
} from '../../../actions/fleet';
import {
  DOCUMENT_TYPE_ADDITIONAL,
  DOCUMENT_TYPE_REFERENCE,
  DOCUMENT_TYPE_RESUME,
  DOCUMENT_TYPE_SSN,
  DOCUMENT_TYPE_LICENCE,
} from '../../../constants/documents';
import { STATUS_AVAILABLE } from '../../../constants/workingStatuses';
import moment from 'moment';
import { AbsenceLightModel } from '../../employees/AbsenceLightModel';
import { compliancesColumnsFunc } from './CompliancesTableColumns';
import { getAllCountries } from '../../../actions/common';
import { notification } from 'antd';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';
import { FLEET } from '../../../constants/menu';
import { editDriverCompensationsTableColumns } from './EditDriverCompensationsColumns';
import { editDriverCompensationValuesOrDeductionsTableColumns } from './EditDriverCompensationValuesOrDeductionsColumns';
import { CompensationsModel } from '../../payroll/Compensations/CompensationsModel';
import { DriversCompensationsModel } from './DriversCompensationModel';
import {
  COMPENSATION_NAME_REQUIRED,
  COMPENSATION_SELECTION_REQUIRED,
  ERROR_ADDING_NEW_COMPENSATION_TO_DRIVER,
  ERROR_DELETING_COMPENSATION,
} from '../../../constants/payroll';
import { addCompensationToDriverPromise } from '../../../promises/fleet';

const isEqual = require('lodash.isequal');

export const DriverModel = types
  .model('DriverModel', {
    driver_id: types.identifierNumber,
    first_name: types.maybe(types.maybeNull(types.string)),
    last_name: types.maybe(types.maybeNull(types.string)),
    code_name: types.maybe(types.maybeNull(types.string)),
    driver_type: types.maybe(types.maybeNull(types.string)),
    driver_category: types.maybe(types.maybeNull(types.string)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),
    organization_id: types.maybe(types.maybeNull(types.number)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    date_of_birth: types.maybe(types.maybeNull(types.string)),
    date_hired: types.maybe(types.maybeNull(types.string)),
    working_status: types.maybe(types.maybeNull(types.string)),
    street_address: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    company: types.maybe(types.maybeNull(types.string)),
    owner: types.maybe(types.maybeNull(types.string)),
    current_absence_id: types.maybe(types.maybeNull(types.number)),
    documents: types.map(DocumentModel),
    notes: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),

    currentAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),
    absences: types.map(AbsenceModel),
    newAbsence: types.maybe(types.maybeNull(AbsenceLightModel)),
    editedAbsence: types.maybe(types.maybeNull(types.reference(AbsenceModel))),

    compliances: types.map(CompliancyModel),
    newCompliancy: types.maybe(types.maybeNull(CompliancyLightModel)),
    editedCompliancy: types.maybe(
      types.maybeNull(types.reference(CompliancyModel))
    ),

    successModalOpen: false,

    newLicence: types.maybe(types.maybeNull(LicenceLightModel)),
    licence: types.maybe(types.maybeNull(LicenceModel)),

    association: types.maybe(types.maybeNull(AssociationModel)),
    trucksAvailableForAssociation: types.frozen([]),

    isSaveFirstModalOpen: false,

    driverFullyFetched: false,
    countries: types.frozen([]),
    internal_company_id: types.maybe(types.maybeNull(types.string)),
    connected_truck_data: types.frozen([]),
    connected_trailer_data: types.frozen([]),

    compensations: types.map(DriversCompensationsModel),
    isAddCompensationFormOpen: false,

    selectedCompensationToAdd: types.maybeNull(CompensationsModel),
  })
  .views((self) => {
    return {
      get compensationsArray() {
        return Array.from(self.compensations.values());
      },
      get isOwnerOperator() {
        return self.driver_type === OWNER_OPERATOR;
      },
      get isCompanyDriver() {
        return self.driver_type === COMPANY_DRIVER;
      },
      get driverWorkingStatus() {
        const unavailableStatus =
          rootInstance.fleetPageState.availableAbsencesTypes.find(
            (status) => status === self.currentAbsence?.status
          );
        if (unavailableStatus) {
          return unavailableStatus;
        } else {
          return STATUS_AVAILABLE;
        }
      },
      get isDriverOff() {
        return !!this.current_absence_id;
      },
      get documentsArray() {
        return Array.from(self.documents.values());
      },
      get editDriverCompensationTableColumns() {
        return Object.values(
          editDriverCompensationsTableColumns(getRoot(self))
        );
      },
      getCompensationValuesOrDeductionsTableColumns(type) {
        return Object.values(
          editDriverCompensationValuesOrDeductionsTableColumns(
            getRoot(self),
            type
          )
        );
      },
      get cv() {
        return [...this.documentsArray]
          .reverse()
          .find((document) => document.document_type === DOCUMENT_TYPE_RESUME);
      },
      get reccomendationLetter() {
        return [...this.documentsArray]
          .reverse()
          .find(
            (document) => document.document_type === DOCUMENT_TYPE_REFERENCE
          );
      },
      get ssnAttachment() {
        return [...this.documentsArray]
          .reverse()
          .find((document) => document.document_type === DOCUMENT_TYPE_SSN);
      },
      get driverAdditionalFiles() {
        return [...this.documentsArray].filter(
          (document) => document.document_type === DOCUMENT_TYPE_ADDITIONAL
        );
      },
      get isChangedDuringEdit() {
        const snapshot = getSnapshot(self);
        return !isEqual(
          snapshot,
          rootInstance.fleetPageState.initialLoadedDriver
        );
      },
      get absencesArray() {
        return Array.from(self.absences.values());
      },
      get complianciesArray() {
        return Array.from(self.compliances.values());
      },
      get countriesArray() {
        return Array.from(self.countries.values());
      },
      get filteredStates() {
        const selectedCountry = self.country;
        return this.countriesArray.find((country) => {
          return country.name === selectedCountry;
        });
      },
    };
  })
  .actions((self) => {
    return {
      setSelectedCompensationToAdd(value) {
        self.selectedCompensationToAdd = value ? { ...value } : null;
      },
      setNotes(notes) {
        self.notes = notes;
      },
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      setDriverFullyFetched(status) {
        self.driverFullyFetched = status;
      },
      getCompliancesColumns() {
        return Object.values(compliancesColumnsFunc(getRoot(self)));
      },
      setCompliances(items) {
        items.forEach((item) => {
          self.compliances.put({
            ...item,
          });
        });
      },
      setCompensations(items) {
        self.compensations.clear();
        items.forEach((item) => {
          self.compensations.put({
            ...item,
          });
        });
      },
      // getEditingValue(compensation_value_id) {
      //   let selectedValue = self.compensations.filter(
      //     (org) => org.compensation_value_id === compensation_value_id
      //   )[0];
      //   rootInstance.payrollPageState.setEditingValue(selectedValue);
      // },
      createNewCompliancy() {
        // if (self.isChangedDuringEdit) {
        //     this.setIsSaveFirstModalOpen(true);
        // } else {
        this.setNewCompliancy({
          driver_id: self.driver_id,
        });
        // }
      },
      setNewCompliancy(compliancy) {
        self.newCompliancy = compliancy;
      },
      setEditedCompliancy(compliancy_id) {
        // if (compliancy_id && self.isChangedDuringEdit) {
        //     this.setIsSaveFirstModalOpen(true);
        // } else {
        self.editedCompliancy = compliancy_id;
        // }
      },
      setNewAbsence(absence) {
        self.newAbsence = absence;
      },
      setIsAddCompensationFormOpen(value) {
        self.isAddCompensationFormOpen = value;
      },
      setEditedAbsence(absence_id) {
        // if (absence_id && self.isChangedDuringEdit) {
        //     this.setIsSaveFirstModalOpen(true);
        // } else {
        self.editedAbsence = absence_id;
        // }
      },
      setCurrentAbsence(absence_id) {
        self.currentAbsence = absence_id;
      },
      createNewAbsence() {
        // if (self.isChangedDuringEdit) {
        //     this.setIsSaveFirstModalOpen(true);
        // } else {
        this.setNewAbsence({
          driver_id: self.driver_id,
        });
        // }
      },
      cancelChanges() {
        // rootInstance.fleetPageState.getEditedDriver(self.driver_id);
        rootInstance.router.setView(rootInstance.router.views.get(FLEET));
      },
      setAbsences(items) {
        const endOfDay = '23:59:59';
        items.forEach((item) => {
          item.start = moment(item.starting_date).toDate();
          item.end = moment(item.end_date + ' ' + endOfDay).toDate();
          item.title = item.status.toUpperCase();
          self.absences.put({
            ...item,
          });
        });
      },
      setIsSaveFirstModalOpen(status) {
        self.isSaveFirstModalOpen = status;
      },
      setSuccessModalOpen(status) {
        self.successModalOpen = status;
      },
      setErrorText(text) {
        this.setIsSaveFirstModalOpen(false);
        self.errorText = text;
      },
      setDocuments(items) {
        self.documents.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.documents.put({
            ...item,
          });
        });
      },
      createNewLicence() {
        // if (self.isChangedDuringEdit) {
        //     this.setIsSaveFirstModalOpen(true);
        // } else {
        this.setNewLicence({
          driver_id: self.driver_id,
        });
        // }
      },
      setNewLicence(licence) {
        self.newLicence = licence;
      },
      setLicence(licence) {
        self.licence = licence;
      },
      setAssociation(association) {
        if (!association.association_id) {
          association.association_id = getRandomInt();
        }
        self.association = association;
      },
      setTrucksAvailableForAssociation(items) {
        let associationArray = items;

        // self.association && associationArray.push(self.association);
        self.trucksAvailableForAssociation = associationArray;
      },
      updateDateOfBirth(date) {
        self.date_of_birth = date;
      },
      updateWorkingStatus(status) {
        self.working_status = status;
      },
      updateDriverType(type) {
        self.driver_type = type;
      },
      updateDriverCategory(category) {
        self.driver_category = category;
      },
      updateDriverInternalCompanyId(id) {
        self.internal_company_id = id;
      },
      updateDriverCodeName(code_name) {
        self.code_name = code_name;
      },
      updateDriverName(name) {
        self.first_name = name;
      },
      updateDriverLastName(lastName) {
        self.last_name = lastName;
      },
      updateDriverEmail(email) {
        self.email = email;
      },
      updatePhoneNumber(phoneNumber) {
        self.phone_number = phoneNumber;
      },
      updateDriverAddress(address) {
        self.street_address = address;
      },
      updateDriverCity(city) {
        self.city = city;
      },
      updateDriverState(state) {
        self.state = state;
      },
      updateDriverCountry(country) {
        self.country = country;
      },
      updateDriverPostalCode(postal_code) {
        self.postal_code = postal_code;
      },
      validateUpdateDriverForm() {
        const emailValid = validateEmail(self.email);
        const phoneNumberValid = validatePhoneNumber(self.phone_number);
        if (!self.driver_type) {
          this.setErrorText(DRIVER_TYPE_REQUIRED);
        } else if (!self.driver_category) {
          this.setErrorText(DRIVER_CATEGORY_REQUIRED);
        } else if (!self.code_name) {
          this.setErrorText(DRIVER_CODE_NAME_REQUIRED);
        } else if (!self.internal_company_id) {
          this.setErrorText(DRIVER_COMPANY_ID_REQUIRED);
        } else if (!self.first_name) {
          this.setErrorText(FIRST_NAME_REQUIRED);
        } else if (!self.last_name) {
          this.setErrorText(LAST_NAME_REQUIRED);
        } else if (!self.email) {
          this.setErrorText(EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else if (self.phone_number && !phoneNumberValid) {
          this.setErrorText(PHONE_NUMBER_ERROR_TEXT);
        } else if (!self.date_of_birth) {
          this.setErrorText(BIRTH_DATE_REQUIRED);
        } else if (
          (self.licence && !self.licence.licence_number) ||
          (self.newLicence && !self.newLicence.licence_number)
        ) {
          this.setErrorText(LICENCE_NUMBER_REQUIRED);
        } else if (
          (self.licence && !self.licence.issued_in) ||
          (self.newLicence && !self.newLicence.issued_in)
        ) {
          this.setErrorText(LICENCE_ISSUED_IN_REQUIRED);
        } else if (
          (self.licence && !self.licence.expiry_date) ||
          (self.newLicence && !self.newLicence.expiry_date)
        ) {
          this.setErrorText(LICENCE_EXPIRY_DATE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      throwEditDriverError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        this.setIsSaveFirstModalOpen(false);
        setFetchingInProgress(false);
      },
      updateDriver(documentsToUpload, documentsToDelete) {
        if (this.validateUpdateDriverForm()) {
          setFetchingInProgress(true);
          let promises = [];

          documentsToDelete.forEach((document) => {
            const documentType = document.document_type;
            if (documentType) {
              promises.push(
                new Promise((resolve, reject) => {
                  deleteDriverDocument(
                    self.driver_id,
                    document.uid,
                    documentType
                  )
                    .then((response) => {
                      resolve(response);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              );
            }
          });

          documentsToUpload.forEach((document) => {
            const documentType = document.originFileObj?.documentType;
            if (documentType && documentType !== DOCUMENT_TYPE_LICENCE) {
              promises.push(
                new Promise((resolve, reject) => {
                  const formData = new FormData();
                  formData.append('file', document.originFileObj);
                  uploadDriverDocument(self.driver_id, formData, documentType)
                    .then((response) => {
                      resolve(response);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              );
            }
          });

          if (self.newLicence) {
            promises.push(
              new Promise((resolve, reject) => {
                addDriverLicence(self.newLicence)
                  .then((licence) => {
                    const attachment = documentsToUpload.find((document) => {
                      return (
                        document.originFileObj?.documentType ===
                        DOCUMENT_TYPE_LICENCE
                      );
                    });
                    if (attachment) {
                      const formData = new FormData();
                      formData.append('file', attachment.originFileObj);
                      uploadLicenceDocument(licence.data.licence_id, formData)
                        .then((response) => {
                          resolve(response);
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    } else {
                      resolve(licence);
                    }
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            );
          }

          if (self.licence?.licence_id && self.licence.isChangedDuringEdit) {
            promises.push(
              new Promise((resolve, reject) => {
                editDriverLicence(self.licence)
                  .then((licence) => {
                    const attachment = documentsToUpload.find((document) => {
                      return (
                        document.originFileObj?.documentType ===
                        DOCUMENT_TYPE_LICENCE
                      );
                    });
                    if (attachment) {
                      const formData = new FormData();
                      formData.append('file', attachment.originFileObj);
                      uploadLicenceDocument(licence.data.licence_id, formData)
                        .then((response) => {
                          resolve(response);
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    } else {
                      resolve(licence);
                    }
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
            );
          }
          if (self.association) {
            if (self.driver_type === COMPANY_DRIVER) {
              promises.push(
                new Promise((resolve, reject) => {
                  setCompanyDriverAssociations(self)
                    .then((response) => {
                      resolve(response);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              );
            } else {
              promises.push(
                new Promise((resolve, reject) => {
                  setOwnDriverAssociations(self)
                    .then((response) => {
                      resolve(response);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
              );
            }
          }

          promises.push(
            new Promise((resolve, reject) => {
              editDriver(self)
                .then((response) => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );

          Promise.all(promises)
            .then(() => {
              this.setIsSaveFirstModalOpen(false);
              setFetchingInProgress(false);
              this.setSuccessModalOpen(true);
            })
            .catch((error) => {
              this.throwEditDriverError(error);
            });
        }
      },
      validateAddCompensationtoDriverForm() {
        if (!self.selectedCompensationToAdd) {
          this.setErrorText(COMPENSATION_SELECTION_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      addCompensationToDriver() {
        if (this.validateAddCompensationtoDriverForm()) {
          setFetchingInProgress(true);
          addCompensationToDriverPromise(
            self.driver_id,
            self.selectedCompensationToAdd.compensation_id
          )
            .then(() => {
              setFetchingInProgress(false);
              rootInstance.fleetPageState.getDriverCompensationStructuresForEditing(
                self.driver_id
              );
              this.setIsAddCompensationFormOpen(false);
            })
            .catch((error) => {
              setFetchingInProgress(false);
              const errorText = getErrorText(error);
              notification.error({
                message: ERROR_ADDING_NEW_COMPENSATION_TO_DRIVER,
                description: errorText,
              });
            });
        }
      },
    };
  });
