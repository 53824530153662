import React from 'react';
import {
  ADDRESS,
  COUNTRY_STATE_CITY,
  DELETE_RECORD_TEXT,
  EDIT_RECORD_TEXT,
  LOCATION_NAME,
  COMPANY_OWNER,
  ZONE,
} from '../../constants';
import trash from '../../assets/images/trash.svg';
import edit from '../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.locationsPageState.setLocationToBeDeleted(record.location_id);
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.locationsPageState.getEditedLocation(record.location_id);
};

export const locationsColumnsFunc = (rootInstance) => ({
  '1': {
    title: LOCATION_NAME,
    dataIndex: 'location_name',
    key: 'location_name',
  },
  '2': {
    title: COMPANY_OWNER,
    key: 'owner_company',
    dataIndex: 'owner_company',
    render: (text, record) => {
      return <span>{record.owner?.company}</span>;
    },
  },
  '3': {
    title: COUNTRY_STATE_CITY,
    key: 'country_state_city',
    dataIndex: 'country_state_city',
    render: (text, record) => {
      return (
        <span>
          {record.country} / {record.state} / {record.city}
        </span>
      );
    },
  },
  '4': {
    title: ADDRESS,
    key: 'address',
    dataIndex: 'address',
  },
  '5': {
    title: ZONE,
    key: 'zone',
    dataIndex: 'zone',
  },
  '6': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          {rootInstance.userState.canRemoveLocations && (
            <Tooltip title={DELETE_RECORD_TEXT}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          )}
          {rootInstance.userState.canEditLocations && (
            <div onClick={(e) => onClickEdit(e, record)}>
              <Tooltip title={EDIT_RECORD_TEXT}>
                <img src={edit} alt="edit" className="pointer ph10" />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
  },
});
