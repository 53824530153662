import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Select } from 'antd';
import ErrorBox from '../../../../ErrorBox';
import { useMst } from '../../../../../state/RootModel';
import addCarrierIcon from '../../../../../assets/images/add-carrier.svg';
import FormPageTitle from '../../../../FormPageTitle';
import { EDIT_CARRIER, SAVE_CHANGES, STATUS } from '../../../../../constants';
import CarrierDetails from './CarrierDetails';
import InsuranceDetails from './InsuranceDetails';
import LocationDetails from './LocationDetails';
import EquipmentDetails from './EquipmentDetails';

const { Option } = Select;

const EditProbillModal = () => {
  const {
    carriersPageState: {
      editedCarrier,
      setEditedCarrier,
      availableCarrierStatuses,
    },
  } = useMst();

  const onSaveChanges = () => {
    editedCarrier.saveCarrier();
  };

  const onCancel = () => {
    setEditedCarrier(null);
  };

  const onSelectStatus = (value) => {
    editedCarrier.setStatus(value);
  };

  return (
    <div>
      <Modal
        visible={!!editedCarrier}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={1000}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addCarrierIcon} title={EDIT_CARRIER} />
            <div className="input-horizontal align-center ml10">
              <span className="no-wrap mr10">{STATUS}</span>
              <Select
                onChange={onSelectStatus}
                className="text-capitalize"
                value={editedCarrier.status}
              >
                {availableCarrierStatuses.map((status, index) => (
                  <Option
                    key={index}
                    value={status}
                    className="text-capitalize"
                  >
                    {status}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <CarrierDetails />
          <InsuranceDetails />
          <LocationDetails />
          <EquipmentDetails />

          <ErrorBox obj={editedCarrier} />

          <button className="btn primary" onClick={onSaveChanges}>
            {SAVE_CHANGES}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditProbillModal);
