import { Empty, Select, Table } from 'antd';
import React from 'react';
import {
  EMPTY_COMPENSATION_VALUES_TABLE_TEXT,
  FILTER_COMPENSATION_VALUES,
  FILTER_DEFAULT_VALUE,
} from '../../../constants/payroll';
import { useMst } from '../../../state/RootModel';
import { observer } from 'mobx-react';

const { Option } = Select;

export const CompensationsValuesComponent = () => {
  const {
    payrollPageState: {
      compensationTypes,
      compensationValuesByType,
      setValueFilterType,
      valueFilterType,
      getCompensationValuesByType,
      getCompensationValuesTableColumns,
    },
  } = useMst();

  const onFilterSelect = (e) => {
    setValueFilterType(e);
    getCompensationValuesByType();
  };

  return (
    <div className="pv20 compensation-structures-component">
      <div className="filters-wrapper align-center justify-end">
        <p className="mb20">{FILTER_COMPENSATION_VALUES}</p>
        <Select
          className="text-capitalize ml20 mb20"
          style={{ width: 200 }}
          defaultValue={FILTER_DEFAULT_VALUE}
          onChange={onFilterSelect}
        >
          {compensationTypes.map((type, index) => (
            <Option key={index} value={type} className="text-capitalize">
              {type}
            </Option>
          ))}
        </Select>
      </div>
      <Table
        className="compensations-table"
        rowKey={(record, index) => index.toString()}
        columns={getCompensationValuesTableColumns()}
        dataSource={compensationValuesByType.map((type) => {
          return {
            compensation_value: type,
            compensation_type: valueFilterType,
          };
        })}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_COMPENSATION_VALUES_TABLE_TEXT}
            />
          ),
        }}
      />
    </div>
  );
};

export default observer(CompensationsValuesComponent);
