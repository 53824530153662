import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import plusCircle from '../../../../../assets/images/plus-circle.svg';
import CarrierEquipmentDetail from './CarrierEquipmentDetail';
import { EQUIPMENT_DETAILS } from '../../../../../constants';

const CarrierEquipment = () => {
  const {
    carriersPageState: { newCarrier },
  } = useMst();

  const onAddEquipmentType = () => {
    newCarrier.addNewEquipmentItem({});
  };

  return (
    <div className="page-section shipment-details-section w100">
      <div className="subsection-header align-center">
        <p>{EQUIPMENT_DETAILS}</p>
        <img
          src={plusCircle}
          alt="add equipment"
          className="ml20 pointer"
          onClick={onAddEquipmentType}
        />
      </div>
      <div className="subsection-content flex-column">
        {newCarrier.equipmentsListArray.map((item, index) => (
          <CarrierEquipmentDetail key={index} />
        ))}
      </div>
    </div>
  );
};

export default observer(CarrierEquipment);
