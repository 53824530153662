import { types, getRoot } from 'mobx-state-tree';
import { DocumentModel } from '../../DocumentModel';
import { documentsColumnsFunc } from '../../DocumentationTableColumns';
import { RegistrationModel } from '../RegistrationModel';
import { registrationsColumnsFunc } from '../RegistrationTableColumns';
import { InspectionModel } from '../InspectionModel';
import { trailerInspectionsColumnsFunc } from '../InspectionTableColumns';
import { MaintenanceModel } from '../MaintenanceModel';
import { maintenanceColumnsFunc } from '../MaintenanceTableColumns';
import { associationsColumnsFunc } from './AssociationsTableColumns';
import { AssociationModel } from './AssociationModel';

export const TrailerViewModel = types
  .model('TrailerViewModel', {
    vehicle_id: types.identifierNumber,
    vin: types.maybe(types.maybeNull(types.string)),
    ownership_type: types.maybe(types.maybeNull(types.string)),
    type_of_financing: types.maybe(types.maybeNull(types.string)),
    date_hired: types.maybe(types.maybeNull(types.string)),
    vehicle_model_type: types.maybe(types.maybeNull(types.string)),
    vehicle_make: types.maybe(types.maybeNull(types.string)),
    vehicle_model_name: types.maybe(types.maybeNull(types.string)),
    vehicle_production_year: types.maybe(types.maybeNull(types.number)),
    vehicle_weight: types.maybe(types.maybeNull(types.number)),
    vehicle_weight_unit: types.maybe(types.maybeNull(types.string)),
    vehicle_body_class: types.maybe(types.maybeNull(types.string)),
    vehicle_latitude: types.maybe(types.maybeNull(types.number)),
    vehicle_longitude: types.maybe(types.maybeNull(types.number)),
    status: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),
    documents: types.map(DocumentModel),
    registrations: types.map(RegistrationModel),
    inspections: types.map(InspectionModel),
    maintenances: types.map(MaintenanceModel),

    isGeneralInfoSectionOpen: true,
    isRegistrationSectionOpen: true,
    isDocumentationSectionOpen: true,
    isInspectionsSectionOpen: true,
    isMaintenanceSectionOpen: true,
    isAssociationsSectionOpen: true,

    associations: types.map(AssociationModel),
    vehicleFullyFetched: false,
    internal_company_id: types.maybe(types.maybeNull(types.string)),
    connected_truck_data: types.map(AssociationModel),
  })
  .volatile((self) => {
    return {
      position: [51.505, -0.09],
    };
  })
  .views((self) => {
    return {
      get associationsArray() {
        return Array.from(self.associations.values());
      },
      get documentsArray() {
        return Array.from(self.documents.values());
      },
      get registrationsArray() {
        return Array.from(self.registrations.values());
      },
      get activeRegistration() {
        return [...this.registrationsArray]
          .reverse()
          .find((registration) => registration.is_active);
      },
      get inspectionsArray() {
        return Array.from(self.inspections.values());
      },
      get maintenancesArray() {
        return Array.from(self.maintenances.values());
      },
      get truckAssociation() {
        return Array.from(self.connected_truck_data.values());
      },
    };
  })
  .actions((self) => {
    return {
      setTrailerTruckAssociation(items) {
        self.connected_truck_data.clear();
        items.forEach((item) => {
          self.connected_truck_data.put({
            ...item,
          });
        });
      },
      setAssociations(items) {
        self.associations.clear();
        items.forEach((item) => {
          self.associations.put({
            ...item,
          });
        });
      },
      getAssociationsColumns() {
        return Object.values(associationsColumnsFunc(getRoot(self)));
      },
      setIsAssociationsSectionOpen(value) {
        self.isAssociationsSectionOpen = value;
      },
      setVehicleFullyFetched(status) {
        self.vehicleFullyFetched = status;
      },
      setMaintenances(items) {
        self.maintenances.clear();
        items.forEach((item) => {
          self.maintenances.put({
            ...item,
          });
        });
      },
      getMaintenanceColumns() {
        return Object.values(maintenanceColumnsFunc(getRoot(self)));
      },
      setInspections(items) {
        self.inspections.clear();
        items.forEach((item) => {
          self.inspections.put({
            ...item,
          });
        });
      },
      getInspectionsColumns() {
        return Object.values(trailerInspectionsColumnsFunc(getRoot(self)));
      },
      setDocuments(items) {
        self.documents.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.documents.put({
            ...item,
          });
        });
      },
      setRegistrations(items) {
        self.registrations.clear();
        items.forEach((item) => {
          self.registrations.put({
            ...item,
          });
        });
      },
      setIsGeneralInfoSectionOpen(status) {
        self.isGeneralInfoSectionOpen = status;
      },
      getDocumentsColumns() {
        return Object.values(documentsColumnsFunc(getRoot(self)));
      },
      getRegistrationsColumns() {
        return Object.values(registrationsColumnsFunc(getRoot(self)));
      },
      setIsDocumentationSectionOpen(status) {
        self.isDocumentationSectionOpen = status;
      },
      setIsRegistrationSectionOpen(status) {
        self.isRegistrationSectionOpen = status;
      },
      setIsInspectionsSectionOpen(status) {
        self.isInspectionsSectionOpen = status;
      },
      setIsMaintenanceSectionOpen(status) {
        self.isMaintenanceSectionOpen = status;
      },
    };
  });
