import { Tooltip } from 'antd';
import React from 'react';
import {
  ATTACHED_FILE,
  EXPIRY_DATE,
  ISSUED_IN,
  ISSUED_ON,
  LICENCE_NUMBER,
  VIEW_FILE,
} from '../../../constants';

export const licencesColumnsFunc = (rootInstance) => ({
  '1': {
    title: LICENCE_NUMBER,
    dataIndex: 'licence_number',
    key: 'licence_number',
  },
  '2': {
    title: ISSUED_IN,
    dataIndex: 'issued_in',
    key: 'issued_in',
  },
  '3': {
    title: ISSUED_ON,
    dataIndex: 'issued_on',
    key: 'issued_on',
  },
  '4': {
    title: EXPIRY_DATE,
    dataIndex: 'expiry_date',
    key: 'expiry_date',
  },
  '5': {
    title: ATTACHED_FILE,
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <Tooltip placement="top" title={VIEW_FILE}>
          <a href={record.licenceAttachment?.document_url} target="blank">
            {record.licenceAttachment?.name}
          </a>
        </Tooltip>
      );
    },
  },
});
