import React from 'react';
import { useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import EditDriverComponent from '../../components/fleet/EditDriver/EditDriverComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import SuccessModal from '../../components/SuccessModal';
import AddAbsenceModal from '../../components/fleet/EditDriver/AddAbsenceModal';
import EditAbsenceModal from '../../components/fleet/EditDriver/EditAbsenceModal';
import ConfirmDeleteAbsenceModal from '../../components/fleet/EditDriver/ConfirmDeleteAbsenceModal';
import AddCompliancyModal from '../../components/fleet/EditDriver/AddCompliancyModal';
import EditCompliancyModal from '../../components/fleet/EditDriver/EditCompliancyModal';
import PageRoutes from '../../utils/PageRoutes';
import {
  EDIT_DRIVER,
  MODAL_SUCCESS_EDIT_DRIVER_TEXT,
  MODAL_SUCCESS_EDIT_DRIVER_BUTTON_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../constants';
import EditCompensationValueModal from '../../components/fleet/EditDriver/EditCompensationValueModal';
import EditCompensationDeductionModal from '../../components/fleet/EditDriver/EditCompensationDeductionModal';

const EditDriverPage = () => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  const backLink = PageRoutes.ViewDriver.name;

  const params = { driverId: editedDriver?.driver_id.toString() };

  return (
    <div>
      <div className="page edit-driver-page">
        <BreadcrumsComponent
          page={EDIT_DRIVER}
          breadcrumbsBackLink={PageRoutes.Fleet.name}
        />
        <SuccessModal
          title={MODAL_SUCCESS_TITLE}
          text={MODAL_SUCCESS_EDIT_DRIVER_TEXT}
          buttonText={MODAL_SUCCESS_EDIT_DRIVER_BUTTON_TEXT}
          backLink={backLink}
          params={params}
          open={editedDriver?.successModalOpen}
          closeModal={() => editedDriver.setSuccessModalOpen(false)}
        />
        <div className="page-content">
          <EditDriverComponent />
          <AddCompliancyModal />
          <EditCompliancyModal />
          <AddAbsenceModal />
          <EditAbsenceModal />
          <ConfirmDeleteAbsenceModal />
          <EditCompensationValueModal />
          <EditCompensationDeductionModal />
        </div>
      </div>
    </div>
  );
};

export default observer(EditDriverPage);
