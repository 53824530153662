export const LOGIN = 'Login';
export const RESET_PASSWORD = 'Reset password';
export const FORGOT_PASSWORD = 'Forgot password?';
export const LOGIN_TO_ACCOUNT = 'Login to your account?';
export const USERNAME = 'Username';
export const PASSWORD = 'Password';
export const FULLNAME = 'Full name';
export const EMAIL = 'Email';
export const PROFILE_PICTURE = 'Profile picture';
export const IMAGE_UPLOAD = 'Image upload';
export const IMAGE = 'Image';
export const PHONE_NUMBER = 'Phone number';
export const ACCOUNT_STATUS = 'Account status';
export const WORKING_STATUS = 'Working status';
export const ABSENCE_PERIOD = 'Absence period';
export const TRACKING_DEPARTMENT = 'Tracking department (Manager only)';
export const DEPARTMENT = 'Department';
export const CHOOSE_DEPARTMENT_TEXT = 'If there are any created (Optional)';
export const MANAGER = 'Manager';
export const SAFETY = 'Safety';
export const MAINTENANCE = 'maintenance';
export const ACCOUNTING = 'Accounting';
export const ADD = 'Add';
export const ADD_EMPLOYEE = 'Add employee';
export const ADD_COMPENSATION = 'Add compensation';
export const ADD_COMPENSATION_VALUE = 'Add new value';
export const ADD_COMPENSATION_DEDUCTION = 'Add new deduction';
export const EDIT_COMPENSATION = 'Edit compensation';
export const ADD_COMPENSATION_TO_DRIVER = 'Add compensation to driver';
export const SET_AS_ACTIVE = 'Set as active';
export const EDIT = 'Edit';
export const SAVE_CHANGES = 'Save changes';
export const SAVE = 'Save';
export const DISCARD_CHANGES = 'Discard changes';
export const DISCARD = 'Discard';
export const TYPE = 'Type';
export const ACTION = 'Action';
export const ACTION_ID = 'Action ID';
export const ACTION_TYPE = 'Action Type';
export const STATUS = 'Status';
export const AVAILABLE = 'available';
export const ACTIVE = 'active';
export const INACTIVE = 'inactive';
export const BLOCKED = 'Blocked';
export const BANNED = 'Banned';
export const NEW_PASSWORD = 'New password';
export const RETYPE_PASSWORD = 'Re-type password';
export const CONFIRM_PASSWORD = 'Confirm password';
export const CHANGE_PASSWORD = 'Change password';
export const CURRENT_PASSWORD = 'Current password';
export const CURRENT_PASSWORD_REQUIRED_TEXT =
  'Current password field is required.';
export const NEW_PASSWORD_REQUIRED_TEXT = 'New password field is required.';
export const CONFIRMED_PASSWORD_REQUIRED_TEXT =
  'Confirmed password field is required.';
export const CODE = '6-digit code';
export const CODE_LENGTH_INVALID = 'Code must have exactly 6 digits!';
export const LOGIN_ERROR_TEXT = 'Wrong email and/or password';
export const SERVER_ERROR_TEXT = 'Something went wrong. Please try again later';
export const EMAIL_ERROR_TEXT = 'Invalid email address';
export const CODE_NAME_REQUIRED = 'Code name is required';
export const PASSWORD_ERROR_TEXT = 'Please fill out password field';
export const USER_NOT_FOUND_ERROR_TEXT = 'User not found';
export const BACK = 'Back';
export const NEXT = 'Next';
export const CONTINUE = 'Continue';
export const FORGOT_PASSWORD_SUCCESS_EMAIL =
  'Check your inbox! Please enter the code you recieved in field above.';
export const FORGOT_PASSWORD_FAILURE =
  'Didn’t get the email? Click here and we will try again.';
export const FORGOT_PASSWORD_CODE_RESENT_TEXT = 'We just sent another email.';
export const PASSWORDS_NOT_MATCHING_ERROR_TEXT =
  'Passwords not maching, try again.';
export const PASSWORD_SUCCESSFULLY_CHANGED_TEXT =
  'Your password is successfully changed!';
export const PASSWORD_SUCCESSFULLY_SET_TEXT =
  'Your password is successfully set!';
export const LOGOUT_FAILURE = 'Unable to logout user!';
export const SUCCESS = 'Success';
export const ENTER_LOGIN_CREDENTIALS_TEXT =
  'Please enter email and password associated with your account to proceed.';
export const ENTER_EMAIL_FORGOT_PASSWORD_TEXT =
  'Enter Email associated with the account to reset your password.';
export const PASSWORD_INSTRUCTIONS_TEXT =
  'Password must be 8 characters long and contain at least 1-Uppercase, 1-Lowercase and 1 number.';
export const EMAIL_INPUT_PLACEHOLDER = 'Email Address';

export const TITLE_404 = '404 - PAGE NOT FOUND';
export const TEXT_404 =
  'Data you are looking for might not exist, has been deleted or is temporarily unavailable.';
export const GO_BACK = 'Go back';

export const BACKGROUND = 'Background';
export const LOGO = 'Logo';

export const TERMS_OF_USE = 'Terms of Use';
export const PRIVACY_POLICY = 'Privacy Policy';
export const HELP_SUPPORT = 'Help & Support';
export const OUR_WEBSITE = 'Our Website';
export const CLOSE = 'Close';

export const CLEAR_DETAILS = 'Clear details';

export const SHOW = 'Show';
export const HIDE = 'Hide';
export const EMPLOYEE_SEARCH_PLACEHOLDER_TEXT = 'Search Employees';
export const FLEET_SEARCH_PLACEHOLDER_TEXT = 'Search Fleet';
export const BROKERAGE_SEARCH_PLACEHOLDER_TEXT =
  'Search Shipments by ID, Driver, VIN#, Status';
export const VIEW_ARCHIVED_TEXT = 'View Archived';
export const VIEW_ACTIVE_TEXT = 'View Active';
export const SELECT = 'Select';
export const ALL = 'All';
export const ARCHIVED = 'archived';
export const REACTIVATE_EMPLOYEE = 'Reactivate employee';
export const REACTIVATE_SELECTED_EMPLOYEES = 'Reactivate selected employees';

export const WELCOME = 'Welcome';
export const ENTER_NEW_PASSWORD_TEXT = 'enter a new password for your account';
export const CREATE_PASSWORD_TEXT =
  'Welcome, to set up your account provide the information below...';
export const CREATE_ACCOUNT = 'Create account';
export const INVALID_TOKEN_TEXT =
  'Your reset password token seems invalid or is expired. Please try again.';
export const DEFAULT_ERROR_TEXT =
  'Something went wrong. Please try again later.';

export const GENERAL_INFORMATION = 'General information';
export const DRIVER_PAYROLL = 'Driver Payroll';
export const EMPLOYEE_DOCUMENTATION = 'Employee documentation';
export const ADVANCED_OPTIONS = 'Advanced options';
export const FIRST_NAME = 'First name';
export const LAST_NAME = 'Last name';
export const UPLOAD_DOCUMENTS_TEXT = 'Upload scanned documents (optional)';
export const FETCH_DEPARTMENTS_ERROR = 'Error fetching departments!';
export const FETCH_WORKING_STATUSES_ERROR =
  'Error fetching available working statuses!';
export const FETCH_ACCOUNT_STATUSES_ERROR =
  'Error fetching available account statuses!';
export const FETCH_EMPLOYEES_ERROR = 'Error fetching employees!';
export const FETCH_EMPLOYEE_ERROR = 'Error fetching employee!';
export const FETCH_DRIVER_ERROR = 'Error fetching driver!';
export const FETCH_ROLES_ERROR = 'Error fetching available roles!';
export const FETCH_ACCOUNT_SETTINGS_ERROR = 'Error fetching account settings';
export const FETCH_EMPLOYEE_DOCUMENTATION_ERROR =
  'Error fetching employee documentation!';
export const FETCH_EMPLOYEE_IMAGE_ERROR = 'Error fetching employee photo!';
export const FETCH_EMPLOYEE_ABSENCE_ERROR = 'Error fetching employee absence!';
export const ABSENCE_ERROR_TEXT = 'Absence dates are missing or invalid.';
export const USER_TYPE = 'User type';
export const USER_ROLE = 'User role';
export const ACTIVE_ROLE = 'Active role';
export const DEPARTMENTS = 'Departments';
export const CHOOSE_DEPARTMENTS_TEXT =
  'Choose departments that this manager will track';
export const DEPARTMENT_NOT_ASSIGNED = 'Department not assigned';
export const CHOOSE_DEPARTMENTS_CURRENT_USER_TEXT = 'Departments you track';
export const FIRST_NAME_REQUIRED = 'First name field is required';
export const LAST_NAME_REQUIRED = 'Last name field is required';
export const EMAIL_REQUIRED = 'Email field is required';
export const BILLING_EMAIL_REQUIRED = 'Billing email field is required';
export const PIB_REQUIRED = 'PIB field is required';
export const PHONE_NUMBER_REQUIRED = 'Phone number field is required';
export const PHONE_NUMBER_ERROR_TEXT = 'Phone number is invalid';
export const USER_TYPE_REQUIRED = 'User type field is required';
export const MODAL_SUCCESS_TITLE = 'Successful!';
export const MODAL_SUCCESS_ADD_EMPLOYEE_TEXT =
  'Information to create an account has been successfuly sent to the email provided. You will be notified when user logins to the platform.';
export const MODAL_SUCCESS_ADD_EMPLOYEE_BUTTON_TEXT = 'Back to Employees';
export const MODAL_SUCCESS_EDIT_EMPLOYEE_TEXT =
  'Employee data successfuly updated.';
export const MODAL_SUCCESS_EDIT_EMPLOYEE_BUTTON_TEXT = 'Back to Employee';
export const MODAL_SUCCESS_EDIT_PROFILE_TEXT = 'Profile successfuly updated.';
export const MODAL_SUCCESS_EDIT_PROFILE_BUTTON_TEXT = 'Back to Account';
export const BACK_TO = 'Back to';
export const EDIT_EMPLOYEE = 'Edit employee';
export const DELETE_EMPLOYEE = 'Delete employee';
export const DELETE_SELECTED_EMPLOYEES = 'Delete selected employees';

export const NO_ROLES_ASSIGNED = 'No roles assigned';
export const NO_ROLE_SELECTED = 'No role selected';
export const LOGIN_AS = 'Login as';
export const ACCOUNT_SETTINGS = 'Account settings';
export const WELCOME_MODAL_TITLE = 'Welcome!';
export const WELCOME_MODAL_TEXT =
  'Welcome to the platform. To continue the use of the platform you need to finish seting up your account.';
export const WELCOME_MODAL_BUTTON_TEXT = 'Go to profile';
export const CONFIRM = 'Confirm';
export const CANCEL = 'Cancel';
export const CONFIRM_DELETE_MODAL_TEXT = 'Are you sure you want to delete';
export const CONFIRM_DELETE_NOTE_MODAL_TEXT =
  'Are you sure you want to delete selected note?';
export const CONFIRM_DELETE_USERS_TEXT =
  'Are you sure you want to delete selected users?';
export const CONFIRM_REACTIVATE_MODAL_TEXT =
  'Are you sure you want to reactivate';
export const REACTIVATE_EMPLOYEE_ERROR = 'Error reactivating employee!';
export const REACTIVATE_EMPLOYEES_ERROR = 'Error reactivating employees!';
export const CONFIRM_REACTIVATE_USERS_TEXT =
  'Are you sure you want to reactivate selected users?';
export const DELETE_EMPLOYEE_ERROR = 'Error deleting employee!';
export const DELETE_EMPLOYEES_ERROR = 'Error deleting employees!';
export const DELETE_MODAL_SUCCESS_TITLE = 'Deleted!';
export const REACTIVATE_EMPLOYEE_MODAL_SUCCESS_TITLE = 'Reactivated!';
export const DELETE_EMPLOYEE_MODAL_SUCCESS_TEXT =
  'Employee was succesfully deleted.';
export const REACTIVATE_EMPLOYEE_MODAL_SUCCESS_TEXT =
  'Employee was succesfully reactivated.';
export const REACTIVATE_EMPLOYEES_MODAL_SUCCESS_TEXT =
  'Employees were succesfully reactivated.';
export const DELETE_EMPLOYEES_MODAL_SUCCESS_TEXT =
  'Employees were succesfully deleted.';
export const DELETE_EMPLOYEE_MODAL_SUCCESS_BUTTON_TEXT = 'Back to employees';
export const RESUME = 'resume';
export const REFERENCE = 'reference';
export const EMPLOYEE_IMAGE = 'employee image';
export const DATE = 'Date';
export const UPDATE_DATE = 'Update date';

export const EMPTY_EMPLOYEES_TABLE_TEXT = 'No drivers in this fleet';
export const DRIVERS = 'Drivers';
export const DRIVER = 'Driver';
export const DRIVER_TYPE = 'Driver type';
export const OVERALL_DRIVER_SCORE = 'Overall Driver Score';
export const CITY_STATE = 'City / State';
export const LICENCE = 'Licence';
export const EDIT_DRIVER = 'Edit driver';
export const DELETE_DRIVER = 'Delete driver';
export const COPY_TO_CLIPBOARD = 'Copy to clipboard';
export const COPIED_TO_CLIPBOARD = 'Copied to clipboard!';

export const TRUCKS = 'Trucks';
export const TRUCK = 'Truck';
export const BLUE_TRUCK = 'Blue truck';
export const TRUCK_ID = 'Truck ID';
export const ASSIGNED_DRIVER = 'Assigned driver';
export const MAKE = 'Make';
export const YEAR = 'Year';
export const SELECT_YEAR = 'Select year';
export const VIN = 'VIN#';
export const PRICE = 'Price';
export const EMPTY_TRUCKS_TABLE_TEXT = 'No trucks in this fleet';
export const EDIT_TRUCK = 'Edit truck';
export const DELETE_TRUCK = 'Delete truck';
export const TRUCK_TYPE = 'Truck type';
export const TRUCK_MAKE = 'Truck make';

export const TRAILERS = 'Trailers';
export const TRAILER = 'Trailer';
export const BLUE_TRAILER = 'Blue trailer';
export const TRAILER_ID = 'Trailer ID';
export const LINKED_TRUCK = 'Linked truck';
export const EMPTY_TRAILERS_TABLE_TEXT = 'No trailers in this fleet';
export const EDIT_TRAILER = 'Edit trailer';
export const DELETE_TRAILER = 'Delete trailer';

export const FLEET_OVERVIEW = 'Fleet Overview';
export const TOTAL_AMOUNT_REMAINING = 'Total Amount Remaining';
export const AVERAGE_MARKET_VALUE_RATE = 'Average Market Value Rate';
export const TOTAL_REMAINING_MONTHS = 'Total Remaining Months';
export const AVERAGE_DEPRECATION_RATE = 'Average Depreciation Rate';
export const MONTHS = 'Months';

export const UTILIZATION = 'Utilization';
export const MAKE_MODEL_YEAR = 'Make/Model/Year';
export const IDLE_TIME = 'Idle time';
export const DRIVING_TIME = 'Driving time';

export const LEASING_CALCULATION = 'Leasing Calculation';
export const TRUCK_PRICE = 'Truck Price';
export const LEASE_RATE = 'Lease Rate';
export const REMAINING_AMOUNT = 'Remaining Amount';
export const PARTICIPATION = 'Participation';
export const PAYMENT_PERIOD = 'Payment Period';
export const REMAINING_PERIOD = 'Remaining Period';
export const MVR = 'MVR';
export const DPR = 'DPR';
export const MARKET_VALUE_RATE = 'Market value rate';
export const DEPRECIATION_RATE = 'Depreciation rate';

export const EMPLOYEE_AGREEMENT = 'employee agreement';
export const OTHER_DOCUMENTS = 'Other documents';

export const ADD_TRUCK = 'Add truck';
export const MODAL_SUCCESS_ADD_TRUCK_TEXT = 'Truck successfuly added to fleet.';
export const MODAL_SUCCESS_ADD_TRUCK_BUTTON_TEXT = 'Back to fleet';
export const TYPE_OF_FINANCING = 'Type of Financing';
export const TRUCK_INFORMATION = 'Truck information';
export const SHIPMENT_INFORMATION = 'Shipment information';
export const SHIPMENT_SIZE = 'Shipment size';
export const OWNERSHIP_TYPE = 'Ownership type';
export const MODEL = 'Model';
export const GROSS_WEIGHT = 'Gross weight';
export const CLASS = 'Class';
export const FUEL_TYPE = 'Fuel type';
export const DATE_HIRED = 'Date hired';
export const MODAL_SUCCESS_EDIT_TRUCK_TEXT = 'Truck successfuly updated.';
export const MODAL_SUCCESS_EDIT_TRUCK_BUTTON_TEXT = 'Back to Truck';
export const REGISTRATION_INFORMATION = 'Registration information';
export const LICENCE_PLATE = 'Licence plate';
export const LICENCE_STATE = 'Licence state';
export const EDIT_REGISTRATION_TEXT = 'Edit registration';
export const DELETE_REGISTRATION_TEXT = 'Delete registration';
export const ADD_REGISTRATION_TEXT = 'Add registration';
export const LICENCE_PLATE_REQUIRED = 'Licence plate field is required';
export const LICENCE_STATE_REQUIRED = 'Licence state field is required';
export const LICENCE_EXPIRY_DATE_REQUIRED =
  'Licence expiration date field is required';
export const TRACKING_INFORMATION = 'Tracking information';
export const TELEMATICS_PROVIDER = 'Telematics provider';
export const TRACKING_ID = 'Tracking ID';
export const IFTA = 'IFTA';
export const TOLL_PROVIDER = 'Toll provider';
export const TOLL_NUMBER = 'Toll number';
export const ODOMETER_WHEN_HIRED = 'Odometer when hired';
export const ODOMETER = 'Odometer';
export const FINANCING_INFORMATION = 'Financing information';
export const FINANCING_USER = 'Financing user';
export const FINANCING_PROVIDER = 'Financing provider';
export const FINANCING_DETAILS = 'Financing details';
export const DOWN_PAYMENT = 'Down payment';
export const TOTAL_INTEREST = 'Total interest';
export const DUE_VALUE = 'Due value';
export const RATE = 'Rate';
export const CALCULATOR_DR_LR = 'Calculator (depreciation rate, lease rate)';
export const MARKET_VALUE_RATIO = 'Market value ratio';
export const EXPECTED_VEHICLE_LIFE = 'Expected vehicle service life';
export const ANNUAL_LEASE = 'Annual lease';
export const ANNUAL_DEPRECIATION_RATE = 'Annual Depreciation Rate';
export const PARTICIPATION_RATE = 'Participation rate';
export const INSURANCE_INFORMATION = 'Insurance information';
export const LINK_TO_DRIVER =
  'Link to Driver - Licence - Expiry Date - City - State';
export const LINK_TO_TRAILER =
  'Link to trailer - Licence - Expiry Date - City - State';
export const REGISTRATION = 'registration';
export const TRUCK_PICTURES = 'Truck pictures';
export const TRUCK_TYPE_REQUIRED = 'Truck type field is required';
export const TRUCK_CLASS_REQUIRED = 'Truck class field is reqiured.';
export const OWNERSHIP_TYPE_REQUIRED = 'Ownership type field is reqiured.';
export const VIN_REQUIRED = 'VIN field is required';
export const STATUS_REQUIRED = 'Status field is required';
export const FINANCING_TYPE_REQUIRED = 'Financing type field is required';
export const KM = 'km';
export const FETCH_CUSTOMERS_ERROR = 'Error fetching customers!';
export const FETCH_TRUCKS_ERROR = 'Error fetching trucks!';
export const FETCH_TRAILERS_ERROR = 'Error fetching trailers!';
export const FETCH_DRIVERS_ERROR = 'Error fetching drivers!';
export const FETCH_VEHICLE_ERROR = 'Error fetching vehicle!';
export const FETCH_PERMISSIONS_ERROR = 'Error fetching user permissions!';
export const FETCH_ROLE_ERROR = 'Error fetching user role!';

export const FETCH_VEHICLE_OWNERSHIP_TYPES_ERROR =
  'Error fetching vehicle ownership types!';
export const FETCH_VEHICLE_FINANCING_TYPES_ERROR =
  'Error fetching vehicle financing types!';
export const FETCH_VEHICLE_FUEL_TYPES_ERROR =
  'Error fetching vehicle fuel types'!;
export const UPDATE_REASON = 'Update reason';
export const OUT_OF_SERVICE = 'out of service';
// export const FLEET_OUT_OF_SERVICE_STATUS = 'Out_of_service';
export const FLEET_OUT_OF_SERVICE_STATUS = 'Out of service';
export const REASON = 'Reason';
export const MAINTENANCE_TYPE = 'Maintenance type';
export const INSPECTION_TYPE = 'Inspection type';
export const MAINTENANCE_NOTE = 'Maintenance note';
export const INSPECTION_NOTE = 'Inspection note';
export const INSPECTION_NOTES = 'Inspection notes';
export const NOTE_PLACEHOLDER = 'Write something...';
export const MAINTENANCE_DOCUMENT = 'Maintenance document';
export const INSPECTION_DOCUMENT = 'Inspection document';
export const INSPECTION_INFORMATION = 'Inspection information';
export const ADD_INSPECTION = 'Add inspection';
export const DATE_TIME = 'Date / Time';
export const DEFECTS = 'Defects';
export const NOTES = 'Notes';
export const CUSTOMER_NOTES = 'Customer notes';
export const CARRIER_NOTES = 'Carrier notes';
export const SHIPPER_NOTES = 'Shipper notes';
export const RECEIVER_NOTES = 'Receiver notes';
export const OTHER_NOTES = 'Other notes';
export const SIGNATURE = 'Signature';
export const EMPTY_INSPECTIONS_TABLE_TEXT = 'No inspections reported';
export const EMPTY_MAINTENANCE_TABLE_TEXT = 'No maintenance reported';
export const EMPTY_REGISTRATION_TABLE_TEXT = 'No registrations reported';
export const DELETE_VEHICLE_ERROR = 'Error deleting vehicle!';
export const DELETE_VEHICLE_MODAL_SUCCESS_TEXT =
  'Vehicle was succesfully deleted.';
export const DELETE_VEHICLE_MODAL_SUCCESS_BUTTON_TEXT = 'Back to fleet';

export const ADD_TRAILER = 'Add trailer';
export const MODAL_SUCCESS_ADD_TRAILER_TEXT =
  'Trailer successfuly added to fleet.';
export const MODAL_SUCCESS_ADD_TRAILER_BUTTON_TEXT = 'Back to fleet';
export const TRAILER_INFORMATION = 'Trailer information';
export const TRAILER_TYPE = 'Trailer type';
export const TRAILER_PRICE = 'Trailer price';
export const MODAL_SUCCESS_EDIT_TRAILER_TEXT = 'Trailer successfuly updated.';
export const MODAL_SUCCESS_EDIT_TRAILER_BUTTON_TEXT = 'Back to Trailer';
export const TRAILER_PICTURES = 'Trailer pictures';

export const VIEW_DRIVER = 'View driver';
export const ADD_DRIVER = 'Add driver';
export const MODAL_SUCCESS_ADD_DRIVER_TEXT =
  'Driver successfuly added to fleet.';
export const MODAL_SUCCESS_ADD_COMPENSATION_TEXT =
  'Compensation successfuly added to payroll.';
export const MODAL_SUCCESS_ADD_DRIVER_BUTTON_TEXT = 'Back to fleet';
export const MODAL_SUCCESS_ADD_COMPENSATION_BUTTON_TEXT = 'Go back';
export const DOCUMENTATION = 'Documentation';
export const CV_RESUME = 'CV / Resume';
export const RECOMMENDATION_LETTER = 'Recommendation Letter';
export const SSN_ATTACHMENT = 'SSN Attachment';
export const ADDITIONAL_DOCUMENTATION = 'Additional documentation';
export const ADDITIONAL = 'additional';
export const VEHICLE_IMAGE = 'vehicle image';
export const UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE =
  'Maximum file number exceeded!';
export const UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT =
  'Maximum number of file uploads is 5. Please remove some of the uploaded files before uploading more.';
export const DRIVER_TYPE_REQUIRED = 'Driver type field is required.';
export const DRIVER_CLASS_REQUIRED = 'Driver class field is reqiured.';
export const DRIVER_CATEGORY_REQUIRED = 'Driver category field is reqiured.';
export const DRIVER_CODE_NAME_REQUIRED = 'Driver code name field is reqiured.';
export const DRIVER_COMPANY_ID_REQUIRED =
  'Driver company id field is reqiured.';
export const BIRTH_DATE_REQUIRED = 'Date of birth field is reqiured.';
export const LICENCE_NUMBER_REQUIRED = 'Licence number required';
export const LICENCE_ISSUED_IN_REQUIRED = 'Licence issuing location required';
export const CV_REQUIRED = 'CV is required.';
export const STREET_ADDRESS = 'Street address';
export const CITY = 'City';
export const STATE = 'State';
export const POSTAL_CODE = 'Postal code';
export const SSN = 'Social security number';
export const SSN_SHORT = 'ssn';
export const DRIVER_INFORMATION = 'Driver information';
export const LICENCE_INFORMATION = 'Licence information';
export const LICENCE_NUMBER = 'Licence number';
export const LICENCE_ATTACHMENT = 'Licence attachment';
export const EXPIRY_DATE = 'Expiry date';
export const ADD_LICENCE = 'Add licence';
export const PAYMENT_INFORMATION = 'Payment information';
export const PAYMENT_AMOUNT = 'Payment amount';
export const PAYMENT_TYPE = 'Payment type';
export const CONTRACT_LENGTH = 'Contract length';
export const PAYMENT_DOCUMENTS = 'Payment documents';
export const CONTRACT_AGREEMENT = 'Contract agreement';
export const ASSOCIATIONS = 'Associations';
export const LINK_TO_TRUCK = 'Link to Truck ID - Make - Type';
export const CATEGORY = 'Category';
export const CODE_NAME = 'Code name';
export const DRIVER_COMPANY_ID = 'Driver Company ID';
export const TEAM = 'Team';
export const ISSUED_IN = 'Issued in';
export const ISSUED_ON = 'Issued on';
export const DATE_OF_BIRTH = 'Date of birth';
export const DESCRIPTION_RESULTS = 'Description / Results';
export const EXPIRATION_DATE = 'Expiration Date';
export const DRIVER_COMPLIANCY = 'Driver Compliancy';
export const EMPTY_COMPLIANCY_TABLE_TEXT = 'No exams reported';
export const DELETE_DRIVER_ERROR = 'Error deleting driver!';
export const DELETE_DRIVER_MODAL_SUCCESS_TEXT =
  'Driver was succesfully deleted.';
export const DELETE_DRIVER_MODAL_SUCCESS_BUTTON_TEXT = 'Back to fleet';
export const CHANGE_DRIVER_COMPENSATION_ERROR =
  'Error changing driver compensation activity!';

export const MODAL_SUCCESS_EDIT_DRIVER_TEXT = 'Driver successfuly updated.';
export const MODAL_SUCCESS_EDIT_DRIVER_BUTTON_TEXT = 'Back to Driver';

export const SAVE_FIRST_MODAL_TEXT =
  'Before adding new field, you need to save. Do you want to save your changes?';
export const YES = 'Yes';
export const NO = 'No';
export const NOT_AVAILABLE = 'N/A';
export const NOT_AVAILABLE_SHORT = 'NA';
export const COMPANY = 'Company';
export const CONNECTED_TRUCK = 'Connected truck';
export const CONNECTED_TRAILER = 'Connected trailer';
export const HOME_ADDRESS = 'Home address';
export const EMPTY_LICENCES_TABLE_TEXT = 'No licences reported';
export const EMPTY_COMPENSATIONS_TABLE_TEXT = 'No compensations reported';
export const EMPTY_ASSOCIATIONS_TABLE_TEXT = 'No associations reported';
export const EMPTY_DOCUMENTS_TABLE_TEXT = 'No documents attached';
export const EMPTY_NOTES_TABLE_TEXT = 'No notes attached';
export const EMPTY_COMPLIANCES_TABLE_TEXT = 'No compliances reported';
export const DATE_CREATED = 'Date created';
export const VEHICLE_ID = 'Vehicle ID';
export const DOCUMENT_TYPE_LICENCE = 'licence';
export const DOCUMENT_TYPE_REGISTRATION = 'registration';
export const REGISTRATION_ATTACHMENT = 'Registration attachment';
export const VEHICLE_TYPE = 'Vehicle type';

export const REMOVE = 'Remove';
export const CURRENT_LICENCE = 'Current licence';
export const CURRENT_REGISTRATION = 'Current registration';
export const NEW_LICENCE = 'New licence';
export const NEW_REGISTRATION = 'New registration';
export const ATTACHED_FILE = 'Attached file';
export const VIEW_FILE = 'View file';
export const DATE_STARTED = 'Date started';
export const DATE_END = 'Date end';
export const COMPLIANCY_TYPE = 'Compliancy type';
export const COMPLIANCY_DESCRIPTION = 'Description / Results';
export const VIEW_TRUCK = 'View truck';
export const CONNECTED_DRIVER = 'Connected driver';
export const VIEW_TRAILER = 'View trailer';
export const VIEW_EMPLOYEE = 'View employee';
export const EMPLOYEE_INFORMATION = 'Employee information';
export const ACCOUNT_ACTIVE_ROLE = 'Account active role';
export const ASSIGNED_ROLES = 'Assigned roles';
export const ADD_ABSENCE_TEXT = 'Add absence';
export const EDIT_ABSENCE_TEXT = 'Edit absence';
export const VIEW_ABSENCE_TEXT = 'View absence';
export const ABSENCE_TYPE = 'Absence type';
export const NO_ABSENCE_TYPE_SELECTED = 'No absence type selected';
export const START_DATE_REQUIRED = 'Starting date is required';
export const END_DATE_REQUIRED = 'Ending date is required';
export const ABSENCE_TYPE_REQUIRED = 'Absence type is required';
export const INSPECTION_HISTORY = 'Inspection history';

export const DATE_OF_BIRTH_REQUIRED = 'Date of birth is required';
export const FETCH_VEHICLE_INSPECTION_TYPES_ERROR =
  'Error fetching inspection types!';
export const NO_TYPE_SELECTED = 'No type selected';
export const TIME = 'Time';
export const DEFECT = 'Defect';
export const DEFECT_PLACEHOLDER = 'Defect brief description...';
export const INSPECTION_TYPE_REQUIRED = 'Inspection type required';
export const DATE_REQUIRED = 'Date required';
export const TIME_REQUIRED = 'Time required';
export const ODOMETER_REQUIRED = 'Odometer required';
export const ODOMETER_UNITS_REQUIRED = 'Odometer units required';
export const DEFECT_REQUIRED = 'Defect decription required';
export const DELETE_ABSENCE_TEXT = 'Delete absence';
export const CONFIRM_DELETE_ABSENCE_MODAL_TEXT =
  'Are you sure you want to delete this absence?';
export const DELETE_INSPECTION = 'Delete inspection';
export const EDIT_INSPECTION = 'Edit inspection';
export const CONFIRM_DELETE_RECORD_MODAL_TEXT =
  'Are you sure you want to delete this record?';
export const MAINTENANCE_TYPE_REQUIRED = 'Maintenance type required';
export const NOTES_REQUIRED = 'Notes required';
export const DELETE_MAINTENANCE = 'Delete maintenance';
export const EDIT_MAINTENANCE = 'Edit maintenance';
export const MAINTENANCE_HISTORY = 'Maintenance history';
export const ADD_MAINTENANCE = 'Add maintenance';
export const LAST_LOCATION = 'Last location';
export const NOTES_PLACEHOLDER = 'Enter brief notes...';
export const NOTE_ID = 'Note No';
export const NOTE_TEXT = 'Note text';
export const DELETE_NOTE = 'Delete note';
export const EDIT_NOTE = 'Edit note';
export const FETCH_AVAILABLE_ODOMETER_UNITS_ERROR =
  'Error fetching odometer units!';
export const ABSENCE_DOCUMENT = 'Absence document';
export const FETCH_ABSENCE_TYPES_ERROR =
  'Error fetching available absence types!';
export const EDIT_COMPLIANCY = 'Edit compliancy';
export const ADD_COMPLIANCY = 'Add compliancy';
export const COMPLIANCY_DOCUMENT = 'Compliancy document';
export const COMPLIANCY_TYPE_REQUIRED = 'Compliancy type required';
export const COMPLIANCY_DESCRIPTION_REQUIRED =
  'Compliancy description required';
export const EXPIRATION_DATE_REQUIRED = 'Compliancy expiry date required';
export const NO_COMPLIANCY_TYPE_SELECTED = 'No compliancy type selected';
export const DELETE_COMPLIANCE_TEXT = 'Delete compliance';
export const NOT_ASSOCIATED = 'Not associated';
export const FETCH_COMPLIANCY_TYPES_ERROR =
  'Error fetching available compliancy types!';
export const CREATE_SHIPMENT = 'Create shipment';
export const SHIPMENT_STATUS = 'Shipment Status';
export const SHIPMENT_NOTE_EMPTY_TEXT = 'Shipment note cannot be empty';
export const PENDING_ASSIGNEMENT = 'Pending assignement';
export const ON_TIME_SHIPMENTS = 'On-Time shipments';
export const DELAYED_SHIPMENTS = 'Delayed shipments';
export const SCHEDULED_SHIPMENTS = 'Scheduled shipments';
export const COMPLETED_SHIPMENTS = 'Completed shipments';
export const SHOW_SHIPMENTS = 'Show shipments';
export const SHIPMENT_NO = 'Shipment No.';
export const SHIPMENT_STATUS_SELECT_PLACEHOLDER = 'Select shipment status...';
export const SHIPMENT_DETAILS = 'Shipment details';
export const START_TYPING_PLACEHOLDER_TEXT = 'Start typing...';
export const CUSTOMER = 'Customer';
export const BILL_TO = 'Bill to';
export const PO_NUMBER = 'P.O. number';
export const BROKER_NAME = 'Broker name';
export const SHIPMENTS = 'Shipments';
export const CUSTOMERS = 'Customers';
export const SELECT_CUSTOMERS = 'Select customer';

export const LOCATIONS = 'Locations';
export const ADD_CUSTOMER = 'Add customer';
export const ADD_LOCATION = 'Add location';
export const SEARCH_DRIVERS = 'Search drivers';
export const SEARCH_TRUCKS = 'Search trucks';
export const SEARCH_TRAILERS = 'Search trailers';
export const FETCH_SHIPMENTS_ERROR = 'Error fetching shipments!';
export const FETCH_PLANNER_SHIPMENTS_ERROR =
  'Error fetching planner shipments!';
export const SHIPMENT_ID = 'Shipment ID';
export const CURRENT_DRIVER = 'Current driver';
export const TRAILER_VIN_TYPE = 'Trailer VIN# / Type';
export const START_LOCATION = 'Start location';
export const END_LOCATION = 'End location';
export const TRAILER_LOCATION = 'Trailer location';
export const DELETE_SHIPMENT = 'Delete shipment';
export const EDIT_SHIPMENT = 'Edit shipment';
export const EMPTY_SHIPMENTS_TABLE_TEXT = 'No shipments yet';
export const EMPTY_SHIPMENTS_TABLE_DETAILS_TEXT = 'No action details';
export const DELETE_RECORD_TEXT = 'Delete record';
export const FETCH_CLIENTS_ERROR = 'Error fetching clients!';
export const CUSTOMER_NAME = 'Customer name';
export const COUNTRY_STATE_CITY = 'Country / State / City';
export const ADDRESS = 'Address';
export const EDIT_RECORD_TEXT = 'Edit record';
export const EMPTY_CUSTOMERS_TABLE_TEXT = 'No customers yet';
export const FETCH_LOCATIONS_ERROR = 'Error fetching locations!';
export const EMPTY_LOCATIONS_TABLE_TEXT = 'No locations yet';
export const LOCATION_NAME = 'Location name';
export const LOCATION_NOTE = 'Location note';
export const COMPANY_OWNER = 'Company owner';
export const ZONE = 'Zone';
export const COMPANY_NAME = 'Company name';
export const PROVINCE = 'Province';
export const COUNTRY = 'Country';
export const CUSTOMER_NAME_REQUIRED = 'Customer name required';
export const ADDRESS_REQUIRED = 'Address required';
export const COUNTRY_REQUIRED = 'Country required';
export const STATE_REQUIRED = 'State required';
export const CITY_REQUIRED = 'City required';
export const MC_REQUIRED = 'MC required';
export const POSTAL_CODE_REQUIRED = 'Postal code required';
export const COMPANY_CONTACT_NAME = 'Company contact name';
export const LOCATION_NAME_REQUIRED = 'Location name required';
export const MODAL_SUCCESS_ADD_CUSTOMER_TEXT = 'Customer successfuly added.';
export const MODAL_SUCCESS_ADD_CUSTOMER_BUTTON_TEXT = 'Back to customers';
export const MODAL_SUCCESS_EDIT_CUSTOMER_TEXT = 'Customer successfuly updated.';
export const MODAL_SUCCESS_EDIT_CUSTOMER_BUTTON_TEXT = 'Back to customers';
export const MODAL_SUCCESS_ADD_LOCATION_TEXT = 'Location successfuly added.';
export const MODAL_SUCCESS_ADD_LOCATION_BUTTON_TEXT = 'Back to locations';
export const MODAL_SUCCESS_EDIT_LOCATION_TEXT = 'Location successfuly updated.';
export const MODAL_SUCCESS_EDIT_LOCATION_BUTTON_TEXT = 'Back to locations';
export const PO_NUMBER_REQUIRED = 'P.O. number required';
export const BROKER_NAME_REQUIRED = 'Broker name required';
export const BROKER_PHONE_REQUIRED = 'Broker phone number required';
export const FETCH_SHIPMENT_STATUSES_ERROR =
  'Error fetching available shipment statuses';
export const FETCH_SHIPMENT_ERROR = 'Error fetching shipment!';
export const SEARCH_SHIPMENTS = 'Search shipments';
export const SEARCH_CUSTOMERS = 'Search customers';
export const SEARCH_LOCATIONS = 'Search locations';
export const DRIVER_ID = 'Driver ID';
export const DRIVER_NAME = 'Driver name';
export const ASSOCIATIONS_HISTORY = 'Associations history';
export const CUSTOMER_INFORMATION = 'Customer information';
export const LOCATION_INFORMATION = 'Location information';
export const FILTER_SHIPMENTS = 'Filter shipments';
export const ZIP_CODE = 'Zip Code';
export const SEARCH_ADDRESS = 'Search address...';
export const LOCATION_CONTACT_NAME = 'Location contact name';
export const COMPANY_REQUIRED = 'Company required';
export const CONFIRM_LOCATION_COORDINATES_TEXT =
  'These are the current coordinates for the location you added. Should you need to change them, please move the marker on the map below to the correct position. ';
export const EDIT_LOCATION_ERROR = 'Error editing location!';
export const DELETE_CUSTOMER_ERROR = 'Error deleting customer!';
export const DELETE_CUSTOMER_MODAL_SUCCESS_TEXT =
  'Customer was succesfully deleted.';
export const DELETE_SHIPMENT_ERROR = 'Error deleting shipment!';
export const DELETE_SHIPMENT_MODAL_SUCCESS_TEXT =
  'Shipment was succesfully deleted.';
export const DELETE_LOCATION_MODAL_SUCCESS_TEXT =
  'Location was succesfully deleted.';
export const DELETE_BROKERAGE_MODAL_SUCCESS_BUTTON_TEXT = 'Back to brokerage';
export const DELETE_LOCATION_ERROR = 'Error deleting location!';
export const LOCATION = 'Location';
export const PROBILL_DETAILS = 'Probill details';
export const SHIPMENT_ITINERARY = 'Shipment Itinerary';
export const ADD_STOP = 'Add stop';
export const ADD_PROBILL = 'Add probill';
export const STOP_NO = 'Stop no.';
export const PROBILL_NO = 'Probill no.';
export const CARGO_DESCRIPTION = 'Cargo description';
export const PICKUP_LOCATION = 'Pickup location';
export const DELIVERY_LOCATION = 'Delivery location';
export const REMOVE_STOP = 'Remove stop';
export const REMOVE_PROBILL = 'Remove probill';
export const EDIT_STOP = 'Edit stop';
export const EDIT_PROBILL = 'Edit probill';
export const EMPTY_PROBILLS_TABLE_TEXT = 'No probills yet';
export const EMPTY_STOP_TABLE_TEXT = 'No stops yet';
export const GENERAL = 'General';
export const CARGO = 'Cargo';
export const CARGO_DETAILS = 'Cargo details';
export const FETCH_WEIGHT_UNITS_ERROR = 'Error fetching available weight units';
export const FETCH_QUANTITY_UNITS_ERROR =
  'Error fetching available quantity units';
export const UNIT_LOAD = 'Unit load';
export const WEIGHT = 'Weight';
export const QUANTITY = 'Quantity';
export const SHIPPER = 'Shipper';
export const RECEIVER = 'Receiver';
export const PICKUP_TIME = 'Pickup time';
export const PICKUP_DATE_START = 'Pickup date/time start';
export const PICKUP_DATE_END = 'Pickup date/time end';
export const PICKUP_TYPE = 'Pickup type';
export const DELIVERY_TYPE = 'Delivery type';
export const SCHEDULED = 'Scheduled';
export const DELIVERY_TIME = 'Delivery time';
export const DELIVERY_DATE = 'Delivery date';
export const DELIVERY_DATE_START = 'Delivery date/time start';
export const DELIVERY_DATE_END = 'Delivery date/time end';
export const PICKUP_NUMBER = 'Pickup No.';
export const DELIVERY_NUMBER = 'Delivery No.';
export const SHIPPER_REQUIRED = 'Shipper required';
export const PICKUP_LOCATION_REQUIRED = 'Pickup location required';
export const PICKUP_TIME_REQUIRED = 'Pickup time required';
export const PICKUP_DATE_REQUIRED = 'Pickup date required';
export const PICKUP_NUMBER_REQUIRED = 'Pickup number required';
export const RECEIVER_REQUIRED = 'Receiver required';
export const DELIVERY_LOCATION_REQUIRED = 'Delivery location required';
export const DELIVERY_TIME_REQUIRED = 'Delivery time required';
export const DELIVERY_DATE_REQUIRED = 'Delivery date required';
export const DELIVERY_NUMBER_REQUIRED = 'Delivery number required';
export const CARGO_DESCRIPTION_REQUIRED = 'Cargo description required';
export const UNIT_LOAD_REQUIRED = 'Quantity unit required';
export const QUANTITY_REQUIRED = 'Quantity required';
export const WEIGHT_REQUIRED = 'Weight required';
export const WEIGHT_UNIT_REQUIRED = 'Weight unit required';
export const BROKERAGE_SHIPMENT = 'Brokerage shipment';
export const BROKERAGE_CHARGE = 'Brokerage charge';
export const ACTIVE_SHIPMENTS = 'Active shipments';
export const INACTIVE_SHIPMENTS = 'Inactive shipments';
export const BROKERAGE_SHIPMENTS = 'Brokerage shipments';
export const REGULAR_SHIPMENTS = 'Regular shipments';
export const ASSETS_SHIPMENTS = 'Assets';
export const BROKERAGE_SHIPMENTS_NEW = '3PL';
export const REGULAR_SHIPMENT = 'Regular shipment';
export const SELECT_CHARGE_TYPE = 'Select charge type:';
export const BROKER_NAME_PHONE = 'Broker name / Phone';
export const TRAILER_ID_TYPE = 'Trailer ID / Type';
export const TRUCK_ID_TYPE = 'Truck ID / Type';
export const EDIT_CUSTOMER = 'Edit customer';
export const EDIT_LOCATION = 'Edit location';
export const DELETE_PROBILL_ERROR = 'Error deleting probill!';
export const FETCH_PROBILL_ERROR = 'Error fetching probill!';
export const VIEW_SHIPMENT = 'View shipment';
export const VIEW_DRIVER_POSITION = 'View driver position';
export const VIEW_ALL_SHIPMENTS = 'View all shipments';
export const VIEW_ALL_ACTIONS = 'View all actions';
export const SELECT_SHIPMENT = 'Select shipment';
export const BROKER_PHONE_NUMBER = 'Broker phone number';
export const CURRENT_LOCATION = 'Current location';
export const TARGET_LOCATION = 'Target location';
export const OVERWRITE_CURRENT_LOCATION = 'Overwrite current location?';
export const MODAL_SUCCESS_EDIT_SHIPMENT_TEXT =
  'Shipment data successfuly updated.';
export const MODAL_SUCCESS_EDIT_SHIPMENT_BUTTON_TEXT = 'Back to shipment';
export const CARRIERS = 'Carriers';
export const EMPTY_CARRIERS_TABLE_TEXT = 'No carriers yet';
export const CARRIER_COMPANY = 'Carrier company';
export const CARRIER_ADDRESS_CITY_STATE = 'Carrier Address / City / State';
export const OVERALL_DRIVERS_SCORE = 'Overall drivers score';
export const INSURANCE_EXPIRES = 'Insurance expires';
export const ADD_CARRIER = 'Add carrier';
export const EDIT_CARRIER = 'Edit carrier';
export const CARRIER_DETAILS = 'Carrier details';
export const CONTACT_NAME = 'Contact name';
export const CONTACT_POSITION = 'Contact position';
export const DOT_NUMBER = 'DOT number';
export const MC_NUMBER = 'MC number';
export const CARRIER_CODE = 'Carrier code';
export const FAX_NUMBER = 'Fax number';
export const INSURANCE_DETAILS = 'Insurance details';
export const INSURANCE_COMPANY_NAME = 'Insurance company name';
export const INSURANCE_BROKER = 'Insurance broker';
export const INSURANCE_POLICY = 'Insurance policy';
export const INSURANCE_EXPIRY_DATE = 'Insurance expiry date';
export const CARGO_AMOUNT = 'Cargo amount';
export const LIABILITY_AMOUNT = 'Liability amount';
export const LOCATION_DETAILS = 'Location details';
export const COMPANY_NAME_REQUIRED = 'Company name required';
export const CARRIER = 'Carrier';
export const DELETE_CARRIER_ERROR = 'Error deleting carrier!';
export const MODAL_SUCCESS_ADD_CARRIER_TEXT = 'Carrier successfuly added.';
export const MODAL_SUCCESS_ADD_CARRIER_BUTTON_TEXT = 'Back to carriers';
export const DELETE_CARRIER_MODAL_SUCCESS_TEXT = 'Carrier successfuly deleted.';
export const FETCH_CARRIER_ERROR = 'Error fetching carrier!';
export const FETCH_CARRIERS_ERROR = 'Error fetching carriers!';
export const MODAL_SUCCESS_EDIT_CARRIER_TEXT = 'Carrier successfuly updated.';
export const MODAL_SUCCESS_EDIT_CARRIER_BUTTON_TEXT = 'Back to carriers';
export const VIEW_CARRIER = 'View carrier';
export const CONTACT_PHONE = 'Contact phone';
export const INSURANCE_CARGO_AMOUNT = 'Insurance Cargo Amount';
export const INSURANCE_LIABILITY_AMOUNT = 'Insurance LIability Amount';
export const OTHER = 'Other';
export const ADD_NEW = 'Add new';
export const FILTER = 'Filter';
export const FETCH_SHIPMENT_SPECIFICS_ERROR =
  'Error fetching shipment specifics!';
export const TITLE = 'Title';
export const DESCRIPTION = 'Description';
export const EMPTY_SHIPMENT_CHARGES_TABLE_TEXT = 'No shipment charges yet';
export const EMPTY_TRIP_EVENTS_TABLE_TEXT = 'No trip events yet';
export const EMPTY_ROUTE_ACTIONS_TABLE_TEXT = 'No route actions yet';
export const EMPTY_SHIPMENT_DOCUMENT_TYPES_TABLE_TEXT =
  'No shipment document types yet';
export const ADD_OTHER = 'Add other';
export const CREATE_NEW = 'Create new';
export const ITEM = 'Item';
export const CURRENCY = 'Currency';
export const TITLE_REQUIRED = 'Title required';
export const BILLING_DETAILS = 'Billing details';
export const BILLING_EMAIL = 'Billing email';
export const PIB = 'PIB';
export const SHIPMENT_TOTAL_CHARGES = 'Shipment total charges';
export const SHIPMENT_SPECIFIC_DETAILS = 'Shipment specific details';
export const DOCUMENTS = 'Documents';
export const ADD_DOCUMENT = 'Add document';
export const ADD_NOTE = 'Add note';
export const SAVE_NOTE = 'Edit note';
export const UPLOAD_FILE = 'Upload file';
export const UPLOAD_ERROR = 'Upload error';
export const CHARGE_ITEM = 'Charge item';
export const AMOUNT = 'Amount';
export const FETCH_SHIPMENT_DOCUMENTS_ERROR =
  'Error fetching shipment documents!';
export const DOCUMENT_TYPE = 'Document type';
export const DATE_TIME_CREATED = 'Date/Time created';
export const DATE_ADDED = 'Date added';
export const SAVE_SHIPMENT = 'Save shipment';
export const MODAL_SUCCESS_CREATE_SHIPMENT_TEXT =
  'Shipment successfully created!';
export const MODAL_SUCCESS_CREATE_SHIPMENT_BUTTON_TEXT = 'Back to shipment';
export const BASE_CHARGE = 'Base charge';
export const CARRIER_CHARGE_BROKERAGE = 'Carrier Charge (Brokerage)';
export const TOTAL_CHARGES = 'Total charges';
export const DOCUMENT = 'Document';
export const DELETE_DOCUMENT_ERROR = 'Error deleting document!';
export const REMOVE_DOCUMENT = 'Remove document';
export const ALL_CHARGE_ITEMS_FIELDS_REQUIRED =
  'All charge items fields are required';
export const SHIPMENT_PROFIT = 'Shipment profit';
export const TOTAL_PROFIT = 'Total profit';
export const DELETE_SHIPMENT_SPECIFICS_ERROR =
  'Delete shipment specific error!';
export const MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_TEXT =
  'Shipment specific successfuly deleted.';
export const MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_BUTTON_TEXT =
  'Back to shipment specifics';
export const PRINT_PDF = 'Export to PDF';
export const EXPORT_TO_CSV = 'Export to CSV';
export const LABEL_ID = 'ID';
export const EQUIPMENT_DETAILS = 'Equipment details';
export const CATEGORY_TYPE = 'Category/Type';
export const ORGANIZE_TOUR = 'Organize tour now';
export const LOCAL_PD = 'Local P&D';
export const APPLY = 'Apply';
export const ASSETS = 'Assets';
export const SEE_DETAILS = 'See details';
export const HIDE_DETAILS = 'Hide details';
export const OVERWRITE_EXISTING_LOCATION = 'Overwrite Existing Location';
export const SAVE_AS_NEW_LOCATION = 'Save as new location';
export const START_TIME = 'Start time';
export const START_DATE = 'Start date';
export const START_DATE_TIME = 'Start Date & Time';
export const END_DATE_TIME = 'End Date & Time';
export const MILES = 'Miles';
export const SEND_TO_PLANNER = 'Send to planner';
export const SEARCH_BY_C_NAME_OR_ID = 'Search by C.Name or ID';
export const SEARCH_BY_ID = 'Search by ID';
export const SEARCH_BY_ID_STATUS = 'Search by ID, Status';
export const SELECT_ALL = 'Select all';
export const STATUS_FOR_ACTIVE_SHIPMENT = 'Status for active shipment';
export const ROUTE_ACTION_LIST = 'Route action list';
export const EMPTY = 'Empty';
export const LOADED = 'Loaded';
export const CARGO_STATUS = 'Cargo Status';

export const COMPENSATION_SEARCH_PLACEHOLDER_TEXT =
  'Search compensation structure';

// NOTIFICATIONS
export const NOTIFICATIONS = 'Notifications';
export const MARK_AS_RESOLVED = 'Mark as resolved';
