import React from 'react';
import { observer } from 'mobx-react';
import { Spin } from 'antd';

const LoaderComponent = () => {
  return (
    <div className="loader">
      <Spin size="large" className="spinner" />
    </div>
  );
};

export default observer(LoaderComponent);
