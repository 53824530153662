import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Input, Select } from 'antd';
import { useMst } from '../../../../state/RootModel';
import {
  TITLE,
  PRICE,
  CURRENCY,
  DESCRIPTION,
  BROKERAGE_CHARGE,
} from '../../../../constants';

const { Option } = Select;
const { TextArea } = Input;

const NewShipmentChargeComponent = () => {
  const {
    shipmentSpecificsPageState: { newShipmentCharge, currencyTypes },
  } = useMst();

  const onCurrencySelect = (value) => {
    newShipmentCharge.setCurrency(value);
  };

  const onChangeDescription = (e) => {
    newShipmentCharge.setDescription(e.target.value);
  };

  const onSetPrice = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      newShipmentCharge.setPrice(null);
    } else {
      newShipmentCharge.setPrice(Number(e.target.value));
    }
  };

  const onSelectBrokerageCharge = () => {
    newShipmentCharge.setIsBrokerage(!newShipmentCharge.is_brokerage);
  };
  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="align-center">
          <Checkbox
            checked={newShipmentCharge?.is_brokerage}
            onChange={onSelectBrokerageCharge}
            className="align-center"
          >
            <p>{BROKERAGE_CHARGE}</p>
          </Checkbox>
        </div>
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) => newShipmentCharge.setChargeName(e.target.value)}
          value={newShipmentCharge?.charge_name}
        />
      </div>
      <div className="flex-alignitems-flex-start w100 mt20">
        <div className="input-wrapper w25">
          <div className="input-label justify-between">
            <span>{CURRENCY}</span>
          </div>
          <Select
            className="text-capitalize"
            style={{ width: '100%' }}
            onChange={onCurrencySelect}
            value={newShipmentCharge?.currency}
          >
            {currencyTypes.map((type) => (
              <Option key={type} value={type} className="text-capitalize">
                {type}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper w70 ml5pc">
          <div className="input-label justify-between">
            <span>{PRICE}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={onSetPrice}
            value={newShipmentCharge?.price}
          />
        </div>
      </div>
      <div className="input-wrapper mt20 w100">
        <div className="input-label justify-between">
          <span>{DESCRIPTION}</span>
        </div>
        <TextArea
          rows={4}
          onChange={onChangeDescription}
          value={newShipmentCharge?.description}
        />
      </div>
    </div>
  );
};

export default observer(NewShipmentChargeComponent);
