import { types } from 'mobx-state-tree';
import { ROUTE_ACTION } from '../../../constants/shipmentSpecificsTypes';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { TITLE_REQUIRED } from '../../../constants';
import { editShipmentRouteAction } from '../../../actions/shipmentSpecifics';
import { rootInstance } from '../../RootModel';

export const RouteActionModel = types
  .model('RouteActionModel', {
    type: ROUTE_ACTION,
    action_id: types.identifierNumber,
    organization_id: types.maybe(types.maybeNull(types.number)),
    description: types.maybe(types.maybeNull(types.string)),
    name: types.maybe(types.maybeNull(types.string)),
    requires_truck: types.maybe(types.maybeNull(types.boolean)),
    requires_trailer: types.maybe(types.maybeNull(types.boolean)),

    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setRequiresTruck(value) {
        self.requires_truck = value;
      },
      setRequiresTrailer(value) {
        self.requires_trailer = value;
      },
      setRouteActionName(value) {
        self.name = value;
      },
      setDescription(value) {
        self.description = value;
      },
      clearModel() {
        this.setRouteActionName(null);
        this.setDescription(null);
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveItemError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.name) {
          this.setErrorText(TITLE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveItem() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          editShipmentRouteAction(self)
            .then(() => {
              rootInstance.shipmentSpecificsPageState.setEditingShipmentSpecific(
                false
              );
              rootInstance.shipmentSpecificsPageState.setRouteActionToBeEdited(
                null
              );
              rootInstance.shipmentSpecificsPageState.setSelectedShipmentSpecificType(
                ROUTE_ACTION
              );
              //@TODO: Create success Modal for all types
              rootInstance.shipmentSpecificsPageState.setAddShipmentSpecificSuccessModalOpen(
                true
              );
            })
            .catch((error) => {
              this.throwSaveItemError(error);
            });
        }
      },
    };
  });
