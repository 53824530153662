import React from 'react';
import { observer } from 'mobx-react';
import { Menu } from 'antd';
import logo from '../../assets/images/menu/logo-menu.svg';
import CustomMenu from './CustomMenu';

const SideMenu = () => {
  return (
    <div className="side-menu-wrapper">
      <img src={logo} alt="Kamion SCM logo" className="w100" />
      <Menu mode="inline" className="side-menu" inlineIndent={0}>
        <CustomMenu />
      </Menu>
    </div>
  );
};

export default observer(SideMenu);
