export const DOCUMENT_TYPE_RESUME = 'Resume';
export const DOCUMENT_TYPE_REFERENCE = 'Reference';
export const DOCUMENT_TYPE_EMPLOYEE_AGREEMENT = 'Employee agreement';
export const DOCUMENT_TYPE_EMPLOYEE_IMAGE = 'Employee image';
export const DOCUMENT_TYPE_SSN = 'SSN';
export const DOCUMENT_TYPE_REGISTRATION = 'Registration';
export const DOCUMENT_TYPE_VEHICLE_IMAGE = 'Vehicle image';
export const DOCUMENT_TYPE_ADDITIONAL = 'Additional';
export const DOCUMENT_TYPE_LICENCE = 'Licence';
export const DOCUMENT_TYPE_INSPECTION = 'Inspection';
export const DOCUMENT_TYPE_COMPLIANCY = 'Compliancy';
export const DOCUMENT_TYPE_MAINTENANCE = 'Maintenance';
export const DOCUMENT_TYPE_ABSENCE = 'Absence';
