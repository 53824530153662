import React from 'react';
import PageRoutes from '../../utils/PageRoutes';
import { Tooltip } from 'antd';
import {
  FULLNAME,
  EMAIL,
  PHONE_NUMBER,
  USER_ROLE,
  ACTION,
  EDIT_EMPLOYEE,
  DELETE_EMPLOYEE,
  ARCHIVED,
  REACTIVATE_EMPLOYEE,
} from '../../constants';
import edit from '../../assets/images/edit.svg';
import trash from '../../assets/images/trash.svg';
import setActiveIcon from '../../assets/images/view-active.svg';
import {
  concatenateArrayMembersNames,
  isCurrentUser,
} from '../../utils/methods';
import { rootInstance } from '../RootModel';

const isUserActive = (user) => {
  return user.account_status !== ARCHIVED;
};

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.employeesPageState.setUserToBeDeleted(record.user_id);
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  const params = { employeeId: record.user_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(
      isCurrentUser(record.user_id)
        ? PageRoutes.Account.name
        : PageRoutes.EditEmployee.name
    ),
    params
  );
};
const reactivateUserHandle = (e, user_id) => {
  e.stopPropagation();
  rootInstance.employeesPageState.setUserToBeReactivated(user_id);
};

export const employeesColumnsFunc = (rootInstance) => ({
  '1': {
    title: FULLNAME,
    key: 'name',
    dataIndex: 'name',
    width: '20%',
    render: (text, record) => {
      return <span>{record.fullName}</span>;
    },
  },
  '2': {
    title: EMAIL,
    key: 'email',
    dataIndex: 'email',
    width: '25%',
  },
  '3': {
    title: PHONE_NUMBER,
    key: 'phone_number',
    dataIndex: 'phone_number',
    width: '15%',
  },
  '4': {
    title: USER_ROLE,
    dataIndex: 'user_role',
    key: 'user_role',
    width: '25%',
    render: (text, record) => {
      const availableRoles = rootInstance.employeesPageState.employeeRolesArray;
      const roles = [];
      record.user_assigned_roles.forEach((id) => {
        availableRoles.forEach((role) => {
          if (role.role_id === id) {
            role.name = role.role_name;
            roles.push(role);
          }
        });
      });
      const concatenatedRoles = concatenateArrayMembersNames(roles);
      return <span>{concatenatedRoles}</span>;
    },
  },
  '5': {
    title: ACTION,
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    render: (text, record) => {
      return (
        <div>
          {isUserActive(record) ? (
            <div>
              {isCurrentUser(record.user_id) ? (
                <img src={trash} alt="delete" className="ph10 disabled" />
              ) : (
                rootInstance.userState.canRemoveUsersFromDepartment && (
                  <Tooltip title={DELETE_EMPLOYEE}>
                    <img
                      src={trash}
                      alt="delete"
                      className="pointer ph10"
                      onClick={(e) => onClickDelete(e, record)}
                    />
                  </Tooltip>
                )
              )}
              {rootInstance.userState.canEditUsers && (
                <span onClick={(e) => onClickEdit(e, record)}>
                  <Tooltip title={EDIT_EMPLOYEE}>
                    <img src={edit} alt="edit" className="pointer ph10" />
                  </Tooltip>
                </span>
              )}
            </div>
          ) : (
            <div>
              <Tooltip title={REACTIVATE_EMPLOYEE}>
                <img
                  src={setActiveIcon}
                  alt="set active"
                  className="pointer ph10"
                  // onClick={() =>
                  //   rootInstance.employeesPageState.setUserToBeReactivated(
                  //     record.user_id,
                  //   )
                  // }
                  onClick={(e) => reactivateUserHandle(e, record.user_id)}
                />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
  },
});
