import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal } from 'antd';
import { CONFIRM, CANCEL, CONFIRM_DELETE_MODAL_TEXT } from '../../../constants';

const ConfirmRemoveRouteAction = () => {
  const {
    dispatchPageState: {
      removeRouteActionModalOpen,
      setRemoveRouteActionModalOpen,
      deleteShipmentRouteAction,
    },
  } = useMst();

  const onCancel = () => {
    setRemoveRouteActionModalOpen(false);
  };

  const onConfirm = () => {
    deleteShipmentRouteAction();
  };

  return (
    <div>
      <Modal
        visible={!!removeRouteActionModalOpen}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={400}
        className="confirm-delete-modal"
      >
        <div className="p20">
          <div>
            <p>{CONFIRM_DELETE_MODAL_TEXT} route action?</p>
          </div>
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ConfirmRemoveRouteAction);
