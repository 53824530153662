import React from 'react';
import { observer } from 'mobx-react';
import {
  FULLNAME,
  EMAIL,
  PHONE_NUMBER,
  USER_ROLE,
  ACTION,
} from '../../constants';

const EmployeesTableHeader = () => {
  return (
    <div className="header-row align-center">
      <span className="column w20">{FULLNAME}</span>
      <span className="column w25">{EMAIL}</span>
      <span className="column w15">{PHONE_NUMBER}</span>
      <span className="column w25">{USER_ROLE}</span>
      <span className="column w15">{ACTION}</span>
    </div>
  );
};

export default observer(EmployeesTableHeader);
