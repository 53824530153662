import {
  COMPENSATION_NAME,
  COMPENSATION_PERIOD,
  COMPENSATION_TYPE,
} from '../../../constants/payroll';

export const compensationsTableColumns = (rootInstance) => ({
  '1': {
    title: COMPENSATION_NAME,
    dataIndex: 'compensation_name',
    key: 'compensation_name',
    width: '30%',
  },
  '2': {
    title: COMPENSATION_TYPE,
    dataIndex: 'compensation_type',
    key: 'compensation_type',
    width: '30%',
  },
  '3': {
    title: COMPENSATION_PERIOD,
    dataIndex: 'period',
    key: 'period',
    width: '30%',
  },
});
