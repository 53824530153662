import { types } from 'mobx-state-tree';
import { LocationModel } from '../location/LocationModel';

export const DispatchShipmentModel = types.model('DispatchShipmentModel', {
  shipment_id: types.identifierNumber,
  shipment_number: types.maybe(types.maybeNull(types.string)),
  status: types.maybe(types.maybeNull(types.number)),
  trailer_id: types.maybe(types.maybeNull(types.number)),
  trailer_type: types.maybe(types.maybeNull(types.string)),
  pickup_location: types.maybe(types.maybeNull(LocationModel)),
  delivery_location: types.maybe(types.maybeNull(LocationModel)),
  all_actions: types.frozen([]),
  current_action: types.maybe(types.maybeNull(types.string)),
  details: types.frozen([]),
});
