import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { useMst } from '../../../state/RootModel';
import { EMPTY_ASSOCIATIONS_TABLE_TEXT } from '../../../constants';

const TrailerAssociationsTable = () => {
  const {
    fleetPageState: { viewedTrailer },
  } = useMst();

  return (
    <Table
      className="mb20"
      rowKey={'association_id'}
      columns={viewedTrailer.getAssociationsColumns()}
      dataSource={viewedTrailer.truckAssociation}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={EMPTY_ASSOCIATIONS_TABLE_TEXT}
          />
        ),
      }}
    />
  );
};

export default observer(TrailerAssociationsTable);
