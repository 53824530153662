import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Modal } from 'antd';
import { CANCEL, CONFIRM } from '../../../constants';
import {
  ADD_NEW_ORGANIZATION,
  CVOR,
  DOT,
  MC,
  ORGANIZATION_ADMIN_EMAIL,
  ORGANIZATION_ADMIN_FIRSTNAME,
  ORGANIZATION_ADMIN_LASTNAME,
  ORGANIZATION_NAME,
} from '../../../constants/organizations/organizations';
import addOrganization from '../../../assets/images/add-organization.svg';
import ErrorBox from '../../ErrorBox';

const AddOrganizationModal = () => {
  const {
    organizationState: {
      addOrganizationModalOpened,
      setAddOrganizationModalOpen,
      newOrganization,
    },
  } = useMst();

  const onCancel = () => {
    setAddOrganizationModalOpen(false);
  };

  const onConfirm = (e) => {
    e.preventDefault();
    newOrganization.saveNewOrganization();
  };
  const onOrganizationNameChange = (e) => {
    newOrganization.setOrganizationName(e.target.value);
  };
  const onOrganizationMCChange = (e) => {
    newOrganization.setOrganizationMC(e.target.value);
  };
  const onOrganizationDOTChange = (e) => {
    newOrganization.setOrganizationDOT(e.target.value);
  };
  const onOrganizationCVORChange = (e) => {
    newOrganization.setOrganizationCVOR(e.target.value);
  };

  const onOrganizationAdminFirstnameChange = (e) => {
    newOrganization.setAdminFirstname(e.target.value);
  };
  const onOrganizationAdminLastnameChange = (e) => {
    newOrganization.setAdminLastname(e.target.value);
  };
  const onOrganizationAdminChange = (e) => {
    newOrganization.setAdminEmail(e.target.value);
  };

  const onAdminConfirm = (e) => {
    e.preventDefault();
    newOrganization.setNewOrganizationAdmin();
  };

  return (
    <div>
      <Modal
        visible={!!addOrganizationModalOpened}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="modal-content p20">
          <div className="align-center mb20">
            <img src={addOrganization} alt="save" className="mr10" />
            <h3>{ADD_NEW_ORGANIZATION}</h3>
          </div>
          <div className="flex-column">
            {!newOrganization.isAdminEmailSet && (
              <div className="flex-column">
                <div className="input-wrapper mb20 w100">
                  <div className="input-label justify-between">
                    <span>
                      {ORGANIZATION_NAME}
                      <span className="color-secondary d-iblock ml10">*</span>
                    </span>
                  </div>
                  <Input
                    type="text"
                    value={newOrganization.organization_name}
                    placeholder={ORGANIZATION_NAME}
                    onChange={(e) => onOrganizationNameChange(e)}
                    className="trailer-search-input"
                  />
                </div>
                <div className="input-wrapper mb20 w100">
                  <div className="input-label justify-between">
                    <span>{MC}</span>
                  </div>
                  <Input
                    type="text"
                    placeholder={MC}
                    value={newOrganization.mc}
                    onChange={(e) => onOrganizationMCChange(e)}
                    className="trailer-search-input"
                  />
                </div>
                <div className="input-wrapper mb20 w100">
                  <div className="input-label justify-between">
                    <span>{DOT}</span>
                  </div>
                  <Input
                    type="text"
                    placeholder={DOT}
                    value={newOrganization.dot}
                    onChange={(e) => onOrganizationDOTChange(e)}
                    className="trailer-search-input"
                  />
                </div>
                <div className="input-wrapper mb20 w100">
                  <div className="input-label justify-between">
                    <span>{CVOR}</span>
                  </div>
                  <Input
                    type="text"
                    placeholder={CVOR}
                    value={newOrganization.cvor}
                    onChange={(e) => onOrganizationCVORChange(e)}
                    className="trailer-search-input"
                  />
                </div>
              </div>
            )}
            {newOrganization.isAdminEmailSet && (
              <div className="flex-column">
                <h3 className="mb20">
                  To create person responsible for "
                  <strong>{newOrganization.organization_name}</strong>" enter
                  following information:
                </h3>
                <div className="input-wrapper mb20 w100">
                  <div className="input-label justify-between">
                    <span>
                      {ORGANIZATION_ADMIN_FIRSTNAME}
                      <span className="color-secondary d-iblock ml10">*</span>
                    </span>
                  </div>
                  <Input
                    type="text"
                    placeholder={ORGANIZATION_ADMIN_FIRSTNAME}
                    value={newOrganization.admin_firstname}
                    onChange={(e) => onOrganizationAdminFirstnameChange(e)}
                    className="trailer-search-input"
                  />
                </div>
                <div className="input-wrapper mb20 w100">
                  <div className="input-label justify-between">
                    <span>
                      {ORGANIZATION_ADMIN_LASTNAME}
                      <span className="color-secondary d-iblock ml10">*</span>
                    </span>
                  </div>
                  <Input
                    type="text"
                    placeholder={ORGANIZATION_ADMIN_LASTNAME}
                    value={newOrganization.admin_lastname}
                    onChange={(e) => onOrganizationAdminLastnameChange(e)}
                    className="trailer-search-input"
                  />
                </div>
                <div className="input-wrapper w100">
                  <div className="input-label justify-between">
                    <span>
                      {ORGANIZATION_ADMIN_EMAIL}
                      <span className="color-secondary d-iblock ml10">*</span>
                    </span>
                  </div>
                  <Input
                    type="email"
                    value={newOrganization.admin_email}
                    placeholder={ORGANIZATION_ADMIN_EMAIL}
                    onChange={(e) => onOrganizationAdminChange(e)}
                    className="trailer-search-input"
                  />
                </div>
              </div>
            )}
          </div>
          <ErrorBox obj={newOrganization} />
          {!newOrganization.isAdminEmailSet && (
            <div className="justify-end mt50">
              <button className="btn white mr10" onClick={onCancel}>
                {CANCEL}
              </button>
              <button className="btn primary" onClick={onConfirm}>
                {CONFIRM}
              </button>
            </div>
          )}
          {newOrganization.isAdminEmailSet && (
            <div className="justify-end mt50">
              <button className="btn primary" onClick={onAdminConfirm}>
                {CONFIRM}
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddOrganizationModal);
