import React from 'react';
import { observer } from 'mobx-react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { SHOW_SHIPMENTS } from '../constants';

const ChartComponent = (props) => {
  const data = { ...props };
  return (
    <div className="chart-component flex-column align-center">
      <div className="chart-wrapper">
        <CircularProgressbar
          value={data.value}
          text={data.innerCircleText}
          strokeWidth={5}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0.5,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',

            // Text size
            textSize: '24px',

            // Colors
            pathColor: data.pathColor,
            textColor: '#444444',
          })}
        />
      </div>
      <p className="chart-title">{data.title}</p>
      <p className="chart-text" style={{ color: data.pathColor }}>
        {data.text}
      </p>
      {data.link && (
        <a href={data.link} className="chart-link">
          {SHOW_SHIPMENTS}
        </a>
      )}
    </div>
  );
};

export default observer(ChartComponent);
