import React from 'react';
import { observer } from 'mobx-react';
import { InputNumber, Select, Tooltip } from 'antd';
import {
  AMOUNT,
  CHARGE_ITEM,
  CURRENCY,
  DELETE_RECORD_TEXT,
} from '../../../constants';
import { useMst } from '../../../state/RootModel';
import trash from '../../../assets/images/trash.svg';

const { Option } = Select;

const BillingDetailComponent = (props) => {
  const { data } = props;

  const {
    shipmentsPageState: {
      currentShipment: {
        currencyTypes,
        availableShipmentCharges,
        shipmentChargesArray,
        setShipmentChargeToBeDeleted,
      },
    },
  } = useMst();

  const currentBillingDetail = shipmentChargesArray.find((item) => {
    return item.shipment_charge_id === data.shipment_charge_id;
  });

  const onCurrencySelect = (value) => {
    currentBillingDetail.setCurrency(value);
  };

  const onChargeItemSelect = (value) => {
    const billingCharge = availableShipmentCharges.find((item) => {
      return item.charge_id === value;
    });
    currentBillingDetail.setChargeItem(billingCharge);
  };

  const onChangeAmount = (value) => {
    currentBillingDetail.setAmount(Number(value));
  };

  const onClickDelete = () => {
    setShipmentChargeToBeDeleted(currentBillingDetail.shipment_charge_id);
  };

  return (
    <div className="align-center mt20 w100">
      <div className="input-wrapper w15">
        <div className="input-label justify-between">
          <span>{CURRENCY}</span>
        </div>
        <Select
          className="text-capitalize"
          style={{ width: '100%' }}
          onChange={onCurrencySelect}
          value={currentBillingDetail.currency}
        >
          {currencyTypes.map((item, index) => (
            <Option key={index} value={item} className="text-capitalize">
              {item}
            </Option>
          ))}
        </Select>
      </div>
      <div className="input-wrapper w35 ml5pc">
        <div className="input-label justify-between">
          <span>{CHARGE_ITEM}</span>
        </div>
        <Select
          className="text-capitalize"
          style={{ width: '100%' }}
          onChange={onChargeItemSelect}
          value={currentBillingDetail.charge_id}
        >
          {availableShipmentCharges.map((item) => (
            <Option
              key={item.charge_id}
              value={item.charge_id}
              className="text-capitalize"
            >
              {item.charge_name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="input-wrapper w30 ml5pc">
        <div className="input-label justify-between">
          <span>{AMOUNT}</span>
        </div>
        <InputNumber
          className="add-edit-input"
          onChange={onChangeAmount}
          value={currentBillingDetail.price}
          precision={2}
          step={0.01}
          type={'number'}
        />
      </div>
      <Tooltip title={DELETE_RECORD_TEXT}>
        <img
          src={trash}
          alt="delete"
          className="pointer ml20"
          onClick={onClickDelete}
          width="15px"
        />
      </Tooltip>
    </div>
  );
};

export default observer(BillingDetailComponent);
