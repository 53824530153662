import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import logo from '../../../assets/images/kamion-logo.svg';
import successIcon from '../../../assets/images/success-icon.svg';

const DispatchSuccessModal = (props) => {
  const title = props.title;
  const text = props.text;
  const buttonText = props.buttonText;
  const open = props.open;
  const closeModal = props.closeModal;

  const redirectToBackLink = () => {
    closeModal();
  };

  return (
    <div>
      <Modal
        visible={open}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={700}
        className="success-modal"
      >
        <div className="success-modal-content">
          <img src={logo} alt="logo" className="logo mb50" />
          <img src={successIcon} alt="success" className="mb20" />
          <h2 className="modal-title">{title}</h2>
          <p className="text-italic color-white mt20">{text}</p>
          <button className="btn secondary mt20" onClick={redirectToBackLink}>
            {buttonText}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default observer(DispatchSuccessModal);
