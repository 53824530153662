import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { DatePicker, Input, Select, Upload } from 'antd';
import fileUpload from '../../../assets/images/file-upload.svg';
import {
  GENERAL_INFORMATION,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  PHONE_NUMBER,
  CHANGE_PASSWORD,
  STREET_ADDRESS,
  CITY,
  STATE,
  POSTAL_CODE,
  PROFILE_PICTURE,
  IMAGE_UPLOAD,
  DATE_OF_BIRTH,
  COUNTRY,
  OTHER,
  CODE_NAME,
} from '../../../constants';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';

const { Option } = Select;
const GeneralInformation = (props) => {
  const profilePhoto = [...props.profilePhoto];
  const handleProfilePhotoUpload = props.uploadProfilePhoto;

  const {
    employeesPageState: { editedEmployee },
    loginForm: { currentUser },
  } = useMst();

  const employee = editedEmployee
    ? editedEmployee
    : currentUser
    ? currentUser
    : null;

  const openChangePasswordModal = (e) => {
    e.preventDefault();
    currentUser.setChangePasswordModalOpen(true);
  };

  const handleOnPreview = (image) => {
    window.open(image.url, '_blank');
  };
  const setEmployeeDateOfBirth = (value, string) => {
    employee.setDateOfBirth(string);
  };

  return (
    <div className="form-page-section">
      <div className="table-title-row">
        <span className="color-primary text-medium ml10">
          {GENERAL_INFORMATION}
        </span>
      </div>
      <div className="section-content flex">
        <div className="photo-upload-wrapper pr20 flex-column justify-start">
          <div className="input-label">{PROFILE_PICTURE}</div>
          <Upload
            listType={'picture-card'}
            accept=".jpg, .png"
            fileList={profilePhoto}
            onChange={handleProfilePhotoUpload}
            beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            onPreview={handleOnPreview}
          >
            {!profilePhoto.length && (
              <div className="align-center justify-between pointer">
                <span className="color-primary">{IMAGE_UPLOAD}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            )}
          </Upload>
        </div>
        <div>
          <div className="align-center">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{FIRST_NAME}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => employee.setFirstName(e.target.value)}
                className="add-edit-input"
                value={employee.first_name}
              />
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{LAST_NAME}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => employee.setLastName(e.target.value)}
                className="add-edit-input"
                value={employee.last_name}
              />
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{CODE_NAME}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => employee.setCodeName(e.target.value)}
                className="add-edit-input"
                value={employee.code_name}
              />
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{DATE_OF_BIRTH}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={setEmployeeDateOfBirth}
                value={
                  employee.date_of_birth ? moment(employee.date_of_birth) : null
                }
                // disabledDate={disabledDate}
              />
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{EMAIL}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                onChange={(e) => employee.setEmail(e.target.value)}
                className="add-edit-input"
                value={employee.email}
                disabled
              />
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{PHONE_NUMBER}</span>
                <span className="color-secondary">*</span>
              </div>
              <PhoneInput
                placeholder="e.g. 712338645"
                value={employee.phone_number}
                onChange={(value) => employee.setPhoneNumber(value)}
                maxLength={15}
                displayInitialValueAsLocalNumber
              />
            </div>
          </div>
          <div className="align-center mt30">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{STREET_ADDRESS}</span>
              </div>
              <Input
                onChange={(e) => employee.setStreetAddress(e.target.value)}
                className="add-edit-input"
                value={employee.street_address}
              />
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{COUNTRY}</span>
              </div>
              <Select
                className={'w100'}
                onChange={(country) => employee.setCountry(country)}
                value={employee.country}
              >
                {employee.countriesArray.map((country, index) => (
                  <Option key={index} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{STATE}</span>
              </div>
              <Select
                className={'w100'}
                onChange={(country) => employee.setState(country)}
                value={employee.state}
              >
                {employee?.filteredStates?.states.map((state, index) => (
                  <Option key={index} value={state}>
                    {state}
                  </Option>
                ))}
                <Option key={'other'} value={'Other'}>
                  {OTHER}
                </Option>
              </Select>
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{CITY}</span>
              </div>
              <Input
                onChange={(e) => employee.setCity(e.target.value)}
                className="add-edit-input"
                value={employee.city}
              />
            </div>
            <div className="input-wrapper ml20">
              <div className="input-label justify-between">
                <span>{POSTAL_CODE}</span>
              </div>
              <Input
                onChange={(e) => employee.setPostalCode(e.target.value)}
                className="add-edit-input"
                value={employee.postal_code}
              />
            </div>
          </div>
          {currentUser && (
            <a
              href="# "
              className="btn transparent mt5"
              onClick={openChangePasswordModal}
            >
              {CHANGE_PASSWORD}?
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformation);
