import { types } from 'mobx-state-tree';

export const AssociationModel = types
  .model('AssociationModel', {
    association_id: types.identifierNumber,
    driver_id: types.maybe(types.maybeNull(types.number)),
    first_name: types.maybe(types.maybeNull(types.string)),
    last_name: types.maybe(types.maybeNull(types.string)),
    internal_company_id: types.maybe(types.maybeNull(types.string)),
    start_driving: types.maybe(types.maybeNull(types.string)),
    end_driving: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
