import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';

import DispatchTourDetails from '../tour/DispatchTourDetails';

import {
  ACTIONS,
  ADD_ACTION,
  ALL_SHIPMENT_ACTIONS,
  TRACK_SHIPMENT,
  TRACKING_SHIPMENT,
  VEHICLE_ACTIONS,
} from '../../../constants/dispatch';
import expanded from '../../../assets/images/dispatch/expand.svg';
import expandedWhite from '../../../assets/images/dispatch/expand-white.svg';
import collapseWhite from '../../../assets/images/dispatch/collapse-white.svg';
import collapseBlack from '../../../assets/images/dispatch/collapse-black.svg';
import DispatchNewRouteActionItem from '../routeAction/DispatchNewRouteActionItem';
import VehicleRouteActions from '../routeAction/VehicleRouteActions';
import { HIDE, NOT_AVAILABLE, SHOW, TRAILER, TRUCK } from '../../../constants';
import TackingShipmentDetails from '../shipments/TackingShipmentDetails';
import PageRoutes from '../../../utils/PageRoutes';

const DispatchMapOrganizeTour = () => {
  const {
    dispatchPageState: {
      showTourDetailsStatus,
      isOrganizeTourOpened,
      setIsOrganizeTourOpened,
      addNewRouteActionItem,
      getSavedRouteTripItems,
      isAllShipmentActionsOpened,
      setIsAllShipmentActionsOpened,
      selectedVehicleRouteActions,
      setSelectedVehicleRouteActions,
      setIsAssetsListOpened,
      selectedVehicleForRouteActions,
      selectedVehicleTypeForRouteActions,
      selectedShipmentDetails,
      getNewRouteTripItem,
      trackingShipment,
      setIsTrackingShipmentDetailsOpened,
      isTrackingShipmentDetailsOpened,
    },
  } = useMst();

  const [vehicleActionsExpanded, setVehicleActionsExpanded] = useState(true);

  const onExpandVehicleActions = () => {
    setVehicleActionsExpanded(!vehicleActionsExpanded);
  };

  const onShowAllShipmentActions = () => {
    setIsAllShipmentActionsOpened(!isAllShipmentActionsOpened);
    if (!isAllShipmentActionsOpened) {
      // rootInstance.dispatchPageState.removeTripItem();
      setIsOrganizeTourOpened(false);
    }
    setSelectedVehicleRouteActions(null);
  };
  const onShowOrganizeTour = () => {
    setIsOrganizeTourOpened(!isOrganizeTourOpened);
    let shipment_data = {
      selected_trailer: null,
      location_name: false,
      shipment_id: false,
      location: false,
    };
    if (isOrganizeTourOpened) {
      rootInstance.dispatchPageState.removeTripItem();
    } else {
      let selected_trailer = {
        trailer_id: null,
        trailer_company_id: null,
      };
      if (getSavedRouteTripItems && getSavedRouteTripItems.length > 0) {
        getSavedRouteTripItems.map((route, index) => {
          selected_trailer.trailer_id = route.trailer.trailer_id;
          selected_trailer.trailer_company_id =
            route.trailer.trailer_company_id;
        });
      }
      if (selectedShipmentDetails) {
        // rootInstance.dispatchPageState.beforeEnterSetSelectedShipment(
        //   selectedShipmentDetails.shipment_id
        // );
        // rootInstance.dispatchPageState.loadRouteActionTrailers();
        // rootInstance.dispatchPageState.loadRouteActions();

        shipment_data.shipment_id = selectedShipmentDetails.shipment_id;
        if (
          selectedShipmentDetails.delivery_location?.location_name !==
          NOT_AVAILABLE
        ) {
          shipment_data.location = selectedShipmentDetails.delivery_location;
        }
      }

      shipment_data.selected_trailer = selected_trailer;

      addNewRouteActionItem(shipment_data);
      setIsAllShipmentActionsOpened(false);
    }

    setSelectedVehicleRouteActions(null);
    setIsAssetsListOpened(false);
  };

  const onShipmentListClick = () => {
    const params = { shipmentId: selectedShipmentDetails.shipment_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.ViewShipment.name),
      params
    );
  };

  const onCollapseTrackingShipmentDetails = () => {
    setIsTrackingShipmentDetailsOpened(!isTrackingShipmentDetailsOpened);
  };

  // const onClearTrackingShipment = () => {
  //   clearTrackingShipment();
  // };
  return (
    <div className="dispatch-map-organize-tour">
      {trackingShipment?.length > 0 && (
        <div>
          <div className="dispatch-map-organize-tour__trigger filter-trigger-item ph10 align-center flex-wrap cursor-pointer">
            <p className="text-bold text-uppercase text-black">
              {TRACKING_SHIPMENT} {selectedShipmentDetails?.shipment_number}
            </p>
            <img
              onClick={onCollapseTrackingShipmentDetails}
              src={isTrackingShipmentDetailsOpened ? collapseBlack : expanded}
              alt={TRACK_SHIPMENT}
              title={isTrackingShipmentDetailsOpened ? HIDE : SHOW}
            />
          </div>
          {isTrackingShipmentDetailsOpened && (
            <TackingShipmentDetails data={trackingShipment[0]} />
          )}
        </div>
      )}
      {/*<div*/}
      {/*  className="dispatch-map-organize-tour__trigger filter-trigger-item ph10 align-center flex-wrap cursor-pointer"*/}
      {/*  onClick={onShowPickupAndDelivery}*/}
      {/*>*/}
      {/*  <p className="text-bold text-uppercase text-black">{TOUR_DETAILS}</p>*/}
      {/*  <img*/}
      {/*    src={showTourDetailsStatus ? closeExpanded : expanded}*/}
      {/*    alt="route details toggle"*/}
      {/*  />*/}
      {/*</div>*/}
      {showTourDetailsStatus && <DispatchTourDetails />}
      {selectedVehicleRouteActions && selectedVehicleRouteActions.length > 0 && (
        <div className="dispatch-map-organize-tour__main vehicle-route-actions">
          <div
            className="bg-primary holder-vehicle align-center justify-between ph10 align-center flex-wrap cursor-pointer"
            onClick={onExpandVehicleActions}
          >
            <p>
              {VEHICLE_ACTIONS}
              <br />
              <span>
                {selectedVehicleTypeForRouteActions}:{' '}
                {selectedVehicleForRouteActions.internal_company_id}
                {' - '}
                {selectedVehicleTypeForRouteActions === TRAILER &&
                  selectedVehicleForRouteActions.trailer_type}
                {selectedVehicleTypeForRouteActions === TRUCK &&
                  selectedVehicleForRouteActions?.vehicle_make}
              </span>
            </p>
            <img
              src={vehicleActionsExpanded ? collapseWhite : expandedWhite}
              className="cursor-pointer"
              title={
                vehicleActionsExpanded
                  ? 'Collapse ' + VEHICLE_ACTIONS
                  : 'Expand ' + VEHICLE_ACTIONS
              }
              alt={
                vehicleActionsExpanded
                  ? 'Collapse ' + VEHICLE_ACTIONS
                  : 'Expand ' + VEHICLE_ACTIONS
              }
            />
          </div>
          {vehicleActionsExpanded &&
            selectedVehicleRouteActions.map((item) => (
              <VehicleRouteActions key={item.route_action_id} data={item} />
            ))}
        </div>
      )}

      {getSavedRouteTripItems.length > 0 && (
        <div className="dispatch-map-organize-tour__main">
          <div
            className="bg-primary holder-vehicle align-center justify-between ph10 align-center flex-wrap cursor-pointer"
            onClick={onShowAllShipmentActions}
          >
            <p>
              {ALL_SHIPMENT_ACTIONS}
              <br />
              {selectedShipmentDetails?.shipment_number && (
                <span onClick={onShipmentListClick} className="text-underline">
                  {selectedShipmentDetails?.shipment_number &&
                    'Shipment: ' + selectedShipmentDetails?.shipment_number}
                </span>
              )}
            </p>
            <img
              src={isAllShipmentActionsOpened ? collapseWhite : expandedWhite}
              className="cursor-pointer"
              alt={ALL_SHIPMENT_ACTIONS}
            />
          </div>
          {/*{isAllShipmentActionsOpened && (*/}
          {/*  <div className="inside-details">*/}
          {/*    <div className="inside-details__single-trip-wrapper">*/}
          {/*      {!getEditingRouteAction &&*/}
          {/*        getSavedRouteTripItems.map((routeItem, index) => (*/}
          {/*          <DispatchRouteActionItem*/}
          {/*            data={routeItem}*/}
          {/*            index={index}*/}
          {/*            key={index}*/}
          {/*          />*/}
          {/*        ))}*/}
          {/*      {getEditingRouteAction && (*/}
          {/*        <DispatchEditingRouteActionItem*/}
          {/*          data={getEditingRouteAction}*/}
          {/*        />*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      )}
      <div className="dispatch-map-organize-tour__main">
        <div
          className="holder align-center justify-between ph10 align-center flex-wrap cursor-pointer"
          onClick={onShowOrganizeTour}
        >
          <p className="text-capitalize">{ADD_ACTION}</p>
          <img
            src={isOrganizeTourOpened ? collapseWhite : expandedWhite}
            className="cursor-pointer"
            alt={ACTIONS}
          />
        </div>
        {getNewRouteTripItem && (
          <div className="inside-details">
            <div className="inside-details__add-trip">
              <div className="inside-details__single-trip-wrapper">
                <DispatchNewRouteActionItem data={getNewRouteTripItem} />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(DispatchMapOrganizeTour);
