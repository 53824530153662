import { Tooltip } from 'antd';
import React from 'react';
import moment from 'moment';
import {
  ATTACHED_FILE,
  DATE_TIME_CREATED,
  DOCUMENT_TYPE,
  VIEW_FILE,
} from '../constants';

export const documentsColumnsFunc = (rootInstance) => ({
  '1': {
    title: DOCUMENT_TYPE,
    dataIndex: 'document_type.user_document_type_name',
    key: 'document_type',
  },
  '2': {
    title: DATE_TIME_CREATED,
    dataIndex: 'date_added',
    key: 'date_added',
    render: (text, record) => {
      return moment(record.date_added).format('MMM d, YYYY HH:mm');
    },
  },
  '3': {
    title: ATTACHED_FILE,
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <Tooltip placement="top" title={VIEW_FILE}>
          <a
            className={'document-download-link'}
            href={record.document_url}
            target="blank"
          >
            {text}
          </a>
        </Tooltip>
      );
    },
  },
});
