import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Select } from 'antd';
import { useMst } from '../../../state/RootModel';
import { CANCEL, FILTER, SEARCH_BY_ID, TRAILER } from '../../../constants';
import {
  EMPTY_AVAILABLE_TRAILERS_TABLE_TEXT,
  TRAILERS_LIST,
} from '../../../constants/dispatch';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import { trailerIconBlue } from '../Icon';
import { DebounceInput } from 'react-debounce-input';
import AssetsTable from './AssetsTable';

const { Option } = Select;
const OrganizeTourTrailerModal = () => {
  const {
    dispatchPageState: {
      isOrganizeTourTrailersModalOpen,
      setIsOrganizeTourTrailersModalOpen,
      setTrailerModalSearch,
      trailerModalSearch,
      getOrganizeTourTrailers,
      getAvailableTrailersTableColumns,
      isSelectedTrailerChanged,
      selectedTrailerForMap,
      trailerModalLocationSearch,
      setTrailerModalLocationSearch,
      getAllFilteredAvailableLocations,
      setTrailerOgPage,
      getAvailableDriverFilterOptions,
      trailerFilterOptionSearch,
      setTrailersFilterOption,
      resetOgTrailerData,
      setLocationsSearchText,
    },
  } = useMst();

  const onClose = () => {
    setPageNumber(1);
    resetOgTrailerData();
    setIsOrganizeTourTrailersModalOpen(false);
  };

  const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  };
  const onTrailerSearch = (e) => {
    setTrailerModalSearch(e.target.value);
  };
  const onSetTrailerLocationSearch = (location) => {
    setPageNumber(1);
    resetOgTrailerData();
    setTrailerModalLocationSearch(location);
    setLocationsSearchText('');
  };

  const onSetTrailerFilterOption = (option) => {
    setPageNumber(1);
    resetOgTrailerData();
    setTrailersFilterOption(option);
  };

  const [page, setPageNumber] = useState(2);

  const updateTrailers = () => {
    setPageNumber((prev) => {
      return prev + 1;
    });
    setTrailerOgPage(page);
  };

  const onSearchLocationsList = (value) => {
    setLocationsSearchText(value);
  };

  return (
    <div>
      <Modal
        visible={!!isOrganizeTourTrailersModalOpen}
        style={{ top: 88 }}
        footer={null}
        centered={false}
        closable={true}
        onCancel={onClose}
        width={1500}
        className="assign-trailer-modal"
      >
        <div className="p32">
          <div className="assign-trailer-modal__available-trailers">
            <div className="align-center mb40">
              <p className="mr50 color-title text-bold text-uppercase">
                {TRAILERS_LIST}
              </p>
              <div className="input-wrapper">
                <DebounceInput
                  debounceTimeout={300}
                  value={trailerModalSearch}
                  placeholder={SEARCH_BY_ID}
                  onChange={(e) => onTrailerSearch(e)}
                  className="trailer-search-input"
                />
              </div>
              <div className="input-wrapper ml-a mr40 align-center">
                <p className="mr10 color-title">{FILTER}</p>
                <Select
                  style={{
                    minWidth: 150,
                  }}
                  className="fb200"
                  value={trailerFilterOptionSearch}
                  onChange={onSetTrailerFilterOption}
                >
                  {getAvailableDriverFilterOptions.map((option, index) => (
                    <Option key={index} value={option}>
                      {option}
                    </Option>
                  ))}
                </Select>
                <p className="ml10 mr10 color-title">to</p>
                <Select
                  style={{
                    minWidth: 250,
                    width: '100%',
                  }}
                  showSearch
                  optionFilterProp="children"
                  className="fb200"
                  allowClear={true}
                  value={trailerModalLocationSearch}
                  onChange={onSetTrailerLocationSearch}
                  onSearch={onSearchLocationsList}
                >
                  {getAllFilteredAvailableLocations.map((location) => (
                    <Option
                      key={location.location_id}
                      value={location.location_id}
                    >
                      {location.location_name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            {getOrganizeTourTrailers && (
              <AssetsTable
                tableText={EMPTY_AVAILABLE_TRAILERS_TABLE_TEXT}
                data={getOrganizeTourTrailers}
                column={getAvailableTrailersTableColumns()}
                update={updateTrailers}
              />
            )}
            {/*<Table*/}
            {/*    className="mb20"*/}
            {/*    rowKey={'vehicle_id'}*/}
            {/*    dataSource={getOrganizeTourTrailers}*/}
            {/*    columns={getAvailableTrailersTableColumns()}*/}
            {/*    pagination={false}*/}
            {/*    // scroll={{y: 200}}*/}
            {/*    locale={{*/}
            {/*        emptyText: (*/}
            {/*            <Empty*/}
            {/*                image={Empty.PRESENTED_IMAGE_SIMPLE}*/}
            {/*                description={EMPTY_AVAILABLE_TRAILERS_TABLE_TEXT}*/}
            {/*            />*/}
            {/*        ),*/}
            {/*    }}*/}
            {/*/>*/}
            {/*<div className="table-footer">*/}
            {/*    <Pagination*/}
            {/*        onChange={paginationChange}*/}
            {/*        total={trailerOgTotal}*/}
            {/*        current={trailerOgPage}*/}
            {/*        pageSize={trailerOgPerPage}*/}
            {/*    />*/}
            {/*</div>*/}
          </div>
          {isSelectedTrailerChanged && (
            <MapContainer
              style={{
                width: '100%',
                height: '300px',
                position: 'relative',
                top: 0,
              }}
              center={[
                selectedTrailerForMap.trailer_current_action.location_latitude,
                selectedTrailerForMap.trailer_current_action.location_longitude,
              ]}
              zoom={12}
              className="view-fleet-map"
              animate={true}
            >
              <ChangeView
                center={[
                  selectedTrailerForMap.trailer_current_action
                    .location_latitude,
                  selectedTrailerForMap.trailer_current_action
                    .location_longitude,
                ]}
                zoom={13}
              />
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {/*<DispatchMapMarker data={dispatchData.pickup_location} type={PICKUP_TYPE}/>*/}
              {isSelectedTrailerChanged && (
                <Marker
                  key={selectedTrailerForMap?.internal_company_id}
                  position={[
                    selectedTrailerForMap.trailer_current_action
                      .location_latitude,
                    selectedTrailerForMap.trailer_current_action
                      .location_longitude,
                  ]}
                  // @ts-ignore
                  icon={trailerIconBlue}
                  className="dispatch-map-marker"
                >
                  <Popup>
                    <p className="dispatch-map-marker__type delivery text-bold">
                      {TRAILER}
                      <span className="ml10">
                        {selectedTrailerForMap?.internal_company_id}
                      </span>
                    </p>
                    <p className="dispatch-map-marker__info">
                      {/*<span className='dispatch-map-marker__company text-bold'>{location.company}</span>*/}
                      <br />
                      {/*{location.address},{location.city},{location.state}*/}
                    </p>
                  </Popup>
                </Marker>
              )}
            </MapContainer>
          )}

          <div className="mt50">
            <button className="btn white mr10" onClick={onClose}>
              {CANCEL}
            </button>
            {/*<button className="btn primary" onClick={onConfirm}>*/}
            {/*    {ASSIGN_TRAILER}*/}
            {/*</button>*/}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(OrganizeTourTrailerModal);
