import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import {
  ADDITIONAL_ORGANIZATION_INFORMATION,
  CBSA,
  CTPAT_NUMBER,
  CVOR,
  DOT,
  DUNS_NUMBER,
  FAST,
  FEIN,
  MC,
  PIP,
  SCAC,
} from '../../../constants/organizations/organizations';
import { Input } from 'antd';
import { IFTA } from '../../../constants';

const EditOrganizationAdditionalInformation = () => {
  const {
    organizationState: { editedOrganization },
  } = useMst();

  return (
    <div className="add-trailer-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">
          {ADDITIONAL_ORGANIZATION_INFORMATION}
        </span>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{MC}</span>
          </div>
          <Input
            placeholder={MC}
            onChange={(e) =>
              editedOrganization.setOrganizationMC(e.target.value)
            }
            value={editedOrganization?.mc}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{DOT}</span>
          </div>
          <Input
            placeholder={DOT}
            onChange={(e) =>
              editedOrganization.setOrganizationDOT(e.target.value)
            }
            value={editedOrganization?.dot}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CVOR}</span>
          </div>
          <Input
            placeholder={CVOR}
            onChange={(e) =>
              editedOrganization.setOrganizationCVOR(e.target.value)
            }
            value={editedOrganization?.cvor}
            className="add-edit-input"
          />
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{IFTA}</span>
          </div>
          <Input
            placeholder={IFTA}
            onChange={(e) => editedOrganization.setIfta(e.target.value)}
            value={editedOrganization?.ifta}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{FEIN}</span>
          </div>
          <Input
            placeholder={FEIN}
            onChange={(e) => editedOrganization.setFein(e.target.value)}
            value={editedOrganization?.fein}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{DUNS_NUMBER}</span>
          </div>
          <Input
            placeholder={DUNS_NUMBER}
            onChange={(e) => editedOrganization.setDunsNumber(e.target.value)}
            value={editedOrganization?.duns_number}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{SCAC}</span>
          </div>
          <Input
            placeholder={SCAC}
            onChange={(e) => editedOrganization.setScac(e.target.value)}
            value={editedOrganization?.scac}
            className="add-edit-input"
          />
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{CBSA}</span>
          </div>
          <Input
            placeholder={CBSA}
            onChange={(e) => editedOrganization.setCbsa(e.target.value)}
            value={editedOrganization?.cbsa}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CTPAT_NUMBER}</span>
          </div>
          <Input
            placeholder={CTPAT_NUMBER}
            onChange={(e) => editedOrganization.setCtpatNumber(e.target.value)}
            value={editedOrganization?.ctpat_number}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{PIP}</span>
          </div>
          <Input
            placeholder={PIP}
            onChange={(e) => editedOrganization.setPip(e.target.value)}
            value={editedOrganization?.pip}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{FAST}</span>
          </div>
          <Input
            placeholder={FAST}
            onChange={(e) => editedOrganization.setFast(e.target.value)}
            value={editedOrganization?.fast}
            className="add-edit-input"
          />
        </div>
      </div>
    </div>
  );
};

export default observer(EditOrganizationAdditionalInformation);
