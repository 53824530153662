import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Input } from 'antd';
import { useMst } from '../../../../../state/RootModel';
import {
  TITLE,
  STATUS_FOR_ACTIVE_SHIPMENT,
  CANCEL,
  EDIT,
} from '../../../../../constants';
import ErrorBox from '../../../../ErrorBox';

const EditedShipmentStatusComponent = () => {
  const {
    shipmentSpecificsPageState: {
      shipmentStatusToBeEdited,
      setShipmentStatusToBeEdited,
      setEditingShipmentSpecific,
      editedShipmentSpecificType,
    },
  } = useMst();

  const onSelectActiveForShipment = () => {
    shipmentStatusToBeEdited.setActive(
      !shipmentStatusToBeEdited.status_for_active_shipment
    );
  };

  const onCancel = () => {
    setEditingShipmentSpecific(false);
    setShipmentStatusToBeEdited(null);
  };
  const onEdit = () => {
    shipmentStatusToBeEdited.saveItem();
  };
  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) => shipmentStatusToBeEdited.setName(e.target.value)}
          value={shipmentStatusToBeEdited?.name}
        />
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="align-center">
          <Checkbox
            checked={shipmentStatusToBeEdited?.status_for_active_shipment}
            onChange={onSelectActiveForShipment}
            className="align-center"
          >
            <p>{STATUS_FOR_ACTIVE_SHIPMENT}</p>
          </Checkbox>
        </div>
      </div>
      <ErrorBox obj={editedShipmentSpecificType} />

      <div className="justify-end mt20">
        <button className="btn white mr10" onClick={onCancel}>
          {CANCEL}
        </button>
        <button className="btn primary" onClick={onEdit}>
          {EDIT}
        </button>
      </div>
    </div>
  );
};

export default observer(EditedShipmentStatusComponent);
