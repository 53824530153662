import moment from 'moment';
import React from 'react';
import {
  DATE_END,
  DATE_STARTED,
  MAKE_MODEL_YEAR,
  TRUCK_ID,
} from '../../../constants';

export const associationsColumnsFunc = (rootInstance) => ({
  '1': {
    title: TRUCK_ID,
    dataIndex: 'truck_internal_company_id',
    key: 'truck_internal_company_id',
  },
  '2': {
    title: MAKE_MODEL_YEAR,
    key: 'make_model_year',
    render: (text, record) => {
      return <span>{`${record.truck_make} / ${record.truck_model_name}`}</span>;
    },
  },
  '3': {
    title: DATE_STARTED,
    dataIndex: 'start_date',
    key: 'start_date',
    render: (text, record) => {
      return text && <span>{moment(text).format('YYYY-MM-DD')}</span>;
    },
  },
  '4': {
    title: DATE_END,
    dataIndex: 'end_date',
    key: 'end_date',
    render: (text, record) => {
      return text && <span>{moment(text).format('YYYY-MM-DD')}</span>;
    },
  },
});
