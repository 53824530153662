import React from 'react';
import {
  CLASS,
  TYPE,
  LINKED_TRUCK,
  MAKE,
  VIN,
  EDIT_TRAILER,
  DELETE_TRAILER,
  LABEL_ID,
  STATUS,
  FLEET_OUT_OF_SERVICE_STATUS,
} from '../../../constants';
import trash from '../../../assets/images/trash.svg';
import edit from '../../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import PageRoutes from '../../../utils/PageRoutes';
import { rootInstance } from '../../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.fleetPageState.setTrailerToBeDeleted(record.vehicle_id);
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  const params = { trailerId: record.vehicle_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditTrailer.name),
    params
  );
};

export const trailersColumnsFunc = (rootInstance) => ({
  '0': {
    title: LABEL_ID,
    dataIndex: 'internal_company_id',
    key: 'internal_company_id',
    render: (text, record) => {
      return <span>{text ? text : '/'}</span>;
    },
  },
  '1': {
    title: CLASS,
    dataIndex: 'ownership_type',
    key: 'ownership_type',
    render: (text, record) => {
      return <span className="text-capitalize">{text}</span>;
    },
  },
  '2': {
    title: TYPE,
    key: 'vehicle_model_name',
    dataIndex: 'vehicle_model_name',
  },
  '3': {
    title: MAKE,
    key: 'vehicle_make',
    dataIndex: 'vehicle_make',
  },
  '4': {
    title: LINKED_TRUCK,
    key: 'linked_truck',
    dataIndex: 'linked_truck',
  },
  '5': {
    title: VIN,
    dataIndex: 'vin',
    key: 'vin',
  },
  '7': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      return (
        <div className="align-center">
          <span className="text-capitalize">{text}</span>
          <span
            className={`status-dot ml10 ${
              record.status === FLEET_OUT_OF_SERVICE_STATUS ? 'unavailable' : ''
            }`}
          ></span>
        </div>
      );
    },
  },
  '8': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          {rootInstance.userState.canRemoveVehicles && (
            <Tooltip title={DELETE_TRAILER}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          )}
          {rootInstance.userState.canEditVehicles && (
            <div onClick={(e) => onClickEdit(e, record)}>
              <Tooltip title={EDIT_TRAILER}>
                <img src={edit} alt="edit" className="pointer ph10" />
              </Tooltip>
            </div>
          )}
        </div>
      );
    },
  },
});
