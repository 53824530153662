import { types } from 'mobx-state-tree';
import {
  ADDRESS_REQUIRED,
  CARRIERS,
  CITY_REQUIRED,
  COMPANY_NAME_REQUIRED,
  COUNTRY_REQUIRED,
  EMAIL_ERROR_TEXT,
  EMAIL_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  STATE_REQUIRED,
} from '../../constants';
import {
  getErrorText,
  getRandomInt,
  setFetchingInProgress,
  validateEmail,
} from '../../utils/methods';
import { addCarrier, addCarrierEquipments } from '../../actions/carriers';
import { rootInstance } from '../RootModel';
import { EquipmentModel } from '../shipments/EquipmentModel';
import { EquipmentRelatedModel } from '../shipments/EquipmentRelatedModel';

export const CarrierLightModel = types
  .model('CarrierLightModel', {
    company_name: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    address: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    fax_number: types.maybe(types.maybeNull(types.string)),
    email: types.maybe(types.maybeNull(types.string)),
    dot_number: types.maybe(types.maybeNull(types.string)),
    mc_number: types.maybe(types.maybeNull(types.string)),
    carrier_code: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    contact_name: types.maybe(types.maybeNull(types.string)),
    contact_position: types.maybe(types.maybeNull(types.string)),
    insurance_company: types.maybe(types.maybeNull(types.string)),
    insurance_broker: types.maybe(types.maybeNull(types.string)),
    insurance_policy_number: types.maybe(types.maybeNull(types.string)),
    insurance_expiry_date: types.maybe(types.maybeNull(types.string)),
    insurance_cargo_amount: types.maybe(types.maybeNull(types.string)),
    insurance_liability_amount: types.maybe(types.maybeNull(types.number)),
    notes: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    equipments: types.frozen([]),
    equipmentsList: types.map(EquipmentRelatedModel),
    equipmentItems: types.map(EquipmentModel),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get equipmentsArray() {
        return Array.from(self.equipments.values());
      },
      get equipmentsListArray() {
        return Array.from(self.equipmentsList.values());
      },
      get equipmentItemsArray() {
        return Array.from(self.equipmentItems.values());
      },
    };
  })
  .actions((self) => {
    return {
      setNotes(notes) {
        self.notes = notes;
      },
      addNewEquipmentItem(item) {
        item.carrier_equipment_id = getRandomInt();
        self.equipmentsList.put({
          ...item,
        });
      },
      setEquipmentItem(item) {
        self.equipmentItems.put({
          ...item,
        });
      },
      setStatus(value) {
        self.status = value;
      },
      setCompanyName(value) {
        self.company_name = value;
      },
      setContactName(value) {
        self.contact_name = value;
      },
      setContactPosition(value) {
        self.contact_position = value;
      },
      setDotNumber(value) {
        self.dot_number = value;
      },
      setMcNumber(value) {
        self.mc_number = value;
      },
      setCarrierCode(value) {
        self.carrier_code = value;
      },
      setPhoneNumber(value) {
        self.phone_number = value;
      },
      setFaxNumber(value) {
        self.fax_number = value;
      },
      setEmail(value) {
        self.email = value;
      },
      setInsuranceCompany(value) {
        self.insurance_company = value;
      },
      setInsuranceBroker(value) {
        self.insurance_broker = value;
      },
      setInsurancePolicyNumber(value) {
        self.insurance_policy_number = value;
      },
      setInsuranceExpiryDate(value) {
        self.insurance_expiry_date = value;
      },
      setInsuranceCargoAmount(value) {
        self.insurance_cargo_amount = value;
      },
      setInsuranceLiabilityAmount(value) {
        self.insurance_liability_amount = value;
      },
      setPostalCode(value) {
        self.postal_code = value;
      },
      setCountry(value) {
        self.country = value;
      },
      setState(value) {
        self.state = value;
      },
      setCity(value) {
        self.city = value;
      },
      setAddress(value) {
        self.address = value;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveCarrierError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        const emailValid = validateEmail(self.email);
        if (!self.company_name) {
          this.setErrorText(COMPANY_NAME_REQUIRED);
        } else if (!self.phone_number) {
          this.setErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!self.email) {
          this.setErrorText(EMAIL_REQUIRED);
        } else if (!emailValid) {
          this.setErrorText(EMAIL_ERROR_TEXT);
        } else if (!self.country) {
          this.setErrorText(COUNTRY_REQUIRED);
        } else if (!self.state) {
          this.setErrorText(STATE_REQUIRED);
        } else if (!self.city) {
          this.setErrorText(CITY_REQUIRED);
        } else if (!self.address) {
          this.setErrorText(ADDRESS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveCarrier() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addCarrier(self)
            .then((response) => {
              const carrier_id = response.data.carrier_id;
              if (self.equipmentItemsArray) {
                addCarrierEquipments(carrier_id, self.equipmentItemsArray).then(
                  (res) => {
                    rootInstance.carriersPageState.setNewCarrier(null);
                    rootInstance.brokeragePageState.setActiveTab(CARRIERS);
                    setFetchingInProgress(false);
                    rootInstance.carriersPageState.setAddCarrierSuccessModalOpen(
                      true
                    );
                  }
                );
              } else {
                rootInstance.carriersPageState.setNewCarrier(null);
                rootInstance.brokeragePageState.setActiveTab(CARRIERS);
                setFetchingInProgress(false);
                rootInstance.carriersPageState.setAddCarrierSuccessModalOpen(
                  true
                );
              }
            })
            .catch((error) => {
              this.throwSaveCarrierError(error);
            });
        }
      },
    };
  });
