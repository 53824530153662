import { notification } from 'antd';
import { types } from 'mobx-state-tree';
import {
  DEFAULT_VALUE,
  ERROR_DELETING_COMPENSATION_VALUE,
} from '../../../constants/payroll';
import { deleteCompensationValuePromise } from '../../../promises/payroll';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';

export const CompensationValueModel = types
  .model('CompensationValueModel', {
    compensation_value_id: types.identifierNumber,
    compensation_value: types.maybe(types.maybeNull(types.string)),
    based_on: types.maybe(types.maybeNull(types.string)),
    rate: types.maybe(types.maybeNull(types.string)),
    default_value: types.optional(types.number, 0.0),
    currency: types.maybe(types.maybeNull(types.string)),

    default_value_temp: types.maybeNull(
      types.optional(types.string, DEFAULT_VALUE)
    ),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setDefaultValueTemp(value) {
        self.default_value_temp = value;
        self.default_value = parseFloat(value);
      },

      setCurrency(value) {
        self.currency = value;
      },
      setDefaultValue(value) {
        self.default_value = value;
      },
      setBasedOn(value) {
        self.based_on = value;
      },
      setRate(value) {
        self.rate = value;
      },
      setValue(value) {
        console.log(value);
        self.compensation_value = value;
      },
      deleteCompensationValue(compensation_id, compensation_value_id) {
        setFetchingInProgress(true);
        deleteCompensationValuePromise(compensation_id, compensation_value_id)
          .then(() => {
            setFetchingInProgress(false);
            rootInstance.payrollPageState.setValueToBeDeleted(null);
            rootInstance.payrollPageState.setIsDeleteValueModalOpen(false);
            rootInstance.payrollPageState.getCompensationStructures();
          })

          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION_VALUE,
              description: errorText,
            });
          });
      },
    };
  });
