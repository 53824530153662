import React from 'react';
import { rootInstance, useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import {
  ADD_COMPENSATION,
  MODAL_SUCCESS_ADD_COMPENSATION_BUTTON_TEXT,
  MODAL_SUCCESS_ADD_COMPENSATION_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../constants';
import AddCompensationComponent from '../../components/payroll/AddCompensation/AddCompensationComponent';
import SuccessModal from '../../components/SuccessModal';

const AddCompensationPage = () => {
  const {
    payrollPageState: {
      addCompensationSuccessModalOpen,
      setAddCompensationSuccessModalOpen,
    },
  } = useMst();

  return (
    <div>
      <div className="page add-payroll-page">
        <BreadcrumsComponent
          page={ADD_COMPENSATION}
          breadcrumbsBackLink={PageRoutes.Payroll.name}
        />
        <SuccessModal
          title={MODAL_SUCCESS_TITLE}
          text={MODAL_SUCCESS_ADD_COMPENSATION_TEXT}
          buttonText={MODAL_SUCCESS_ADD_COMPENSATION_BUTTON_TEXT}
          open={addCompensationSuccessModalOpen}
          closeModal={() => setAddCompensationSuccessModalOpen(false)}
        />
        <div className="page-content">
          <AddCompensationComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(AddCompensationPage);
