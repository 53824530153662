import React from 'react';
import { observer } from 'mobx-react';
import dropdownIcon from '../../assets/images/dropdown-icon.svg';
import { useMst } from '../../state/RootModel';
import DriversTable from './DriversTable';
import TrucksTable from './TrucksTable';
import TrailersTable from './TrailersTable';
import { FLEET } from '../../constants/menu';

const FleetList = () => {
  const {
    fleetPageState: { isFleetListOpen, setIsFleetListOpen },
    userState: { canViewVehicles, canViewDrivers },
  } = useMst();

  return (
    <div>
      <div className="table-title-row align-center">
        <div
          className="pointer"
          onClick={() => setIsFleetListOpen(!isFleetListOpen)}
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${isFleetListOpen ? 'open' : ''}`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {FLEET}
          </span>
        </div>
      </div>
      {isFleetListOpen && (
        <div>
          {canViewDrivers && <DriversTable />}
          {canViewVehicles && <TrucksTable />}
          {canViewVehicles && <TrailersTable />}
        </div>
      )}
    </div>
  );
};

export default observer(FleetList);
