import { notification } from 'antd';
import { types, getRoot } from 'mobx-state-tree';
import {
  deleteShipmentCharge,
  deleteShipmentDocumentType,
  deleteShipmentRouteAction,
  deleteShipmentStatus,
  getAvailableShipmentSpecificTypes,
} from '../../actions/shipmentSpecifics';
import {
  DELETE_SHIPMENT_SPECIFICS_ERROR,
  FETCH_SHIPMENT_SPECIFICS_ERROR,
} from '../../constants';
import {
  SHIPMENT_CHARGE,
  SHIPMENT_DOCUMENT_TYPE,
  ROUTE_ACTION,
  SHIPMENT_STATUS,
} from '../../constants/shipmentSpecificsTypes';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { ShipmentChargeLightModel } from './shipmentCharge/ShipmentChargeLightModel';
import { ShipmentChargeModel } from './shipmentCharge/ShipmentChargeModel';
import { shipmentChargesColumnsFunc } from './shipmentCharge/ShipmentChargesTableColumns';
import { RouteActiontLightModel } from './routeActions/RouteActiontLightModel';
import { routeActionsColumnsFunc } from './routeActions/RouteActionsTableColumns';
import { ShipmentDocumentTypeLightModel } from './shipmentDocumentType/ShipmentDocumentTypeLightModel';
import { ShipmentDocumentTypeModel } from './shipmentDocumentType/ShipmentDocumentTypeModel';
import { shipmentDocumentTypesColumnsFunc } from './shipmentDocumentType/ShipmentDocumentTypesTableColumns';
import { shipmentSpecifics } from '../../promises/shipments';
import { currencyTypes } from '../../promises/probills';
import { RouteActionModel } from './routeActions/RouteActionModel';
import { ShipmentStatusModel } from './shipmentStatus/ShipmentStatusModel';
import { shipmentStatusColumnsFunc } from './shipmentStatus/ShipmentStatusTableColumns';
import { ShipmentStatusLightModel } from './shipmentStatus/ShipmentStatusLightModel';

const allSettled = require('promise.allsettled');

export const ShipmentSpecificsPageModel = types
  .model('ShipmentSpecificsPageModel', {
    shipmentCharges: types.map(ShipmentChargeModel),
    routeActions: types.map(RouteActionModel),
    shipmentDocumentTypes: types.map(ShipmentDocumentTypeModel),
    shipmentStatuses: types.map(ShipmentStatusModel),
    currentPage: 1,
    pageSize: 10,
    totalItems: types.maybe(types.maybeNull(types.number)),
    availableShipmentSpecificTypes: types.frozen([]),
    currencyTypes: types.frozen([]),
    selectedShipmentSpecificType: SHIPMENT_CHARGE,
    selectedNewShipmentSpecificType: types.maybe(types.maybeNull(types.string)),

    newShipmentSpecificModalOpen: false,
    newShipmentCharge: types.maybe(types.maybeNull(ShipmentChargeLightModel)),
    newShipmentStatus: types.maybe(types.maybeNull(ShipmentStatusLightModel)),
    newRouteAction: types.maybe(types.maybeNull(RouteActiontLightModel)),
    newShipmentDocumentType: types.maybe(
      types.maybeNull(ShipmentDocumentTypeLightModel)
    ),

    addShipmentSpecificSuccessModalOpen: false,

    shipmentChargeToBeDeleted: types.maybeNull(
      types.reference(ShipmentChargeModel)
    ),
    successDeleteShipmentChargeModalOpen: false,
    shipmentStatusToBeDeleted: types.maybeNull(
      types.reference(ShipmentStatusModel)
    ),
    successDeleteShipmentStatusModalOpen: false,

    routeActionToBeDeleted: types.maybeNull(types.reference(RouteActionModel)),
    successDeleteRouteActionModalOpen: false,

    documentTypeToBeDeleted: types.maybeNull(
      types.reference(ShipmentDocumentTypeModel)
    ),
    successDeleteDocumentTypeModalOpen: false,

    /** Others / Edit **/
    editedShipmentSpecificType: SHIPMENT_CHARGE,
    editingShipmentSpecific: false,
    shipmentChargeToBeEdited: types.maybeNull(
      types.reference(ShipmentChargeModel)
    ),
    routeActionToBeEdited: types.maybeNull(types.reference(RouteActionModel)),
    shipmentDocumentTypeToBeEdited: types.maybeNull(
      types.reference(ShipmentDocumentTypeModel)
    ),
    shipmentStatusToBeEdited: types.maybeNull(
      types.reference(ShipmentStatusModel)
    ),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get shipmentChargesArray() {
        return Array.from(self.shipmentCharges.values());
      },
      get routeActionsArray() {
        return Array.from(self.routeActions.values());
      },
      get shipmentDocumentTypesArray() {
        return Array.from(self.shipmentDocumentTypes.values());
      },
      get shipmentStatusesArray() {
        return Array.from(self.shipmentStatuses.values());
      },
      get isShipmentStatusSelected() {
        return self.selectedShipmentSpecificType === SHIPMENT_STATUS;
      },
      get isShipmentChargeSelected() {
        return self.selectedShipmentSpecificType === SHIPMENT_CHARGE;
      },
      get isRouteActionSelected() {
        return self.selectedShipmentSpecificType === ROUTE_ACTION;
      },
      get isShipmentDocumentTypeSelected() {
        return self.selectedShipmentSpecificType === SHIPMENT_DOCUMENT_TYPE;
      },
      get isNewShipmentStatusSelected() {
        return self.selectedNewShipmentSpecificType === SHIPMENT_STATUS;
      },
      get isNewShipmentChargeSelected() {
        return self.selectedNewShipmentSpecificType === SHIPMENT_CHARGE;
      },
      get isNewRouteActionSelected() {
        return self.selectedNewShipmentSpecificType === ROUTE_ACTION;
      },
      get isNewShipmentDocumentTypeSelected() {
        return self.selectedNewShipmentSpecificType === SHIPMENT_DOCUMENT_TYPE;
      },
      get newShipmentSpecific() {
        if (self.newShipmentCharge) {
          return self.newShipmentCharge;
        } else if (self.newRouteAction) {
          return self.newRouteAction;
        } else if (self.newShipmentDocumentType) {
          return self.newShipmentDocumentType;
        } else if (self.newShipmentStatus) {
          return self.newShipmentStatus;
        }
        return null;
      },
    };
  })
  .actions((self) => {
    return {
      setEditingShipmentSpecific(value) {
        self.editingShipmentSpecific = value;
      },
      setDeleteShipmentChargeSuccessModalOpen(status) {
        self.successDeleteShipmentChargeModalOpen = status;
      },
      setDeleteShipmentStatusSuccessModalOpen(status) {
        self.successDeleteShipmentStatusModalOpen = status;
      },
      setShipmentChargeToBeEdited(id) {
        self.shipmentChargeToBeEdited = id;
      },
      setShipmentChargeToBeDeleted(id) {
        self.shipmentChargeToBeDeleted = id;
      },
      setShipmentStatusToBeDeleted(id) {
        self.shipmentStatusToBeDeleted = id;
      },
      setShipmentStatusToBeEdited(id) {
        self.shipmentStatusToBeEdited = id;
      },
      setDeleteRouteActionSuccessModalOpen(status) {
        self.successDeleteRouteActionModalOpen = status;
      },
      setRouteActionToBeEdited(id) {
        self.routeActionToBeEdited = id;
      },
      setRouteActionToBeDeleted(id) {
        self.routeActionToBeDeleted = id;
      },
      setDeleteDocumentTypeSuccessModalOpen(status) {
        self.successDeleteDocumentTypeModalOpen = status;
      },
      setShipmentDocumentTypeToBeEdited(id) {
        self.shipmentDocumentTypeToBeEdited = id;
      },
      setDocumentTypeToBeDeleted(id) {
        self.documentTypeToBeDeleted = id;
      },
      setAddShipmentSpecificSuccessModalOpen(status) {
        self.addShipmentSpecificSuccessModalOpen = status;
      },
      setNewShipmentStatus(obj) {
        if (obj) {
          this.setNewShipmentDocumentType(null);
          this.setNewRouteAction(null);
          this.setNewShipmentCharge(null);
        } else {
          self.newShipmentStatus?.clearModel();
        }
        self.newShipmentStatus = obj;
      },
      setNewShipmentCharge(obj) {
        if (obj) {
          this.setNewShipmentDocumentType(null);
          this.setNewRouteAction(null);
          this.setNewShipmentStatus(null);
        } else {
          self.newShipmentCharge?.clearModel();
        }
        self.newShipmentCharge = obj;
      },
      setNewShipmentDocumentType(obj) {
        if (obj) {
          this.setNewShipmentCharge(null);
          this.setNewRouteAction(null);
          this.setNewShipmentStatus(null);
        } else {
          self.newShipmentDocumentType?.clearModel();
        }
        self.newShipmentDocumentType = obj;
      },
      setNewRouteAction(obj) {
        if (obj) {
          this.setNewShipmentCharge(null);
          this.setNewShipmentDocumentType(null);
          this.setNewShipmentStatus(null);
        } else {
          self.newRouteAction?.clearModel();
        }
        self.newRouteAction = obj;
      },
      clearNewShipmentSpecifics() {
        this.setSelectedNewShipmentSpecificType(null);
        this.setNewShipmentCharge(null);
        this.setNewShipmentDocumentType(null);
        this.setNewRouteAction(null);
        this.setNewShipmentStatus(null);
      },
      setSelectedNewShipmentSpecificType(value) {
        self.selectedNewShipmentSpecificType = value;
        self.isNewShipmentChargeSelected && this.setNewShipmentCharge({});
        self.isNewRouteActionSelected && this.setNewRouteAction({});
        self.isNewShipmentStatusSelected && this.setNewShipmentStatus({});
        self.isNewShipmentDocumentTypeSelected &&
          this.setNewShipmentDocumentType({});
      },
      setNewShipmentSpecificModalOpen(status) {
        if (!status) {
          this.clearNewShipmentSpecifics();
        }
        self.newShipmentSpecificModalOpen = status;
      },
      setEditedShipmentSpecificType(value) {
        self.editedShipmentSpecificType = value;
      },
      setSelectedShipmentSpecificType(value) {
        self.selectedShipmentSpecificType = value;
        this.getShipmentSpecifics();
      },
      setAvailableShipmentSpecificTypes(items) {
        self.availableShipmentSpecificTypes = items;
      },
      setCurrencyTypes(items) {
        self.currencyTypes = items;
      },
      getShipmentSpecifics() {
        setFetchingInProgress(true);
        getAvailableShipmentSpecificTypes()
          .then((response) => {
            this.setAvailableShipmentSpecificTypes(response.data);
            let promises = [];
            promises.push(shipmentSpecifics(self));
            promises.push(currencyTypes(self));
            allSettled(promises).then((res) => {
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_SHIPMENT_SPECIFICS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      getShipmentChargesColumns() {
        return Object.values(shipmentChargesColumnsFunc(getRoot(self)));
      },
      setShipmentCharges(items) {
        this.clearShipmentCharges();
        items.forEach((item) => {
          self.shipmentCharges.put({
            ...item,
          });
        });
      },
      clearShipmentCharges() {
        self.shipmentCharges.clear();
      },
      getRouteActionsColumns() {
        return Object.values(routeActionsColumnsFunc(getRoot(self)));
      },
      setRouteActions(items) {
        this.clearRouteActions();
        items.forEach((item) => {
          self.routeActions.put({
            ...item,
          });
        });
      },
      clearRouteActions() {
        self.routeActions.clear();
      },
      getShipmentDocumentTypesColumns() {
        return Object.values(shipmentDocumentTypesColumnsFunc(getRoot(self)));
      },
      setShipmentDocumentTypes(items) {
        this.clearShipmentDocumentTypes();
        items.forEach((item) => {
          self.shipmentDocumentTypes.put({
            ...item,
          });
        });
      },
      clearShipmentDocumentTypes() {
        self.shipmentDocumentTypes.clear();
      },
      setShipmentStatuses(items) {
        this.clearShipmentStatuses();
        items.forEach((item) => {
          self.shipmentStatuses.put({
            ...item,
          });
        });
      },
      getShipmentStatusesColumns() {
        return Object.values(shipmentStatusColumnsFunc(getRoot(self)));
      },
      clearShipmentStatuses() {
        self.shipmentStatuses.clear();
      },
      deleteShipmentStatus(id) {
        setFetchingInProgress(true);
        deleteShipmentStatus(id)
          .then((response) => {
            this.setShipmentStatusToBeDeleted(null);
            this.setDeleteShipmentStatusSuccessModalOpen(true);
            this.setSelectedShipmentSpecificType(SHIPMENT_STATUS);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_SHIPMENT_SPECIFICS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      deleteShipmentCharge(id) {
        setFetchingInProgress(true);
        deleteShipmentCharge(id)
          .then((response) => {
            this.setShipmentChargeToBeDeleted(null);
            this.setDeleteShipmentChargeSuccessModalOpen(true);
            this.setSelectedShipmentSpecificType(SHIPMENT_CHARGE);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_SHIPMENT_SPECIFICS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      deleteRouteAction(id) {
        setFetchingInProgress(true);
        deleteShipmentRouteAction(id)
          .then((response) => {
            this.setRouteActionToBeDeleted(null);
            this.setDeleteRouteActionSuccessModalOpen(true);
            this.setSelectedShipmentSpecificType(ROUTE_ACTION);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_SHIPMENT_SPECIFICS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      deleteDocumentType(id) {
        setFetchingInProgress(true);
        deleteShipmentDocumentType(id)
          .then((response) => {
            this.setDocumentTypeToBeDeleted(null);
            this.setDeleteDocumentTypeSuccessModalOpen(true);
            this.setSelectedShipmentSpecificType(SHIPMENT_DOCUMENT_TYPE);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_SHIPMENT_SPECIFICS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
    };
  });
