import React from 'react';
import { observer } from 'mobx-react';

const FormPageTitle = (props) => {
  const image = props.image;
  const title = props.title;
  return (
    <div className="align-center form-page-title-wrapper">
      <img src={image} alt="employees" />
      <p className="form-page-title">{title}</p>
    </div>
  );
};

export default observer(FormPageTitle);
