import React from 'react';
import { observer } from 'mobx-react';
import {
  BILL_TO,
  BROKER_NAME,
  BROKER_PHONE_NUMBER,
  CURRENT_LOCATION,
  CUSTOMER,
  SHIPMENT_DETAILS,
  PO_NUMBER,
  TRAILER_TYPE,
} from '../../../constants';
import { useMst } from '../../../state/RootModel';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';

const ShipmentDetails = () => {
  const {
    shipmentsPageState: { viewedShipment },
  } = useMst();

  const defaultCenter = [39.742043, -104.991531];

  return (
    <div className="page-section">
      <div className="section-content flex-alignitems-flex-start justify-between">
        <div className="w40">
          <div className="subsection-header align-center">
            <p>{SHIPMENT_DETAILS}</p>
          </div>
          <div className="flex-alignitems-flex-start">
            <div className="w40">
              <div className="shipment-detail mt20">
                <p>{CUSTOMER}</p>
                <p className="text-medium">
                  {viewedShipment.shipment_by?.company}
                </p>
              </div>
              <div className="shipment-detail mt20">
                <p>{PO_NUMBER}</p>
                <p className="text-medium">{viewedShipment.pio_number}</p>
              </div>
              <div className="shipment-detail mt20">
                <p>{BROKER_PHONE_NUMBER}</p>
                <p className="text-medium">{viewedShipment.broker_phone}</p>
              </div>
            </div>
            <div className="w40 ml5pc">
              <div className="shipment-detail mt20">
                <p>{BILL_TO}</p>
                <p className="text-medium">{viewedShipment.bill_to?.company}</p>
              </div>
              <div className="shipment-detail mt20">
                <p>{BROKER_NAME}</p>
                <p className="text-medium">{viewedShipment.broker_name}</p>
              </div>
              <div className="shipment-detail mt20">
                <p>{TRAILER_TYPE}</p>
                <p className="text-medium">
                  {viewedShipment.trailer_type?.equipment_type}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w55">
          <div className="subsection-header align-center">
            <p>{CURRENT_LOCATION}</p>
          </div>
          <MapContainer
            center={defaultCenter}
            zoom={13}
            scrollWheelZoom={false}
            className="view-shipment-map w100 mt20"
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={defaultCenter} />
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default observer(ShipmentDetails);
