import React from 'react';
import { observer } from 'mobx-react';
import {
  ADDRESS,
  CITY,
  COUNTRY,
  LOCATION_DETAILS,
  STATE,
  ZIP_CODE,
} from '../../../../../constants';
import { useMst } from '../../../../../state/RootModel';

const LocationDetails = () => {
  const {
    carriersPageState: { viewedCarrier },
  } = useMst();

  return (
    <div className="w45 ml5pc">
      <div className="subsection-header align-center">
        <p>{LOCATION_DETAILS}</p>
      </div>
      <div className="flex-alignitems-flex-start">
        <div className="w20">
          <div className="shipment-detail mt20">
            <p>{ZIP_CODE}</p>
            <p className="text-medium">{viewedCarrier.postal_code}</p>
          </div>
          <div className="shipment-detail mt20">
            <p>{ADDRESS}</p>
            <p className="text-medium">{viewedCarrier.address}</p>
          </div>
        </div>
        <div className="w20 ml5pc">
          <div className="shipment-detail mt20">
            <p>{COUNTRY}</p>
            <p className="text-medium">{viewedCarrier.country}</p>
          </div>
        </div>
        <div className="w20 ml5pc">
          <div className="shipment-detail mt20">
            <p>{STATE}</p>
            <p className="text-medium">{viewedCarrier.state}</p>
          </div>
        </div>
        <div className="w20 ml5pc">
          <div className="shipment-detail mt20">
            <p>{CITY}</p>
            <p className="text-medium">{viewedCarrier.city}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(LocationDetails);
