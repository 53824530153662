import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Modal, Select, Upload } from 'antd';
import { useMst } from '../../../state/RootModel';
import addDocumentIcon from '../../../assets/images/menu/brokerage-active.svg';
import FormPageTitle from '../../FormPageTitle';
import {
  ADD,
  ADD_DOCUMENT,
  CANCEL,
  TYPE,
  UPLOAD_FILE,
} from '../../../constants';
import fileUpload from '../../../assets/images/file-upload.svg';

const { Option } = Select;

const AddDocumentModal = () => {
  const {
    shipmentsPageState: { currentShipment },
  } = useMst();

  const onAdd = () => {
    const uploadedFileType = currentShipment.availableDocumentTypes.find(
      (type) => {
        return type.document_type_id === fileType;
      }
    );
    currentShipment.uploadDocument(uploadedFileType, uploadedFile[0]);
  };

  const onCancel = () => {
    currentShipment.setAddDocumentModalOpen(false);
  };

  const [fileType, setFileType] = useState();
  const onTypeSelect = (value) => {
    setFileType(value);
  };

  const [uploadedFile, setUploadedFile] = useState([]);
  const handleFileUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      if (file.status !== 'uploading') {
        setUploadedFile(listToUpload);
      }
    } else {
      setUploadedFile(fileList);
    }
  };

  return (
    <div>
      <Modal
        visible={currentShipment?.addDocumentModalOpen}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={400}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addDocumentIcon} title={ADD_DOCUMENT} />
          </div>
          <div className="input-wrapper w100 mt20">
            <div className="input-label justify-between">
              <span>{TYPE}</span>
              <span className="color-secondary">*</span>
            </div>
            <Select
              className="text-capitalize"
              style={{ width: '100%' }}
              onChange={onTypeSelect}
              value={fileType}
            >
              {currentShipment.availableDocumentTypes.map((type) => (
                <Option
                  key={type.document_type_id}
                  value={type.document_type_id}
                  className="text-capitalize"
                >
                  {type.document_type_name}
                </Option>
              ))}
            </Select>
          </div>

          {fileType && (
            <div className="input-wrapper mt30">
              <div className="input-label">
                <Upload
                  fileList={uploadedFile}
                  onChange={handleFileUpload}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <div className="align-center justify-between pointer">
                    <span className="color-primary">{UPLOAD_FILE}</span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>
          )}

          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onAdd}>
              {ADD}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddDocumentModal);
