import React from 'react';
import { observer } from 'mobx-react';
import ViewEmployeeComponent from '../../components/employees/ViewEmployee/ViewEmployeeComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import { VIEW_EMPLOYEE } from '../../constants';
import ViewAbsenceModal from '../../components/employees/ViewEmployee/ViewAbsenceModal';

const ViewEmployeePage = () => {
  return (
    <div>
      <div className="page employees-page">
        <BreadcrumsComponent
          breadcrumbsBackLink={PageRoutes.Employees.name}
          page={VIEW_EMPLOYEE}
        />
        <div className="page-content">
          <ViewEmployeeComponent />
          <ViewAbsenceModal />
        </div>
      </div>
    </div>
  );
};

export default observer(ViewEmployeePage);
