import React from 'react';
import { observer } from 'mobx-react';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import { VIEW_TRUCK } from '../../constants';
import ViewTruckComponent from '../../components/fleet/ViewTruck/ViewTruckComponent';

const ViewTruckPage = () => {
  return (
    <div>
      <div className="page fleet-page">
        <BreadcrumsComponent
          breadcrumbsBackLink={PageRoutes.Fleet.name}
          page={VIEW_TRUCK}
        />
        <div className="page-content">
          <ViewTruckComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(ViewTruckPage);
