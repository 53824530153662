import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal, DatePicker, Select, Upload } from 'antd';
import {
  SAVE_CHANGES,
  DATE,
  ADD_ABSENCE_TEXT,
  ABSENCE_TYPE,
  NO_ABSENCE_TYPE_SELECTED,
  ABSENCE_DOCUMENT,
} from '../../../constants';
import calendar from '../../../assets/images/calendar-edit.svg';
import moment from 'moment';
import ErrorBox from '../../ErrorBox';
import fileUpload from '../../../assets/images/file-upload.svg';

const { Option } = Select;
const { RangePicker } = DatePicker;

const AddAbsenceModal = () => {
  const {
    employeesPageState: { editedEmployee, availableAbsencesTypes },
  } = useMst();

  const [document, setDocument] = useState([]);
  const handleDocumentUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      if (file.status !== 'uploading') {
        setDocument(listToUpload);
      }
    } else {
      setDocument(fileList);
    }
  };

  const onCancel = () => {
    editedEmployee.setNewAbsence(null);
  };

  const onConfirm = () => {
    editedEmployee.newAbsence.saveAbsence(...document);
  };

  const onSelectAbsenceType = (type) => {
    editedEmployee.newAbsence.setStatus(type);
  };

  const onDatesChange = (dates) => {
    const starting_date = moment(dates[0]).format('YYYY-MM-DD');
    const end_date = moment(dates[1]).format('YYYY-MM-DD');
    editedEmployee.newAbsence.setStartingDate(starting_date);
    editedEmployee.newAbsence.setEndDate(end_date);
  };

  const onDateChange = (date) => {
    const formatted_date = moment(date).format('YYYY-MM-DD');
    editedEmployee.newAbsence.setStartingDate(formatted_date);
    editedEmployee.newAbsence.setEndDate(formatted_date);
  };

  return (
    !!editedEmployee.newAbsence && (
      <div>
        <Modal
          visible={!!editedEmployee.newAbsence}
          footer={null}
          centered={true}
          onCancel={onCancel}
          width={400}
          className="confirm-delete-modal"
          maskClosable={false}
        >
          <div className="p32">
            <div className="align-center mb20">
              <img src={calendar} alt="calendar" className="mr10" />
              <h3>{ADD_ABSENCE_TEXT}</h3>
            </div>
            <div className="input-wrapper mb20">
              <div className="input-label justify-between">
                <span>{ABSENCE_TYPE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className="w100 text-capitalize"
                onChange={onSelectAbsenceType}
                value={
                  editedEmployee.newAbsence?.status &&
                  editedEmployee.newAbsence.status
                }
                placeholder={
                  !editedEmployee.newAbsence?.status && NO_ABSENCE_TYPE_SELECTED
                }
              >
                {availableAbsencesTypes.map((type, index) => (
                  <Option key={index} value={type} className="text-capitalize">
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
            {editedEmployee.newAbsence?.status && (
              <div>
                {editedEmployee.newAbsence.shouldDisplayDatePicker ? (
                  <div className="mt20">
                    <p>{DATE}</p>
                    <DatePicker
                      value={
                        editedEmployee.newAbsence?.starting_date
                          ? moment(editedEmployee.newAbsence.starting_date)
                          : null
                      }
                      onChange={onDateChange}
                    />
                  </div>
                ) : (
                  <div>
                    <p>{DATE}</p>
                    <RangePicker
                      value={[
                        editedEmployee.newAbsence?.starting_date
                          ? moment(editedEmployee.newAbsence.starting_date)
                          : null,
                        editedEmployee.newAbsence?.end_date
                          ? moment(editedEmployee.newAbsence.end_date)
                          : null,
                      ]}
                      onChange={onDatesChange}
                    />
                  </div>
                )}
                <div className="input-wrapper mt20">
                  <div className="input-label">
                    <Upload
                      fileList={document}
                      onChange={handleDocumentUpload}
                      beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    >
                      <div className="align-center justify-between pointer">
                        <span className="color-primary mr5">
                          {ABSENCE_DOCUMENT}
                        </span>
                        <img src={fileUpload} alt="file upload" />
                      </div>
                    </Upload>
                  </div>
                </div>
              </div>
            )}
            <ErrorBox obj={editedEmployee.newAbsence} />
            <div>
              <button className="btn primary mt20" onClick={onConfirm}>
                {SAVE_CHANGES}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(AddAbsenceModal);
