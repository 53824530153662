import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import EmployeesTableHeader from './EmployeesTableHeader';
import DepartmentList from './DepartmentList';
import EmployeesTable from './EmployeesTable';

const DepartmentsList = () => {
  const {
    employeesPageState: {
      departmentsArray,
      filteredEmployeesWithNoDepartmentArray,
    },
  } = useMst();

  return (
    <div>
      <EmployeesTableHeader />
      {departmentsArray.map((department) => {
        return (
          <DepartmentList
            key={department.department_id}
            department={department}
          />
        );
      })}
      {filteredEmployeesWithNoDepartmentArray.length ? (
        <EmployeesTable />
      ) : null}
    </div>
  );
};

export default observer(DepartmentsList);
