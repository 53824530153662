import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal } from 'antd';
import { CANCEL } from '../../../constants';
import addOrganization from '../../../assets/images/add-organization.svg';
import {
  COMPENSATION_DEDUCTION_DELETE,
  CONFIRM_DELETING_DEDUCTION,
} from '../../../constants/payroll';

const DeleteDeductionModal = () => {
  const {
    payrollPageState: {
      isDeleteDeductionModalOpen,
      setIsDeleteDeductionModalOpen,
      deletingDeduction,
      selectedCompensationStructure,
    },
  } = useMst();

  const onCancel = () => {
    setIsDeleteDeductionModalOpen(false);
  };

  const onDeductionDelete = () => {
    deletingDeduction.deleteCompensationDeduction(
      selectedCompensationStructure.compensation_id,
      deletingDeduction.compensation_deduction_id
    );
  };

  return (
    <div>
      <Modal
        visible={!!isDeleteDeductionModalOpen}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="p20">
          <div className="align-center mb20">
            <img src={addOrganization} alt="save" className="mr10" />
            <h3>{COMPENSATION_DEDUCTION_DELETE}</h3>
          </div>
          <p>{CONFIRM_DELETING_DEDUCTION}</p>
          <h3>{deletingDeduction?.deduction_type}</h3>

          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onDeductionDelete}>
              {COMPENSATION_DEDUCTION_DELETE}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(DeleteDeductionModal);
