import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal, DatePicker, Select, Upload } from 'antd';
import {
  SAVE_CHANGES,
  DATE,
  ABSENCE_TYPE,
  NO_ABSENCE_TYPE_SELECTED,
  EDIT_ABSENCE_TEXT,
  DELETE_ABSENCE_TEXT,
  ABSENCE_DOCUMENT,
} from '../../../constants';
import calendar from '../../../assets/images/calendar-edit.svg';
import moment from 'moment';
import ErrorBox from '../../ErrorBox';
import FormButtonsSection from '../../FormButtonsSection';
import fileUpload from '../../../assets/images/file-upload.svg';
import { getRandomInt } from '../../../utils/methods';
const isEqual = require('lodash.isequal');

const { Option } = Select;
const { RangePicker } = DatePicker;

const EditAbsenceModal = () => {
  const {
    employeesPageState: { editedEmployee, availableAbsencesTypes },
  } = useMst();

  // added to reset state on discard changes
  const [key, setKey] = useState(getRandomInt());

  const [document, setDocument] = useState([]);
  useEffect(() => {
    setDocument(
      editedEmployee.editedAbsence?.document
        ? [editedEmployee.editedAbsence.document]
        : []
    );
  }, [editedEmployee.editedAbsence?.document, key]);
  const handleDocumentUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      if (file.status !== 'uploading') {
        setDocument(listToUpload);
      }
    } else {
      setDocument(fileList);
    }
  };

  const onSelectAbsenceType = (type) => {
    editedEmployee.editedAbsence.setStatus(type);
  };

  const onDatesChange = (dates) => {
    const starting_date = moment(dates[0]).format('YYYY-MM-DD');
    const end_date = moment(dates[1]).format('YYYY-MM-DD');
    editedEmployee.editedAbsence.setStartingDate(starting_date);
    editedEmployee.editedAbsence.setEndDate(end_date);
  };

  const onDateChange = (date) => {
    const formatted_date = moment(date).format('YYYY-MM-DD');
    editedEmployee.editedAbsence.setStartingDate(formatted_date);
    editedEmployee.editedAbsence.setEndDate(formatted_date);
  };

  const getDocumentForUpload = () => {
    if (document[0] && !editedEmployee.editedAbsence.document) {
      return document[0];
    } else if (
      document[0] &&
      editedEmployee.editedAbsence.document &&
      !isEqual(editedEmployee.editedAbsence.document, document[0])
    ) {
      return document[0];
    }
    return false;
  };

  const getDocumentForDeletion = () => {
    if (
      editedEmployee.editedAbsence.document &&
      !isEqual(editedEmployee.editedAbsence.document, document[0])
    ) {
      return editedEmployee.editedAbsence.document;
    }
    return false;
  };

  const onCancel = () => {
    setKey(getRandomInt());
    editedEmployee.setEditedAbsence(null);
  };

  const onConfirm = () => {
    const documentToUpload = getDocumentForUpload();
    const documentToDelete = getDocumentForDeletion();
    editedEmployee.editedAbsence.saveAbsence(
      documentToUpload,
      documentToDelete
    );
  };

  const onDelete = () => {
    editedEmployee.editedAbsence.setIsConfirmDeleteModalOpen(true);
  };

  return (
    editedEmployee?.editedAbsence && (
      <div>
        <Modal
          visible={!!editedEmployee.editedAbsence}
          footer={null}
          centered={true}
          onCancel={onCancel}
          width={500}
          className="confirm-delete-modal"
          maskClosable={false}
        >
          <div className="p32">
            <div className="align-center mb20">
              <img src={calendar} alt="calendar" className="mr10" />
              <h3>{EDIT_ABSENCE_TEXT}</h3>
            </div>
            <div className="input-wrapper mb20">
              <div className="input-label justify-between">
                <span>{ABSENCE_TYPE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className="w100 text-capitalize"
                onChange={onSelectAbsenceType}
                value={
                  editedEmployee.editedAbsence?.status &&
                  editedEmployee.editedAbsence.status
                }
                placeholder={
                  !editedEmployee.editedAbsence?.status &&
                  NO_ABSENCE_TYPE_SELECTED
                }
              >
                {availableAbsencesTypes.map((type, index) => (
                  <Option key={index} value={type} className="text-capitalize">
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
            {editedEmployee.editedAbsence?.status && (
              <div>
                {editedEmployee.editedAbsence.shouldDisplayDatePicker ? (
                  <div className="mt20">
                    <p>{DATE}</p>
                    <DatePicker
                      value={
                        editedEmployee.editedAbsence?.starting_date
                          ? moment(editedEmployee.editedAbsence.starting_date)
                          : null
                      }
                      onChange={onDateChange}
                    />
                  </div>
                ) : (
                  <div>
                    <p>{DATE}</p>
                    <RangePicker
                      value={[
                        editedEmployee.editedAbsence?.starting_date
                          ? moment(editedEmployee.editedAbsence.starting_date)
                          : null,
                        editedEmployee.editedAbsence?.end_date
                          ? moment(editedEmployee.editedAbsence.end_date)
                          : null,
                      ]}
                      onChange={onDatesChange}
                    />
                  </div>
                )}
                <div className="input-wrapper mt20">
                  <div className="input-label">
                    <Upload
                      fileList={document}
                      onChange={handleDocumentUpload}
                      beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                    >
                      <div className="align-center justify-between pointer">
                        <span className="color-primary mr5">
                          {ABSENCE_DOCUMENT}
                        </span>
                        <img src={fileUpload} alt="file upload" />
                      </div>
                    </Upload>
                  </div>
                </div>
              </div>
            )}
            <ErrorBox obj={editedEmployee?.editedAbsence} />

            <FormButtonsSection
              onConfirm={onConfirm}
              onDiscard={onDelete}
              confirmTitle={SAVE_CHANGES}
              discardTitle={DELETE_ABSENCE_TEXT}
            />
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(EditAbsenceModal);
