import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';

import { DatePicker, Table, Card } from 'antd';

import { useMst } from '../../../state/RootModel';

const { RangePicker } = DatePicker;

const totalItems = [
  'sum_of_empty_miles',
  'sum_of_loaded_miles',
  'sum_of_total_miles',
  'sum_of_total_charge',
  'sum_of_total_cost',
  'average_of_ravenue_per_mile',
  'average_of_cost_per_mile',
  'average_of_profit_per_mile',
  'average_of_driver_per_mile',
];

const Reports = () => {
  const {
    reportsPageState: {
      reportsDateRange,
      getReports,
      reportsTableColumns,
      reports,
      // TODO: Check how to display averages
      averagesOfReports,
    },
  } = useMst();

  const onChange = (_, dates) => {
    getReports(dates);
  };

  return (
    <div>
      <div className="range-picker">
        <RangePicker
          onChange={onChange}
          value={[
            !!reportsDateRange?.[0] ? moment(reportsDateRange?.[0]) : null,
            !!reportsDateRange?.[1] ? moment(reportsDateRange?.[1]) : null,
          ]}
        />
      </div>

      <Table
        rowKey="shipment_number"
        columns={reportsTableColumns}
        dataSource={reports}
        className="mt10 mb30"
        scroll={{ y: 440 }}
        pagination={{ pageSize: 50 }}
      />

      <h3 className="mt20 mb20">Grand total</h3>

      <div className="totals">
        <div className="totals__items mt20">
          {!!reports.length && (
            <>
              {totalItems.map((item) => (
                <Card key={item} className="card card--rounded">
                  <p className="card-title">
                    {
                      reportsTableColumns.find((col) => col.dataIndex === item)
                        ?.title
                    }
                  </p>
                  <p className="count">{averagesOfReports[item]}</p>
                </Card>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(Reports);
