import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import AsyncSelect from 'react-select/async';
import { Input } from 'antd';
import {
  SHIPMENT_DETAILS,
  START_TYPING_PLACEHOLDER_TEXT,
  CUSTOMER,
  BILL_TO,
  PO_NUMBER,
  BROKER_NAME,
  PHONE_NUMBER,
} from '../../../constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useMst } from '../../../state/RootModel';
import ClientDetails from '../ClientDetails';
import { getClient } from '../../../actions/clients';

const debounce = require('lodash.debounce');

const ShipmentDetails = () => {
  const {
    shipmentsPageState: { editedShipment },
    clientsPageState: { getFilteredClients, filteredClients },
  } = useMst();

  const billTo = {
    ...editedShipment.bill_to,
    value: editedShipment?.bill_to?.client_id?.toString(),
    label: editedShipment?.bill_to?.company,
  };
  const customer = {
    ...editedShipment.shipment_by,
    value: editedShipment?.shipment_by?.client_id?.toString(),
    label: editedShipment?.shipment_by?.company,
  };

  const [selectedCustomer, setSelectedCustomer] = useState(customer);
  const [selectedBillTo, setSelectedBillTo] = useState(billTo);

  const loadSuggestions = debounce(getFilteredClients, 300);

  const onSelectCustomer = (client) => {
    editedShipment.setShipmentBy(client.client_id);
    const customer = filteredClients.find(
      (el) => el.client_id === client.client_id
    );
    setSelectedCustomer(customer);
    setSelectedBillTo(customer);
    editedShipment.setBillTo(client.client_id);
  };

  const onSelectBillTo = (client) => {
    editedShipment.setBillTo(client.client_id);
    const customer = filteredClients.find(
      (el) => el.client_id === client.client_id
    );
    setSelectedBillTo(customer);
  };

  useEffect(() => {
    getClient(editedShipment?.shipment_by?.client_id)
      .then((response) => {
        const data = {
          ...response.data,
          value: response.data?.client_id?.toString(),
          label: response.data?.company,
        };
        setSelectedCustomer(data);
      })
      .catch((error) => {});
  }, [setSelectedCustomer, editedShipment?.shipment_by?.client_id]);

  useEffect(() => {
    getClient(editedShipment?.bill_to?.client_id)
      .then((response) => {
        const data = {
          ...response.data,
          value: response.data?.client_id?.toString(),
          label: response.data?.company,
        };
        setSelectedBillTo(data);
      })
      .catch((error) => {});
  }, [setSelectedBillTo, editedShipment?.bill_to?.client_id]);

  // const onBrokerageShipmentChange = (e) => {
  //   editedShipment.setIsBrokerageShipment(e.target.checked);
  // };
  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header align-center">
        <p>{SHIPMENT_DETAILS}</p>
        {/* <Checkbox
          onChange={onBrokerageShipmentChange}
          className="ml20"
          checked={editedShipment.is_brokerage_shipment}
        >
          {BROKERAGE_SHIPMENT}
        </Checkbox> */}
      </div>
      <div className="subsection-content flex-alignitems-flex-start">
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CUSTOMER}</span>
            <span className="color-secondary">*</span>
          </div>
          <AsyncSelect
            loadOptions={loadSuggestions}
            onChange={onSelectCustomer}
            placeholder={START_TYPING_PLACEHOLDER_TEXT}
            value={selectedCustomer ? selectedCustomer : undefined}
          />
          {selectedCustomer && <ClientDetails client={selectedCustomer} />}
          {/*<ClientDetails client={selectedCustomer} />*/}
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{BILL_TO}</span>
            <span className="color-secondary">*</span>
          </div>
          <AsyncSelect
            loadOptions={loadSuggestions}
            onChange={onSelectBillTo}
            placeholder={START_TYPING_PLACEHOLDER_TEXT}
            value={selectedBillTo ? selectedBillTo : undefined}
          />
          {!!selectedBillTo && <ClientDetails client={selectedBillTo} />}
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{PO_NUMBER}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedShipment.setPioNumber(e.target.value)}
            value={editedShipment.pio_number}
          />
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{BROKER_NAME}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => editedShipment.setBrokerName(e.target.value)}
            value={editedShipment.broker_name}
          />
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{PHONE_NUMBER}</span>
          </div>
          <PhoneInput
            placeholder="e.g. 712338645"
            value={editedShipment.broker_phone}
            onChange={(value) => editedShipment.setBrokerPhone(value)}
            maxLength={15}
            displayInitialValueAsLocalNumber
            className="phone-input"
          />
        </div>
      </div>
    </div>
  );
};

export default observer(ShipmentDetails);
