import React from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';
import trackShipment from '../../../assets/images/dispatch/track-shipment.svg';
import organizeTour from '../../../assets/images/dispatch/bx_bx-trip.svg';
import editPD from '../../../assets/images/edit-equipment.svg';
import {
  ADD_ACTION,
  EDIT_P_AND_D,
  LOCAL_DELIVERY,
  LOCAL_PICKUP,
  ORGANIZE_TOUR,
  TRACK_SHIPMENT,
} from '../../../constants/dispatch';
import { NOT_AVAILABLE } from '../../../constants';

const ShipmentListPopup = ({ record, visible, x, y }) => {
  const {
    dispatchPageState: {
      addNewRouteActionItem,
      setPlannerOpened,
      setShipmentToChangePD,
    },
  } = useMst();

  const onOrganizeTour = (e, record) => {
    e.preventDefault();
    rootInstance.dispatchPageState.removeEditingRouteItem();
    rootInstance.dispatchPageState.beforeEnterSetSelectedShipment(
      record.shipment_id
    );
    rootInstance.dispatchPageState.loadRouteActionTrailers();
    rootInstance.dispatchPageState.loadRouteActions();
    let shipment_data = {
      action_type: record.action_type[0],
      location_name: false,
      shipment_id: record.shipment_id,
      location: false,
    };
    if (record.delivery_location?.location_name !== NOT_AVAILABLE) {
      shipment_data.location = record.delivery_location;
    }

    addNewRouteActionItem(shipment_data);
    setTimeout(() => {
      setPlannerOpened(false);
    }, 200);
  };

  const onEditPD = (e, record) => {
    setShipmentToChangePD(record);
  };

  // const onViewAllShipments = (e, record) => {
  //   rootInstance.dispatchPageState.onViewShipmentActions(record.shipment_id);
  // };
  const onTrackShipment = (e, record) => {
    rootInstance.dispatchPageState.removeEditingRouteItem();
    rootInstance.dispatchPageState.loadSelectedShipmentData(record.shipment_id);
  };
  const allowedForEditPD = [LOCAL_DELIVERY, LOCAL_PICKUP];
  return (
    visible && (
      <ul
        className="planner-list__popup"
        style={{ left: `${x - 50}px`, top: `${y - 100}px` }}
      >
        <li onClick={(e) => onOrganizeTour(e, record)}>
          <img
            style={{ marginRight: 8 }}
            src={organizeTour}
            alt={ORGANIZE_TOUR}
          />
          {ADD_ACTION}
        </li>
        {record?.has_route_actions && (
          <li onClick={(e) => onTrackShipment(e, record)}>
            <img
              style={{ marginRight: 8, width: 14, height: 14 }}
              src={trackShipment}
              alt={TRACK_SHIPMENT}
            />
            {TRACK_SHIPMENT}
          </li>
        )}
        {/*{record?.has_route_actions && (*/}
        {/*  <li onClick={(e) => onViewAllShipments(e, record)}>*/}
        {/*    <img*/}
        {/*      style={{ marginRight: 8 }}*/}
        {/*      src={viewShipments}*/}
        {/*      alt={ORGANIZE_TOUR}*/}
        {/*    />*/}
        {/*    {VIEW_ALL_ACTIONS}*/}
        {/*  </li>*/}
        {/*)}*/}

        {record.action_type.some((ai) => allowedForEditPD.includes(ai)) && (
          <li onClick={(e) => onEditPD(e, record)}>
            <img style={{ marginRight: 8 }} src={editPD} alt={EDIT_P_AND_D} />
            {EDIT_P_AND_D}
          </li>
        )}
      </ul>
    )
  );
};

export default observer(ShipmentListPopup);
