import React from 'react';
import { rootInstance } from '../../RootModel';

import selectIcon from '../../../assets/images/select-icon.svg';
import {
  ID,
  AVAILABLE_FROM,
  AVAILABLE_AT,
  LINKED_DRIVER,
  CURRENT_ACTION,
} from '../../../constants/dispatch';
import {
  CURRENT_LOCATION,
  NOT_AVAILABLE_SHORT,
  STATUS,
  TRUCK_MAKE,
} from '../../../constants';
import { replaceUnderscore } from '../../../utils/methods';
import moment from 'moment';
import { Tooltip } from 'antd';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';

const setSelectedTruck = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsOrganizeTourTrucksModalOpen(false);
  rootInstance.dispatchPageState?.routeActionItem?.changeSelectedTruck(
    record.vehicle_id
  );
};

export const DispatchAvailableTrucksTableColumns = (rootInstance) => ({
  '1': {
    title: ID,
    dataIndex: 'internal_company_id',
    key: 'internal_company_id',
    width: 70,
    render: (text, record) => {
      return <span>{record?.internal_company_id}</span>;
    },
  },
  '2': {
    title: TRUCK_MAKE,
    dataIndex: 'vehicle_make',
    key: 'vehicle_make',
    render: (text, record) => {
      let type =
        record?.ownership_type === 'Company owned'
          ? 'CO'
          : record.ownership_type === OWNER_OPERATOR
          ? 'OO'
          : record.ownership_type === 'rented'
          ? 'R'
          : NOT_AVAILABLE_SHORT;
      return (
        <div>
          <p>
            {text !== '' ? text : NOT_AVAILABLE_SHORT}
            <span className="ml10">
              <strong className="text-bold text-blue">
                ({record?.ownership_type ? type : NOT_AVAILABLE_SHORT})
              </strong>
            </span>
          </p>
        </div>
      );
    },
  },
  '3': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (text) => {
      return (
        <span
          className={`text-capitalize text-bold ${
            text === 'Available' ? 'text-green' : 'text-dkred'
          }`}
        >
          {replaceUnderscore(text)}
        </span>
      );
    },
  },
  '4': {
    title: LINKED_DRIVER,
    dataIndex: 'linked_driver',
    key: 'linked_driver',
    render: (text, record) => {
      let driver = '';
      driver += record?.linked_driver?.first_name
        ? record?.linked_driver.first_name
        : '';
      driver += record?.linked_driver?.last_name
        ? ' ' + record?.linked_driver?.last_name
        : '';

      let type =
        record?.linked_driver?.driver_type === COMPANY_DRIVER
          ? 'CD'
          : record?.linked_driver?.driver_type === OWNER_OPERATOR
          ? 'OO'
          : NOT_AVAILABLE_SHORT;
      return (
        <div>
          <p>
            {driver !== '' ? driver : NOT_AVAILABLE_SHORT}
            <span className="ml10">
              <strong className="text-bold text-blue">
                (
                {record?.linked_driver?.driver_type
                  ? type
                  : NOT_AVAILABLE_SHORT}
                )
              </strong>
            </span>
          </p>
        </div>
      );
    },
  },
  '5': {
    title: CURRENT_ACTION,
    dataIndex: 'current_action',
    key: 'current_action',
    render: (text, record) => {
      return record?.current_action ? (
        <span>{record?.current_action?.name}</span>
      ) : (
        NOT_AVAILABLE_SHORT
      );
    },
  },
  '6': {
    title: CURRENT_LOCATION,
    dataIndex: 'location_description',
    key: 'location_description',
    render: (text) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '7': {
    title: AVAILABLE_FROM,
    dataIndex: 'available_from',
    key: 'available_from',
    render: (text) => {
      return (
        <span>
          {text ? moment(text).format('DD/MM/YYYY HH:MM') : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '8': {
    title: AVAILABLE_AT,
    dataIndex: 'available_at',
    key: 'available_at',
    render: (text, record) => {
      return (
        <p>
          <span className="text-bold text-black">
            {record?.location_name
              ? record?.location_name
              : NOT_AVAILABLE_SHORT}
          </span>
          <br />
          <span>{record?.address ? record?.address : NOT_AVAILABLE_SHORT}</span>
        </p>
      );
    },
  },
  '9': {
    title: '',
    dataIndex: 'action',
    width: 50,
    render: (text, record) => {
      return (
        <div className="align-center">
          <Tooltip title="Select truck">
            <img
              src={selectIcon}
              className="cursor-pointer"
              alt="select truck"
              onClick={(e) => setSelectedTruck(e, record)}
            />
          </Tooltip>
        </div>
      );
    },
  },
});
