import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import { GENERAL_INFORMATION } from '../../../../../constants';
import dropdownIcon from '../../../../../assets/images/dropdown-icon.svg';
import CarrierDetails from './CarrierDetails';
import LocationDetails from './LocationDetails';
import EquipmentDetails from './EquipmentDetails';

const GeneralInformation = () => {
  const {
    carriersPageState: { viewedCarrier },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section mt20">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedCarrier.setIsGeneralInfoSectionOpen(
              !viewedCarrier.isGeneralInfoSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedCarrier.isGeneralInfoSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {GENERAL_INFORMATION}
          </span>
        </div>
      </div>
      {viewedCarrier?.isGeneralInfoSectionOpen && (
        <div>
          <div className="flex-alignitems-flex-start w100">
            <CarrierDetails />
            <LocationDetails />
          </div>
          <div className="flex-alignitems-flex-start w100">
            <EquipmentDetails />
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(GeneralInformation);
