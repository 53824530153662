import { Empty, Input, Select, Table } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ADD, ALL, DISCARD } from '../../../constants';
import {
  COMPENSATION_VALUES,
  COMPENSATION_VALUE_BASED_ON,
  COMPENSATION_VALUE_CURRENCY,
  COMPENSATION_VALUE_DEFAULT_VALUE,
  COMPENSATION_VALUE_NAME,
  COMPENSATION_VALUE_RATE,
  DEFAULT_VALUE,
  DEFAULT_VALUE_REGEX,
  EMPTY_COMPENSATION_VALUES_TABLE_TEXT,
  FIRST_COLUMN,
  LAST_COLUMN,
  VALUES_EDIT,
} from '../../../constants/payroll';
import { useMst } from '../../../state/RootModel';
import ErrorBox from '../../ErrorBox';

const { Option } = Select;
const CompensationValuesSection = () => {
  const {
    payrollPageState: {
      newCompensationStructure,
      getCompensationValuesOrDeductionsTableColumns,
      compensationValuesByType,
    },
  } = useMst();

  const onCancel = () => {
    newCompensationStructure.setNewCompensationValues([]);
  };

  return (
    <div className="add-driver-section form-page-section">
      <div className="form-section-title-row pb10 mb10">
        {COMPENSATION_VALUES}
        <span className="form-section-title"></span>
      </div>

      {!!newCompensationStructure.new_compensation_values.length && (
        <Table
          className="compensations-table"
          columns={getCompensationValuesOrDeductionsTableColumns(
            VALUES_EDIT
          ).slice(FIRST_COLUMN, LAST_COLUMN)}
          dataSource={newCompensationStructure.new_compensation_values.map(
            (el) => (el.based_on === null ? { ...el, based_on: 'N/A' } : el)
          )}
          pagination={false}
          rowKey={(record, index) => index.toString()}
          rowClassName={() => 'compensation-table-expanded-row'}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_COMPENSATION_VALUES_TABLE_TEXT}
              />
            ),
          }}
        />
      )}

      <div className="section-content flex-alignitems-flex-start pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_VALUE_NAME}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Select
            className={'w100'}
            onChange={(ct) =>
              newCompensationStructure.setNewCompensationValue(ct)
            }
            value={newCompensationStructure.new_compensation_value}
          >
            {compensationValuesByType.map((ct, index) => (
              <Option key={index} value={ct}>
                {ct}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{COMPENSATION_VALUE_BASED_ON}</span>
          </div>
          <Select
            className={'w100'}
            onChange={(ct) =>
              newCompensationStructure.setNewCompensationValueBasedOn(ct)
            }
            value={newCompensationStructure.new_compensation_based_on}
          >
            <Option key={ALL} value={null} className="text-capitalize">
              -
            </Option>
            {newCompensationStructure?.compensation_based_on_types.map(
              (ct, index) => (
                <Option key={index} value={ct}>
                  {ct}
                </Option>
              )
            )}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_VALUE_RATE}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Select
            className={'w100'}
            onChange={(ct) =>
              newCompensationStructure.setNewCompensationValueRate(ct)
            }
            value={newCompensationStructure.new_compensation_rate}
          >
            {newCompensationStructure?.compensation_payment_rates.map(
              (ct, index) => (
                <Option key={index} value={ct}>
                  {ct}
                </Option>
              )
            )}
          </Select>
        </div>
      </div>

      <div className="section-content flex-alignitems-flex-start pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{COMPENSATION_VALUE_DEFAULT_VALUE}</span>
          </div>
          <Input
            type="text"
            onFocus={() => {
              if (
                newCompensationStructure.value_default_value_temp ===
                DEFAULT_VALUE
              )
                newCompensationStructure.setValueDefaultValueTemp(null);
            }}
            onBlur={() => {
              if (!newCompensationStructure.value_default_value_temp)
                newCompensationStructure.setValueDefaultValueTemp(
                  DEFAULT_VALUE
                );
            }}
            onChange={(e) => {
              var rgx = DEFAULT_VALUE_REGEX;

              if (rgx.test(e.target.value) && e.target.value)
                newCompensationStructure.setValueDefaultValueTemp(
                  e.target.value
                );
              else {
                newCompensationStructure.setValueDefaultValueTemp(null);
              }
            }}
            className="add-edit-input"
            value={newCompensationStructure.value_default_value_temp}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>
              {COMPENSATION_VALUE_CURRENCY}

              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          <Select
            className={'w100'}
            onChange={(ct) =>
              newCompensationStructure.setNewCompensationValueCurrency(ct)
            }
            value={newCompensationStructure.new_compensation_currency}
          >
            {newCompensationStructure?.compensation_currencies.map(
              (ct, index) => (
                <Option key={index} value={ct}>
                  {ct}
                </Option>
              )
            )}
          </Select>
        </div>
      </div>

      <div className="ml10">
        <ErrorBox
          obj={{ errorText: newCompensationStructure.errorTextValue }}
        />
      </div>
      <div className="align-center justify-end">
        <button
          className="btn primary add no-wrap header-button mt10 mb20"
          onClick={() => newCompensationStructure.addCompensationValue()}
        >
          {ADD}
        </button>
        <button
          type="button"
          className="btn transparent discard text-capitalize ml10 mt10 mb20"
          onClick={onCancel}
        >
          {DISCARD}
        </button>
      </div>
    </div>
  );
};

export default observer(CompensationValuesSection);
