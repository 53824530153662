import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { useMst } from '../../../../../state/RootModel';
import { CATEGORY_TYPE } from '../../../../../constants';

const { Option } = Select;
const CarrierEquipmentDetail = () => {
  const {
    carriersPageState: {
      newCarrier: { setEquipmentItem },
      equipments,
    },
  } = useMst();

  const onEquipmentSelect = (value) => {
    const equipmentItem = equipments.find((equipment) => {
      return equipment.equipment_id === value;
    });
    setEquipmentItem(equipmentItem);
  };

  return (
    <div className="align-center mt20 w100">
      <div className="input-wrapper">
        <div className="input-label justify-between">{CATEGORY_TYPE}</div>
        <Select
          className="text-capitalize"
          style={{ width: '100%' }}
          onChange={onEquipmentSelect}
        >
          {equipments.map((item, index) => (
            <Option
              key={index}
              value={item.equipment_id}
              className="text-capitalize"
            >
              {item.equipment_category} / {item.equipment_type}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default observer(CarrierEquipmentDetail);
