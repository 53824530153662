import React from 'react';
import { observer } from 'mobx-react';
import { ADDRESS, CITY, NOT_AVAILABLE, STATE } from '../../constants';
import { MC } from '../../constants/organizations/organizations';

const ClientDetails = (props) => {
  const data = { ...props.client };
  return (
    <div className="customer-details-component w100 mt10">
      <p>
        <strong>{ADDRESS}: </strong>{' '}
        {data.address ? data.address : NOT_AVAILABLE}
      </p>
      <p>
        <strong>{CITY}: </strong> {data.city ? data.city : NOT_AVAILABLE}
      </p>
      <p>
        <strong>{STATE}: </strong> {data.state ? data.state : NOT_AVAILABLE}
      </p>
      {/*<p>*/}
      {/*  <strong>{COUNTRY}: </strong>{' '}*/}
      {/*  {data.country ? data.country : NOT_AVAILABLE}*/}
      {/*</p>*/}
      <p>
        <strong>{MC}: </strong> {data.pib ? data.pib : NOT_AVAILABLE}
      </p>
    </div>
  );
};

export default observer(ClientDetails);
