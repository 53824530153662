import { types } from 'mobx-state-tree';
import {
  addVehicleMaintenance,
  uploadMaintenanceDocument,
} from '../../actions/fleet';
import {
  DATE_REQUIRED,
  MAINTENANCE_TYPE_REQUIRED,
  NOTES_REQUIRED,
  TIME_REQUIRED,
} from '../../constants';
import { EDIT_TRUCK } from '../../constants/menu';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { rootInstance } from '../RootModel';

export const MaintenanceLightModel = types
  .model('MaintenanceLightModel', {
    maintenance_type: types.maybe(types.maybeNull(types.string)),
    date: types.maybe(types.maybeNull(types.string)),
    time: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),
    odometer: types.maybe(types.maybeNull(types.number)),
    odometer_unit: types.maybe(types.maybeNull(types.string)),
    signature: types.maybe(types.maybeNull(types.string)),
    vehicle_id: types.maybe(types.maybeNull(types.number)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get dateTime() {
        if (self.date && self.time) {
          return `${self.date}T${self.time}:00`;
        }
        return null;
      },
      get isEditTruckPage() {
        return rootInstance.router.currentView.name === EDIT_TRUCK;
      },
    };
  })
  .actions((self) => {
    return {
      setType(type) {
        self.maintenance_type = type;
      },
      setNotes(notes) {
        self.notes = notes;
      },
      setDate(date) {
        self.date = date;
      },
      setTime(time) {
        self.time = time;
      },
      setSignature(value) {
        self.signature = value;
      },
      setOdometer(odometer) {
        self.odometer = odometer;
      },
      setOdometerUnit(value) {
        self.odometer_unit = value;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwAddMaintenanceError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.maintenance_type) {
          this.setErrorText(MAINTENANCE_TYPE_REQUIRED);
        } else if (!self.date) {
          this.setErrorText(DATE_REQUIRED);
        } else if (!self.time) {
          this.setErrorText(TIME_REQUIRED);
        }
        // else if (self.isEditTruckPage && !self.odometer) {
        //     this.setErrorText(ODOMETER_REQUIRED);
        // } else if (self.isEditTruckPage && !self.odometer_unit) {
        //     this.setErrorText(ODOMETER_UNITS_REQUIRED);
        // }
        else if (!self.notes) {
          this.setErrorText(NOTES_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      redirectAfterSave() {
        if (rootInstance.fleetPageState.isEditTruckPage) {
          rootInstance.fleetPageState.getEditedTruck(self.vehicle_id);
        } else if (rootInstance.fleetPageState.isEditTrailerPage) {
          rootInstance.fleetPageState.getEditedTrailer(self.vehicle_id);
        }
      },
      saveMaintenance(document) {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addVehicleMaintenance(self)
            .then((maintenance) => {
              if (document) {
                const formData = new FormData();
                formData.append('file', document.originFileObj);
                uploadMaintenanceDocument(
                  maintenance.data.maintenance_id,
                  formData
                )
                  .then((document) => {
                    this.redirectAfterSave();
                    setFetchingInProgress(false);
                  })
                  .catch((error) => {
                    this.throwAddMaintenanceError(error);
                  });
              } else {
                this.redirectAfterSave();
                setFetchingInProgress(false);
              }
            })
            .catch((error) => {
              this.throwAddMaintenanceError(error);
            });
        }
      },
    };
  });
