import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import addOrganization from '../../../assets/images/add-organization.svg';
import { Input, Modal } from 'antd';
import ErrorBox from '../../ErrorBox';
import { CANCEL, CONFIRM } from '../../../constants';
import {
  ADD_NEW_DEPARTMENT,
  DEPARTMENT_NAME,
} from '../../../constants/organization/departments';

const AddOrganizationDepartmentModal = () => {
  const {
    currentOrganization: {
      isAddNewDepartmentModalOpened,
      setIsAddNewDepartmentModalOpened,
      newDepartment,
    },
  } = useMst();

  const onSetDepartmentName = (e) => {
    newDepartment.setDepartmentName(e.target.value);
  };

  const onCancel = () => {
    setIsAddNewDepartmentModalOpened(false);
  };
  const onConfirm = () => {
    console.log('validating backend..');
    newDepartment.saveNewDepartment();
  };
  return (
    <div>
      <Modal
        visible={!!isAddNewDepartmentModalOpened}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="modal-content p20">
          <div className="align-center mb20">
            <img src={addOrganization} alt="save" className="mr10" />
            <h3>{ADD_NEW_DEPARTMENT}</h3>
          </div>
          <div className="flex-column">
            <div className="input-wrapper w100">
              <div className="input-label justify-between">
                <span>
                  {DEPARTMENT_NAME}
                  <span className="color-secondary d-iblock ml10">*</span>
                </span>
              </div>
              <Input
                type="text"
                value={newDepartment.department_name}
                placeholder={DEPARTMENT_NAME}
                onChange={(e) => onSetDepartmentName(e)}
                className="trailer-search-input"
              />
            </div>
          </div>

          <ErrorBox obj={newDepartment} />
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(AddOrganizationDepartmentModal);
