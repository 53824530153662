import moment from 'moment';
import React from 'react';
import {
  DATE_END,
  DATE_STARTED,
  DRIVER_ID,
  DRIVER_NAME,
} from '../../../constants';

export const associationColumnsFunc = (rootInstance) => ({
  '1': {
    title: DRIVER_ID,
    dataIndex: 'driver_id',
    key: 'driver_id',
  },
  '2': {
    title: DRIVER_NAME,
    key: 'name',
    render: (text, record) => {
      return (
        <span>
          {record.first_name} {record.last_name}
        </span>
      );
    },
  },
  '3': {
    title: DATE_STARTED,
    dataIndex: 'start_driving',
    key: 'start_driving',
    render: (text, record) => {
      return (
        <span>
          {record.start_driving
            ? moment(record.start_driving).format('YYYY-MM-DD / HH:mm')
            : ''}
        </span>
      );
    },
  },
  '4': {
    title: DATE_END,
    dataIndex: 'end_driving',
    key: 'end_driving',
    render: (text, record) => {
      return (
        <span>
          {record.end_driving
            ? moment(record.end_driving).format('YYYY-MM-DD / HH:mm')
            : ''}
        </span>
      );
    },
  },
});
