export const USER_ROLE_NO = 'User Role No.';
export const USER_ROLE_NAME = 'User Role Name';
export const USER_ROLE_EMPLOYEES = 'No. of Employees';

// Actions
export const USER_ROLE_EDIT = 'Edit user role';
export const USER_ROLE_DELETE = 'Delete user role';

// Other
export const USER_ROLE_NAME_REQUIRED = 'Role name is required';
export const USER_ROLE_NAME_ERROR =
  'Role name cannot contain words: admin, super';
export const EMPTY_USER_ROLES_TABLE_TEXT = 'There are no user roles defined';
export const ADD_USER_ROLE = 'Add user role';
export const MODAL_SUCCESS_CREATE_ROLE_TEXT =
  "User role and it's permissions have been successfully created and added.";
export const MODAL_SUCCESS_EDIT_ROLE_TEXT =
  "User role and it's permissions have been successfully updated.";
