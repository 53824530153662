import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input } from 'antd';
import {
  GENERAL_INFORMATION,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  CODE_NAME,
} from '../../../constants';

const GeneralInformation = () => {
  const {
    employeesPageState: { newEmployee },
  } = useMst();

  return (
    <div className="form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{GENERAL_INFORMATION}</span>
      </div>
      <div className="section-content align-center">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{FIRST_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            value={newEmployee.first_name}
            onChange={(e) => newEmployee.setFirstName(e.target.value)}
            className="add-edit-input"
            required
          />
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{LAST_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            value={newEmployee.last_name}
            onChange={(e) => newEmployee.setLastName(e.target.value)}
            className="add-edit-input"
            required
          />
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{EMAIL}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            value={newEmployee.email}
            onChange={(e) => newEmployee.setEmail(e.target.value)}
            className="add-edit-input"
            required
          />
        </div>
        <div className="input-wrapper ml20">
          <div className="input-label justify-between">
            <span>{CODE_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            value={newEmployee.code_name}
            onChange={(e) => newEmployee.setCodeName(e.target.value)}
            className="add-edit-input"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformation);
