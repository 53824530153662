import React from 'react';
import { observer } from 'mobx-react';
import fileUpload from '../../../assets/images/file-upload.svg';
import { Upload } from 'antd';
import {
  TRUCK_PICTURES,
  DOCUMENTATION,
  ADDITIONAL_DOCUMENTATION,
} from '../../../constants';
import { onFilePreview } from '../../../utils/methods';

const DocumentationSection = (props) => {
  // const registration = [...props.registration];
  // const handleRegistrationUpload = props.uploadRegistration;
  const truckPictures = [...props.truckPictures];
  const handleTruckPicturesUpload = props.uploadTruckPictures;
  const additionalFiles = [...props.additionalFiles];
  const handleAdditionalFilesUpload = props.uploadAdditionalFiles;

  return (
    <div className="add-driver-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{DOCUMENTATION}</span>
      </div>

      <div className="section-content flex">
        {/* <div className="input-wrapper ml0">
          <div className="input-label">
            <Upload
              fileList={registration}
              onChange={handleRegistrationUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              className="pointer"
              onPreview={(file) => {
                onFilePreview(file);
              }}
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary text-capitalize">
                  {REGISTRATION}
                </span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div> */}
        <div className="input-wrapper ml0">
          <div className="input-label">
            <Upload
              listType={'picture'}
              accept=".jpg, .png"
              fileList={truckPictures}
              onChange={handleTruckPicturesUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              className="pointer"
              onPreview={(file) => {
                onFilePreview(file);
              }}
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{TRUCK_PICTURES}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
      </div>

      {/* additional documentation subsection */}

      <div className="form-page-subsection">
        <div className="subsection-header">
          <p>{ADDITIONAL_DOCUMENTATION}</p>
        </div>
        <div className="section-content align-center">
          <div className="input-wrapper ml0">
            <div className="input-label">
              <Upload
                fileList={additionalFiles}
                onChange={handleAdditionalFilesUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                className="pointer"
                onPreview={(file) => {
                  onFilePreview(file);
                }}
              >
                <div className="align-center justify-between pointer">
                  <span className="color-primary">
                    {ADDITIONAL_DOCUMENTATION}
                  </span>
                  <img src={fileUpload} alt="file upload" />
                </div>
              </Upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DocumentationSection);
