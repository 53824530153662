import React from 'react';
import {
  CARGO_DESCRIPTION,
  PICKUP_LOCATION,
  DELIVERY_LOCATION,
  REMOVE_STOP,
  EDIT_STOP,
  STOP_NO,
  PICKUP_TIME,
  PICKUP_TYPE,
  PICKUP_NUMBER,
  NOT_AVAILABLE,
  DELIVERY_TIME,
  DELIVERY_TYPE,
  DELIVERY_NUMBER,
  UNIT_LOAD,
  QUANTITY,
  WEIGHT,
} from '../../constants';
import trash from '../../assets/images/trash.svg';
import edit from '../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../RootModel';
import moment from 'moment';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentsPageState.currentShipment.setProbillToBeDeleted(
    record.probill_id
  );
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentsPageState.currentShipment.getEditedProbill(
    record.probill_id
  );
};

export const probillsColumnsFunc = (rootInstance) => ({
  '1': {
    title: STOP_NO,
    dataIndex: 'probill_number',
    key: 'probill_number',
  },
  '2': {
    title: CARGO_DESCRIPTION,
    key: 'description',
    dataIndex: 'description',
  },
  '3': {
    title: PICKUP_LOCATION,
    key: 'pickup_location',
    dataIndex: 'pickup_location',
    render: (text, record) => {
      return (
        <span>
          <strong>{record.pickup_location?.location_name}</strong>
          <br />
          {record.pickup_location?.address}
        </span>
      );
    },
  },
  '4': {
    title: PICKUP_TIME,
    key: 'pickup_start_time',
    dataIndex: 'pickup_start_time',
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('DD.MM.YYYY HH:MM') : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '5': {
    title: PICKUP_TYPE,
    key: 'pickup_type',
    dataIndex: 'pickup_type',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '6': {
    title: PICKUP_NUMBER,
    key: 'pickup_number',
    dataIndex: 'pickup_number',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '7': {
    title: DELIVERY_LOCATION,
    key: 'delivery_location',
    dataIndex: 'delivery_location',
    render: (text, record) => {
      return (
        <span>
          <strong>{record.delivery_location?.location_name}</strong>
          <br />
          {record.delivery_location?.address}
          <br />
        </span>
      );
    },
  },
  '8': {
    title: DELIVERY_TIME,
    key: 'delivery_start_time',
    dataIndex: 'delivery_start_time',
    render: (text, record) => {
      return (
        <span>
          {text ? moment(text).format('DD.MM.YYYY HH:MM') : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '9': {
    title: DELIVERY_TYPE,
    key: 'delivery_type',
    dataIndex: 'delivery_type',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '10': {
    title: DELIVERY_NUMBER,
    key: 'delivery_number',
    dataIndex: 'delivery_number',
    render: (text, record) => {
      return <span>{text ? text : NOT_AVAILABLE}</span>;
    },
  },
  '11': {
    title: UNIT_LOAD,
    key: 'quantity_unit',
    dataIndex: 'quantity_unit',
    render: (text, record) => {
      return (
        <span>
          {record?.quantity_unit ? record.quantity_unit : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '12': {
    title: QUANTITY,
    key: 'quantity',
    dataIndex: 'quantity',
    render: (text, record) => {
      return <span>{record?.quantity ? record.quantity : NOT_AVAILABLE}</span>;
    },
  },
  '13': {
    title: WEIGHT,
    key: 'weight',
    dataIndex: 'weight',
    render: (text, record) => {
      return (
        <span>
          {record?.weight
            ? record.weight + ' ' + record?.weight_unit
            : NOT_AVAILABLE}
        </span>
      );
    },
  },
  '15': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        !rootInstance.shipmentsPageState.isViewShipmentPage && (
          <div className="align-center">
            {rootInstance.userState.canRemoveProbills && (
              <Tooltip title={REMOVE_STOP}>
                <img
                  src={trash}
                  alt="delete"
                  className="pointer ph10"
                  onClick={(e) => onClickDelete(e, record)}
                />
              </Tooltip>
            )}
            {rootInstance.canEditProbills && (
              <div onClick={(e) => onClickEdit(e, record)}>
                <Tooltip title={EDIT_STOP}>
                  <img src={edit} alt="edit" className="pointer ph10" />
                </Tooltip>
              </div>
            )}
          </div>
        )
      );
    },
  },
});
