import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import blackTruck from '../../../assets/images/black-truck.svg';
import deleteItem from '../../../assets/images/delete-item.svg';
import editItem from '../../../assets/images/edit-item.svg';
import { ordinal_suffix_of } from '../../../utils/methods';
import {
  ACTION,
  END_DATE_TIME,
  NOT_AVAILABLE_SHORT,
  NOTES,
  START_DATE_TIME,
  START_LOCATION,
  TARGET_LOCATION,
  TRAILER,
  TRUCK,
} from '../../../constants';
import moment from 'moment';
import { Select } from 'antd';
import {
  COMPANY_DRIVER,
  OWNER_OPERATOR,
} from '../../../constants/driver/ownershipTypes';

const { Option } = Select;

const DispatchRouteActionItem = (props) => {
  const {
    dispatchPageState: {
      removeRouteActionModalOpen,
      setRemoveRouteActionModalOpen,
      setRouteActionToBeDeleted,
      getAvailableStatuses,
      loadRouteActionDetails,
    },
  } = useMst();
  const { data } = props;
  const { index } = props;

  const onEditRouteAction = () => {
    loadRouteActionDetails(data);
  };
  const onDeleteRouteAction = () => {
    setRouteActionToBeDeleted(data.route_action_id);
    setRemoveRouteActionModalOpen(!removeRouteActionModalOpen);
  };

  return (
    <div className="dispatch-map-organize-tour-list__item">
      {!data.tripDetailsExpanded && (
        <div className="trip-tour ph20 pv20">
          <div className="align-center mb10">
            <p className="w100 text-black align-center">
              <img
                src={blackTruck}
                alt={ordinal_suffix_of(index + 1) + '-trip'}
                className="mr10"
              />
              <span className="text-black text-bold">
                {ACTION + ':'} {data?.action.action_name}
              </span>
              {/*<span className="text-blue text-capitalize ml5">*/}
              {/*  {' '}*/}
              {/*  ({data.route_action_type})*/}
              {/*</span>*/}
              <span className="flex ml-a">
                <span
                  className="trip-tour-edit-item mr10 cursor-pointer"
                  onClick={onEditRouteAction}
                >
                  <img
                    width={15}
                    src={editItem}
                    alt={`Edit route action ` + data?.route_action_id}
                  />
                </span>
                <span
                  className="trip-tour-delete-item cursor-pointer"
                  onClick={onDeleteRouteAction}
                >
                  <img
                    width={15}
                    src={deleteItem}
                    alt={`Delete route action ` + data?.route_action_id}
                  />
                </span>
              </span>
            </p>
          </div>
          <div className="ml30">
            <p>
              <span className="text-bold">{TRAILER}:</span>{' '}
              <span>
                {data.trailer.trailer_company_id +
                  ' - ' +
                  data.trailer.trailer_type}
              </span>
            </p>
            <p>
              <span className="text-bold">{TRUCK}:</span>{' '}
              <span>
                {data.truck.truck_company_id} - {data.truck.truck_model}
              </span>
            </p>
            <p>
              <span className="text-bold">{START_DATE_TIME}:</span>{' '}
              <span>
                {data?.start_date_and_time
                  ? moment(data.start_date_and_time).format(
                      'DD.MM.YYYY HH:mm:ss'
                    )
                  : NOT_AVAILABLE_SHORT}
              </span>
            </p>
            <p>
              <span className="text-bold">{END_DATE_TIME}:</span>{' '}
              <span>
                {data?.end_date_and_time
                  ? moment(data.end_date_and_time).format('DD.MM.YYYY HH:mm:ss')
                  : NOT_AVAILABLE_SHORT}
              </span>
            </p>
          </div>
          <p className="text-black mt10 ml30">
            <span className="text-black text-bold">
              {data.driver.driver_code_name}
            </span>
            <span className="ml5 text-blue text-capitalize text-bold">
              {data.driver.driver_type === COMPANY_DRIVER
                ? 'CD'
                : data.driver.driver_type === OWNER_OPERATOR
                ? 'OO'
                : NOT_AVAILABLE_SHORT}
            </span>
            <span
              className="ml5 text-capitalize text-orange"
              style={{ fontSize: 12 }}
            >
              (
              {data?.driver?.driver_availability
                ? data?.driver?.driver_availability
                : NOT_AVAILABLE_SHORT}
              )
            </span>
          </p>
          <p className="mt10 ml30">
            <span className="text-bold flex text-uppercase">
              {START_LOCATION}
            </span>
            <span>{data?.locations[0]?.address}</span>
          </p>
          {data?.locations?.length > 0 && (
            <p className="mt10 ml30">
              <span className="text-bold flex text-uppercase">
                {TARGET_LOCATION}
              </span>
              <span>{data.locations[data.locations.length - 1].address}</span>
            </p>
          )}
          {data.notes && (
            <p className="mt10 ml30">
              <span className="text-bold flex text-uppercase">{NOTES}</span>
              <span>{data.notes}</span>
            </p>
          )}
          <div className="align-center justify-between mt10 ml30">
            <div className="w50">
              <Select
                className="w100"
                value={data.status}
                onChange={data.setActionRuteStatus}
              >
                {getAvailableStatuses?.map((status, index) => (
                  <Option key={index} value={status}>
                    {status}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(DispatchRouteActionItem);
