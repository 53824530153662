import { types } from 'mobx-state-tree';

export const BottomMenuItemModel = types
  .model('BottomMenuItemModel', {
    id: types.identifier,
    title: types.string,
    active: types.boolean,
    text: types.frozen([]),
  })
  .actions((self) => {
    return {
      setActive(status) {
        self.active = status;
      },
    };
  });
