import { types } from 'mobx-state-tree';
import {
  DATE_REQUIRED,
  MAINTENANCE_TYPE_REQUIRED,
  NOTES_REQUIRED,
  TIME_REQUIRED,
} from '../../constants';
import { EDIT_TRUCK } from '../../constants/menu';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { rootInstance } from '../RootModel';
import { DocumentModel } from '../DocumentModel';
import {
  deleteDocument,
  uploadMaintenanceDocumentPromise,
  editMaintenance,
} from '../../promises/fleet';
import { deleteVehicleMaintenance } from '../../actions/fleet';

export const MaintenanceModel = types
  .model('MaintenanceModel', {
    maintenance_id: types.identifierNumber,
    maintenance_type: types.maybe(types.maybeNull(types.string)),
    date_time: types.maybe(types.maybeNull(types.string)),
    odometer: types.maybe(
      types.maybeNull(types.union(types.number, types.string))
    ),
    odometer_unit: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),
    signature: types.maybe(types.maybeNull(types.string)),
    vehicle_id: types.maybe(types.maybeNull(types.number)),
    document: types.maybe(types.maybeNull(DocumentModel)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {
      date: types.maybe(types.maybeNull(types.string)),
      time: types.maybe(types.maybeNull(types.string)),
    };
  })
  .views((self) => {
    return {
      get dateTime() {
        if (self.date && self.time) {
          return `${self.date}T${self.time}:00`;
        }
        return null;
      },
      get isEditTruckPage() {
        return rootInstance.router.currentView.name === EDIT_TRUCK;
      },
    };
  })
  .actions((self) => {
    return {
      setType(type) {
        self.maintenance_type = type;
      },
      setNotes(notes) {
        self.notes = notes;
      },
      setDate(date) {
        self.date = date;
      },
      setTime(time) {
        self.time = time;
      },
      setOdometer(odometer) {
        self.odometer = odometer;
      },
      setOdometerUnit(value) {
        self.odometer_unit = value;
      },
      setSignature(value) {
        self.signature = value;
      },
      setDocument(item) {
        item.uid = item.document_id;
        item.name = item.document_name;
        item.url = item.document_url;
        self.document = item;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwEditMaintenanceError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.maintenance_type) {
          this.setErrorText(MAINTENANCE_TYPE_REQUIRED);
        } else if (!self.date) {
          this.setErrorText(DATE_REQUIRED);
        } else if (!self.time) {
          this.setErrorText(TIME_REQUIRED);
        }
        // else if (self.isEditTruckPage && !self.odometer) {
        //   this.setErrorText(ODOMETER_REQUIRED);
        // } else if (self.isEditTruckPage && !self.odometer_unit) {
        //   this.setErrorText(ODOMETER_UNITS_REQUIRED);
        // }
        else if (!self.notes) {
          this.setErrorText(NOTES_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      redirectAfterSave() {
        if (rootInstance.fleetPageState.isEditTruckPage) {
          rootInstance.fleetPageState.getEditedTruck(self.vehicle_id);
        } else if (rootInstance.fleetPageState.isEditTrailerPage) {
          rootInstance.fleetPageState.getEditedTrailer(self.vehicle_id);
        }
      },
      updateMaintenance(documentToUpload, documentToDelete) {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          let promises = [];
          if (documentToUpload) {
            promises.push(
              uploadMaintenanceDocumentPromise(
                self.maintenance_id,
                documentToUpload
              )
            );
          }
          if (documentToDelete) {
            if (documentToDelete.document_type) {
              promises.push(deleteDocument(self.vehicle_id, documentToDelete));
            }
          }
          promises.push(editMaintenance(self));
          Promise.all(promises)
            .then(() => {
              this.redirectAfterSave();
            })
            .catch((error) => {
              this.throwEditMaintenanceError(error);
            });
        }
      },
      deleteMaintenance() {
        setFetchingInProgress(true);
        deleteVehicleMaintenance(self)
          .then((response) => {
            this.redirectAfterSave();
          })
          .catch((error) => {
            rootInstance.fleetPageState.editedVehicle.setEditedMaintenance(
              null
            );
            this.throwEditAbsenceError(error);
          });
      },
    };
  });
