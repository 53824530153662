// TABS
export const REPORTS = 'reports';

export const SHIPMENT_NO = 'Shipment No.';
export const PICKUP_COUNTRY = 'Pick up Country';
export const DELIVERY_CONTRY = 'Delivery country';
export const SUM_EMPTY_MILES = 'Sum of Empty miles';
export const SUM_LOADED_MILES = 'Sum of Loaded miles';
export const SUM_TOTAL_MILES = 'Sum of Total miles';
export const SUM_TOTAL_CHARGE = 'Sum of Total charge';
export const SUM_TOTAL_COST = 'Sum of Total cost';
export const SUM_PROFIT = 'Sum of Profit';
export const AVERAGE_REVENUE_MILE = 'Average revenue per mile';
export const AVERAGE_COST_MILE = 'Average Cost per mile';
export const AVERAGE_PROFIT_MILE = 'Average Profit per mile';
export const AVERAGE_DRIVER_MILE = 'Average Driver per mile';
