import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Select } from 'antd';
import {
  GENERAL_INFORMATION,
  DRIVER_TYPE,
  FIRST_NAME,
  LAST_NAME,
  CODE_NAME,
  CATEGORY,
  WORKING_STATUS,
  NOTES,
  DRIVER_COMPANY_ID,
} from '../../../constants';
import { snakeCaseToString } from '../../../utils/methods';

const { Option } = Select;

const GeneralInformation = () => {
  const {
    fleetPageState: {
      editedDriver,
      driverCategories,
      driverTypes,
      driverWorkingStatuses,
    },
  } = useMst();

  const onSelectType = (value) => {
    editedDriver.updateDriverType(value);
  };

  const onSelectCategory = (value) => {
    editedDriver.updateDriverCategory(value);
  };

  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{GENERAL_INFORMATION}</span>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{DRIVER_TYPE}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectType}
            value={editedDriver.driver_type}
          >
            {driverTypes.map((type) => (
              <Option key={type} className="text-capitalize">
                {snakeCaseToString(type)}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CATEGORY}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectCategory}
            value={editedDriver.driver_category}
          >
            {driverCategories.map((type) => (
              <Option key={type} className="text-capitalize">
                {snakeCaseToString(type)}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{DRIVER_COMPANY_ID}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedDriver.updateDriverInternalCompanyId(e.target.value);
            }}
            className="add-edit-input"
            value={editedDriver.internal_company_id}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{CODE_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedDriver.updateDriverCodeName(e.target.value);
            }}
            className="add-edit-input"
            value={editedDriver.code_name}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{FIRST_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedDriver.updateDriverName(e.target.value);
            }}
            className="add-edit-input"
            value={editedDriver.first_name}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{LAST_NAME}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              editedDriver.updateDriverLastName(e.target.value);
            }}
            className="add-edit-input"
            value={editedDriver.last_name}
          />
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{WORKING_STATUS}</span>
          </div>
          <Select
            className="w100 text-capitalize"
            value={editedDriver.driverWorkingStatus}
            disabled
          >
            {driverWorkingStatuses.map((status) => (
              <Option
                key={status}
                value={status}
                className="text-capitalize align-center"
              >
                <div className="align-center">
                  <div
                    className={`status-dot mr10 ${
                      editedDriver.isDriverOff ? 'unavailable' : ''
                    }`}
                  />
                  <div>{status}</div>
                </div>
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{NOTES}</span>
          </div>
          <Input
            onChange={(e) => {
              editedDriver.setNotes(e.target.value);
            }}
            className="add-edit-input"
            value={editedDriver.notes}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformation);
