import React from 'react';
import { observer } from 'mobx-react';
import AssetsWrapper from './AssetsWrapper';
import { Empty, Table } from 'antd';
import { DRIVERS, TRUCKS } from '../../../constants';
import AssetsListDriverTableDetails from '../drivers/AssetsListDriverTableDetails';
import AssetsListTruckTableDetails from '../trucks/AssetsListTruckTableDetails';

const AssetsTable = ({ tableText, data, column, expandable, type, update }) => {
  return (
    <AssetsWrapper
      isInfiniteScrollOn={true}
      lengthData={data.length}
      functionNext={update}
    >
      <Table
        className="mb20 mt20"
        rowClassName={(record, index) => 'assetsTableRowHeight'}
        rowKey={'internal_company_id'}
        dataSource={data}
        columns={column}
        pagination={false}
        expandRowByClick={expandable}
        expandedRowRender={(record) => {
          if (expandable && type === DRIVERS) {
            return <AssetsListDriverTableDetails data={record} />;
          } else if (expandable && type === TRUCKS) {
            return <AssetsListTruckTableDetails data={record} />;
          }
        }}
        expandIcon={() => null}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={tableText}
            />
          ),
        }}
      />
    </AssetsWrapper>
  );
};

export default observer(AssetsTable);
