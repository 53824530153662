import React from 'react';
import { observer } from 'mobx-react';
import { DOCUMENTATION } from '../../../../constants';
import dropdownIcon from '../../../../assets/images/dropdown-icon.svg';
import ViewDocumentsTable from './ViewDocumentsTable';

const ViewDocumentationSection = ({ model }) => {
  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            model.setIsDocumentationSectionOpen(
              !model.isDocumentationSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              model.isDocumentationSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {DOCUMENTATION}
          </span>
        </div>
      </div>
      {model?.isDocumentationSectionOpen && (
        <ViewDocumentsTable model={model} />
      )}
    </div>
  );
};

export default observer(ViewDocumentationSection);
