import React from 'react';
import { observer } from 'mobx-react';

const barInitialHeight = '80px';
const barHeightPercent = '1.4px';

const BarComponent = (props) => {
  const data = { ...props };

  return (
    <div className="bar-wrapper">
      <div
        className="bar"
        style={{
          height: `calc(${barInitialHeight} + ${data.value} * ${barHeightPercent})`,
        }}
      >
        <div className="flex-column justify-between h100">
          <span className="bar-value">{data.value}%</span>
          <span className="bar-text">{data.text}</span>
        </div>

        <span className="bar-title">{data.title}</span>
      </div>
    </div>
  );
};

export default observer(BarComponent);
