import { types } from 'mobx-state-tree';
import { addShipment } from '../../actions/shipments';
import {
  CUSTOMER_NAME_REQUIRED,
  PO_NUMBER_REQUIRED,
  STATUS_REQUIRED,
} from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { rootInstance } from '../RootModel';

export const ShipmentLightModel = types
  .model('ShipmentLightModel', {
    shipment_by: types.maybe(types.maybeNull(types.number)),
    bill_to: types.maybe(types.maybeNull(types.number)),
    pio_number: types.maybe(types.maybeNull(types.string)),
    broker_name: types.maybe(types.maybeNull(types.string)),
    broker_phone: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.number)),
    is_brokerage_shipment: false,
    errorText: types.maybe(types.maybeNull(types.string)),
    shipment_size: '',
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setShipmentSize(size) {
        self.shipment_size = size;
      },
      setIsBrokerageShipment(status) {
        self.is_brokerage_shipment = status;
      },
      setShipmentBy(number) {
        self.shipment_by = number;
      },
      setBillTo(number) {
        self.bill_to = number;
      },
      setPioNumber(text) {
        self.pio_number = text;
      },
      setBrokerPhone(text) {
        self.broker_phone = text;
      },
      setBrokerName(text) {
        self.broker_name = text;
      },
      setStatus(status) {
        self.status = status;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveShipmentError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.shipment_by) {
          this.setErrorText(CUSTOMER_NAME_REQUIRED);
        } else if (!self.bill_to) {
          this.setErrorText(CUSTOMER_NAME_REQUIRED);
        } else if (!self.pio_number) {
          this.setErrorText(PO_NUMBER_REQUIRED);
        } else if (!self.status) {
          this.setErrorText(STATUS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveShipment() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addShipment(self)
            .then((response) => {
              const shipment = response.data;
              rootInstance.shipmentsPageState.getShipmentInCreation(
                shipment.shipment_id
              );
              // rootInstance.shipmentsPageState.setNewShipment(null);
              // setFetchingInProgress(false);
              // console.log(response.data);
              // rootInstance.clientsPageState.setAddClientSuccessModalOpen(true);
            })
            .catch((error) => {
              this.throwSaveShipmentError(error);
            });
        }
      },
    };
  });
