import React from 'react';
import { observer } from 'mobx-react';
import { Checkbox, Modal } from 'antd';
import { useMst } from '../../../state/RootModel';
import FormPageTitle from '../../FormPageTitle';
import dispatchIcon from '../../../assets/images/menu/dispatch-active.svg';
import { CANCEL, LOCAL_PD, SAVE } from '../../../constants';

const SendDataToPlanner = () => {
  const {
    shipmentsPageState: {
      isSendDataToPlannerModalOpen,
      setIsSendDataToPlannerModalOpen,
      viewedShipment,
    },
  } = useMst();

  const onCancel = () => {
    setIsSendDataToPlannerModalOpen(false);
    viewedShipment?.setPlannerDataLocalPickupValue(false);
    viewedShipment?.setPlannerDataLocalDeliveryValue(false);
  };
  const onSelectLocalPickup = (e) => {
    viewedShipment?.setPlannerDataLocalPickupValue(e.target.checked);
  };
  const onSelectLocalDelivery = (e) => {
    viewedShipment?.setPlannerDataLocalDeliveryValue(e.target.checked);
  };
  const sendDataToPlanner = () => {
    const data_for_planner = {
      o_id: viewedShipment.shipment_id,
      local_pickup: viewedShipment?.plannerDataLocalPickup,
      local_delivery: viewedShipment?.plannerDataLocalDelivery,
    };
    viewedShipment?.setPickupDelivery(data_for_planner);
    setIsSendDataToPlannerModalOpen(false);
    viewedShipment?.setPlannerDataLocalPickupValue(false);
    viewedShipment?.setPlannerDataLocalDeliveryValue(false);
  };

  return (
    <Modal
      visible={isSendDataToPlannerModalOpen}
      footer={null}
      centered={true}
      onCancel={onCancel}
      width={400}
      className="modal"
    >
      <div className="p32 location-modal-content">
        <div className="align-center mb20">
          <FormPageTitle image={dispatchIcon} title={LOCAL_PD} />
        </div>
        <div className="flex-column form-page-title-wrapper pv0">
          <Checkbox
            key={'local_pickup'}
            checked={viewedShipment?.plannerDataLocalPickup}
            onClick={onSelectLocalPickup}
          >
            Local Pickup
          </Checkbox>
          <Checkbox
            key={'local_delivery'}
            checked={viewedShipment?.plannerDataLocalDelivery}
            className="pt10"
            style={{ marginLeft: 0 }}
            onClick={onSelectLocalDelivery}
          >
            Local Delivery
          </Checkbox>
        </div>
        <button
          className="btn small primary ml15 mt20 text-capitalize"
          onClick={sendDataToPlanner}
        >
          {SAVE}
        </button>
        <button
          className="btn small text-dkred ml15 mt20 text-capitalize"
          onClick={onCancel}
        >
          {CANCEL}
        </button>
      </div>
    </Modal>
  );
};

export default observer(SendDataToPlanner);
