import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { GENERAL_INFORMATION } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import EmployeeInformation from './EmployeeInformation';

const GeneralInformation = () => {
  const {
    employeesPageState: { viewedEmployee },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedEmployee.setIsGeneralInfoSectionOpen(
              !viewedEmployee.isGeneralInfoSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedEmployee.isGeneralInfoSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {GENERAL_INFORMATION}
          </span>
        </div>
      </div>
      {viewedEmployee?.isGeneralInfoSectionOpen && <EmployeeInformation />}
    </div>
  );
};

export default observer(GeneralInformation);
