import React from 'react';
import { observer } from 'mobx-react';
import { BILLING_DETAILS } from '../../../constants';
import { useMst } from '../../../state/RootModel';
import plusCircle from '../../../assets/images/plus-circle.svg';
import BillingDetailComponent from './BillingDetail';

const BillingDetails = () => {
  const {
    shipmentsPageState: {
      currentShipment: { shipmentChargesArray, addNewShipmentCharge },
    },
    userState: { canAddShipmentBillingCharges },
  } = useMst();

  const onAddBillingDetail = () => {
    addNewShipmentCharge({});
  };

  return (
    <div className="page-section shipment-details-section w40">
      <div className="subsection-header align-center">
        <p>{BILLING_DETAILS}</p>
        {canAddShipmentBillingCharges && (
          <img
            src={plusCircle}
            alt="add detail"
            className="ml20 pointer"
            onClick={onAddBillingDetail}
          />
        )}
      </div>
      <div className="subsection-content flex-column">
        {shipmentChargesArray.map((item) => (
          <BillingDetailComponent data={item} key={item.shipment_charge_id} />
        ))}
      </div>
    </div>
  );
};

export default observer(BillingDetails);
