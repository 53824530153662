import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import errorSign from '../../assets/images/error-sign.svg';

const LoginErrorBox = () => {
  const loginForm = useMst().loginForm;

  return (
    <div className="login-info error">
      <div className="w100 justify-between align-center">
        <div className="flex-alignitems-flex-end">
          <img src={errorSign} alt="error sign" height="24" />
          <p className="error-text ml5">{loginForm.errorText}</p>
        </div>
      </div>
    </div>
  );
};

export default observer(LoginErrorBox);
