import React from 'react';
import { observer } from 'mobx-react';
import fileUpload from '../../../assets/images/file-upload.svg';
import { Upload } from 'antd';
import {
  DOCUMENTATION,
  CV_RESUME,
  RECOMMENDATION_LETTER,
  ADDITIONAL_DOCUMENTATION,
} from '../../../constants';

const DocumentationSection = (props) => {
  const cv = [...props.cv];
  const handleCVUpload = props.uploadCV;
  const reccomendationLetter = [...props.reccomendationLetter];
  const handleReccomendationLetterUpload = props.uploadReccomendationLetter;
  const additionalFiles = [...props.additionalFiles];
  const handleAdditionalFilesUpload = props.uploadAdditionalFiles;

  return (
    <div className="add-driver-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{DOCUMENTATION}</span>
      </div>

      <div className="section-content flex">
        <div className="input-wrapper ml0">
          <div className="input-label">
            <Upload
              fileList={cv}
              onChange={handleCVUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{CV_RESUME}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
        <div className="input-wrapper">
          <div className="input-label">
            <Upload
              fileList={reccomendationLetter}
              onChange={handleReccomendationLetterUpload}
              beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
            >
              <div className="align-center justify-between pointer">
                <span className="color-primary">{RECOMMENDATION_LETTER}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            </Upload>
          </div>
        </div>
      </div>
      {/* additional documentation */}

      <div className="form-page-subsection">
        <div className="subsection-header">
          <p>{ADDITIONAL_DOCUMENTATION}</p>
        </div>
        <div className="section-content align-center">
          <div className="input-wrapper ml0">
            <div className="input-label">
              <Upload
                fileList={additionalFiles}
                onChange={handleAdditionalFilesUpload}
                beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
              >
                <div className="align-center justify-between pointer">
                  <span className="color-primary">
                    {ADDITIONAL_DOCUMENTATION}
                  </span>
                  <img src={fileUpload} alt="file upload" />
                </div>
              </Upload>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DocumentationSection);
