import { types } from 'mobx-state-tree';
import {
  VIN_REQUIRED,
  FINANCING_TYPE_REQUIRED,
  OWNERSHIP_TYPE_REQUIRED,
} from '../../../constants';

export const TruckLightModel = types
  .model('TruckLightModel', {
    vin: types.maybe(types.maybeNull(types.string)),
    ownership_type: types.maybe(types.maybeNull(types.string)),
    type_of_financing: types.maybe(types.maybeNull(types.string)),
    date_hired: types.maybe(types.maybeNull(types.string)),
    vehicle_model_type: types.maybe(types.maybeNull(types.string)),
    vehicle_make: types.maybe(types.maybeNull(types.string)),
    vehicle_model_name: types.maybe(types.maybeNull(types.string)),
    vehicle_production_year: types.maybe(types.maybeNull(types.number)),
    fuel_type: types.maybe(types.maybeNull(types.string)),
    vehicle_weight: types.maybe(types.maybeNull(types.number)),
    vehicle_weight_unit: types.maybe(types.maybeNull(types.string)),
    vehicle_body_class: types.maybe(types.maybeNull(types.string)),
    odometer_when_hired: types.maybe(types.maybeNull(types.number)),
    odometer_units: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {
      availableWeightUnits: ['t', 'kg', 'lb'],
    };
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setNotes(notes) {
        self.notes = notes;
      },
      setYear(year) {
        self.vehicle_production_year = year;
      },
      setMake(make) {
        self.vehicle_make = make;
      },
      setModel(model) {
        self.vehicle_model_name = model;
      },
      setType(type) {
        self.vehicle_model_type = type;
      },
      setWeight(weight) {
        self.vehicle_weight = weight;
      },
      setWeightUnits(weight_units) {
        self.vehicle_weight_unit = weight_units;
      },
      setClass(truck_class) {
        self.vehicle_body_class = truck_class;
      },
      setFuelType(fuel_type) {
        self.fuel_type = fuel_type;
      },
      setOwnershipType(ownership_type) {
        self.ownership_type = ownership_type;
      },
      setVin(vin) {
        self.vin = vin;
      },
      setFinancingType(type_of_financing) {
        self.type_of_financing = type_of_financing;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setDateHired(date) {
        self.date_hired = date;
      },
      clearForm() {
        this.setOwnershipType(null);
        this.setVin(null);
        this.setFinancingType(null);
      },
      validateForm() {
        if (!self.vin) {
          this.setErrorText(VIN_REQUIRED);
        } else if (!self.ownership_type) {
          this.setErrorText(OWNERSHIP_TYPE_REQUIRED);
        } else if (!self.type_of_financing) {
          this.setErrorText(FINANCING_TYPE_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
    };
  });
