import React from 'react';
import { Menu, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import Link from '../Link';
import { getIsUserPermittedForRoute } from '../../utils/methods';
import { LOGOUT } from '../../constants/menu';

const CustomMenu = (props: any) => {
  const {
    sideMenuState: { menuItemsArray, closeAllDescriptionBoxes },
    loginForm,
    router: { views },
  } = useMst();

  const logout = (e) => {
    e.preventDefault();
    loginForm.logout();
  };

  return (
    <div>
      {menuItemsArray.map((item) => {
        if (
          item.title !== LOGOUT &&
          item.limitedTo !== false &&
          getIsUserPermittedForRoute(item.limitedTo)
        ) {
          return (
            <div
              className="menu-item-wrapper"
              key={item.title}
              onMouseEnter={() => item.setDescriptionOpen(true)}
              onMouseLeave={() => closeAllDescriptionBoxes()}
            >
              <Menu.Item {...props}>
                <Link view={views.get(item.route)} className="sidemenu-link">
                  <Tooltip placement="right" title={item.title}>
                    <span
                      className={`menu-icon-wrapper ${
                        item.active ? 'active' : null
                      }`}
                    >
                      <img src={item.icon} alt="menu-icon" />
                    </span>
                  </Tooltip>
                </Link>
              </Menu.Item>
            </div>
          );
        } else if (item.title === LOGOUT) {
          return (
            <div
              className="menu-item-wrapper logout-link"
              key={item.title}
              onMouseEnter={() => item.setDescriptionOpen(true)}
              onMouseLeave={() => closeAllDescriptionBoxes()}
            >
              <Menu.Item {...props}>
                <div onClick={logout} className="sidemenu-link">
                  <Tooltip placement="right" title={item.title}>
                    <img
                      src={item.icon}
                      alt="menu-icon"
                      onMouseEnter={() => item.setDescriptionOpen(true)}
                    />
                  </Tooltip>
                </div>
              </Menu.Item>
            </div>
          );
        }
      })}
    </div>
  );
};

export default observer(CustomMenu);
