import { rootInstance } from '../../state/RootModel';

import qs from 'qs';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getPerformanceAndAnalize = (args: {
  start_date: string;
  end_date: string;
}) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/performance_and_analise?${qs.stringify(args)}`,
    config
  );
};
