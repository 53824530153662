import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../state/RootModel';
import { Modal } from 'antd';
import {
  CONFIRM,
  CANCEL,
  CONFIRM_DELETE_MODAL_TEXT,
  CARRIER,
} from '../../../../constants';

const ConfirmDeleteCarrierModal = () => {
  const {
    carriersPageState: {
      confirmDeleteCarrierModalOpen,
      setConfirmDeleteCarrierModalOpen,
      carrierToBeDeleted,
      deleteCarrier,
    },
  } = useMst();

  const onCancel = () => {
    setConfirmDeleteCarrierModalOpen(false);
  };

  const onConfirm = () => {
    deleteCarrier();
  };

  return (
    carrierToBeDeleted && (
      <div>
        <Modal
          visible={confirmDeleteCarrierModalOpen}
          footer={null}
          centered={true}
          closable={false}
          onCancel={() => {}}
          width={400}
          className="confirm-delete-modal"
        >
          <div className="p32">
            <div>
              <p>
                {CONFIRM_DELETE_MODAL_TEXT} {CARRIER.toLowerCase()}{' '}
                {carrierToBeDeleted.company_name}?
              </p>
            </div>
            <div className="justify-end mt50">
              <button className="btn white mr10" onClick={onCancel}>
                {CANCEL}
              </button>
              <button className="btn primary" onClick={onConfirm}>
                {CONFIRM}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(ConfirmDeleteCarrierModal);
