import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { Tabs } from 'antd';

import {
  COMPENSATION_DEDUCTIONS,
  COMPENSATION_STRUCTURES,
  COMPENSATION_VALUES,
} from '../../constants/payroll';
import CompensationsComponent from '../../components/payroll/Compensations/CompensationsComponent';
import CompensationsValuesComponent from '../../components/payroll/Compensations/CompensationsValuesComponent';
import CompensationsDeductionsComponent from '../../components/payroll/Compensations/CompensationsDeductionsComponent';
import DeleteCompensationModal from '../../components/payroll/Compensations/DeleteCompensationModal';
import DeleteValueModal from '../../components/payroll/Compensations/DeleteValueModal';
import DeleteDeductionModal from '../../components/payroll/Compensations/DeleteDeductionModal';
import EditCompensationModal from '../../components/payroll/EditCompensation/EditCompensationModal';
import EditCompensationValueModal from '../../components/payroll/EditCompensationValue/EditCompensationValueModal';
import EditCompensationDeductionModal from '../../components/payroll/EditCompensationDeduction/EditCompensationDeductionModal';
const { TabPane } = Tabs;

const PayrollPage = () => {
  const {
    payrollPageState: { activeTab, setActiveTab },
  } = useMst();

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="page payroll-page">
      <div className="page-content pv20">
        <Tabs activeKey={activeTab} onChange={onTabChange}>
          <TabPane tab={COMPENSATION_STRUCTURES} key={COMPENSATION_STRUCTURES}>
            <CompensationsComponent />
          </TabPane>
          <TabPane tab={COMPENSATION_VALUES} key={COMPENSATION_VALUES}>
            <CompensationsValuesComponent />
          </TabPane>
          <TabPane tab={COMPENSATION_DEDUCTIONS} key={COMPENSATION_DEDUCTIONS}>
            <CompensationsDeductionsComponent />
          </TabPane>
        </Tabs>
      </div>
      <DeleteCompensationModal />
      <DeleteDeductionModal />
      <EditCompensationModal />
      <EditCompensationValueModal />
      <EditCompensationDeductionModal />
    </div>
  );
};

export default observer(PayrollPage);
