import { types } from 'mobx-state-tree';
import {
  deleteUserRole,
  updateRoleName,
  updateRolePermissions,
} from '../../../actions/organization';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { notification } from 'antd';
import { rootInstance } from '../../RootModel';

export const UserRoleOrganizationModel = types
  .model('UserRoleOrganizationModel', {
    role_id: types.identifierNumber,
    role_name: types.maybe(types.maybeNull(types.string)),
    permissions: types.frozen([]),
    isUserRoleEditingSuccessModalOpen: false,
    errorText: types.maybe(types.maybeNull(types.string)),
    number_of_people: types.maybe(types.maybeNull(types.number)),
  })
  .volatile((self) => {
    return {
      forbiddenNames: ['admin', 'Admin', 'super', 'Super'],
    };
  })
  .views((self) => {
    return {
      get getRolePermissions() {
        return [...new Set(self.permissions)];
      },
    };
  })
  .actions((self) => {
    return {
      setIsUserRoleEditingSuccessModalOpen(value) {
        self.isUserRoleEditingSuccessModalOpen = value;
      },
      setRoleName(value) {
        self.role_name = value;
      },
      setPermissions(permissions) {
        self.permissions = permissions;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      validateForm() {
        // if (!self.role_name) {
        //     this.setErrorText(USER_ROLE_NAME_REQUIRED)
        // } else if (self.role_name.toLocaleLowerCase().includes('admin') || self.role_name.toLocaleLowerCase().includes('super')) {
        //     this.setErrorText(USER_ROLE_NAME_ERROR)
        // } else
        if (self.permissions.length === 0) {
          this.setErrorText('Please select at least one permission');
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveEditedRole() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          if (self.forbiddenNames.includes(self.role_name)) {
            updateRolePermissions(self.role_id, self.getRolePermissions)
              .then((response) => {
                setFetchingInProgress(false);
                this.setIsUserRoleEditingSuccessModalOpen(true);
              })
              .catch((error) => {
                setFetchingInProgress(false);
                console.log('Error: ', error);
                const errorText = getErrorText(error);
                notification.error({
                  message: 'Error',
                  description: errorText,
                });
              });
          } else {
            updateRoleName(self.role_id, self.role_name)
              .then((response) => {
                updateRolePermissions(self.role_id, self.getRolePermissions)
                  .then((response) => {
                    setFetchingInProgress(false);
                    this.setIsUserRoleEditingSuccessModalOpen(true);
                  })
                  .catch((error) => {
                    setFetchingInProgress(false);
                    console.log('Error: ', error);
                    const errorText = getErrorText(error);
                    notification.error({
                      message: 'Error',
                      description: errorText,
                    });
                  });
              })
              .catch((error) => {
                setFetchingInProgress(false);
                console.log('Error: ', error);
                const errorText = getErrorText(error);
                notification.error({
                  message: 'Error',
                  description: errorText,
                });
              });
          }
        }
      },
      async deleteRole(id) {
        setFetchingInProgress(true);
        try {
          await deleteUserRole(id);
          rootInstance.currentOrganization.setUserRoleToBeDeleted(null);
          rootInstance.currentOrganization.setIsDeleteUserRoleModalOpen(false);
          rootInstance.currentOrganization.getUserRolesList();
        } catch (error) {
          const errorText = getErrorText(error);
          notification.error({
            message: 'Error',
            description: errorText,
          });
        } finally {
          setFetchingInProgress(false);
        }
      },
    };
  });
