import { types } from 'mobx-state-tree';
import { RouteActionListDriverModel } from './RouteActionListDriverModel';
import { RouteActionListTruckModel } from './RouteActionListTruckModel';
import { RouteActionListTrailerModel } from './RouteActionListTrailerModel';

export const RouteActionListModel = types.model('RouteActionListModel', {
  route_action_id: types.identifierNumber,
  dispatcher: types.maybe(types.maybeNull(types.string)),
  bill_to_customer: types.maybe(types.maybeNull(types.string)),
  driver: types.maybeNull(RouteActionListDriverModel),
  truck: types.maybeNull(RouteActionListTruckModel),
  trailer: types.maybeNull(RouteActionListTrailerModel),
  action_type: types.maybe(types.maybeNull(types.string)),
  start: types.maybe(types.maybeNull(types.string)),
  miles: types.maybe(types.maybeNull(types.number)),
  eta: types.maybe(types.maybeNull(types.string)),
  notes: types.maybe(types.maybeNull(types.string)),
  status: types.maybe(types.maybeNull(types.string)),
  end_location: types.frozen([]),
});
