import { notification } from 'antd';
import { getRoot, types } from 'mobx-state-tree';
import {
  deleteProbill,
  getProbill,
  getProbillTimeTypes,
} from '../../actions/probills';
import {
  DELETE_PROBILL_ERROR,
  FETCH_PROBILL_ERROR,
  CUSTOMER_NAME_REQUIRED,
  PO_NUMBER_REQUIRED,
  STATUS_REQUIRED,
  FETCH_SHIPMENT_SPECIFICS_ERROR,
  UPLOAD_ERROR,
  FETCH_SHIPMENT_DOCUMENTS_ERROR,
  DELETE_DOCUMENT_ERROR,
  ALL_CHARGE_ITEMS_FIELDS_REQUIRED,
  FETCH_CARRIERS_ERROR,
  BASE_CHARGE,
  CARRIER_CHARGE_BROKERAGE
} from '../../constants';
import { receiverLocations, shipperLocations } from '../../promises/customers';
import {
  getErrorText,
  getRandomInt,
  setFetchingInProgress,
  toAmount,
} from '../../utils/methods';
import { ProbillLightModel } from '../probills/ProbillLightModel';
import { ProbillModel } from '../probills/ProbilModel';
import { probillsColumnsFunc } from './ProbillsTableColumns';
import { shipmentCarriersTableColumnsFunc } from './ShipmentCarriersTableColumns';
import moment from 'moment';
import {
  getShipmentProbills,
  uploadShipmentDocument,
  getShipmentDocuments,
  deleteShipmentDocument,
  deleteShipmentRelatedCharge,
  getCarriersWithEquipment,
  setLocalPD,
} from '../../actions/shipments';
import {
  editShipmentPromise,
  addShipmentChargesPromise,
  editShipmentRelatedChargePromise,
} from '../../promises/shipments';
import { rootInstance } from '../RootModel';
import { ShipmentDocumentModel } from '../ShipmentDocumentModel';
import { SHIPMENT_DOCUMENT_TYPE } from '../../constants/shipmentSpecificsTypes';
import { getShipmentSpecifics } from '../../actions/shipmentSpecifics';
import { documentsColumnsFunc } from './DocumentsTableColumns';
import { ShipmentRelatedChargeModel } from './ShipmentRelatedCharge';
import { EquipmentModel } from './EquipmentModel';
import {
  CREATE_SHIPMENT,
  EDIT_SHIPMENT,
  VIEW_SHIPMENT,
} from '../../constants/menu';
import { CarrierModel } from '../carriers/CarrierModel';
import { ClientModel } from '../clients/ClientModel';
import { ShipmentNoteModel } from './ShipmentNoteModel';
import { notesColumnsFunc } from './NotesTableColumns';
import { ShipmentNoteLightModel } from './ShipmentNoteLightModel';

const allSettled = require('promise.allsettled');

export const ShipmentModel = types
  .model('ShipmentModel', {
    shipment_id: types.identifierNumber,
    bill_to: types.frozen({}),
    broker_name: types.maybe(types.maybeNull(types.string)),
    broker_phone: types.maybe(types.maybeNull(types.string)),
    carrier: types.frozen({}),
    shipment_by: types.frozen({}),
    shipment_number: types.maybe(types.maybeNull(types.string)),
    pio_number: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.number)),
    trailer_type_id: types.maybe(types.maybeNull(types.number)),
    trailer_type: types.frozen({}),
    is_brokerage_shipment: types.maybe(types.maybeNull(types.boolean)),
    documents: types.map(ShipmentDocumentModel),
    documentToBeDeleted: types.maybeNull(
      types.reference(ShipmentDocumentModel)
    ),
    availableDocumentTypes: types.frozen([]),
    currencyTypes: types.frozen([]),
    availableShipmentCharges: types.frozen([]),
    shipmentCharges: types.map(ShipmentRelatedChargeModel),
    shipmentChargeToBeDeleted: types.maybeNull(
      types.reference(ShipmentRelatedChargeModel)
    ),

    availableEquipment: types.map(EquipmentModel),
    selectedEquipment: types.maybeNull(types.reference(EquipmentModel)),
    carriersWithEquipment: types.map(CarrierModel),
    selectedCarrier: types.maybeNull(types.reference(CarrierModel)),

    organization_id: types.maybe(types.maybeNull(types.number)),
    price: types.maybe(types.maybeNull(types.number)),
    currency: types.maybe(types.maybeNull(types.string)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),

    probills: types.map(ProbillModel),
    newProbill: types.maybe(types.maybeNull(ProbillLightModel)),
    probillToBeDeleted: types.maybeNull(types.reference(ProbillModel)),

    editedProbill: types.maybe(types.maybeNull(ProbillModel)),
    editProbillSuccessModalOpen: false,

    addDocumentModalOpen: false,

    errorText: types.maybe(types.maybeNull(types.string)),
    fullyFetched: false,
    isCarriersModalOpened: false,
    carriersListCurrentPage: 1,
    carriersListPageSize: 5,
    carrierListSearchTerm: '',
    carriersTotalItems: types.maybe(types.maybeNull(types.number)),

    timeTypes: types.frozen([]),

    plannerDataLocalPickup: false,
    plannerDataLocalDelivery: false,

    client_data: types.maybe(types.maybeNull(ClientModel)),
    shipment_size: '',
    shipment_notes: types.map(ShipmentNoteModel),
    shipmentNoteToBeDeleted: types.maybeNull(
      types.reference(ShipmentNoteModel)
    ),
    isAddShipmentNoteModalOpened: false,
    newShipmentNote: types.maybe(types.maybeNull(ShipmentNoteLightModel)),
    shipmentNoteToBeEdited: types.maybeNull(types.reference(ShipmentNoteModel)),
    carrier_notes: types.frozen([]),
    receiver_notes: types.frozen([]),
    shipper_notes: types.frozen([]),
    customer_notes: types.maybe(types.maybeNull(types.string)),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      getShipmentNotesArray() {
        return Array.from(self.shipment_notes.values());
      },
      get probillsArray() {
        return Array.from(self.probills.values());
      },
      get documentsArray() {
        return Array.from(self.documents.values());
      },
      get availableEquipmentArray() {
        return Array.from(self.availableEquipment.values());
      },
      get shipmentChargesArray() {
        return Array.from(self.shipmentCharges.values());
      },
      get carriersWithEquipmentArray() {
        return Array.from(self.carriersWithEquipment.values());
      },
      get filteredCarriersWithEquipmentArray() {
        const searchText = self.carrierListSearchTerm;
        const filteredArray = this.carriersWithEquipmentArray.filter(
          (carrier) => {
            return (
              carrier.company_name.includes(searchText) ||
              carrier.carrier_id === parseInt(searchText) ||
              carrier.phone_number.includes(searchText)
            );
          }
        );
        return filteredArray;
      },
      get isViewShipmentPage() {
        return rootInstance.router.currentView.name === VIEW_SHIPMENT;
      },
      get isEditShipmentPage() {
        return rootInstance.router.currentView.name === EDIT_SHIPMENT;
      },
      get isCreateShipmentPage() {
        return rootInstance.router.currentView.name === CREATE_SHIPMENT;
      },
      get isBrokerageShipment() {
        return self.is_brokerage_shipment;
      },
      get baseChargeAmount() {
        if (this.shipmentChargesArray.length) {
          return toAmount(
            this.shipmentChargesArray.find(
              (item) =>
                (item.charge_name ?? '').toLowerCase() ===
                BASE_CHARGE.toLowerCase()
            )?.price ?? 0
          );
        }
        return toAmount(0);
      },
      get totalChargesAmount() {
        if (this.shipmentChargesArray.length) {
          let prices = this.shipmentChargesArray.map((item) => {
            // case when carrier charge brokerage add as minus
            return item.charge_name === CARRIER_CHARGE_BROKERAGE ? -Number(item.price) : Number(item.price);
          });
          let sum = prices.reduce(function (accumulator, current) {
            return accumulator + current;
          }, 0);
          return toAmount(sum);
        }
        return toAmount(0);
      },
    };
  })
  .actions((self) => {
    return {
      setShipmentNoteToBeEdited(note) {
        self.shipmentNoteToBeEdited = note;
      },
      setIsAddShipmentNoteModalOpened(value) {
        self.isAddShipmentNoteModalOpened = value;
      },
      setNewShipmentNote(shipment_note) {
        self.newShipmentNote = shipment_note;
      },
      setShipmentNoteToBeDeleted(note_id) {
        self.shipmentNoteToBeDeleted = note_id;
      },
      clearShipmentNotes() {
        self.shipment_notes.clear();
      },
      setCarrierNotes(notes) {
        self.carrier_notes = notes;
      },
      setReceiverNotes(notes) {
        self.receiver_notes = notes;
      },
      setShipperNotes(notes) {
        self.shipper_notes = notes;
      },
      setCustomerNote(note) {
        self.customer_notes = note;
      },
      setShipmentNotes(items) {
        this.clearShipmentNotes();
        items.forEach((item) => {
          self.shipment_notes.put({
            ...item,
          });
        });
      },
      setShipmentSize(size) {
        self.shipment_size = size;
      },
      setClientData(data) {
        self.client_data = data;
      },
      setCarriersListCurrentPage(value) {
        self.carriersListCurrentPage = value;
        this.setSelectedEquipment(self.selectedEquipment, value);
      },
      setPlannerDataLocalPickupValue(value) {
        self.plannerDataLocalPickup = value;
      },
      setPlannerDataLocalDeliveryValue(value) {
        self.plannerDataLocalDelivery = value;
      },
      getShipmentCarriersColumns() {
        return Object.values(shipmentCarriersTableColumnsFunc(getRoot(self)));
      },
      setCarrierListSearchTerm(value) {
        self.carrierListSearchTerm = value;
      },
      setCarrierModalOpen() {
        self.isCarriersModalOpened = !self.isCarriersModalOpened;
      },
      setDocumentToBeDeleted(documentId) {
        self.documentToBeDeleted = documentId;
      },
      deleteDocument(id) {
        setFetchingInProgress(true);
        deleteShipmentDocument(self.shipment_id, id)
          .then(() => {
            this.setDocumentToBeDeleted(null);
            this.getDocuments();
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_DOCUMENT_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setShipmentChargeToBeDeleted(chargeId) {
        self.shipmentChargeToBeDeleted = chargeId;
      },
      deleteShipmentCharge(charge) {
        setFetchingInProgress(true);
        if (!charge.isNew) {
          deleteShipmentRelatedCharge(
            self.shipment_id,
            charge.shipment_charge_id
          )
            .then(() => {
              this.setShipmentChargeToBeDeleted(null);
              this.removeShipmentChargeItem(charge.shipment_charge_id);
              setFetchingInProgress(false);
            })
            .catch((error) => {
              console.log(error);
              const errorText = getErrorText(error);
              notification.error({
                message: DELETE_DOCUMENT_ERROR,
                description: errorText,
              });
              setFetchingInProgress(false);
            });
        } else {
          this.setShipmentChargeToBeDeleted(null);
          this.removeShipmentChargeItem(charge.shipment_charge_id);
          setFetchingInProgress(false);
        }
      },
      setShipmentCharges(items) {
        self.shipmentCharges.clear();
        items.forEach((item) => {
          self.shipmentCharges.put({
            ...item,
          });
        });
      },
      removeShipmentChargeItem(id) {
        self.shipmentCharges.delete(id);
      },
      addNewShipmentCharge(item) {
        item.shipment_charge_id = getRandomInt();
        item.isNew = true;
        self.shipmentCharges.put({
          ...item,
        });
      },
      getDocumentsColumns() {
        return Object.values(documentsColumnsFunc(getRoot(self)));
      },
      getNotesColumns() {
        return Object.values(notesColumnsFunc(getRoot(self)));
      },
      getDocuments() {
        setFetchingInProgress(true);
        getShipmentDocuments(self.shipment_id)
          .then((response) => {
            this.setDocuments(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_SHIPMENT_DOCUMENTS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDocuments(items) {
        self.documents.clear();
        items.forEach((item) => {
          item.uid = item.document_id;
          item.name = item.document_name;
          item.url = item.document_url;
          self.documents.put({
            ...item,
          });
        });
      },
      setAvailableShipmentCharges(items) {
        self.availableShipmentCharges = items;
      },
      setAvailableEquipment(items) {
        self.availableEquipment.clear();
        items.forEach((item) => {
          self.availableEquipment.put({
            ...item,
          });
        });
      },
      setCarriersTotalItems(number) {
        self.carriersTotalItems = number;
      },
      async setSearchedEquipment(search) {
        setFetchingInProgress(true);
        await getCarriersWithEquipment(search)
          .then((response) => {
            this.setCarriersWithSelectedEquipment(response.data.carriers);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_CARRIERS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setSelectedEquipment(id) {
        setFetchingInProgress(true);
        this.setSelectedCarrier(null);
        if (!id) {
          self.selectedEquipment = null;
        } else {
          self.selectedEquipment = id;
        }
        if (self.is_brokerage_shipment) {
          getCarriersWithEquipment(id, self.carriersListCurrentPage)
            .then((response) => {
              this.setCarriersTotalItems(response.data.total);
              this.setCarriersWithSelectedEquipment(response.data.carriers);
              setFetchingInProgress(false);
            })
            .catch((error) => {
              const errorText = getErrorText(error);
              notification.error({
                message: FETCH_CARRIERS_ERROR,
                description: errorText,
              });
              setFetchingInProgress(false);
            });
        } else {
          setFetchingInProgress(false);
        }
      },

      // async setSelectedEquipment(id) {
      //   this.setSelectedCarrier(null);
      //   self.selectedEquipment = id;
      //   if (self.is_brokerage_shipment) {
      //     await this.getCarriersWithSelectedEquipment();
      //   }
      // },
      // async getCarriersWithSelectedEquipment() {
      //   setFetchingInProgress(true);
      //   this.setSelectedCarrier(null);
      //   await getCarriersWithEquipment(self.selectedEquipment.equipment_id)
      //     .then((response) => {
      //       this.setCarriersWithSelectedEquipment(response.data.carriers);
      //       setFetchingInProgress(false);
      //     })
      //     .catch((error) => {
      //       const errorText = getErrorText(error);
      //       notification.error({
      //         message: FETCH_CARRIERS_ERROR,
      //         description: errorText,
      //       });
      //       setFetchingInProgress(false);
      //     });
      // },
      setClearCarrierEquipment() {
        self.carriersWithEquipment.clear();
      },
      setCarriersWithSelectedEquipment(items) {
        self.carriersWithEquipment.clear();
        items.forEach((item) => {
          self.carriersWithEquipment.put({
            ...item,
          });
        });
      },
      setSelectedCarrier(id) {
        self.selectedCarrier = id;
      },
      setCurrencyTypes(items) {
        self.currencyTypes = items;
      },
      uploadDocument(fileType, documentToUpload) {
        setFetchingInProgress(true);
        const formData = new FormData();
        formData.append('file', documentToUpload.originFileObj);
        uploadShipmentDocument(
          self.shipment_id,
          fileType.document_type_id,
          formData
        )
          .then(() => {
            this.getDocuments();
            this.setAddDocumentModalOpen(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: UPLOAD_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      async getAvailableDocumentTypes() {
        setFetchingInProgress(true);
        await getShipmentSpecifics(SHIPMENT_DOCUMENT_TYPE)
          .then((response) => {
            this.setAvailableDocumentTypes(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_SHIPMENT_SPECIFICS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableDocumentTypes(items) {
        self.availableDocumentTypes = items;
      },
      setAddDocumentModalOpen(status) {
        self.addDocumentModalOpen = status;
      },
      openAddDocumentModal() {
        this.getAvailableDocumentTypes().then(() => {
          this.setAddDocumentModalOpen(true);
        });
      },
      setFullyFetched(status) {
        self.fullyFetched = status;
      },
      getEditedProbill(id) {
        setFetchingInProgress(true);
        getProbill(id)
          .then((response) => {
            this.setEditedProbill(response.data);
            getProbillTimeTypes()
              .then((response) => {
                self.editedProbill.setTimeTypes(response.data);
              })
              .catch((error) => {
                console.log('Error: ', error);
              });

            /**
             * Detect type
             * case Appointment - Time: only pickup time start | Date: start date === end date
             * case Time Frame - Time: pickup time start + end | Date: start + end
             * case 24/7 - Waiting for Danka
             * case Not assigned - Time: null (start & end) | Date: null (start & end)
             */
            self.editedProbill.setPickupTimeStartSelected(
              moment(self.editedProbill.scheduled_pickup_time_start).format(
                'HH:mm'
              )
            );
            self.editedProbill.setPickupTimeEndSelected(
              moment(self.editedProbill.scheduled_pickup_time_end).format(
                'HH:mm'
              )
            );
            self.editedProbill.setPickupDateStartSelected(
              moment(self.editedProbill.scheduled_pickup_time_start).format(
                'YYYY-MM-DD'
              )
            );
            self.editedProbill.setPickupDateEndSelected(
              moment(self.editedProbill.scheduled_pickup_time_end).format(
                'YYYY-MM-DD'
              )
            );
            self.editedProbill.setDeliveryTimeStartSelected(
              moment(self.editedProbill.scheduled_delivery_time_start).format(
                'HH:mm'
              )
            );
            self.editedProbill.setDeliveryTimeEndSelected(
              moment(self.editedProbill.scheduled_delivery_time_end).format(
                'HH:mm'
              )
            );
            self.editedProbill.setDeliveryDateStartSelected(
              moment(self.editedProbill.scheduled_delivery_time_start).format(
                'YYYY-MM-DD'
              )
            );
            self.editedProbill.setDeliveryDateEndSelected(
              moment(self.editedProbill.scheduled_delivery_time_end).format(
                'YYYY-MM-DD'
              )
            );

            let promises = [];
            promises.push(shipperLocations(self.editedProbill));
            promises.push(receiverLocations(self.editedProbill));
            allSettled(promises).then(() => {
              self.editedProbill.setFullyFetched(true);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_PROBILL_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setEditedProbill(probill) {
        self.editedProbill = probill;
      },
      setProbillToBeDeleted(probillId) {
        self.probillToBeDeleted = probillId;
      },
      getProbills() {
        setFetchingInProgress(true);
        getShipmentProbills(self.shipment_id)
          .then((probills) => {
            this.setProbills(probills.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_PROBILL_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setProbills(items) {
        this.clearProbills();
        items.forEach((item) => {
          self.probills.put({
            ...item,
          });
        });
      },
      clearProbills() {
        self.probills.clear();
      },
      setIsBrokerageShipment(status) {
        self.is_brokerage_shipment = status;
      },
      setShipmentBy(number) {
        self.shipment_by = number;
      },
      setBillTo(number) {
        self.bill_to = number;
      },
      setPioNumber(text) {
        self.pio_number = text;
      },
      setBrokerPhone(text) {
        self.broker_phone = text;
      },
      setBrokerName(text) {
        self.broker_name = text;
      },
      setStatus(status) {
        self.status = status;
      },
      getProbillsColumns() {
        return Object.values(probillsColumnsFunc(getRoot(self)));
      },
      setNewProbill(probill) {
        self.newProbill = probill;
        if (probill) {
          self.newProbill.getTimeTypes();
          self.newProbill.getCountriesList();
        }
      },
      removeProbill(id) {
        self.probills.delete(id);
      },
      deleteProbill(id) {
        setFetchingInProgress(true);
        deleteProbill(id)
          .then(() => {
            this.setProbillToBeDeleted(null);
            this.removeProbill(id);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_PROBILL_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setErrorText(text) {
        self.errorText = text;
      },
      throwSaveShipmentError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateShipmentCharges() {
        let passed = true;
        self.shipmentChargesArray.forEach((element) => {
          if (!element.currency || !element.charge_id || !element.price) {
            passed = false;
            return;
          }
        });
        return passed;
      },
      setPickupDelivery(data) {
        setFetchingInProgress(true);
        setLocalPD(data)
          .then((response) => {
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('response: ', error);
            setFetchingInProgress(false);
          });
      },
      validateForm() {
        if (!self.shipment_by) {
          this.setErrorText(CUSTOMER_NAME_REQUIRED);
        } else if (!self.bill_to) {
          this.setErrorText(CUSTOMER_NAME_REQUIRED);
        } else if (!self.pio_number) {
          this.setErrorText(PO_NUMBER_REQUIRED);
        } else if (!self.status) {
          this.setErrorText(STATUS_REQUIRED);
        } else if (!this.validateShipmentCharges()) {
          this.setErrorText(ALL_CHARGE_ITEMS_FIELDS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      saveShipment() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          let newCharges = self.shipmentChargesArray.filter((item) => {
            // @ts-ignore
            return item.isNew;
          });
          let editedCharges = self.shipmentChargesArray.filter((item) => {
            // @ts-ignore
            return item.isChangedDuringEdit;
          });
          let promises = [];
          promises.push(editShipmentPromise(self));
          promises.push(addShipmentChargesPromise(self, newCharges));
          editedCharges.forEach((item) => {
            promises.push(
              editShipmentRelatedChargePromise(self.shipment_id, item)
            );
          });
          allSettled(promises)
            .then(() => {
              setFetchingInProgress(false);
              self.isCreateShipmentPage &&
                rootInstance.shipmentsPageState.setCreateShipmentSuccessModalOpen(
                  true
                );
              self.isEditShipmentPage &&
                rootInstance.shipmentsPageState.setEditShipmentSuccessModalOpen(
                  true
                );
            })
            .catch((error) => {
              this.throwSaveShipmentError(error);
            });
        }
      },
    };
  });
