import React from 'react';
import { observer } from 'mobx-react';
import ShipmentsComponent from '../../components/brokerage/Brokerage/Shipments/ShipmentsComponent';
import CustomersComponent from '../../components/brokerage/Brokerage/Customers/CustomersComponent';
import LocationsComponent from '../../components/brokerage/Brokerage/Locations/LocationsComponent';
import CarriersComponent from '../../components/brokerage/Brokerage/Carriers/CarriersComponent';
import OtherComponent from '../../components/brokerage/Brokerage/Other/OtherComponent';
import { Tabs } from 'antd';
import {
  CUSTOMERS,
  DELETE_BROKERAGE_MODAL_SUCCESS_BUTTON_TEXT,
  DELETE_CUSTOMER_MODAL_SUCCESS_TEXT,
  DELETE_LOCATION_MODAL_SUCCESS_TEXT,
  DELETE_MODAL_SUCCESS_TITLE,
  DELETE_SHIPMENT_MODAL_SUCCESS_TEXT,
  LOCATIONS,
  SHIPMENTS,
  CARRIERS,
  DELETE_CARRIER_MODAL_SUCCESS_TEXT,
  OTHER,
} from '../../constants';
import { useMst } from '../../state/RootModel';
import ConfirmDeleteCustomerModal from '../../components/brokerage/Brokerage/Customers/ConfirmDeleteCustomerModal';
import ConfirmDeleteShipmentModal from '../../components/brokerage/Brokerage/Shipments/ConfirmDeleteShipmentModal';
import ConfirmDeleteLocationModal from '../../components/brokerage/Brokerage/Locations/ConfirmDeleteLocationModal';
import ConfirmDeleteCarrierModal from '../../components/brokerage/Brokerage/Carriers/ConfirmDeleteCarrierModal';
import SuccessModal from '../../components/SuccessModal';
import PageRoutes from '../../utils/PageRoutes';

const { TabPane } = Tabs;

const BrokeragePage = () => {
  const {
    brokeragePageState: { setActiveTab, activeTab },
    clientsPageState: {
      successDeleteCustomerModalOpen,
      setDeleteCustomerSuccessModalOpen,
    },
    shipmentsPageState: {
      successDeleteShipmentModalOpen,
      setDeleteShipmentSuccessModalOpen,
    },
    locationsPageState: {
      successDeleteLocationModalOpen,
      setDeleteLocationSuccessModalOpen,
    },
    carriersPageState: {
      successDeleteCarrierModalOpen,
      setDeleteCarrierSuccessModalOpen,
    },
    userState: {
      canViewShipments,
      canViewClients,
      canViewLocations,
      canViewCarriers,
    },
  } = useMst();

  const backLink = PageRoutes.Brokerage.name;

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  return (
    <div className="page brokerage-page">
      <div className="page-content pv20">
        <Tabs activeKey={activeTab} onChange={onTabChange}>
          {canViewShipments && (
            <TabPane tab={SHIPMENTS} key={SHIPMENTS}>
              <ShipmentsComponent />
            </TabPane>
          )}
          {canViewClients && (
            <TabPane tab={CUSTOMERS} key={CUSTOMERS}>
              <CustomersComponent />
            </TabPane>
          )}
          {canViewLocations && (
            <TabPane tab={LOCATIONS} key={LOCATIONS}>
              <LocationsComponent />
            </TabPane>
          )}
          {canViewCarriers && (
            <TabPane tab={CARRIERS} key={CARRIERS}>
              <CarriersComponent />
            </TabPane>
          )}
          <TabPane tab={OTHER} key={OTHER}>
            <OtherComponent />
          </TabPane>
        </Tabs>
      </div>
      <ConfirmDeleteCustomerModal />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_CUSTOMER_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteCustomerModalOpen}
        buttonText={DELETE_BROKERAGE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setDeleteCustomerSuccessModalOpen(false)}
      />

      <ConfirmDeleteShipmentModal />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_SHIPMENT_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteShipmentModalOpen}
        buttonText={DELETE_BROKERAGE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setDeleteShipmentSuccessModalOpen(false)}
      />

      <ConfirmDeleteLocationModal />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_LOCATION_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteLocationModalOpen}
        buttonText={DELETE_BROKERAGE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setDeleteLocationSuccessModalOpen(false)}
      />

      <ConfirmDeleteCarrierModal />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_CARRIER_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteCarrierModalOpen}
        buttonText={DELETE_BROKERAGE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setDeleteCarrierSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(BrokeragePage);
