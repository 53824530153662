import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { useMst } from '../../state/RootModel';
import {
  EMPTY_EMPLOYEES_TABLE_TEXT,
  DEPARTMENT_NOT_ASSIGNED,
} from '../../constants';
import PageRoutes from '../../utils/PageRoutes';

const EmployeesTable = (props) => {
  const department = { ...props.department };

  const {
    employeesPageState: {
      getEmployeesColumns,
      departments,
      selectedEmployeesArrayIds,
      selectedEmployees,
      setSelectedEmployees,
      removeFromSelectedEmployees,
      filteredEmployeesWithNoDepartmentArray,
    },
    loaderState: { fetchingInProgress },
    router,
  } = useMst();

  const currentDepartment = departments.get(department.department_id);

  const rowSelection = {
    selectedRowKeys: selectedEmployeesArrayIds,
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      const newSelection = [];
      if (!selected) {
        removeFromSelectedEmployees(record.user_id);
      } else if (selected) {
        newSelection.push(record);
      }
      selectedEmployees.forEach((user) => {
        newSelection.push(user);
      });
      setSelectedEmployees(newSelection);
    },
    columnWidth: 16,
  };

  const onRowClick = (e, record) => {
    e.stopPropagation();
    const params = { employeeId: record.user_id };
    router.setView(router.views.get(PageRoutes.ViewEmployee.name), params);
  };

  return (
    !fetchingInProgress && (
      <div>
        {!currentDepartment && (
          <div className="subsection-header">
            <p>{DEPARTMENT_NOT_ASSIGNED}</p>
          </div>
        )}
        <Table
          className="employees-table"
          rowKey={'user_id'}
          rowSelection={rowSelection}
          columns={getEmployeesColumns()}
          dataSource={
            currentDepartment
              ? currentDepartment.employeesArray
              : filteredEmployeesWithNoDepartmentArray
          }
          pagination={false}
          showHeader={false}
          onRow={(r) => ({
            onClick: (e) => onRowClick(e, r),
          })}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_EMPLOYEES_TABLE_TEXT}
              />
            ),
          }}
        />
      </div>
    )
  );
};

export default observer(EmployeesTable);
