import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { DELIVERY_LOCATION, PICKUP_LOCATION } from '../../../constants';

const DispatchTourDetails = () => {
  const {
    dispatchPageState: { shipmentPickupLocations, shipmentDeliveryLocations },
  } = useMst();

  return (
    <div className="dispatch-map-organize-tour__details ph10">
      <div className="details-top pl40 pb10">
        <p className="headline mb5 dispatch-map-marker__type pickup">
          {shipmentPickupLocations[0]?.location_name} ({PICKUP_LOCATION})
        </p>
        {shipmentPickupLocations[0]?.address && (
          <p className="sub-headline">
            <span className="flex">{shipmentPickupLocations[0]?.address}</span>
          </p>
        )}
      </div>
      <div className="has-bl mb30"></div>
      <div className="details-bottom pl40 pb10 mb20 has-bl">
        <p className="headline mb5 dispatch-map-marker__type delivery">
          {shipmentDeliveryLocations[0]?.location_name} ({DELIVERY_LOCATION})
        </p>
        {shipmentDeliveryLocations[0]?.address && (
          <p className="sub-headline">
            <span className="flex">
              {shipmentDeliveryLocations[0]?.address}
            </span>
          </p>
        )}
      </div>
    </div>
  );
};
export default observer(DispatchTourDetails);
