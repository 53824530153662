import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Select, Radio } from 'antd';
import questionMark from '../../../assets/images/question-mark.svg';
import {
  ADVANCED_OPTIONS,
  USER_TYPE,
  ACTIVE_ROLE,
  DEPARTMENT,
  CHOOSE_DEPARTMENT_TEXT,
  CHOOSE_DEPARTMENTS_CURRENT_USER_TEXT,
  WORKING_STATUS,
  NO_ROLES_ASSIGNED,
  NO_ROLE_SELECTED,
} from '../../../constants';

const { Option } = Select;

const AdvancedOptions = () => {
  const {
    employeesPageState: {
      editedEmployee,
      departmentsArray,
      employeeRolesArray,
      workingStatuses,
    },
    loginForm: { currentUser },
    userState: {
      canAddUserRoles,
      canRemoveUserRoles,
      canViewUserRoles,
      canAddUserActiveRoles,
    },
  } = useMst();

  const employee = editedEmployee
    ? editedEmployee
    : currentUser
    ? currentUser
    : null;

  const onCheckedDepartment = (e) => {
    employee.setDepartment(e.target.value);
  };

  const onSelectUserRole = (value) => {
    if (employee.user_assigned_roles.length > value.length) {
      if (canRemoveUserRoles) {
        employee.setUserAssignedRoles(value);
      }
      return false;
    } else if (canAddUserRoles) {
      employee.setUserAssignedRoles(value);
    }
    return false;
  };

  const onSelectCurrentUserRole = (value) => {
    const role = employee.user_assigned_roles.find((role) => {
      return role.role_id === value;
    });
    employee.setActiveRole(role);
  };

  // const onSelectAccountStatus = (value) => {
  //   employee.setAccountStatus(value);
  // };

  return (
    <div className="form-page-section">
      <div className="table-title-row">
        <span className="color-primary text-medium ml10">
          {ADVANCED_OPTIONS}
        </span>
      </div>
      {currentUser ? (
        <div className="section-content align-center">
          <div className="input-wrapper ml0">
            <div className="input-label justify-between">
              <span>{ACTIVE_ROLE}</span>
              <span className="color-secondary">*</span>
            </div>
            <Select
              className="w100 text-capitalize"
              onChange={onSelectCurrentUserRole}
              placeholder={
                !employee.user_assigned_roles.length && NO_ROLE_SELECTED
              }
              value={employee.active_role && employee.active_role.role_id}
              allowClear={canRemoveUserRoles}
              dropdownClassName={
                canAddUserActiveRoles
                  ? 'ant-select-dropdown'
                  : 'ant-select-dropdown-hidden'
              }
            >
              {employee.user_assigned_roles.map((role) => (
                <Option
                  key={'active_role' + role.role_id}
                  value={role.role_id}
                  className="text-capitalize"
                >
                  {role.role_name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      ) : (
        canViewUserRoles && (
          <div className="section-content align-center">
            <div className="input-wrapper ml0">
              <div className="input-label justify-between">
                <span>{USER_TYPE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                mode={'multiple'}
                className="w100 text-capitalize"
                onChange={onSelectUserRole}
                placeholder={
                  !employee.user_assigned_roles.length && NO_ROLES_ASSIGNED
                }
                value={employee.user_assigned_roles}
                allowClear={canRemoveUserRoles}
                dropdownClassName={
                  canAddUserRoles
                    ? 'ant-select-dropdown'
                    : 'ant-select-dropdown-hidden'
                }
              >
                {employeeRolesArray.map((role) => (
                  <Option
                    key={role.role_id}
                    value={role.role_id}
                    className="text-capitalize"
                  >
                    {role.role_name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="checkbox-wrapper ml20">
              <div className="input-label">
                <span>{DEPARTMENT}</span>
                <img src={questionMark} alt="question mark" className="ml20" />
                <span className="ml10 color-primary text-regular text-italic">
                  {currentUser
                    ? CHOOSE_DEPARTMENTS_CURRENT_USER_TEXT
                    : CHOOSE_DEPARTMENT_TEXT}
                </span>
                <span className="ml10 color-secondary">*</span>
              </div>
              <Radio.Group
                value={employee.user_department ? employee.user_department : 0}
                onChange={onCheckedDepartment}
                className="mt10 text-capitalize"
              >
                {departmentsArray.map((department) => (
                  <Radio
                    key={department.department_id}
                    value={department.department_id}
                  >
                    {department.department_name}
                  </Radio>
                ))}
                <Radio key={0} value={0}>
                  {'None'}
                </Radio>
              </Radio.Group>
            </div>
            {/* <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ACCOUNT_STATUS}</span>
              </div>
              <Select
                className="w100 text-capitalize"
                onChange={onSelectAccountStatus}
                value={employee.account_status}
                disabled
              >
                {accountStatuses.map((status) => (
                  <Option key={status} value={status} className="text-capitalize">
                    {status}
                  </Option>
                ))}
              </Select>
            </div> */}
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{WORKING_STATUS}</span>
              </div>
              <Select
                className="w100 text-capitalize"
                value={employee.employeeWorkingStatus}
                disabled
              >
                {workingStatuses.map((status) => (
                  <Option
                    key={status}
                    value={status}
                    className="text-capitalize align-center"
                  >
                    <div className="align-center">
                      <div
                        className={`status-dot mr10 ${
                          employee.isEmployeeOff ? 'unavailable' : ''
                        }`}
                      />
                      <div>{status}</div>
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default observer(AdvancedOptions);
