import React from 'react';
import { useMst } from '../../../state/RootModel';
import { observer } from 'mobx-react';
import { DRIVER_COMPLIANCY, ADD_COMPLIANCY } from '../../../constants';
import CompliancesTable from './CompliancesTable';

const CompliancesSection = () => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  const onAddCompliancy = () => {
    editedDriver.createNewCompliancy();
  };

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <span className="text-capitalize color-primary text-medium ml10">
          {DRIVER_COMPLIANCY}
        </span>
        <button className="btn primary ml20 ph20 pv0" onClick={onAddCompliancy}>
          {ADD_COMPLIANCY}
        </button>
      </div>
      <CompliancesTable />
    </div>
  );
};

export default observer(CompliancesSection);
