import React from 'react';
import { useMst } from '../../state/RootModel';
import { observer } from 'mobx-react';
import AddDriverComponent from '../../components/fleet/AddDriver/AddDriverComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import SuccessModal from '../../components/SuccessModal';
import PageRoutes from '../../utils/PageRoutes';
import {
  ADD_DRIVER,
  MODAL_SUCCESS_ADD_DRIVER_TEXT,
  MODAL_SUCCESS_ADD_DRIVER_BUTTON_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../constants';

const AddDriverPage = () => {
  const {
    fleetPageState: { addDriverSuccessModalOpen, setAddDriverSuccessModalOpen },
  } = useMst();

  const backLink = PageRoutes.Fleet.name;

  return (
    <div>
      <div className="page add-driver-page">
        <BreadcrumsComponent
          page={ADD_DRIVER}
          breadcrumbsBackLink={PageRoutes.Fleet.name}
        />
        <SuccessModal
          title={MODAL_SUCCESS_TITLE}
          text={MODAL_SUCCESS_ADD_DRIVER_TEXT}
          buttonText={MODAL_SUCCESS_ADD_DRIVER_BUTTON_TEXT}
          backLink={backLink}
          open={addDriverSuccessModalOpen}
          closeModal={() => setAddDriverSuccessModalOpen(false)}
        />
        <div className="page-content">
          <AddDriverComponent />
        </div>
      </div>
    </div>
  );
};

export default observer(AddDriverPage);
