import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, Select, DatePicker } from 'antd';
import {
  GENERAL_INFORMATION,
  OWNERSHIP_TYPE,
  VIN,
  TYPE_OF_FINANCING,
  DATE_HIRED,
  NOTES,
} from '../../../constants';
import moment from 'moment';

const { Option } = Select;

const GeneralInformation = () => {
  const {
    fleetPageState: {
      newTrailer,
      vehicleOwnershipTypes,
      vehicleFinancingTypes,
    },
  } = useMst();

  const onSelectOwnershipType = (value) => {
    newTrailer.setOwnershipType(value);
  };

  const onSelectFinancingType = (value) => {
    newTrailer.setFinancingType(value);
  };

  const onDateHiredChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    newTrailer.setDateHired(date);
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current > moment().endOf('day');
  }

  return (
    <div className="add-trailer-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">{GENERAL_INFORMATION}</span>
      </div>

      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label justify-between">
            <span>{VIN}</span>
            <span className="color-secondary">*</span>
          </div>
          <Input
            onChange={(e) => {
              newTrailer.setVin(e.target.value);
            }}
            value={newTrailer.vin}
            className="add-edit-input"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{OWNERSHIP_TYPE}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectOwnershipType}
            value={newTrailer.ownership_type}
          >
            {vehicleOwnershipTypes.map((status, index) => (
              <Option key={index} value={status} className="text-capitalize">
                {status}
              </Option>
            ))}
          </Select>
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{TYPE_OF_FINANCING}</span>
            <span className="color-secondary">*</span>
          </div>
          <Select
            className="w100 text-capitalize"
            onChange={onSelectFinancingType}
            value={newTrailer.type_of_financing}
          >
            {vehicleFinancingTypes.map((financingType, index) => (
              <Option
                key={index}
                value={financingType}
                className="text-capitalize"
              >
                {financingType}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="section-content align-center pb20">
        <div className="input-wrapper ml0">
          <div className="input-label">
            <span>{DATE_HIRED}</span>
          </div>
          <DatePicker
            allowClear={false}
            onChange={onDateHiredChange}
            value={newTrailer.date_hired ? moment(newTrailer.date_hired) : null}
            disabledDate={disabledDate}
          />
        </div>
        <div className="input-wrapper">
          <div className="input-label justify-between">
            <span>{NOTES}</span>
          </div>
          <Input
            onChange={(e) => {
              newTrailer.setNotes(e.target.value);
            }}
            className="add-edit-input"
            value={newTrailer.notes}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(GeneralInformation);
