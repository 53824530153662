import React from 'react';
import { observer } from 'mobx-react';
import dropdownIcon from '../../assets/images/dropdown-icon.svg';
import organization from '../../assets/images/organization-black.svg';
import { DEPARTMENTS, MODAL_SUCCESS_TITLE, USER_ROLE } from '../../constants';
import { ADD_DEPARTMENT } from '../../constants/organization/departments';
import { ORGANIZATION } from '../../constants/menu';
import { EDIT_ORGANIZATION } from '../../constants/organizations/organizations';
import { rootInstance, useMst } from '../../state/RootModel';
import DepartmentTable from '../../components/organization/Departments/DepartmentTable';
import UserRoleTable from '../../components/organization/UserRole/UserRoleTable';
import AddOrganizationDepartmentModal from '../../components/organization/Departments/AddOrganizationDepartmentModal';
import EditOrganizationDepartmentModal from '../../components/organization/Departments/EditOrganizationDepartmentModal';
import DeleteOrganizationDepartmentModal from '../../components/organization/Departments/DeleteOrganizationDepartmentModal';
import DeleteUserRoleModal from '../../components/organization/UserRole/DeleteUserRoleModal';
import PageRoutes from '../../utils/PageRoutes';
import Link from '../../components/Link';
import SuccessModal from '../../components/SuccessModal';
import {
  MODAL_SUCCESS_CREATE_DEPARTMENT_TEXT,
  MODAL_SUCCESS_DEPARTMENT_BUTTON_TEXT,
} from '../../constants/organization/departments';
import { ADD_USER_ROLE } from '../../constants/organization/userRole';

const OrganizationSettingsPage = () => {
  const {
    currentOrganization: {
      setIsDepartmentsListOpened,
      isDepartmentsListOpened,
      setIsUserRolesListOpened,
      isUserRolesListOpened,
      setIsAddNewDepartmentModalOpened,
      organization_name,
      isDepartmentSuccessModalOpen,
      setIsDepartmentSuccessModalOpen,
      newDepartment,
    },
    userState: { canEditOrganization, canAddNewDepartment, canAddUserRoles },
  } = useMst();
  const onOpenDepartmentsList = (e) => {
    e.stopPropagation();
    setIsDepartmentsListOpened(!isDepartmentsListOpened);
  };
  const onOpenUserRolesList = (e) => {
    e.stopPropagation();
    setIsUserRolesListOpened(!isUserRolesListOpened);
  };

  const onOrganizationEdit = (e) => {
    e.preventDefault();
    const organization_id = JSON.parse(
      localStorage.getItem('user')
    ).user_organization_id;
    const params = { organizationId: organization_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.EditOrganization.name),
      params
    );
  };
  const onAddDepartment = (e) => {
    e.preventDefault();
    setIsAddNewDepartmentModalOpened(true);
    newDepartment.clearForm();
  };

  const onCloseDepartmentSuccessModal = () => {
    setIsAddNewDepartmentModalOpened(false);
    setIsDepartmentSuccessModalOpen(false);
  };

  const backLink = PageRoutes.Organization.name;

  return (
    <div className="page">
      <div className="page-content">
        <div className="page-header-section">
          <div className="table-header mb20 mt20 align-center">
            <div>
              <img src={organization} alt="Organization" />
              <span className="ml10 color-title">{ORGANIZATION}</span>
            </div>
            <h1 className="text-capitalize text-bold color-primary ml10">
              {organization_name}
            </h1>
            {canEditOrganization && (
              <button
                type="button"
                className="btn light ml-a"
                onClick={(e) => onOrganizationEdit(e)}
              >
                {EDIT_ORGANIZATION}
              </button>
            )}
          </div>
        </div>
        <div className="single-organization-section">
          <div className="table-title-row align-center">
            <div
              className="pointer align-center"
              onClick={(e) => onOpenDepartmentsList(e)}
            >
              <img
                src={dropdownIcon}
                alt="dropdown"
                className={`dropdown-icon ${
                  isDepartmentsListOpened ? 'open' : ''
                }`}
                height="14"
              />
              <span className="text-capitalize color-primary text-medium ml10">
                {DEPARTMENTS}
              </span>
            </div>
            {canAddNewDepartment && (
              <button
                type="button"
                className="btn light add ml30"
                onClick={(e) => onAddDepartment(e)}
              >
                {ADD_DEPARTMENT}
              </button>
            )}
          </div>
          {isDepartmentsListOpened && (
            <DepartmentTable
              permittedTo={[
                'Admin',
                'Can view departments',
                'Super Admin permissions',
              ]}
            />
          )}
        </div>
        <div className="single-organization-section">
          <div className="table-title-row align-center">
            <div
              className="pointer align-center"
              onClick={(e) => onOpenUserRolesList(e)}
            >
              <img
                src={dropdownIcon}
                alt="dropdown"
                className={`dropdown-icon ${
                  isUserRolesListOpened ? 'open' : ''
                }`}
                height="14"
              />
              <span className="text-capitalize color-primary text-medium ml10">
                {USER_ROLE}
              </span>
            </div>
            {canAddUserRoles && (
              <Link
                className="btn light add ml30"
                view={PageRoutes.AddUserRole.name}
              >
                <span>{ADD_USER_ROLE}</span>
              </Link>
            )}
          </div>
          {isUserRolesListOpened && <UserRoleTable />}
        </div>
      </div>
      <AddOrganizationDepartmentModal />
      <EditOrganizationDepartmentModal />
      <DeleteOrganizationDepartmentModal />
      <DeleteUserRoleModal />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_CREATE_DEPARTMENT_TEXT}
        buttonText={MODAL_SUCCESS_DEPARTMENT_BUTTON_TEXT}
        backLink={backLink}
        open={isDepartmentSuccessModalOpen}
        closeModal={() => onCloseDepartmentSuccessModal()}
      />
    </div>
  );
};

export default observer(OrganizationSettingsPage);
