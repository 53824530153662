import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { EMPTY_REGISTRATION_TABLE_TEXT } from '../../../../constants';

const ViewRegistrationTable = ({ model }) => {
  return (
    model.vehicleFullyFetched && (
      <Table
        className="mb20"
        rowKey={'vehicle_registration_id'}
        columns={model.getRegistrationsColumns()}
        dataSource={model.registrationsArray}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_REGISTRATION_TABLE_TEXT}
            />
          ),
        }}
      />
    )
  );
};

export default observer(ViewRegistrationTable);
