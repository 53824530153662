import { types } from 'mobx-state-tree';
import { SideMenuItemModel } from './SideMenuItemModel';
import { sideMenuItems } from './SideMenuItems';

export const SideMenuModel = types
  .model('SideMenu', {
    menuItems: types.map(SideMenuItemModel),
  })
  .views((self) => {
    return {
      get menuItemsArray() {
        let menuItems = Array.from(self.menuItems.values());
        return menuItems;
      },
      get activeMenuItem() {
        const activeItem = this.menuItemsArray.find((item) => item.active);
        return activeItem;
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        this.setItems(sideMenuItems);
      },
      setItems(items) {
        Object.values(items).forEach((item) => {
          self.menuItems.put(item);
        });
      },
      setActiveItem(title) {
        if (title) {
          const selectedItem = self.menuItemsArray.find((item) => {
            return item.title === title;
          });
          selectedItem.setActive(true);
        } else {
          self.menuItemsArray.forEach((item) => {
            item.setActive(false);
          });
        }
      },
      closeAllDescriptionBoxes() {
        self.menuItemsArray.forEach((item) => {
          item.closeDescription();
        });
      },
    };
  });
