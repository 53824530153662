import { notification } from 'antd';
import { types, getRoot } from 'mobx-state-tree';
import {
  deleteLocation,
  getLocation,
  getLocations,
} from '../../actions/locations';
import { DELETE_LOCATION_ERROR, FETCH_LOCATIONS_ERROR } from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { LocationLightModel } from './LocationLightModel';
import { LocationModel } from './LocationModel';
import { locationsColumnsFunc } from './LocationsTableColumns';
import { getAllCountries } from '../../actions/common';

export const LocationsPageModel = types
  .model('LocationsPageModel', {
    locations: types.map(LocationModel),
    searchTerm: types.maybe(types.maybeNull(types.string)),
    currentPage: 1,
    pageSize: 10,
    totalItems: types.maybe(types.maybeNull(types.number)),

    newLocation: types.maybe(types.maybeNull(LocationLightModel)),
    draggableEditedLocation: types.maybe(types.maybeNull(LocationModel)),
    addLocationSuccessModalOpen: false,

    editedLocation: types.maybe(types.maybeNull(LocationModel)),
    editLocationSuccessModalOpen: false,

    // delete location
    locationToBeDeleted: types.maybeNull(types.reference(LocationModel)),
    confirmDeleteLocationModalOpen: false,
    successDeleteLocationModalOpen: false,
    // end delete location

    countries: types.frozen([]),
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get countriesArray() {
        return Array.from(self.countries.values());
      },
      get filteredStates() {
        const locationType = self.newLocation
          ? self.newLocation
          : self.editedLocation;
        const selectedCountry = locationType.country;
        return this.countriesArray.find((country) => {
          return country.name === selectedCountry;
        });
      },
      get locationsArray() {
        return Array.from(self.locations.values());
      },
      get mapLocation() {
        if (self.newLocation) {
          return self.newLocation;
        } else if (self.editedLocation) {
          return self.editedLocation;
        }
        return null;
      },
    };
  })
  .actions((self) => {
    return {
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      getEditedLocation(id) {
        setFetchingInProgress(true);
        this.getCountriesList();
        getLocation(id)
          .then((response) => {
            this.setEditedLocation(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setEditedLocation(location) {
        self.editedLocation = location;
      },
      setEditLocationSuccessModalOpen(status) {
        self.editLocationSuccessModalOpen = status;
      },
      setSearchTerm(text) {
        self.searchTerm = text;
      },
      setCurrentPage(number) {
        self.currentPage = number;
      },
      setTotalItems(number) {
        self.totalItems = number;
      },
      setAddLocationSuccessModalOpen(status) {
        self.addLocationSuccessModalOpen = status;
      },
      setNewLocation(location) {
        this.getCountriesList();
        self.newLocation = location;
      },
      getLocations({ page = null }) {
        setFetchingInProgress(true);
        this.clearLocations();
        // if page is specified in request, set current page to page requested
        page && this.setCurrentPage(page);
        getLocations(self.searchTerm, self.currentPage, self.pageSize)
          .then((response) => {
            this.setTotalItems(response.data.total);
            this.setLocations(response.data.locations);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setLocations(items) {
        self.locations.clear();
        items.forEach((item) => {
          self.locations.put({
            ...item,
          });
        });
      },
      clearLocations() {
        self.locations.clear();
      },
      getDraggableEditedLocation(id) {
        setFetchingInProgress(true);
        getLocation(id)
          .then((response) => {
            this.setDraggableEditedLocation(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDraggableEditedLocation(location) {
        self.draggableEditedLocation = location;
      },
      getLocationsColumns() {
        return Object.values(locationsColumnsFunc(getRoot(self)));
      },
      // delete location
      setLocationToBeDeleted(location_id) {
        self.locationToBeDeleted = null;
        if (location_id) {
          this.setConfirmDeleteLocationModalOpen(true);
        }
        self.locationToBeDeleted = location_id;
      },
      setConfirmDeleteLocationModalOpen(status) {
        if (!status) {
          this.setLocationToBeDeleted(null);
        }
        self.confirmDeleteLocationModalOpen = status;
      },
      deleteLocation() {
        setFetchingInProgress(true);
        deleteLocation(self.locationToBeDeleted.location_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteLocationModalOpen(false);
            this.setDeleteLocationSuccessModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_LOCATION_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDeleteLocationSuccessModalOpen(status) {
        self.successDeleteLocationModalOpen = status;
      },
      // end delete location
    };
  });
