import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty, Pagination } from 'antd';
import { rootInstance, useMst } from '../../../../state/RootModel';
import { EMPTY_SHIPMENTS_TABLE_TEXT } from '../../../../constants';
import PageRoutes from '../../../../utils/PageRoutes';

const ShipmentsTable = (props) => {
  const tableClass = props.tableClassName;
  const {
    shipmentsPageState: {
      getShipmentsColumns,
      shipmentsArray,
      getShipments,
      totalItems,
      currentPage,
      pageSize,
    },
  } = useMst();

  const onRowClick = (e, record) => {
    e.stopPropagation();
    const params = { shipmentId: record.shipment_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.ViewShipment.name),
      params
    );
  };

  const paginationChange = (val) => {
    getShipments({ page: val });
  };

  return (
    <div>
      <Table
        className={`${tableClass} fleet-table`}
        rowKey={'shipment_id'}
        columns={getShipmentsColumns()}
        dataSource={shipmentsArray}
        pagination={false}
        onRow={(r) => ({
          onClick: (e) => onRowClick(e, r),
        })}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_SHIPMENTS_TABLE_TEXT}
            />
          ),
        }}
      />
      <div className="table-footer">
        <Pagination
          onChange={paginationChange}
          total={totalItems}
          current={currentPage}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default observer(ShipmentsTable);
