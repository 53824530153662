import React from 'react';
import { observer } from 'mobx-react';
import { Input, Icon, Form } from 'antd';
import { useMst } from '../../state/RootModel';
import {
  FORGOT_PASSWORD,
  ENTER_EMAIL_FORGOT_PASSWORD_TEXT,
  CONTINUE,
  EMAIL_INPUT_PLACEHOLDER,
} from '../../constants';
import LoginErrorBox from './LoginErrorBox';
import LoginInfoBox from './LoginInfoBox';
import back from '../../assets/images/arrow-back.svg';

const ForgotPasswordComponent = () => {
  const loginForm = useMst().loginForm;

  const onBackClick = (e) => {
    e.preventDefault();
    if (!loginForm.loading) {
      loginForm.setStep(1);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    loginForm.onForgotPassword();
  };

  return (
    <div className="login-content">
      <div className="form-content">
        <div className="align-center">
          <img
            src={back}
            alt="back"
            onClick={onBackClick}
            className={`mr10 pointer ${loginForm.loading ? 'disabled' : null}`}
          />
          <h3 className="title mb0">{FORGOT_PASSWORD}</h3>
        </div>
        <p className="mb10">{ENTER_EMAIL_FORGOT_PASSWORD_TEXT}</p>
        <Form onSubmit={onSubmit} className="mt20">
          <Form.Item>
            <Input
              onChange={(e) => loginForm.setUsername(e.target.value)}
              placeholder={EMAIL_INPUT_PLACEHOLDER}
              value={loginForm.username}
              className="login-input"
              required
            />
          </Form.Item>
          <div className="login-box">
            {loginForm.errorText && <LoginErrorBox />}
            {loginForm.infoText && <LoginInfoBox />}
          </div>
          <button
            className={`btn secondary login-btn mt20 ${
              loginForm.loading && 'disabled'
            }`}
            type={'submit'}
            disabled={loginForm.loading}
          >
            {loginForm.loading && (
              <span className="mr10">
                <Icon type="loading" />
              </span>
            )}
            {CONTINUE}
          </button>
        </Form>
      </div>
    </div>
  );
};

export default observer(ForgotPasswordComponent);
