import { types } from 'mobx-state-tree';
import { TruckModel } from '../trucks/TruckModel';

export const AssociationModel = types
  .model('AssociationModel', {
    association_id: types.identifierNumber,
    vehicle_id: types.maybe(types.maybeNull(types.number)),
    internal_company_id: types.maybe(types.maybeNull(types.number)),
    vehicle_make: types.maybe(types.maybeNull(types.string)),
    vehicle_model_name: types.maybe(types.maybeNull(types.string)),
    vehicle_production_year: types.maybe(types.maybeNull(types.number)),
    start_driving: types.maybe(types.maybeNull(types.string)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),
    end_driving: types.maybe(types.maybeNull(types.string)),
    associated_truck: types.maybe(types.maybeNull(TruckModel)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setAssociatedTruck(associated_truck) {
        self.associated_truck = associated_truck;
      },
    };
  });
