import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Input, DatePicker, Select } from 'antd';
import {
  DRIVER_INFORMATION,
  STREET_ADDRESS,
  CITY,
  STATE,
  POSTAL_CODE,
  PHONE_NUMBER,
  EMAIL,
  DATE_OF_BIRTH,
  COUNTRY,
  OTHER,
} from '../../../constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';
const { Option } = Select;
const DriverInformation = () => {
  const {
    fleetPageState: { editedDriver },
  } = useMst();

  const onDateOfBirthChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    editedDriver.updateDateOfBirth(date);
  };

  return (
    <div className="edit-driver-section form-page-section">
      <div className="form-page-subsection">
        <div className="subsection-header mt0">
          <p>{DRIVER_INFORMATION}</p>
        </div>
        <div className="section-content align-center pb20">
          <div className="input-wrapper ml0">
            <div className="input-label justify-between">
              <span>{STREET_ADDRESS}</span>
            </div>
            <Input
              onChange={(e) => {
                editedDriver.updateDriverAddress(e.target.value);
              }}
              className="add-edit-input"
              value={editedDriver.street_address}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label justify-between">
              <span>{COUNTRY}</span>
            </div>
            <Select
              className={'w100'}
              onChange={(country) => editedDriver.updateDriverCountry(country)}
              value={editedDriver.country}
            >
              {editedDriver.countries.map((country, index) => (
                <Option key={index} value={country.name}>
                  {country.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="input-wrapper">
            <div className="input-label justify-between">
              <span>{STATE}</span>
            </div>
            <Select
              className={'w100'}
              onChange={(state) => editedDriver.updateDriverState(state)}
              value={editedDriver.state}
            >
              {editedDriver?.filteredStates?.states.map((state, index) => (
                <Option key={index} value={state}>
                  {state}
                </Option>
              ))}
              <Option key={'other'} value={'Other'}>
                {OTHER}
              </Option>
            </Select>
          </div>
          <div className="input-wrapper">
            <div className="input-label justify-between">
              <span>{CITY}</span>
            </div>
            <Input
              onChange={(e) => {
                editedDriver.updateDriverCity(e.target.value);
              }}
              className="add-edit-input"
              value={editedDriver.city}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label justify-between">
              <span>{POSTAL_CODE}</span>
            </div>
            <Input
              onChange={(e) => {
                editedDriver.updateDriverPostalCode(e.target.value);
              }}
              className="add-edit-input"
              value={editedDriver.postal_code}
            />
          </div>
        </div>
        <div className="section-content align-center">
          <div className="input-wrapper ml0">
            <div className="input-label justify-between">
              <span>{PHONE_NUMBER}</span>
            </div>
            <PhoneInput
              placeholder="e.g. 712338645"
              value={editedDriver.phone_number}
              onChange={(value) => editedDriver.updatePhoneNumber(value)}
              maxLength={15}
              displayInitialValueAsLocalNumber
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label justify-between">
              <span>{EMAIL}</span>
              <span className="color-secondary">*</span>
            </div>
            <Input
              onChange={(e) => {
                editedDriver.updateDriverEmail(e.target.value);
              }}
              className="add-edit-input"
              value={editedDriver.email}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label justify-between">
              <span>{DATE_OF_BIRTH}</span>
              <span className="color-secondary">*</span>
            </div>
            <DatePicker
              allowClear={false}
              onChange={onDateOfBirthChange}
              value={
                editedDriver.date_of_birth
                  ? moment(editedDriver.date_of_birth)
                  : null
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(DriverInformation);
