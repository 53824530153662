import { rootInstance } from '../../state/RootModel';
const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getLocations = (text, pageNumber, pageSize) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  return axios.get(
    `${rootInstance.baseUrl}/locations?page=${pageNumber}&articles_per_page=${pageSize}${textQuery}`,
    config
  );
};

export const getLocation = (id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/locations/${id}`, config);
};

export const addLocation = (location) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/locations`,
    {
      owner_id: location.owner_id,
      address: location.address,
      city: location.city,
      country: location.country,
      state: location.state,
      postal_code: location.postal_code,
      location_name: location.location_name,
      location_contact_name: location.location_contact_name,
      phone_number: location.phone_number,
      notes: location.notes,
    },
    config
  );
};

export const editLocation = (location) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/locations/${location.location_id}`,
    {
      owner_id: location.owner_id,
      address: location.address,
      city: location.city,
      country: location.country,
      state: location.state,
      location_name: location.location_name,
      phone_number: location.phone_number,
      postal_code: location.postal_code,
      location_contact_name: location.location_contact_name,
      location_id: location.location_id,
      organization_id: location.organization_id,
      is_archived: location.is_archived,
      owner_name: location.owner_name,
      zone: location.zone,
      location_longitude: location.location_longitude,
      location_latitude: location.location_latitude,
      notes: location.notes,
    },
    config
  );
};

export const editLocationCoordinates = (location) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/locations/${location.location_id}/coordinates`,
    {
      location_longitude: location.location_longitude,
      location_latitude: location.location_latitude,
    },
    config
  );
};

export const deleteLocation = (location_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/locations/${location_id}`,
    config
  );
};
