import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import collapse from '../../../assets/images/dispatch/collapse.svg';
import {
  EMPTY_AVAILABLE_PLANNER_TABLE_TEXT,
  SHIPMENT_LIST,
} from '../../../constants/dispatch';
import { Empty, Table } from 'antd';
import PlannerPopup from './ShipmentListPopup';
import ShipmentListProbillDetails from './ShipmentListProbillDetails';

const ShipmentList = () => {
  const {
    dispatchPageState: {
      setPlannerOpened,
      getShipmentListTableColumns,
      plannedShipments,
    },
  } = useMst();

  const [selectedShipment, setSelectedShipment] = useState({
    record: null,
    visible: false,
    x: 0,
    y: 0,
  });
  const onPlannerClose = () => {
    setPlannerOpened(false);
  };

  const onRowClick = (record) => ({
    onContextMenu: (event) => {
      event.preventDefault();
      if (!selectedShipment.visible) {
        document.addEventListener(`click`, function onClickOutside() {
          setSelectedShipment({
            record: null,
            visible: false,
            x: 0,
            y: 0,
          });
          document.removeEventListener(`click`, onClickOutside);
        });
      }
      setSelectedShipment({
        record,
        visible: true,
        x: event.clientX,
        y: event.clientY,
      });
    },
  });

  return (
    <div className="planner-list__inner">
      <div className="align-center mt20 mb20">
        <p className="color-title text-bold text-uppercase">{SHIPMENT_LIST}</p>
        <img
          src={collapse}
          alt="collapse shipments list"
          className="ml-a"
          style={{ cursor: 'pointer' }}
          onClick={onPlannerClose}
        />
      </div>
      <Table
        className="pt20"
        rowKey={'index'}
        dataSource={plannedShipments}
        columns={getShipmentListTableColumns()}
        pagination={false}
        style={{
          height: 600,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
        onRow={onRowClick}
        expandedRowRender={(record) => {
          if (record?.probills?.length > 0) {
            record?.probills.sort((a, b) =>
              a.probill_number.localeCompare(b.probill_number)
            );
            record?.probills.forEach((item, index) => {
              item.index = index;
            });
            return <ShipmentListProbillDetails data={record?.probills} />;
          }
          return null;
        }}
        rowClassName={(record) => {
          if (!record?.probills) {
            return 'not-expandable';
          }
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_AVAILABLE_PLANNER_TABLE_TEXT}
            />
          ),
        }}
      />
      <PlannerPopup {...selectedShipment} />
    </div>
  );
};

export default observer(ShipmentList);
