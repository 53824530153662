import React from 'react';
import { rootInstance } from '../../RootModel';
import selectIcon from '../../../assets/images/select-icon.svg';
import {
  ID,
  AVAILABLE_FROM,
  AVAILABLE_AT,
  CURRENT_SHIPMENT,
  ESTIMATED_FINISH,
  SEE_ON_MAP,
  CURRENT_ACTION,
  DISTANCE,
  CHOOSE_TRAILER,
} from '../../../constants/dispatch';
import { NOT_AVAILABLE_SHORT, STATUS, TRAILER_TYPE } from '../../../constants';
import { replaceUnderscore } from '../../../utils/methods';
import moment from 'moment';
import { Tooltip } from 'antd';

const onTrailerChange = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsSelectedTrailerChanged(true);
  rootInstance.dispatchPageState.setSelectedTrailerForMap(record);
};

const onTrailerSelect = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.setIsOrganizeTourTrailersModalOpen(false);
  rootInstance.dispatchPageState?.routeActionItem.changeSelectedTrailer(
    record,
    'modal'
  );
};

export const DispatchAvailableTrailersTableColumns = (rootInstance) => ({
  '1': {
    title: ID,
    dataIndex: 'internal_company_id',
    key: 'internal_company_id',
    render: (text) => {
      return <span>{text}</span>;
    },
  },
  '2': {
    title: TRAILER_TYPE,
    dataIndex: 'trailer_type',
    key: 'trailer_type',
    render: (text) => {
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '3': {
    title: STATUS,
    dataIndex: 'status',
    key: 'status',
    render: (text) => {
      return (
        <span
          className={`text-capitalize text-bold ${
            text === 'Available' ? 'text-green' : 'text-orange'
          }`}
        >
          {text ? replaceUnderscore(text) : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '4': {
    title: AVAILABLE_FROM,
    dataIndex: 'available_from',
    key: 'available_from',
    render: (text) => {
      return (
        <span>
          {text ? moment(text).format('YYYY-MM-DD HH:MM') : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '5': {
    title: AVAILABLE_AT,
    dataIndex: 'available_at',
    key: 'available_at',
    render: (text, record) => {
      return (
        <p>
          <span className="text-bold text-black">
            {record?.location_name
              ? record?.location_name
              : NOT_AVAILABLE_SHORT}
          </span>
          <br />
          <span>{record?.address ? record?.address : NOT_AVAILABLE_SHORT}</span>
        </p>
      );
    },
  },
  '6': {
    title: CURRENT_ACTION,
    dataIndex: 'trailer_current_action',
    key: 'trailer_current_action',
    render: (text) => {
      return (
        <span className={text?.route_action_id ? 'go-to' : ''}>
          {text?.name ? text.name : NOT_AVAILABLE_SHORT}
        </span>
      );
    },
  },
  '7': {
    title: (
      <p>
        <span className="ant-table-column-title">{ESTIMATED_FINISH}</span>{' '}
        <br /> <span style={{ fontSize: '12px' }}>{CURRENT_SHIPMENT}</span>
      </p>
    ),
    dataIndex: 'estimated_finish',
    key: 'estimated_finish',
    render: (text, record) => {
      let eta = record?.shipment_number;
      return <span>{text ? text : NOT_AVAILABLE_SHORT}</span>;
    },
  },
  '8': {
    title: DISTANCE,
    dataIndex: 'distance',
    key: 'distance',
    render: (text) => {
      return (
        <span>{text ? text.toFixed(2) + ' miles' : NOT_AVAILABLE_SHORT} </span>
      );
    },
  },
  '9': {
    title: '',
    dataIndex: 'action',
    render: (text, record) => {
      let button_disabled = true;
      if (
        record?.trailer_current_action?.location_latitude &&
        record?.trailer_current_action?.location_longitude
      ) {
        button_disabled = false;
      }
      return (
        <div className="align-center">
          <Tooltip title={CHOOSE_TRAILER}>
            <img
              className="cursor-pointer"
              onClick={(e) => onTrailerSelect(e, record)}
              src={selectIcon}
              alt=""
            />
          </Tooltip>
          <button
            type="button"
            disabled={button_disabled}
            className={`ml10 btn primary small ${
              button_disabled ? 'disabled' : ''
            }`}
            onClick={(e) => onTrailerChange(e, record)}
          >
            {SEE_ON_MAP}
          </button>
        </div>
      );
    },
  },
});
