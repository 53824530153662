export const COMPENSATION_STRUCTURES = 'Compensation structures';
export const COMPENSATION_VALUES = 'Compensation values';
export const COMPENSATION_DEDUCTIONS = 'Compensation deductions';

export const FIRST_COLUMN = 0;
export const LAST_COLUMN = 5;
export const DEFAULT_VALUE = '0.00';
export const DEFAULT_VALUE_REGEX = /^\d*\.?\d*$/;
/** Fields **/
export const COMPENSATION_NAME = 'Compensation name';
export const COMPENSATION_TYPE = 'Compensation type';
export const COMPENSATION_PERIOD = 'Period';
export const CREATE_COMPENSATION_STRUCTURE = 'Create compensation structure';
export const ADD_COMPENSATION_STRUCTURE = 'Add compensation structure';
export const COMPENSATION_STRUCTURE_INFORMATION =
  'Compensation structure information';
export const COMPENSATION_VALUE_NAME = 'Compensation value';
export const COMPENSATION_DEDUCTION_NAME = 'Compensation deduction';
export const COMPENSATION_DEDUCTION_TYPE = 'Deduction type';
export const COMPENSATION_VALUE_BASED_ON = 'Based on';
export const COMPENSATION_VALUE_DEFAULT_VALUE = 'Default value';
export const COMPENSATION_VALUE_CURRENCY = 'Currency';
export const COMPENSATION_VALUE_RATE = 'Rate';
export const VALUES_EDIT = 'values edit';
export const VALUES_DELETE = 'values delete';
export const VALUES = 'values';
export const DEDUCTIONS_EDIT = 'deductions edit';
export const DEDUCTIONS_DELETE = 'deductions delete';
export const DEDUCTIONS = 'deductions';
/** Errors/Validations/Empty **/
export const EMPTY_COMPENSATION_STRUCTURES_TABLE_TEXT =
  'There are no compensation structures';
export const ERROR_FETCHING_ALL_COMPENSATIONS =
  'Error fetching all compensations';
export const EMPTY_COMPENSATION_VALUES_TABLE_TEXT =
  'There are no compensation values';
export const EMPTY_COMPENSATION_DEDUCTIONS_TABLE_TEXT =
  'There are no compensation deductions';
export const ERROR_FETCHING_COMPENSATION = 'Error fetching compensation';

export const ERROR_LOADING_DATA = 'Error loading data';

export const ERROR_CREATING_NEW_COMPENSATION_STRUCTURE =
  'Error creating new compensation structure';
export const ERROR_ADDING_NEW_COMPENSATION_TO_DRIVER =
  'Error adding new compensation structure to driver';
export const ERROR_FETCHING_COMPENSATION_TYPES =
  'Error fetching compensation types';
export const ERROR_FETCHING_COMPENSATION_VALUES =
  'Error fetching compensation values';
export const ERROR_FETCHING_COMPENSATION_DEDUCTIONS =
  'Error fetching compensation deductions';
export const ERROR_DELETING_COMPENSATION = 'Error deleting compensation';
export const ERROR_DELETING_COMPENSATION_VALUE =
  'Error deleting compensation value';
export const ERROR_DELETING_COMPENSATION_VALUE_RATES =
  'Error deleting compensation value rates';
export const ERROR_DELETING_COMPENSATION_VALUE_PERIODS =
  'Error deleting compensation value periods';
export const ERROR_DELETING_COMPENSATION_VALUE_CURRENCIES =
  'Error deleting compensation value currencies';
export const ERROR_DELETING_COMPENSATION_VALUE_BASED_ON_TYPES =
  'Error deleting compensation value based on types';
export const ERROR_DELETING_COMPENSATION_DEDUCTION =
  'Error deleting compensation deduction';

/** REQUIRED **/
export const COMPENSATION_NAME_REQUIRED = 'Compensation name is required';
export const COMPENSATION_TYPE_REQUIRED = 'Compensation type is required';
export const COMPENSATION_VALUE_RATE_REQUIRED =
  'Compensation value rate is required';
export const COMPENSATION_VALUE_CURRENCY_REQUIRED =
  'Compensation value currency is required';
export const COMPENSATION_DEDUCTION_RATE_REQUIRED =
  'Compensation deduction rate is required';
export const COMPENSATION_DEDUCTION_CURRENCY_REQUIRED =
  'Compensation deduction currency is required';

export const COMPENSATION_PAYMENT_PERIOD_REQUIRED =
  'Compensation payment period is required';
export const COMPENSATION_VALUE_OR_DEDUCTION_REQUIRED =
  'Compensation must contain at least one value or deduction';
export const COMPENSATION_VALUE_REQUIRED =
  'All required fields must be filled in to add new compensation value.';
export const COMPENSATION_DEDUCTION_REQUIRED =
  'All required fields must be filled in to add new compensation deduction.';
export const COMPENSATION_SELECTION_REQUIRED = 'Compensation must be selected.';
export const COMPENSATION_VALUE_UNIQUE =
  'Compensation value, rate and based on fields must be unique.';
export const COMPENSATION_DEDUCTION_UNIQUE =
  'Deduction type, rate and based on fields must be unique.';
// Actions
export const COMPENSATION_DELETE = 'Delete compensation';
export const COMPENSATION_EDIT = 'Edit compensation';
export const COMPENSATION_VALUE_DELETE = 'Delete value';
export const COMPENSATION_VALUE_EDIT = 'Edit value';
export const COMPENSATION_DEDUCTION_DELETE = 'Delete deduction';
export const COMPENSATION_DEDUCTION_EDIT = 'Edit deduction';
export const CONFIRM_DELETING_COMPENSATION =
  'Are you sure that you want to remove selected compensation structure?';
export const CONFIRM_DELETING_VALUE =
  'Are you sure that you want to remove selected compensation value?';
export const CONFIRM_DELETING_DEDUCTION =
  'Are you sure that you want to remove selected compensation deduction?';
export const EDIT = 'Edit';
export const DELETE = 'Delete';
//Filter values
export const FILTER_COMPENSATION_VALUES = 'Filter values';
export const FILTER_DEFAULT_VALUE = 'Per mile';
