import { types } from 'mobx-state-tree';
import {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  DRIVER_TYPE_REQUIRED,
  DRIVER_CATEGORY_REQUIRED,
  DRIVER_CODE_NAME_REQUIRED,
  DRIVER_COMPANY_ID_REQUIRED,
} from '../../../constants';

export const DriverLightModel = types
  .model('DriverLightModel', {
    code_name: types.maybe(types.maybeNull(types.string)),
    first_name: types.maybe(types.maybeNull(types.string)),
    last_name: types.maybe(types.maybeNull(types.string)),
    driver_type: types.maybe(types.maybeNull(types.string)),
    driver_category: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),
    internal_company_id: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setInternalCompanyId(id) {
        self.internal_company_id = id;
      },
      setNotes(notes) {
        self.notes = notes;
      },
      setType(type) {
        self.driver_type = type;
      },
      setCategory(category) {
        self.driver_category = category;
      },
      setCodeName(code_name) {
        self.code_name = code_name;
      },
      setFirstName(first_name) {
        self.first_name = first_name;
      },
      setLastName(last_name) {
        self.last_name = last_name;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      clearForm() {
        this.setType(null);
        this.setCategory(null);
        this.setCodeName(null);
        this.setFirstName(null);
        this.setLastName(null);
        this.setErrorText(null);
      },
      validateForm() {
        if (!self.driver_type) {
          this.setErrorText(DRIVER_TYPE_REQUIRED);
        } else if (!self.driver_category) {
          this.setErrorText(DRIVER_CATEGORY_REQUIRED);
        } else if (!self.internal_company_id) {
          this.setErrorText(DRIVER_COMPANY_ID_REQUIRED);
        } else if (!self.code_name) {
          this.setErrorText(DRIVER_CODE_NAME_REQUIRED);
        } else if (!self.first_name) {
          this.setErrorText(FIRST_NAME_REQUIRED);
        } else if (!self.last_name) {
          this.setErrorText(LAST_NAME_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
    };
  });
