import React from 'react';
import { NOTE_TEXT, DELETE_NOTE, EDIT_NOTE } from '../../constants';
import edit from '../../assets/images/edit-equipment.svg';
import trash from '../../assets/images/trash.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../RootModel';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentsPageState.currentShipment.setShipmentNoteToBeDeleted(
    record
  );
};
const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentsPageState.currentShipment.setShipmentNoteToBeEdited(
    record
  );
};

export const notesColumnsFunc = (rootInstance) => ({
  '2': {
    title: NOTE_TEXT,
    key: 'note_text',
    dataIndex: 'note_text',
  },
  '3': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        !rootInstance.shipmentsPageState.isViewShipmentPage && (
          <div className="align-center">
            <Tooltip title={EDIT_NOTE}>
              <img
                src={edit}
                alt="edit"
                className="pointer ph10"
                onClick={(e) => onClickEdit(e, record)}
              />
            </Tooltip>
            <Tooltip title={DELETE_NOTE}>
              <img
                src={trash}
                alt="delete"
                className="pointer ph10"
                onClick={(e) => onClickDelete(e, record)}
              />
            </Tooltip>
          </div>
        )
      );
    },
  },
});
