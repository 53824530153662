import React from 'react';
import { observer } from 'mobx-react';
import CreateShipmentComponent from '../../components/brokerage/CreateShipment/CreateShipmentComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import { CREATE_SHIPMENT } from '../../constants';
import { useMst } from '../../state/RootModel';

const CreateShipmentPage = () => {
  const {
    shipmentsPageState: { shipmentBeingCreated },
  } = useMst();

  return (
    <div className="page create-shipment-page">
      <BreadcrumsComponent
        page={`${CREATE_SHIPMENT}`}
        breadcrumbsBackLink={PageRoutes.Brokerage.name}
      />
      {shipmentBeingCreated && <CreateShipmentComponent />}
    </div>
  );
};

export default observer(CreateShipmentPage);
