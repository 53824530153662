import React from 'react';
import { observer } from 'mobx-react';
import ShipmentStatusComponent from './ShipmentStatusComponent';
import CompletedShipmentsComponent from './CompletedShipmentsComponent';

const DiagramsSection = () => {
  return (
    <div className="page-section pv20">
      <div className="section-content diagram-section-content flex-alignitems-flex-start">
        <ShipmentStatusComponent />
        <CompletedShipmentsComponent />
      </div>
    </div>
  );
};

export default observer(DiagramsSection);
