import React from 'react';
import { observer } from 'mobx-react';
import { deliveryIcon } from '../Icon';
import { Marker, Popup } from 'react-leaflet';
import { PICKUP } from '../../../constants/dispatch';

const TrackingShipmentPickupLocation = (props) => {
  const { data } = props;
  return (
    <Marker
      position={[
        data[0]?.pickup_location?.latitude,
        data[0]?.pickup_location?.longitude,
      ]}
      // @ts-ignore
      icon={deliveryIcon}
      className="dispatch-map-marker"
    >
      <Popup>
        <p className="dispatch-map-marker__type text-bold text-green">
          {PICKUP}: {data[0]?.pickup_location.location_name}
        </p>
        {data[0]?.pickup_location?.address && (
          <span className="text-black">{data[0]?.pickup_location.address}</span>
        )}
      </Popup>
    </Marker>
  );
};

export default observer(TrackingShipmentPickupLocation);
