import React from 'react';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import ErrorBox from '../../../ErrorBox';
import { useMst } from '../../../../state/RootModel';
import addLocationIcon from '../../../../assets/images/add-location.svg';
import FormPageTitle from '../../../FormPageTitle';
import { ADD_LOCATION, SAVE_CHANGES } from '../../../../constants';
import 'react-phone-number-input/style.css';
import EditLocationCoordinatesMap from './EditLocationCoordinatesMap';

const EditLocationCoordinatesModal = () => {
  const {
    locationsPageState: { draggableEditedLocation },
  } = useMst();

  const onSaveChanges = () => {
    draggableEditedLocation.editCoordinates();
  };

  return (
    <div>
      <Modal
        visible={!!draggableEditedLocation}
        footer={null}
        centered={true}
        maskClosable={false}
        closable={false}
        width={600}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 location-modal-content">
          <div className="align-center">
            <FormPageTitle image={addLocationIcon} title={ADD_LOCATION} />
          </div>

          <EditLocationCoordinatesMap />

          <ErrorBox obj={draggableEditedLocation} />

          <div>
            <button className="btn primary" onClick={onSaveChanges}>
              {SAVE_CHANGES}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditLocationCoordinatesModal);
