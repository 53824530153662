import React from 'react';
import { observer } from 'mobx-react';
import {
  EMPTY_NOTES_TABLE_TEXT,
  LOCATION_NAME,
  LOCATION_NOTE,
} from '../../../constants';
import { Empty, Table } from 'antd';

const ShipmentNotes = (props) => {
  const { data } = props;
  const { title } = props;

  const columns = [
    {
      title: LOCATION_NAME,
      dataIndex: 'location_name',
      key: 'location_name',
    },
    {
      title: LOCATION_NOTE,
      dataIndex: 'location_note',
      key: 'location_note',
    },
  ];
  return (
    <div className="page-section shipment-details-section w100">
      <div className="subsection-header align-center">
        <p>{title}</p>
      </div>
      <div className="subsection-content">
        <Table
          className="drivers-table fleet-table"
          rowKey={'location_name'}
          columns={columns}
          dataSource={data}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={EMPTY_NOTES_TABLE_TEXT}
              />
            ),
          }}
        />
      </div>
    </div>
  );
};

export default observer(ShipmentNotes);
