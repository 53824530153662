import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Tabs } from 'antd';
import ErrorBox from '../../ErrorBox';
import { useMst } from '../../../state/RootModel';
import addFileIcon from '../../../assets/images/add-file.svg';
import FormPageTitle from '../../FormPageTitle';
import { CARGO, EDIT_STOP, GENERAL, SAVE_CHANGES } from '../../../constants';
import CargoTabComponent from './editProbill/CargoTab';
import GeneralTabComponent from './editProbill/GeneralTab';

const { TabPane } = Tabs;

const EditProbillModal = () => {
  const {
    shipmentsPageState: {
      currentShipment: { editedProbill, setEditedProbill },
    },
  } = useMst();

  const onSaveChanges = () => {
    editedProbill.saveProbill();
  };

  const onCancel = () => {
    setEditedProbill(null);
  };

  const onTabChange = (key) => {
    editedProbill.setActiveTab(key);
  };

  return (
    <div>
      <Modal
        visible={!!editedProbill}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={700}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addFileIcon} title={EDIT_STOP} />
          </div>

          <Tabs activeKey={editedProbill?.activeTab} onChange={onTabChange}>
            <TabPane tab={GENERAL} key={GENERAL}>
              <GeneralTabComponent />
            </TabPane>
            <TabPane tab={CARGO} key={CARGO}>
              <CargoTabComponent />
            </TabPane>
          </Tabs>

          <ErrorBox obj={editedProbill} />

          <button className="btn primary" onClick={onSaveChanges}>
            {SAVE_CHANGES}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditProbillModal);
