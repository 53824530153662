import { types } from 'mobx-state-tree';
import { observable } from 'mobx';
import { rootInstance } from '../RootModel';
import { EmployeeModel } from '../employees/EmployeeModel';

export const DepartmentModel = types
  .model('DepartmentModel', {
    department_id: types.identifierNumber,
    department_name: types.maybe(types.maybeNull(types.string)),
    employees: types.map(EmployeeModel),
    isEmployeeTableOpen: false,
    newEmployeeFirstName: types.maybeNull(types.string),
    newEmployeeLastName: types.maybeNull(types.string),
    newEmployeeEmail: types.maybeNull(types.string),
    newEmployeePhoneNumber: types.maybeNull(types.string),
    newEmployeeType: types.maybeNull(types.string),
    newEmployeeDepartments: types.maybe(types.array(types.string)),
    newEmployeeErrorText: types.maybeNull(types.string),
    editedEmployee: types.maybeNull(types.reference(EmployeeModel)),
    successModalOpen: false,
  })
  .volatile((self) => {
    return {
      selectedEmployees: observable.map({}),
    };
  })
  .views((self) => {
    return {
      get employeesArray() {
        return rootInstance.employeesPageState.filteredEmployeesArray.filter(
          (employee) => {
            return employee.user_department === self.department_id;
          }
        );
      },
      // get selectedEmployeesArray() {
      //   return Array.from(self.selectedEmployees.values());
      // },
      // get selectedEmployeesArrayIds() {
      //   let ids = [];
      //   self.selectedEmployees.forEach((element) => {
      //     ids.push(element.user_id);
      //   });
      //   return ids;
      // },
    };
  })
  .actions((self) => {
    return {
      setSuccessModalOpen(status) {
        self.successModalOpen = status;
      },
      setEditedEmployee(id) {
        self.editedEmployee = id;
      },
      setIsEmployeesTableOpen(status) {
        self.isEmployeeTableOpen = status;
      },
      closeEmployeesTable() {
        self.isEmployeeTableOpen = false;
      },
      openEmployeesTable() {
        self.isEmployeeTableOpen = true;
      },
      // setSelectedEmployees(employees) {
      //   self.selectedEmployees.clear();
      //   employees.forEach((element) => {
      //     self.selectedEmployees.set(element.user_id, element);
      //   });
      // },
      // removeFromSelectedEmployees(id) {
      //   self.selectedEmployees.delete(id);
      // },
    };
  });
