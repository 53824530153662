import { types } from 'mobx-state-tree';

export const LocationModel = types
  .model('DispatchMapLocation', {
    // location_random_num: types.identifierNumber,
    // location_id: types.maybe(types.maybeNull(types.number)),
    location_id: types.identifierNumber,
    address: types.maybe(types.maybeNull(types.string)),
    company: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    scheduled_pickup_time_start: types.maybe(types.maybeNull(types.string)),
    scheduled_pickup_time_end: types.maybe(types.maybeNull(types.string)),
    scheduled_delivery_time_start: types.maybe(types.maybeNull(types.string)),
    scheduled_delivery_time_end: types.maybe(types.maybeNull(types.string)),
    location_name: types.maybe(types.maybeNull(types.string)),
    location_longitude: types.maybe(types.maybeNull(types.number)),
    location_latitude: types.maybe(types.maybeNull(types.number)),
    latitude: types.maybe(types.maybeNull(types.number)),
    longitude: types.maybe(types.maybeNull(types.number)),
    state: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  });
