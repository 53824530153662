import {
  getShipmentProbills,
  getAvailableShipmentCharges,
  addShipmentCharges,
  editShipment,
  getShipmentDocuments,
  getShipmentCharges,
  editShipmentCharge,
  getEquipment,
  getAvailableBillingCharges,
  getCarriersWithEquipment,
  getShipmentNotes,
} from '../../actions/shipments';
import { getShipmentSpecifics } from '../../actions/shipmentSpecifics';

export const shipmentProbills = (model) => {
  return new Promise((resolve, reject) => {
    getShipmentProbills(model.shipment_id)
      .then((response) => {
        model.setProbills(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const shipmentSpecifics = (model) => {
  return new Promise((resolve, reject) => {
    getShipmentSpecifics(model.selectedShipmentSpecificType)
      .then((response) => {
        if (model.isShipmentChargeSelected) {
          model.setShipmentCharges(response.data);
        } else if (model.isRouteActionSelected) {
          model.setRouteActions(response.data);
        } else if (model.isShipmentDocumentTypeSelected) {
          model.setShipmentDocumentTypes(response.data);
        } else if (model.isShipmentStatusSelected) {
          model.setShipmentStatuses(response.data);
        }
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// export const uploadDocument = (shipment_id, document) => {
//   return new Promise((resolve, reject) => {
//     const formData = new FormData();
//     formData.append('file', document.originFileObj);
//     uploadShipmentDocument(shipment_id, documentTypeId, formData)
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         reject(error);
//       });
//   });
// };

export const shipmentCharges = (model) => {
  return new Promise((resolve, reject) => {
    getAvailableShipmentCharges()
      .then((response) => {
        let shipmentCharges = response.data;
        if (model.is_brokerage_shipment) {
          getAvailableBillingCharges().then((res) => {
            const billingCharges = res.data;
            billingCharges.forEach((item) => {
              shipmentCharges.push(item);
            });
            model.setAvailableShipmentCharges(shipmentCharges);
          });
        } else {
          model.setAvailableShipmentCharges(shipmentCharges);
        }

        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const shipmentDocuments = (model) => {
  return new Promise((resolve, reject) => {
    getShipmentDocuments(model.shipment_id)
      .then((response) => {
        model.setDocuments(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const shipmentRelatedCharges = (model) => {
  return new Promise((resolve, reject) => {
    getShipmentCharges(model.shipment_id)
      .then((response) => {
        model.setShipmentCharges(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addShipmentChargesPromise = (model, items) => {
  return new Promise((resolve, reject) => {
    addShipmentCharges(model.shipment_id, items)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editShipmentPromise = (model) => {
  return new Promise((resolve, reject) => {
    editShipment(model)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editShipmentRelatedChargePromise = (shipment_id, model) => {
  return new Promise((resolve, reject) => {
    editShipmentCharge(shipment_id, model)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const shipmentAvailableEquipment = (model) => {
  return new Promise((resolve, reject) => {
    getEquipment()
      .then((response) => {
        model.setAvailableEquipment(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEquipmentsList = (model) => {
  return new Promise((resolve, reject) => {
    getCarriersWithEquipment(
      model?.trailer_type?.equipment_id,
      model.carriersListCurrentPage
    )
      .then((response) => {
        model.setCarriersTotalItems(response.data.total);
        model.setCarriersWithSelectedEquipment(response.data.carriers);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getShipmentNotesPromise = (model) => {
  return new Promise((resolve, reject) => {
    getShipmentNotes(model.shipment_id)
      .then((response) => {
        model.setCarrierNotes(response.data?.carrier_notes);
        model.setReceiverNotes(response.data?.receiver_notes);
        model.setShipperNotes(response.data?.shipper_notes);
        model.setCustomerNote(response.data?.customer_notes);
        model.setShipmentNotes(response.data?.other_notes);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
