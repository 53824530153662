import React from 'react';
import { observer } from 'mobx-react';
import ViewShipmentHeader from './ViewShipmentHeader';
import ShipmentDetails from './ShipmentDetails';
import ProbillsSection from '../Probills/ProbillsSection';
import DocumentsSection from './DocumentsSection';
import BillingDetails from './BillingDetails';
import ShipmentTotalCharges from '../ShipmentCommons/ShipmentTotalCharges';
import { useMst } from '../../../state/RootModel';
import ShipmentProfit from '../ShipmentCommons/ShipmentProfit';
import SendDataToPlanner from '../ShipmentCommons/SendDataToPlanner';
import NotesSection from '../ShipmentCommons/NotesSection';
import ShipmentNotes from '../ShipmentCommons/ShipmentNotes';
import {
  CARRIER_NOTES,
  RECEIVER_NOTES,
  SHIPPER_NOTES,
} from '../../../constants';

const ViewShipmentComponent = () => {
  const {
    shipmentsPageState: { viewedShipment, isSendDataToPlannerModalOpen },
  } = useMst();

  return (
    <div className="page-content">
      <ViewShipmentHeader />
      <ShipmentDetails />
      {/*CustomerNote*/}
      {viewedShipment?.carrier_notes &&
        viewedShipment?.carrier_notes?.length > 0 && (
          <ShipmentNotes
            title={CARRIER_NOTES}
            data={viewedShipment.carrier_notes}
          />
        )}
      {viewedShipment?.shipper_notes &&
        viewedShipment?.shipper_notes?.length > 0 && (
          <ShipmentNotes
            title={SHIPPER_NOTES}
            data={viewedShipment.shipper_notes}
          />
        )}
      {viewedShipment?.receiver_notes &&
        viewedShipment?.receiver_notes?.length > 0 && (
          <ShipmentNotes
            title={RECEIVER_NOTES}
            data={viewedShipment.receiver_notes}
          />
        )}
      <NotesSection />

      <ProbillsSection />
      <div className="w100 mt20 flex-alignitems-flex-start">
        <BillingDetails />
        {viewedShipment?.isBrokerageShipment && <ShipmentProfit />}
        {!viewedShipment?.isBrokerageShipment && <ShipmentTotalCharges />}
        {isSendDataToPlannerModalOpen && <SendDataToPlanner />}
      </div>
      <DocumentsSection />
    </div>
  );
};

export default observer(ViewShipmentComponent);
