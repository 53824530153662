import React from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';
import {
  NOT_AVAILABLE,
  TRUCK_INFORMATION,
  VIN,
  YEAR,
  LICENCE_PLATE,
  GROSS_WEIGHT,
  EXPIRY_DATE,
  MAKE,
  CONNECTED_DRIVER,
  MODEL,
  FUEL_TYPE,
  OWNERSHIP_TYPE,
  CONNECTED_TRAILER,
  NOTES,
} from '../../../constants';
import PageRoutes from '../../../utils/PageRoutes';
import { ID } from '../../../constants/dispatch';
import { OWNER_OPERATOR } from '../../../constants/driver/ownershipTypes';

const TruckInformation = () => {
  const {
    fleetPageState: { viewedTruck },
  } = useMst();

  const onDriverView = (e) => {
    e.stopPropagation();
    const params = { driverId: viewedTruck.connected_driver_data.driver_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.ViewDriver.name),
      params
    );
  };
  const onTrailerView = (e) => {
    const params = { trailerId: viewedTruck.connected_vehicle_data.vehicle_id };
    rootInstance.router.setView(
      rootInstance.router.views.get(PageRoutes.ViewTrailer.name),
      params
    );
  };

  return (
    <div className="edit-driver-section form-page-section w50 pr10">
      <div className="form-page-subsection">
        <div className="subsection-header mt0">
          <p>{TRUCK_INFORMATION}</p>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{ID}</p>
            <p className="content">
              {viewedTruck.internal_company_id
                ? viewedTruck.internal_company_id
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{VIN}</p>
            <p className="content">
              {viewedTruck.vin ? viewedTruck.vin : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{OWNERSHIP_TYPE}</p>
            <p className="content text-capitalize">
              {viewedTruck.ownership_type
                ? viewedTruck.ownership_type
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{GROSS_WEIGHT}</p>
            <p className="content">
              {viewedTruck.vehicle_weight
                ? viewedTruck.vehicle_weight
                : NOT_AVAILABLE}{' '}
              {viewedTruck.vehicle_weight_unit
                ? viewedTruck.vehicle_weight_unit
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{LICENCE_PLATE}</p>
            <p className="content">
              {viewedTruck.activeRegistration?.licence_plate
                ? viewedTruck.activeRegistration.licence_plate
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{EXPIRY_DATE}</p>
            <p className="content">
              {viewedTruck.activeRegistration?.expiry_date
                ? viewedTruck.activeRegistration.expiry_date
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{MAKE}</p>
            <p className="content">
              {viewedTruck.vehicle_make
                ? viewedTruck.vehicle_make
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{MODEL}</p>
            <p className="content">
              {viewedTruck.vehicle_model_name
                ? viewedTruck.vehicle_model_name
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{YEAR}</p>
            <p className="content">
              {viewedTruck.vehicle_production_year
                ? viewedTruck.vehicle_production_year
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{FUEL_TYPE}</p>
            <p className="content text-capitalize">
              {viewedTruck.fuel_type ? viewedTruck.fuel_type : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{CONNECTED_DRIVER}</p>
            {!viewedTruck.connected_driver_data && (
              <p className="content text-capitalize">{NOT_AVAILABLE}</p>
            )}
            {viewedTruck.connected_driver_data && (
              <p
                className="content text-capitalize go-to"
                onClick={(e) => onDriverView(e)}
              >
                {/*{viewedTruck?.connected_driver_data?.internal_company_id ? viewedTruck.connected_driver_data.internal_company_id : NOT_AVAILABLE}{' - '}*/}
                {viewedTruck?.connected_driver_data?.first_name
                  ? viewedTruck.connected_driver_data.first_name
                  : NOT_AVAILABLE}
                {viewedTruck?.connected_driver_data?.last_name
                  ? ' ' + viewedTruck.connected_driver_data.last_name
                  : NOT_AVAILABLE}
                {viewedTruck?.connected_driver_data?.driver_type
                  ? viewedTruck.connected_driver_data.driver_type ===
                    OWNER_OPERATOR
                    ? ' (OO)'
                    : ' (CD)'
                  : NOT_AVAILABLE}
              </p>
            )}
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{CONNECTED_TRAILER}</p>
            {!viewedTruck.connected_vehicle_data && (
              <p className="content text-capitalize">{NOT_AVAILABLE}</p>
            )}
            {viewedTruck.connected_vehicle_data && (
              <p
                className="content text-capitalize go-to"
                onClick={(e) => onTrailerView(e)}
              >
                {viewedTruck?.connected_vehicle_data?.internal_company_id
                  ? viewedTruck.connected_vehicle_data.internal_company_id
                  : NOT_AVAILABLE}
                {' - '}
                {viewedTruck?.connected_vehicle_data?.vehicle_make
                  ? viewedTruck.connected_vehicle_data.vehicle_make
                  : NOT_AVAILABLE}
              </p>
            )}
          </div>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{NOTES}</p>
            <p className="content text-capitalize">
              {viewedTruck.notes ? viewedTruck.notes : NOT_AVAILABLE}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TruckInformation);
