import React from 'react';
import { observer } from 'mobx-react';

const FormButtonsSection = (props) => {
  const handleConfirm = props.onConfirm;
  const handleDiscard = props.onDiscard;
  const confirmTitle = props.confirmTitle;
  const discardTitle = props.discardTitle;

  return (
    <div className="mt20">
      <button className="btn add primary" onClick={handleConfirm}>
        {confirmTitle}
      </button>
      <button className="btn add white ml20" onClick={handleDiscard}>
        {discardTitle}
      </button>
    </div>
  );
};

export default observer(FormButtonsSection);
