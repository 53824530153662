import { Instance, types } from 'mobx-state-tree';
import { WindowStateModel } from './WindowStateModel';
import { createContext, useContext } from 'react';
import { LoginModel } from './login/LoginModel';
import { HeaderModel } from './HeaderModel';
import { BottomMenuModel } from './bottomMenu/BottomMenuModel';
import { SideMenuModel } from './sideMenu/SideMenuModel';
import { RouterStore } from './RouterStore';
import { PageRoutes } from '../utils/PageRoutes';
import { startRouter } from './RouterStore';
import { observable } from 'mobx';
import { EmployeesPageModel } from './employees/EmployeesPageModel';
import { FleetPageModel } from './fleet/FleetPageModel';
import { BrokeragePageModel } from './brokerage/BrokeragePageModel';
import { ShipmentsPageModel } from './shipments/ShipmentsPageModel';
import { ClientsPageModel } from './clients/ClientsPageModel';
import { LocationsPageModel } from './locations/LocationsPageModel';
import { CarriersPageModel } from './carriers/CarriersPageModel';
import { ShipmentSpecificsPageModel } from './shipmentSpecifics/ShipmentSpecificsPageModel';
import { LoaderModel } from './loader/LoaderModel';
import { DispatchPageModel } from './dispatch/DispatchPageModel';
import { OrganizationPageModel } from './organizations/OrganizationPageModel';
import { OrganizationSettingsPageModel } from './organization/OrganizationSettingsPageModel';
import { UserModel } from './UserModel';
import { PayrollPageModel } from './payroll/PayrollPageModel';
import { ReportsPageModel } from './reports/ReportsPageModel';

export const baseUrl = 'https://api.kamion-scm.com';

export const imageBaseUrl = '';

export const websiteImageBaseUrl = '';

export const environment = '';

export const websiteUrl = '';

export const RootModel = types
  .model('Root', {
    loginForm: LoginModel,
    userState: UserModel,
    loaderState: LoaderModel,
    headerState: HeaderModel,
    bottomMenuState: BottomMenuModel,
    sideMenuState: SideMenuModel,
    employeesPageState: EmployeesPageModel,
    fleetPageState: FleetPageModel,
    brokeragePageState: BrokeragePageModel,
    shipmentsPageState: ShipmentsPageModel,
    clientsPageState: ClientsPageModel,
    locationsPageState: LocationsPageModel,
    carriersPageState: CarriersPageModel,
    shipmentSpecificsPageState: ShipmentSpecificsPageModel,
    router: RouterStore,
    windowState: WindowStateModel,
    baseUrl,
    imageBaseUrl,
    environment,
    websiteUrl,
    websiteImageBaseUrl,
    dispatchPageState: DispatchPageModel,
    organizationState: OrganizationPageModel,
    currentOrganization: OrganizationSettingsPageModel,
    payrollPageState: PayrollPageModel,
    reportsPageState: ReportsPageModel,
  })
  .actions((self) => {
    return {};
  });

export const mobxStore = observable({
  name: 'mobx',
});

export type MobxStoreInstance = typeof mobxStore;
export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);
const MobxStoreContext = createContext<null | MobxStoreInstance>(null);
export const MobxProvider = MobxStoreContext.Provider;
export const Provider = RootStoreContext.Provider;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store as RootInstance;
}

export function useMobx() {
  const store = useContext(MobxStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store as MobxStoreInstance;
}

export const rootInstance = RootModel.create({
  loginForm: {},
  loaderState: {},
  headerState: {},
  bottomMenuState: {
    bottomMenuItems: {},
  },
  sideMenuState: {
    menuItems: {},
  },
  employeesPageState: {
    departments: {},
    newEmployee: {},
  },
  fleetPageState: {
    newDriver: {},
    newTrailer: {},
    newTruck: {},
  },
  brokeragePageState: {},
  dispatchPageState: {},
  payrollPageState: {
    newCompensationStructure: {},
  },
  reportsPageState: {},
  organizationState: {
    newOrganization: {},
  },
  currentOrganization: {
    newDepartment: {},
    newUserRole: {},
  },
  shipmentsPageState: {
    shipments: {},
  },
  clientsPageState: {
    clients: {},
  },
  locationsPageState: {
    locations: {},
  },
  carriersPageState: {
    carriers: {},
  },
  shipmentSpecificsPageState: {
    orderCharges: {},
  },
  router: {
    views: PageRoutes,
  },
  windowState: WindowStateModel.create(),
  userState: {},
});
startRouter(rootInstance.router);

export interface RootType extends Instance<typeof rootInstance> {}
