import React from 'react';
import { observer } from 'mobx-react';
import { BRANDING_ORGANIZATION_INFORMATION } from '../../../constants/organizations/organizations';
import {
  BACKGROUND,
  IMAGE,
  IMAGE_UPLOAD,
  LOGIN,
  LOGO,
} from '../../../constants';
import { Upload } from 'antd';
import fileUpload from '../../../assets/images/file-upload.svg';
import { THUMBNAIL } from '../../../constants/organization/organization';

const EditOrganizationBranding = (props) => {
  const logo = [...props.logo];
  const onLogoChange = props.onLogoChange;
  const thumbnail = [...props.thumbnail];
  const onThumbnailChange = props.onThumbnailChange;
  const background = [...props.background];
  const onBackgroundImageChange = props.onBackgroundImageChange;

  return (
    <div className="add-trailer-section form-page-section">
      <div className="form-section-title-row">
        <span className="form-section-title">
          {BRANDING_ORGANIZATION_INFORMATION}
        </span>
      </div>
      <div className="section-content flex">
        <div className="photo-upload-wrapper pr20 flex-column justify-start">
          <div className="input-label">{LOGO}</div>
          <Upload
            listType={'picture-card'}
            accept=".jpg, .png"
            beforeUpload={() => false}
            onChange={onLogoChange}
            fileList={logo}
          >
            {!logo.length && (
              <div className="align-center justify-between pointer">
                <span className="color-primary">{IMAGE_UPLOAD}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            )}
          </Upload>
        </div>
        <div className="photo-upload-wrapper pr20 flex-column justify-start">
          <div className="input-label">{THUMBNAIL}</div>
          <Upload
            listType={'picture-card'}
            accept=".jpg, .png"
            beforeUpload={() => false}
            onChange={onThumbnailChange}
            fileList={thumbnail}
          >
            {!thumbnail.length && (
              <div className="align-center justify-between pointer">
                <span className="color-primary">{IMAGE_UPLOAD}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            )}
          </Upload>
        </div>
        <div className="photo-upload-wrapper pr20 flex-column justify-start">
          <div className="input-label">{`${BACKGROUND} ${IMAGE} (${LOGIN})`}</div>
          <Upload
            listType={'picture-card'}
            accept=".jpg, .png"
            beforeUpload={() => false}
            onChange={onBackgroundImageChange}
            fileList={background}
          >
            {!background.length && (
              <div className="align-center justify-between pointer">
                <span className="color-primary">{IMAGE_UPLOAD}</span>
                <img src={fileUpload} alt="file upload" />
              </div>
            )}
          </Upload>
        </div>
      </div>
    </div>
  );
};

export default observer(EditOrganizationBranding);
