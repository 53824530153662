import React from 'react';
import moment from 'moment';
import { rootInstance } from '../../RootModel';
import { Tooltip } from 'antd';
import {
  ALL_ACTIONS,
  CURRENT_ACTION,
  SCHEDULED_PICKUP_DATE_TIME,
} from '../../../constants/dispatch';

import PageRoutes from '../../../utils/PageRoutes';
import {
  DELIVERY_LOCATION,
  EDIT_SHIPMENT,
  NOT_AVAILABLE_SHORT,
  SHIPMENT_NO,
  PICKUP_LOCATION,
  SELECT_SHIPMENT,
  STATUS,
} from '../../../constants';
import editIcon from '../../../assets/images/edit-equipment.svg';
import selectIcon from '../../../assets/images/select-icon.svg';

const onShipmentSwitch = (e, record) => {
  rootInstance.dispatchPageState.clearSelectedShipment();
  rootInstance.dispatchPageState.clearSpecificShipmentDetails();
  rootInstance.dispatchPageState.beforeEnterSetSelectedShipment(
    record.shipment_id
  );
  if (rootInstance.dispatchPageState.getNewRouteActionItems.length > 0) {
    rootInstance.dispatchPageState.addNewRouteActionItem({});
  }
};
const onShipmentEdit = (e, record) => {
  e.stopPropagation();
  const params = { shipmentId: record.shipment_id };
  rootInstance.router.setView(
    rootInstance.router.views.get(PageRoutes.EditShipment.name),
    params
  );
};

// const user_id = JSON.parse(localStorage.getItem('user')).user_id;
let selectedColumns = [
  'shipment_number',
  'status',
  'current_action',
  'all_actions',
  'pickup_location',
  'delivery_location',
];
let ctd = {};
selectedColumns.forEach((column, index) => {
  switch (column) {
    case 'shipment_number':
      ctd[index] = {
        title: SHIPMENT_NO,
        dataIndex: column,
        key: column,
        render: (text, record) => {
          return <span>{text}</span>;
        },
      };
      break;
    case 'status':
      ctd[index] = {
        title: STATUS,
        dataIndex: column,
        key: column,
        sorter: (a, b) => a.status.length - b.status.length,
        sortDirections: ['descend'],
        render: (text, record) => {
          let color;
          switch (text) {
            case 'scheduled':
              color = 'text-blue';
              break;
            case 'pending assignment':
              color = 'text-dkred';
              break;
            case 'on time':
              color = 'text-green';
              break;
            case 'delayed':
              color = 'text-orange';
              break;
          }
          return (
            <span className={`${color} text-capitalize text-bold`}>{text}</span>
          );
        },
      };
      break;

    case 'current_action':
      ctd[index] = {
        title: CURRENT_ACTION,
        dataIndex: column,
        key: column,
        render: (text, record) => {
          return (
            <span>
              {record?.current_action
                ? record?.current_action
                : NOT_AVAILABLE_SHORT}
            </span>
          );
        },
      };
      break;
    case 'all_actions':
      ctd[index] = {
        title: ALL_ACTIONS,
        dataIndex: column,
        key: column,
        render: (text, record) => {
          if (record.all_actions?.length > 0) {
            let ret_text = '';
            record.all_actions.forEach((action, index) => {
              if (record.all_actions.length > 1 && index === 0) {
                ret_text += action + ', ';
              } else {
                ret_text += action;
              }
            });
            return <p>{ret_text}</p>;
          } else {
            return <span>NA</span>;
          }
        },
      };
      break;
    case 'delivery_location':
      ctd[index] = {
        title: DELIVERY_LOCATION,
        dataIndex: column,
        key: column,
        render: (text, record) => {
          const location_name = record?.delivery_location?.location_name
            ? record?.delivery_location?.location_name
            : 'N/A';
          const address = record?.delivery_location?.address
            ? record?.delivery_location?.address
            : 'N/A';
          return (
            <div>
              <p className="text-black text-bold">{location_name}</p>
              <p>{address}</p>
            </div>
          );
        },
      };
      break;
    case 'pickup_location':
      ctd[index] = {
        title: PICKUP_LOCATION,
        dataIndex: column,
        key: column,
        render: (text, record) => {
          const location_name = record?.pickup_location?.location_name
            ? record?.pickup_location?.location_name
            : 'N/A';
          const address = record?.pickup_location?.address
            ? record?.pickup_location?.address
            : 'N/A';
          return (
            <div>
              <p className="text-black text-bold">{location_name}</p>
              <p>{address}</p>
            </div>
          );
        },
      };
      break;
    case 'scheduled_pickup':
      ctd[index] = {
        title: SCHEDULED_PICKUP_DATE_TIME,
        dataIndex: 'scheduled_pickup',
        key: 'scheduled_pickup',
        render: (text, record) => {
          if (
            record?.pickup_location?.scheduled_pickup_time_start &&
            record?.pickup_location?.scheduled_pickup_time_end
          ) {
            let date_start = moment(
              record?.pickup_location?.scheduled_pickup_time_start
            ).format('Do MMMM');
            let time_start = moment(
              record?.pickup_location?.scheduled_pickup_time_start
            ).format('HH:mm');

            let date_end = moment(
              record?.pickup_location?.scheduled_pickup_time_end
            ).format('Do MMMM');
            let time_end = moment(
              record?.pickup_location?.scheduled_pickup_time_end
            ).format('HH:mm');
            if (date_start === date_end) {
              return (
                <p>
                  <span>
                    {date_end} | Until: {time_end}
                  </span>
                </p>
              );
            } else {
              return (
                <p>
                  <span>
                    {date_start} | From: {time_start}
                  </span>{' '}
                  <br />
                  <span>
                    {date_end} | To: {time_end}
                  </span>
                </p>
              );
            }
          } else if (
            record?.pickup_location?.scheduled_pickup_time_start &&
            !record?.pickup_location?.scheduled_pickup_time_end
          ) {
            let date_start = moment(
              record?.pickup_location?.scheduled_pickup_time_start
            ).format('Do MMMM');
            let time_start = moment(
              record?.pickup_location?.scheduled_pickup_time_start
            ).format('HH:mm');
            return (
              <p>
                <span>
                  {date_start} | From: {time_start}
                </span>
              </p>
            );
          } else if (
            !record?.pickup_location?.scheduled_pickup_time_start &&
            record?.pickup_location?.scheduled_pickup_time_end
          ) {
            let date_end = moment(
              record?.pickup_location?.scheduled_pickup_time_end
            ).format('Do MMMM');
            let time_end = moment(
              record?.pickup_location?.scheduled_pickup_time_end
            ).format('HH:mm');
            return (
              <p>
                <span>
                  {date_end} | Until: {time_end}
                </span>
              </p>
            );
          } else {
            return (
              <p>
                <span>Date and time are not specified</span>
              </p>
            );
          }
        },
      };
      break;
  }
  ctd[index + 1] = {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          <Tooltip
            placement="top"
            title={EDIT_SHIPMENT + ':' + record.shipment_id}
            className="align-center"
          >
            <img
              style={{ cursor: 'pointer' }}
              onClick={(e) => onShipmentEdit(e, record)}
              src={editIcon}
              alt=""
            />
          </Tooltip>
          <Tooltip
            placement="top"
            title={SELECT_SHIPMENT + ':' + record.shipment_id}
            className="align-center"
          >
            <img
              className="ml10"
              style={{ cursor: 'pointer' }}
              onClick={(e) => onShipmentSwitch(e, record)}
              src={selectIcon}
              alt=""
            />
          </Tooltip>
        </div>
      );
    },
  };
});
export const ShipmentsSidebarTableColumns = (rootInstance) => ctd;
