import React from 'react';
import {
  ADDRESS,
  COUNTRY_STATE_CITY,
  CUSTOMER_NAME,
  EMAIL,
  PHONE_NUMBER,
  SELECT_CUSTOMERS,
} from '../../../constants';
import edit from '../../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../../RootModel';

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.dispatchPageState.getShipmentDetails(null);
  rootInstance.dispatchPageState?.routeActionItem.setCustomerToBill(
    record.client_id
  );
  rootInstance.dispatchPageState.setIsOrganizeTourCustomersModalOpen(false);
};

export const CustomersTableColumns = (rootInstance) => ({
  '1': {
    title: CUSTOMER_NAME,
    dataIndex: 'company',
    key: 'company',
  },
  '2': {
    title: COUNTRY_STATE_CITY,
    key: 'country_state_city',
    dataIndex: 'current_driver',
    render: (text, record) => {
      return (
        <span>
          {record.country} / {record.state} / {record.city}
        </span>
      );
    },
  },
  '3': {
    title: ADDRESS,
    key: 'address',
    dataIndex: 'address',
  },
  '4': {
    title: EMAIL,
    key: 'email',
    dataIndex: 'email',
  },
  '5': {
    title: PHONE_NUMBER,
    dataIndex: 'phone_number',
    key: 'phone_number',
  },
  '7': {
    dataIndex: 'action',
    width: 50,
    render: (text, record) => {
      return (
        <div className="align-center">
          <div onClick={(e) => onClickEdit(e, record)}>
            <Tooltip title={SELECT_CUSTOMERS}>
              <img src={edit} alt="edit" className="pointer ph10" />
            </Tooltip>
          </div>
        </div>
      );
    },
  },
});
