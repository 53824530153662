import React from 'react';
import { observer } from 'mobx-react';
import { Marker, Popup } from 'react-leaflet';
import {
  trailerIcon,
  trailerIconBlue,
  truckIcon,
  truckIconBlue,
} from '../Icon';
import { BLUE_TRAILER, BLUE_TRUCK, TRAILER, TRUCK } from '../../../constants';
import {
  VEHICLE_TYPE_TRAILER,
  VEHICLE_TYPE_TRUCK,
} from '../../../constants/vehicleTypes';

const DispatchMapVehicleMarker = (props) => {
  let { data } = props;
  const { type } = props;
  let icon;
  let text = '';
  switch (type) {
    case VEHICLE_TYPE_TRAILER:
      icon = trailerIcon;
      text = TRAILER;
      break;
    case BLUE_TRAILER:
      icon = trailerIconBlue;
      text = TRAILER;
      break;
    case BLUE_TRUCK:
      icon = truckIconBlue;
      text = TRUCK;
      break;
    case VEHICLE_TYPE_TRUCK:
      icon = truckIcon;
      text = TRUCK;
      break;
  }
  let location;
  let show;
  let obj = true;
  if (
    data?.trailer_current_action?.location_latitude &&
    data?.trailer_current_action?.location_longitude
  ) {
    location = [
      data.trailer_current_action.location_latitude,
      data.trailer_current_action.location_longitude,
    ];
    show = true;
  } else if (data?.vehicle_latitude && data?.vehicle_longitude) {
    location = [data.vehicle_latitude, data.vehicle_longitude];
    show = true;
  } else if (data?.length > 0) {
    location = [data[0].vehicle_latitude, data[0].vehicle_longitude];
    show = true;
    obj = false;
  } else {
    show = false;
  }

  function TruckObjData() {
    return (
      <div>
        <p className="dispatch-map-marker__type delivery text-bold">
          {text}
          <span className="ml10">
            {data?.internal_company_id
              ? data?.internal_company_id
              : data?.vehicle_id}
          </span>
        </p>
        <p className="dispatch-map-marker__info">
          <span className="dispatch-map-marker__company text-bold">
            {data?.location_name}
          </span>
          <br />
          {data?.location_description}
        </p>
      </div>
    );
  }

  function TruckArrData() {
    return (
      <div>
        <p className="dispatch-map-marker__type delivery text-bold">
          {text}
          {data?.length > 0 && (
            <span className="ml10">
              {data[0]?.internal_company_id
                ? data[0]?.internal_company_id
                : data[0]?.vehicle_id}
            </span>
          )}
        </p>
        <p className="dispatch-map-marker__info">
          <span className="dispatch-map-marker__company text-bold">
            {data[0]?.location_name}
          </span>
          <br />
          {data[0]?.location_description}
        </p>
      </div>
    );
  }

  return (
    show && (
      <Marker
        key={data.vehicle_id}
        position={location}
        icon={icon}
        className="dispatch-map-marker"
      >
        <Popup>
          {obj && <TruckObjData />}
          {!obj && <TruckArrData />}
        </Popup>
      </Marker>
    )
  );
};

export default observer(DispatchMapVehicleMarker);
