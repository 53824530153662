import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal } from 'antd';
import { CANCEL } from '../../../constants';
import addOrganization from '../../../assets/images/add-organization.svg';
import {
  CONFIRM_DELETING_DEPARTMENT,
  DEPARTMENT_DELETE,
} from '../../../constants/organization/departments';

const DeleteOrganizationDepartmentModal = () => {
  const {
    currentOrganization: {
      isDeleteDepartmentModalOpened,
      setIsDeleteDepartmentModalOpened,
      deletingDepartment,
    },
  } = useMst();

  const onCancel = () => {
    setIsDeleteDepartmentModalOpened(false);
  };

  const onDepartmentDelete = () => {
    deletingDepartment.deleteDepartment(deletingDepartment.department_id);
  };

  return (
    <div>
      <Modal
        visible={!!isDeleteDepartmentModalOpened}
        footer={null}
        centered={true}
        closable={false}
        onCancel={() => {}}
        width={600}
        className="confirm-delete-modal"
      >
        <div className="p20">
          <div className="align-center mb20">
            <img src={addOrganization} alt="save" className="mr10" />
            <h3>{DEPARTMENT_DELETE}</h3>
          </div>
          <p>{CONFIRM_DELETING_DEPARTMENT}</p>
          <h3>{deletingDepartment?.department_name}</h3>

          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onDepartmentDelete}>
              {DEPARTMENT_DELETE}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(DeleteOrganizationDepartmentModal);
