import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import {
  NOT_AVAILABLE,
  CLASS,
  VIN,
  YEAR,
  LICENCE_PLATE,
  GROSS_WEIGHT,
  EXPIRY_DATE,
  MAKE,
  MODEL,
  OWNERSHIP_TYPE,
  CONNECTED_TRUCK,
  TRAILER_INFORMATION,
  NOTES,
} from '../../../constants';
import { ID } from '../../../constants/dispatch';
import PageRoutes from '../../../utils/PageRoutes';

const TrailerInformation = () => {
  const {
    fleetPageState: { viewedTrailer },
    router,
  } = useMst();

  const onViewTruck = (e) => {
    e.stopPropagation();
    const params = { truckId: viewedTrailer?.truckAssociation[0].truck_id };
    router.setView(router.views.get(PageRoutes.ViewTruck.name), params);
  };
  return (
    <div className="edit-driver-section form-page-section w50 pr10">
      <div className="form-page-subsection">
        <div className="subsection-header mt0">
          <p>{TRAILER_INFORMATION}</p>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{ID}</p>
            <p className="content">
              {viewedTrailer.internal_company_id
                ? viewedTrailer.internal_company_id
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{VIN}</p>
            <p className="content">
              {viewedTrailer.vin ? viewedTrailer.vin : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{OWNERSHIP_TYPE}</p>
            <p className="content text-capitalize">
              {viewedTrailer.ownership_type
                ? viewedTrailer.ownership_type
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{GROSS_WEIGHT}</p>
            <p className="content">
              {viewedTrailer.vehicle_weight
                ? viewedTrailer.vehicle_weight
                : NOT_AVAILABLE}{' '}
              {viewedTrailer.vehicle_weight_unit
                ? viewedTrailer.vehicle_weight_unit
                : NOT_AVAILABLE}
            </p>
          </div>
          {/*<div className="view-page-item-wrapper">*/}
          {/*  <p className="label">{WEIGHT}</p>*/}
          {/*  <p className="content">*/}
          {/*    {viewedTrailer.vehicle_model_type*/}
          {/*      ? viewedTrailer.vehicle_model_type*/}
          {/*      : NOT_AVAILABLE}*/}
          {/*  </p>*/}
          {/*</div>*/}
          <div className="view-page-item-wrapper">
            <p className="label">{LICENCE_PLATE}</p>
            <p className="content">
              {viewedTrailer.activeRegistration?.licence_plate
                ? viewedTrailer.activeRegistration.licence_plate
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{EXPIRY_DATE}</p>
            <p className="content">
              {viewedTrailer.activeRegistration?.expiry_date
                ? viewedTrailer.activeRegistration.expiry_date
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{MAKE}</p>
            <p className="content">
              {viewedTrailer.vehicle_make
                ? viewedTrailer.vehicle_make
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{MODEL}</p>
            <p className="content">
              {viewedTrailer.vehicle_model_name
                ? viewedTrailer.vehicle_model_name
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{YEAR}</p>
            <p className="content">
              {viewedTrailer.vehicle_production_year
                ? viewedTrailer.vehicle_production_year
                : NOT_AVAILABLE}
            </p>
          </div>
        </div>
        <div className="section-content flex-alignitems-flex-start pb20 justify-between">
          <div className="view-page-item-wrapper">
            <p className="label">{CLASS}</p>
            <p className="content">
              {viewedTrailer.vehicle_body_class
                ? viewedTrailer.vehicle_body_class
                : NOT_AVAILABLE}
            </p>
          </div>
          <div className="view-page-item-wrapper">
            <p className="label">{CONNECTED_TRUCK}</p>
            {viewedTrailer?.truckAssociation[0] && (
              <p
                className="content text-capitalize go-to"
                onClick={(e) => onViewTruck(e)}
              >
                {viewedTrailer.truckAssociation[0].truck_internal_company_id
                  ? viewedTrailer.truckAssociation[0].truck_internal_company_id
                  : NOT_AVAILABLE}
                {' - '}
                {viewedTrailer.truckAssociation[0].truck_make
                  ? viewedTrailer.truckAssociation[0].truck_make
                  : NOT_AVAILABLE}
              </p>
            )}
            {!viewedTrailer?.truckAssociation[0] && (
              <p className="content text-capitalize">{NOT_AVAILABLE}</p>
            )}
          </div>
          {/*<div className="view-page-item-wrapper">*/}
          {/*  <p className="label">{MODEL}</p>*/}
          {/*  <p className="content">*/}
          {/*    {viewedTrailer.vehicle_model_name*/}
          {/*      ? viewedTrailer.vehicle_model_name*/}
          {/*      : NOT_AVAILABLE}*/}
          {/*  </p>*/}
          {/*</div>*/}
          <div className="view-page-item-wrapper">
            <p className="label">{NOTES}</p>
            <p className="content">
              {viewedTrailer.notes ? viewedTrailer.notes : NOT_AVAILABLE}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(TrailerInformation);
