import { types } from 'mobx-state-tree';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { setLocalPD } from '../../../actions/shipments';
import { rootInstance } from '../../RootModel';
import { notification } from 'antd';

export const PDShipmentModel = types
  .model('PDShipmentModel', {
    shipment_id: types.identifierNumber,
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setPickupDelivery(data) {
        setFetchingInProgress(true);
        setLocalPD(data)
          .then(() => {
            rootInstance.dispatchPageState.setIsShipmentPDSuccessModalOpened(
              true
            );
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('error: ', error);
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: 'Error fetching route actions!',
              description: errorText,
            });
          });
      },
    };
  });
