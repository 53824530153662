import { notification } from 'antd';
import { types, getRoot } from 'mobx-state-tree';
import {
  deleteShipment,
  getAvailableCreateShipmentStatuses,
  getAvailableShipmentStatuses,
  getShipment,
  getShipments,
  getShipmentSizes,
} from '../../actions/shipments';
import {
  ALL,
  DELETE_SHIPMENT_ERROR,
  FETCH_SHIPMENTS_ERROR,
  FETCH_SHIPMENT_ERROR,
  FETCH_SHIPMENT_STATUSES_ERROR,
  FETCH_QUANTITY_UNITS_ERROR,
  FETCH_WEIGHT_UNITS_ERROR,
} from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { ShipmentModel } from './ShipmentModel';
import { ShipmentLightModel } from './ShipmentLightModel';
import { shipmentsColumnsFunc } from './ShipmentsTableColumns';
import {
  getAvailableQuantityUnits,
  getAvailableWeightUnits,
} from '../../actions/common';
import {
  shipmentProbills,
  shipmentCharges,
  shipmentDocuments,
  shipmentRelatedCharges,
  shipmentAvailableEquipment,
  getEquipmentsList,
  getShipmentNotesPromise,
} from '../../promises/shipments';
import { currencyTypes } from '../../promises/probills';
import { rootInstance } from '../RootModel';
import {
  CREATE_SHIPMENT,
  EDIT_SHIPMENT,
  VIEW_SHIPMENT,
} from '../../constants/menu';

const allSettled = require('promise.allsettled');

export const ShipmentsPageModel = types
  .model('ShipmentsPageModel', {
    shipments: types.map(ShipmentModel),
    searchTerm: types.maybe(types.maybeNull(types.string)),
    currentPage: 1,
    pageSize: 10,
    totalItems: types.maybe(types.maybeNull(types.number)),
    status: types.maybe(types.maybeNull(types.number)),
    activeStatus: true,
    archived: false,
    brokerageShipmentStatus: false,

    availableShipmentStatuses: types.frozen([]),
    availableCreateShipmentStatuses: types.frozen([]),

    availableWeightUnits: types.frozen([]),
    availableQuantityUnits: types.frozen([]),

    newShipment: types.maybe(types.maybeNull(ShipmentLightModel)),
    shipmentInCreation: types.maybe(types.maybeNull(ShipmentModel)),
    createShipmentSuccessModalOpen: false,

    // delete shipment
    shipmentToBeDeleted: types.maybeNull(types.reference(ShipmentModel)),
    confirmDeleteShipmentModalOpen: false,
    successDeleteShipmentModalOpen: false,
    // end delete shipment

    // edit shipment
    editedShipment: types.maybe(types.maybeNull(ShipmentModel)),
    editShipmentSuccessModalOpen: false,
    // end edit shipment

    // view shipment
    viewedShipment: types.maybe(types.maybeNull(ShipmentModel)),
    // end view shipment

    // enable/disable editing
    probillEditLocationEnabled: false,
    showProbillLocationIcons: false,
    showEditedProbillModal: false,
    probillAddNewLocationEnabled: false,
    showAddNewProbillModal: false,

    // planner
    isSendDataToPlannerModalOpen: false,

    // Shipment size
    availableShipmentSizes: types.frozen([]),
    selectedShipmentSize: '',
  })
  .volatile((self) => {
    return {};
  })
  .views((self) => {
    return {
      get shipmentsArray() {
        return Array.from(self.shipments.values());
      },
      get shipmentBeingCreated() {
        if (self.newShipment) {
          return self.newShipment;
        } else if (self.shipmentInCreation) {
          return self.shipmentInCreation;
        }
        return null;
      },
      get isCreateShipmentPage() {
        return rootInstance.router.currentView.name === CREATE_SHIPMENT;
      },
      get isEditShipmentPage() {
        return rootInstance.router.currentView.name === EDIT_SHIPMENT;
      },
      get isViewShipmentPage() {
        return rootInstance.router.currentView.name === VIEW_SHIPMENT;
      },
      get currentShipment() {
        const shipment = this.isCreateShipmentPage
          ? self.shipmentInCreation
          : this.isEditShipmentPage
          ? self.editedShipment
          : this.isViewShipmentPage
          ? self.viewedShipment
          : null;
        return shipment;
      },
    };
  })
  .actions((self) => {
    return {
      setIsSendDataToPlannerModalOpen(value) {
        self.isSendDataToPlannerModalOpen = value;
      },
      setShowEditedProbillModal(value) {
        self.showEditedProbillModal = value;
      },
      setShowAddNewProbillModal(value) {
        self.showAddNewProbillModal = value;
      },
      setProbillLocationIcons(value) {
        self.showProbillLocationIcons = value;
      },
      setProbillEditLocationEnabled(value) {
        self.probillEditLocationEnabled = value;
      },
      setProbillAddNewLocationEnabled(value) {
        self.probillAddNewLocationEnabled = value;
      },
      setCreateShipmentSuccessModalOpen(status) {
        self.createShipmentSuccessModalOpen = status;
      },
      setEditShipmentSuccessModalOpen(status) {
        self.editShipmentSuccessModalOpen = status;
      },
      resetPage() {
        this.setCurrentPage(1);
        this.setStatus(ALL);
      },
      setBrokerageShipmentStatus(status) {
        self.brokerageShipmentStatus = status;
        this.resetPage();
      },
      setArchived(status) {
        self.archived = status;
        this.resetPage();
      },
      setActiveStatus(status) {
        self.activeStatus = status;
        this.resetPage();
      },
      setStatus(status) {
        if (status === ALL) {
          self.status = null;
        } else {
          self.status = status;
        }
        this.getShipments({});
      },
      setNewShipment(shipment) {
        self.newShipment = shipment;
      },
      setSearchTerm(text) {
        self.searchTerm = text;
      },
      setCurrentPage(number) {
        self.currentPage = number;
      },
      setTotalItems(number) {
        self.totalItems = number;
      },
      getShipments({ page = null }) {
        setFetchingInProgress(true);
        this.clearShipments();
        // if page is specified in request, set current page to page requested
        page && this.setCurrentPage(page);
        this.getAvailableShipmentStatuses({ active: self.activeStatus });
        !self.availableWeightUnits.length && this.getAvailableWeightUnits();
        !self.availableQuantityUnits.length && this.getAvailableQuantityUnits();
        getShipments(
          self.searchTerm,
          self.currentPage,
          self.pageSize,
          self.status,
          self.archived,
          self.brokerageShipmentStatus,
          self.activeStatus
        )
          .then((response) => {
            this.setShipments(response.data.shipments);
            this.setTotalItems(response.data.total);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            console.log('error: ', error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_SHIPMENTS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setShipments(items) {
        self.shipments.clear();
        items.forEach((item) => {
          self.shipments.put({
            ...item,
          });
        });
      },
      clearShipments() {
        self.shipments.clear();
      },
      getShipmentsColumns() {
        return Object.values(shipmentsColumnsFunc(getRoot(self)));
      },
      getAvailableShipmentStatuses({ active = true }) {
        setFetchingInProgress(true);
        getAvailableShipmentStatuses({ active })
          .then((response) => {
            this.setAvailableShipmentStatuses(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_SHIPMENT_STATUSES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableShipmentStatuses(items) {
        self.availableShipmentStatuses = items;
      },
      getAvailableCreateShipmentStatuses() {
        setFetchingInProgress(true);
        getAvailableCreateShipmentStatuses()
          .then((response) => {
            this.setAvailableCreateShipmentStatuses(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_SHIPMENT_STATUSES_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableCreateShipmentStatuses(items) {
        self.availableCreateShipmentStatuses = items;
      },

      getAvailableWeightUnits() {
        setFetchingInProgress(true);
        getAvailableWeightUnits()
          .then((response) => {
            this.setAvailableWeightUnits(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_WEIGHT_UNITS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableWeightUnits(items) {
        self.availableWeightUnits = items;
      },

      getAvailableQuantityUnits() {
        setFetchingInProgress(true);
        getAvailableQuantityUnits()
          .then((response) => {
            this.setAvailableQuantityUnits(response.data);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_QUANTITY_UNITS_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setAvailableQuantityUnits(items) {
        self.availableQuantityUnits = items;
      },

      getShipmentInCreation(id) {
        setFetchingInProgress(true);
        getShipment(id)
          .then((response) => {
            this.setShipmentInCreation(response.data);
            this.setNewShipment(null);
            let promises = [];
            promises.push(currencyTypes(self.shipmentInCreation));
            promises.push(shipmentCharges(self.shipmentInCreation));
            promises.push(shipmentAvailableEquipment(self.shipmentInCreation));
            promises.push(getEquipmentsList(self.shipmentInCreation));
            allSettled(promises).then(() => {
              self.shipmentInCreation.setFullyFetched(true);
              setFetchingInProgress(false);
            });
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_SHIPMENT_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setShipmentInCreation(shipment) {
        self.shipmentInCreation = shipment;
      },
      // delete shipment
      setShipmentToBeDeleted(shipment_id) {
        self.shipmentToBeDeleted = null;
        if (shipment_id) {
          this.setConfirmDeleteShipmentModalOpen(true);
        }
        self.shipmentToBeDeleted = shipment_id;
      },
      setConfirmDeleteShipmentModalOpen(status) {
        if (!status) {
          this.setShipmentToBeDeleted(null);
        }
        self.confirmDeleteShipmentModalOpen = status;
      },
      deleteShipment() {
        setFetchingInProgress(true);
        deleteShipment(self.shipmentToBeDeleted.shipment_id)
          .then(() => {
            setFetchingInProgress(false);
            this.setConfirmDeleteShipmentModalOpen(false);
            this.setDeleteShipmentSuccessModalOpen(true);
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: DELETE_SHIPMENT_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      setDeleteShipmentSuccessModalOpen(status) {
        self.successDeleteShipmentModalOpen = status;
      },
      // end delete shipment

      // edit shipment
      getEditedShipment(id) {
        setFetchingInProgress(true);
        getShipment(id)
          .then((shipment_data) => {
            const shipment = shipment_data.data;
            this.setEditedShipment(shipment);
            let promises = [];

            promises.push(shipmentProbills(self.editedShipment));
            promises.push(currencyTypes(self.editedShipment));
            promises.push(shipmentCharges(self.editedShipment));
            promises.push(shipmentDocuments(self.editedShipment));
            promises.push(shipmentRelatedCharges(self.editedShipment));
            promises.push(shipmentAvailableEquipment(self.editedShipment));
            promises.push(getEquipmentsList(self.editedShipment));
            promises.push(getShipmentNotesPromise(self.editedShipment));
            allSettled(promises).then(async () => {
              if (shipment.trailer_type?.equipment_id) {
                await self.editedShipment.setSelectedEquipment(
                  shipment.trailer_type?.equipment_id
                );
                // checks if trailer type is still available in carriers fleet
                const carriersIds =
                  self.editedShipment.carriersWithEquipmentArray.map((item) => {
                    return item.carrier_id;
                  });
                if (
                  shipment.carrier?.carrier_id &&
                  carriersIds.includes(shipment.carrier?.carrier_id)
                ) {
                  self.editedShipment.setSelectedCarrier(
                    shipment.carrier.carrier_id
                  );
                } else {
                  self.editedShipment.setSelectedCarrier(null);
                }
                self.editedShipment.setFullyFetched(true);
              } else {
                self.editedShipment.setFullyFetched(true);
                setFetchingInProgress(false);
              }
            });
          })
          .catch((error) => {
            console.log(error);
            this.throwFetchShipmentError(error);
          });
      },
      setEditedShipment(shipment) {
        self.editedShipment = shipment;
      },
      throwFetchShipmentError(error) {
        const errorText = getErrorText(error);
        notification.error({
          message: FETCH_SHIPMENT_ERROR,
          description: errorText,
        });
        setFetchingInProgress(false);
      },
      // end edit shipment

      // view shipment
      getViewedShipment(id) {
        setFetchingInProgress(true);
        getShipment(id)
          .then((shipment_data) => {
            const shipment = shipment_data.data;
            this.setViewedShipment(shipment);
            let promises = [];
            promises.push(shipmentProbills(self.viewedShipment));
            promises.push(shipmentDocuments(self.viewedShipment));
            promises.push(getEquipmentsList(self.viewedShipment));
            promises.push(shipmentRelatedCharges(self.viewedShipment));
            promises.push(getShipmentNotesPromise(self.viewedShipment));
            allSettled(promises)
              .then(() => {
                self.viewedShipment.setFullyFetched(true);
                setFetchingInProgress(false);
              })
              .catch((error) => {
                this.throwFetchShipmentError(error);
              });
          })
          .catch((error) => {
            this.throwFetchShipmentError(error);
          });
      },
      setViewedShipment(shipment) {
        self.viewedShipment = shipment;
      },

      setAvailableShipmentSizes(sizes) {
        self.availableShipmentSizes = sizes;
      },
      setSelectedShipmentSize(size) {
        self.selectedShipmentSize = size;
        self.shipmentBeingCreated.setShipmentSize(size);
      },
      // end view shipment
      getAvailableShipmentSizes() {
        setFetchingInProgress(true);
        getShipmentSizes()
          .then((response) => {
            this.setAvailableShipmentSizes(response.data);
            this.setSelectedShipmentSize(response?.data[0]);
            setFetchingInProgress(false);
          })
          .catch((error) => {
            setFetchingInProgress(false);
          });
      },
      beforeEnterCreateShipmentPage() {
        this.setNewShipment({});
        this.getAvailableCreateShipmentStatuses();
        this.getAvailableShipmentSizes();
        !self.availableWeightUnits.length && this.getAvailableWeightUnits();
        !self.availableQuantityUnits.length && this.getAvailableQuantityUnits();
      },
      beforeExitCreateShipmentPage() {
        this.setShipmentInCreation(null);
        this.setNewShipment(null);
      },
      beforeEnterEditShipmentPage(id) {
        this.getEditedShipment(id);
        this.getAvailableShipmentSizes();
        this.getAvailableShipmentStatuses({ active: false });
        !self.availableWeightUnits.length && this.getAvailableWeightUnits();
        !self.availableQuantityUnits.length && this.getAvailableQuantityUnits();
      },
      beforeExitEditShipmentPage() {
        this.setEditedShipment(null);
      },
      beforeEnterViewShipmentPage(id) {
        this.getViewedShipment(id);
        // this.getAvailableShipmentStatuses({ active: false });
        // !self.availableWeightUnits.length && this.getAvailableWeightUnits();
        // !self.availableQuantityUnits.length && this.getAvailableQuantityUnits();
      },
      beforeExitViewShipmentPage() {
        this.setViewedShipment(null);
      },
    };
  });
