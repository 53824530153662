import { types } from 'mobx-state-tree';
import { BottomMenuItemModel } from './BottomMenuItemModel';
import { bottomMenuItems } from './BottomMenuItems';

export const BottomMenuModel = types
  .model('MenuModel', {
    bottomMenuItems: types.map(BottomMenuItemModel),
    mobileMenuOpen: false,
  })
  .views((self) => {
    return {
      get bottomMenuItemsArray() {
        return Array.from(self.bottomMenuItems.values());
      },
      get activeItem() {
        const arr = Array.from(self.bottomMenuItems.values());
        const activeItem = arr.find((item) => item.active);
        return activeItem;
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        this.setItems(bottomMenuItems);
      },
      setItems(items) {
        Object.values(items).forEach((item) => {
          self.bottomMenuItems.put(item);
        });
      },
      setActiveItem(title) {
        self.bottomMenuItems.forEach((item) => {
          item.setActive(false);
        });
        if (title) {
          const activeItem = self.bottomMenuItemsArray.find(
            (item) => title === item.title
          );
          activeItem.setActive(true);
        }
      },
    };
  });
