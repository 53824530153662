import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../state/RootModel';
import { Modal } from 'antd';
import FormPageTitle from '../../../FormPageTitle';
import addOtherIcon from '../../../../assets/images/add-other.svg';
import { EDIT } from '../../../../constants';
import EditedShipmentCharge from './ShipmentCharge/EditedShipmentCharge';
import EditedRouteAction from './RouteAction/EditedRouteAction';
import EditedShipmentDocumentType from './ShipmentDocumentType/EditedShipmentDocumentType';
import EditedShipmentStatus from './ShipmentStatus/EditedShipmentStatus';

const EditShipmentSpecificModal = () => {
  const {
    shipmentSpecificsPageState: {
      shipmentChargeToBeEdited,
      setShipmentChargeToBeEdited,
      setEditingShipmentSpecific,
      editedShipmentSpecificType,
      routeActionToBeEdited,
      editingShipmentSpecific,
      setRouteActionToBeEdited,
      setShipmentDocumentTypeToBeEdited,
      shipmentDocumentTypeToBeEdited,
      shipmentStatusToBeEdited,
      setShipmentStatusToBeEdited,
    },
  } = useMst();

  const onCancel = () => {
    setShipmentChargeToBeEdited(null);
    setRouteActionToBeEdited(null);
    setShipmentDocumentTypeToBeEdited(null);
    setShipmentStatusToBeEdited(null);
    setEditingShipmentSpecific(false);
  };

  return (
    <Modal
      visible={!!editingShipmentSpecific}
      footer={null}
      centered={true}
      onCancel={onCancel}
      maskClosable={false}
      width={400}
      className="modal"
      destroyOnClose={true}
    >
      <div className="p32 location-modal-content">
        <div className="align-center">
          <FormPageTitle
            image={addOtherIcon}
            title={EDIT + ' ' + editedShipmentSpecificType}
          />
        </div>
        {shipmentChargeToBeEdited && <EditedShipmentCharge />}
        {routeActionToBeEdited && <EditedRouteAction />}
        {shipmentDocumentTypeToBeEdited && <EditedShipmentDocumentType />}
        {shipmentStatusToBeEdited && <EditedShipmentStatus />}
      </div>
    </Modal>
  );
};

export default observer(EditShipmentSpecificModal);
