import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import { Modal } from 'antd';
import {
  CONFIRM,
  CANCEL,
  CONFIRM_DELETE_MODAL_TEXT,
} from '../../../../../constants';
import { SHIPMENT_STATUS } from '../../../../../constants/shipmentSpecificsTypes';

const ConfirmDeleteShipmentStatus = () => {
  const {
    shipmentSpecificsPageState: {
      shipmentStatusToBeDeleted,
      setShipmentStatusToBeDeleted,
      deleteShipmentStatus,
    },
  } = useMst();

  const onCancel = () => {
    setShipmentStatusToBeDeleted(null);
  };

  const onConfirm = () => {
    deleteShipmentStatus(shipmentStatusToBeDeleted.status_id);
  };

  return (
    shipmentStatusToBeDeleted && (
      <div>
        <Modal
          visible={!!shipmentStatusToBeDeleted}
          footer={null}
          centered={true}
          closable={false}
          onCancel={() => {}}
          width={400}
          className="confirm-delete-modal"
        >
          <div className="p32">
            <div>
              <p>
                {CONFIRM_DELETE_MODAL_TEXT} {SHIPMENT_STATUS.toLowerCase()}{' '}
                {shipmentStatusToBeDeleted.name}?
              </p>
            </div>
            <div className="justify-end mt50">
              <button className="btn white mr10" onClick={onCancel}>
                {CANCEL}
              </button>
              <button className="btn primary" onClick={onConfirm}>
                {CONFIRM}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(ConfirmDeleteShipmentStatus);
