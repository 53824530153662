import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import dropdownIcon from '../../assets/images/dropdown-icon.svg';
import { ORGANIZATIONS } from '../../constants/menu';
import OrganizationTable from '../../components/organizations/Organization/OrganizationTable';
import AddOrganizationModal from '../../components/organizations/Organization/AddOrganizationModal';
import DeleteOrganizationModal from '../../components/organizations/Organization/DeleteOrganizationModal';

const OrganizationPage = () => {
  const {
    organizationState: { isOrganizationsListOpened, setOrganizationListOpen },
  } = useMst();

  return (
    <div className="page">
      <div className="page-content">
        <div className="table-title-row align-center">
          <div
            className="pointer"
            onClick={() => setOrganizationListOpen(!isOrganizationsListOpened)}
          >
            <img
              src={dropdownIcon}
              alt="dropdown"
              className={`dropdown-icon ${
                isOrganizationsListOpened ? 'open' : ''
              }`}
              height="14"
            />
            <span className="text-capitalize color-primary text-medium ml10">
              {ORGANIZATIONS}
            </span>
          </div>
        </div>
        {isOrganizationsListOpened && (
          <div>
            <OrganizationTable />
            {/*Modals*/}
            <AddOrganizationModal />
            <DeleteOrganizationModal />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(OrganizationPage);
