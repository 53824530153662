import React from 'react';
import { observer } from 'mobx-react';

const DashboardPage = () => {
  return (
    <div className="page">
      <div className="page-content">
        <h1 className="text-center page-title">Dashboard Page</h1>
      </div>
    </div>
  );
};

export default observer(DashboardPage);
