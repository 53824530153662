import { types } from 'mobx-state-tree';
import { TruckModel } from '../trucks/TruckModel';

export const AssociationModel = types
  .model('AssociationModel', {
    association_id: types.identifierNumber,
    trailer_id: types.maybe(types.maybeNull(types.number)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),
    start_date: types.maybe(types.maybeNull(types.string)),
    end_date: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(types.maybeNull(types.string)),
    truck_id: types.maybe(types.maybeNull(types.number)),
    truck_make: types.maybe(types.maybeNull(types.string)),
    truck_model_name: types.maybe(types.maybeNull(types.string)),
    truck_internal_company_id: types.maybe(types.maybeNull(types.string)),
    associated_truck: types.maybe(types.maybeNull(TruckModel)),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setAssociatedTruck(associated_truck) {
        self.associated_truck = associated_truck;
      },
    };
  });
