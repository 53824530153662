import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { PageRoutes } from '../../utils/PageRoutes';
// import FleetOverview from '../../components/fleet/FleetOverview';
import FleetList from '../../components/fleet/FleetList';
import ConfirmDeleteTruckModal from '../../components/fleet/ConfirmDeleteTruckModal';
import ConfirmDeleteTrailerModal from '../../components/fleet/ConfirmDeleteTrailerModal';
import ConfirmDeleteDriverModal from '../../components/fleet/ConfirmDeleteDriverModal';
import SuccessModal from '../../components/SuccessModal';
import {
  DELETE_DRIVER_MODAL_SUCCESS_BUTTON_TEXT,
  DELETE_DRIVER_MODAL_SUCCESS_TEXT,
  DELETE_MODAL_SUCCESS_TITLE,
  DELETE_VEHICLE_MODAL_SUCCESS_TEXT,
  DELETE_VEHICLE_MODAL_SUCCESS_BUTTON_TEXT,
} from '../../constants';

const FleetPage = () => {
  const {
    fleetPageState: {
      successDeleteDriverModalOpen,
      setDeleteDriverSuccessModalOpen,
      successDeleteTruckModalOpen,
      setDeleteTruckSuccessModalOpen,
      successDeleteTrailerModalOpen,
      setDeleteTrailerSuccessModalOpen,
    },
  } = useMst();

  const backLink = PageRoutes.Fleet.name;

  return (
    <div className="page fleet-page">
      <ConfirmDeleteTruckModal />
      <ConfirmDeleteDriverModal />
      <ConfirmDeleteTrailerModal />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_DRIVER_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteDriverModalOpen}
        buttonText={DELETE_DRIVER_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setDeleteDriverSuccessModalOpen(false)}
      />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_VEHICLE_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteTruckModalOpen}
        buttonText={DELETE_VEHICLE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setDeleteTruckSuccessModalOpen(false)}
      />
      <SuccessModal
        title={DELETE_MODAL_SUCCESS_TITLE}
        text={DELETE_VEHICLE_MODAL_SUCCESS_TEXT}
        backLink={backLink}
        open={successDeleteTrailerModalOpen}
        buttonText={DELETE_VEHICLE_MODAL_SUCCESS_BUTTON_TEXT}
        closeModal={() => setDeleteTrailerSuccessModalOpen(false)}
      />
      <div className="page-content">
        <div className="fleet-page-content">
          {/* <FleetOverview /> */}
          <FleetList />
        </div>
      </div>
    </div>
  );
};

export default observer(FleetPage);
