import { notification } from 'antd';
import { observable } from 'mobx';
import { types } from 'mobx-state-tree';
import { searchClients } from '../../actions/clients';
import {
  editLocationCoordinates,
  editLocation,
  addLocation,
} from '../../actions/locations';
import {
  EDIT_LOCATION_ERROR,
  ADDRESS_REQUIRED,
  CITY_REQUIRED,
  COMPANY_REQUIRED,
  COUNTRY_REQUIRED,
  LOCATION_NAME_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  STATE_REQUIRED,
  LOCATIONS,
  SERVER_ERROR_TEXT,
  FETCH_LOCATIONS_ERROR,
} from '../../constants';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { rootInstance } from '../RootModel';
import { getAllCountries } from '../../actions/common';

export const LocationModel = types
  .model('LocationModel', {
    location_id: types.identifierNumber,
    owner_id: types.maybe(types.maybeNull(types.number)),
    owner_name: types.maybe(types.maybeNull(types.string)),
    owner: types.frozen({}),
    address: types.maybe(types.maybeNull(types.string)),
    city: types.maybe(types.maybeNull(types.string)),
    country: types.maybe(types.maybeNull(types.string)),
    state: types.maybe(types.maybeNull(types.string)),
    postal_code: types.maybe(types.maybeNull(types.string)),
    location_contact_name: types.maybe(types.maybeNull(types.string)),
    location_name: types.maybe(types.maybeNull(types.string)),
    phone_number: types.maybe(types.maybeNull(types.string)),
    location_latitude: types.maybe(types.maybeNull(types.number)),
    location_longitude: types.maybe(types.maybeNull(types.number)),
    organization_id: types.maybe(types.maybeNull(types.number)),
    zone: types.maybe(types.maybeNull(types.string)),
    is_archived: types.maybe(types.maybeNull(types.boolean)),

    isSearchFieldVisible: false,
    customerSearchTerm: types.maybe(types.maybeNull(types.string)),
    notes: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    countries: types.frozen([]),
  })
  .volatile((self) => {
    return {
      companies: observable([]),
      latlng: observable([]),
    };
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setNotes(notes) {
        self.notes = notes;
      },
      setCountries(countries) {
        self.countries = countries;
      },
      getCountriesList() {
        getAllCountries()
          .then((response) => {
            this.setCountries(response.data);
          })
          .catch((error) => {
            console.log(error);
            const errorText = getErrorText(error);
            notification.error({
              message: FETCH_LOCATIONS_ERROR,
              description: errorText,
            });
          });
      },
      setIsSearchFieldVisible(status) {
        self.isSearchFieldVisible = status;
      },
      setLatLng(coordinates) {
        self.latlng = coordinates;
      },
      setCompanies(items) {
        self.companies = items;
      },
      setCustomerSearchTerm(text) {
        self.customerSearchTerm = text;
        this.searchCustomers();
      },
      searchCustomers(text) {
        searchClients(text).then((response) => {
          response.data.forEach((item) => {
            item.value = item.client_id;
            item.label = item.company;
          });
          return response.data;
        });
      },
      setOwnerId(number) {
        self.owner_id = number;
      },
      setLocationName(text) {
        self.location_name = text;
      },
      setLocationContactName(text) {
        self.location_contact_name = text;
      },
      setPhoneNumber(phone_number) {
        self.phone_number = phone_number;
      },
      setAddress(text) {
        self.address = text;
      },
      setCountry(text) {
        self.country = text;
        this.setState('');
      },
      setCity(text) {
        self.city = text;
      },
      setState(text) {
        self.state = text;
      },
      setPostalCode(text) {
        self.postal_code = text;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setLocationLatitude(lat) {
        self.location_latitude = lat;
      },
      setLocationLongitude(lng) {
        self.location_longitude = lng;
      },
      editCoordinates() {
        setFetchingInProgress(true);
        editLocationCoordinates(self)
          .then((response) => {
            rootInstance.locationsPageState.setDraggableEditedLocation(null);
            setFetchingInProgress(false);
            rootInstance.locationsPageState.setAddLocationSuccessModalOpen(
              true
            );
          })
          .catch((error) => {
            const errorText = getErrorText(error);
            notification.error({
              message: EDIT_LOCATION_ERROR,
              description: errorText,
            });
            setFetchingInProgress(false);
          });
      },
      throwSaveLocationError(error) {
        const errorText = getErrorText(error);
        this.setErrorText(errorText);
        setFetchingInProgress(false);
      },
      validateForm() {
        if (!self.location_name) {
          this.setErrorText(LOCATION_NAME_REQUIRED);
        } else if (!self.owner_id) {
          this.setErrorText(COMPANY_REQUIRED);
        } else if (!self.phone_number) {
          this.setErrorText(PHONE_NUMBER_REQUIRED);
        } else if (!self.country) {
          this.setErrorText(COUNTRY_REQUIRED);
        } else if (!self.state) {
          this.setErrorText(STATE_REQUIRED);
        } else if (!self.city) {
          this.setErrorText(CITY_REQUIRED);
        } else if (!self.address) {
          this.setErrorText(ADDRESS_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      addNewLocation() {
        this.validateForm();
        if (this.validateForm()) {
          setFetchingInProgress(true);
          addLocation(self)
            .then((location) => {
              rootInstance.locationsPageState.setEditLocationSuccessModalOpen(
                true
              );
              rootInstance.shipmentsPageState.setShowAddNewProbillModal(false);
              rootInstance.shipmentsPageState.setProbillAddNewLocationEnabled(
                false
              );
              setFetchingInProgress(false);

              notification.success({
                message: 'Success',
                description: 'New location is saved.',
              });
            })
            .catch((error) => {
              const errorText = getErrorText(error);
              notification.error({
                message: errorText,
                description:
                  'Please edit address, location name and location contact name fields and try again.',
              });
              rootInstance.shipmentsPageState.setShowAddNewProbillModal(false);
              this.throwSaveLocationError(error);
            });
        }
      },
      saveLocation() {
        this.validateForm();
        if (this.validateForm()) {
          setFetchingInProgress(true);
          editLocation(self)
            .then((location) => {
              if (!self.location_latitude && !self.location_longitude) {
                rootInstance.locationsPageState.getDraggableEditedLocation(
                  location.data.location_id
                );
              } else {
                rootInstance.locationsPageState.setEditLocationSuccessModalOpen(
                  true
                );
              }

              notification.success({
                message: 'Success',
                description: 'Location has been edited.',
              });

              rootInstance.shipmentsPageState.setShowEditedProbillModal(false);
              rootInstance.shipmentsPageState.setProbillEditLocationEnabled(
                false
              );
              rootInstance.locationsPageState.setEditedLocation(null);
              rootInstance.brokeragePageState.setActiveTab(LOCATIONS);
              setFetchingInProgress(false);
            })
            .catch((error) => {
              const errorText = getErrorText(error);
              notification.error({
                message: errorText,
                description: SERVER_ERROR_TEXT,
              });
              rootInstance.shipmentsPageState.setShowEditedProbillModal(false);
              this.throwSaveLocationError(error);
            });
        }
      },
    };
  });
