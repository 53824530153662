import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { rootInstance, useMst } from '../../../state/RootModel';
import {
  CHOOSE_DRIVER,
  CHOOSE_TRAILER,
  CHOOSE_TRUCK,
  SAVE_ACTION,
  LINK_SHIPMENT,
  CHOOSE_TEAM_DRIVER,
  NO_LOCATIONS_LOADED,
} from '../../../constants/dispatch';
import { DatePicker, Input, Select, TimePicker, Checkbox } from 'antd';
import {
  ACTION_TYPE,
  ADD_LOCATION,
  BILL_TO,
  CUSTOMER,
  DISCARD,
  LOCATION,
  NOTE_PLACEHOLDER,
  NOTES,
  START_DATE,
  START_TIME,
  CARGO_STATUS,
  EMPTY,
  LOADED,
} from '../../../constants';
import ErrorBox from '../../ErrorBox';
import dispatchExpandList from '../../../assets/images/dispatch/dispatch-expand-list.svg';
import removeItemIcon from '../../../assets/images/close-sign.svg';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import plusCircle from '../../../assets/images/plus-circle.svg';

const { Option } = Select;
const { TextArea } = Input;

const DispatchNewRouteActionItem = (props) => {
  const {
    dispatchPageState: {
      getOrganizeTourShipments,
      getOrganizeTourCustomers,
      getOrganizeTourDrivers,
      getOrganizeTourTrailers,
      getOrganizeTourAllActions,
      setIsOrganizeTourShipmentsModalOpen,
      setIsOrganizeTourCustomersModalOpen,
      setIsOrganizeTourDriversModalOpen,
      setIsOrganizeTourTeamDriversModalOpen,
      setIsOrganizeTourTrailersModalOpen,
      getAllAvailableLocations,
      getOrganizeTourTrucks,
      setIsOrganizeTourTrucksModalOpen,
      // Load route action data one-by-one
      loadRouteActionShipments,
      loadRouteActionCustomers,
      loadRouteActionDrivers,
      loadRouteActionTrucks,
      loadRouteActionTrailers,
      loadRouteActions,
      resetOgTrailerData,
      resetOgTrucksData,
      resetOgDriverData,
      setLocationsSearchText,
      getAllFilteredAvailableLocations,
    },
  } = useMst();

  const { data } = props;

  const onChangeSelectedShipment = (shipment_id) => {
    rootInstance.dispatchPageState.beforeEnterSetSelectedShipment(shipment_id);
    data.setSelectedShipmentID(shipment_id);
  };
  const onSetSelectedDriver = (driver) => {
    data.setSelectedDriver(driver);
  };
  const onTripEventChange = (id) => {
    data.setActionID(id);
    const selectedAction = getOrganizeTourAllActions.filter(
      (x) => x.action_id === id
    )[0].name;
    data.setLocationByActionType(selectedAction);
  };
  const onStartDateChange = (value, string) => {
    data.setStartDate(string);
  };
  const onStartTimeChange = (value, string) => {
    data.setStartTime(string);
  };

  const onChangeNote = (e) => {
    data.setNote(e.target.value);
  };
  const onTripDiscard = () => {
    rootInstance.dispatchPageState.removeTripItem(data.trip_id);
  };
  const onChangeSelectedTruck = (truck) => {
    data.changeSelectedTruck(truck);
  };
  const onChangeSelectedTrailer = (trailer) => {
    data.changeSelectedTrailer(trailer);
  };

  const onChangeEmptyMiles = (emptyMiles) => {
    data.setEmptyMiles(emptyMiles);
  };

  /** Modals **/
  const onOpenShipmentsListModal = () => {
    setIsOrganizeTourShipmentsModalOpen(true);
    loadRouteActionShipments();
  };
  const onOpenCustomerListModal = () => {
    setIsOrganizeTourCustomersModalOpen(true);
    loadRouteActionCustomers();
  };
  const onOpenDriversListModal = () => {
    resetOgDriverData();
    setIsOrganizeTourDriversModalOpen(true);
    loadRouteActionDrivers();
  };
  const onOpenTeamDriversListModal = () => {
    resetOgDriverData();
    setIsOrganizeTourTeamDriversModalOpen(true);
    loadRouteActionDrivers();
  };
  const onOpenTrailersListModal = () => {
    resetOgTrailerData();
    setIsOrganizeTourTrailersModalOpen(true);
    loadRouteActionTrailers();
  };
  const onOpenTrucksListModal = () => {
    resetOgTrucksData();
    setIsOrganizeTourTrucksModalOpen(true);
    loadRouteActionTrucks();
  };
  const onShipmentsDropdownOpen = (value) => {
    if (value) {
      loadRouteActionShipments();
    }
  };
  const onCustomersDropdownOpen = (value) => {
    if (value) {
      loadRouteActionCustomers();
    }
  };
  const onDriversDropdownOpen = (value) => {
    if (value) {
      loadRouteActionDrivers();
    }
  };
  const onTrailersDropdownOpen = (value) => {
    if (value) {
      resetOgTrailerData();
      loadRouteActionTrailers();
    }
  };
  const onRouteActionsDropdownOpen = (value) => {
    if (value) {
      loadRouteActions();
    }
  };

  const onTrucksDropdownOpen = (value) => {
    if (value) {
      resetOgTrucksData();
      loadRouteActionTrucks();
    }
  };

  // React state to track order of items
  const [itemList, setItemList] = useState(data.getSelectedLocationsList);

  useEffect(() => {
    setItemList(data.locationsLoaded ? data.getSelectedLocationsList : []);
  }, [data.locationsLoaded]);

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    let updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    data.setSelectedLocations(updatedList);
    data.setUpdatedLocationsList(updatedList);
    data.setIsLocationListChanged(true);
    setItemList(updatedList);
  };

  const onUpdateLocationsList = (location) => {
    let selected_location = getAllAvailableLocations.filter(
      (loc) => loc.location_id === location
    )[0];
    let updatedList = [...itemList];
    updatedList.push(selected_location);
    data.setSelectedLocations(updatedList);
    data.setUpdatedLocationsList(updatedList);
    data.setIsLocationListChanged(true);
    data.setEnableAddingLocation(false);
    setItemList(updatedList);
    setLocationsSearchText('');
    // data.setSelectedLocationID(location);
  };

  const removeItemFromList = (e, item) => {
    const updatedList = itemList.filter(
      (el) => el.location_id !== item.location_id
    );
    data.setSelectedLocations(updatedList);
    data.setUpdatedLocationsList(updatedList);
    data.setIsLocationListChanged(true);
    setItemList(updatedList);
  };

  const onSearchLocationsList = (value) => {
    setLocationsSearchText(value);
  };

  const onTripSave = () => {
    data.saveNewTrip();
  };

  return (
    <div className="inside-details__single-trip ph40 pb20 pt20">
      <div className="input-wrapper mb20 w100">
        <div className="input-label justify-between">
          <span>{LINK_SHIPMENT}</span>
        </div>
        <div className="align-center">
          <Select
            className="w100"
            value={data.shipment_id}
            onDropdownVisibleChange={onShipmentsDropdownOpen}
            onChange={onChangeSelectedShipment}
          >
            <Option key={0} value={0}>
              No shipment selected
            </Option>
            {getOrganizeTourShipments?.map((shipment) => (
              <Option key={shipment.shipment_id} value={shipment.shipment_id}>
                {shipment.shipment_number}
              </Option>
            ))}
          </Select>
          <button
            type="button"
            className="btn transparent small"
            onClick={onOpenShipmentsListModal}
          >
            <img src={dispatchExpandList} alt="" />
          </button>
        </div>
      </div>
      {data.shipment_id && (
        <div className="input-wrapper mb20 w100">
          <div className="input-label justify-between">
            <span>
              {LOCATION}
              <span className="color-secondary d-iblock ml10">*</span>
            </span>
          </div>
          {itemList.length > 0 && (
            <DragDropContext onDragEnd={handleDrop}>
              <Droppable droppableId="locations-list">
                {(provided, snapshot) => (
                  <div
                    className={`locations-list mb20 ${
                      itemList.length > 1 ? '' : 'hide-dots'
                    }`}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {itemList.map((item, index) => (
                      <Draggable
                        key={item.location_id}
                        draggableId={item.location_name}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="locations-list__item justify-between align-center "
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <span>{item.location_name}</span>
                            <img
                              width="15"
                              src={removeItemIcon}
                              alt=""
                              onClick={(e) => removeItemFromList(e, item)}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
          {itemList.length === 0 && (
            <div className="input-wrapper mb20 w100">
              <span>{NO_LOCATIONS_LOADED}</span>
            </div>
          )}
          {!data.enableAddingLocation && (
            <div className="align-center">
              <img
                src={plusCircle}
                alt="add detail"
                className="mr10 pointer"
                onClick={() => data.setEnableAddingLocation(true)}
              />
              <p>{ADD_LOCATION}</p>
            </div>
          )}
          {data.enableAddingLocation && (
            <Select
              showSearch
              optionFilterProp="children"
              className="w100"
              value={data.selected_location_id}
              onChange={onUpdateLocationsList}
              onSearch={onSearchLocationsList}
            >
              {getAllFilteredAvailableLocations.map((location) => (
                <Option key={location.location_id} value={location.location_id}>
                  {location.location_name}
                </Option>
              ))}
            </Select>
          )}
        </div>
      )}
      <div className="input-wrapper mb20 w100">
        <div className="input-label justify-between">
          <span>{BILL_TO + ' ' + CUSTOMER}</span>
        </div>
        <div className="align-center">
          <Select
            className="w100"
            value={data.bill_to}
            disabled={!!data.shipment_id}
            onDropdownVisibleChange={onCustomersDropdownOpen}
            onChange={data.setCustomerToBill}
          >
            <Option key={0} value={0}>
              No customer selected
            </Option>
            {getOrganizeTourCustomers?.map((customer) => (
              <Option key={customer.client_id} value={customer.client_id}>
                {customer.company}
              </Option>
            ))}
          </Select>
          <button
            type="button"
            className={`btn transparent small ${
              !!data?.shipment_id ? 'disabled' : ''
            }`}
            disabled={!!data.shipment_id}
            onClick={onOpenCustomerListModal}
          >
            <img src={dispatchExpandList} alt="" />
          </button>
        </div>
      </div>
      <div className="input-wrapper mb20 w100">
        <div className="input-label justify-between">
          <span>
            {CHOOSE_DRIVER}
            <span className="color-secondary d-iblock ml10">*</span>
          </span>
        </div>
        <div className="align-center">
          <Select
            className="w100"
            value={data.driver_id}
            onDropdownVisibleChange={onDriversDropdownOpen}
            onChange={onSetSelectedDriver}
          >
            {getOrganizeTourDrivers.map((driver) => (
              <Option key={driver.driver_id} value={driver.driver_id}>
                {driver?.internal_company_id
                  ? driver?.internal_company_id + ' - '
                  : 'NA - '}
                {driver.code_name}
              </Option>
            ))}
          </Select>
          <button
            type="button"
            className="btn transparent small"
            onClick={onOpenDriversListModal}
          >
            <img src={dispatchExpandList} alt="" />
          </button>
        </div>
      </div>
      <div className="input-wrapper mb20 w100">
        <div className="input-label justify-between">
          <span>{CHOOSE_TEAM_DRIVER}</span>
        </div>
        <div className="align-center">
          <Select
            className="w100"
            value={data.team_driver_id}
            onDropdownVisibleChange={onDriversDropdownOpen}
            onChange={data.setTeamDriver}
          >
            {getOrganizeTourDrivers.map((driver) => (
              <Option
                disabled={data.driver_id === driver.driver_id}
                key={driver.driver_id}
                value={driver.driver_id}
              >
                {driver?.internal_company_id
                  ? driver?.internal_company_id + ' - '
                  : 'NA - '}
                {driver.code_name}
              </Option>
            ))}
          </Select>
          <button
            type="button"
            className="btn transparent small"
            onClick={onOpenTeamDriversListModal}
          >
            <img src={dispatchExpandList} alt="" />
          </button>
        </div>
      </div>
      {data.driver_id && (
        <div className="input-wrapper mb20 w100">
          <div className="input-label justify-between">
            <span>{CHOOSE_TRUCK}</span>
          </div>
          <div className="align-center">
            <Select
              key={data?.truck}
              className="w100"
              onDropdownVisibleChange={onTrucksDropdownOpen}
              onChange={onChangeSelectedTruck}
              value={data?.truck}
            >
              {getOrganizeTourTrucks.map((truck) => (
                <Option key={truck.vehicle_id} value={truck.vehicle_id}>
                  {truck.internal_company_id + ' - ' + truck.vehicle_make}
                </Option>
              ))}
            </Select>
            <button
              type="button"
              className="btn transparent small"
              onClick={onOpenTrucksListModal}
            >
              <img src={dispatchExpandList} alt="" />
            </button>
          </div>
        </div>
      )}
      <div className="input-wrapper mb20 w100 inside-details__add-trip-event">
        <div className="input-label justify-between">
          <span>
            {ACTION_TYPE}
            <span className="color-secondary d-iblock ml10">*</span>
          </span>
        </div>
        <Select
          key={data.action_id}
          className="w100"
          onChange={(e) => onTripEventChange(e)}
          onDropdownVisibleChange={onRouteActionsDropdownOpen}
          value={data.action_id}
        >
          {getOrganizeTourAllActions.map((trip) => (
            <Option key={trip.action_id} value={trip.action_id}>
              {trip.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="input-wrapper mb20 w100">
        <div className="input-label justify-between">
          <span>{CHOOSE_TRAILER}</span>
        </div>
        <div className="align-center">
          <Select
            className="w100"
            value={data.trailer_id}
            onDropdownVisibleChange={onTrailersDropdownOpen}
            onChange={onChangeSelectedTrailer}
          >
            {getOrganizeTourTrailers.map((trailer) => (
              <Option key={trailer.vehicle_id} value={trailer.vehicle_id}>
                {trailer?.internal_company_id
                  ? trailer?.internal_company_id + ' - '
                  : 'NA - '}
                {trailer.trailer_type ? trailer.trailer_type : 'NA'}
              </Option>
            ))}
          </Select>
          <button
            type="button"
            className="btn transparent small"
            onClick={onOpenTrailersListModal}
          >
            <img src={dispatchExpandList} alt="" />
          </button>
        </div>
      </div>

      <div className="input-wrapper mb20 w100 inside-details__add-trip-event">
        <div className="input-label justify-between">
          <span>
            {CARGO_STATUS}
            <span className="color-secondary d-iblock ml10">*</span>
          </span>
        </div>
        <Checkbox
          onChange={() => onChangeEmptyMiles(true)}
          checked={!!data.empty_miles}
        >
          {EMPTY}
        </Checkbox>
        <Checkbox
          onChange={() => onChangeEmptyMiles(false)}
          checked={!data.empty_miles}
        >
          {LOADED}
        </Checkbox>
      </div>

      <div className="input-wrapper mb20 w100 inside-details__add-trip-event">
        <div className="input-label justify-between">
          <span>
            {START_DATE}
            <span className="color-secondary d-iblock ml10">*</span>
          </span>
        </div>
        <DatePicker
          className="w100"
          value={data.start_date ? moment(data.start_date) : null}
          onChange={onStartDateChange}
        />
      </div>
      <div className="input-wrapper mb20 w100 inside-details__add-trip-event">
        <div className="input-label justify-between">
          <span>
            {START_TIME}
            <span className="color-secondary d-iblock ml10">*</span>
          </span>
        </div>
        <TimePicker
          className="w100"
          format={'HH:mm'}
          value={data.start_time ? moment(data.start_time, 'HH:mm') : null}
          onChange={onStartTimeChange}
        />
      </div>

      <div className="input-wrapper mb20 w100">
        <div className="input-label justify-between">
          <span>{NOTES}</span>
        </div>
        <TextArea
          rows={4}
          placeholder={NOTE_PLACEHOLDER}
          onChange={onChangeNote}
          value={data.note}
        />
      </div>
      <div className="ph20">
        <ErrorBox obj={data} />
      </div>
      <div className="action-buttons align-center flex-start mt40">
        <button
          type="button"
          className="btn primary add text-capitalize"
          onClick={onTripSave}
        >
          {SAVE_ACTION}
        </button>
        <button
          type="button"
          className="btn transparent discard text-capitalize ml10"
          onClick={onTripDiscard}
        >
          {DISCARD}
        </button>
      </div>
    </div>
  );
};

export default observer(DispatchNewRouteActionItem);
