import { notification } from 'antd';
import { types } from 'mobx-state-tree';
import {
  DEFAULT_VALUE,
  ERROR_DELETING_COMPENSATION_DEDUCTION,
} from '../../../constants/payroll';
import { deleteCompensationDeductionPromise } from '../../../promises/payroll';
import { getErrorText, setFetchingInProgress } from '../../../utils/methods';
import { rootInstance } from '../../RootModel';

export const CompensationDeductionModel = types
  .model('CompensationDeductionModel', {
    compensation_deduction_id: types.identifierNumber,
    deduction_type: types.maybe(types.maybeNull(types.string)),
    based_on: types.maybe(types.maybeNull(types.string)),
    rate: types.maybe(types.maybeNull(types.string)),
    default_value: types.maybe(types.maybeNull(types.number)),
    currency: types.maybe(types.maybeNull(types.string)),

    default_value_temp: types.maybeNull(
      types.optional(types.string, DEFAULT_VALUE)
    ),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setDefaultValueTemp(value) {
        self.default_value_temp = value;
        self.default_value = parseFloat(value);
      },
      setBasedOn(value) {
        self.based_on = value;
      },
      setRate(value) {
        self.rate = value;
      },
      setCurrency(value) {
        self.currency = value;
      },
      setDefaultValue(value) {
        self.default_value = value;
      },
      deleteCompensationDeduction(compensation_id, compensation_deduction_id) {
        setFetchingInProgress(true);
        deleteCompensationDeductionPromise(
          compensation_id,
          compensation_deduction_id
        )
          .then(() => {
            setFetchingInProgress(false);
            rootInstance.payrollPageState.setDeductionToBeDeleted(null);
            rootInstance.payrollPageState.setIsDeleteDeductionModalOpen(false);
            rootInstance.payrollPageState.getCompensationStructures();
          })

          .catch((error) => {
            setFetchingInProgress(false);
            const errorText = getErrorText(error);
            notification.error({
              message: ERROR_DELETING_COMPENSATION_DEDUCTION,
              description: errorText,
            });
          });
      },
    };
  });
