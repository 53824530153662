import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import { OUR_WEBSITE } from '../../constants';

const BottomMenu = () => {
  const {
    bottomMenuState: { bottomMenuItemsArray, setActiveItem },
  } = useMst();

  const openItem = (e, title) => {
    e.preventDefault();
    setActiveItem(title);
  };

  return (
    <div className="bottom-menu">
      <div className="justify-between align-center">
        {bottomMenuItemsArray.map((item) => {
          return (
            <a
              key={item.id}
              className={`bottom-menu-link ${item.active && 'active'}`}
              onClick={(e) => openItem(e, item.title)}
              href="# "
            >
              <span className="bottom-menu-title">{item.title}</span>
            </a>
          );
        })}
        <a className="bottom-menu-link" href="# ">
          <span className="bottom-menu-title">{OUR_WEBSITE}</span>
        </a>
      </div>
    </div>
  );
};

export default observer(BottomMenu);
