import React from 'react';
import { observer } from 'mobx-react';
import {
  SHIPMENT_TOTAL_CHARGES,
  BASE_CHARGE,
  TOTAL_CHARGES,
} from '../../../constants';
import { useMst } from '../../../state/RootModel';

const ShipmentTotalCharges = () => {
  const {
    shipmentsPageState: {
      currentShipment: { baseChargeAmount, totalChargesAmount },
    },
  } = useMst();

  return (
    <div className="page-section shipment-details-section w30 ml5pc">
      <div className="subsection-header align-center">
        <p>{SHIPMENT_TOTAL_CHARGES}</p>
      </div>
      <ul className="subsection-content flex-column w90">
        <li className="align-center justify-between pv10">
          <div className="no-wrap">{BASE_CHARGE}:</div>
          <div>{baseChargeAmount}</div>
        </li>
        <li className="w100 align-center justify-between pv10 bt1solid">
          <div className="no-wrap">{TOTAL_CHARGES}:</div>
          <div>{totalChargesAmount}</div>
        </li>
      </ul>
    </div>
  );
};

export default observer(ShipmentTotalCharges);
