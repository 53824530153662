import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import { CATEGORY_TYPE, EQUIPMENT_DETAILS } from '../../../../../constants';
import { Select } from 'antd';
import plusCircle from '../../../../../assets/images/plus-circle.svg';

const { Option } = Select;
const EquipmentDetails = () => {
  const {
    carriersPageState: { editedCarrier, equipments },
  } = useMst();

  /**
   * @param id
   * @param index
   */
  const onEquipmentChange = (id, index) => {
    const equipmentItem = equipments.find((equipment) => {
      return equipment.equipment_id === id;
    });
    editedCarrier.setEditEquipmentItem(index, equipmentItem);
  };
  const onAddEquipmentType = () => {
    editedCarrier.addNewEquipmentItem({});
  };

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header align-center">
        <p>{EQUIPMENT_DETAILS}</p>
        <img
          src={plusCircle}
          alt="add equipment"
          className="ml20 pointer"
          onClick={onAddEquipmentType}
        />
      </div>
      {editedCarrier?.getEquipmentItemsArray &&
        editedCarrier?.getEquipmentItemsArray.map((eq, index) => (
          <div className="input-wrapper mt20" key={eq.carrier_equipment_id}>
            <div className="input-label justify-between">{CATEGORY_TYPE}</div>
            <Select
              className="text-capitalize"
              style={{ width: '100%' }}
              onChange={(id) => onEquipmentChange(id, eq.carrier_equipment_id)}
              value={eq?.equipment_id}
            >
              {equipments.map((item, index) => (
                <Option
                  key={index}
                  value={item.equipment_id}
                  className="text-capitalize"
                >
                  {item.equipment_category} / {item.equipment_type}
                </Option>
              ))}
            </Select>
          </div>
        ))}
    </div>
  );
};

export default observer(EquipmentDetails);
