import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
// import { notification } from 'antd';
import addEmployeeIcon from '../../../assets/images/menu/add-employee.svg';
import ErrorBox from '../../ErrorBox';
import FormPageTitle from '../../FormPageTitle';
import GeneralInformation from './GeneralInformation';
import AdvancedOptions from './AdvancedOptions';
// import DocumentationSection from './DocumentationSection';
import FormButtonsSection from '../../FormButtonsSection';
import {
  ADD_EMPLOYEE,
  DISCARD_CHANGES,
  // UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
  // UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
} from '../../../constants';

const AddEmployeeComponent = () => {
  const {
    employeesPageState: { addNewEmployee, newEmployee },
  } = useMst();

  // const [otherDocuments, setOtherDocuments] = useState([]);
  // const [cv, setCv] = useState([]);
  // const [employeeAgreement, setEmployeeAgreement] = useState([]);

  // const handleOtherDocumentsUpload = ({ file, fileList }) => {
  //   const isUploadAllowed = () => {
  //     return fileList.length < 6 || fileList.length < otherDocuments.length;
  //   };
  //   if (file.status !== 'uploading') {
  //     if (isUploadAllowed()) {
  //       setOtherDocuments(fileList);
  //     } else {
  //       notification.error({
  //         message: UPLOAD_NOT_ALLOWED_NOTIFICATION_TITLE,
  //         description: UPLOAD_NOT_ALLOWED_NOTIFICATION_TEXT,
  //       });
  //     }
  //   }
  // };

  // const handleCVUpload = ({ file, fileList }) => {
  //   if (file.status !== 'uploading') {
  //     fileList.length ? setCv([file]) : setCv(fileList);
  //   }
  // };

  // const handleEmployeeAgreementUpload = ({ file, fileList }) => {
  //   if (file.status !== 'uploading') {
  //     fileList.length
  //       ? setEmployeeAgreement([file])
  //       : setEmployeeAgreement(fileList);
  //     setEmployeeAgreement(fileList);
  //   }
  // };

  // const getDocumentsForUpload = () => {
  //   return [...cv, ...employeeAgreement, ...otherDocuments];
  // };

  const discardChanges = () => {
    newEmployee.clearForm();
    // setCv([]);
    // setEmployeeAgreement([]);
    // setOtherDocuments([]);
  };

  const addEmployee = () => {
    // const documentsToUpload = getDocumentsForUpload();
    addNewEmployee();
  };

  return (
    <div className="add-edit-form-component">
      <FormPageTitle image={addEmployeeIcon} title={ADD_EMPLOYEE} />

      <AdvancedOptions />

      <GeneralInformation />

      {/* <DocumentationSection
        uploadCV={handleCVUpload}
        uploadEmployeeAgreement={handleEmployeeAgreementUpload}
        uploadOtherDocuments={handleOtherDocumentsUpload}
        cv={cv}
        employeeAgreement={employeeAgreement}
        otherDocuments={otherDocuments}
      /> */}

      <ErrorBox obj={newEmployee} />

      <FormButtonsSection
        onConfirm={addEmployee}
        onDiscard={discardChanges}
        confirmTitle={ADD_EMPLOYEE}
        discardTitle={DISCARD_CHANGES}
      />
    </div>
  );
};

export default observer(AddEmployeeComponent);
