import { types } from 'mobx-state-tree';
import {
  COMPENSATION_DEDUCTION_REQUIRED,
  COMPENSATION_NAME_REQUIRED,
  COMPENSATION_PAYMENT_PERIOD_REQUIRED,
  COMPENSATION_TYPE_REQUIRED,
  COMPENSATION_VALUE_OR_DEDUCTION_REQUIRED,
  COMPENSATION_VALUE_REQUIRED,
  DEFAULT_VALUE,
  ERROR_CREATING_NEW_COMPENSATION_STRUCTURE,
} from '../../constants/payroll';
import { createNewCompensationStructure } from '../../actions/payroll';
import { getErrorText, setFetchingInProgress } from '../../utils/methods';
import { notification } from 'antd';
import { rootInstance } from '../RootModel';
import { CompensationValueLightModel } from './Compensations/CompensationValueLightModel';
import { CompensationDeductionLightModel } from './Compensations/CompensationDeductionLightModel';

export const CompensationStructureLightModel = types
  .model('CompensationStructureLightModel', {
    compensation_name: types.maybe(types.maybeNull(types.string)),
    compensation_types: types.frozen([]),
    compensation_payment_periods: types.frozen([]),
    compensation_based_on_types: types.frozen([]),
    compensation_payment_rates: types.frozen([]),
    compensation_deduction_types: types.frozen([]),
    compensation_currencies: types.frozen([]),
    compensation_type: types.maybe(types.maybeNull(types.string)),
    compensation_payment_period: types.maybe(types.maybeNull(types.string)),
    errorText: types.maybe(types.maybeNull(types.string)),
    errorTextValue: types.maybe(types.maybeNull(types.string)),
    errorTextDeduction: types.maybe(types.maybeNull(types.string)),

    new_compensation_values: types.array(CompensationValueLightModel),
    new_compensation_deductions: types.array(CompensationDeductionLightModel),

    new_compensation_value: types.maybe(types.maybeNull(types.string)),
    new_compensation_based_on: types.maybe(types.maybeNull(types.string)),
    new_compensation_currency: types.maybe(types.maybeNull(types.string)),
    new_compensation_rate: types.maybe(types.maybeNull(types.string)),
    new_compensation_default_value: types.maybeNull(
      types.optional(types.number, 0.0)
    ),
    new_value_error_text: types.maybe(types.maybeNull(types.string)),

    value_default_value_temp: types.maybeNull(
      types.optional(types.string, DEFAULT_VALUE)
    ),
    deduction_default_value_temp: types.maybeNull(
      types.optional(types.string, DEFAULT_VALUE)
    ),

    new_deduction_type: types.maybe(types.maybeNull(types.string)),
    new_deduction_based_on: types.maybe(types.maybeNull(types.string)),
    new_deduction_currency: types.maybe(types.maybeNull(types.string)),
    new_deduction_rate: types.maybe(types.maybeNull(types.string)),
    new_deduction_default_value: types.optional(types.number, 0.0),
    new_deduction_error_text: types.maybe(types.maybeNull(types.string)),
  })
  .views((self) => {
    return {
      get allCompensationTypes() {
        return Array.from(self.compensation_types.values());
      },
      get allCompensationPaymentPeriods() {
        return Array.from(self.compensation_payment_periods.values());
      },
    };
  })
  .actions((self) => {
    return {
      setNewCompensationTypes(compensation_types) {
        self.compensation_types = compensation_types;
      },
      setNewCompensationPaymentPeriods(payment_periods) {
        self.compensation_payment_periods = payment_periods;
      },
      setNewCompensationBasedOnTypes(based_on_types) {
        self.compensation_based_on_types = based_on_types;
      },
      setNewCompensationPaymentRates(payment_rates) {
        self.compensation_payment_rates = payment_rates;
      },
      setNewCompensationDeductionTypes(deduction_types) {
        self.compensation_deduction_types = deduction_types;
      },
      setName(name) {
        self.compensation_name = name;
      },
      setCompensationType(compensation_type) {
        self.compensation_type = compensation_type;
      },
      setCompensationPeriod(compensation_payment_period) {
        self.compensation_payment_period = compensation_payment_period;
      },
      setErrorText(text) {
        self.errorText = text;
      },
      setErrorTextValue(text) {
        self.errorTextValue = text;
      },
      setErrorTextDeduction(text) {
        self.errorTextDeduction = text;
      },
      setNewCompensationDeductions(deductions) {
        self.new_compensation_deductions = deductions;
      },
      setNewCompensationValues(values) {
        self.new_compensation_values = values;
      },
      setCompensationCurrencies(values) {
        self.compensation_currencies = values;
      },
      setNewCompensationValue(value) {
        self.new_compensation_value = value;
      },
      setNewCompensationValueBasedOn(value) {
        self.new_compensation_based_on = value;
      },
      setNewCompensationValueDefaultValue(value) {
        self.new_compensation_default_value = value;
      },
      setValueDefaultValueTemp(value) {
        self.value_default_value_temp = value;
      },
      setDeductionDefaultValueTemp(value) {
        self.deduction_default_value_temp = value;
      },
      setNewCompensationValueRate(value) {
        self.new_compensation_rate = value;
      },
      setNewCompensationValueCurrency(value) {
        self.new_compensation_currency = value;
      },
      setNewDeductionType(value) {
        self.new_deduction_type = value;
      },
      setNewDeductionBasedOn(value) {
        self.new_deduction_based_on = value;
      },
      setNewDeductionDefaultValue(value) {
        self.new_deduction_default_value = value;
      },
      setNewDeductionRate(value) {
        self.new_deduction_rate = value;
      },
      setNewDeductionCurrency(value) {
        self.new_deduction_currency = value;
      },
      clearValueForm() {
        this.setNewCompensationValue(null);
        this.setNewCompensationValueBasedOn(null);
        this.setNewCompensationValueDefaultValue(0);
        this.setNewCompensationValueRate(null);
        this.setNewCompensationValueCurrency(null);
      },
      clearDeductionForm() {
        this.setNewDeductionType(null);
        this.setNewDeductionBasedOn(null);
        this.setNewDeductionDefaultValue(0);
        this.setNewDeductionRate(null);
        this.setNewDeductionCurrency(null);
      },
      clearForm() {
        this.setName('');
        this.setCompensationType('');
        this.setCompensationPeriod('');
      },
      validateForm() {
        if (!self.compensation_name) {
          this.setErrorText(COMPENSATION_NAME_REQUIRED);
        } else if (!self.compensation_type) {
          this.setErrorText(COMPENSATION_TYPE_REQUIRED);
        } else if (!self.compensation_payment_period) {
          this.setErrorText(COMPENSATION_PAYMENT_PERIOD_REQUIRED);
        } else if (
          !self.new_compensation_values.length &&
          !self.new_compensation_deductions.length
        ) {
          this.setErrorText(COMPENSATION_VALUE_OR_DEDUCTION_REQUIRED);
        } else {
          this.setErrorText(null);
          return true;
        }
        return false;
      },
      validateValueForm() {
        if (
          !self.new_compensation_value ||
          !self.new_compensation_rate ||
          !self.new_compensation_currency
        ) {
          this.setErrorTextValue(COMPENSATION_VALUE_REQUIRED);
        } else {
          this.setErrorTextValue(null);
          return true;
        }
        return false;
      },
      validateDeductionForm() {
        if (
          !self.new_deduction_type ||
          !self.new_deduction_rate ||
          !self.new_deduction_currency
        ) {
          this.setErrorTextDeduction(COMPENSATION_DEDUCTION_REQUIRED);
        } else {
          this.setErrorTextDeduction(null);
          return true;
        }
        return false;
      },
      addCompensationValue() {
        if (this.validateValueForm()) {
          const compensationValues = self.new_compensation_values;
          compensationValues.push({
            compensation_value: self.new_compensation_value,
            based_on: self.new_compensation_based_on,
            rate: self.new_compensation_rate,
            default_value: parseFloat(self.value_default_value_temp),
            currency: self.new_compensation_currency,
          });
          this.setNewCompensationValues(compensationValues);
          this.clearValueForm();
        }
      },
      addCompensationDeduction() {
        if (this.validateDeductionForm()) {
          const compensationDeductions = self.new_compensation_deductions;
          compensationDeductions.push({
            deduction_type: self.new_deduction_type,
            based_on: self.new_deduction_based_on,
            rate: self.new_deduction_rate,
            default_value: parseFloat(self.deduction_default_value_temp),
            currency: self.new_deduction_currency,
          });
          this.setNewCompensationDeductions(compensationDeductions);
          this.clearDeductionForm();
        }
      },
      saveNewCompensationStructure() {
        if (this.validateForm()) {
          setFetchingInProgress(true);
          createNewCompensationStructure(self)
            .then((response) => {
              this.setNewCompensationValues([]);
              this.setNewCompensationDeductions([]);
              this.clearForm();
              rootInstance.payrollPageState.setAddCompensationSuccessModalOpen(
                true
              );
              setFetchingInProgress(false);
            })
            .catch((error) => {
              setFetchingInProgress(false);
              const errorText = getErrorText(error);
              notification.error({
                message: ERROR_CREATING_NEW_COMPENSATION_STRUCTURE,
                description: errorText,
              });
            });
        }
      },
    };
  });
