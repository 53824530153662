import React from 'react';
import { observer } from 'mobx-react';
import {
  CARRIER_COMPANY,
  SHIPMENT_SPECIFIC_DETAILS,
  TRAILER_TYPE,
} from '../../../constants';
import { useMst } from '../../../state/RootModel';
import { Select } from 'antd';
import clipboard from '../../../assets/images/clipboard.svg';

const { Option } = Select;

const ShipmentSpecificDetails = () => {
  const {
    shipmentsPageState: {
      currentShipment: {
        isBrokerageShipment,
        setSelectedEquipment,
        selectedEquipment,
        availableEquipmentArray,
        setSelectedCarrier,
        selectedCarrier,
        carriersWithEquipmentArray,
        setCarrierModalOpen,
      },
    },
  } = useMst();

  const onSelectTrailerType = (value) => {
    setSelectedEquipment(value);
  };

  const onSelectCarrier = (value) => {
    setSelectedCarrier(value);
  };

  const onGetCarriersList = (e) => {
    e.preventDefault();
    setCarrierModalOpen();
  };

  return (
    <div className="page-section shipment-details-section w20 ml5pc">
      <div className="subsection-header align-center">
        <p>{SHIPMENT_SPECIFIC_DETAILS}</p>
      </div>
      <div className="subsection-content flex-column">
        <div className="input-wrapper w100">
          <div className="input-label justify-between">
            <span>{TRAILER_TYPE}</span>
          </div>
          <Select
            className="text-capitalize"
            style={{ width: '80%' }}
            onChange={onSelectTrailerType}
            value={selectedEquipment?.equipment_id}
          >
            <Option key={'none'} value={null}>
              -
            </Option>
            {availableEquipmentArray?.map((type) => (
              <Option
                key={type.equipment_id}
                value={type.equipment_id}
                className="text-capitalize"
              >
                {type.equipment_type}
              </Option>
            ))}
          </Select>
        </div>
        {isBrokerageShipment && (
          <div className="input-wrapper w100 mt20">
            <div className="input-label justify-between">
              <span>{CARRIER_COMPANY}</span>
            </div>
            <Select
              className="text-capitalize"
              style={{ width: '80%' }}
              onChange={onSelectCarrier}
              value={selectedCarrier?.carrier_id}
            >
              <Option key={'none'} value={null}>
                -
              </Option>
              {carriersWithEquipmentArray?.map((carrier) => (
                <Option
                  key={carrier.carrier_id}
                  value={carrier.carrier_id}
                  className="text-capitalize"
                >
                  {carrier.company_name}
                </Option>
              ))}
            </Select>
            <img
              src={clipboard}
              alt="copy"
              className="pointer p10"
              onClick={(e) => {
                onGetCarriersList(e);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(ShipmentSpecificDetails);
