import { NONAME } from 'dns';
import { SET_AS_ACTIVE } from '../../../constants';
import {
  COMPENSATION_NAME,
  COMPENSATION_PERIOD,
  COMPENSATION_TYPE,
} from '../../../constants/payroll';

export const editDriverCompensationsTableColumns = (rootInstance) => ({
  '1': {
    title: COMPENSATION_NAME,
    dataIndex: 'compensation_name',
    key: 'compensation_name',
    width: '30%',
  },
  '2': {
    title: COMPENSATION_TYPE,
    dataIndex: 'compensation_type',
    key: 'compensation_type',
    width: '30%',
  },
  '3': {
    title: COMPENSATION_PERIOD,
    dataIndex: 'period',
    key: 'period',
    width: '30%',
  },
  '4': {
    title: NONAME,
    render: (text, record) => {
      return (
        !record.is_active && (
          <div className="align-center">
            <button
              onClick={(e) => {
                e.stopPropagation();
                rootInstance.fleetPageState.changeCompensationActivity(
                  record.compensation_id
                );
              }}
              className="btn add white ml20 ph20 pv0"
              style={{ width: 150 }}
            >
              {SET_AS_ACTIVE}
            </button>
          </div>
        )
      );
    },
  },
});
