import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../../state/RootModel';
import { CANCEL, DESCRIPTION, EDIT, TITLE } from '../../../../../constants';
import { Checkbox, Input } from 'antd';
import ErrorBox from '../../../../ErrorBox';
const { TextArea } = Input;

const EditedRouteAction = () => {
  const {
    shipmentSpecificsPageState: {
      routeActionToBeEdited,
      editedShipmentSpecificType,
      setEditingShipmentSpecific,
      setRouteActionToBeEdited,
    },
  } = useMst();

  const onChangeDescription = (e) => {
    routeActionToBeEdited.setDescription(e.target.value);
  };

  const onSelectRequiresTruck = () => {
    routeActionToBeEdited.setRequiresTruck(
      !routeActionToBeEdited.requires_truck
    );
  };
  const onSelectRequiresTrailer = () => {
    routeActionToBeEdited.setRequiresTrailer(
      !routeActionToBeEdited.requires_trailer
    );
  };

  const onCancel = () => {
    setEditingShipmentSpecific(false);
    setRouteActionToBeEdited(null);
  };
  const onEdit = () => {
    routeActionToBeEdited.saveItem();
    // newLocation.setOwnerId(customer.client_id);
  };

  return (
    <div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{TITLE}</span>
          <span className="color-secondary">*</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) =>
            routeActionToBeEdited.setRouteActionName(e.target.value)
          }
          value={routeActionToBeEdited?.name}
        />
      </div>
      <div className="input-wrapper align-center w100 mt20">
        <div className="input-wrapper w50">
          <div className="align-center">
            <Checkbox
              checked={routeActionToBeEdited?.requires_truck}
              onChange={onSelectRequiresTruck}
              className="align-center"
            >
              <p>Requires Truck</p>
            </Checkbox>
          </div>
        </div>
        <div className="input-wrapper w50">
          <div className="align-center">
            <Checkbox
              checked={routeActionToBeEdited?.requires_trailer}
              onChange={onSelectRequiresTrailer}
              className="align-center"
            >
              <p>Requires Trailer</p>
            </Checkbox>
          </div>
        </div>
      </div>
      <div className="input-wrapper mt20 w100">
        <div className="input-label justify-between">
          <span>{DESCRIPTION}</span>
        </div>
        <TextArea
          rows={4}
          onChange={onChangeDescription}
          value={routeActionToBeEdited?.description}
        />
      </div>
      <ErrorBox obj={editedShipmentSpecificType} />

      <div className="justify-end mt20">
        <button className="btn white mr10" onClick={onCancel}>
          {CANCEL}
        </button>
        <button className="btn primary" onClick={onEdit}>
          {EDIT}
        </button>
      </div>
    </div>
  );
};

export default observer(EditedRouteAction);
