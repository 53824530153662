import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal, Select } from 'antd';
import {
  DRIVERS_LIST,
  EMPTY_AVAILABLE_DRIVERS_TABLE_TEXT,
} from '../../../constants/dispatch';
import { FILTER, SEARCH_BY_C_NAME_OR_ID } from '../../../constants';
import { DebounceInput } from 'react-debounce-input';
import AssetsTable from './AssetsTable';

const { Option } = Select;
const OrganizeTourDriverModal = () => {
  const {
    dispatchPageState: {
      isOrganizeTourDriversModalOpen,
      setIsOrganizeTourDriversModalOpen,
      getOrganizeTourDrivers,
      getAvailableDriversTableColumns,
      driverModalSearch,
      setDriverModalSearch,
      driverModalLocationSearch,
      setDriverModalLocationSearch,
      getAvailableDriverFilterOptions,
      setDriverFilterOption,
      driverFilterOptionSearch,
      setDriverOgPage,
      resetOgDriverData,
      setLocationsSearchText,
      getAllFilteredAvailableLocations,
    },
  } = useMst();

  const onClose = () => {
    setPageNumber(1);
    resetOgDriverData();
    setIsOrganizeTourDriversModalOpen(false);
  };

  const onDriverSearch = (e) => {
    setDriverModalSearch(e.target.value);
  };
  const onSetDriverLocationSearch = (location) => {
    setDriverModalLocationSearch(location);
  };
  const onClearDriverLocationSearch = () => {
    setDriverModalLocationSearch(null);
  };
  const onSetDriverFilterOption = (option) => {
    setDriverFilterOption(option);
  };

  const [page, setPageNumber] = useState(2);
  const updateDrivers = () => {
    setPageNumber((prev) => {
      return prev + 1;
    });
    setDriverOgPage(page);
  };
  const onSearchLocationsList = (value) => {
    setLocationsSearchText(value);
  };

  return (
    <div>
      <Modal
        visible={!!isOrganizeTourDriversModalOpen}
        style={{ top: 88 }}
        footer={null}
        centered={false}
        closable={true}
        onCancel={onClose}
        className="og-drivers-modal"
        width={1300}
      >
        <div className="p32">
          <div className="align-center mb40">
            <p className="mr50 color-title text-bold text-uppercase">
              {DRIVERS_LIST}
            </p>
            <div className="input-wrapper" style={{ width: 300 }}>
              <DebounceInput
                debounceTimeout={300}
                value={driverModalSearch}
                placeholder={SEARCH_BY_C_NAME_OR_ID}
                onChange={(e) => onDriverSearch(e)}
                className="trailer-search-input"
              />
            </div>
            <div className="input-wrapper ml-a mr40 align-center">
              <p className="mr10 color-title">{FILTER}</p>
              <Select
                style={{
                  minWidth: 150,
                }}
                value={driverFilterOptionSearch}
                onChange={onSetDriverFilterOption}
              >
                {getAvailableDriverFilterOptions.map((option, index) => (
                  <Option key={index} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
              <p className="ml10 mr10">to</p>
              <Select
                style={{
                  minWidth: 200,
                }}
                showSearch
                optionFilterProp="children"
                allowClear={true}
                // @ts-ignore
                onClear={onClearDriverLocationSearch}
                value={driverModalLocationSearch}
                onChange={onSetDriverLocationSearch}
                onSearch={onSearchLocationsList}
              >
                <Option key={null} value={null}>
                  Select location
                </Option>
                {getAllFilteredAvailableLocations.map((location) => (
                  <Option
                    key={location.location_id}
                    value={location.location_id}
                  >
                    {location.location_name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {getOrganizeTourDrivers && (
            <AssetsTable
              tableText={EMPTY_AVAILABLE_DRIVERS_TABLE_TEXT}
              data={getOrganizeTourDrivers}
              column={getAvailableDriversTableColumns()}
              update={updateDrivers}
            />
          )}

          {/*<Table*/}
          {/*    className={'og-drivers-table'}*/}
          {/*    dataSource={getOrganizeTourDrivers}*/}
          {/*    columns={getAvailableDriversTableColumns()}*/}
          {/*    rowKey={'driver_id'}*/}
          {/*    pagination={false}*/}
          {/*    expandRowByClick={true}*/}
          {/*    expandedRowRender={record => <OrganizeTourDriverTableDetails data={record}/>}*/}
          {/*    expandIcon={() => null}*/}
          {/*    // scroll={{y: 500}}*/}
          {/*    locale={{*/}
          {/*        emptyText: (*/}
          {/*            <Empty*/}
          {/*                image={Empty.PRESENTED_IMAGE_SIMPLE}*/}
          {/*                description={EMPTY_AVAILABLE_DRIVERS_TABLE_TEXT}*/}
          {/*            />*/}
          {/*        ),*/}
          {/*    }}*/}
          {/*/>*/}
          {/*<div className="table-footer">*/}
          {/*    <Pagination*/}
          {/*        onChange={paginationChange}*/}
          {/*        total={driverOgTotal}*/}
          {/*        current={driverOgPage}*/}
          {/*        pageSize={driverOgPerPage}*/}
          {/*    />*/}
          {/*</div>*/}
        </div>
      </Modal>
    </div>
  );
};

export default observer(OrganizeTourDriverModal);
