import {
  getAllDriversLicences,
  getCompanyDriversAssociations,
  getDriverCompliances,
  getDriverCompliancyDocument,
  getDriverDocumentation,
  getDriversLicenceAttachment,
  getOwnerDriversAssociations,
  getVehicle,
  getDriversActiveLicence,
  getMaintenanceTypes,
  getAllVehicleMaintenances,
  getVehicleMaintenanceDocument,
  getVehicleDocumentation,
  getAllVehicleInspections,
  getVehicleInspectionDocument,
  getVehicleRegistration,
  getVehicleRegistrationDocument,
  getAllVehicleRegistrations,
  deleteVehicleDocument,
  uploadVehicleDocument,
  addVehicleRegistration,
  uploadRegistrationDocument,
  editVehicleRegistration,
  editTruck,
  editTrailer,
  uploadInspectionDocument,
  updateVehicleInspection,
  uploadMaintenanceDocument,
  updateVehicleMaintenance,
  getAllDriverAbsences,
  getDriverAbsence,
  getDriverAbsenceDocument,
  deleteDriverDocument,
  updateDriverAbsence,
  uploadDriverAbsenceDocument,
  updateDriverCompliancy,
  getVehicleAssociations,
  getAvailableTrucksforDriverAssociation,
  getVehicleLocation,
  getTrailerTruckAssociations,
  getDriverTypes,
  getVehicleStatuses,
  getAllDriversCompensations,
  changeDriversCompensationActivity,
  addCompensationToDriver,
  editCompensationValueForDriver,
  editCompensationDeductionForDriver,
} from '../../actions/fleet';
import { DOCUMENT_TYPE_REGISTRATION } from '../../constants/documents';

const allSettled = require('promise.allsettled');

/** DRIVER PROMISES */

export const driverDocumentation = (model) => {
  return new Promise((resolve, reject) => {
    getDriverDocumentation(model.driver_id)
      .then((response) => {
        model.setDocuments(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const driverCompliances = (model) => {
  return new Promise((resolve, reject) => {
    getDriverCompliances(model.driver_id)
      .then((response) => {
        model.setCompliances(response.data);
        let complianciesPromises = [];
        model.complianciesArray.forEach((compliancy) => {
          complianciesPromises.push(
            new Promise((resolve, reject) => {
              getDriverCompliancyDocument(compliancy.compliancy_id)
                .then((document) => {
                  compliancy.setDocument(document.data);
                  resolve(document);
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );
        });
        allSettled(complianciesPromises).then(() => {
          resolve(response);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editDriverCompliancy = (compliancy) => {
  return new Promise((resolve, reject) => {
    updateDriverCompliancy(compliancy)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteDriverDocumentPromise = (driver_id, document) => {
  return new Promise((resolve, reject) => {
    deleteDriverDocument(driver_id, document.uid, document.document_type)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const driverLicences = (model) => {
  return new Promise((resolve, reject) => {
    getAllDriversLicences(model.driver_id)
      .then((response) => {
        model.setDriversLicences(response.data);
        let licencesPromises = [];
        model.licencesArray.forEach((licence) => {
          licencesPromises.push(
            new Promise((resolve, reject) => {
              getDriversLicenceAttachment(licence.licence_id).then(
                (attachments) => {
                  licence.setAttachments(attachments.data);
                  resolve(attachments);
                }
              );
            })
          );
        });
        allSettled(licencesPromises).then(() => {
          resolve(response);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const driverCompensations = (model) => {
  return new Promise((resolve, reject) => {
    getAllDriversCompensations(model.driver_id)
      .then((response) => {
        model.setCompensations(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const driverActiveLicence = (model) => {
  return new Promise((resolve, reject) => {
    getDriversActiveLicence(model.driver_id)
      .then((response) => {
        model.setLicence(response.data);
        getDriversLicenceAttachment(model.licence.licence_id).then(
          (attachments) => {
            model.licence.setAttachments(attachments.data);
            resolve(response);
          }
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ownerDriversAssociations = (model) => {
  return new Promise((resolve, reject) => {
    getOwnerDriversAssociations(model.driver_id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ownerDriversCurrentAssociation = (model) => {
  return new Promise((resolve, reject) => {
    getOwnerDriversAssociations(model.driver_id)
      .then((response) => {
        model.setAssociation(response.data[0]);
        getVehicle(model.association.vehicle_id).then((vehicle) => {
          model.association.setAssociatedTruck(vehicle.data);
          resolve(response);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const companyDriversAssociations = (model) => {
  return new Promise((resolve, reject) => {
    getCompanyDriversAssociations(model.driver_id)
      .then((response) => {
        model.setAssociations(response.data);
        getVehicle(response.data[0].vehicle_id).then((res) => {
          const vehicle_data = {
            vehicle_id: res.data.vehicle_id,
            long: res.data.vehicle_longitude,
            lat: res.data.vehicle_latitude,
          };
          model.setLocation(vehicle_data);
          resolve(response.data);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const companyDriversCurrentAssociation = (model) => {
  return new Promise((resolve, reject) => {
    getCompanyDriversAssociations(model.driver_id)
      .then((response) => {
        const currentAssociation = response.data.find((association) => {
          return !association.end_driving;
        });
        if (currentAssociation) {
          model.setAssociation(currentAssociation);
        }
        getVehicle(model.association.vehicle_id).then((vehicle) => {
          model.association.setAssociatedTruck(vehicle.data);
          resolve(response);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const availableTrucksforDriverAssociation = (model) => {
  return new Promise((resolve, reject) => {
    getAvailableTrucksforDriverAssociation(model.driver_id)
      .then((response) => {
        model.setTrucksAvailableForAssociation(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const driverAbsences = (driver) => {
  return new Promise((resolve, reject) => {
    getAllDriverAbsences(driver.driver_id)
      .then((absences) => {
        driver.setAbsences(absences.data);
        let documentsPromises = [];
        driver.absencesArray.forEach((absence) => {
          if (
            driver.current_absence_id &&
            driver.current_absence_id === absence.absence_id
          ) {
            driver.setCurrentAbsence(absence.absence_id);
          }
          documentsPromises.push(
            new Promise((resolve, reject) => {
              getDriverAbsenceDocument(absence.absence_id)
                .then((document) => {
                  absence.setDocument(document.data);
                  resolve(document);
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );
        });
        allSettled(documentsPromises).then(() => {
          resolve(absences);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const driverCurrentAbsence = (driver) => {
  return new Promise((resolve, reject) => {
    getDriverAbsence(driver.driver_id, driver.current_absence_id)
      .then((absence) => {
        driver.setCurrentAbsence(absence.data);
        resolve(absence);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editDriverAbsence = (absence) => {
  return new Promise((resolve, reject) => {
    updateDriverAbsence(absence)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadDriverAbsenceDocumentPromise = (absence_id, document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', document.originFileObj);
    uploadDriverAbsenceDocument(absence_id, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/** VEHICLE PROMISES */

export const maintenanceTypes = (model) => {
  return new Promise((resolve, reject) => {
    getMaintenanceTypes(model.vehicle_type)
      .then((response) => {
        model.setAvailableMtceTypes(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const allVehicleMaintenances = (model) => {
  return new Promise((resolve, reject) => {
    getAllVehicleMaintenances(model.vehicle_id)
      .then((maintenances) => {
        model.setMaintenances(maintenances.data);
        let maintenancesPromises = [];
        model.maintenancesArray.forEach((maintenance) => {
          maintenancesPromises.push(
            new Promise((resolve, reject) => {
              getVehicleMaintenanceDocument(maintenance.maintenance_id)
                .then((attachment) => {
                  maintenance.setDocument(attachment.data);
                  resolve(attachment);
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );
        });
        allSettled(maintenancesPromises).then(() => {
          resolve(maintenances);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const vehicleDocumentation = (model) => {
  return new Promise((resolve, reject) => {
    getVehicleDocumentation(model.vehicle_id)
      .then((response) => {
        model.setDocuments(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const vehicleLocation = (model) => {
  return new Promise((resolve, reject) => {
    getVehicleLocation(model.vehicle_id)
      .then((response) => {
        model.setLocation(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const allVehicleInspections = (model) => {
  return new Promise((resolve, reject) => {
    getAllVehicleInspections(model.vehicle_id)
      .then((inspections) => {
        model.setInspections(inspections.data.inspections);
        let inspectionsPromises = [];
        model.inspectionsArray.forEach((inspection) => {
          inspectionsPromises.push(
            new Promise((resolve, reject) => {
              getVehicleInspectionDocument(inspection.inspection_id)
                .then((attachment) => {
                  inspection.setDocument(attachment.data);
                  resolve(attachment);
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );
        });
        allSettled(inspectionsPromises).then(() => {
          resolve(inspections);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const vehicleRegistration = (model) => {
  return new Promise((resolve, reject) => {
    getVehicleRegistration(model.vehicle_id)
      .then((response) => {
        model.setLicence(response.data);
        getVehicleRegistrationDocument(model.licence.vehicle_registration_id)
          .then((document) => {
            model.licence.setRegistrationDocument(document.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const allVehicleRegistrations = (model) => {
  return new Promise((resolve, reject) => {
    getAllVehicleRegistrations(model.vehicle_id)
      .then((response) => {
        model.setRegistrations(response.data);
        let registrationsPromises = [];
        model.registrationsArray.forEach((registration) => {
          registrationsPromises.push(
            new Promise((resolve, reject) => {
              getVehicleRegistrationDocument(
                registration.vehicle_registration_id
              )
                .then((attachment) => {
                  registration.setRegistrationDocument(attachment.data);
                  resolve(attachment);
                })
                .catch((error) => {
                  reject(error);
                });
            })
          );
        });
        allSettled(registrationsPromises).then(() => {
          resolve(response);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteDocument = (vehicle_id, document) => {
  return new Promise((resolve, reject) => {
    deleteVehicleDocument(vehicle_id, document.uid, document.document_type)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadDocument = (vehicle_id, document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    const documentType = document.originFileObj.documentType;
    formData.append('file', document.originFileObj);
    uploadVehicleDocument(vehicle_id, formData, documentType)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addRegistration = (registration, documents) => {
  return new Promise((resolve, reject) => {
    addVehicleRegistration(registration)
      .then((licence) => {
        const attachment = documents.find((document) => {
          return (
            document.originFileObj?.documentType === DOCUMENT_TYPE_REGISTRATION
          );
        });
        if (attachment) {
          const formData = new FormData();
          formData.append('file', attachment.originFileObj);
          uploadRegistrationDocument(
            licence.data.vehicle_registration_id,
            formData
          )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(licence);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editRegistration = (registration, documents) => {
  return new Promise((resolve, reject) => {
    editVehicleRegistration(registration)
      .then((licence) => {
        const attachment = documents.find((document) => {
          return (
            document.originFileObj?.documentType === DOCUMENT_TYPE_REGISTRATION
          );
        });
        if (attachment) {
          const formData = new FormData();
          formData.append('file', attachment.originFileObj);
          uploadRegistrationDocument(
            licence.data.vehicle_registration_id,
            formData
          )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(licence);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editTruckPromise = (truck) => {
  return new Promise((resolve, reject) => {
    editTruck(truck)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editTrailerPromise = (trailer) => {
  return new Promise((resolve, reject) => {
    editTrailer(trailer)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadInspectionDocumentPromise = (inspection_id, document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', document.originFileObj);
    uploadInspectionDocument(inspection_id, formData)
      .then((document) => {
        resolve(document);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editInspection = (inspection) => {
  return new Promise((resolve, reject) => {
    updateVehicleInspection(inspection)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadMaintenanceDocumentPromise = (maintenance_id, document) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', document.originFileObj);
    uploadMaintenanceDocument(maintenance_id, formData)
      .then((document) => {
        resolve(document);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editMaintenance = (maintenance) => {
  return new Promise((resolve, reject) => {
    updateVehicleMaintenance(maintenance)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getVehicleAssociationsPromise = (vehicle) => {
  return new Promise((resolve, reject) => {
    getVehicleAssociations(vehicle.vehicle_id)
      .then((response) => {
        vehicle.setAssociations(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const getAllVehicleAssociationsPromise = (vehicle) => {
  return new Promise((resolve, reject) => {
    getTrailerTruckAssociations(vehicle.vehicle_id)
      .then((response) => {
        vehicle.setTrailerTruckAssociation(response.data);
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllDriverTypes = (model) => {
  return new Promise((resolve, reject) => {
    getDriverTypes()
      .then((response) => {
        model.setDriverTypes(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getVehicleStatusesPromise = (model, type) => {
  return new Promise((resolve, reject) => {
    getVehicleStatuses(type)
      .then((response) => {
        model.setAvailableStatuses(response.data);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeDriversCompensationActivityPromise = (
  driver_id,
  compensation_id
) => {
  return new Promise((resolve, reject) => {
    changeDriversCompensationActivity(driver_id, compensation_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addCompensationToDriverPromise = (driver_id, compensation_id) => {
  return new Promise((resolve, reject) => {
    addCompensationToDriver(driver_id, compensation_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editCompensationValueForDriverPromise = (
  driver_id,
  compensation_id,
  editedValue
) => {
  return new Promise((resolve, reject) => {
    editCompensationValueForDriver(driver_id, compensation_id, editedValue)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editCompensationDeductionForDriverPromise = (
  driver_id,
  compensation_id,
  editedDeduction
) => {
  return new Promise((resolve, reject) => {
    editCompensationDeductionForDriver(
      driver_id,
      compensation_id,
      editedDeduction
    )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllDriversCompensationsPromise = (driver_id) => {
  return new Promise((resolve, reject) => {
    getAllDriversCompensations(driver_id)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
