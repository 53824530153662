import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../state/RootModel';
import AsyncSelect from 'react-select/async';
import {
  ADDRESS,
  CANCEL,
  CITY,
  COUNTRY,
  LOCATION_CONTACT_NAME,
  LOCATION_NAME,
  OVERWRITE_EXISTING_LOCATION,
  PHONE_NUMBER,
  PICKUP_LOCATION,
  PICKUP_NUMBER,
  PICKUP_TIME,
  PICKUP_TYPE,
  SAVE_AS_NEW_LOCATION,
  SCHEDULED,
  SHIPPER,
  START_TYPING_PLACEHOLDER_TEXT,
  STATE,
  ZIP_CODE,
  ZONE,
} from '../../../../constants';
import { Input, Select, TimePicker, DatePicker } from 'antd';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';

import editProbillLocation from '../../../../assets/images/save.svg';
import editProbillLocationGray from '../../../../assets/images/save-gray.svg';
import addProbillLocation from '../../../../assets/images/add-file.svg';
import addProbillLocationGray from '../../../../assets/images/add-gray.svg';

const debounce = require('lodash.debounce');
const { Option } = Select;
const { RangePicker } = DatePicker;

const ShipperInfo = () => {
  const {
    shipmentsPageState: {
      currentShipment,
      probillEditLocationEnabled,
      setProbillEditLocationEnabled,
      showProbillLocationIcons,
      setProbillLocationIcons,
      setShowEditedProbillModal,
      setProbillAddNewLocationEnabled,
      probillAddNewLocationEnabled,
      setShowAddNewProbillModal,
    },
    clientsPageState: { getFilteredClients },
  } = useMst();

  const [searchPickupLocationValue, setSearchPickupLocationValue] =
    useState('');

  const loadSuggestions = debounce(getFilteredClients, 300);

  const onSelectShipper = (client) => {
    currentShipment?.newProbill.setShipperId(client.client_id);
  };

  const onChangePickupLocation = (locationId) => {
    const location = currentShipment?.newProbill.pickup_locations.find(
      (item) => {
        return item.location_id === locationId;
      }
    );
    location && currentShipment?.newProbill.setSelectedPickupLocation(location);
    setProbillLocationIcons(true);
  };

  const onSearchPickupLocation = (value) => {
    setSearchPickupLocationValue(value);
  };

  const onPickupTimeFromChange = (value, string) => {
    currentShipment?.newProbill.setPickupTimeStartSelected(string);
  };

  const onPickupTimeToChange = (value, string) => {
    currentShipment?.newProbill.setPickupTimeEndSelected(string);
  };

  const onPickupDatesChange = (dates) => {
    const starting_date = moment(dates[0]).format('YYYY-MM-DD');
    const end_date = moment(dates[1]).format('YYYY-MM-DD');
    currentShipment?.newProbill.setPickupDateStartSelected(starting_date);
    currentShipment?.newProbill.setPickupDateEndSelected(end_date);
  };

  const getDisabledFromHours = () => {
    if (currentShipment?.newProbill?.isPickupRangeSetInSameDate) {
      const toHours = currentShipment?.newProbill?.pickupTimeEndSelected
        ? moment(
            currentShipment?.newProbill.pickupTimeEndSelected,
            'HH:mm'
          ).hours()
        : null;

      let hours = [];
      if (toHours) {
        for (let i = toHours + 1; i < 24; i++) {
          hours.push(i);
        }
      }

      return hours;
    }
  };
  const getDisabledToHours = () => {
    if (currentShipment?.newProbill?.isPickupRangeSetInSameDate) {
      const fromHours = currentShipment?.newProbill?.pickupTimeStartSelected
        ? moment(
            currentShipment?.newProbill.pickupTimeStartSelected,
            'HH:mm'
          ).hours()
        : null;

      let hours = [];
      if (fromHours) {
        for (let i = fromHours - 1; i >= 0; i--) {
          hours.push(i);
        }
      }
      return hours;
    }
  };
  const getDisabledFromMinutes = (hour) => {
    if (currentShipment?.newProbill?.isPickupRangeSetInSameDate) {
      const toHours = currentShipment?.newProbill?.pickupTimeEndSelected
        ? moment(
            currentShipment?.newProbill.pickupTimeEndSelected,
            'HH:mm'
          ).hours()
        : null;
      const toMinutes = currentShipment?.newProbill?.pickupTimeEndSelected
        ? moment(
            currentShipment?.newProbill.pickupTimeEndSelected,
            'HH:mm'
          ).minutes()
        : null;

      let minutes = [];

      if (hour === toHours) {
        for (let i = toMinutes + 1; i < 60; i++) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  };
  const getDisabledToMinutes = (hour) => {
    if (currentShipment?.newProbill?.isPickupRangeSetInSameDate) {
      const fromHours = currentShipment?.newProbill?.pickupTimeStartSelected
        ? moment(
            currentShipment?.newProbill.pickupTimeStartSelected,
            'HH:mm'
          ).hours()
        : null;
      const fromMinutes = currentShipment?.newProbill?.pickupTimeStartSelected
        ? moment(
            currentShipment?.newProbill.pickupTimeStartSelected,
            'HH:mm'
          ).minutes()
        : null;

      let minutes = [];

      if (hour === fromHours) {
        for (let i = fromMinutes - 1; i >= 0; i--) {
          minutes.push(i);
        }
        return minutes;
      }
    }
  };

  const disabledDate = (value) => {
    // Can not select days before pickup start date
    if (currentShipment?.newProbill?.deliveryDateStartSelected) {
      return (
        value >
        moment(currentShipment?.newProbill.deliveryDateStartSelected).endOf(
          'day'
        )
      );
    }
  };

  const onPickupNumberChange = (e) => {
    currentShipment?.newProbill.setPickupNumber(e.target.value);
  };

  const onEditProbillLocation = (value) => {
    setProbillEditLocationEnabled(value);
  };

  const onAddNewProbillLocation = (value) => {
    setProbillAddNewLocationEnabled(value);
  };

  const saveNewProbillLocation = (e) => {
    e.preventDefault();
    setShowAddNewProbillModal(true);
  };

  const saveEditedProbillLocation = (e) => {
    e.preventDefault();
    setShowEditedProbillModal(true);
  };
  const onEditedProbillLocationAddressChange = (e) => {
    currentShipment?.newProbill?.selectedPickupLocation?.setAddress(
      e.target.value
    );
  };
  const onEditedProbillLocationZipChange = (e) => {
    currentShipment?.newProbill?.selectedPickupLocation?.setPostalCode(
      e.target.value
    );
  };
  const onEditedProbillLocationCountryChange = (country) => {
    currentShipment?.newProbill?.selectedPickupLocation?.setCountry(country);
    currentShipment?.newProbill?.setStates(country);
  };
  const onEditedProbillLocationStateChange = (state) => {
    currentShipment?.newProbill?.selectedPickupLocation?.setState(state);
  };
  const onEditedProbillLocationCityChange = (e) => {
    currentShipment?.newProbill?.selectedPickupLocation?.setCity(
      e.target.value
    );
  };

  const isFieldDisabled = () => {
    return !(probillAddNewLocationEnabled || probillEditLocationEnabled);
  };

  const onNewLocationNameChange = (e) => {
    currentShipment?.newProbill?.selectedPickupLocation?.setLocationName(
      e.target.value
    );
  };

  const onNewLocationContactNameChange = (e) => {
    currentShipment?.newProbill?.selectedPickupLocation?.setLocationContactName(
      e.target.value
    );
  };

  function LocationActions() {
    if (!probillEditLocationEnabled && probillAddNewLocationEnabled) {
      return (
        <div className="ml5 align-center">
          <img src={editProbillLocationGray} alt="edit-probill-location" />
          <img
            src={addProbillLocation}
            alt="add-probill-location"
            onClick={(e) => onAddNewProbillLocation(true)}
          />
        </div>
      );
    } else if (probillEditLocationEnabled && !probillAddNewLocationEnabled) {
      return (
        <div className="ml5 align-center">
          <img
            src={editProbillLocation}
            alt="edit-probill-location"
            onClick={(e) => onEditProbillLocation(true)}
          />
          <img src={addProbillLocationGray} alt="add-probill-location" />
        </div>
      );
    } else {
      return (
        <div className="ml5 align-center">
          <img
            src={editProbillLocation}
            alt="edit-probill-location"
            onClick={(e) => onEditProbillLocation(true)}
          />
          <img
            src={addProbillLocation}
            alt="add-probill-location"
            onClick={(e) => onAddNewProbillLocation(true)}
          />
        </div>
      );
    }
  }

  const onPickupDateChange = (value, string) => {
    currentShipment?.newProbill.setPickupDateStartSelected(string);
    currentShipment?.newProbill.setPickupDateEndSelected(string);
  };
  const onSelectPickupType = (value) => {
    currentShipment?.newProbill?.setPickupTimeType(value);
  };

  return (
    <div className="pickup-info w45">
      <div className="input-wrapper w100">
        <div className="input-label justify-between">
          <span>{SHIPPER}</span>
        </div>
        <AsyncSelect
          loadOptions={loadSuggestions}
          onChange={onSelectShipper}
          placeholder={START_TYPING_PLACEHOLDER_TEXT}
        />
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{PICKUP_LOCATION}</span>
        </div>
        <div className="align-center">
          <Select
            disabled={!currentShipment?.newProbill?.shipper_id}
            showSearch
            style={{ width: '100%' }}
            placeholder={
              !searchPickupLocationValue.length &&
              !currentShipment?.newProbill?.pickup_location_id
                ? START_TYPING_PLACEHOLDER_TEXT
                : ''
            }
            optionFilterProp="children"
            onChange={onChangePickupLocation}
            onSearch={onSearchPickupLocation}
            filterOption={(input, option) =>
              option.props.children
                // @ts-ignore
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {currentShipment?.newProbill?.pickup_locations.map((item) => {
              return (
                <Option key={item.location_id} value={item.location_id}>
                  {item.location_name}
                </Option>
              );
            })}
          </Select>
          {showProbillLocationIcons && <LocationActions />}
        </div>
      </div>
      {probillAddNewLocationEnabled && (
        <div className="mt20">
          <div className="input-wrapper w100">
            <div className="input-label justify-between">
              <span>{LOCATION_NAME}</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={(e) => onNewLocationNameChange(e)}
              value={
                currentShipment?.newProbill?.selectedPickupLocation
                  ?.location_name
              }
            />
          </div>
          <div className="input-wrapper mt20 w100">
            <div className="input-label justify-between">
              <span>{LOCATION_CONTACT_NAME}</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={(e) => onNewLocationContactNameChange(e)}
              value={
                currentShipment?.newProbill?.selectedPickupLocation
                  ?.location_contact_name
              }
            />
          </div>
        </div>
      )}
      <div className="justify-between mt20">
        <div className="input-wrapper w70">
          <div className="input-label justify-between">
            <span>{ADDRESS}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => onEditedProbillLocationAddressChange(e)}
            value={currentShipment?.newProbill?.selectedPickupLocation?.address}
            disabled={isFieldDisabled()}
          />
        </div>
        <div className="input-wrapper w25">
          <div className="input-label justify-between">
            <span>{ZONE}</span>
          </div>
          <Input
            className="add-edit-input"
            value={currentShipment?.newProbill?.selectedPickupLocation?.zone}
            disabled
          />
        </div>
      </div>
      <div className="justify-between mt20">
        <div className="input-wrapper w35">
          <div className="input-label justify-between">
            <span>{ZIP_CODE}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => onEditedProbillLocationZipChange(e)}
            value={
              currentShipment?.newProbill?.selectedPickupLocation?.postal_code
            }
            disabled={isFieldDisabled()}
          />
        </div>
        <div className="input-wrapper w60">
          <div className="input-label justify-between">
            <span>{COUNTRY}</span>
          </div>
          <Select
            className="w100"
            onChange={onEditedProbillLocationCountryChange}
            value={currentShipment?.newProbill?.selectedPickupLocation?.country}
            disabled={isFieldDisabled()}
          >
            {currentShipment?.newProbill?.countries.map((country, index) => (
              <Option key={index} value={country.name}>
                {country.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="mt20">
        <div className="input-wrapper w100">
          <div className="input-label justify-between">
            <span>{STATE}</span>
          </div>
          <Select
            className="w100"
            onChange={onEditedProbillLocationStateChange}
            value={currentShipment?.newProbill?.selectedPickupLocation?.state}
            disabled={isFieldDisabled()}
          >
            {currentShipment?.newProbill?.states?.states?.map(
              (state, index) => (
                <Option key={index} value={state}>
                  {state}
                </Option>
              )
            )}
          </Select>
        </div>
        <div className="input-wrapper mt20 w100">
          <div className="input-label justify-between">
            <span>{CITY}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={(e) => onEditedProbillLocationCityChange(e)}
            value={currentShipment?.newProbill?.selectedPickupLocation?.city}
            disabled={isFieldDisabled()}
          />
        </div>
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{PHONE_NUMBER}</span>
        </div>
        <PhoneInput
          value={
            currentShipment?.newProbill?.selectedPickupLocation?.phone_number
          }
          displayInitialValueAsLocalNumber
          disabled={isFieldDisabled()}
        />
      </div>
      {probillEditLocationEnabled && (
        <div className="align-center mt20">
          <button
            type="button"
            className="btn ant-btn-primary small mr10"
            onClick={(e) => saveEditedProbillLocation(e)}
          >
            {OVERWRITE_EXISTING_LOCATION}
          </button>
          <button
            type="button"
            className="btn ant-btn-primary small"
            onClick={(e) => onEditProbillLocation(false)}
          >
            {CANCEL}
          </button>
        </div>
      )}
      {probillAddNewLocationEnabled && (
        <div className="align-center mt20">
          <button
            type="button"
            className="btn ant-btn-primary small mr10"
            onClick={(e) => saveNewProbillLocation(e)}
          >
            {SAVE_AS_NEW_LOCATION}
          </button>
          <button
            type="button"
            className="btn ant-btn-primary small"
            onClick={(e) => onAddNewProbillLocation(false)}
          >
            {CANCEL}
          </button>
        </div>
      )}
      <div className="input-label mt20">
        <span>{PICKUP_TYPE}</span>
        <div className="flex-alignitems-flex-start w100">
          <div className="input-wrapper w100">
            <Select
              className="w100 text-capitalize"
              onChange={onSelectPickupType}
              value={currentShipment?.newProbill?.pickup_time_type}
            >
              {currentShipment?.newProbill?.timeTypes.map((type, index) => (
                <Option key={index} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{SCHEDULED}</span>
        </div>
        {currentShipment?.newProbill?.pickup_time_type === 'Appointment' && (
          <DatePicker
            value={
              currentShipment?.newProbill.pickupDateStartSelected
                ? moment(currentShipment?.newProbill.pickupDateStartSelected)
                : null
            }
            disabledDate={disabledDate}
            onChange={onPickupDateChange}
          />
        )}
        {currentShipment?.newProbill?.pickup_time_type === '24/7' && (
          <DatePicker
            value={moment(currentShipment?.newProbill.pickupDateStartSelected)}
            disabledDate={disabledDate}
            onChange={onPickupDateChange}
          />
        )}
        {currentShipment?.newProbill?.pickup_time_type === 'Time Frame' && (
          <RangePicker
            value={[
              currentShipment?.newProbill?.pickupDateStartSelected
                ? moment(currentShipment?.newProbill.pickupDateStartSelected)
                : null,
              currentShipment?.newProbill?.pickupDateEndSelected
                ? moment(currentShipment?.newProbill.pickupDateEndSelected)
                : null,
            ]}
            onChange={onPickupDatesChange}
            disabledDate={disabledDate}
          />
        )}
        {currentShipment?.newProbill?.pickup_time_type === 'Not Assigned' && (
          <p>Not assigned</p>
        )}
      </div>
      <div className="w100">
        <div className="input-label justify-between mt20">
          <span>{PICKUP_TIME}</span>
        </div>
        <div className="flex-alignitems-flex-start w100">
          <div className="input-wrapper w50">
            <TimePicker
              onChange={onPickupTimeFromChange}
              disabledHours={getDisabledFromHours}
              disabledMinutes={getDisabledFromMinutes}
              allowClear={false}
              format={'HH:mm'}
              value={
                currentShipment?.newProbill?.pickupTimeStartSelected
                  ? moment(
                      currentShipment?.newProbill?.pickupTimeStartSelected,
                      'HH:mm'
                    )
                  : null
              }
              disabled={!currentShipment?.newProbill.isPickupDateRangeSet}
            />
          </div>
          <div className="input-wrapper w50 ml5pc">
            {currentShipment?.newProbill?.pickup_time_type === 'Time Frame' && (
              <TimePicker
                onChange={onPickupTimeToChange}
                disabledHours={getDisabledToHours}
                disabledMinutes={getDisabledToMinutes}
                format={'HH:mm'}
                value={
                  currentShipment?.newProbill?.pickupTimeEndSelected
                    ? moment(
                        currentShipment?.newProbill?.pickupTimeEndSelected,
                        'HH:mm'
                      )
                    : null
                }
                disabled={!currentShipment?.newProbill.isPickupDateRangeSet}
              />
            )}
          </div>
        </div>
      </div>

      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{PICKUP_NUMBER}</span>
        </div>
        <Input
          className="add-edit-input"
          value={currentShipment?.newProbill?.pickup_number}
          onChange={onPickupNumberChange}
        />
      </div>
    </div>
  );
};

export default observer(ShipperInfo);
