import React from 'react';
import {
  DELETE_RECORD_TEXT,
  EDIT_RECORD_TEXT,
  TYPE,
  TITLE,
  DESCRIPTION,
  PRICE,
} from '../../../constants';
import trash from '../../../assets/images/trash.svg';
import edit from '../../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../../RootModel';
import { SHIPMENT_CHARGE } from '../../../constants/shipmentSpecificsTypes';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentSpecificsPageState.setShipmentChargeToBeDeleted(
    record.charge_id
  );
};

const onClickEdit = (e, record) => {
  e.stopPropagation();

  rootInstance.shipmentSpecificsPageState.setEditedShipmentSpecificType(
    SHIPMENT_CHARGE
  );
  rootInstance.shipmentSpecificsPageState.setEditingShipmentSpecific(true);
  rootInstance.shipmentSpecificsPageState.setShipmentChargeToBeEdited(
    record.charge_id
  );
};

export const shipmentChargesColumnsFunc = (rootInstance) => ({
  '1': {
    title: TYPE,
    dataIndex: 'type',
    key: 'type',
  },
  '2': {
    title: TITLE,
    key: 'charge_name',
    dataIndex: 'charge_name',
  },
  '3': {
    title: DESCRIPTION,
    key: 'description',
    dataIndex: 'description',
  },
  '4': {
    title: PRICE,
    key: 'price',
    dataIndex: 'price',
    render: (text, record) => {
      return (
        <span>
          {record.price} {record.currency}
        </span>
      );
    },
  },
  '5': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          <Tooltip title={DELETE_RECORD_TEXT}>
            <img
              src={trash}
              alt="delete"
              className="pointer ph10"
              onClick={(e) => onClickDelete(e, record)}
            />
          </Tooltip>
          <div onClick={(e) => onClickEdit(e, record)}>
            <Tooltip title={EDIT_RECORD_TEXT}>
              <img src={edit} alt="edit" className="pointer ph10" />
            </Tooltip>
          </div>
        </div>
      );
    },
  },
});
