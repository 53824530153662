import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { EMPTY_DOCUMENTS_TABLE_TEXT } from '../../../../constants';

const ViewDocumentsTable = ({ model }) => {
  return (
    <Table
      className="mb20 documents-table"
      rowKey={'uid'}
      columns={model.getDocumentsColumns()}
      dataSource={model.documentsArray}
      pagination={false}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={EMPTY_DOCUMENTS_TABLE_TEXT}
          />
        ),
      }}
    />
  );
};

export default observer(ViewDocumentsTable);
