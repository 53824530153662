import React from 'react';
import { observer } from 'mobx-react';
import PageRoutes from '../../utils/PageRoutes';
import { Input, Icon, Form } from 'antd';
import { useMst } from '../../state/RootModel';
import showPassword from '../../assets/images/show-password.svg';
import hidePassword from '../../assets/images/hide-password.svg';
import logo from '../../assets/images/kamion-logo.svg';
import errorSign from '../../assets/images/error-sign.svg';
import {
  RESET_PASSWORD,
  LOGIN_TO_ACCOUNT,
  WELCOME,
  ENTER_NEW_PASSWORD_TEXT,
  CREATE_PASSWORD_TEXT,
  PASSWORD_INSTRUCTIONS_TEXT,
  NEW_PASSWORD,
  CONFIRM_PASSWORD,
  INVALID_TOKEN_TEXT,
  LOGO,
  PASSWORD,
  CREATE_ACCOUNT,
} from '../../constants';
import LoginErrorBox from './LoginErrorBox';
import { Link } from '../Link';

const ResetPasswordComponent = () => {
  const { loginForm, router } = useMst();

  const isNewUser = () => {
    return router.currentView.name === 'CreatePassword';
  };

  const user = isNewUser()
    ? loginForm.createPasswordTokenDecoded
    : loginForm.resetPasswordTokenDecoded;

  const token = isNewUser()
    ? loginForm.createPasswordToken
    : loginForm.resetPasswordToken;

  const onSubmit = (e) => {
    e.preventDefault();
    isNewUser()
      ? loginForm.onCreatePassword(token)
      : loginForm.onResetPassword(token);
  };

  return (
    <div className="reset-password-content">
      <img src={logo} alt={LOGO} className="logo mb20" />
      {token ? (
        <div className="form-content">
          <p>{WELCOME},</p>
          <h3 className="title">
            {user.data.first_name} {user.data.last_name}
          </h3>
          <p className="mb10 text-italic">
            {isNewUser() ? CREATE_PASSWORD_TEXT : ENTER_NEW_PASSWORD_TEXT}
          </p>
          <Form onSubmit={onSubmit} className="mt30">
            <Form.Item>
              <Input
                onChange={(e) => loginForm.setNewPassword(e.target.value)}
                placeholder={isNewUser() ? PASSWORD : NEW_PASSWORD}
                type={loginForm.newPasswordVisible ? 'text' : 'password'}
                value={loginForm.newPassword}
                className="login-input mb10"
                required
              />
              {loginForm.newPasswordVisible ? (
                <img
                  className="show-password-sign"
                  src={hidePassword}
                  alt="eye"
                  height="18"
                  onClick={() => loginForm.setNewPasswordVisible(false)}
                />
              ) : (
                <img
                  className="show-password-sign"
                  src={showPassword}
                  alt="eye"
                  height="18"
                  onClick={() => loginForm.setNewPasswordVisible(true)}
                />
              )}
            </Form.Item>
            <Form.Item>
              <Input
                onChange={(e) => loginForm.setConfirmedPassword(e.target.value)}
                placeholder={CONFIRM_PASSWORD}
                type={loginForm.confirmedPasswordVisible ? 'text' : 'password'}
                value={loginForm.confirmedPassword}
                className="login-input mb10"
                required
              />
              {loginForm.confirmedPasswordVisible ? (
                <img
                  className="show-password-sign"
                  src={hidePassword}
                  alt="eye"
                  height="18"
                  onClick={() => loginForm.setConfirmedPasswordVisible(false)}
                />
              ) : (
                <img
                  className="show-password-sign"
                  src={showPassword}
                  alt="eye"
                  height="18"
                  onClick={() => loginForm.setConfirmedPasswordVisible(true)}
                />
              )}
            </Form.Item>
            <p className="text-italic mb10">{PASSWORD_INSTRUCTIONS_TEXT}</p>

            <div className="login-box">
              {loginForm.errorText && <LoginErrorBox />}
            </div>
            <button
              className={`btn secondary login-btn mt20 ${
                loginForm.loading && 'disabled'
              }`}
              type={'submit'}
              disabled={loginForm.loading}
            >
              {loginForm.loading && (
                <span className="mr10">
                  <Icon type="loading" />
                </span>
              )}{' '}
              {isNewUser() ? CREATE_ACCOUNT : RESET_PASSWORD}
            </button>
          </Form>
          {!isNewUser() && (
            <div className="mt20">
              <Link
                className={`forgot-password-link ${
                  loginForm.loading && 'disabled'
                }`}
                view={PageRoutes.Login.name}
              >
                {LOGIN_TO_ACCOUNT}
              </Link>
            </div>
          )}
        </div>
      ) : (
        !isNewUser() && (
          <div className="mt50">
            <div className="align-center">
              <img src={errorSign} alt="error sign" height="36" />
              <h3 className="error-text ml5">{INVALID_TOKEN_TEXT}</h3>
            </div>
            <div className="mt50">
              <Link
                className={`reset-password-link ${
                  loginForm.loading && 'disabled'
                }`}
                view={PageRoutes.Login.name}
              >
                {LOGIN_TO_ACCOUNT}
              </Link>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default observer(ResetPasswordComponent);
