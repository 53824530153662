import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Empty, Table, Pagination } from 'antd';
import { useMst } from '../../../state/RootModel';
import { EMPTY_CARRIERS_TABLE_TEXT } from '../../../constants';
import {
  CARRIER_COMPANY_LIST,
  SEARCH_CARRIERS,
} from '../../../constants/shipmentSpecificsTypes';
import search from '../../../assets/images/search.svg';
import { DebounceInput } from 'react-debounce-input';

const ShipmentCarriersListModal = () => {
  const {
    shipmentsPageState: {
      currentShipment: {
        isCarriersModalOpened,
        setCarrierModalOpen,
        getShipmentCarriersColumns,
        carriersListCurrentPage,
        setCarriersListCurrentPage,
        carriersListPageSize,
        carriersTotalItems,
        filteredCarriersWithEquipmentArray,
        setCarrierListSearchTerm,
      },
    },
  } = useMst();

  const onModalCancel = () => {
    setCarrierModalOpen();
  };
  const handleSearch = (e) => {
    const searchText = e.target.value;
    setCarrierListSearchTerm(searchText);
  };
  const paginationChange = (val) => {
    setCarriersListCurrentPage(val);
  };

  return (
    <div>
      <Modal
        visible={!!isCarriersModalOpened}
        footer={null}
        centered={true}
        onCancel={onModalCancel}
        maskClosable={false}
        width={1200}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content carriers-list-modal-content">
          <div className="table-header mb20 mt20 align-center">
            <span className="text-uppercase text-medium ml10">
              {CARRIER_COMPANY_LIST}
            </span>
            <div className="search-wrapper border-bottom-search create-shipment-search align-center ml30">
              <img src={search} alt="search" className="search-icon" />
              <DebounceInput
                debounceTimeout={300}
                onChange={(e) => handleSearch(e)}
                style={{ width: 250, fontStyle: 'italic' }}
                placeholder={SEARCH_CARRIERS}
              />
            </div>
          </div>
          <Table
            className="carriers-list-table"
            rowKey={'carrier_id'}
            columns={getShipmentCarriersColumns()}
            dataSource={filteredCarriersWithEquipmentArray}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={EMPTY_CARRIERS_TABLE_TEXT}
                />
              ),
            }}
          />
          <div className="table-footer">
            <Pagination
              onChange={paginationChange}
              total={carriersTotalItems}
              current={carriersListCurrentPage}
              pageSize={carriersListPageSize}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ShipmentCarriersListModal);
