import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../state/RootModel';
import LoginFormComponent from './LoginFormComponent';
import ForgotPasswordComponent from './ForgotPasswordComponent';
import logo from '../../assets/images/kamion-logo.svg';
import { LOGO } from '../../constants';

const Login = () => {
  const loginForm = useMst().loginForm;

  return (
    <div className="login-content">
      <img src={logo} alt={LOGO} className="logo mb20" />
      <div className="login-inner-content">
        {loginForm.step === 1 && <LoginFormComponent />}
        {loginForm.step === 2 && <ForgotPasswordComponent />}
      </div>
    </div>
  );
};

export default observer(Login);
