export const DEPARTMENT_NO = 'Department No.';
export const DEPARTMENT_NAME = 'Department Name';
export const DEPARTMENT_EMPLOYEES = 'No. of Employees';

// Actions
export const DEPARTMENT_EDIT = 'Edit department';
export const DEPARTMENT_DELETE = 'Delete department';
export const USER_ROLE_DELETE = 'Delete user role';

// Other
export const DEPARTMENT_NAME_REQUIRED = 'Department name required';
export const EMPTY_DEPARTMENTS_TABLE_TEXT = 'There are no departments';
export const ADD_NEW_DEPARTMENT = 'Add new department';
export const EDIT_DEPARTMENT = 'Edit department';
export const ADD_DEPARTMENT = 'Add department';
export const CONFIRM_DELETING_DEPARTMENT =
  'Are you sure that you want to remove selected department?';
export const MODAL_SUCCESS_CREATE_DEPARTMENT_TEXT =
  'Department successfully created.';
export const MODAL_SUCCESS_DEPARTMENT_BUTTON_TEXT = 'Back to organization';
export const CONFIRM_DELETING_USER_ROLE =
  'Are you sure that you want to remove selected user role?';
