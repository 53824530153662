import React from 'react';
import { observer } from 'mobx-react';
import dropdownIcon from '../../assets/images/dropdown-icon.svg';
import { useMst } from '../../state/RootModel';
import { DEPARTMENT } from '../../constants';
import EmployeesTable from './EmployeesTable';

const DepartmentList = (props) => {
  const department = props.department ? { ...props.department } : null;

  const {
    employeesPageState: { departments },
  } = useMst();

  const currentDepartment = department
    ? departments.get(department.department_id)
    : null;

  return (
    <div>
      {currentDepartment && (
        <div className="table-title-row align-center">
          <div
            onClick={() =>
              currentDepartment.setIsEmployeesTableOpen(
                !currentDepartment.isEmployeeTableOpen
              )
            }
            className="pointer"
          >
            <img
              src={dropdownIcon}
              alt="dropdown"
              className={`dropdown-icon ${
                currentDepartment.isEmployeeTableOpen ? 'open' : ''
              }`}
              height="14"
            />
            <span className="text-capitalize color-primary text-medium ml10">
              {currentDepartment.department_name} {DEPARTMENT}
            </span>
          </div>
        </div>
      )}
      {currentDepartment && currentDepartment.isEmployeeTableOpen && (
        <EmployeesTable department={currentDepartment} />
      )}
    </div>
  );
};

export default observer(DepartmentList);
