import { toAmount } from '../../utils/methods';
import React from 'react';
import moment from 'moment';
import { rootInstance } from '../RootModel';
import {
  CARRIER_COMPANY,
  CARRIER_DETAILS,
  PHONE_NUMBER,
  OVERALL_DRIVER_SCORE,
  INSURANCE_EXPIRES,
  CARGO,
  NOT_AVAILABLE,
} from '../../constants';

const onSelectCarrier = (e, record) => {
  e.preventDefault();
  rootInstance.shipmentsPageState.currentShipment.setSelectedCarrier(
    record.carrier_id
  );
  rootInstance.shipmentsPageState.currentShipment.setCarrierModalOpen();
};

export const shipmentCarriersTableColumnsFunc = (rootInstance) => ({
  '1': {
    title: CARRIER_COMPANY,
    dataIndex: 'company_name',
    key: 'company_name',
    render: (text, record) => {
      return <span>{text ? text : '/'}</span>;
    },
  },
  '2': {
    title: CARRIER_DETAILS,
    dataIndex: 'address',
    key: 'address',
    render: (text, record) => {
      return (
        <span>
          {text} <br />
          {record.city}, {record.state}{' '}
          {record.postal_code && ',' + record.postal_code}
        </span>
      );
    },
  },
  '3': {
    title: PHONE_NUMBER,
    dataIndex: 'phone_number',
    key: 'phone_number',
    render: (text, record) => {
      return <span>{text ? text : '/'}</span>;
    },
  },
  '4': {
    title: OVERALL_DRIVER_SCORE,
    dataIndex: 'driver_type',
    key: 'driver_type',
    render: (text, record) => {
      return <span>{NOT_AVAILABLE}</span>;
    },
  },
  '5': {
    title: INSURANCE_EXPIRES,
    dataIndex: 'insurance_expiry_date',
    key: 'insurance_expiry_date',
    render: (text, record) => {
      return (
        <span>{text ? moment(text).format('MM.DD.YYYY') : NOT_AVAILABLE}</span>
      );
    },
  },
  '6': {
    title: CARGO,
    dataIndex: 'insurance_cargo_amount',
    key: 'insurance_cargo_amount',
    render: (text, record) => {
      return <span>{text ? '$' + toAmount(text) : NOT_AVAILABLE}</span>;
    },
  },
  '7': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          <button
            type="button"
            className="btn primary small no-wrap text-uppercase"
            onClick={(e) => onSelectCarrier(e, record)}
          >
            select carrier
          </button>
        </div>
      );
    },
  },
});
