import React from 'react';
import { observer } from 'mobx-react';
import { Table, Empty } from 'antd';
import { useMst } from '../../../../../state/RootModel';
import {
  EMPTY_ROUTE_ACTIONS_TABLE_TEXT,
  MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_BUTTON_TEXT,
  MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_TEXT,
  MODAL_SUCCESS_TITLE,
} from '../../../../../constants';
import PageRoutes from '../../../../../utils/PageRoutes';
import ConfirmDeleteRouteActionModal from './ConfirmDeleteRouteActionModal';
import SuccessModal from '../../../../SuccessModal';

const RouteActionsTable = () => {
  const {
    shipmentSpecificsPageState: {
      getRouteActionsColumns,
      routeActionsArray,
      setDeleteRouteActionSuccessModalOpen,
      successDeleteRouteActionModalOpen,
    },
  } = useMst();

  const backLink = PageRoutes.Brokerage.name;

  return (
    <div>
      <Table
        className="drivers-table fleet-table mt20"
        rowKey={'action_id'}
        columns={getRouteActionsColumns()}
        dataSource={routeActionsArray}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={EMPTY_ROUTE_ACTIONS_TABLE_TEXT}
            />
          ),
        }}
      />
      <ConfirmDeleteRouteActionModal />
      <SuccessModal
        title={MODAL_SUCCESS_TITLE}
        text={MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_TEXT}
        buttonText={MODAL_SUCCESS_DELETE_SHIPMENT_SPECIFIC_BUTTON_TEXT}
        backLink={backLink}
        open={successDeleteRouteActionModalOpen}
        closeModal={() => setDeleteRouteActionSuccessModalOpen(false)}
      />
    </div>
  );
};

export default observer(RouteActionsTable);
