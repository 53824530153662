import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../state/RootModel';
import arrowBack from '../assets/images/arrow-back.svg';
import breadcrumbsArrow from '../assets/images/breadcrumbs-arrow.svg';

const BreadcrumbsComponent = (props) => {
  const page = props.page;
  const breadcrumbsBackLink = props.breadcrumbsBackLink;

  const {
    loginForm: { currentUser },
    router,
  } = useMst();

  const redirectToLink = () => {
    router.setView(router.views.get(breadcrumbsBackLink));
  };

  return (
    <div className="breadcrumbs align-center">
      <div className="back-link pointer" onClick={redirectToLink}>
        <img src={arrowBack} alt="back" width="14px" />
        <span className="ml5">Back</span>
      </div>
      <span className="border-right mr20" />
      {!currentUser && (
        <div className="pointer" onClick={redirectToLink}>
          <span className="ml5">{breadcrumbsBackLink}</span>
        </div>
      )}
      {!currentUser && (
        <img src={breadcrumbsArrow} alt="arrow" className="breadcrumbs-arrow" />
      )}
      <p className="color-primary">{page}</p>
    </div>
  );
};

export default observer(BreadcrumbsComponent);
