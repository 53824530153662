import { rootInstance } from '../../state/RootModel';
import qs from 'qs';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const login = (username, password) => {
  let data = qs.stringify({
    password,
    username,
  });
  return axios.post(`${rootInstance.baseUrl}/account/login`, data, {
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
  });
};

export const forgotPassword = (email) => {
  return axios.post(
    `${rootInstance.baseUrl}/account/forgot_password?email=${email}`
  );
};

export const resetPassword = (newPassword, confirmedPassword, token) => {
  return axios.put(`${rootInstance.baseUrl}/account/forgot_password`, {
    new_password: newPassword,
    confirmed_password: confirmedPassword,
    token,
  });
};

export const createPassword = (newPassword, confirmedPassword, token) => {
  return axios.post(`${rootInstance.baseUrl}/account/activate`, {
    new_password: newPassword,
    confirmed_password: confirmedPassword,
    token,
  });
};

export const editCurrentUser = (user) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/account`,
    {
      first_name: user.first_name,
      last_name: user.last_name,
      code_name: user.code_name,
      email: user.email,
      date_of_birth: user.date_of_birth,
      phone_number: user.phone_number,
      user_organization_id: user.user_organization_id,
      street_address: user.street_address,
      city: user.city,
      country: user.country,
      state: user.state,
      postal_code: user.postal_code,
      social_security_number: user.social_security_number,
    },
    config
  );
};

export const changePassword = (
  currentPassword,
  newPassword,
  confirmedPassword
) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/account/password`,
    {
      new_password: newPassword,
      confirmed_password: confirmedPassword,
      current_password: currentPassword,
    },
    config
  );
};
