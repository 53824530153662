import React from 'react';
import { observer } from 'mobx-react';
import ViewDriverComponent from '../../components/fleet/ViewDriver/ViewDriverComponent';
import BreadcrumsComponent from '../../components/Breadcrumbs';
import PageRoutes from '../../utils/PageRoutes';
import { VIEW_DRIVER } from '../../constants';
import ViewAbsenceModal from '../../components/fleet/ViewDriver/ViewAbsenceModal';

const ViewDriverPage = () => {
  return (
    <div>
      <div className="page fleet-page">
        <BreadcrumsComponent
          breadcrumbsBackLink={PageRoutes.Fleet.name}
          page={VIEW_DRIVER}
        />
        <div className="page-content">
          <ViewDriverComponent />
          <ViewAbsenceModal />
        </div>
      </div>
    </div>
  );
};

export default observer(ViewDriverPage);
