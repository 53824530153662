import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { GENERAL_INFORMATION, TRUCK } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import TruckInformation from './TruckInformation';
import ViewLocationSection from '../SharedComponents/ViewComponents/ViewLocationSection';

const GeneralInformation = () => {
  const {
    fleetPageState: { viewedTruck },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedTruck.setIsGeneralInfoSectionOpen(
              !viewedTruck.isGeneralInfoSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedTruck.isGeneralInfoSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {GENERAL_INFORMATION}
          </span>
        </div>
      </div>
      {viewedTruck?.isGeneralInfoSectionOpen && (
        <div className="flex-alignitems-flex-start">
          <TruckInformation />
          <ViewLocationSection model={viewedTruck} type={TRUCK} />
        </div>
      )}
    </div>
  );
};

export default observer(GeneralInformation);
