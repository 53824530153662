import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { LICENCE_INFORMATION } from '../../../constants';
import dropdownIcon from '../../../assets/images/dropdown-icon.svg';
import LicencesTable from './LicencesTable';

const LicenceInformation = () => {
  const {
    fleetPageState: { viewedDriver },
  } = useMst();

  return (
    <div className="edit-driver-section form-page-section">
      <div className="table-title-row align-center">
        <div
          onClick={() => {
            viewedDriver.setIsLicenceInfoSectionOpen(
              !viewedDriver.isLicenceInfoSectionOpen
            );
          }}
          className="pointer"
        >
          <img
            src={dropdownIcon}
            alt="dropdown"
            className={`dropdown-icon ${
              viewedDriver.isLicenceInfoSectionOpen ? 'open' : ''
            }`}
            height="14"
          />
          <span className="text-capitalize color-primary text-medium ml10">
            {LICENCE_INFORMATION}
          </span>
        </div>
      </div>
      {viewedDriver?.isLicenceInfoSectionOpen && <LicencesTable />}
    </div>
  );
};

export default observer(LicenceInformation);
