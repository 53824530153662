import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Empty, Modal } from 'antd';
import { SHIPMENTS_LIST } from '../../../constants/dispatch';
import { Table } from 'antd';
import { EMPTY_SHIPMENTS_TABLE_TEXT, SEARCH_BY_ID } from '../../../constants';
import OrganizeTourShipmentsTableDetails from './OrganizeTourShipmentsTableDetails';
import { DebounceInput } from 'react-debounce-input';

const OrganizeTourShipmentsModal = () => {
  const {
    dispatchPageState: {
      isOrganizeTourShipmentsModalOpen,
      setIsOrganizeTourShipmentsModalOpen,
      organizeTourShipmentsModalSearch,
      setOrganizeTourShipmentsModalSearch,
      getFilteredAvailableShipments,
      getOrganizeTourShipmentModalColumns,
    },
  } = useMst();

  const onClose = () => {
    setIsOrganizeTourShipmentsModalOpen(false);
    setOrganizeTourShipmentsModalSearch('');
  };

  const onTripDataSearch = (e) => {
    setOrganizeTourShipmentsModalSearch(e.target.value);
  };

  return (
    <div>
      <Modal
        visible={!!isOrganizeTourShipmentsModalOpen}
        style={{ top: 88 }}
        footer={null}
        centered={false}
        closable={true}
        onCancel={onClose}
        width={1500}
      >
        <div className="p32">
          <div className="align-center mb40">
            <p className="mr50 color-title text-bold text-uppercase">
              {SHIPMENTS_LIST}
            </p>
            <div className="input-wrapper">
              <DebounceInput
                debounceTimeout={300}
                placeholder={SEARCH_BY_ID}
                value={organizeTourShipmentsModalSearch}
                onChange={(e) => onTripDataSearch(e)}
                className="trailer-search-input"
              />
            </div>
          </div>
          <Table
            rowKey={'shipment_number'}
            dataSource={getFilteredAvailableShipments}
            columns={getOrganizeTourShipmentModalColumns()}
            pagination={false}
            expandRowByClick={true}
            expandedRowRender={(record) => (
              <OrganizeTourShipmentsTableDetails data={record} />
            )}
            expandIcon={() => null}
            scroll={{ y: 500 }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={EMPTY_SHIPMENTS_TABLE_TEXT}
                />
              ),
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default observer(OrganizeTourShipmentsModal);
