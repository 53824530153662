import { SHIPMENT_CHARGE } from '../../constants/shipmentSpecificsTypes';
import { rootInstance } from '../../state/RootModel';

const axios = require('axios');

const getToken = () => {
  const token = localStorage.getItem('access_token');
  return `Bearer ${token}`;
};

const getConfig = () => {
  return {
    headers: {
      Authorization: getToken(),
    },
  };
};

export const getShipments = (
  text = null,
  pageNumber,
  pageSize,
  status,
  archived,
  isBrokerageShipment,
  active
) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  const statusQuery = status ? `&status=${status}` : '';
  const archivedQuery = `&is_archived=${archived}`;
  const isBrokerageShipmentQuery = `&is_brokerage_shipment=${isBrokerageShipment}`;
  const activeQuery = `&active=${active}`;
  return axios.get(
    `${rootInstance.baseUrl}/shipments?page=${pageNumber}&articles_per_page=${pageSize}${textQuery}${statusQuery}${archivedQuery}${isBrokerageShipmentQuery}${activeQuery}`,
    config
  );
};

export const getShipmentsForExport = (
  text = null,
  status,
  archived,
  isBrokerageShipment,
  active
) => {
  const config = getConfig();
  const textQuery = text ? `&text=${text}` : '';
  const statusQuery = status ? `&status=${status}` : '';
  const archivedQuery = `&is_archived=${archived}`;
  const isBrokerageShipmentQuery = `&is_brokerage_shipment=${isBrokerageShipment}`;
  const activeQuery = `&active=${active}`;
  return axios.get(
    `${rootInstance.baseUrl}/shipments?page=1&articles_per_page=10000${textQuery}${statusQuery}${archivedQuery}${isBrokerageShipmentQuery}${activeQuery}`,
    config
  );
};

export const getShipment = (shipment_id) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/shipments/${shipment_id}`, config);
};

export const getShipmentProbills = (shipment_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/probills`,
    config
  );
};

export const getAvailableShipmentStatuses = ({ active = true }) => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/shipment_statuses`, config);
};

export const getAvailableCreateShipmentStatuses = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/shipment_statuses`, config);
};

export const addShipment = (shipment) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/shipments`,
    {
      shipment_by: shipment.shipment_by,
      bill_to: shipment.bill_to,
      pio_number: shipment.pio_number,
      broker_name: shipment.broker_name,
      broker_phone: shipment.broker_phone,
      status: shipment.status,
      is_brokerage_shipment: shipment.is_brokerage_shipment,
      shipment_size: shipment.shipment_size,
    },
    config
  );
};

export const editShipment = (shipment) => {
  const config = getConfig();

  const trailerTypeId = shipment.selectedEquipment?.equipment_id
    ? shipment.selectedEquipment.equipment_id
    : null;
  const set_trailer_type_id_to_null = trailerTypeId === null;

  const carrierId = shipment.selectedCarrier?.carrier_id
    ? shipment.selectedCarrier?.carrier_id
    : null;
  const set_carrier_id_to_null = carrierId === null;

  return axios.put(
    `${rootInstance.baseUrl}/shipments/${shipment.shipment_id}?set_carrier_id_to_null=${set_carrier_id_to_null}&set_trailer_type_id_to_null=${set_trailer_type_id_to_null}`,
    {
      shipment_by: shipment.shipment_by.client_id,
      shipment_size: shipment.shipment_size,
      bill_to: shipment.bill_to.client_id,
      pio_number: shipment.pio_number,
      broker_name: shipment.broker_name,
      broker_phone: shipment.broker_phone,
      trailer_type_id: trailerTypeId,
      carrier_id: carrierId,
      status: shipment.status,
      set_trailer_type_id_to_null,
      set_carrier_id_to_null,
    },
    config
  );
};

export const deleteShipment = (shipment_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/shipments/${shipment_id}`,
    config
  );
};

export const uploadShipmentDocument = (
  shipmentId,
  documentTypeId,
  formData
) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/shipments/${shipmentId}/documents?document_type_id=${documentTypeId}`,
    formData,
    config
  );
};

export const getAvailableBillingCharges = () => {
  const config = getConfig();
  let is_brokerage = 'true';
  return axios.get(
    `${rootInstance.baseUrl}/billing_charges?is_brokerage_shipment=${is_brokerage}`,
    config
  );
};

export const getAvailableShipmentCharges = () => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipment_other?item=${SHIPMENT_CHARGE}`,
    config
  );
};

export const getShipmentDocuments = (shipment_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/documents`,
    config
  );
};

export const getShipmentCharges = (shipment_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_charges`,
    config
  );
};

export const addShipmentCharges = (shipment_id, items) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_charges`,
    items,
    config
  );
};

export const editShipmentCharge = (shipment_id, charge) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_charges/${charge.shipment_charge_id}`,
    {
      price: charge.price,
      currency: charge.currency,
    },
    config
  );
};

export const deleteShipmentDocument = (shipment_id, document_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/documents/${document_id}`,
    config
  );
};

export const deleteShipmentRelatedCharge = (shipment_id, charge_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_charges/${charge_id}`,
    config
  );
};

export const getEquipment = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/equipment`, config);
};

export const getCarriersWithEquipment = (
  equipment_id,
  page = 1,
  perPage = 5
) => {
  const config = getConfig();
  const eq = equipment_id ? `&equipment_id=${equipment_id}` : '';
  return axios.get(
    // `${rootInstance.baseUrl}/equipment/carriers${eq}`,
    `${rootInstance.baseUrl}/equipment/carriers?page=${page}&articles_per_page=5${eq}`,
    config
  );
};

export const setLocalPD = (shipment) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/shipments/${shipment.o_id}/local_p_and_d?pickup=${shipment.local_pickup}&delivery=${shipment.local_delivery}`,
    '',
    config
  );
};

export const getShipmentSizes = () => {
  const config = getConfig();
  return axios.get(`${rootInstance.baseUrl}/shipment_size`, config);
};

export const getShipmentNotes = (shipment_id) => {
  const config = getConfig();
  return axios.get(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_notes`,
    config
  );
};

export const addNewNote = (shipment_id, note_text) => {
  const config = getConfig();
  return axios.post(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_notes?text=${note_text}`,
    {},
    config
  );
};

export const deleteShipmentNote = (shipment_id, note_id) => {
  const config = getConfig();
  return axios.delete(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_notes/${note_id}`,
    config
  );
};
export const editShipmentNote = (shipment_id, note_id, text) => {
  const config = getConfig();
  return axios.put(
    `${rootInstance.baseUrl}/shipments/${shipment_id}/shipment_notes/${note_id}?new_text=${text}`,
    {},
    config
  );
};
