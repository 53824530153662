import { types } from 'mobx-state-tree';
import moment from 'moment';

import { getRoot } from 'mobx-state-tree';
import { REPORTS } from '../../constants/reports';

import { reportsTableColumns } from './ReportsTableColumns';

import { getPerformanceAndAnalize } from '../../actions/reports';

const initialDates = [
  moment().subtract(7, 'days').format('YYYY-MM-DD'),
  moment().format('YYYY-MM-DD'),
];

export const ReportsPageModel = types
  .model('ReportsPageModel', {
    activeTab: types.maybe(types.string),

    reportsDateRange: types.frozen(initialDates),

    reports: types.frozen([]),
  })
  .views((self) => {
    return {
      get reportsTableColumns() {
        return Object.values(reportsTableColumns(getRoot(self)));
      },

      get averagesOfReports() {
        const numOfReports = self.reports.length || 1;

        const averages = self.reports.reduce(
          (acc, curr) => {
            // sum cols
            [
              'sum_of_empty_miles',
              'sum_of_loaded_miles',
              'sum_of_total_miles',
              'sum_of_profit',
            ].forEach((property) => {
              acc[property] += curr[property] ?? 0;
            });

            // avg cols
            [
              'average_of_ravenue_per_mile',
              'average_of_cost_per_mile',
              'average_of_profit_per_mile',
              'average_of_driver_per_mile',
            ].forEach((property) => {
              acc[property] += (curr[property] ?? 0) / numOfReports;
            });

            // nested sum cols
            acc.sum_of_total_charge +=
              (curr?.sum_of_total_charge?.shipment_charge ?? 0) / numOfReports;
            acc.sum_of_total_cost +=
              (curr?.sum_of_total_cost?.total_cost ?? 0) / numOfReports;

            return acc;
          },
          {
            sum_of_empty_miles: 0,
            sum_of_loaded_miles: 0,
            sum_of_total_miles: 0,
            sum_of_profit: 0,
            average_of_ravenue_per_mile: 0,
            average_of_cost_per_mile: 0,
            average_of_profit_per_mile: 0,
            average_of_driver_per_mile: 0,

            // nested
            sum_of_total_charge: 0,
            sum_of_total_cost: 0,
          }
        );

        // parse averages in right format
        [
          'sum_of_empty_miles',
          'sum_of_loaded_miles',
          'sum_of_total_miles',
          'sum_of_profit',
          'average_of_ravenue_per_mile',
          'average_of_cost_per_mile',
          'average_of_profit_per_mile',
          'average_of_driver_per_mile',
        ].forEach((key) => {
          averages[key] = averages[key].toFixed(2);
        });

        averages.sum_of_total_charge = `${averages.sum_of_total_charge.toFixed(
          2
        )} ${self.reports[0]?.sum_of_total_charge?.currency ?? ''}`;
        averages.sum_of_total_cost = `${averages.sum_of_total_cost.toFixed(
          2
        )} ${self.reports[0]?.sum_of_total_cost?.currency ?? ''}`;

        return averages;
      },
    };
  })
  .actions((self) => {
    return {
      setActiveTab(key) {
        self.activeTab = key;
      },

      setReports(reports) {
        self.reports = reports;
      },

      setReportsDateRange(dates) {
        self.reportsDateRange = dates;
      },

      resetInitial() {
        this.setActiveTab(REPORTS);
        this.setReports([]);
        this.setReportsDateRange(initialDates);
        this.getReports(initialDates);
      },

      async getReports(dates) {
        if (!dates?.length) return;
        try {
          this.setReportsDateRange(dates);
          const result = await getPerformanceAndAnalize({
            start_date: dates[0],
            end_date: dates[1],
          });
          this.setReports(result?.data ?? []);
        } catch (error) {}
      },

      beforePageEnter() {
        this.resetInitial();
      },
    };
  });
