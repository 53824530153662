import { Tooltip } from 'antd';
import React from 'react';
import {
  ATTACHED_FILE,
  EXPIRATION_DATE,
  ISSUED_IN,
  ISSUED_ON,
  LICENCE_PLATE,
  VIEW_FILE,
} from '../../constants';

export const registrationsColumnsFunc = (rootInstance) => ({
  '1': {
    title: LICENCE_PLATE,
    dataIndex: 'licence_plate',
    key: 'licence_plate',
  },
  '2': {
    title: ISSUED_IN,
    dataIndex: 'issued_in',
    key: 'issued_in',
  },
  '3': {
    title: ISSUED_ON,
    dataIndex: 'issued_on',
    key: 'issued_on',
  },
  '4': {
    title: EXPIRATION_DATE,
    dataIndex: 'expiry_date',
    key: 'expiry_date',
  },
  '5': {
    title: ATTACHED_FILE,
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <Tooltip placement="top" title={VIEW_FILE}>
          <a href={record.document?.document_url} target="blank">
            {record.document?.name}
          </a>
        </Tooltip>
      );
    },
  },
});
