import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import { Modal, DatePicker, Select, Upload } from 'antd';
import {
  SAVE_CHANGES,
  DATE,
  COMPLIANCY_TYPE,
  EDIT_COMPLIANCY,
  NO_COMPLIANCY_TYPE_SELECTED,
  DESCRIPTION_RESULTS,
  NOTES_PLACEHOLDER,
  COMPLIANCY_DOCUMENT,
  DELETE_COMPLIANCE_TEXT,
} from '../../../constants';
import calendar from '../../../assets/images/calendar-edit.svg';
import moment from 'moment';
import ErrorBox from '../../ErrorBox';
import FormButtonsSection from '../../FormButtonsSection';
import fileUpload from '../../../assets/images/file-upload.svg';
import { getRandomInt } from '../../../utils/methods';
import TextArea from 'antd/lib/input/TextArea';
import { DOCUMENT_TYPE_COMPLIANCY } from '../../../constants/documents';
const isEqual = require('lodash.isequal');

const { Option } = Select;

const EditCompliancyModal = () => {
  const {
    fleetPageState: { editedDriver, availableCompliancesTypes },
  } = useMst();

  // added to reset state on discard changes
  const [key, setKey] = useState(getRandomInt());

  const [document, setDocument] = useState([]);
  useEffect(() => {
    setDocument(
      editedDriver?.editedCompliancy?.document
        ? [editedDriver.editedCompliancy.document]
        : []
    );
  }, [editedDriver?.editedCompliancy?.document, key]);
  const handleDocumentUpload = ({ file, fileList }) => {
    const listToUpload = fileList.length && [fileList[fileList.length - 1]];
    if (listToUpload && listToUpload.length) {
      listToUpload[0].originFileObj.documentType = DOCUMENT_TYPE_COMPLIANCY;
      if (file.status !== 'uploading') {
        setDocument(listToUpload);
      }
    } else {
      setDocument(fileList);
    }
  };

  const onSelectCompliancyType = (type) => {
    editedDriver.editedCompliancy.setType(type);
  };

  const onChangeDescription = (e) => {
    editedDriver.editedCompliancy.setDescription(e.target.value);
  };

  const onDateChange = (value, string) => {
    editedDriver.editedCompliancy.setExpirationDate(string);
  };

  const getDocumentForUpload = () => {
    if (document[0] && !editedDriver.editedCompliancy.document) {
      return document[0];
    } else if (
      document[0] &&
      editedDriver.editedCompliancy.document &&
      !isEqual(editedDriver.editedCompliancy.document, document[0])
    ) {
      return document[0];
    }
    return false;
  };

  const getDocumentForDeletion = () => {
    if (
      editedDriver.editedCompliancy.document &&
      !isEqual(editedDriver.editedCompliancy.document, document[0])
    ) {
      return editedDriver.editedCompliancy.document;
    }
    return false;
  };

  const onCancel = () => {
    setKey(getRandomInt());
    editedDriver.setEditedCompliancy(null);
  };

  const onConfirm = () => {
    const documentToUpload = getDocumentForUpload();
    const documentToDelete = getDocumentForDeletion();
    editedDriver.editedCompliancy.saveCompliancy(
      documentToUpload,
      documentToDelete
    );
  };

  const onDelete = () => {
    editedDriver.editedCompliancy.setIsConfirmDeleteModalOpen(true);
  };

  return (
    !!editedDriver?.editedCompliancy && (
      <div>
        <Modal
          visible={!!editedDriver.editedCompliancy}
          footer={null}
          centered={true}
          onCancel={onCancel}
          width={500}
          className="confirm-delete-modal"
          maskClosable={false}
        >
          <div className="p32">
            <div className="align-center mb20">
              <img src={calendar} alt="calendar" className="mr10" />
              <h3>{EDIT_COMPLIANCY}</h3>
            </div>
            <div className="input-wrapper mb20">
              <div className="input-label justify-between">
                <span>{COMPLIANCY_TYPE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className="w100 text-capitalize"
                onChange={onSelectCompliancyType}
                value={
                  editedDriver.editedCompliancy?.compliancy_type &&
                  editedDriver.editedCompliancy.compliancy_type
                }
                placeholder={
                  !editedDriver.editedCompliancy.compliancy_type &&
                  NO_COMPLIANCY_TYPE_SELECTED
                }
              >
                {availableCompliancesTypes.map((type, index) => (
                  <Option key={index} value={type} className="text-capitalize">
                    {type}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="input-wrapper mt20">
              <div className="input-label justify-between">
                <span>{DESCRIPTION_RESULTS}</span>
                <span className="color-secondary">*</span>
              </div>
              <TextArea
                rows={4}
                placeholder={NOTES_PLACEHOLDER}
                onChange={onChangeDescription}
                value={editedDriver.editedCompliancy?.compliancy_description}
              />
            </div>

            <div className="input-wrapper mt20">
              <div className="input-label justify-between">
                <span>{DATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <DatePicker
                allowClear={false}
                onChange={onDateChange}
                value={
                  editedDriver.editedCompliancy?.expiration_date
                    ? moment(editedDriver.editedCompliancy.expiration_date)
                    : null
                }
              />
            </div>

            <div className="input-wrapper mt20 mb20">
              <div className="input-label">
                <Upload
                  fileList={document}
                  onChange={handleDocumentUpload}
                  beforeUpload={() => false} // return false so that antd doesn't upload the picture right away
                >
                  <div className="align-center justify-between pointer">
                    <span className="color-primary mr5">
                      {COMPLIANCY_DOCUMENT}
                    </span>
                    <img src={fileUpload} alt="file upload" />
                  </div>
                </Upload>
              </div>
            </div>

            <ErrorBox obj={editedDriver.editedCompliancy} />

            <FormButtonsSection
              onConfirm={onConfirm}
              onDiscard={onDelete}
              confirmTitle={SAVE_CHANGES}
              discardTitle={DELETE_COMPLIANCE_TEXT}
            />
          </div>
        </Modal>
      </div>
    )
  );
};

export default observer(EditCompliancyModal);
