import React from 'react';
import { observer } from 'mobx-react';
import { Modal, Input } from 'antd';
import ErrorBox from '../../../ErrorBox';
import { useMst } from '../../../../state/RootModel';
import addFileIcon from '../../../../assets/images/add-file.svg';
import FormPageTitle from '../../../FormPageTitle';
import {
  ADDRESS,
  BILLING_EMAIL,
  CITY,
  COUNTRY,
  CUSTOMER_INFORMATION,
  CUSTOMER_NAME,
  EDIT_CUSTOMER,
  EMAIL,
  LOCATION_INFORMATION,
  NOTES,
  OTHER,
  PHONE_NUMBER,
  POSTAL_CODE,
  SAVE_CHANGES,
  STATE,
} from '../../../../constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { Select } from 'antd';
import { MC } from '../../../../constants/organizations/organizations';

const { Option } = Select;

const EditCustomerModal = () => {
  const {
    clientsPageState: {
      editedCustomer,
      setEditedCustomer,
      countriesArray,
      filteredStates,
    },
  } = useMst();

  const onChangeCompany = (e) => {
    editedCustomer.setCompany(e.target.value);
  };

  const onChangeEmail = (e) => {
    editedCustomer.setEmail(e.target.value);
  };

  const onChangeBillingEmail = (e) => {
    editedCustomer.setBillingEmail(e.target.value);
  };

  const onChangeMC = (e) => {
    editedCustomer.setMc(e.target.value);
  };
  const onChangeNote = (e) => {
    editedCustomer.setNote(e.target.value);
  };

  const onChangePhoneNumber = (value) => {
    editedCustomer.setPhoneNumber(value);
  };

  const onChangePostalCode = (e) => {
    editedCustomer.setPostalCode(e.target.value);
  };

  const onChangeCity = (e) => {
    editedCustomer.setCity(e.target.value);
  };

  const onChangeAddress = (e) => {
    editedCustomer.setAddress(e.target.value);
  };

  const onSaveChanges = () => {
    editedCustomer.saveCustomer();
  };

  const onCancel = () => {
    setEditedCustomer(null);
  };

  const onCountryChange = (country) => {
    editedCustomer.setCountry(country);
    editedCustomer.setState('');
  };
  const onChangeState = (state) => {
    editedCustomer.setState(state);
  };

  return (
    <div>
      <Modal
        visible={!!editedCustomer}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={700}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={addFileIcon} title={EDIT_CUSTOMER} />
          </div>

          <div className="form-page-subsection">
            <div className="subsection-header mt0">
              <p>{CUSTOMER_INFORMATION}</p>
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{CUSTOMER_NAME}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangeCompany}
                value={editedCustomer.company}
              />
            </div>

            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{EMAIL}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangeEmail}
                value={editedCustomer.email}
              />
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{PHONE_NUMBER}</span>
                <span className="color-secondary">*</span>
              </div>
              <PhoneInput
                placeholder="e.g. 712338645"
                value={editedCustomer.phone_number}
                onChange={(value) => onChangePhoneNumber(value)}
                maxLength={15}
                displayInitialValueAsLocalNumber
              />
            </div>
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{BILLING_EMAIL}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangeBillingEmail}
                value={editedCustomer.billing_email}
              />
            </div>
          </div>
          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{MC}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangeMC}
                value={editedCustomer.mc}
              />
            </div>
          </div>
          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper w100">
              <div className="input-label justify-between">
                <span>{NOTES}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangeNote}
                value={editedCustomer.notes}
              />
            </div>
          </div>

          <div className="form-page-subsection mt20">
            <div className="subsection-header mt0">
              <p>{LOCATION_INFORMATION}</p>
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper w20">
              <div className="input-label justify-between">
                <span>{POSTAL_CODE}</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangePostalCode}
                value={editedCustomer.postal_code}
              />
            </div>

            <div className="input-wrapper w35">
              <div className="input-label justify-between">
                <span>{COUNTRY}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className={'w100'}
                onChange={(country) => onCountryChange(country)}
                value={editedCustomer.country}
              >
                {countriesArray.map((country, index) => (
                  <Option key={index} value={country.name}>
                    {country.name}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="input-wrapper w35">
              <div className="input-label justify-between">
                <span>{STATE}</span>
                <span className="color-secondary">*</span>
              </div>
              <Select
                className={'w100'}
                onChange={(state) => onChangeState(state)}
                value={editedCustomer.state}
              >
                {filteredStates &&
                  filteredStates.states.map((state, index) => (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  ))}
                <Option key={'other'} value={'Other'}>
                  {OTHER}
                </Option>
              </Select>
            </div>
          </div>

          <div className="flex-alignitems-flex-start justify-between mt20">
            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{CITY}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangeCity}
                value={editedCustomer.city}
              />
            </div>

            <div className="input-wrapper">
              <div className="input-label justify-between">
                <span>{ADDRESS}</span>
                <span className="color-secondary">*</span>
              </div>
              <Input
                className="add-edit-input"
                onChange={onChangeAddress}
                value={editedCustomer.address}
              />
            </div>
          </div>

          <ErrorBox obj={editedCustomer} />

          <div>
            <button className="btn primary" onClick={onSaveChanges}>
              {SAVE_CHANGES}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditCustomerModal);
