import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../state/RootModel';
import FormPageTitle from '../../FormPageTitle';
import editDocumentIcon from '../../../assets/images/edit-pen.svg';
import { SAVE_NOTE, CANCEL, NOTE_TEXT } from '../../../constants';
import { Input, Modal } from 'antd';

const EditShipmentNoteModal = () => {
  const {
    shipmentsPageState: { currentShipment },
  } = useMst();

  const onCancel = () => {
    currentShipment.setShipmentNoteToBeEdited(null);
  };

  const onConfirm = () => {
    currentShipment.shipmentNoteToBeEdited.saveNote();
  };

  return (
    <div>
      <Modal
        visible={!!currentShipment?.shipmentNoteToBeEdited}
        footer={null}
        centered={true}
        onCancel={onCancel}
        maskClosable={false}
        width={600}
        className="modal"
        destroyOnClose={true}
      >
        <div className="p32 customer-modal-content">
          <div className="align-center">
            <FormPageTitle image={editDocumentIcon} title={SAVE_NOTE} />
          </div>
          <div className="input-wrapper w100 mt20">
            <div className="input-label justify-between">
              <span>{NOTE_TEXT}</span>
              <span className="color-secondary">*</span>
            </div>
            <Input
              className="add-edit-input"
              onChange={(e) =>
                currentShipment.shipmentNoteToBeEdited.setNoteText(
                  e.target.value
                )
              }
              value={currentShipment.shipmentNoteToBeEdited.note_text}
            />
          </div>
          <div className="justify-end mt50">
            <button className="btn white mr10" onClick={onCancel}>
              {CANCEL}
            </button>
            <button className="btn primary" onClick={onConfirm}>
              {SAVE_NOTE}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default observer(EditShipmentNoteModal);
