import { Tooltip } from 'antd';
import React from 'react';
import { ACTION, NOT_AVAILABLE } from '../../../constants';
import trash from '../../../assets/images/trash.svg';
import edit from '../../../assets/images/edit-item.svg';
import {
  DEPARTMENT_DELETE,
  DEPARTMENT_EDIT,
  DEPARTMENT_EMPLOYEES,
  DEPARTMENT_NAME,
  DEPARTMENT_NO,
} from '../../../constants/organization/departments';
import { rootInstance } from '../../RootModel';

const onDepartmentDelete = (e, department_id) => {
  e.preventDefault();
  rootInstance.currentOrganization.setIsDeleteDepartmentModalOpened(true);
  rootInstance.currentOrganization.getDepartmentToBeDeleted(department_id);
};
const onDepartmentEdit = (e, department_id) => {
  e.preventDefault();
  rootInstance.currentOrganization.setIsEditingDepartmentModalOpened(true);
  rootInstance.currentOrganization.getEditingDepartment(department_id);
};

export const organizationDepartmentsColumnsFunc = (rootInstance) => ({
  '1': {
    title: DEPARTMENT_NO,
    dataIndex: 'department_id',
    key: 'department_id',
  },
  '2': {
    title: DEPARTMENT_NAME,
    dataIndex: 'department_name',
    key: 'department_name',
    render: (text) => {
      return <p className="text-capitalize">{text ? text : NOT_AVAILABLE}</p>;
    },
  },
  '3': {
    title: DEPARTMENT_EMPLOYEES,
    dataIndex: 'number_of_people',
    key: 'number_of_people',
    render: (text) => {
      return <p>{text ? text : NOT_AVAILABLE}</p>;
    },
  },
  '7': {
    title: ACTION,
    render: (text, record) => {
      return (
        <div className="align-center">
          {rootInstance.userState.canRemoveDepartment && (
            <Tooltip title={DEPARTMENT_DELETE}>
              <img
                src={trash}
                alt={DEPARTMENT_DELETE}
                className="pointer ph10"
                onClick={(e) => onDepartmentDelete(e, record.department_id)}
              />
            </Tooltip>
          )}
          {rootInstance.userState.canEditDepartments && (
            <Tooltip title={DEPARTMENT_EDIT}>
              <img
                src={edit}
                alt={DEPARTMENT_EDIT}
                className="pointer ph10"
                onClick={(e) => onDepartmentEdit(e, record.department_id)}
              />
            </Tooltip>
          )}
        </div>
      );
    },
  },
});
