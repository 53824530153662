import React from 'react';
import { observer } from 'mobx-react';
import { stopIcon } from '../Icon';
import { Marker, Popup } from 'react-leaflet';
import { DELIVERY } from '../../../constants/dispatch';

const TrackingShipmentDeliveryLocation = (props) => {
  const { data } = props;
  return (
    <Marker
      position={[
        data[0]?.delivery_location?.latitude,
        data[0]?.delivery_location?.longitude,
      ]}
      // @ts-ignore
      icon={stopIcon}
      className="dispatch-map-marker"
    >
      <Popup>
        <p className="dispatch-map-marker__type text-dkred text-bold">
          {DELIVERY}: {data[0]?.delivery_location?.location_name}
        </p>
        {data[0]?.delivery_location?.address && (
          <span className="text-black">
            {data[0]?.delivery_location?.address}
          </span>
        )}
      </Popup>
    </Marker>
  );
};

export default observer(TrackingShipmentDeliveryLocation);
