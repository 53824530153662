import React from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../../../../state/RootModel';
import {
  CARGO_DESCRIPTION,
  CARGO_DETAILS,
  QUANTITY,
  UNIT_LOAD,
  WEIGHT,
} from '../../../../constants';
import { Input, Select } from 'antd';

const { Option } = Select;

const CargoTabComponent = () => {
  const {
    shipmentsPageState: {
      availableWeightUnits,
      availableQuantityUnits,
      currentShipment,
    },
  } = useMst();

  const onSelectQuantityUnit = (unit) => {
    currentShipment.newProbill.setQuantityUnit(unit);
  };

  const onChangeQuantity = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      currentShipment.newProbill.setQuantity(null);
    } else {
      currentShipment.newProbill.setQuantity(Number(e.target.value));
    }
  };

  const onChangeWeight = (e) => {
    if (!e.target.value || isNaN(e.target.value)) {
      currentShipment.newProbill.setWeight(null);
    } else {
      currentShipment.newProbill.setWeight(Number(e.target.value));
    }
  };

  const onSelectWeightUnits = (unit) => {
    currentShipment.newProbill.setWeightUnit(unit);
  };

  const selectUnits = (
    <Select
      className=" select-odometer-units w100 text-capitalize"
      onChange={onSelectWeightUnits}
    >
      {availableWeightUnits.map((unit, index) => (
        <Option className="text-capitalize" key={index} value={unit}>
          {unit}
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="page-section shipment-details-section">
      <div className="subsection-header">
        <p>{CARGO_DETAILS}</p>
      </div>

      <div className="input-wrapper w100 mt20">
        <div className="input-label justify-between">
          <span>{CARGO_DESCRIPTION}</span>
        </div>
        <Input
          className="add-edit-input"
          onChange={(e) =>
            currentShipment?.newProbill.setDescription(e.target.value)
          }
          value={currentShipment?.newProbill?.description}
        />
      </div>

      <div className="subsection-content flex-alignitems-flex-start mt20">
        <div className="input-wrapper w30">
          <span className="input-label justify-between">{UNIT_LOAD}</span>
          <Select
            onChange={onSelectQuantityUnit}
            className="text-capitalize w100"
          >
            {availableQuantityUnits.map((unit, index) => (
              <Option key={index} value={unit} className="text-capitalize">
                {unit}
              </Option>
            ))}
          </Select>
        </div>

        <div className="input-wrapper w30 ml20">
          <div className="input-label justify-between">
            <span>{QUANTITY}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={onChangeQuantity}
            value={currentShipment?.newProbill?.quantity}
          />
        </div>

        <div className="input-wrapper w30 ml20 ">
          <div className="input-label justify-between">
            <span>{WEIGHT}</span>
          </div>
          <Input
            className="add-edit-input"
            onChange={onChangeWeight}
            value={currentShipment?.newProbill?.weight}
            addonAfter={selectUnits}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(CargoTabComponent);
