import React from 'react';
import {
  DELETE_RECORD_TEXT,
  EDIT_RECORD_TEXT,
  TYPE,
  TITLE,
} from '../../../constants';
import trash from '../../../assets/images/trash.svg';
import edit from '../../../assets/images/edit-equipment.svg';
import { Tooltip } from 'antd';
import { rootInstance } from '../../RootModel';
import { SHIPMENT_STATUS } from '../../../constants/shipmentSpecificsTypes';

const onClickDelete = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentSpecificsPageState.setShipmentStatusToBeDeleted(
    record.status_id
  );
};

const onClickEdit = (e, record) => {
  e.stopPropagation();
  rootInstance.shipmentSpecificsPageState.setEditedShipmentSpecificType(
    SHIPMENT_STATUS
  );
  rootInstance.shipmentSpecificsPageState.setEditingShipmentSpecific(true);
  rootInstance.shipmentSpecificsPageState.setShipmentStatusToBeEdited(
    record.status_id
  );
};

export const shipmentStatusColumnsFunc = (rootInstance) => ({
  '1': {
    title: TYPE,
    dataIndex: 'type',
    key: 'type',
  },
  '2': {
    title: TITLE,
    key: 'name',
    dataIndex: 'name',
  },
  '3': {
    dataIndex: 'action',
    render: (text, record) => {
      return (
        <div className="align-center">
          <Tooltip title={DELETE_RECORD_TEXT}>
            <img
              src={trash}
              alt="delete"
              className="pointer ph10"
              onClick={(e) => onClickDelete(e, record)}
            />
          </Tooltip>
          <div onClick={(e) => onClickEdit(e, record)}>
            <Tooltip title={EDIT_RECORD_TEXT}>
              <img src={edit} alt="edit" className="pointer ph10" />
            </Tooltip>
          </div>
        </div>
      );
    },
  },
});
